import React from "react";
import { Link } from "react-router-dom";
import api from "../../../Environment";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";
import Helper from "../../Helper/Helper";
// import SavedDropdown from "../../Helper/savedDropdown";
import SearchForm from "../../Helper/searchForm";
import configuration from "react-global-configuration";

class FixedHeader extends Helper {
  // constructor(props) {
  //   super(props);
  // }

  state = {
    categories: null,
    loadingCategory: true,
    loadingWishlist: true,
    wishLists: null,
    data: {},
    chat_notification:0,
  };

  componentDidMount() {
    // Call api function
    // this.getCategory();
    if(localStorage.getItem("userLoginStatus")) {
      this.chatSocketConnect();
    }
  }

  handleSaveDropdown = (event) => {
    event.preventDefault();

    api.postMethod("wishlist").then((response) => {
      if (response.data.success === true) {
        this.setState({
          wishLists: response.data.data,
          loadingWishlist: false,
        });
      }
    });
  };

  render() {
    // const count = Object.keys(this.state.data).length;

    const { loadingCategory, loadingWishlist, wishLists } = this.state;
    let renderDetails;
    if (!localStorage.getItem("userLoginStatus")) {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item">
            <a className="nav-link" target="_blank" href="https://blog.partybudha.ca/">
              Blog
            </a>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/host">
              Become a Host
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="#"
              data-toggle="modal"
              data-target="#signup"
            >
              Sign up
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="#"
              data-toggle="modal"
              data-target="#login"
            >
              Log in
            </Link>
          </li>
        </React.Fragment>
      );
    } else {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/wishlist"}
              id="navbarwishdrop"
              // onClick={this.handleSaveDropdown}
              // data-toggle={loadingWishlist ? "" : "dropdown"}
            >
              Saved
            </Link>
            {/* {loadingWishlist ? "" : <SavedDropdown wishLists={wishLists} />} */}
          </li>
          <li className="nav-item dropdown">
            <Link
              to={"/history"}
              className="nav-link dropdown-toggle"
              id="navbardrop"
            >
              Bookings
            </Link>
          </li>
          <li className={`${this.state.chat_notification ? "nav-item msg-notification" : "nav-item"}`}>
            <Link
              className="nav-link dropdown-toggle"
              to={"/inbox"}
              id="navbardrop"
            >
              Messages{" "}
              {/* <span className="messages">
                <i className="fas fa-circle" />
              </span> */}
            </Link>
          </li>


          <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-link1 dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
            >
              <img
                src={
                  localStorage.getItem("user_picture") === null
                    ? "../assets/img/user-pic.png"
                    : localStorage.getItem("user_picture")
                }
                className="profile-img"
                alt="User"
              />
            </Link>
            <div className="dropdown-menu profile-drop">
              <Link to={"/edit-profile"} className="item">
                <div className="msg-head">
                  <h5>edit profile</h5>
                </div>
                <p className="msg-line" />
              </Link>
              {/* <Link to={"/notification"} className="item">
                <div className="msg-head">
                  <h5>account settings</h5>
                </div>
                <p className="msg-line" />
              </Link> */}
              <Link to={"/logout"} className="item">
                <div className="msg-head">
                  <h5>logout</h5>
                </div>
                <p className="msg-line" />
              </Link>
            </div>
          </li>
        </React.Fragment>
      );
    }
    return (
      <div className="sticky-top">
        <nav
          className="navbar navbar-expand-xl bg-light navbar-light white-header"
          id="navbar"
        >
          <Link className="navbar-brand" to={"/"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
          </Link>
          <SearchForm {...this.props} />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
            aria-controls="collapsibleNavbar" 
            aria-expanded="false" 
            aria-label="Toggle navigation"
          >
            <i className="fas fa-chevron-down" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav">{renderDetails}</ul>
          </div>
        </nav>

        <div className="header-height" />
        <Login {...this.props.props} />
        <Register {...this.props.props} />
      </div>
    );
  }
}

export default FixedHeader;
