import React, { Component } from "react";
import api from "../../../Environment";
import io from "socket.io-client";
import { Link } from "react-router-dom";
import { apiConstants } from "../../Constant/constants";
import Helper from "../../Helper/Helper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import ReactDOM from 'react-dom';
import configuration from "react-global-configuration";
import FancyBox from "./FancyBox";
import ChatAssetModal from "./ChatAssetModal";
import ReactPlayer from "react-player/lazy";


let socket;
class Inbox extends Helper {
  state = {
    chatList: null,
    loadingChatList: true,
    socket: false,
    inputMessage: null,
    chatData: null,
    loadingChatData: true,
    headerDetail: null,
    headerDetailsLoadFirst: true,
    headerContentLoading: true,
    loadingStatus: true,
    loadingContent: null,
    loadingStatusChatHistory: true,
    loadingContentChatHistory: null,
    chatListskipCount: 0,
    chatHistorySkipCount: 0,
    loadMoreChatHistory: false,
    chatIndex: 0,
    chatSidebar: {
      status: true,
      desktop: false
    },
    chatUser: null,
    chatProviderId: 0,
    initialHeight: 0,
    notificationIndex: [],
    fileType: '',
    chatAssetUpload: false,
  };
  componentDidMount() {
    this.chatListApiCall(0);
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }
    window.addEventListener('resize', this.handleChatsidebar);
  }

  componentWillUnmount() {
    socket.disconnect();
    window.removeEventListener('resize', this.handleChatsidebar);
  }

  handleChatsidebar = () => {
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state, chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state, chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }
  };

  handleAssetUploadModal = (fileType) => {
    this.setState({
      ...this.state,
      fileType: fileType,
      chatAssetUpload: true,
    });
  };

  closeChatAssetUploadModal = () => {
    this.setState({
      ...this.state,
      chatAssetUpload: false,
    });
  };

  messageStatusApiCall = (provider_id) => {
    let inputData;

    inputData = {
      user_id: localStorage.getItem("userId"),
      provider_id: provider_id,
    };

    api
      .postMethod("chat_messages_status", inputData)
      .then((response) => {
        if (response.data.success) {

        } else {

        }
      });
  };

  handleChatSideBarToggler = (toggle_status) => {
    this.setState({
      ...this.state, chatSidebar: {
        ...this.state.chatSidebar,
        status: toggle_status,
      }
    });
  }

  chatListApiCall = (loadMoreStatus) => {
    let items;
    api
      .postMethod("bookings_inbox", { skip: this.state.chatListskipCount })
      .then((response) => {
        if (response.data.success) {
          if (this.state.chatList != null) {
            items = [...this.state.chatList, ...response.data.data.chat_messages];
          } else {
            items = [...response.data.data.chat_messages];
          }
          this.setState({
            chatList: items,
            loadingChatList: false,
            chatListskipCount:
              response.data.data.chat_messages.length + this.state.chatListskipCount,
            loadingStatus: true,
            chatUser: response.data.data.users,
          });

          if (this.state.chatList.length > 0 && loadMoreStatus == 0) {
            this.headerDetails(0);
            this.chatDetailsApiCall(0, true);
          } else {
            this.setState({
              headerContentLoading: false,
            });
          }
        } else {
          this.errorCodeChecker(response.data.error_code);
        }
      });
  };

  socketConnectionfun = (userId, providerId, hostId) => {
    let socketUrl = configuration.get("configData.chat_socket_url");
    socket = io(socketUrl, {
      query:
        `commonid: 'user_id_` +
        userId +
        `_provider_id_` +
        providerId +
        `_host_id_` +
        hostId +
        `', myid: ` +
        userId,
    });

    socket.emit("update sender", {
      commonid:
        "user_id_" +
        userId +
        "_provider_id_" +
        providerId +
        "_host_id_" +
        hostId,
      myid: userId,
      userId: userId,
    });
    let chatContent;
    socket.on("message", (newData) => {
      //  if(newData.chattype)
      console.log(newData);
      // this.messageStatusApiCall(newData.provider_id);
      let content = [];
      content.push(newData);
      chatContent = [...this.state.chatData, ...content];
      this.setState({ chatData: chatContent }, () => this.scrollDown());
    });

  };

  assetUploadSuccess = (event, data) => {
    console.log(data);
    this.handleSubmit(event, data);
  }

  chatDetailsApiCall = (index, status) => {
    let inputData;
    if (this.state.chatList[index].booking_id == 0) {
      inputData = {
        user_id: localStorage.getItem("userId"),
        // host_id: this.state.chatList[index].host_id,
        provider_id: this.state.chatUser[index].provider_id,
        skip: status ? this.state.chatHistorySkipCount : 0,
        status: 0,
      };
    } else {
      inputData = {
        user_id: localStorage.getItem("userId"),
        // booking_id: this.state.chatList[index].booking_id,
        // host_id: this.state.chatList[index].host_id,
        provider_id: this.state.chatUser[index].provider_id,
        skip: status ? this.state.chatHistorySkipCount : 0,
        status: 0,
      };
    }
    console.log(inputData);
    api.postMethod("bookings_chat_details", inputData).then((response) => {
      let chathistory;
      if (response.data.success) {
        if (this.state.loadMoreChatHistory) {
          if (this.state.chatData != null) {
            chathistory = [
              ...response.data.data.reverse(),
              ...this.state.chatData,
            ];
          } else {
            chathistory = [...response.data.data.reverse()];
          }
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount:
              response.data.data.length + this.state.chatHistorySkipCount,
            loadingStatusChatHistory: true,
            loadMoreChatHistory: false,
          });
        } else {
          chathistory = response.data.data.reverse();
          // console.log(chathistory);
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount: response.data.data.length,
            loadingStatusChatHistory: true,
            chatIndex: index,
          }, () => {
            this.scrollDown();
          });
        }
      } else {
        //
      }
    });
  };

  headerDetails = async (index) => {
    await this.setState({
      headerDetail: this.state.chatUser[index],
      headerContentLoading: false,
      chatProviderId: this.state.chatUser[index].provider_id
    });
    if (!this.state.headerContentLoading) {
      this.socketConnectionfun(
        localStorage.getItem("userId"),
        this.state.headerDetail.provider_id,
        this.state.chatUser[index].host_id
      );
    }
  };

  handleOnChange = ({ currentTarget: input }) => {
    this.setState({ inputMessage: input.value });
  };

  handleSubmit = (event, assetData) => {
    event.preventDefault();
    if(this.state.inputMessage) {
      let online_status = 0;
      let inputData = { provider_id: this.state.headerDetail.provider_id };
      api
        .postMethod("online_status", inputData)
        .then((response) => {
          if (response.data.success) {
            online_status = response.data.data.status;
          } else {
            this.errorCodeChecker(response.data.error_code);
          }
          let chatData = [
            {
              user_id: localStorage.getItem("userId"),
              provider_id: this.state.headerDetail.provider_id,
              message: this.state.inputMessage,
              host_id: this.state.headerDetail.host_id,
              booking_id: this.state.chatData[0] ? this.state.chatData[0].booking_id : 0,
              chat_type: "up",
              updated: 'Just now',
              user_picture: localStorage.getItem("user_picture"),
              notification_type: 2,
              request_type: 0,
              user_status: 1,
              provider_status: online_status == 1 ? 1 : 0,
              sender_id: localStorage.getItem("userId"),
              chat_asset_id: assetData ? assetData.data.chat_asset.id : "",
              chat_asset_url: assetData ? assetData.data.chat_asset.file : "",
              file_type: assetData ? assetData.data.chat_asset.file_type : "",
            },
          ];
          console.log(chatData);
          socket.emit("message", chatData[0]);

          let messages;

          if (this.state.chatData != null) {
            messages = [...this.state.chatData, ...chatData];
          } else {
            messages = [...chatData];
          }
          this.setState({
            chatData: messages,
            inputMessage: "",
          }, () => this.scrollDown());
        });
    }
  };

  changeChatfun = async (event, index) => {
    event.preventDefault();
    socket.disconnect();
    await this.setState({ chatHistorySkipCount: 0 });
    this.headerDetails(index);
    this.chatDetailsApiCall(index, true);
    this.chatListApiCall(1);
    if (!this.state.chatSidebar.desktop) {
      this.handleChatSideBarToggler(false)
    }
  };

  loadMoreChatList = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.chatListApiCall(1);
  };

  loadMoreChatHistory = (event) => {
    event.preventDefault();
    this.setState({
      loadingStatusChatHistory: false,
      loadingContentChatHistory: "Loading...",
      loadMoreChatHistory: true,
    });
    this.chatDetailsApiCall(this.state.chatIndex, true);
  };

  scrollDown = () => {

    const objDiv = document.getElementById("messages-holder");

    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      // console.log(differenceNumber)

      if (differenceNumber > 100) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = this.state.initialHeight;
        this.setState({
          ...this.state,
          initialHeight: this.state.initialHeight + 20,
        });
      }
    }
  };

  getNotificationIndex = () => {

    let lastIndexFound = 0;

    let startingIndex = null;

    const indexArray = []

    for (let index = 0; index <= this.state.chatData.length - 1; ++index) {
      if (this.state.chatData[index] && (this.state.chatData[index].notification_type == 1 || this.state.chatData[index].notification_type == 2)) {
        if (this.state.chatData[index].notification_type == this.state.chatData[index + 1 > this.state.chatData.length - 1 ? this.state.chatData.length - 1 : index + 1].notification_type) {
          if (startingIndex == null) {
            startingIndex = index
            indexArray.push(index)
          }
        } else {
          lastIndexFound = 0;
          startingIndex = null;
        }
      }
    }
    console.log(indexArray);
    this.setState({
      ...this.state,
      notificationIndex: indexArray
    })
  }

  requestStatus = (event, chat_message, status) => {
    event.preventDefault();
    api.postMethod("custom_price_request_status", {
      user_id: chat_message.user_id,
      host_id: chat_message.host_id,
      provider_id: chat_message.provider_id,
      custom_price_request_id: chat_message.custom_price_request_id,
      status: status
    }).then((response) => {
      if (response.data.success) {
        if (status != 3) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
        }

        // this.chatDetailsApiCall(this.state.chatIndex, false);
        this.props.history.push(`/event/${response.data.data.host_unique_id}`,
          {
            custom_pricing: 1,
            checkin: response.data.data.checkin,
            custom_pricing_request_id: response.data.data.custom_pricing_request_id,
          }
        );
      } else {
        if (response.data.error_code == 1004) {
          this.props.toggleLoginModal(false);
        }
      }
    });
  }

  render() {
    const {
      chatList,
      chatUser,
      loadingChatList,
      chatData,
      loadingChatData,
      headerDetail,
      headerContentLoading,
      loadingStatus,
      loadingContent,
      loadingContentChatHistory,
      loadingStatusChatHistory,
      chatProviderId,
    } = this.state;

    return (
      <div className="main chatWrapper container mx-auto" id="inbox">
        {loadingChatList ? (
          "Loading"
        ) : chatList.length > 0 ? (
          <div className="row m-0 w-100">
            {this.state.chatSidebar.status && (
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-3 chat-leftsec">
                <div className="chat-details text-center">
                  <div
                    className="chat-list-block"
                    id="chat-list"
                  >
                    {chatUser.map((list, index) => (
                      <Link
                        to="#"
                        key={list.provider_id}
                        className={`chat-list-box ${list.provider_id == chatProviderId ? "active" : ""}`}
                        onClick={(event) => this.changeChatfun(event, index)}
                      >
                        <div className="chat-inline-box">
                          <div className="chat-prof-img ">
                            <img
                              src={list.provider_details.picture}
                              alt={list.provider_name}
                            />
                          </div>
                          <div className="chat-prof-content">
                            <h5 className="chat-name">{list.provider_name}</h5>
                            {/* <p className="chat-msg">{list.message}</p>
                            <p className="chat-date">{list.updated_at_formatted}</p> */}
                          </div>
                        </div>
                        {list.unread_status == 1 && list.isActive == 0 ?

                          <div className="chat-new-msg">
                            New
                          </div>

                          : ''}

                      </Link>
                    ))}
                  </div>
                  <div className="text-center">
                    {loadingStatus ? "" : loadingContent}
                    {chatList.length > 0 ? (
                      <a
                        href="#"
                        class="show-all"
                        onClick={this.loadMoreChatList}
                      >
                        show more
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-9 chat-rightsec">
              {loadingChatList ? (
                "Loading.. "
              ) : (
                <div className="relative height-100">
                  {headerContentLoading ? (
                    "Loading..."
                  ) : headerDetail ? (
                    <div className="chat-header">
                      <div className="chat-list-box single-chat-box">
                        <div className="chat-inline-box">
                          <div className="chatSidebarToggler ml-2" onClick={() => this.props.history.goBack()}>
                            <i class="fas fa-arrow-left"></i>
                          </div>
                          <div className="chat-prof-img">
                            <img
                              src={headerDetail.provider_details.picture}
                              alt={headerDetail.provider_name}
                            />
                          </div>
                          <div className="chat-prof-content">
                            <h5 className="chat-name">
                              {headerDetail.provider_name}
                            </h5>
                            <p className="chat-date">{headerDetail.updated_at_formatted}</p>
                          </div>
                        </div>
                        {this.state.chatSidebar.desktop || (
                          <>
                            {this.state.chatSidebar.status ? (
                              <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(false)}>
                                <i class="fas fa-times"></i>
                              </div>
                            ) : (
                              <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(true)}>
                                <i class="fas fa-bars"></i>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="chat-name m-t-10">
                      <p className="text-center">No Messages found.</p>
                    </div>
                  )}

                  {loadingChatData ? (
                    ""
                  ) : (
                    <div className="chat-content" id="messages-holder">
                      {chatData.length > 0 ? (
                        <div className="text-center">
                          {loadingStatusChatHistory
                            ? ""
                            : loadingContentChatHistory}
                          <a
                            href="category/index.html"
                            class="show-all"
                            onClick={(event) => this.loadMoreChatHistory(event)}
                          >
                            show more
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      {chatData.map((chat, index) =>
                        <>
                          {/* {this.state.notificationIndex.map((data) => (
                          <>
                            {index == data && chat.notification_type == 1 && (
                              <div className="chat-protect-notes">
                                <p>Booking Enquiry</p>
                              </div>
                            ) }
                          </>
                        ))} */}
                          {/* {index == this.state.chatHistorySkipCount ? 
                          <div className="chat-protect-notes">
                            <p>General Conversation</p>
                          </div>
                        : ''} */}

                          {chat.confirmed_booking_id || chat.draft_booking_id ?
                            <div className="offer-list-box">
                              <h4>{chat.bookings.host_name}</h4>
                              <p>{chat.message}</p>
                              {chat.confirmed_booking_id ?
                                <ul className="list-unstyled offer-list">
                                  <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                  <li><i class="far fa-clock mr-2"></i> Booked on: {chat.bookings.created}</li>
                                  <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                                </ul> :
                                <ul className="list-unstyled offer-list">
                                  <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                  <li><i class="far fa-clock mr-2"></i> Enquired on: {chat.bookings.created}</li>
                                  <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                                </ul>
                              }
                              {chat.confirmed_booking_id ?
                                <Link to={`/history-details/${chat.bookings.booking_id}`} className="view-order-btn">View Bookings</Link>
                                : ''}
                            </div>
                            :
                            chat.chat_type == "pu" ? (
                              <div className="chat-left">
                                <div className="display-inline">
                                  <img
                                    className="chat-img-left"
                                    src={chat.provider_picture}
                                  />
                                  <div className="chat-content-right">
                                    {chat.message ?
                                      <div className="chat-message">
                                        {chat.message}{" "}
                                      </div>
                                      : ''}
                                    <p className="clearfix m-0" />
                                    <div className="chat-img-upload-preview-card-left">
                                      {chat.chat_asset_url &&
                                        chat.file_type == "image" ? (
                                        <FancyBox>
                                          <img
                                            src={chat.chat_asset_url}
                                            className="chat-img-preview-img"
                                            data-fancybox
                                          />
                                        </FancyBox>
                                      ) : (
                                        ""
                                      )}
                                      {chat.chat_asset_url &&
                                        chat.file_type == "video" ? (
                                        <ReactPlayer
                                          url={chat.chat_asset_url}
                                          controls={true}
                                          className="post-video-size chat-video"
                                          width="450px"
                                          height="450px"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {chat.chat_asset_url &&
                                        chat.file_type == "document" ? (
                                        <a href={chat.chat_asset_url} target="_blank">
                                          <img
                                            src="../assets/img/icon/document.jpg"
                                            className="chat-img-preview-img"
                                          />
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <p className="chat-time">{chat.updated}</p>
                                    {chat.request_type == 1 && chat.custom_price_request_status === 0 ?
                                      <>
                                        <p>Note: Request only valid for 2 days.</p>
                                        <button
                                          type="submit"
                                          onClick={(event) =>
                                            this.requestStatus(event, chat, 1)
                                          }
                                          className="btn btn-success width-btn mr-3"
                                        >
                                          Proceed to Book
                                        </button>
                                        {/* <button
                                          type="button"
                                          onClick={(event) =>
                                            this.requestStatus(event, chat, 2)
                                          }
                                          className="btn btn-danger width-btn"
                                        >
                                          Reject
                                        </button> */}
                                      </>
                                      : chat.request_type == 1 && chat.custom_price_request_status == 1 ?
                                        <>
                                          <p>Note: Quote is valid for 2 days only. Please book at the earliest.</p>
                                          <button
                                            type="submit"
                                            onClick={(event) =>
                                              this.requestStatus(event, chat, 3)
                                            }
                                            className="btn btn-success width-btn mr-3"
                                          >
                                            Proceed to Book
                                          </button>
                                        </>
                                        : chat.request_type == 1 && chat.custom_price_request_status == 2 ?
                                          <p className="chat-time status-msg-indication">Rejected</p>
                                          : ''}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="clearfix" />
                                <div className="chat-right">
                                  <div className="display-inline">
                                    <div className="chat-content-right">
                                      {chat.message ?
                                        <div className="chat-message">
                                          {chat.message}{" "}
                                        </div>
                                        : ''}
                                      <p className="clearfix m-0" />
                                      <div className="chat-img-upload-preview-card-right">
                                        {chat.chat_asset_url &&
                                          chat.file_type == "image" ? (
                                          <FancyBox>
                                            <img
                                              src={chat.chat_asset_url}
                                              className="chat-img-preview-img"
                                              data-fancybox
                                            />
                                          </FancyBox>
                                        ) : (
                                          ""
                                        )}
                                        {chat.chat_asset_url &&
                                          chat.file_type == "video" ? (
                                          <ReactPlayer
                                            url={chat.chat_asset_url}
                                            controls={true}
                                            className="post-video-size chat-video"
                                            width="450px"
                                            height="450px"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {chat.chat_asset_url &&
                                          chat.file_type == "document" ? (
                                          <a href={chat.chat_asset_url} target="_blank">
                                            <img
                                              src="../assets/img/icon/document.jpg"
                                              className="chat-img-preview-img"
                                            />
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="chat-status-indication-right">
                                        <p className="chat-time text-right">
                                          {chat.updated}
                                        </p>
                                        {chat.provider_status == 1 ?
                                          <p className="read-msg-indication mr-1">Read</p>
                                          :
                                          <p className="delivered-msg-indication mr-1">Sent</p>
                                        }
                                        <p className="clearfix m-0" />
                                        {chat.chat_assets && chat.message_type == 1 && chat.chat_assets.file ? (
                                          chat.chat_assets.chat_asset_files.map(
                                            (file, index) => (
                                              <p className="preview-attach-info mr-2"><a href={file} target="_blank">Attachment {index + 1}{" "} </a></p>
                                            )
                                          )
                                        ) : ''}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </>
                      )}

                    </div>
                  )}

                  <div className="chat-footer">
                    <form onSubmit={this.handleSubmit}>
                      <div className="input-group dropdown">
                        <input
                          type="text"
                          className="form-control dropdown-toggle"
                          data-toggle="dropdown"
                          placeholder="enter your message"
                          name="message"
                          value={this.state.inputMessage}
                          onChange={this.handleOnChange}
                        />
                        <div
                          className="input-group-append"
                        >
                          <span className="input-group-text" id="basic-addon">
                            <img
                              src="../assets/img/icon/image-upload.png"
                              className="action-upload-icon"
                              onClick={() =>
                                this.handleAssetUploadModal("image")
                              }
                            />
                          </span>
                        </div>
                        <div
                          className="input-group-append"
                        >
                          <span className="input-group-text" id="basic-addon">
                            <img
                              src="../assets/img/icon/video-upload.png"
                              className="action-upload-icon"
                              onClick={() =>
                                this.handleAssetUploadModal("video")
                              }
                            />
                          </span>
                        </div>
                        <div
                          className="input-group-append"
                        >
                          <span className="input-group-text" id="basic-addon">
                            <img
                              src="../assets/img/icon/file-upload.png"
                              className="action-upload-icon"
                              onClick={() =>
                                this.handleAssetUploadModal("document")
                              }
                            />
                          </span>
                        </div>
                        <div
                          className="input-group-append"
                          onClick={this.handleSubmit}
                        >
                          <span className="input-group-text" id="basic-addon">
                            {/* <i className="fas fa-paper-plane" /> */}
                            <img
                              src="../assets/img/icon/send.png"
                              className="action-upload-icon-1"
                            />
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          "There is no message for you..."
        )}
        <ChatAssetModal
          chatAssetUpload={this.state.chatAssetUpload}
          closeChatAssetUploadModal={this.closeChatAssetUploadModal}
          fileType={this.state.fileType}
          assetUploadSuccess={this.assetUploadSuccess}
          toUserId={this.state.chatProviderId}
          chatType="up"
        />
      </div>
    );
  }
}

export default withToastManager(Inbox);
