export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  // Song download status

  DOWNLOAD_INITIATE_STAUTS: 1,

  DOWNLOAD_PROGRESSING_STAUTS: 2,

  DOWNLOAD_PAUSE_STAUTS: 3,

  DOWNLOAD_COMPLETE_STAUTS: 4,

  DOWNLOAD_CANCEL_STAUTS: 5,

  // Wishlist status

  WISHLIST_CLEAR_ALL: 1,

  WISHLIST_CLEAR_SINGLE_AUDIO: 0,

  // History status

  HISTORY_CLEAR_ALL: 1,

  HISTORY_CLEAR_SINGLE_AUDIO: 0,

  // PAGE TYPES

  PRIVACY_PAGE_TYPE: "privacy",

  TERMS_PAGE_TYPE: "terms",

  // Social Login Credentials

  GOOGLE_CLIENT_ID:
    "501400690973-ppt4lehtqrrh6jutldrmnc962nkr1tsi.apps.googleusercontent.com",

  FACEBOOK_APP_ID: "285371778766319",

  JWPLAYER_KEY: "M2NCefPoiiKsaVB8nTttvMBxfb1J3Xl7PDXSaw==",

  // Hisotry status

  HISTORY_INITIATE_STATUS: 1,

  HISTORY_COMPLETE_STATUS: 2,

  HISTORY_DELETE_STATUS: 3,

  USER_API_CALL: "/user",

  HOST_API_CALL: "/provider",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: "https://adminstaging.partybudha.ca/api/settings",

  google_api_key: "AIzaSyBwMhGADzGH8vWuzlxkTzL0JrvxVPAHtiY",

  socketUrl: "https://adminstaging.partybudha.ca:3002/",

  baseUrl:"https://adminstaging.partybudha.ca/",
  STRIPE_PUBLISHABLE_KEY: "pk_test_51J1WyBA112mG0u7tmQDjE6j9femQs1vPSbmscOcfA7MQvzMz8rXCvBqipQ6uP8cRoaw174Kzv619vjvXZPKMbqaP005SoY03X9",

};
