import React, { Component } from "react";
import InputField from "../../Helper/inputfield";
import api from "../../../HostEnvironment";
import ToastDemo from "../../Helper/toaster";
import HostHelper from "../../Helper/hostHelper";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class SocialLogin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { googleLoginInput } = this.props;
    return (
      <div className="modal fade auth social-login" id="social-login">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                id="close-signup"
                data-dismiss="modal"
              >
                {/* <i className="material-icons">close</i> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#787878" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
              </button>
            </div>

            <div className="modal-body">
              <h1 className="section-head">
                Welcome to {configuration.get("configData.site_name")}
              </h1>

              <div className="input-group">
                <div class="upload-btn-wrapper">
                  <img
                    src={googleLoginInput.picture}
                    class="mr-3 rounded-circle user-profile-img"
                  />
                  <input type="file" name="picture" />
                </div>
              </div>

              <InputField
                type="text"
                name="first_name"
                disabled="true"
                placeholder="First Name"
                iconClassName="fas fa-user-circle"
                value={googleLoginInput.first_name}
              />
              <InputField
                type="text"
                name="last_name"
                disabled="true"
                placeholder="Last Name"
                iconClassName="fas fa-user"
                value={googleLoginInput.last_name}
              />

              <InputField
                type="text"
                name="email"
                disabled="true"
                placeholder="Primary Email"
                iconClassName="fas fa-envelope"
                value={googleLoginInput.email}
              />

              <p className="text-muted">
                All the communication on the platform will be sent to the above
                email.
              </p>

              {/* <InputField
                type="text"
                name="mobile"
                onChange={this.props.handleResponseChange}
                placeholder="Phone Number"
                iconClassName="fas fa-mobile"
                value={googleLoginInput.mobile}
              /> */}

              <div>
                <div className="input-group">
                  <PhoneInput
                    country={'ca'}
                    label="Phone number"
                    type="text"
                    placeholder=""
                    id="number"
                    name="mobile"
                    value={googleLoginInput.mobile}
                    onChange={value => this.props.handleMobileResponse(value)}
                    className="form-control"
                  />
                  {/* <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon">
                      <i className="fas fa-mobile" />
                    </span>
                  </div> */}
                </div>
              </div>
              
              {/* <InputField
                type="text"
                name="secondary_email"
                onChange={this.props.handleResponseChange}
                placeholder="Secondary email"
                iconClassName="fas fa-envelope"
                value={googleLoginInput.secondary_email}
              /> */}

              <p className="text-muted">
                We'll send you marketing promotions, special offers,
                inspiration, and policy updates via email.
              </p>

              <div className="input-group">
                <label className="text-none">
                  <input
                    type="checkbox"
                    name="is_newsletter"
                    value="0"
                    // onChange={this.handleChange}
                    className=""
                  />{" "}
                  I don’t want to receive any marketing messages from{" "}
                  {configuration.get("configData.site_name")}. I understand I
                  will still receive booking confirmation and other important
                  notification from Party Budha even if I opt out of the
                  marketing messages.
                </label>
              </div>

              <p>
                By clicking Agree & Signup, I agree to
                {"  "}
                <Link to={`/page/Host-Service-Agreement`} target="_blank">
                  {configuration.get("configData.site_name")}
                  {"  "}
                  Host Services Agreement
                </Link>
                {"  "} and 
                <Link to={`/page/privacy`} target="_blank">
                  {" "}
                  Privacy Policy{" "}
                </Link>
                .
              </p>

              <button
                className="pink-btn bottom1"
                onClick={this.props.handleGoogleSubmit}
              >
                {"Agree & Signup"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialLogin;
