import React, { Component } from "react";
import HostHelper from "../../Helper/hostHelper";
import { Link } from "react-router-dom";

class HostSingleFrontDesk extends HostHelper {
  state = {
    data: {},
    loadingFrontDesk: true,
    error: null,
    loginStatus: true,
    loadingContent: null,
    buttonDisable: false
  };

  componentDidMount() {
    this.getFrontDeskDetails(this.props.match.params.id);
  }

  render() {
    const { loadingFrontDesk, data } = this.state;
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              {loadingFrontDesk ? (
                "Loading..."
              ) : (
                <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                  <form>
                    <div class="panel">
                      <div class="panel-heading">

                         {data.name}
                         <Link className="float-right" to={"/host/frontdesk-users"}>
                          <i className="fas fa-chevron-left" /> Back
                        </Link>
                      </div>
                      <div class="panel-body account">
                        <h5 class="m-0 captalize lh-1-4">Front Desk  details</h5>
                        <p class="overview-line" />

                        <label class="form-checkbox">
                          Name: {data.name}
                        </label>

                        <label class="form-checkbox">
                          Email: {data.email}
                        </label>

                        <label class="form-checkbox">
                          Mobile: {data.mobile}
                        </label>                      

                        <label class="form-checkbox">
                          Status:{" "}
                          {data.provider_status === 1 ? "Approved" : "Declined"}
                        </label>

                        <label class="form-checkbox">
                          Total Hosts :{" "}
                          {data.total_hosts}
                        </label>
                     
                        <label class="form-checkbox">
                          Description: {data.description}
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HostSingleFrontDesk;
