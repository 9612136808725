import React, { Component } from "react";
import { withToastManager } from "react-toast-notifications";

import $ from "jquery";
import { isMobile } from "react-device-detect";
import api from "../../Environment";
import ToastDemo from "../../components/Helper/toaster";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";

class BannerPromo extends Component {

    state = {
        data:{},
        promoCodeApiResponse:null,
        loadingPromoCode:false,
        promoCodeText:'',
        placeHolder:''
    };
    
    onInputChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data });
    };

    checkPromoCode = (event) => {
        this.setState({
            loadingPromoCode: true,
            promoCodeText:'',
        });
        event.preventDefault();
        setTimeout(() => {
            api
            .postMethod("get_banner_promo_code",this.state.data)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        promoCodeApiResponse: response.data.data,
                        loadingPromoCode: false,
                        email:'',
                        promoCodeText:response.data.data.promo_code_text,
                        placeHolder:'PROMO CODE: '+response.data.data.promo_code,
                    });
                    ToastDemo(
                        this.props.toastManager,
                        response.data.message,
                        "success"
                    );
                } else {
                    this.setState({
                        promoCodeApiResponse: null,
                        loadingPromoCode: false,
                        email:'',
                        promoCodeText:'',
                        placeHolder:'',
                    });
                    ToastDemo(this.props.toastManager, response.data.error, "error");
                    return false;
                }
            });
        }, 10000);
    };

    onCopy = () => {
        this.setState({ copied: true });
    
        $("#referral_copy_success")
          .html("Copied")
          .fadeIn("slow");
    
        setTimeout(function() {
          $("#referral_copy_success").html("");
        }, 1000);
    };

    render() {
        return (
            <>
            <section className="banner-promo-sec">
                <div className="banner-promo-header-sec header-nav-center">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light px-sm-0">
                            <Link className="navbar-brand" to={"/"}>
                                <img
                                    src={window.location.origin + `/assets/img/landing-page/logo-1.png`}
                                />
                            </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to={"/"}>Home</Link>
                                    </li>
                                </ul>
                            </div> */}
                        </nav>
                        <div className="banner-promo-home-banner-sec">
                        </div>
                    </div>
                </div>
                <div className="banner-promo-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <h2>WIN A FREE BIRTHDAY PARTY WORTH $300</h2>
                                <p>Party Budha is an online marketplace for booking party venues and services</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="banner-promo-register-sec">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-7 text-center">
                            <h3 className="banner-promo-title">Regiter and Win Guaranteed Prices</h3>
                            <p className="banner-promo-desc">Mega Price <span style={{color:'#ff8322'}}>$300</span> Gift Coupon Every participant will win guaranteed $15 gift coupon</p>
                        </div>
                    </div>
                    <div className="row margin-top-lg justify-content-md-center">
                        <div className="col-md-8">
                            {!this.state.loadingPromoCode && this.state.promoCodeText =='' ?
                            <form className="banner-promo-form" onSubmit={this.checkPromoCode}>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="formGroupExampleInput">First Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Enter First Name"
                                                id="first_name"
                                                name="first_name"
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="formGroupExampleInput">Last Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Enter Last Name"
                                                id="last_name"
                                                name="last_name"
                                                onChange={this.onInputChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="formGroupExampleInput">Email</label>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                placeholder="Your Email" 
                                                id="email"
                                                onChange={this.onInputChange}
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="formGroupExampleInput">Phone Number</label>
                                            <input 
                                                type="number" 
                                                className="form-control"
                                                placeholder="Enter Phone Number"
                                                id="mobile"
                                                name="mobile"
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <p>By registering with us, you consent to receive marketing promotions, offers, and updates via email.</p>

                                <div className="banner-promo-btn-sec">
                                    <button 
                                        type="submit" 
                                        className="green-btn btn-small"
                                        disabled={this.state.loadingPromoCode}
                                    >
                                        TRY MY LUCK
                                        
                                    </button>
                                </div>
                            </form>
                            : ''}
                            {this.state.loadingPromoCode ?
                                <div className="banner-promo-loader-sec">
                                    <img
                                        src={window.location.origin + `/assets/img/landing-page/promo-loader-1.gif`}
                                        className="banner-promo-loader"
                                    />
                                </div>
                            : '' }
                            {!this.state.loadingPromoCode && this.state.promoCodeText =='' ?
                                ''
                            : 
                                (this.state.promoCodeText ? 
                                    <>
                                    <div className="banner-promo-success-sec">
                                        <img
                                            src={window.location.origin + `/assets/img/landing-page/success-msg.svg`}
                                            className="success-msg"
                                        />
                                        <p>Congratulations, you have won  <span style={{color:'#ff8322'}}>{this.state.promoCodeText}</span> discount!</p>
                                        <h6>Hurry the code will expire soon!</h6>
                                    </div>
                                    <div className="promo-banner-form">
                                        <div className="input-group">
                                            <input type="text" 
                                                className="form-control copy-text" 
                                                placeholder={this.state.placeHolder}
                                                disabled
                                            />
                                            <div className="input-group-append">
                                                <CopyToClipboard
                                                onCopy={this.onCopy}
                                                text={this.state.promoCodeApiResponse.promo_code}
                                                >
                                                    <button className="input-group-text" id="basic-addon2">
                                                        COPY
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <p
                                            id="referral_copy_success"
                                            className="text-success"
                                        ></p>
                                    </div>
                                    </>
                                : 
                                   ''
                                )
                            }

                        </div>
                    </div>
                </div>
            </section>
            <div className="banner-promo-btn-sec">
                <Link 
                    to={"/"}
                    className="green-btn btn-small"
                >
                    Explore Party Budha
                    
                </Link>
            </div>
            </>
        );
    }
}

export default withToastManager(BannerPromo);
