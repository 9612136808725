import React, { Component } from "react";

class AddListingStep10 extends Component {
  state = {};
  render() {
    const { seventhApiResponse, handleAddOnServices, formData } = this.props;
    return (
      <div id="step-12" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">Add on Services.</h4>
              <p className="main-txt">
                Add additional add-on services & fees to your listing. Please
                give the amount and mention it's required for every booking or
                optional for the guest to choose. Leave it blank if it's not
                applicable.
              </p>
              <div className="flow-content">
                {seventhApiResponse.map((details) => (
                  <div className="addon-content-box">
                    <div className="form-group large-form-group">
                      <label className="light-txt">{details.name}</label>
                      <input
                        type="text"
                        className="form-control"
                        name={`addon_services[${details.server_key}][amount]`}
                        value={formData[details.amount]}
                        placeholder="$0.00"
                        onChange={handleAddOnServices}
                      />
                    </div>

                    {/* <div className="form-group large-form-group">
                      <label className="light-txt">
                        Is required for every booking?
                        <input
                          type="checkbox"
                          name={`addon_services[${details.server_key}][is_required]`}
                          value="1"
                          onChange={handleAddOnServices}
                        />
                      </label>
                    </div> */}

                    <h5 className="flow-box-tit">
                      Is required for every booking?
                    </h5>
                    <div
                      className="form-check add-list-block"
                      key={`add_services_required_yes_${details.server_key}`}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`addon_services[${details.server_key}][is_required]`}
                        id={`add_services_required_yes${details.server_key}`}
                        value="1"
                        onChange={handleAddOnServices}
                      />
                      <label
                        className="form-check-label row"
                        htmlFor={`add_services_required_yes${details.server_key}`}
                      >
                        <h5 className="flow-check-tit">Yes</h5>
                      </label>
                    </div>
                    <div
                      className="form-check add-list-block"
                      key={`add_services_required_no_${details.server_key}`}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`addon_services[${details.server_key}][is_required]`}
                        id={`add_services_required_no${details.server_key}`}
                        value="0"
                        onChange={handleAddOnServices}
                      />
                      <label
                        className="form-check-label row"
                        htmlFor={`add_services_required_no${details.server_key}`}
                      >
                        <h5 className="flow-check-tit">No</h5>
                      </label>
                    </div>

                    {details.type == "others" ? (
                      <div>
                        <div className="form-group large-form-group">
                          <p className="light-txt"></p>
                          <h5 className="flow-box-tit">Choose Unit Type</h5>

                          <div
                            className="form-check add-list-block"
                            key={`add_services_required_unit_type_person${details.server_key}`}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`addon_services[${details.server_key}][unit_type]`}
                              id={`add_services_required_unit_type_person${details.server_key}`}
                              value="person"
                              onClick={handleAddOnServices}
                            />
                            <label
                              className="form-check-label row"
                              htmlFor={`add_services_required_unit_type_person${details.server_key}`}
                            >
                              <h5 className="flow-check-tit">Per Person</h5>
                            </label>
                          </div>
                          <div
                            className="form-check add-list-block"
                            key={`add_services_required_unit_type_item${details.server_key}`}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`addon_services[${details.server_key}][unit_type]`}
                              id={`add_services_required_unit_type_item${details.server_key}`}
                              value="item"
                              onClick={handleAddOnServices}
                            />
                            <label
                              className="form-check-label row"
                              htmlFor={`add_services_required_unit_type_item${details.server_key}`}
                            >
                              <h5 className="flow-check-tit">Per Item</h5>
                            </label>
                          </div>

                          {/* <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name={`addon_services[${details.server_key}][unit_type]`}
                                value="person"
                                onClick={handleAddOnServices}
                                defaultChecked="true"
                              />
                              Per Person
                            </label>
                          </div>

                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name={`addon_services[${details.server_key}][unit_type]`}
                                value="item"
                                onClick={handleAddOnServices}
                                defaultChecked="true"
                              />
                              Per Item
                            </label>
                          </div>
                         */}
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <p className="light-txt">Min Unit</p>

                            <input
                              type="number"
                              className="form-control"
                              min="1"
                              step="1"
                              name={`addon_services[${details.server_key}][min_value]`}
                              placeholder="0"
                              onChange={handleAddOnServices}
                            />
                          </div>

                          <div className="col-6">
                            <p className="light-txt">Max Unit</p>

                            <input
                              type="number"
                              min="1"
                              step="1"
                              className="form-control"
                              name={`addon_services[${details.server_key}][max_value]`}
                              placeholder="0"
                              onChange={handleAddOnServices}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>Per Booking</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep10;
