import React, { Component } from "react";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { withToastManager } from "react-toast-notifications";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import PasswordStrengthBar from 'react-password-strength-bar';
import CustomPasswordMeter from "../Helper/CustomPasswordMeter";

class ResetPassword extends Component {
    state = {
        inputData: {},
        loadingContent: null, 
        buttonDisable: false,
        activeBreakdown:null,
        passwordScore:0,
    };

    handleChange = ({ currentTarget: input }) => {
        const inputData = { ...this.state.inputData };
        inputData[input.name] = input.value;
        inputData['reset_token'] = this.props.match.params.token;
        this.setState({ inputData });
    };

    handlePassword = (value) => {
        this.setState({
          passwordScore:value
        })
    };

    passwordClick = (event) => {
        event.preventDefault();
        this.setState({
            activeBreakdown: this.state.activeBreakdown == 'password' ? null : 'password',
        })
      };
    
    handlebreakdownClose = (event) => {
        event.preventDefault();
        this.setState({
            activeBreakdown: null,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loadingContent: "Loading... Please wait..",
            buttonDisable: true,
        });
        api.postMethod("reset_password", this.state.inputData)
        .then((response) => {
            if (response.data.success === true) {
                localStorage.setItem("userId", response.data.data.user_id);
                localStorage.setItem("socket", true);
                localStorage.setItem("login_by", response.data.data.login_by);
                localStorage.setItem(
                    "accessToken",
                    response.data.data.token
                );
                localStorage.setItem("userLoginStatus", true);
                localStorage.setItem(
                    "user_picture",
                    response.data.data.picture
                );
                localStorage.setItem(
                    "username",
                    response.data.data.username
                );

                localStorage.setItem(
                    "is_frontdesk",
                    0
                );

                ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                );
                this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                });
                this.props.history.push("/");
                
            } else {
                this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                });
                ToastDemo(
                    this.props.toastManager,
                    response.data.error,
                    "error"
                );
            }
        })
        .catch((error) => {
            this.setState({ loadingContent: null, buttonDisable: false });
        });
    };


    render() {
    const { inputData,activeBreakdown } = this.state;
    return (
        <div className="page-content">
            <div className="prov-login signup">
                <h1 className=""> Reset Password </h1>
                <form className="top1 prov-login-form" onSubmit={this.handleSubmit}>
                    <div className="form-group input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your new password"
                            onChange={this.handleChange}
                            id="password"
                            value={inputData.password}
                            type="password"
                            name="password"
                            autoComplete="off"
                        />
                        
                    </div>
                    <div class="row">
                        <div class="col-11">
                        <PasswordStrengthBar password={inputData.password} 
                            shortScoreWord='Too Short' 
                            scoreWords={['', '', 'Okay', 'Good', 'Strong']} 
                            minLength={8}
                            onChangeScore={score => this.handlePassword(score)}
                            className="password-package-bar"
                        />
                        <CustomPasswordMeter score={this.state.passwordScore} scoreWords={['Weak', 'Good', 'Strong']}/>
                        </div>
                        <div class="col-1">
                            <div className="BreakdownWrapper">
                                <span onClick={this.passwordClick}>
                                    <i class="far fa-question-circle breakdown-text-color"></i>
                                </span>
                                <div className={`breakdownModal ${activeBreakdown == "password" ? "active" : null}`}>
                                    <div className="bookingBreakdownwrapper column">
                                        <div className="breakdownHeading">
                                            <span className="close" onClick={this.handlebreakdownClose}>
                                                <i class="fas fa-times"></i>
                                            </span>
                                            <p className="breakdownheading">Password Recommendation</p>
                                        </div>
                                        <p className="overview-line1 my-3 "></p>
                                        <div className="breakdownBody">
                                            <div className="taxwrapper">
                                            <ul>
                                                <li>
                                                Atleast 8 characters in length
                                                </li>
                                                <li>
                                                Contains a number and symbol
                                                </li>
                                                <li>
                                                Contains a capital letter and small letters
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>

                    <div className="form-group input-group">
                        <input
                            id="password_confirmation"
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            onChange={this.handleChange}
                            name="password_confirmation"
                        />
                    </div>
                    
                    <button
                        className="pink-btn bottom1 block cmn-btn"
                        disabled={this.state.buttonDisable}
                        >
                        {" "}
                        {this.state.loadingContent != null
                            ? this.state.loadingContent
                            : "Reset Password"}
                    </button>
                </form>
            
                <h4 className="m-0 text-center captalize">
                    Don't have an account?{" "}
                    <Link  to="#"
                    data-toggle="modal"
                    data-target="#signup">
                    {" "}
                    Sign up
                    </Link>
                </h4>
            </div>
            <div className="header-height" />
        </div>
    );
  }
}

export default withToastManager(ResetPassword);
