import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const BannerLoaders = () => {
  return (
    <>
    <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
        <Skeleton count={1} width="100%" height="100vh" />
    </SkeletonTheme>
    </>
  )
}

export default BannerLoaders;