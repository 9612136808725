import React, { Component, useEffect , useState} from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import DatePicker from "react-datepicker";
const $ = window.$;

class EditListingStep7 extends Component {

  state = {
    loading: true,
    data: null,
    formData: {},
    loadingContent: null,
    submitClicked: false,
    displayTimeslot: false,
    displayDailySlot: false,
    response_time:false,
    response_time_hrs: [],
    response_time_mins:[]
  };


  componentDidMount() {
    this.responseHrsGenerate();
    this.responseMinsGenerate();
    this.step7ApiCall();
  }

  
  responseHrsGenerate = () => {
    const hrslimitsArray = [];
    for (let index = 0; index <= 24; index++) {
      hrslimitsArray.push(index);
      this.setState({ response_time_hrs: hrslimitsArray });
    }
  };

  responseMinsGenerate = () => {
    const minslimitsArray = [];
    for (let index = 0; index <= 60; index++) {
      minslimitsArray.push(index);
      this.setState({ response_time_mins: minslimitsArray });
    }
  };

  handleChangeResponseTime = event => {
    const name = event.currentTarget.name;
    const value =   parseInt(event.currentTarget.value);
    const formData = { ...this.state.formData };
    formData[name] =  value;
    this.setState({ formData });
    this.setState({ isInputChanged: true });

  };

  handle7Change = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    console.log("INPUT", input);

    if (input.type === "checkbox") {
      if (input.checked) {
        if (formData[input.name] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          formData[input.name] = array;
          this.setState({ formData });
        } else {
          const value = parseInt(input.value);
          formData[input.name].push(value);
          this.setState({ formData });
        }
      } else {
        const value = parseInt(input.value);
        let index = formData[input.name].indexOf(value);
        if (index !== -1) {
          formData[input.name].splice(index, 1);
          this.setState({ formData });
        }
      }
    }

    if (input.type === "radio") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "text") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "select-one") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "file") {
      formData[input.name] = input.files[0];
      this.setState({ formData });
    }
    // for booking type change.
    setTimeout(() => {
      if (this.state.formData.booking_type != undefined) {
        if (this.state.formData.booking_type == 2) {
          this.setState({ displayTimeslot: true, displayDailySlot: false ,response_time:false});
        } else {
          this.setState({ displayTimeslot: false, displayDailySlot: true ,response_time:false});
        }
      }
      if (this.state.formData.pricing_type != undefined) {
        this.setState({ pricingType: this.state.formData.pricing_type });
      }
    }, 500);
    this.setState({ isInputChanged: true });
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step6/${this.props.match.params.host_id}`
    );
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });

    if (this.state.isInputChanged) {
      api
        .postMethod("hosts_step7_save", this.state.formData)
        .then((response) => {
          if (response.data.success) {
            this.props.history.push(
              `/host/listing-step8/${this.props.match.params.host_id}`
            );
          } else {
            // alert(response.data.error);
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            this.setState({
              loadingContent: null,
              submitClicked: false,
            });
          }
        });
    }

    this.props.history.push(
      `/host/listing-step8/${this.props.match.params.host_id}`
    );
    this.setState({
      loadingContent: null,
      submitClicked: false,
    });
  };

  step7ApiCall = () => {
    api
      .postMethod("hosts_step_7", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            data: response.data.data,
            checkinTimes: response.data.data.checkin_times,
            checkoutTimes: response.data.data.checkout_times,
            formData: {
              host_id: response.data.data.host_id,
              booking_type: response.data.data.booking_type,
              response_time_hrs:response.data.data.response_time_hrs,
              response_time_mins:response.data.data.response_time_mins,
            },
            displayTimeslot:
              response.data.data.booking_type == 2 ? true : false,
            displayDailySlot:
              response.data.data.booking_type == 1 ? true : false,
          });
        } else {
          alert(response.data.error);
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  handleChange = ({ currentTarget: input }) => {
    // event.preventDefault();
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;

    this.setState({ formData });
  };
  render() {
    const {
      data,
      loading,
      formData,
      displayTimeslot,
      displayDailySlot,
      response_time
    } = this.state;

    console.log("response hrs",formData);

    
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 7: Update the booking types
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  <div id="step-9" className="flow-tab">
                    {this.state.loading ? (
                      "Loading"
                    ) : (
                      <div className="add-flow-block row">
                        <div className="col-md-12">
                          <div className="add-flow-block-left">
                            {this.state.loading ? "Loading..." : <></>}
                            <h4 className="flow-tit">
                              Type of booking allowed
                            </h4>

                            <p className="main-txt"></p>
                            <div className="flow-content">
                              <div className="flow-box">
                                <h5 className="flow-box-tit"></h5>
                                <div
                                  className="form-check add-list-block"
                                  key="daily_booking"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="booking_type"
                                    value={1}
                                    onChange={this.handle7Change}
                                    id="daily_booking"
                                    defaultChecked={
                                      this.state.data.booking_type == 1
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="daily_booking"
                                  >
                                    <h5 className="flow-check-tit">
                                      Daily Booking
                                    </h5>
                                    <p className="flow-check-txt text-muted">
                                      Allow only one booking per day, ideal for
                                      banquet halls etc.
                                    </p>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                                <div
                                  className="form-check add-list-block"
                                  key="time_slot_booking"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="booking_type"
                                    value={2}
                                    onChange={this.handle7Change}
                                    id="time_slot_booking"
                                    defaultChecked={
                                      this.state.data.booking_type == 2
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="time_slot_booking"
                                  >
                                    <h5 className="flow-check-tit">
                                      Time slot based booking
                                    </h5>
                                    <p className="flow-check-txt text-muted">
                                      Allow multiple time slots per day, ideal
                                      for indoor play center, community halls
                                      etc.
                                    </p>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                              </div>
                            </div>
                            {displayTimeslot ? (
                              <div>
                                Update your prefered time slots from the
                                listings page later
                              </div>
                            ) : null}
                          </div>

                          {displayDailySlot ? (
                            <div className="flow-content">
                              <div className="form-group large-form-group">
                                <label className="light-txt">Check-in</label>
                                <select
                                  className="form-control"
                                  name="checkin"
                                  onChange={this.handleChange}
                                >
                                  <option>Select Check-in</option>
                                  {this.state.checkinTimes.map((ans) => (
                                    <option
                                      key={ans.key}
                                      value={ans.key}
                                      selected={ans.is_checked ? true : false}
                                    >
                                      {ans.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group large-form-group">
                                <label className="light-txt">Check-out</label>
                                <select
                                  className="form-control"
                                  name="checkout"
                                  onChange={this.handleChange}
                                >
                                  <option>Select Check-out</option>
                                  {this.state.checkoutTimes.map((ans) => (
                                    <option
                                      key={ans.key}
                                      value={ans.key}
                                      selected={ans.is_checked ? true : false}
                                    >
                                      {ans.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : null}
                        </div>

                      </div>
                    )}

                        



<div className="row age-limit-content">
                                <div className="col-md-12">
                                  <label className="light-txt">
                                    Average Response Time
                                  </label>
                                  <br></br>
                                  <small >Note:This is the average time taken by the Provider to Respond over the Chat</small>

                                </div>
                                <div className="col-md-6 resp-mrg-btm-xs">
                                  
                                <select
                                    maxMenuHeight={300}
                                    menuPlacement="auto"
                                    name="response_time_hrs"
                                    className="form-control"
                                    placeholder="From"
                                    onChange={this.handleChangeResponseTime}
                                  >
                                    <option value="">Select Hr</option>
                                    {this.state.response_time_hrs.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          selected={
                                            value == formData.response_time_hrs
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-6">
                                <select
                                    maxMenuHeight={300}
                                    menuPlacement="auto"
                                    name="response_time_mins"
                                    className="form-control"
                                    placeholder="From"
                                    onChange={this.handleChangeResponseTime}
                                  >
                                    <option value="">Select Min</option>
                                    {this.state.response_time_mins.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          selected={
                                            value == formData.response_time_mins
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                
                                </div>
                              </div>









                  </div>

                </div>

                <div className="listing-footer">
                  <button
                    className="white-btn"
                    type="button"
                    onClick={this.handleBackButton}
                  >
                    <i className="fas fa-chevron-left" /> Back
                  </button>
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={this.state.submitClicked}
                    onClick={this.submitForm}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : "Save & Next"}
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditListingStep7;
