import React, { useState, useEffect } from "react";
import HostProfileSideBar from "./hostProfileSideBar";
import api from "../../../HostEnvironment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";
import HostAddCardForm from "./hostAddCardForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import configuration from "react-global-configuration";
import { apiConstants } from "../../Constant/constants";

const HostCard = (props) => {
  const [state, setState] = useState({
    data: [],
    loading: true
  });
  
  useEffect(() => {
    listCardApi();
  }, []);

  const stripePromise = loadStripe(apiConstants.STRIPE_PUBLISHABLE_KEY);

  const [addCard, setAddCard] = useState(false);

  const closeAddCardModal = () => {
    setAddCard(false);
  };

  const [paymentAddCard, setPaymentAddCard] = useState(false);

  const closePaymentAddCardModal = () => {
    setPaymentAddCard(false);
  };

  const listCardApi = () => {
    api.postMethod("cards_list").then(response => {
      if (response.data.success) {
        setState({
          ...state,
          data: response.data.data,
          loading: false
        });
      } else {
      }
    });
  }

  const setDefaultCard = (event, card) => {
    event.preventDefault();
    api
      .postMethod("cards_default", { provider_card_id: card.provider_card_id })
      .then(response => {
        if (response.data.success) {
          ToastDemo(props.toastManager, response.data.message, "success");
          listCardApi();
        } else {
          ToastDemo(props.toastManager, response.data.error, "error");
        }
      });
  };

  const deleteCard = (event, card) => {
    event.preventDefault();
    api
      .postMethod("cards_delete", { provider_card_id: card.provider_card_id })
      .then(response => {
        if (response.data.success) {
          ToastDemo(props.toastManager, response.data.message, "success");
          listCardApi();
        } else {
          ToastDemo(props.toastManager, response.data.error, "error");
        }
      });
  };
    return (
      
      // <StripeProvider apiKey="pk_test_uDYrTXzzAuGRwDYtu7dkhaF3">
      //   <Elements>
          <div className="main">
            <div className="site-content">
              <div className="top-bottom-spacing">
                <div className="row">
                  <HostProfileSideBar />
                  <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                    <form>
                      <div className="panel">
                        <div className="panel-heading">Payment Methods </div>
                        <div className="panel-body account pt-3">
                          <div className="row">
                            {state.loading
                              ? ""
                              : state.data.cards.map(card => (
                                  <div
                                    className="col-sm-12 col-md-6 col-lg-6 col-xl-4 top"
                                    key={card.provider_card_id}
                                  >
                                    <div className="payment-box">
                                      <h5>XXXX XXXX XXXX {card.last_four}</h5>
                                      <div className="payment-bottom">
                                        {card.is_default == 1 ? (
                                          <p className="captalize m-0">
                                            default card
                                            <img
                                              src="../assets/img/credit-card.png"
                                              className="credit-img"
                                            />
                                          </p>
                                        ) : (
                                          <div>
                                            <Link
                                              to="#"
                                              onClick={event =>
                                                setDefaultCard(event, card)
                                              }
                                            >
                                              <p className="red-text1 m-0">
                                                set as default
                                              </p>
                                            </Link>
                                            <Link
                                              to="#"
                                              onClick={event =>
                                                deleteCard(event, card)
                                              }
                                            >
                                              <p className="captalize m-0">
                                                remove
                                                <img
                                                  src="../assets/img/credit-card.png"
                                                  className="credit-img"
                                                />
                                              </p>{" "}
                                            </Link>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 top">
                              <a
                                href="#"
                                onClick={() => setPaymentAddCard(true)}
                              >
                                <div className="payment-box text-center">
                                  {/* <i className="material-icons big-icon">add</i> */}
                                  
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#484848" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                                  </svg>
                                  <h4 className="captalize top">
                                    Add a payment method
                                  </h4>
                                </div>
                              </a>
                            </div>
                          </div>
                          <Elements stripe={stripePromise}>
                          <HostAddCardForm paymentAddCard={paymentAddCard} />
                          </Elements>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      //   </Elements>
      // </StripeProvider>
    );
};

export default withToastManager(HostCard);
