import React, { Component } from "react";



class PromoCodeDetails extends Component {
    state = {};
    render() {

        const { details, promoCodeApiResponse } = this.props;
        console.log("promoCodeApiResponse",this.props);

        return (

            <div className={`breakdownModal ${this.props.activeBreakdown == "promocode" ? "active" : null}`}>
                        <div className="bookingBreakdownwrapper column">
                          <div className="breakdownHeading">
                            <span className="close" onClick={this.props.handlebreakdownClose}>
                              <i class="fas fa-times"></i>
                            </span>
                            <p className="breakdownheading">Promo Code Information</p>
                          </div>
                          <p className="overview-line1 my-3 "></p>
                          <div className="breakdownBody">
                              <div className="taxwrapper">
                                <span>
                                <h4>Used Code </h4>
                                </span>
                                <span>
                                   {promoCodeApiResponse.promo_code_details.promo_code}
                                </span>
                              </div>

                          </div>

                          <p className="overview-line1 my-3 "></p>
                          <div className="breakdownBody">
                              <div className="taxwrapper">
                                <span>
                                 <h4>Calculation</h4> 
                                </span>
                                <span>
                                   {promoCodeApiResponse.request_amount} - 

                                   {promoCodeApiResponse.promo_amount_formatted}
                                </span>
                              </div>

                          </div>


                          <p className="overview-line1 my-3 "></p>
                          <div className="breakdownBody">
                              <div className="taxwrapper">
                                <span>
                                   {promoCodeApiResponse.promo_code_details.description}
                                </span>
                              </div>

                          </div>

                          <p className="overview-line1 my-3 "></p>
                          <div className="breakdownFooter">
                            <>
                                <span>
                                  Total Payable
                                </span>
                                <span>
                                  {promoCodeApiResponse.actual_total_formatted}
                                </span>
                              </>
                          </div>
                        </div>
                      </div>


        );
    }
}

export default PromoCodeDetails;
