import React from "react";

const CustomPasswordMeter = (props) => {
  return (
    <>
      <div className="custom-password-meter">
        <div className={`passwordbar ${props.score == 1 ? "weak" : ""} ${props.score > 1 && props.score <= 2 ? "good" : ""}  ${props.score >=3  ? "strong" : ""}`}></div>
        <div className={`passwordbar ${props.score > 1 && props.score <= 2 ? "good" : ""}  ${props.score >=3  ? "strong" : ""}`}></div>
        <div className={`passwordbar  ${props.score >=3  ? "strong" : ""}`}></div>
        {/* {props.scoreWords.map((scorewords , index) => (
          <div className={`passwordbar ${props.score == 1 && props.scoreWords[index] == scorewords && index < 1 ? "weak" : ""} ${props.score > 1 && props.score <= 2 && index < 2  ? "good" : ""}  ${props.score >=3  ? "strong" : ""}`}></div>
        ))} */}
      </div>
      <p className="text-right custom-password-meter-text">{props.score <= 1 ? props.scoreWords[0] : props.score <= 2 ? props.scoreWords[1] : props.score >= 3 ? props.scoreWords[2] : null }</p>
    </>
  );
};

export default CustomPasswordMeter;
