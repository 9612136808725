import React, { Component } from "react";
import ProfileSideBar from "../../Helper/profileSideBar";
import Helper from "../../Helper/Helper";
import { Link } from "react-router-dom";
import api from "../../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";

class Notification extends Helper {
  state = {
    data: null,
    loading: true,
    inputData: {}
  };

  componentDidMount() {
    this.getUserDetails();
  }

  handleChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    console.log(input);
    
    if(input.type == "checkbox") {
      if (input.checked) {
        inputData["type"] = input.name;
        inputData["status"] = 1;
      } else {
        inputData["type"] = input.name;
        inputData["status"] = 0;
      }
    } else {
      inputData[input.name] = input.value;
    }
    this.setState({ inputData });
    this.notificationUpdateApiCall(inputData);
  };

  notificationUpdateApiCall = inputData => {
    
    api.postMethod("notification/settings", inputData).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({  inputData: {} });
      } else {
        this.errorCodeChecker(response.data.error_code);
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  render() {
    const { loading, data } = this.state;
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <ProfileSideBar />

              {loading ? (
                "Loading..."
              ) : (
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                  <form>
                    <div className="panel">
                      <div className="panel-heading">Messages</div>
                      <div className="panel-body account">
                        <h5 className="m-0 captalize lh-1-4">
                          receive messages from hosts and guests, including
                          booking requests.
                        </h5>
                        <p className="overview-line" />

                        <label className="form-checkbox">
                          email
                          <input
                            type="checkbox"
                            onChange={this.handleChange}
                            name="email"
                            defaultChecked={data.email_notification_status}
                          />
                          <span className="checkmark" />
                        </label>

                        <label className="form-checkbox">
                          notifications
                          <input
                            type="checkbox"
                            onChange={this.handleChange}
                            name="push"
                            defaultChecked={data.push_notification_status}
                          />
                          <span className="checkmark" />
                        </label>
                        {/* <h5 className="profile-note ml-35">
                          To your mobile or tablet device
                        </h5> */}

                       
                        <h5 className="flow-box-tit">
                          All the email notifications will be send to the below email preferences.
                        </h5>
                        
                          <div
                            className="form-check add-list-block"
                            key="primary_email"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notification_email"
                              value="1"
                              onChange={this.handleChange}
                              id="primary_email"
                              defaultChecked={
                                data.notification_email == data.primary_email
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label row"
                              htmlFor="primary_email"
                            >
                              <h5 className="flow-check-tit">Primary Email</h5>
                              
                            </label>
                            <div className="clear-both" />
                          </div>
                          <div
                            className="form-check add-list-block"
                            key="secondary_email"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="notification_email"
                              value="2"
                              id="secondary_email"
                              onChange={this.handleChange}
                              defaultChecked={
                                data.notification_email == data.secondary_email
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label row"
                              htmlFor="secondary_email"
                            >
                              <h5 className="flow-check-tit">Secondary Email</h5>
                             
                            </label>
                            <div className="clear-both" />
                          </div>
                       
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(Notification);
