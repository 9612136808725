import React from "react";
import { useState, useEffect } from "react";

const InputSelect = (props) => {
  const [showOptions, setShowOptions] = useState(false);

  const [selectedOption, setSelectedOption] = useState(props.defaultSelect);

  const handleToggleOptions = (status) => {
    setShowOptions(status);
  };

  const handleSelecetedOption = (value) => {
    setSelectedOption(value);
    setShowOptions(false);
  };

  return (
    <>
      <div className={props.className} >
        <div
          className={`custom-select-alike ${showOptions ? "open" : ""}`}
          onClick={() => handleToggleOptions(true)}
        >
            <input
                type="text"
                id="promo_code"
                placeholder={selectedOption != null ? selectedOption : props.placeHolder}
                onChange={props.handleInputChange}
                name="promo_code"
                // value={props.promoCodeInputData.promo_code}
            />         
        </div>
        {showOptions && (
          <>
            <div className="options-wrapper">
              <div
                className="options-backdrop"
                onClick={() => handleToggleOptions(false)}
              ></div>
              <div className="options">
                {props.data.map((promocodes, index) => (
                  <p
                    key={promocodes.value}
                    className={""}
                    onClick={() => {
                            props.handleChange(promocodes.value);
                            handleSelecetedOption(promocodes.label);
                        }
                    }
                  >
                    {promocodes.label} 
                  </p>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InputSelect;
