import React, { Component } from "react";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import Helper from "../../Helper/Helper";

class HostRulesandPolicies extends Helper {
  state = {
    buttonDisable: false,
    data:{},
    hostDetails: [],
    loadingData: true,
  };

  componentDidMount() {
    const formData = {
        host_id: this.props.match.params.id,
      };
      this.getHostDetailsApiCall(formData);
  }


  getHostDetailsApiCall(formData) {
    api.postMethod("hosts_view", formData).then((response) => {
      if (response.data.success === true) {
        this.setState({
          hostDetails: response.data.data,
          loadingData: false,
        });
      } else {
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
        ToastDemo(
            this.props.toastManager,
            response.data.error,
            "error"
        );
        this.props.history.goBack();
      }
    });
  }



  render() {
    const { loadingData} = this.state;

    console.log('host details',this.state.hostDetails);
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              {loadingData ? (
                "Loading..."
              ) : (
                this.state.hostDetails.other_house_rules.length > 0  && this.props.match.params.type == 1 ? (
                  <div className="cancellationPolicy">
                      <p className="overview-line" />
                      <h4 className="single-cat-text medium-cls ">
                        House Rules
                      </h4>
                      {this.state.hostDetails.other_house_rules.map((houseRule) => (
                        <div className="d-flex align-items-center my-2 singlePolicies">
                           <span className="mr-3"><i class="far fa-dot-circle"></i></span>
                            <h4 className="single-cat-text mb-0">
                              {houseRule.content}
                            </h4>
                        </div>
                      ))}
                  </div>
                ) : null
              )}


             {loadingData ? (
                "Loading..."
              ) : (
                this.state.hostDetails.other_cancellation_policies.length > 0  && this.props.match.params.type == 2 ? (
                  <div className="cancellationPolicy">
                      <p className="overview-line" />
                      <h4 className="single-cat-text medium-cls ">
                        cancellation Policy
                      </h4>
                      {this.state.hostDetails.other_cancellation_policies.map((cancelPolicy) => (
                        <div className="d-flex align-items-center my-2 singlePolicies">
                           <span className="mr-3"><i class="far fa-dot-circle"></i></span>
                            <h4 className="single-cat-text mb-0">
                              {cancelPolicy.content}
                            </h4>
                        </div>
                      ))}
                  </div>
                ) : null
              )}

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HostRulesandPolicies;
