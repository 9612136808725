import React, { Component } from "react";
import CalForm from "../Helper/calForm";
import FaqSection from "../Helper/faqSection";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import configuration from "react-global-configuration";
import { PopupButton } from "react-calendly"
const $ = window.$;

class BecomeAHost extends Component {
  
  constructor(props){
    super(props);
    this.state = { 
      videoModal : false
    };
  }
  state = {};
  componentDidMount() {
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.error,
        "error"
      );
    }
  }

  handleVideoPopup = (status) => {
    this.setState({videoModal : status})
  }

  handleloginModal = () => {
    $("#hostsignup").modal("show");
    // this.props.toggleLoginModal(true);
  }

  render() {
    console.log("Configurations",configuration.get("configData.site_video_url"));

    return (

      <div>
        <div
          className="host-banner-sec"
          style={{
            backgroundImage: `url('../../../assets/img/provider-new.jpg')`,
          }}
        >
          <div className="host-banner-sec-overlay">
            <div className="site-content">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-5 host-banner-aligncenter">
                  <div className="margin-bottom-add">
                    <h2 className="host-banner-subhead">
                      host on {configuration.get("configData.site_name")}.
                    </h2>
                    <h1 className="host-banner-head">
                      Elevate your Business with {" "}
                      {configuration.get("configData.site_name")}.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="host-details-sec banner-mobileview">
          <div className="host-details-head">
            <h2>Find out what top hosts earn in your area</h2>
          </div>
          <form className="host">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="bangaluru"
              />
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>4 guests</option>
                <option>1 guests</option>
                <option>2 guests</option>
                <option>3 guests</option>
                <option>4 guests</option>
              </select>
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>Entire Place</option>
                <option>Private Place</option>
                <option>Shared Room</option>
              </select>
            </div>
            <div>
              <h1 className="amount">₹18,296</h1>
              <h4 className="amount-subhead">per month</h4>
            </div>
            <div className="mt-4">
              <button className="pink-btn">Get started</button>
            </div>
          </form>
        </div> */}

        <div className="main">
          <div className="provider-features">
            <div className="site-content">
              <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6 co-xl-6 px-4">
                    <div className="featuresWrapper">
                      <h2 className="features-head">
                        Why host on {configuration.get("configData.site_name")}?
                      </h2>
                      <h4 className="features-para">
                        No matter what kind of party related spaces or service you offer, 
                        {" "}{configuration.get("configData.site_name")} makes it simple
                        to list your property, manage bookings, pricing and availability, all through an easy to use secure platform.<br></br>

                        <br></br>
                        Register your property at {configuration.get("configData.site_name")} and reach millions of customers online!
                      </h4>
                    </div>
                  </div>
                <div className="col-sm-12 col-md-12 col-lg-6 co-xl-6">
                 {/* <iframe  className="fetureVideoWrapper" height="315" allowFullScreen={true}
                  src={configuration.get("configData.site_video_url")}>
                </iframe> */}
                <div class="videoPopup">
                    <iframe className="fetureVideoWrapper"  src={configuration.get("configData.site_video_url")} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div class="videoPopup-overlay "  src={configuration.get("configData.site_video_url")} onClick={() => this.handleVideoPopup(true)}></div>
                </div>
                </div>

                {this.state.videoModal && (
                  <div class="modal fade show d-block" id="hostvideoModal" tabindex="-1" role="dialog" aria-labelledby="hostvideoModal" aria-hidden="true" onClick={() => this.handleVideoPopup(false)}> 
                    <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div class="embed-responsive embed-responsive-16by9">
                          <iframe class="embed-responsive-item"  src={`${configuration.get("configData.site_video_url")}?autoplay=1`} 
                            frameborder="0"  allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                )}



                {/* <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  <h2 className="features-head">You’re in control</h2>
                  <h4 className="features-para">
                    With {configuration.get("configData.site_name")}, you’re in
                    full control of your availability, prices, house rules, and
                    how you interact with guests. You can set check-in times and
                    handle the process however you like.
                  </h4>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  <h2 className="features-head">We’re there at every step</h2>
                  <h4 className="features-para">
                    {configuration.get("configData.site_name")} offers tools,
                    hospitality tips, 24/7 support, and an online community of
                    experienced hosts for questions and sharing ideas for
                    success.
                  </h4>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="site-content">
          <div className="host-top-bottom-spacing">
            <div className="host-section-head">
              <h1>
                How to be a {configuration.get("configData.site_name")} host
              </h1>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mob-listing-view">
                <img
                  src="../assets/img/listing1.jpg"
                  className="listing-img"
                  alt="Listing1"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="media">
                  <h1 className="count">1</h1>
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>Create your listing</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                        It’s absolutely free and easy to list your property or event related service on {" "}
                        {configuration.get("configData.site_name")}. Describe
                        your property or service, set the price, add photos and other details
                      </p>
                    </div>
                    {/* <div className="listings-para">
                      <p>
                        Our pricing tool can recommend competitive rates, but
                        what you charge is always up to you.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 listing-view text-right">
                <img
                  src="../assets/img/listing1.jpg"
                  className="listing-img"
                  alt="Listing 1"
                />
              </div>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img
                  src="../assets/img/listing2.jpg"
                  className="listing-img"
                  alt="Listing 2"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="media">
                  <h1 className="count">2</h1>
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>Verify your listing</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                       Once the listing is submitted, our verification team will review the listing and contact you. After the approval, your property is ready to be viewed by millions.
                      </p>
                    </div>
                    {/* <div className="listings-para">
                      <p>
                        Our pricing tool can recommend competitive rates, but
                        what you charge is always up to you.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mob-listing-view">
                <img
                  src="../assets/img/listing1.jpg"
                  className="listing-img"
                  alt="Listing 3"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="media">
                  <h1 className="count">3</h1>
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>You are in Control</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                         You can chat with the guests, accept/reject booking requests, set availablility, price and any other offers.
                      </p>
                    </div>
                    {/* <div className="listings-para">
                      <p>
                        Our pricing tool can recommend competitive rates, but
                        what you charge is always up to you.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 listing-view text-right">
                <img
                  src="../assets/img/listing3.jpg"
                  className="listing-img"
                  alt="Listing 4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="site-content">
          <div className="host-top-bottom-spacing">
            {/* <div className="host-section-head">
              <h1>Need help with listing ?</h1>
            </div> */}

            <div className="row justify-content-between align-items-center">
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <div className="media safety">
                <div className="host-section-head mb-0">
                  <h1>Need help with listing ?</h1>
                </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <p className="mb-0">
                    If you need any help regarding listing your property or service with us, please feel free to contact us.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <div className="media safety">
                  <div className="media-body">
                  <div id="text-center">
                    <PopupButton
                      className="hostlanding-register-btn"
                      text="Schedule"
                      url="https://calendly.com/partybudhasupport"
                    />
                  </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/safety-icon1.png"
                    alt="safety-icon"
                    className="mr-3 rounded-circle review-img"
                  />
                  <div className="media-body">
                    <h2>₹6,00,00,000 Host Guarantee</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/safety-icon2.png"
                    alt="safety-icon"
                    className="mr-3 rounded-circle review-img"
                  />
                  <div className="media-body">
                    <h2>Host Protection Insurance</h2>
                    <p>
                      If your guests get hurt or cause property damage, our Host
                      Protection Insurance protects you from liability claims up
                      to a million dollars, included free for every{" "}
                      {configuration.get("configData.site_name")}
                      host.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/safety-icon3.png"
                    alt="safety-icon"
                    className="mr-3 rounded-circle review-img"
                  />
                  <div className="media-body">
                    <h2>
                      {configuration.get("configData.site_name")} is built on
                      trust
                    </h2>
                    <p>
                      All {configuration.get("configData.site_name")} travelers
                      must submit a profile photo and verify their phone &
                      email. Hosts can also require a government ID. Guests and
                      hosts each publish reviews after check out keeping
                      everyone accountable and respectful.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <FaqSection />

        <div
          className="host-footer-img"
          style={{
            backgroundImage: `url('../../../assets/img/footer.jpg')`,
          }}
        >
          <div className="site-content">
            <div className="row">
              <div className="col-sm-4 col-md-5 col-lg-6 col-xl-7">
              </div>
              <div className="col-sm-8 col-md-7 col-lg-6 col-xl-5">
                <div className="host-footer-content">
                  <div>
                    <div className="host-footer-content-head">
                      <h1>Start creating your listing</h1>
                    </div>
                    <button onClick={this.handleloginModal} className="pink-btn">
                      Get started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(BecomeAHost);
