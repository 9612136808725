import React, { useState, useEffect } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import {
    Form,
    Modal,
    InputGroup,
    FormControl,
    Col,
    Row,
    Button,
  } from "react-bootstrap";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const $ = window.$;

const HostAddCardForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [addCardButtonDisable, setAddCardButtonDisable] = useState(false);
    const [addCardLoadingContent, setAddCardLoadingContent] = useState(null);
  
    const addCard = async (ev) => {
      ev.preventDefault();
      setAddCardButtonDisable(true);
      setAddCardLoadingContent("Loading... Please wait");
      if (stripe) {
        await stripe
          .createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
          })
          .then((payload) => {
            // console.log("Payload", payload);
            const inputData = {
              card_token: payload.paymentMethod.id,
            };
            api
              .postMethod("cards_add", inputData)
              .then((response) => {
                if (response.data.success) {
                  ToastDemo(props.toastManager, response.data.message, "success");
  
                  setAddCardButtonDisable(false);
                  setAddCardLoadingContent(null);
                  window.location = "/host/payment";
                } else {
                  ToastDemo(props.toastManager, response.data.error, "error");
                }
                props.closePaymentAddCardModal();
              })
              .catch((error) => {
                console.log("Error", error);
                setAddCardButtonDisable(false);
                setAddCardLoadingContent(null);
                ToastDemo(props.toastManager, error, "error");
              });
          })
          .catch((error) => {
            console.log("Eroor", error);
            setAddCardButtonDisable(false);
            setAddCardLoadingContent(null);
            ToastDemo(props.toastManager, "Please check your card details and try again..", "error");
          });
      } else {
        setAddCardButtonDisable(false);
        setAddCardLoadingContent(null);
        ToastDemo(props.toastManager, "Stripe is not configured", "error");
      }
    };
  
    return (
      <>
        <Modal
          className="modal-dialog-center payment-add-card-modal"
          size="md"
          centered
          show={props.paymentAddCard}
          onHide={props.closePaymentAddCardModal}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Add Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={12} md={7}>
                  <CardElement />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-danger width-btn"
                data-dismiss="modal"
                onClick={props.closePaymentAddCardModal}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                className="btn btn-success width-btn"
                data-dismiss="modal"
                onClick={addCard}
                disabled={addCardButtonDisable}
              >
                {addCardLoadingContent != null ? addCardLoadingContent : "Add"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  };

export default withToastManager(HostAddCardForm);
