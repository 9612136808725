import React, { Component, useState } from "react";
import Helper from "../../Helper/Helper";
import io from "socket.io-client";
// import openSocket from "socket.io-client";
import api from "../../../Environment";
import moment from 'moment';

import { apiConstants } from "./../../Constant/constants";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import ToastDemo from "../../Helper/toaster";
import {
  Form,
  Modal,
  InputGroup,
  FormControl,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import { withToastManager } from "react-toast-notifications";
import ProfileInput from "../../Helper/profileInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
var yesterday = moment().subtract(1, 'day');
var valid = function (current) {
  return current.isAfter(yesterday);
};

const onlyMonth = {
  month: "numeric",
};
const onlyYear = {
  year: "numeric",
};

const $ = window.$;

var today = new Date();
today.setDate(today.getDate());

const CustomPriceModal = (props) => {
  const [inputData, setInputData] = useState({
    user_id: localStorage.getItem("userId"),
    host_id: props.priceCalInput.host_id,
    date: '',
  });

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data_array = [];
    console.log(selectedFiles);
    const files = Array.from(selectedFiles);
    console.log(files);
    files.forEach((file, key) => {
      console.log(file);
      let name = 'file[' + key + ']';

      data_array[name] = file;

    });
    data_array['message'] = inputData.message;
    data_array['user_id'] = inputData.user_id;
    data_array['host_id'] = inputData.host_id;
    data_array['date'] = inputData.date;

    api.postMethod("custom_price_request_save", data_array).then((response) => {
      if (response.data.success) {
        // console.log(response.data);
        // ToastDemo(props.toastManager, response.data.message, "success");
        window.location.assign("/custom-price-confirmation");
      } else {
        ToastDemo(props.toastManager, response.data.error, "error");
        return false;
      }
    });
  };

  const getDefaultLeavingTime = () => {
    var oldDateObj = new Date();
    var newDateObj = new Date();
    newDateObj.setTime(oldDateObj.getTime() + 60 * 60 * 1000);

    return dayjs(newDateObj).format("YYYY-MM-DD HH:mm");
  };
  const getDefaultArravingTime = (checkin) => {
    return new Date(checkin);
  };

  const getArrivingDateandTime = (date) => {
    const datess = dayjs(date).format("DD-MM-YYYY");
    setInputData({
      ...inputData,
      date: datess,
      date_formatted : date,
    });
  };

  const handleChangeImage = (event) => {
    setSelectedFiles(event.target.files);
    // if (event.currentTarget.type === "file") {
    //   let reader = new FileReader();
    //   let file = event.currentTarget.files[0];

    //   reader.onloadend = () => {
    //     setInputData({
    //       ...inputData,
    //       file: file,
    //       file_type: file.type.match("image") ? "image" : "document",
    //     });
    //   };

    //   if (file) {
    //     reader.readAsDataURL(file);
    //   }
    // }
  };

  return (
    <>
      <Modal
        className="modal-dialog-center payment-add-card-modal"
        size="lg"
        centered
        show={props.requestCustomPricingModel}
        onHide={props.handleCustomPricingModal}
      >
        {props.requestCustomPricingModel ? (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Request Custom Quote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={12} md={12}>
                  <form onSubmit={handleSubmit}>
                    <div className="custom-pricing-form">
                      <div className="account add-promo-code">
                        {/* <ProfileInput
                          label="Message"
                          type="text"
                          placeholder="Message"
                          id="message"
                          name="message"
                          value={inputData.message}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              message: event.currentTarget.value,
                            });
                          }}
                          description=""
                        /> */}
                        <div className="form-group row">
                          <div className="col-12">
                            <label htmlFor="message">
                              Describe the service you're looking for.
                            </label>
                          </div>
                          <div className="col-12">
                            <textarea
                              type="text"
                              className="form-control"
                              rows="4"
                              placeholder="Please provide your detailed requirement. Include all the necessary details, attach reference images if any."
                              id="message"
                              name="message"
                              value={inputData.message}
                              onChange={(event) => {
                                setInputData({
                                  ...inputData,
                                  message: event.currentTarget.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        {/* <ProfileInput
                          label="Amount"
                          type="text"
                          placeholder="Amount"
                          id="amount"
                          name="amount"
                          value={inputData.amount}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              amount: event.currentTarget.value,
                            });
                          }}
                          description=""
                        /> */}

                        {/* <div className="form-group row">
                          <div className="col-3 text-right">
                            <label>Date</label>
                          </div>
                          <div className="col-9">
                            <DatePicker
                              initialDate={getDefaultArravingTime()}
                              onChange={getArrivingDateandTime(
                                "Range DatePicker"
                              )}
                              showDefaultIcon
                              placeholder
                            />
                          </div>
                        </div> */}
                        <div className="form-group row">
                          <div className="col-12">
                            <label>Event Date</label>
                          </div>
                          <div className="col-12">
                           <div className="custom-select-alike">
                            <DatePicker
                              minDate={today}
                              selected={inputData.date ? getDefaultArravingTime(inputData.date_formatted) : ''}
                              onChange={getArrivingDateandTime}
                              excludeDates={[today]}
                              placeholderText="Click to select a date"
                            />
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-12">
                            <label>Attach Referance Images</label>
                          </div>
                          <div className="col-12">
                            <input
                              type="file"
                              name="picture"
                              multiple
                              accept="image/*,.pdf,.doc"
                              onChange={(event) => handleChangeImage(event)}
                              className="grey-outline-btn bold-cls w-100 text-center bottom"
                            />
                          </div>
                          <p>Note: You can choose multiple images.</p>
                        </div>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-danger width-btn"
                data-dismiss="modal"
                onClick={props.handleCustomPricingModal}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                className="btn btn-success width-btn"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Request
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default withToastManager(CustomPriceModal);
