/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import TripBanner from "../../User/TripDetails/tripBanner";
import TripBookingForm from "../../User/TripDetails/tripBookingForm";
import TripReviewSection from "../../User/TripDetails/tripReviewSection";
import TripHostSection from "../../User/TripDetails/tripHostSection";
import TripShowAllAmen from "../../User/TripDetails/tripShowAllAmen";
import api from "../../../Environment";
import hostAPI from "../../../HostEnvironment";
import TripLocation from "../../User/TripDetails/TripLocation";
import renderHTML from "react-render-html";
import Slider from "react-slick";
import Loader from "../../Helper/Loader";
import { RangeDatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import GetAvailability from "../../Helper/getAvailability";
import TripAddonServices from "../../User/TripDetails/tripAddonServices";
import ToastDemo from "../../Helper/toaster";
import TripGallery from "../../User/TripDetails/tripGallery";
import TripPackagePricing from "../../User/TripDetails/TripPackagePricing";
import moment from 'moment';
import TripAddOnSerAddModel from "../../User/TripDetails/TripAddOnSerAddModel";
import ChatModal from "../../User/TripDetails/ChatModal";
import dayjs from "dayjs";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const $ = window.$;

class HostSingleTripView extends Component {
  state = {
    singleTripDetails: null,
    loading: true,
    formData: {
      host_id: this.props.match.params.id,
    },
    priceCalInput: {
      adults: 0,
      children: 0,
      infants: 0,
      checkin: "",
      checkout: "",
      host_id: this.props.match.params.id,
      age_special: 0,
      selectedAmentites : 0,
      addedAmentity : {
        name : "",
        value : ""
      },
      amentityValue : "",
      user_name:"",
      email:"",
      mobile:""
    },
    loadingBookingForm: true,
    total_days_price: null,
    basicDetails: null,
    apiResponse: null,
    apiCallStatus: false,
    providerDetails: null,
    buttonDisableBoBookingForm: false,
    buttonLoadingContentBookingForm: null,
    promoCodeInputData: {},
    promoCodeApiResponse: null,
    loadingPromoCode: true,
    addonServicesData: null,
    loadingAddonService: true,
    addOnServiceModelData: null,
    loadingAddOnServiceModelData: true,
    addOnSerModelAddButtonDisable: false,
    addOnSerModelAddLoadingContent: null,
    timeSlotLoading: true,
    timeSlotData: null,
    min_guests: 1,
    max_guests: 1,
    activeBreakdown : null,
    users_list:[],
    pricingPackagesLoading: true,
    pricingPackagesData: [],
  };

  componentDidMount() {

    const formData = {
      host_id: this.props.match.params.id,
    };
    this.singleTripViewApiCalls(formData);
    this.getUsersListApiCall();
    this.getPricingPackages(formData);

  }

  displaySimilarTrip = (host_id) => {
    const formData = {
      host_id: host_id,
    };
    this.setState({
      loading: true,
      singleTripDetails: null,
      priceCalInput: {
        adults: 0,
        children: 0,
        infants: 0,
        checkin: null,
        checkout: null,
        host_id: null,
      },
    });

    this.singleTripViewApiCall(formData);
  };

  setHostId = () => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[
      "adults"
    ] = this.state.singleTripDetails.basic_details.min_guests;
    priceCalInput[
      "host_id"
    ] = this.state.singleTripDetails.basic_details.host_id;
    this.setState({
      priceCalInput,
      min_guests: this.state.singleTripDetails.basic_details.min_guests,
      max_guests: this.state.singleTripDetails.basic_details.max_guests,
    });
  };

  getPromoCode = ({ currentTarget: input }) => {
    const promoCodeInputData = { ...this.state.promoCodeInputData };
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[input.name] = input.value;
    promoCodeInputData[input.name] = input.value;
    this.setState({ promoCodeInputData });
    this.setState({ priceCalInput });
  };

  removePromoCode = (event) => {
    event.preventDefault();
    this.setState({
      promoCodeInputData: {},
      promoCodeApiResponse: null,
      loadingPromoCode: true,
      buttonLoadingContentBookingForm: null,
      buttonDisableBoBookingForm: false,
      loadingBookingForm: false,
    });
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["promo_code"] = "";
    this.setState({ priceCalInput });
  };

  checkPromoCode = (event) => {
    console.log(this.state.apiResponse);
    event.preventDefault();
    const promoCodeInputData = { ...this.state.promoCodeInputData };
    promoCodeInputData[
      "host_id"
    ] = this.state.singleTripDetails.basic_details.host_id;
    promoCodeInputData["amount"] = this.state.apiResponse.actual_total_formatted.replace(
    "$",
    ""
    );
    this.setState({ promoCodeInputData });
    setTimeout(() => {
      this.promoCodeAPICall();
    }, 1000);
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
    });
  };

  promoCodeAPICall = () => {
    api
      .postMethod("apply_promo_code", this.state.promoCodeInputData)
      .then((response) => {
        console.log("response", response);
        if (response.data.success) {
          this.setState({
            loadingBookingForm: false,
            promoCodeApiResponse: response.data.data,
            loadingPromoCode: false,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
          });
          this.priceCalcultor();
          ToastDemo(
            this.props.toastManager,
            "Promo Code valid, Applied successfully",
            "success"
          );
        } else {
          const priceCalInput = { ...this.state.priceCalInput };
          priceCalInput["promo_code"] = "";
          this.setState({ priceCalInput });
          this.setState({
            promoCodeApiResponse: null,
            loadingPromoCode: true,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            loadingBookingForm: false,
          });
          ToastDemo(this.props.toastManager, response.data.error, "error");
          return false;
        }
      });
  };

  priceCalcultor = () => {
    const priceCalInput = this.state.priceCalInput;
    if (
      priceCalInput.adults !== 0 &&
      priceCalInput.checkin != null &&
      priceCalInput.checkout != "NaN-aN-aN"
    ) {
      this.priceCal();
      this.setState({
        buttonDisableBoBookingForm: true,
        buttonLoadingContentBookingForm: "Loading... Please wait",
      });
    }
  };

  priceCal = () => {
    api
      .postMethod("host_price_calculator", this.state.priceCalInput)
      .then((response) => {
        console.log("response", response);
        if (response.data.success) {
          const priceCalInput = { ...this.state.priceCalInput };

          priceCalInput["adults"] =
            priceCalInput["adults"] > response.data.data.min_guests
              ? priceCalInput["adults"]
              : response.data.data.min_guests;
          this.setState({
            priceCalInput,
            loadingBookingForm: false,
            total_days_price: response.data.data.total_days_price,
            apiResponse: response.data.data,
            apiCallStatus: true,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            min_guests: response.data.data.min_guests,
            max_guests: response.data.data.max_guests,
          });
        } else {
          this.setState({
            loadingBookingForm: true,
            total_days_price: null,
            apiResponse: null,
            apiCallStatus: false,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            toastContent: response.data.error,
            toastType: "error",
          });
          ToastDemo(this.props.toastManager, response.data.error, "error");
          return false;
        }
      });
  };

  getUsersListApiCall = () => {
   
    hostAPI
      .postMethod("search_users")
      .then((response) => {
        if (response.data.success) {
          
          this.setState({
            users_list: response.data.data.users_list,
            loadingUsers: false,
          });
        } 
      });

     
  };


  bookNow = (event) => {
    event.preventDefault();
    if (!localStorage.getItem("userLoginStatus")) {     
      ToastDemo(
        this.props.toastManager,
        "Please login to continue",
        "error"
      );
    } else {
      if (this.state.apiCallStatus) {
        console.log("After API reponse");
        this.props.history.push("/checkout", this.state);
      } else {
        ToastDemo(
          this.props.toastManager,
          "Please select all the details, Try again",
          "error"
        );
      }
    }
    console.log("Book now Clicked");
  };

  increament = (value) => {
    // console.log("Increamenting", value);
    const priceCalInput = { ...this.state.priceCalInput };

    $("#guest-mobile-btn").dropdown("toggle");

    if (value === "adults") {
      let adultsCount =
        this.state.priceCalInput.adults >= this.state.min_guests
          ? this.state.priceCalInput.adults + 1
          : this.state.min_guests;
      if (adultsCount >= this.state.max_guests) {
        adultsCount = this.state.max_guests;
      }
      priceCalInput["adults"] = adultsCount;
    }
    if (value === "children") {
      let childrenCount =
        this.state.priceCalInput.children >= 0
          ? this.state.priceCalInput.children + 1
          : 0;
      priceCalInput["children"] = childrenCount;
    }
    if (value === "infants") {
      let infantsCount =
        this.state.priceCalInput.infants >= 0
          ? this.state.priceCalInput.infants + 1
          : 0;
      priceCalInput["infants"] = infantsCount;
    }
    if (value === "age_special") {
      let infantsCount =
        this.state.priceCalInput.age_special >= 0
          ? this.state.priceCalInput.age_special + 1
          : 0;
      priceCalInput["age_special"] = infantsCount;
    }
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  decreament = (value) => {
    $("#guest-mobile-btn").dropdown("toggle");
    const priceCalInput = { ...this.state.priceCalInput };
    if (value === "adults") {
      let adultsCount =
        this.state.priceCalInput.adults > this.state.min_guests
          ? this.state.priceCalInput.adults - 1
          : this.state.min_guests;
      priceCalInput["adults"] = adultsCount;
    }
    if (value === "children") {
      let childrenCount =
        this.state.priceCalInput.children > 0
          ? this.state.priceCalInput.children - 1
          : 0;
      priceCalInput["children"] = childrenCount;
    }
    if (value === "infants") {
      let infantsCount =
        this.state.priceCalInput.infants > 0
          ? this.state.priceCalInput.infants - 1
          : 0;
      priceCalInput["infants"] = infantsCount;
    }
    if (value === "age_special") {
      let ageSpecialCount =
        this.state.priceCalInput.age_special > 0
          ? this.state.priceCalInput.age_special - 1
          : 0;
      priceCalInput["age_special"] = ageSpecialCount;
    }
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  onChangeDate = (date) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = dayjs(date).format("YYYY-MM-DD");
    priceCalInput["checkin_formatted"] = date;
    priceCalInput["custom_select_value"] = null;

    delete priceCalInput.host_time_slot_id

    this.setState({ priceCalInput } , () => this.priceCalcultor());

    // setTimeout(() => {
    //   this.priceCalcultor();
    // }, 1000);

    if (this.state.priceCalInput["host_time_slot_id"]) {
      setTimeout(function() {
        $(".same-date-picker-input").val("");
      }, 500);
    }

    if (this.state.singleTripDetails.basic_details.booking_type == 2) {
      this.getTimeSlotWithAvailable({
        checkin: dayjs(date).format("YYYY-MM-DD"),
        host_id: this.state.singleTripDetails.basic_details.host_id,
        timezone: const_time_zone,
      });
    }

  };

  onNormalBookingChangeDate = (title) => (...args) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = this.convert(args[0]);
    this.setState({ priceCalInput } , () => {
      this.priceCalcultor();
    });
  };

  handleUserDetailsChange = (event) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[event.currentTarget.name] = event.currentTarget.value;
    this.setState({ priceCalInput });

  };

  getTimeSlotWithAvailable = (inputData) => {
    api
      .postMethod("host_availability_date_view", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            timeSlotLoading: false,
            timeSlotData: response.data.data.data.availability_text,
          });
        } else {
          this.errorCodeChecker(response.data.error_code);
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  onChangeCheckoutDate = (title) => (...args) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkout"] = this.convert(args[0]);
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  getAddonServices = () => {
    api
      .postMethod("addon_services", {
        host_id: this.state.singleTripDetails.basic_details.host_id,
      })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            addonServicesData: response.data.data,
            loadingAddonService: false,
          });
          const priceCalInput = { ...this.state.priceCalInput };
          if (!this.state.loadingAddonService) {
            if (this.state.addonServicesData.length > 0) {
              this.state.addonServicesData.map((service) => {
                if (service.is_required == 1) {
                  let unitKey =
                    "host_addon_services[" +
                    service.host_addon_service_id +
                    "][no_of_units]";
                  priceCalInput[unitKey] = service.min_value;
                  priceCalInput["selectedAmentites"] = this.state.priceCalInput.selectedAmentites + 1;
                  this.setState({ priceCalInput });
                }
              });
            }
          }
        } else {
          // No nothing.
        }
      });
  };

  handleAddonService = (event, service) => {
    console.log(service);
    if(service.unit_type == 'person' || service.is_required == 0) {
      this.setState({
        addOnServiceModelData: service,
        loadingAddOnServiceModelData: false,
      });
      $("#AddOnServiceAdd").modal("show");
    }
  };

  removeAddonService = (event, name) => {
    event.preventDefault();
    const priceCalInput = { ...this.state.priceCalInput };
    delete priceCalInput[name];
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
      addOnSerModelAddButtonDisable: true,
      addOnSerModelAddLoadingContent: "Loading...",
    });
    setTimeout(() => {
      priceCalInput["selectedAmentites"] = this.state.priceCalInput.selectedAmentites - 1;
      // this.setState({ priceCalInput });
      this.setState({
        priceCalInput,
        // loadingBookingForm: false,
        buttonDisableBoBookingForm: false,
        buttonLoadingContentBookingForm: null,
        addOnSerModelAddButtonDisable: false,
        addOnSerModelAddLoadingContent: null,
      },() =>  this.priceCalcultor());
    }, 1000);
    $("#AddOnServiceAdd").modal("hide");
    ToastDemo(this.props.toastManager, "Servie Removed", "success");
  };

  addonServiceQuantity = (event, service) => {
    // add on service Quantity

    if (service.max_value >= event.currentTarget.value) {
      const priceCalInput = { ...this.state.priceCalInput };
      const checkValue = priceCalInput[event.currentTarget.name] !== undefined;

      if(priceCalInput[event.currentTarget.name]){
        //priceCalInput[event.currentTarget.name] = event.currentTarget.value;
        this.setState({ priceCalInput :{
          ...this.state.priceCalInput,
          [event.currentTarget.name] : event.currentTarget.value,
          addedAmentity : { name : event.currentTarget.name , value : event.currentTarget.value}}
        });
      }else{
        this.setState({ priceCalInput :{
          ...this.state.priceCalInput,
          amentityValue:event.currentTarget.value,
          addedAmentity : { name : event.currentTarget.name , value : event.currentTarget.value}}
        });
      }

    } else {
      ToastDemo(
        this.props.toastManager,
        "Please check the min and max of the service.",
        "error"
      );
    }
  };

  addonServiceBooking = (event ,  addon) => {
    // add on service Quantity
      event.preventDefault();
      const {min_value , host_addon_service_id} = addon;

      this.setState({
        loadingBookingForm: true,
        buttonDisableBoBookingForm: true,
        buttonLoadingContentBookingForm: "Loading... Please wait",
        addOnSerModelAddButtonDisable: true,
        addOnSerModelAddLoadingContent: "Loading...",
        priceCalInput :{
          ...this.state.priceCalInput,
          amentityValue: min_value,
          addedAmentity : { name : `host_addon_services[${host_addon_service_id}][no_of_units]` , value : min_value}
        }
      });

      setTimeout(() => {
        const priceCalInput = { ...this.state.priceCalInput };
        priceCalInput["selectedAmentites"] = this.state.priceCalInput.selectedAmentites + 1;
        priceCalInput[this.state.priceCalInput.addedAmentity.name] = this.state.priceCalInput.addedAmentity.value;
        priceCalInput["addedAmentity"] = {
          name : "",
          value : ""
        }
        priceCalInput["amentityValue"] = ""
        this.setState({
          priceCalInput,
          // loadingBookingForm: false,
          buttonDisableBoBookingForm: false,
          buttonLoadingContentBookingForm: null,
          addOnSerModelAddButtonDisable: false,
          addOnSerModelAddLoadingContent: null,
        });
        $("#AddOnServiceAdd").modal("hide");
        this.priceCalcultor();
      }, 1000);
  };

  addonServiceCallAPI = (event) => {
    event.preventDefault();
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
      addOnSerModelAddButtonDisable: true,
      addOnSerModelAddLoadingContent: "Loading...",
    });
    setTimeout(() => {
      const priceCalInput = { ...this.state.priceCalInput };
      priceCalInput["selectedAmentites"] = this.state.priceCalInput.selectedAmentites + 1;
      priceCalInput[this.state.priceCalInput.addedAmentity.name] = this.state.priceCalInput.addedAmentity.value;
      priceCalInput["addedAmentity"] = {
        name : "",
        value : ""
      }
      priceCalInput["amentityValue"] = ""
      this.setState({
        priceCalInput,
        // loadingBookingForm: false,
        buttonDisableBoBookingForm: false,
        buttonLoadingContentBookingForm: null,
        addOnSerModelAddButtonDisable: false,
        addOnSerModelAddLoadingContent: null,
      });
      $("#AddOnServiceAdd").modal("hide");
      if(priceCalInput.checkin){
        this.priceCalcultor();
        ToastDemo(this.props.toastManager, "Service Added..", "success");
      }else{
        ToastDemo(this.props.toastManager, "please select date", "error");
      }
    }, 1000);
  };

  singleTripViewApiCalls(formData) {
    hostAPI.postMethod("hosts_view", formData).then((response) => {
      if (response.data.success === true) {
        console.log(response.data.data)
        this.setState({
          singleTripDetails: response.data.data,
          loading: false,
        });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.props.history.goBack();
      }
    });
  }

  singleTripViewApiCall(formData) {
    api.postMethod("hosts_view", formData).then((response) => {
      if (response.data.success === true) {
        this.setState({
          singleTripDetails: response.data.data,
          loading: false,
        });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.props.history.goBack();
      }
    });
  }

  selectPackage = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[input.name] = input.value;
    this.setState({ priceCalInput });
    $('.packageDropdown').val(input.value);
    setTimeout(() => {
      this.priceCalcultor();
    }, 500);
  };

  handleContactHost = (event) => {
    event.preventDefault();
    if (this.state.apiCallStatus) {
      this.contactHostAPI();
      setTimeout(() => {
        this.props.history.push("/single-trip/chat", {
          host_details: this.state.singleTripDetails.basic_details,
          provider_details: this.state.singleTripDetails.provider_details,
          pricing_details: this.state.singleTripDetails.pricing_details,
          page: "singletrip",
          apiResponse : this.state.apiResponse
        });
      }, 1000);
    } else {
      ToastDemo(
        this.props.toastManager,
        "Please fill the booking details to Contact host",
        "error"
      );
    }
  };

  contactHostAPI = () => {
    api
      .postMethod("bookings_draft_save", this.state.priceCalInput)
      .then((response) => {
        if (response.data.success === true) {
          // Do nothing
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  // get time slot based on the date

  getTimeSlot = (inputData) => {
    api
      .postMethod("hosts_time_slots_prebooking", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            timeSlotLoading: false,
            timeSlotData: response.data.data,
          });
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  getPricingPackages = (inputData) => {
    api
      .postMethod("hosts_pricing_packages", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            pricingPackagesLoading: false,
            pricingPackagesData: response.data.data,
          });
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };


  timeSlotSelected = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_time_slot_id"] = input.value;
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  packagePricingSelected = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_pricing_package_id"] = input.value;
    this.setState({ priceCalInput });
    $('#package-select'+input.value).prop('checked',true);
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  handleAvaliablityDateChange = (selected_date) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = selected_date;
    this.setState({ priceCalInput });

    if (this.state.singleTripDetails.basic_details.booking_type == 2) {
      // this.getTimeSlot({
      //   checkin: selected_date,
      //   host_id: this.props.match.params.id,
      // });
      this.getTimeSlotWithAvailable({
        checkin: selected_date,
        host_id: this.props.match.params.id,
        timezone: const_time_zone,
      });
    }

  }

  hanldeModalTimeSlotChange = (timeSlot) => {
    console.log(timeSlot);
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_time_slot_id"] = timeSlot.time_slot_id;
    priceCalInput["custom_select_value"] = `${timeSlot.name} - ${timeSlot.start_time_formatted} - ${timeSlot.end_time_formatted}`;
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);

  }

  handleRangleCalenderChange = (dates) => {
    const [start, end] = dates;
    const checkin = moment(start).format("DD-MM-YYYY");
    const checkout = moment(end).format("DD-MM-YYYY");
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = checkin;
    priceCalInput["checkout"] = checkout;
    this.setState({ priceCalInput } , () => {
      if(checkout != "Invalid date"){
        this.priceCal()
      }
    });
  }

  handleBreakdownModal = (active_modal) => {
    this.setState({
      activeBreakdown : active_modal
    })
  }

  handlebreakdownClose = () => {
    this.setState({
      activeBreakdown : null
    })
  }

  hanldeNormalBooking = (selected_date) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = selected_date;
    this.setState({ priceCalInput } , () => {
      console.log("calling pricecalcularor")
      this.priceCalcultor();
    })
  }

  bookingCreate = (event) => {
    console.log(this.state.priceCalInput.payment_mode);
    event.preventDefault();
    let inputData;
    if(this.state.selectedOption){
      inputData = {
        ...this.state.priceCalInput,
        payment_mode: this.state.priceCalInput.payment_mode ? this.state.priceCalInput.payment_mode : "CARD",
        user_id : this.state.selectedOption && this.state.selectedOption.value,
        step: 5,
        host_id: this.props.match.params.id,
      };
    }else{
      inputData = {
        ...this.state.priceCalInput,
        payment_mode: this.state.priceCalInput.payment_mode ? this.state.priceCalInput.payment_mode : "CARD",
        step: 5,
        host_id: this.props.match.params.id,
      };
    }


    hostAPI.postMethod("bookings_create", inputData).then((response) => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, "Booking successfull", "success");
          this.props.history.push("/host/booking-confirm");
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        if (
          response.data.error_code == 1004 ||
          response.data.error_code == 1002
        ) {
          this.props.history.push("/");
          ToastDemo(this.props.toastManager, "Please login to book", "error");
        }
      }
    });
    
  };


  handleUserSelect = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  customSelectTimeSlotSelected = (value) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_time_slot_id"] = value;
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };


  render() {
    let load = new Loader("");
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    let { singleTripDetails, loading } = this.state;

    return loading ? (
      load.bannerLoader()
    ) : (
      <div>
        <TripBanner
          basicDetails={singleTripDetails.basic_details}
          status="user"
        />

        <TripGallery details={singleTripDetails.basic_details} />

        <div className="main" onClick={this.state.activeBreakdown != null ? this.handlebreakdownClose : null}>
          <div className="site-content">
            <div className="top-bottom-spacing">
              <div className="row">
                <div className="col-xl-7 col-lg-10 col-md-10 auto-margin">
                  <div id="overview">
                    <div className="media resp-flex-column">
                      <div className="media-body mr-3">
                        <a href="#">
                          <p className="red-text txt-overflow">
                            {singleTripDetails.basic_details.location}
                          </p>
                        </a>
                        <h1 className="category-section-head">
                          {singleTripDetails.basic_details.host_name}
                        </h1>
                        {/* <h4 className="captalize section-subhead">
                          {singleTripDetails.basic_details.host_location}
                        </h4> */}
                        {/* <h5 className="captalize section-subhead">
                          {singleTripDetails.basic_details
                            .is_automatic_booking == 0 ? (
                            <span className="text-strike">Rapid Booking</span>
                          ) : (
                            singleTripDetails.basic_details
                              .is_automatic_booking_text
                          )}
                        </h5> */}
                      </div>
                      {localStorage.getItem('is_frontdesk') == 0 ?
                        localStorage.getItem("userLoginStatus") ? (
                          <>
                          <Link
                            to={{
                              pathname: "/single-trip/chat",
                              state: {
                                host_details: singleTripDetails.basic_details,
                                provider_details: singleTripDetails.provider_details,
                                pricing_details: singleTripDetails.pricing_details,
                                page: "singletrip",
                              },
                            }}
                            onClick={this.handleContactHost}
                            className="green-outline-btn btn-small my-auto mr-3"
                          >
                            contact host
                          </Link>
                          <p className="overview-line" />
                          </>
                        ) : (
                          <div className="my-auto">
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#login"
                            >
                              <h4 className="green-outline-btn btn-small my-auto mr-3">contact host</h4>
                            </a>
                          </div>
                        ) 
                      : ''}
                      <div>
                        <Link
                          to={`/provider-profile/${singleTripDetails.provider_details.provider_id}`}
                        >
                          <img
                            src={singleTripDetails.provider_details.picture}
                            alt="rentcubo"
                            className="mt-3 rounded-circle review-img"
                          />
                        </Link>
                        <p className="text-center top3 mb-0">
                          <a href="#" className="other-proname">
                            {singleTripDetails.provider_details.provider_name}
                          </a>
                        </p>
                      </div>
                    </div>
                    <ul className="home-requirements">
                      {singleTripDetails.basic_details.section_4.length > 0
                        ? singleTripDetails.basic_details.section_4.map(
                            (details) => (
                              <li className="">
                                <img
                                  src={details.picture}
                                  alt="rentcubo"
                                  style={{ width: "20px", height: "auto" }}
                                />
                                &nbsp;
                                <span className={details.class}>{details.title}</span>
                              </li>
                            )
                          )
                        : ""}
                    </ul>

                    <h4 className="single-cat-text">
                      {renderHTML(
                        singleTripDetails.basic_details.host_description
                      )}
                    </h4>

                    {singleTripDetails.basic_details.pricing_type == 3 ? (
                      <TripPackagePricing
                        singleTripDetails={singleTripDetails}
                        loading={loading}
                        selectPackage={this.selectPackage}
                        priceCalInput={this.state.priceCalInput}
                      />
                    ) : null}

                    {singleTripDetails.amenties.data.length > 0 ? (
                      <>
                        <p className="overview-line" />

                        <h4 className="single-cat-text medium-cls ">
                          {singleTripDetails.amenties.title}
                        </h4>
                        <ul className="amenties-list">
                          <div>
                            {singleTripDetails.amenties.data.filter((amenties) => amenties.is_available == 1).map(
                              (amenity, index) =>
                                 index <= 3  ? (
                                  <li className="">
                                    <img
                                      src={amenity.amenities_picture != "" ? amenity.amenities_picture : amenity.picture}
                                      className="amentieimg"
                                    />
                                    &nbsp;{amenity.user_question}
                                    <br />
                                    { isNaN(amenity.answers) ? (
                                    <p className="text-muted amentie-answer">
                                      {amenity.answers}
                                    </p> ) : ''}
                                    <br />
                                    {amenity.description == "" ? (
                                      ""
                                    ) : (
                                      <h6> NOTE: {amenity.description}</h6>
                                    )}
                                  </li>
                                ) : (
                                  ""
                                )
                            )}
                            <div className="clearfix" />

                            {singleTripDetails.amenties.data.length > 3 ? (
                              <>
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#amenities"
                                >
                                  <h4 className="amenities-head">
                                    show all amenities
                                  </h4>
                                </a>

                                <TripShowAllAmen
                                  amenties={singleTripDetails.amenties}
                                  notIncluedAmentites={singleTripDetails.not_included_amenities}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </ul>
                      </>
                    ) : (
                      ""
                    )}

                    {singleTripDetails.other_house_rules.length > 0 ? (
                      <div className="cancellationPolicy">
                          <p className="overview-line" />
                          <h4 className="single-cat-text medium-cls ">
                            House Rules
                          </h4>
                          {singleTripDetails.other_house_rules.map((houseRule) => (
                            <div className="d-flex align-items-center my-2 singlePolicies">
                               <span className="mr-3"><i class="far fa-dot-circle"></i></span>
                                <h4 className="single-cat-text mb-0">
                                  {houseRule.content}
                                </h4>
                            </div>
                          ))}
                      </div>
                    ) : null}

                    {singleTripDetails.other_cancellation_policies.length > 0 ? (
                      <div className="cancellationPolicy">
                          <p className="overview-line" />

                          <h4 className="single-cat-text medium-cls ">
                            Cancellation Policies
                          </h4>
                          {singleTripDetails.other_cancellation_policies.map((cancelPolicy) => (
                            <div className="d-flex align-items-center my-2 singlePolicies">
                              <span className="mr-3"><i class="far fa-dot-circle"></i></span>
                              <h4 className="single-cat-text mb-0">
                                {cancelPolicy.content}
                              </h4>
                          </div>
                          ))}
                      </div>
                    ) : null}

                    {singleTripDetails.house_rules ? (
                      <div className="house_rules">
                          <p className="overview-line" />

                          <h4 className="single-cat-text medium-cls ">
                            Other House Rules
                          </h4>
                          <h4 className="single-cat-text ">
                            {singleTripDetails.house_rules}
                          </h4>
                      </div>
                    ) : null}

                    {singleTripDetails.cancellation_policy ? (
                      <div className="cancellationPolicy">
                          <p className="overview-line" />

                          <h4 className="single-cat-text medium-cls ">
                            Other Cancellation Policies
                          </h4>
                          <h4 className="single-cat-text ">
                            {singleTripDetails.cancellation_policy}
                          </h4>
                      </div>
                    ) : null}

                    <TripAddonServices
                      getAddonServices={this.getAddonServices}
                      handleAddonService={this.handleAddonService}
                      addonServicesData={this.state.addonServicesData}
                      loadingAddonService={this.state.loadingAddonService}
                      priceCalInput={this.state.priceCalInput}
                    />

                    <p className="overview-line" />
                    {singleTripDetails.policies.data.length > 0
                      ? singleTripDetails.policies.data.map((homeDetails) => (
                          <div>
                            <h4 className="single-cat-text medium-cls">
                              {homeDetails.title}
                            </h4>
                            <h4 className="rules-text">
                              {renderHTML(homeDetails.description)}
                            </h4>
                            <p className="overview-line" />
                          </div>
                        ))
                      : ""}

                    <h4 className="single-cat-text medium-cls">availability</h4>
                    <div id="singleTripCalender">
                      <GetAvailability
                        host_id={singleTripDetails.basic_details.host_id}
                        basicDetails={singleTripDetails.basic_details}
                        handleAvaliablityDateChange={this.handleAvaliablityDateChange}
                        hanldeModalTimeSlotChange={this.hanldeModalTimeSlotChange}
                        priceCalInput ={this.state.priceCalInput}
                        handleRangleCalenderChange={this.handleRangleCalenderChange}
                        hanldeNormalBooking={this.hanldeNormalBooking}
                      />
                    </div>
                    
                    <p className="overview-line" />
                  </div>

                  <TripReviewSection
                    details={singleTripDetails.basic_details}
                  />

                  <TripHostSection
                    providerDetails={singleTripDetails.provider_details}
                    singleTripDetails={singleTripDetails}
                    handleContactHost={this.handleContactHost}
                  />

                  <TripLocation location={singleTripDetails.basic_details} />
                  <div className="clearfix" />
                </div>
                <TripBookingForm
                  basicDetails={singleTripDetails.basic_details}
                  status="host"
                  setHostId={this.setHostId}
                  getPromoCode={this.getPromoCode}
                  checkPromoCode={this.checkPromoCode}
                  promoCodeAPICall={this.promoCodeAPICall}
                  removePromoCode={this.removePromoCode}
                  priceCalcultor={this.priceCalcultor}
                  bookNow={this.bookNow}
                  increament={this.increament}
                  decreament={this.decreament}
                  convert={this.convert}
                  onChangeDate={this.onChangeDate}
                  onChangeCheckoutDate={this.onChangeCheckoutDate}
                  addonServiceQuantity={this.addonServiceQuantity}
                  addonServiceCallAPI={this.addonServiceCallAPI}
                  loadingBookingForm={this.state.loadingBookingForm}
                  apiResponse={this.state.apiResponse}
                  apiCallStatus={this.state.apiCallStatus}
                  providerDetails={this.state.providerDetails}
                  buttonDisable={this.state.buttonDisableBookingForm}
                  buttonLoadingContent={
                    this.state.buttonLoadingContentBookingForm
                  }
                  singleTripDetails={this.state.singleTripDetails}
                  loadingAddonService={this.state.loadingAddonService}
                  addonServicesData={this.state.addonServicesData}
                  promoCodeInputData={this.state.promoCodeInputData}
                  promoCodeApiResponse={this.state.promoCodeApiResponse}
                  loadingPromoCode={this.state.loadingPromoCode}
                  providerDetails={singleTripDetails.provider_details}
                  priceCalInput={this.state.priceCalInput}
                  location={this.props.location}
                  history={this.props.history}
                  timeSlotLoading={this.state.timeSlotLoading}
                  timeSlotData={this.state.timeSlotData}
                  timeSlotSelected={this.timeSlotSelected}
                  activeBreakdown={this.state.activeBreakdown}
                  handleBreakdownModal={this.handleBreakdownModal}
                  handlebreakdownClose={this.handlebreakdownClose}
                  onNormalBookingChangeDate={this.onNormalBookingChangeDate}
                  usersList={this.state.users_list}
                  bookingCreate={this.bookingCreate}
                  handleUserSelect={this.handleUserSelect}
                  handleUserDetailsChange={this.handleUserDetailsChange}
                  packagePricingSelected={this.packagePricingSelected}
                  pricingPackagesLoading={this.state.pricingPackagesLoading}
                  pricingPackagesData={this.state.pricingPackagesData}
                  customSelectTimeSlotSelected={this.customSelectTimeSlotSelected}
                  promoCodeData={this.state.promoCodeData}
                />
              </div>

            </div>
          </div>
        </div>
        <TripAddOnSerAddModel
          addOnServiceModelData={this.state.addOnServiceModelData}
          loadingAddOnServiceModelData={this.state.loadingAddOnServiceModelData}
          priceCalInput={this.state.priceCalInput}
          addonServiceQuantity={this.addonServiceQuantity}
          addonServiceCallAPI={this.addonServiceCallAPI}
          addOnSerModelAddButtonDisable={
            this.state.addOnSerModelAddButtonDisable
          }
          addOnSerModelAddLoadingContent={
            this.state.addOnSerModelAddLoadingContent
          }
          removeAddonService={this.removeAddonService}
          addonServiceBooking={this.addonServiceBooking}
        />
        {/* <ChatModal 
          host_details = {this.state.singleTripDetails.basic_details}
          provider_details = {this.state.singleTripDetails.provider_details }
          pricing_details = {this.state.singleTripDetails.pricing_details}
          page = {"singletrip"}
          apiResponse = {this.state.apiResponse}
        /> */}
      </div>
    );
  }
}

export default withToastManager(HostSingleTripView);
