import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";

import "./Listing.css";
import PricingsTimeSlots from "./Pricings/PricingsTimeSlots";
const $ = window.$;

class HostPackageEdit extends Component {
    state = {
        loading: true,
        loadingContent: null,
        buttonDisable: false,
        packagePricing: null,
        loadingPackagePricing: true,
        packagePricingInput: {},
    };
    componentDidMount() {
        this.getPackagePricing(this.props.match.params.host_pricing_package_id);
    }

    getPackagePricing = (host_pricing_package_id) => {
        api
        .postMethod("hosts_pricing_packages_view", { host_pricing_package_id: host_pricing_package_id })
        .then((response) => {
            if (response.data.success === true) {
                const packagePricing = response.data.data.host_pricing_package;

                this.setState({
                    loadingPackagePricing: false,
                    packagePricing: packagePricing,
                });
                const packagePricingInput = { ...this.state.packagePricingInput };
                packagePricingInput["host_pricing_package_id"] = this.props.match.params.host_pricing_package_id;
                packagePricingInput["host_id"] = packagePricing.host_id;
                packagePricingInput["name"] = packagePricing.name;
                packagePricingInput["description"] = packagePricing.description;
                packagePricingInput["custom_date"] = packagePricing.custom_date;
                packagePricingInput["base_price"] = packagePricing.base_price;
                packagePricingInput["min_guests"] = packagePricing.min_guests;
                packagePricingInput["max_guests"] = packagePricing.max_guests;
                this.setState({ packagePricingInput });
            } else {
                ToastDemo(this.props.toastManager, response.data.error, "error");
            }
        })
        .catch(function(error) {});
    };

    packagePricingSave = (event) => {
        event.preventDefault();
        this.setState({
            loadingContent: "Loading... Please wait..",
            buttonDisable: true,
        });
        api
        .postMethod("hosts_pricing_packages_save", this.state.packagePricingInput)
        .then((response) => {
            if (response.data.success === true) {
                ToastDemo(this.props.toastManager, response.data.message, "success");
                this.props.history.push(`/host/pricings/${response.data.data.host_id}`);
            } else {
                ToastDemo(this.props.toastManager, response.data.error, "error");
            }
            this.setState({ loadingContent: null, buttonDisable: false });
        })
        .catch(function(error) {});
    };


    handleChangePackagePricing = ({ currentTarget: input }) => {
        const packagePricingInput = { ...this.state.packagePricingInput };
        packagePricingInput[input.name] = input.value;
        this.setState({ packagePricingInput });
    };

    render() {
        const {
            loadingPackagePricing,
            packagePricing
        } = this.state;
        return (
            <div className="main">
                <div className="site-content">
                    <div className="top-bottom-spacing">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <form onSubmit={this.handlePricingSubmit}>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            Update package pricing
                                            <Link className="float-right" to={"/host/listing"}>
                                                <i className="fas fa-chevron-left" /> Back
                                            </Link>
                                        </div>
                                        <div className="panel-body account host-pricing">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    
                                                    {loadingPackagePricing ? (
                                                        "Loading..."
                                                    ) : 
                                                        <div className="package-content-1">
                                                            <div className="form-group">
                                                                <label className="light-txt">
                                                                Package Name
                                                                </label>
                                                                <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Name"
                                                                name="name"
                                                                defaultValue={packagePricing.name}
                                                                onChange={
                                                                    this.handleChangePackagePricing
                                                                }
                                                                />
                                                            </div>

                                                            <div className="row">
                                                                <div className="form-group col-md-6">
                                                                <label className="light-txt">
                                                                    Min Guests
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    defaultValue={packagePricing.min_guests}
                                                                    className="form-control"
                                                                    placeholder="Enter Number"
                                                                    name="min_guests"
                                                                    onChange={
                                                                    this.handleChangePackagePricing
                                                                    }
                                                                />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                <label className="light-txt">
                                                                    Max Guests
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    defaultValue={packagePricing.max_guests}
                                                                    className="form-control"
                                                                    placeholder="Enter Number"
                                                                    name="max_guests"
                                                                    onChange={
                                                                    this.handleChangePackagePricing
                                                                    }
                                                                />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="light-txt">
                                                                Base price
                                                                </label>
                                                                <input
                                                                type="number"
                                                                defaultValue={packagePricing.base_price}
                                                                className="form-control"
                                                                placeholder="$0.00"
                                                                name="base_price"
                                                                onChange={
                                                                    this.handleChangePackagePricing
                                                                }
                                                                />
                                                            </div>

                                                            <div className="form-group ">
                                                                <label className="light-txt">
                                                                Description
                                                                </label>
                                                                <input
                                                                type="text"
                                                                defaultValue={packagePricing.description}
                                                                className="form-control"
                                                                placeholder="Enter Description"
                                                                name="description"
                                                                onChange={
                                                                    this.handleChangePackagePricing
                                                                }
                                                                />
                                                            </div>

                                                            <button
                                                                className="green-btn"
                                                                type="submit"
                                                                disabled={this.state.buttonDisable}
                                                                onClick={this.packagePricingSave}
                                                            >
                                                                {this.state.loadingContent != null
                                                                ? this.state.loadingContent
                                                                : "Submit"}
                                                            </button>
                                                        </div>
                                                    }
                                                      
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withToastManager(HostPackageEdit);
