import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
//import { url } from "inspector";

class Categories extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      draggable: true,
    };
    const { length: count } = this.props.categoryDetails;

    if (count === 0) {
      return <p />;
    }

    return (
      <div className="party-life site-content">
        <h1 className="section-head">Let's help you get your party to life</h1>
        <section className="m-t-10">
          {/* <div className="row">
            {this.props.categoryDetails.data.map((category) => (
              <div key={category.category_id} className="col-md-3 resp-mrg-btm-xs">
                <Link
                  to={`/see_all/${category.name}/${category.api_page_type_id}/${this.props.categoryDetails.api_page_type}`}
                >
                  <div
                    class="homes-img-sec1 event-image"
                    style={{
                      backgroundImage:
                        "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.6))," +
                        `url(${category.picture})`,
                    }}
                  ></div>

                  <div class="carousel-caption carousel-caption-event">
                    <h1 className="caption-txt">{category.name}</h1>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <br></br> */}
          <Slider className="category" {...settings}>
            {this.props.categoryDetails.data.map((category) => (
              <div key={category.category_id}>
                {this.props.categoryDetails.is_see_all == 1 ? (
                  <Link
                    to={`/see_all/${category.name}/${category.api_page_type_id}/${this.props.categoryDetails.api_page_type}`}
                  >
                    <div className="display-inline home-explore-card">
                      <div className="home-explore-left">
                        <div
                          className="home-explore-img"
                          style={{
                            backgroundImage: `url(${category.picture})`,
                          }}
                        />
                      </div>
                      <div className="home-explore-right">
                        <p className="">{category.name}</p>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </Link>
                ) : (
                  <Link
                    to={`/category/${category.name}/${category.api_page_type_id}/${this.props.categoryDetails.api_page_type}`}
                  >
                    <div className="display-inline home-explore-card">
                      <div className="home-explore-left">
                        <div
                          className="home-explore-img"
                          style={{
                            backgroundImage: `url(${category.picture})`,
                          }}
                        />
                      </div>
                      <div className="home-explore-right">
                        <p className="">{category.name}</p>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </Link>
                )}
              </div>
            ))}
          </Slider>
        </section>
      </div>
    );
  }
}

export default Categories;
