import React, { Component } from "react";
import api from "../../../HostEnvironment";
import io from "socket.io-client";
import SendIcon from "./assets/send-icon.svg";
import { Link } from "react-router-dom";
import { apiConstants } from "../../Constant/constants";
import { Container } from "react-bootstrap";
import configuration from "react-global-configuration";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import CustomPriceModal from "./CustomPriceModal";
import ReactPlayer from "react-player/lazy";
import FancyBox from "../../User/TripDetails/FancyBox";
import ChatAssetModal from "./ChatAssetModal";

let socket;

class HostInbox extends Component {
  state = {
    chatList: null,
    loadingChatList: true,
    socket: false,
    inputMessage: null,
    chatData: null,
    loadingChatData: true,
    headerDetail: null,
    headerDetailsLoadFirst: true,
    headerContentLoading: true,
    loadingStatus: true,
    loadingContent: null,
    loadingStatusChatHistory: true,
    loadingContentChatHistory: null,
    chatListskipCount: 0,
    chatHistorySkipCount: 0,
    loadMoreChatHistory: false,
    chatIndex: 0,
    bookingDetailsData: null,
    loadingBookingDetails: true,
    chatUser: null,
    chatUserId: 0,
    chatSidebar: {
      status: true,
      desktop: false
    },
    notificationIndex: [],
    requestCustomPricingModel: false,
    customPricingRequest: null,
    fileType: '',
    chatAssetUpload: false,
  };

  componentDidMount() {
    this.chatListApiCall(0);
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }
    window.addEventListener('resize', this.handleChatsidebar);
  }
  componentWillUnmount() {
    socket.disconnect();
    window.removeEventListener('resize', this.handleChatsidebar);
  }

  assetUploadSuccess = (event, data) => {
    console.log(data);
    this.handleSubmit(event, data);
  }

  handleChatsidebar = () => {
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state, chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state, chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }
  };

  handleChatSideBarToggler = (toggle_status) => {
    this.setState({
      ...this.state, chatSidebar: {
        ...this.state.chatSidebar,
        status: toggle_status,
      }
    });
  }

  handleCustomPricingRequest = () => {
    this.setState({
      requestCustomPricingModel: true,
    });
  }

  handleCustomPricingModal = (status) => {
    this.setState({
      requestCustomPricingModel: false,
    });
  };

  handleAssetUploadModal = (fileType) => {
    this.setState({
      ...this.state,
      fileType: fileType,
      chatAssetUpload: true,
    });
  };

  closeChatAssetUploadModal = () => {
    this.setState({
      ...this.state,
      chatAssetUpload: false,
    });
  };

  getBookingDetails = (user_id, host_id) => {
    api
      .postMethod("bookings_draft_view", { user_id: user_id, host_id: host_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            bookingDetailsData: response.data.data,
            loadingBookingDetails: false,
          });
        } else {
          if (response.data.error_code == 1004) {
            this.props.toggleLoginModal(false);
          }
        }
      });
  };

  chatListApiCall = (loadMoreStatus) => {
    let items;
    api
      .postMethod("bookings_inbox", { skip: this.state.chatListskipCount })
      .then((response) => {
        if (response.data.success) {
          if (this.state.chatList != null) {
            items = [
              ...this.state.chatList,
              ...response.data.data.chat_messages,
            ];
          } else {
            items = [...response.data.data.chat_messages];
          }
          this.setState({
            chatList: items,
            loadingChatList: false,
            chatListskipCount:
              response.data.data.chat_messages.length +
              this.state.chatListskipCount,
            loadingStatus: true,
            chatUser: response.data.data.users,
          });

          if (this.state.chatList.length > 0 && loadMoreStatus == 0) {
            this.headerDetails(0);
            this.chatDetailsApiCall(0, true);
          } else {
            this.setState({
              headerContentLoading: false,
            });
          }
        } else {
          if (response.data.error_code == 1004) {
            this.props.toggleLoginModal(false);
          }
        }
      });
  };

  messageStatusApiCall = (user_id) => {
    let inputData;

    inputData = {
      user_id: user_id,
      provider_id: localStorage.getItem("hostId"),
    };

    api
      .postMethod("chat_messages_status", inputData)
      .then((response) => {
        if (response.data.success) {

        } else {

        }
      });
  };

  socketConnectionfun = (userId, providerId, hostId) => {
    let socketUrl = configuration.get("configData.chat_socket_url");
    socket = io(socketUrl, {
      query:
        `commonid: 'user_id_` +
        userId +
        `_provider_id_` +
        providerId +
        `_host_id_` +
        hostId +
        `', myid: ` +
        providerId,
    });

    socket.emit("update sender", {
      commonid:
        "user_id_" +
        userId +
        "_provider_id_" +
        providerId +
        "_host_id_" +
        hostId,
      myid: providerId,
      providerId: providerId,
    });
    let chatContent;
    socket.on("message", (newData) => {
      console.log(newData);
      //  if(newData.chattype)
      // console.log(newData);
      // this.messageStatusApiCall(newData.user_id);
      let content = [];
      content.push(newData);
      chatContent = [...this.state.chatData, ...content];
      this.setState({ chatData: chatContent }, () => this.scrollDown());
    });
  };

  chatDetailsApiCall = (index, status) => {
    let inputData;
    if (this.state.chatList[index] && this.state.chatList[index].booking_id == 0) {
      inputData = {
        // host_id: this.state.chatList[index].host_id,
        user_id: this.state.chatUser[index].user_id,
        skip: status ? this.state.chatHistorySkipCount : 0,
        provider_id: localStorage.getItem("hostId"),
      };
    } else {
      inputData = {
        // booking_id: this.state.chatList[index].booking_id,
        // host_id: this.state.chatList[index].host_id,
        user_id: this.state.chatUser[index].user_id,
        skip: status ? this.state.chatHistorySkipCount : 0,
        provider_id: localStorage.getItem("hostId"),
      };
    }

    api.postMethod("bookings_chat_details", inputData).then((response) => {
      let chathistory;
      if (response.data.success) {
        if (this.state.loadMoreChatHistory) {
          if (this.state.chatData != null) {
            chathistory = [
              ...response.data.data.chat_messages.reverse(),
              ...this.state.chatData,
            ];
          } else {
            chathistory = [...response.data.data.chat_messages.reverse()];
          }
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount:
              response.data.data.chat_messages.length + this.state.chatHistorySkipCount,
            loadingStatusChatHistory: true,
            loadMoreChatHistory: false,
            customPricingRequest: response.data.data.custom_pricing_request,
          });
        } else {
          chathistory = response.data.data.chat_messages.reverse();
          // console.log(chathistory);
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount: response.data.data.chat_messages.length,
            loadingStatusChatHistory: true,
            chatIndex: index,
            customPricingRequest: response.data.data.custom_pricing_request,
          }, () => {
            this.scrollDown();
          });
        }
        this.scrollDown()
        // Get Booking details Api call
        this.getBookingDetails(
          this.state.chatData[0].user_id,
          this.state.chatData[0].host_id
        );
      } else {
        //
      }
    });
  };

  getNotificationIndex = () => {

    let lastIndexFound = 0;

    let startingIndex = null;

    const indexArray = []
    for (let index = 0; index <= this.state.chatData.length - 1; ++index) {

      if (this.state.chatData[index] && (this.state.chatData[index].notification_type == 1 || this.state.chatData[index].notification_type == 2)) {
        if (this.state.chatData[index].notification_type == this.state.chatData[index + 1 > this.state.chatData.length - 1 ? this.state.chatData.length - 1 : index + 1].notification_type) {
          if (startingIndex == null) {
            startingIndex = index
            indexArray.push(index)
          }
        } else {
          lastIndexFound = 0;
          startingIndex = null;
        }

      }

    }
    this.setState({
      ...this.state,
      notificationIndex: indexArray
    })
  }

  headerDetails = async (index) => {
    await this.setState({
      headerDetail: this.state.chatUser[index],
      headerContentLoading: false,
      chatUserId: this.state.chatUser[index].user_id
    });

    if (!this.state.headerContentLoading) {
      this.socketConnectionfun(
        this.state.headerDetail.user_id,
        localStorage.getItem("hostId"),
        this.state.chatUser[index].host_id
      );
    }
  };

  handleOnChange = ({ currentTarget: input }) => {
    this.setState({ inputMessage: input.value });
    //
  };

  handleSubmit = (event, assetData) => {
    event.preventDefault();
    if(this.state.inputMessage) {
      let online_status = 0;
      let inputData = { user_id: this.state.headerDetail.user_id };
      api
        .postMethod("online_status", inputData)
        .then((response) => {
          if (response.data.success) {
            online_status = response.data.data.status;
          }
          let chatData = [
            {
              user_id: this.state.headerDetail.user_id,
              provider_id: localStorage.getItem("hostId"),
              message: this.state.inputMessage,
              host_id: this.state.headerDetail.host_id,
              booking_id: this.state.chatData[0].booking_id,
              chat_type: "pu",
              updated: 'Just now',
              request_type: 0,
              provider_picture: localStorage.getItem("host_picture"),
              notification_type: 2,
              user_status: online_status == 1 ? 1 : 0,
              provider_status: 1,
              sender_id: localStorage.getItem("hostId"),
              chat_asset_id: assetData ? assetData.data.chat_asset.id : "",
              chat_asset_url: assetData ? assetData.data.chat_asset.file : "",
              file_type: assetData ? assetData.data.chat_asset.file_type : "",
            },
          ];

          socket.emit("message", chatData[0]);
          let messages;

          if (this.state.chatData != null) {
            messages = [...this.state.chatData, ...chatData];
          } else {
            messages = [...chatData];
          }
          this.setState({
            chatData: messages,
            inputMessage: "",
          }, () => this.scrollDown());
      });
    }
  };

  changeChatfun = async (event, index) => {
    event.preventDefault();
    socket.disconnect();
    await this.setState({ chatHistorySkipCount: 0 });
    this.headerDetails(index);
    this.chatDetailsApiCall(index, true);
    this.chatListApiCall(1);
    if (!this.state.chatSidebar.desktop) {
      this.handleChatSideBarToggler(false)
    }
  };

  loadMoreChatList = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.chatListApiCall(1);
  };

  requestStatus = (event, chat_message, status) => {
    event.preventDefault();
    api.postMethod("custom_price_request_status", {
      user_id: chat_message.user_id,
      host_id: chat_message.host_id,
      provider_id: chat_message.provider_id,
      status: status
    }).then((response) => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.chatDetailsApiCall(this.state.chatIndex, false);
      } else {
        if (response.data.error_code == 1004) {
          this.props.toggleLoginModal(false);
        }
      }
    });
  }

  loadMoreChatHistory = (event) => {
    event.preventDefault();
    this.setState({
      loadingStatusChatHistory: false,
      loadingContentChatHistory: "Loading...",
      loadMoreChatHistory: true,
    });
    this.chatDetailsApiCall(this.state.chatIndex, true);
  };

  scrollDown = () => {

    const objDiv = document.getElementById("messages-holder");

    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      // console.log(differenceNumber)

      if (differenceNumber > 100) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = this.state.initialHeight;
        this.setState({
          ...this.state,
          initialHeight: this.state.initialHeight + 20,
        });
      }
    }
  };

  render() {
    const {
      chatList,
      chatUser,
      loadingChatList,
      chatData,
      loadingChatData,
      headerDetail,
      headerContentLoading,
      loadingStatus,
      loadingContent,
      loadingContentChatHistory,
      loadingStatusChatHistory,
      chatUserId,
    } = this.state;

    return (
      <div className="main chatWrapper container mx-auto" id="inbox">
        {loadingChatList ? (
          "Loading..."
        ) : chatList.length > 0 ? (
          <div className="row m-0 w-100">
            {this.state.chatSidebar.status && (
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-3 chat-leftsec">
                <div className="chat-details text-center">
                  <div
                    className="chat-list-block"
                    id="chat-list"
                  >
                    {chatUser.map((list, index) => (
                      <Link
                        to="#"
                        key={list.user_id}
                        className={`chat-list-box ${list.user_id == chatUserId ? "active" : ""}`}
                        onClick={(event) => this.changeChatfun(event, index)}
                      >
                        <div className="chat-inline-box">
                          <div className="chat-prof-img ">
                            <img
                              src={list.user_details.picture}
                              alt={list.user_name}
                            />
                          </div>
                          <div className="chat-prof-content">
                            <h5 className="chat-name">{list.user_name}</h5>
                            {/* <p className="chat-msg">{list.message}</p>
                            <p className="chat-date">{list.updated_at_formatted}</p> */}
                          </div>
                        </div>
                        {list.unread_status == 1 && list.isActive == 0 ?

                          <div className="chat-new-msg">
                            New
                          </div>

                          : ''}

                      </Link>
                    ))}
                  </div>
                  <div className="text-center">
                    {loadingStatus ? "" : loadingContent}
                    {chatList.length > 0 ? (
                      <a
                        href="#"
                        class="show-all"
                        onClick={this.loadMoreChatList}
                      >
                        show more
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state.customPricingRequest ?
                    <div className="new-host-chat-custom-btn-sec mobile-visble-sec">
                      <button className="pink-btn mb-2" onClick={() => this.handleCustomPricingRequest()}>Custom Price</button>
                    </div>
                    : ''}
                </div>
              </div>
            )}

            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-9 chat-rightsec">
              {loadingChatList ? (
                "Loading.. "
              ) : (
                <div className="relative height-100">
                  {headerContentLoading ? (
                    "Loading..."
                  ) : headerDetail ? (
                    <>
                      <div className="chat-header">
                        <div className="chat-list-box single-chat-box">
                          <div className="chat-inline-box host-chat-width-full">
                            <div className="host-chat-header-left-sec">
                              <div className="chatSidebarToggler ml-2" onClick={() => this.props.history.goBack()}>
                                <i class="fas fa-arrow-left"></i>
                              </div>
                              <div className="chat-prof-img">
                                <img
                                  src={headerDetail.user_details.picture}
                                  alt={headerDetail.user_name}
                                />
                              </div>
                              <div className="chat-prof-content">
                                <h5 className="chat-name">
                                  {headerDetail.user_name}
                                </h5>
                                <p className="chat-date">
                                  {headerDetail.updated_at_formatted}
                                </p>

                                {headerDetail.response_time_hrs &&
                                  headerDetail.response_time_mins ? (
                                  <p className="chat-date">
                                    Response Time:{" "}
                                    {headerDetail.response_time_hrs} hours and{" "}
                                    {headerDetail.response_time_mins} Minutes
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="new-host-chat-custom-btn-sec">
                              <button className="pink-btn mb-2" onClick={() => this.handleCustomPricingRequest()}>Custom Price</button>
                            </div>
                          </div>
                          {this.state.chatSidebar.desktop || (
                            <>
                              {this.state.chatSidebar.status ? (
                                <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(false)}>
                                  <i class="fas fa-times"></i>
                                </div>
                              ) : (
                                <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(true)}>
                                  <i class="fas fa-bars"></i>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="chat-name m-t-10">
                      <p className="text-center">No Messages found.</p>
                    </div>
                  )}
                  {loadingChatData ? (
                    ""
                  ) : (
                    <div className="chat-content" id="messages-holder">
                      {chatData.length > 0 ? (
                        <div className="text-center">
                          {loadingStatusChatHistory
                            ? ""
                            : loadingContentChatHistory}
                          <a
                            href="category/index.html"
                            class="show-all"
                            onClick={(event) =>
                              this.loadMoreChatHistory(event)
                            }
                          >
                            show more
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {chatData.map((chat, index) =>
                        <>
                          {/* {this.state.notificationIndex.map((data) => (
                          <>
                            {index == data && chat.notification_type == 1 && (
                              <div className="chat-protect-notes">
                                <p>Booking Enquiry</p>
                              </div>
                            ) }
                          </>
                          ))} */}
                          {/* {index == this.state.chatHistorySkipCount ? 
                            <div className="chat-protect-notes">
                              <p>General Conversation</p>
                            </div>
                          : ''} */}
                          {chat.confirmed_booking_id || chat.draft_booking_id ?
                            <div className="offer-list-box">
                              <h4>{chat.bookings.host_name}</h4>
                              <p>{chat.message}</p>
                              {chat.confirmed_booking_id ?
                                <ul className="list-unstyled offer-list">
                                  <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                  <li><i class="far fa-clock mr-2"></i> Booked on: {chat.bookings.created}</li>
                                  <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                                </ul> :
                                <ul className="list-unstyled offer-list">
                                  <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                  <li><i class="far fa-clock mr-2"></i> Enquired on: {chat.bookings.created}</li>
                                  <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                                </ul>
                              }
                              {chat.confirmed_booking_id ?
                                <Link to={`/host/booking-details/${chat.bookings.booking_id}`} className="view-order-btn">View Bookings</Link>
                                : ''}
                            </div>
                            :
                            chat.chat_type == "up" ? (
                              <div className="chat-left">
                                <div className="display-inline">
                                  <img
                                    className="chat-img-left"
                                    src={chat.user_picture}
                                  />
                                  <div className="chat-content-right">
                                    {chat.message ?
                                      <div className="chat-message">
                                        {chat.message}{" "}
                                      </div>
                                      : ''}
                                    <p className="clearfix m-0" />
                                    <div className="chat-img-upload-preview-card-left">
                                      {chat.chat_asset_url &&
                                        chat.file_type == "image" ? (
                                        <FancyBox>
                                          <img
                                            src={chat.chat_asset_url}
                                            className="chat-img-preview-img"
                                            data-fancybox
                                          />
                                        </FancyBox>
                                      ) : (
                                        ""
                                      )}
                                      {chat.chat_asset_url &&
                                        chat.file_type == "video" ? (
                                        <ReactPlayer
                                          url={chat.chat_asset_url}
                                          controls={true}
                                          className="post-video-size chat-video"
                                          width="450px"
                                          height="450px"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {chat.chat_asset_url &&
                                        chat.file_type == "document" ? (
                                        <a href={chat.chat_asset_url} target="_blank">
                                          <img
                                            src="../assets/img/icon/document.jpg"
                                            className="chat-img-preview-img"
                                          />
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div>
                                      <p className="chat-time">{chat.updated}</p>
                                      <p className="clearfix m-0" />

                                      {chat.chat_assets && chat.message_type == 1 && chat.chat_assets.file ? (
                                        chat.chat_assets.chat_asset_files.map(
                                          (file, index) => (
                                            <p className="preview-attach-info mr-2"><a href={file} target="_blank">Attachment {index + 1} </a></p>
                                          )
                                        )
                                      ) : ''}
                                    </div>
                                    {/* {chat.request_type == 1 && chat.custom_price_request_status === 0 ?
                                      <>
                                        <button
                                          type="submit"
                                          onClick={(event) =>
                                            this.requestStatus(event, chat, 1)
                                          }
                                          className="btn btn-success width-btn mr-3"
                                        >
                                          Accept
                                        </button>
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.requestStatus(event, chat, 2)
                                          }
                                          className="btn btn-danger width-btn"
                                        >
                                          Reject
                                        </button>
                                      </>
                                      : chat.request_type == 1 && chat.custom_price_request_status == 1 ?
                                        <p className="chat-time status-msg-indication">Approved</p>
                                        : chat.request_type == 1 && chat.custom_price_request_status == 2 ?
                                          <p className="chat-time status-msg-indication">Rejected</p>
                                          : ''} */}


                                  </div>

                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="clearfix" />
                                <div className="chat-right">
                                  <div className="display-inline">
                                    <div className="chat-content-right">
                                      {chat.message ?
                                        <div className="chat-message">
                                          {chat.message}{" "}
                                        </div>
                                        : ''}
                                      <p className="clearfix m-0" />
                                      <div className="chat-img-upload-preview-card-right">
                                        {chat.chat_asset_url &&
                                          chat.file_type == "image" ? (
                                          <FancyBox>
                                            <img
                                              src={chat.chat_asset_url}
                                              className="chat-img-preview-img"
                                              data-fancybox
                                            />
                                          </FancyBox>
                                        ) : (
                                          ""
                                        )}
                                        {chat.chat_asset_url &&
                                          chat.file_type == "video" ? (
                                          <ReactPlayer
                                            url={chat.chat_asset_url}
                                            controls={true}
                                            className="post-video-size chat-video"
                                            width="450px"
                                            height="450px"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {chat.chat_asset_url &&
                                          chat.file_type == "document" ? (
                                          <a href={chat.chat_asset_url} target="_blank">
                                            <img
                                              src="../assets/img/icon/document.jpg"
                                              className="chat-img-preview-img"
                                            />
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="chat-status-indication-right">
                                        <p className="chat-time text-right">
                                          {chat.updated}
                                        </p>
                                        {chat.user_status == 1 ?
                                          <p className="read-msg-indication">Read</p>
                                          :
                                          <p className="delivered-msg-indication">Sent</p>
                                        }
                                        {chat.request_type == 1 && chat.custom_price_request_status == 1 ?
                                          <p className="chat-time status-msg-indication">Approved</p>
                                          : chat.request_type == 1 && chat.custom_price_request_status == 2 ?
                                            <p className="chat-time status-msg-indication">Rejected</p>
                                            : ''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </>
                      )}
                    </div>
                  )}
                  <div className="chat-footer">
                    <form onSubmit={this.handleSubmit}>
                      <div className="input-group dropdown">
                        <input
                          type="text"
                          className="form-control dropdown-toggle"
                          data-toggle="dropdown"
                          placeholder="Write your message..."
                          name="message"
                          value={this.state.inputMessage}
                          onChange={this.handleOnChange}
                        />
                        <div
                          className="input-group-append"
                        >
                          <span className="input-group-text" id="basic-addon">
                            <img
                              src="../assets/img/icon/image-upload.png"
                              className="action-upload-icon"
                              onClick={() =>
                                this.handleAssetUploadModal("image")
                              }
                            />
                          </span>
                        </div>
                        <div
                          className="input-group-append"
                        >
                          <span className="input-group-text" id="basic-addon">
                            <img
                              src="../assets/img/icon/video-upload.png"
                              className="action-upload-icon"
                              onClick={() =>
                                this.handleAssetUploadModal("document")
                              }
                            />
                          </span>
                        </div>
                        <div
                          className="input-group-append"
                        >
                          <span className="input-group-text" id="basic-addon">
                            <img
                              src="../assets/img/icon/file-upload.png"
                              className="action-upload-icon"
                              onClick={() =>
                                this.handleAssetUploadModal("document")
                              }
                            />
                          </span>
                        </div>
                        <div
                          className="input-group-append"
                          onClick={this.handleSubmit}
                        >
                          <span className="input-group-text" id="basic-addon">
                            {/* <i className="fas fa-paper-plane" /> */}
                            <img
                              src="../assets/img/icon/send.png"
                              className="action-upload-icon-1"
                            />
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
            {this.state.requestCustomPricingModel && (
              <CustomPriceModal
                requestCustomPricingModel={this.state.requestCustomPricingModel}
                handleCustomPricingModal={this.handleCustomPricingModal}
                userDetails={headerDetail.user_details}
                customPricingRequest={this.state.customPricingRequest}
              />
            )}
          </div>
        ) : (
          " There is no Message for you... "
        )}
        <ChatAssetModal
          chatAssetUpload={this.state.chatAssetUpload}
          closeChatAssetUploadModal={this.closeChatAssetUploadModal}
          fileType={this.state.fileType}
          assetUploadSuccess={this.assetUploadSuccess}
          toUserId={this.state.chatUserId}
          chatType="pu"
        />
      </div>
    );
  }
}

export default withToastManager(HostInbox);
