import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
//import { url } from "inspector";
import LazyLoad from 'react-lazyload';

const queryString = require("query-string");

class LandingPageEvents extends Component {
  render() {
    const { loadingEvent, eventData,location_name,loadingNavigator } = this.props;

    var location = {}

    const handleEventRedirect = (eventDetail) => {

      const query = queryString.stringify(
        {selectedEvent : [eventDetail.title] , eventId : [eventDetail.event_id]},
        { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
      );
  

      location = {
        pathname : `/see_all/events/${eventDetail.event_id}/${eventDetail.title}`,
        state : { 
          // type:"EVENT",
          // searchInput: { 
          //   event_title : eventDetail.title,
          //   event_id : eventDetail.event_id,
          // },
          notMainSearch: true,
          location_name : loadingNavigator ? '' : location_name.city,
        },
        search : query
      }
      return location;
    }

    return loadingEvent ? (
      "Loading..."
    ) : eventData.length > 0 ? (
      <div className="party-life site-content">
        <h1 className="section-head">Let's help you get your party to life</h1>
        <section className="m-t-20">
          <div className="row justify-content-md-center grid-column-sec">
            {eventData.map((eventDetail) => (
              <div
                key={eventDetail.event_id}
                className="col-md-4 col-lg-3 col-xl-3 margin-btm1 resp-mrg-btm-xs"
              >
                <Link
                  to={handleEventRedirect(eventDetail)}
                > 
                  <LazyLoad>
                    <div
                      class="homes-img-sec1 event-image"
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.6))," +
                          `url(${eventDetail.picture})`,
                      }}
                    ></div>
                  </LazyLoad>
                  <div class="carousel-caption carousel-caption-event">
                    <h1 className="caption-txt">{eventDetail.title}</h1>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <br></br>
        </section>
      </div>
    ) : null;
  }
}

export default LandingPageEvents;
