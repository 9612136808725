import React, { Component } from "react";

class AddListingStep2 extends Component {
  state = {};
  render() {
    const { stepData, handleAmentiesChange } = this.props;
    return (
      <div id="step-2" className="flow-tab">
        <div className="add-flow-block row add-listing-left-content">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">Events allowed in your property</h4>
              <p className="main-txt">{stepData[1].title}</p>
              <div className="flow-content">
                <div className="flow-box">
                  <h5 className="flow-box-tit">{stepData[1].description}</h5>
                  {stepData[1].data.host_events.map((details) => (
                    <>
                      <div
                        className="form-group form-check tick-check"
                        key={`event_id` + details.event_id}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="event_id"
                          id={`event_id` + details.event_id}
                          value={details.event_id}
                          onChange={handleAmentiesChange}
                        />
                        <div>
                          <label
                            className="form-check-label"
                            htmlFor={`event_id` + details.event_id}
                          >
                            <div>
                              <h5 className="amen-tit">
                                <b>{details.title}</b>
                              </h5>
                            </div>
                          </label>
                          <p className="flow-check-txt text-muted">
                            {details.description}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>

                {/* <div className="flow-box">
                  <h5 className="flow-box-tit">{stepData[2].title}</h5>
                  {stepData[2].data.host_listings.map((details) => (
                    <div
                      className="form-check add-list-block"
                      key={`listing_id` + details.listing_id}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listing_id"
                        id={`listing_id` + details.listing_id}
                        value={details.listing_id}
                        onChange={handleAmentiesChange}
                      />
                      <label
                        className="form-check-label row"
                        htmlFor={`listing_id` + details.listing_id}
                      >
                        <h5 className="flow-check-tit">{details.title}</h5>
                        <p className="flow-check-txt text-muted">
                          {details.description}
                        </p>
                      </label>
                      <div className="clear-both" />
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep2;
