import React, { Component } from "react";
import HostHeader from "./hostHeader";
import HostFooter from "./hostFooter";
import HostLoginModal from "../Auth/HostLoginModal";
import FrontDeskheader from "./FrontdeskHeader";

// This Layouts used in Home page Header,  static footer and content

class HostLayout extends Component {
    constructor(props) {
        super(props);

        // define states

        this.state = {
            isValidAuth : true
        }
    }

    toggleLoginModal = (state) => {
        this.setState({
            ...this.state,
            isValidAuth : state
        })
    }

    render() {
        return (
            <div className="wrapper">
                {localStorage.getItem("is_frontdesk") == "1" ? <FrontDeskheader {...this.props.children.props}/> : <HostHeader {...this.props.children.props} />}
                {this.state.isValidAuth || (
                    <HostLoginModal />
                )} 
                <div>
                    {React.cloneElement(this.props.children ,  {isValidAuth : this.state.isValidAuth , toggleLoginModal :this.toggleLoginModal})}
                </div>
                <div>
                    <HostFooter key="host-footer" id="host-footer001" />
                </div>
            </div>
        );
    }
}
export default HostLayout;
