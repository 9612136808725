import React, { Component } from "react";

class AddListingStep9 extends Component {
  state = {};
  render() {
    const {
      eighthApiResponse,
      handleAmentiesChange,
      formData,
      OtherRuleLoading,
      OtherHouseRules,
      OtherCancellationPolicies,
    } = this.props;
    return (
      <div id="step-11" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{eighthApiResponse[0].title}</h4>
              <p className="main-txt">{eighthApiResponse[0].description}</p>

              <div className="flow-content">
                {eighthApiResponse[0].data.map((details) => (
                  <>
                    <div className="form-group large-form-group">
                      <label className="light-txt">{details.question}</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder={details.placeholder}
                        name={details.server_key}
                        value={formData[details.server_key]}
                        onChange={handleAmentiesChange}
                      />
                    </div>
                    {details.server_key == "house_rules" ? (
                      <>
                        {OtherRuleLoading == false
                          ? OtherHouseRules.map((OtherHouseRule) => (
                              <div
                                className="form-group form-check tick-check"
                                key={`other_house_rule_ids` + OtherHouseRule.id}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="other_house_rule_ids"
                                  id={
                                    `other_house_rule_ids` +
                                    OtherHouseRule.rule_lookup_id
                                  }
                                  value={OtherHouseRule.rule_lookup_id}
                                  onChange={handleAmentiesChange}
                                />

                                <label
                                  className="form-check-label"
                                  htmlFor={
                                    `other_house_rule_ids` +
                                    OtherHouseRule.rule_lookup_id
                                  }
                                >
                                  <div>
                                    <h5 className="amen-tit">
                                      <b>{OtherHouseRule.content}</b>
                                    </h5>
                                  </div>
                                </label>
                                {/* <p className="flow-check-txt text-muted">
                                  {details.description}
                                </p> */}
                              </div>
                            ))
                          : ""}
                      </>
                    ) : (
                      ""
                    )}

                    {details.server_key == "cancellation_policy" ? (
                      <>
                        {OtherRuleLoading == false
                          ? OtherCancellationPolicies.map((OtherCP) => (
                              <div
                                className="form-group form-check tick-check"
                                key={
                                  `other_cancellation_policy_ids` + OtherCP.id
                                }
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="other_cancellation_policy_ids"
                                  id={
                                    `other_cancellation_policy_ids` +
                                    OtherCP.rule_lookup_id
                                  }
                                  value={OtherCP.rule_lookup_id}
                                  onChange={handleAmentiesChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={
                                    `other_cancellation_policy_ids` +
                                    OtherCP.rule_lookup_id
                                  }
                                >
                                  <div>
                                    <h5 className="amen-tit">
                                      <b>{OtherCP.content}</b>
                                    </h5>
                                  </div>
                                </label>
                                {/* <p className="flow-check-txt text-muted">
                                  {details.description}
                                </p> */}
                              </div>
                            ))
                          : ""}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep9;
