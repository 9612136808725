import React, { Component } from "react";
import HostHelper from "../../Helper/hostHelper";

class HostSinglePromoCode extends HostHelper {
  state = {
    promoCodeData: {},
    loadingPromoCode: true,
    error: null,
    loginStatus: true,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false,
    data:{}
  };

  componentDidMount() {
    this.getPromoCodeDetails(this.props.match.params.id);
  }

  render() {
    const { loadingPromoCode, promoCodeData,data } = this.state;
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              {loadingPromoCode ? (
                "Loading..."
              ) : (
                <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                  <form>
                    <div class="panel">
                      <div class="panel-heading">
                        Promo Code {data.title}
                      </div>
                      <div class="panel-body account">
                        <h5 class="m-0 captalize lh-1-4">Promo Code details</h5>
                        <p class="overview-line" />

                        <label class="form-checkbox">
                          Title: {data.title}
                        </label>

                        <label class="form-checkbox">
                          Promo Code: {data.promo_code}
                        </label>

                        <label class="form-checkbox">
                          Amount: {data.amount}
                        </label>

                        <label class="form-checkbox">
                          Amount Type:{" "}
                          {data.amount_type == 1
                            ? "Percentage"
                            : "Absolute"}
                        </label>

                        <label class="form-checkbox">
                          Status:{" "}
                          {data.status == 1 ? "Approved" : "Declined"}
                        </label>

                        <label class="form-checkbox">
                          Number of Users Limits :{" "}
                          {data.no_of_users_limit}
                        </label>

                        <label class="form-checkbox">
                          Per User limit : {data.per_users_limit}
                        </label>

                        <label class="form-checkbox">
                          Description: {data.description}
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HostSinglePromoCode;
