import React, { Component } from "react";
import InputField from "../Helper/inputfield";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
const $ = window.$;

class ForgotPassword extends Helper {
    state = {
        data: {},
        loadingContent: null,
        buttonDisable: false,
      }; 
      
    loginClick = (event) => {
        event.preventDefault();
        $("#forgot-password").modal("hide");
        $("#login").modal("show");
    };

    handleSubmit = (event) => {
        $("#login").modal("hide");
        event.preventDefault();
        this.setState({
        loadingContent: "Loading... Please wait..",
        buttonDisable: true,
        });
        api
        .postMethod("forgot_password", this.state.data)
        .then((response) => {
            if (response.data.success) {
                $("#forgot-password").modal("hide");
                ToastDemo(this.props.toastManager, response.data.message, "success");
                this.setState({ loadingContent: null, buttonDisable: false });
    
            } else {
                ToastDemo(this.props.toastManager, response.data.error, "error");
                this.setState({ loadingContent: null, buttonDisable: false });
            }
        })
        .catch((error) => {
            this.setState({ loadingContent: null });
        });
    };

    render() {
        return (
            <div>
                <div className="modal fade auth" id="forgot-password">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    id="close-forgot"
                                    data-dismiss="modal"
                                >
                                    {/* <i className="material-icons">close</i> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#787878" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </button>
                            </div>

                            <div className="modal-body">
                                <h1 className="section-head">Reset password</h1>
                                <p className="small-line" />
                                <h4>
                                    Enter the email address associated with your
                                    account, and we'll email you a link to reset
                                    your password.
                                </h4>
                                <form className="top1">
                                    <InputField
                                        type="text"
                                        name="email"
                                        onChange={this.handleChange}
                                        placeholder="email"
                                        iconClassName="fas fa-envelope"
                                    />

                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <a
                                                href="#"
                                                className="back-to-login close-forgot"
                                                onClick={this.loginClick}
                                                >
                                                Back to login
                                            </a>
                                            {/* <a
                                                href="#"
                                                className="back-to-login close-forgot"
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#login"
                                            >
                                                <i className="fas fa-chevron-left" />
                                                &nbsp;&nbsp;back to login
                                            </a> */}
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <button
                                                className="pink-btn bottom1"
                                                disabled={this.state.buttonDisable}
                                                onClick={this.handleSubmit}
                                                >
                                                {this.state.loadingContent != null
                                                    ? this.state.loadingContent
                                                    : "Send reset link"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withToastManager(ForgotPassword);
