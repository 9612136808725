import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Search, Grid, Header, Segment } from "semantic-ui-react";
import _ from "lodash";


class HostViewFrontDeskList extends Component {
  state = {
    frontdesk_users: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    isLoading: false,
    results: [],
    value: "",
  };
  componentDidMount() {
    this.frontdeskuserApiCall();
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.message,
        "success"
      );
    }
  }

  handleResultSelect = (e, { result }) =>
    this.setState({ value: result.title });

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          isLoading: false,
          results: [],
          value: "",
        });

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = (result) => re.test(result.name);

      this.setState({
        isLoading: false,
        results: _.filter(this.state.frontdesk_users, isMatch),
      });
    }, 300);
  };

  frontdeskStatusChange = (event, provider_id) => {
    event.preventDefault();

    api
      .postMethod("host_front_desk_status", { provider_id: provider_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({ loading: true, skipCount: 0, frontdesk_users: null });
          this.frontdeskuserApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        }
      });
  };

  deleteFrontDesk = (event, provider_id) => {
    event.preventDefault();
    api
      .postMethod("host_front_desks_delete", { provider_id: provider_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({ loading: true, skipCount: 0, frontdesk_users: null });
          this.frontdeskuserApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        }
      });
  };

  frontdeskuserApiCall() {
    let items;
    api
      .postMethod("host_front_desks_index", { skip: this.state.skipCount })
      .then((response) => {
        if (response.data.success) {
          if (this.state.frontdesk_users != null) {
            items = [...this.state.frontdesk_users, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            frontdesk_users: items,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadingStatus: true,
          });
        }
      });
  }
  loadMore = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });
    this.frontdeskuserApiCall();
  };

  render() {
    const { loading, loadingContent, loadingStatus } = this.state;
    let frontdesk_users;
    const { isLoading, value, results } = this.state;
    if (results.length > 0) {
        frontdesk_users = results;
    } else {
        frontdesk_users = this.state.frontdesk_users;
    }
    return (
      <div className="main">
        <div className="container-fluid">
          <div className="rooms top-bottom-spacing">
            <div className="rooms-head">
              <div className="room-filter input-group mb-3 resp-mrg-btm-xs">
                <Grid>
                  <Grid.Column width={6}>
                    <Search
                      loading={isLoading}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={_.debounce(this.handleSearchChange, 500, {
                        leading: true,
                      })}
                      results={results}
                      value={value}
                      {...this.props}
                    />
                  </Grid.Column>
                </Grid>
              </div>
              <Link className="float-right" to={"/host/create-frontdesk-user"}>
                Add Front Desk User
              </Link>
            </div>
            <div className="room-content">
              <div className="rooms-table table-responsive">
                <table className="cmn-table table">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading
                      ? ""
                      : frontdesk_users.length > 0
                      ? frontdesk_users.map((frontdesk_user, index) => (
                          <tr key={frontdesk_user.promo_code_id}>
                            <td>{index + 1}</td>
                            <td>
                              
                                {frontdesk_user.name}
                            </td>

                            <td>{frontdesk_user.email}</td>
                          
                            <td>{frontdesk_user.mobile}</td>
                            <td>
                              {frontdesk_user.provider_status === 1 ? "Approved" : "Declined"}
                            </td>
                            <td>
                              <ul className="action-menu nav">
                                <li className="nav-item dropdown">
                                  <a
                                    className="dropdown-toggle action-menu-icon"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src={
                                        window.location.origin +
                                        "/assets/img/menu.svg"
                                      }
                                      alt="menu"
                                    />
                                  </a>
                                  <div
                                    className="dropdown-menu dropdown-menu-right animate slideIn"
                                    aria-labelledby="navbarDropdown"
                                  >
                                    <Link
                                      className="dropdown-item"
                                      to={`/host/edit-front-desk/${frontdesk_user.provider_id}`}
                                    >
                                      <i className="far fa-edit" /> Edit
                                    </Link>

                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={(event) =>
                                        window.confirm("Are you sure?") &&
                                        this.frontdeskStatusChange(
                                          event,
                                          frontdesk_user.provider_id
                                        )
                                      }
                                    >
                                      <i className="far fa-copy" />{" "}
                                      {frontdesk_user.provider_status === 1
                                        ? "Decline"
                                        : "Approve"}{" "}
                                    </a>

                                    
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={(event) =>
                                        window.confirm("Are you sure?") &&
                                        this.deleteFrontDesk(
                                          event,
                                          frontdesk_user.provider_id
                                        )
                                      }
                                    >
                                      <i className="fas fa-trash-alt" /> Delete
                                    </a>

                                    <Link
                                      className="dropdown-item"
                                      to={`/host/single-front-desk/${frontdesk_user.provider_id}`}
                                    >
                                      <i className="fas fa-eye" /> Preview
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))
                      : "No data Found"}
                    {loadingStatus ? "" : loadingContent}
                  </tbody>
                </table>
                {loading ? (
                  ""
                ) : frontdesk_users.length > 0 ? (
                  <Link to={"#"} onClick={this.loadMore}>
                    Load More
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostViewFrontDeskList);
