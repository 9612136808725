import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
const $ = window.$;

class EditListingStep5 extends Component {
  state = {
    loadingContent: null,
    submitClicked: false,
    loading: true,
    formData: {
      host_id: this.props.match.params.host_id
    },
    isInputChanged: false,
  };

  componentDidMount() {
    this.initialSteps();
  }

  initialSteps = () => {
    api
      .postMethod("hosts_step_5", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          const formData = {...this.state.formData};
          $(response.data.data.common_questions).each(function(index,details){
            if(details.question_input_type == 'checkbox' || details.question_input_type == 'radio'){
              let array = [];
              $(details.answer).each(function(key,value){
                
                if(value.is_checked) {
                  const arr_value = parseInt(value.key);
                  array.push(arr_value);                  
                }
                
              });
              formData[`amenities[${details.server_key}][answer]`] = array;
            }
          });
        
          this.setState({ formData });

          this.setState({
            loading: false,
            data: response.data.data,
            // formData: { host_id: response.data.data.host_id },
          });
        } else {
          alert(response.data.error);
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  handle5Change = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    console.log("INPUT", input);

    if (input.type === "checkbox") {
      if (input.checked) {
        if (formData[input.name] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          formData[input.name] = array;
          this.setState({ formData });
        } else {
          const value = parseInt(input.value);
          formData[input.name].push(value);
          this.setState({ formData });
        }
      } else {
        const value = parseInt(input.value);
        let index = formData[input.name].indexOf(value);
        if (index !== -1) {
          formData[input.name].splice(index, 1);
          this.setState({ formData });
        }
      }
    }

    if (input.type === "radio") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "text") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "select-one") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "file") {
      formData[input.name] = input.files[0];
      this.setState({ formData });
    }
    // for booking type change.
    setTimeout(() => {
      if (this.state.formData.booking_type != undefined) {
        if (this.state.formData.booking_type == 2) {
          this.setState({ displayTimeslot: true, displayDailySlot: false });
        } else {
          this.setState({ displayTimeslot: false, displayDailySlot: true });
        }
      }
      if (this.state.formData.pricing_type != undefined) {
        this.setState({ pricingType: this.state.formData.pricing_type });
      }
    }, 500);

    this.setState({ isInputChanged: true });
  };

  handleChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step4/${this.props.match.params.host_id}`
    );
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    if (this.state.isInputChanged) {
      api
        .postMethod("hosts_step5_save", this.state.formData)
        .then((response) => {
          if (response.data.success) {
            this.props.history.push(
              `/host/listing-step6/${this.props.match.params.host_id}`
            );
          } else {
            // alert(response.data.error);
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            this.setState({
              loadingContent: null,
              submitClicked: false,
            });
          }
        });
    }

    this.props.history.push(
      `/host/listing-step6/${this.props.match.params.host_id}`
    );
    this.setState({
      loadingContent: null,
      submitClicked: false,
    });
  };

  render() {
    const { data, loading, formData, handle5Change } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>Step 5: Amenities</span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          {/* <div className="add-flow"> */}
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  <div id="step-6" className="flow-tab">
                    <div className="add-flow-block row">
                      <div className="col-md-12">
                        <div className="add-flow-block-left">
                          <h4 className="flow-tit">
                            Choose Amenities that you provide.
                          </h4>
                          <p className="main-txt">
                            Please answer all. If you can't provide an amenity, then select No/Unavailable.
                          </p>
                          {loading ? (
                            "Loading"
                          ) : (
                            <div className="flow-content">
                              {this.state.data.common_questions.map(
                                (details) => (
                                  <div
                                    className="flow-box"
                                    key={details.server_key}
                                  >
                                    <h5 className="flow-box-tit">
                                      {details.provider_question}
                                    </h5>
                                    <input
                                      on={handle5Change}
                                      type="hidden"
                                      value={details.server_key}
                                      name={`amenities[${details.server_key}][server_key]`}
                                    ></input>
                                    {(details.question_input_type ==
                                      "checkbox" ||
                                      details.question_input_type == "radio") &&
                                    details.answer.length > 0 ? (
                                      details.answer.map((ans) => (
                                        <div
                                          className={details.type == "checkbox" ? "form-group form-check tick-check" : "form-group form-check"}
                                          key={`amenities-key${details.server_key}${ans.key}`}
                                        >
                                          <input
                                            type={details.type}
                                            className="form-check-input"
                                            name={`amenities[${details.server_key}][answer]`}
                                            value={ans.key}
                                            onChange={this.handle5Change}
                                            id={`amenities-key${details.server_key}${ans.key}`}                                            
                                            defaultChecked={
                                              ans.is_checked ? true : false
                                            }
                                          />
                                          {console.log(
                                            "check this",
                                            formData[details.server_key] ==
                                              undefined
                                              ? ""
                                              : formData[
                                                  details.server_key
                                                ].indexOf(ans.key) == ans.key
                                              ? true
                                              : formData[
                                                  details.server_key
                                                ].indexOf(ans.key)
                                          )}
                                          <label
                                            className="form-check-label"
                                            htmlFor={`amenities-key${details.server_key}${ans.key}`}
                                          >
                                            <div>
                                              <h5 className="amen-tit">
                                                {ans.value}
                                              </h5>
                                            </div>
                                          </label>
                                        </div>
                                      ))
                                    ) : (
                                      <div className="form-group large-form-group">
                                        <input
                                          type={
                                            details.type == "input"
                                              ? "text"
                                              : details.type
                                          }
                                          className="form-control"
                                          placeholder="Provide accurate text"
                                          name={`amenities[${details.server_key}][answer]`}
                                          onChange={this.handle5Change}
                                          defaultValue={details.answer}
                                        />
                                      </div>
                                    )}
                                    {details.is_description_required != 1 ? (
                                      <hr></hr>
                                    ) : (
                                      ""
                                    )}

                                    {details.is_description_required == 1 ? (
                                      <>
                                        <div className="form-group large-form-group">
                                          <label className="form-check-label">
                                            <h3 className="amen-tit">
                                              Description *
                                            </h3>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Provide accurate description"
                                            name={`amenities[${details.server_key}][description]`}
                                            onChange={this.handle5Change}
                                            defaultValue={details.description}
                                          />
                                        </div>
                                        <hr></hr>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="listing-footer">
                  <button
                    className="white-btn"
                    type="button"
                    onClick={this.handleBackButton}
                  >
                    <i className="fas fa-chevron-left" /> Back
                  </button>
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={this.state.submitClicked}
                    onClick={this.submitForm}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : "Save & Next"}
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditListingStep5;
