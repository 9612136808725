import React, { useEffect,useState} from 'react';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { Link } from "react-router-dom";
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import api from "../../Environment";
import { apiConstants } from "../../components/Constant/constants";
import $ from "jquery";

let yesterday = moment().subtract(1, 'day');
let valid = function (current) {
 return current.isAfter(yesterday);
};

const FilterIndex = (props) => {

  const options = [
    'one', 'two', 'three'
  ];
  const defaultOption = options[0];

  const [activeFilter , setActiveFilter] = useState(null)

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  
  const [loadingKeyword, setLoadingKeyword] = useState(true);
  const [keywordData, setKeywordData] = useState('');
  useEffect(() => {
    api.postMethod("keywords_list",{
      search_keywords:inputValue,
      selected_keywords:props.keywordData,
      is_main:1,
    }).then((response) => {
      if (response.data.success === true) {
        setLoadingKeyword(false);
        setKeywordData(response.data.data);
      }
    });
  }, [props.keywordData.length > 0]);

  const handleDropdown = (fiter) => {
    setActiveFilter(fiter)
  }

  const closeDropDown = () => {
    setActiveFilter(null)
  }
  // load options using API call
  // const loadOptions = async (inputValue) => {
  //   const response = await fetch(`${apiConstants.baseUrl}api/user/keywords_list?search_keywords=${inputValue}`).then(res => res.json());
  //   console.log(response.data);
  //   return response.data;
  // };
  
  const getKeywords = (value,is_main) => {
    setInputValue(value);
    setLoadingKeyword(true);
    setKeywordData('');
    api.postMethod("keywords_list",{
      search_keywords:value,
      selected_keywords:props.keywordData,
      is_main:is_main,
    }).then((response) => {
      if (response.data.success === true) {
       setLoadingKeyword(false);
       setKeywordData(response.data.data);
      } else {
       // Do nothing
      }
     });
  }
  //handle location clear button
  // const handleKeywordCancelButton = (event) => {
  //  var $checkboxes = $("#keyword-type-form :checkbox");
  //  let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
  //  $checkboxes.prop("checked", checkbox_value ? false : '');
  //  event.preventDefault();
  //  setKeywordData('');
  //  setLoadingKeyword(true);
  // };


 return (
  <>
   <div>

    <div className="section-spacing sub-nav filter-nav">
     <ul className="filters">
      <li>
        <div className="filter-calendar">
        <Datetime
         onChange={props.onChangeDate("Range DatePicker")}
         isValidDate={valid}
         dateFormat="DD-MM-YYYY"
         inputProps={{
          placeholder: 'Event Date',
         }}
         closeOnSelect={true}
         timeFormat={false}
         value={moment(props.fromDate)}
        />    
        </div>  
      </li>
      <li className="dropdown">
       <button
        className={
         props.totalGuest != 0
          ?  "filter-active"
          : ""
        }
        id="guest-mobile-btn"
        //data-toggle="dropdown"
        onClick={() => handleDropdown("Guests")}
       >
        {props.totalGuest != 0 ? props.totalGuest : ""}{" "}
        Guests
       </button>
       <div className="filter-dropdown-wrapper">
       {activeFilter == "Guests" && (
          <div className="filter-backdrop" onClick={closeDropDown}></div>
        )}
        <div className={`dropdown-menu guest resp-dropdown-left ${activeFilter == "Guests" ? "show" : ""}`}>
        <div className="inc-dec-count">
         <div className="row guest-options">
          <div className="col-6 col-sm-6">
           <label htmlFor="name">adults</label>
          </div>
          <div className="col-6 col-sm-6 text-right">
           <div className="float-right">
            <div
             className="decrement-btn"
             onClick={() => {
              props.setAdultCount(() => props.adultCount > 0 ? props.adultCount - 1 : 0)
              props.setTotalGuest(() => props.adultCount > 0 ? props.totalGuest - 1 : props.totalGuest)
             }}
            >
             <div className="dec button">
              <i className="fas fa-minus" />
             </div>
            </div>
            <input
             type="text"
             value={props.adultCount}
             name="adults"
            />
            <div
             className="increment-btn"
             onClick={() => {
              props.setTotalGuest(props.totalGuest + 1);
              props.setAdultCount(props.adultCount + 1);
             }}
            >
             <div className="inc button">
              <i className="fa fa-plus" />
             </div>
            </div>
           </div>
          </div>
         </div>
         <div className="row guest-options">
          <div className="col-6 col-sm-6">
           <label htmlFor="name">
            children
            {/* <small className="text-muted">
             <br />
             Ages 2–12
            </small> */}
           </label>
          </div>
          <div className="col-6 col-sm-6 text-right">
           <div className="float-right">
            <div
             className="decrement-btn"
             onClick={() => {
              props.setChildren(() => props.children > 0 ? props.children - 1 : 0)
              props.setTotalGuest(() => props.children > 0 ? props.totalGuest - 1 : props.totalGuest)
             }}
            >
             <div className="dec button">
              <i className="fas fa-minus" />
             </div>
            </div>
            <input
             type="text"
             value={props.children}
             name="children"
            />
            <div
             className="increment-btn"
             onClick={() => {
              props.setTotalGuest(props.totalGuest + 1);
              props.setChildren(props.children + 1);
             }}
            >
             <div className="inc button">
              <i className="fas fa-plus" />
             </div>
            </div>
           </div>
          </div>
         </div>
         <div className="row guest-options">
          <div className="col-6 col-sm-6">
           <label htmlFor="name">
            infants
            {/* <small className="text-muted">
             <br />
             Under 2
            </small> */}
           </label>
          </div>
          <div className="col-6 col-sm-6 text-right">
           <div className="float-right">
            <div
             className="decrement-btn"
             onClick={() => {
              props.setInfants(() => props.infants > 0 ? props.infants - 1 : 0)
              props.setTotalGuest(() => props.infants > 0 ? props.totalGuest - 1 : props.totalGuest)
             }}
            >
             <div className="dec button">
              <i className="fas fa-minus" />
             </div>
            </div>
            <input
             type="text"
             value={props.infants}
             name="infants"
            />
            <div
             className="increment-btn"
             onClick={() => {
              props.setTotalGuest(props.totalGuest + 1);
              props.setInfants(props.infants + 1);
             }}
            >
             <div className="inc button">
              <i className="fas fa-plus" />
             </div>
            </div>
           </div>
          </div>
         </div>
         <div className="row">
          <div className="col-6">
           <Link
            to="#"
            className="cancel-link"
            onClick={(event) => { props.handleGuestCancelButton(event) ; closeDropDown()}}
            //data-toggle="dropdown"
           >
            Clear
           </Link>
          </div>
          <div className="col-6">
           <Link
            to="#"
            className="submit-link"
            onClick={(event) => {event.preventDefault(); props.searchResultApiCall(); closeDropDown()}}
            //data-toggle="dropdown"
           >
            Apply
           </Link>
          </div>
         </div>
        </div>
        </div>
       </div>
      </li>
      {/* <li className=" dropdown">
       {props.totalHomeTypeSelected != 0 ? (
        <button
         className="dropdown-toggle filter-active"
         //data-toggle="dropdown"
         onClick={() => handleDropdown("Space Type")}
        >
         {" "}
         Space Type - {props.totalHomeTypeSelected}
        </button>
       ) : (
        <button className="" onClick={() => handleDropdown("Space Type")}>
         Space Type 
        </button>
       )}

      <div className="filter-dropdown-wrapper">
        {activeFilter == "Space Type" && (
          <div className="filter-backdrop" onClick={closeDropDown}></div>
        )}
        <div className={`dropdown-menu guest account left resp-dropdown-left-md ${activeFilter == "Space Type" ? "show" : ""}`}>
        <div id="home-type-form">
         <div>
          <label className="form-checkbox mb-0">
           Entire place
           <input
            type="checkbox"
            name="host_type"
            onChange={props.handlePlaceType}
            value="Entire Place"
           />
           <span className="checkmark" />
          </label>
          <p className="profile-note ml-35 mt-0">
           Have a place to yourself
          </p>
         </div>

         <label className="form-checkbox mb-0">
          Private place
          <input
           type="checkbox"
           name="host_type"
           onChange={props.handlePlaceType}
           value="Private Place"
          />
          <span className="checkmark" />
         </label>
         <p className="profile-note ml-35 mt-0">
          Have your own room and share some common spaces
         </p>

         <label className="form-checkbox mb-0">
          Shared Room
          <input
           type="checkbox"
           name="host_type"
           onChange={props.handlePlaceType}
           value="Others"
          />
          <span className="checkmark" />
         </label>
         <p className="profile-note ml-35 mt-0">
          Stay in Link shared space, like Link common room
         </p>

         <div className="row">
          <div className="col-6 mt-3">
           <button
            className="cancel-link"
            onClick={(event) => {event.preventDefault() ; props.handleHomeTypeCancelButton(event) ; closeDropDown()}}
            //data-toggle="dropdown"
           >
            Clear
           </button>
          </div>
          <div className="col-6 mt-3">
           <button
            className="submit-link"
            onClick={(event) => {event.preventDefault(); closeDropDown(); props.searchResultApiCall() }}
            //data-toggle="dropdown"
           >
            Apply
           </button>
          </div>
         </div>
        </div>
        </div>
      </div>
      </li> */}

      <li className=" dropdown event-type-dropdown">
       {props.selectedEvent.length > 0 ? (
        <button
         className="filter-active"
         //data-toggle="dropdown"
         onClick={() => handleDropdown("event type")}
        >
         Event Types - <b>{props.selectedEvent.length}</b>
        </button>
       ) : (
        <button className="" onClick={() => handleDropdown("event type")}>
         Event Types
        </button>
       )}

        <div className="filter-dropdown-wrapper">
        {activeFilter == "event type" && (
          <div className="filter-backdrop" onClick={closeDropDown}></div>
        )}
        <div className={`dropdown-menu guest account ${activeFilter == "event type" ? "show" : ""}`}>
        <div id="event-type-form">
         {props.loadingEvent
          ? "Loading..."
          : props.eventData.length > 0
           ? props.eventData.map((events) => (
            <div>
             <label className="form-checkbox mb-0">
              {events.title}
              <input
               type="checkbox"
               name="event_id"
               onChange={(event) =>
                props.handlChangeEvent(event, events)
               }
               checked={props.selectedEvent != null && props.selectedEvent.find((id) => id == events.title)}
              />
              <span className="checkmark" />
             </label>
             <p className="profile-note ml-35 mt-0">
              <>
               {events.description.length > 30
                ? `${events.description.substring(0, 30)}...`
                : events.description}
              </>
             </p>
            </div>
           ))
           : "No data Found"}

         <div className="row">
          <div className="col-6 mt-3">
           <button
            className="cancel-link"
            onClick={() => {props.handleEventCancelButton() ; closeDropDown()}}
            //data-toggle="dropdown"
           >
            Clear
           </button>
          </div>
          <div className="col-6 mt-3">
           <button
            type="button"
            className="submit-link"
            onClick={(event) => { closeDropDown(); props.searchResultApiCall() }}
            //data-toggle="dropdown"
           >
            Apply
           </button>
          </div>
         </div>
        </div>
       </div>
        </div>
      </li>

      <li className="dropdown service-type-dropdown">
       {props.selectedSubCategory.length > 0 ? (
        <button className="dropdown-toggle filter-active"  onClick={() => handleDropdown("Service Type")}>
         Service Type - <b>{props.selectedSubCategory.length}</b>
        </button>
       ) : (
        <button className="dropdown-toggle"  onClick={() => handleDropdown("Service Type")}>
         Service Type
        </button>
       )}

      <div className="filter-dropdown-wrapper">
        {activeFilter == "Service Type" && (
          <div className="filter-backdrop" onClick={closeDropDown}></div>
        )}
        <div className={`dropdown-menu guest account resp-dropdown-left ${activeFilter == "Service Type" ? "show" : ""}`}>
        <div id="subCat-type-form">
         {props.loadingSubCategory ? "loading" : props.subCategoryData.length > 0
          ? props.subCategoryData.map((sub_cat) => (
           <div>
            <label className="form-checkbox mb-0">
             {sub_cat.provider_name}
             <input
              type="checkbox"
              name="sub_category"
              onChange={(event) =>
               props.handlSubCateEvent(event, sub_cat)
              }
              checked={props.subCategoryId != null && props.subCategoryId.find((id) => id == sub_cat.id)}
             />
             <span className="checkmark" />
            </label>
            <p className="profile-note ml-35 mt-0">
             <>
              {sub_cat.provider_name.length > 30
               ? `${sub_cat.provider_name.substring(0, 30)}...`
               : sub_cat.provider_name}
             </>
            </p>
           </div>
          ))
          : "No data Found"}

         <div className="row">
          <div className="col-6 mt-3">
           <button
            className="cancel-link"
            onClick={(event) => {props.handleSubCatCancelButton(event) ; closeDropDown()}}
            //data-toggle="dropdown"
           >
            Clear
           </button>
          </div>
          <div className="col-6 mt-3">
           <button
            className="submit-link"
            onClick={(event) => { event.preventDefault(); props.searchResultApiCall() ; closeDropDown()}}
            //data-toggle="dropdown"
           >
            Apply
           </button>
          </div>
         </div>
        </div>
        </div>
      </div>
      </li>

      <li className=" dropdown location-dropdown">
       {props.selectedLocation.length > 0 ? (
        <button className="dropdown-toggle filter-active" onClick={() => handleDropdown("Location")}>
         Location - <b>{props.selectedLocation.length}</b>
        </button>
       ) : (
        <button className="dropdown-toggle" onClick={() => handleDropdown("Location")}>
         location
        </button>
       )}
        <div className="filter-dropdown-wrapper">
        {activeFilter == "Location" && (
          <div className="filter-backdrop" onClick={closeDropDown}></div>
        )}
        <div className={`dropdown-menu guest account ${activeFilter == "Location" ? "show" : ""}`}>
        <div id="location-type-form">
         {props.loadingLocation
          ? "Loading..."
          : props.locationData.length > 0
           ? props.locationData.map((location) => (
            <div>
             <label className="form-checkbox mb-0">
              {location.service_location_name}
              <input
               type="checkbox"
               name="location"
               onChange={(event) =>
                props.handlLocationEvent(event, location)
               }
               checked={props.selectedLocation != null && props.selectedLocation.find((id) => id == location.service_location_name)}
              />
              <span className="checkmark" />
             </label>
            </div>
           ))
           : "No data Found"}

         <div className="row">
          <div className="col-6 mt-3">
           <button
            className="cancel-link"
            onClick={(event) => {props.handleLocationCancelButton(event); closeDropDown()}}
            //data-toggle="dropdown"
           >
            Clear
           </button>
          </div>
          <div className="col-6 mt-3">
           <button
            className="submit-link"
            onClick={(event) => { event.preventDefault(); props.searchResultApiCall() ;closeDropDown()}}
            //data-toggle="dropdown"
           >
            Apply
           </button>
          </div>
         </div>
        </div>
        </div>
        </div>
      </li>
      <li className="dropdown location-dropdown keyword-dropdown">
        
        {props.keywordData.length > 0 ? (
        <button className="dropdown-toggle filter-active" onClick={() => handleDropdown("Keywords")}>
         Keywords - <b>{props.keywordData.length}</b>
        </button>
        ) : (
          <button className="dropdown-toggle" onClick={() => handleDropdown("Keywords")}>
          Keywords
          </button>
        )}   
        <div className="filter-dropdown-wrapper">
        {activeFilter == "Keywords" && (
          <div className="filter-backdrop" onClick={closeDropDown}></div>
        )}
        <div className={`dropdown-menu guest resp-dropdown-left account ${activeFilter == "Keywords" ? "show" : ""}`}>
        <div id="keyword-type-form">
          <input
            type="text"
            className="filter-keyword-search"
            placeholder="Search Keyword"
            name="keywords" 
            value={inputValue} 
            onChange={(event) => 
              getKeywords(
                event.currentTarget.value,0
              )
            }          
          />
         {props.keywordData.lenght > 0 || keywordData.length > 0
           ? keywordData.map((keyword,index) => (
            <div id={keyword.keywords}>
             <label className="form-checkbox mb-0" id={keyword.keywords} name={`keyword_`+index}>
              {keyword.label}
              <input
               type="checkbox"
               name={`keyword_`+index}
               id={keyword.keywords}
               onChange={(event) =>
                props.onChangeKeyword(event, keyword.keywords)
               }
               checked={props.keywordData != null && props.keywordData.find((id) => id == keyword.keywords)}
               defaultChecked={keyword.is_selected == 1 ? true : false}
              />
              <span id={keyword.keywords} name={`keyword_`+index} className="checkmark" />
             </label>
            </div>
           ))
           : " "}

         <div className="row">
          <div className="col-6 mt-3">
           <button
            className="cancel-link"
            onClick={(event) => {props.handleKeywordCancelButton(event); closeDropDown(); setInputValue('');}}
            //data-toggle="dropdown"
           >
            Clear
           </button>
          </div>
          <div className="col-6 mt-3">
           <button
            className="submit-link"
            onClick={(event) => { event.preventDefault(); props.searchResultApiCall() ; getKeywords('',0); setInputValue('');closeDropDown() }}
            //data-toggle="dropdown"
           >
            Apply
           </button>
          </div>
         </div>
        </div>
        </div>
        </div>
      </li>

      <li
       className="toggle-btn  dis-sm-none dis-md-none"
       id="map-toggle-btn"
       onClick={props.handleMap}
      >
       <h5 className="float-left">Map</h5>
       <label>
        <input
         type="checkbox"
         name="map_view"
         checked={props.mapView}
         className="ios-switch"
        />{" "}
        <div>
         <div />
        </div>
       </label>
      </li>

      <li className="toggle-btn-instant  dis-sm-none dis-md-none">
       <h5 className="float-left">Rapid Booking</h5>
       <label>
        <input
         type="checkbox"
         checked={props.automaticBooking}
         name="is_automatic_booking"
         onChange={props.handleInstantBooking}
         className="ios-switch"
        />{" "}
        <div>
         <div />
        </div>
       </label>
      </li>
     </ul>
     {/* <div className="row">
       <div className="col-md-3 mt-3 pl-1">
        <div className="filter-select d-none d-sm-block">
          <AsyncSelect
            cacheOptions
            isMulti
            placeholder={'Keywords'}
            defaultOptions
            value={props.selectedKeyword}
            getOptionLabel={e => e.label}
            getOptionValue={e => e.value}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            onChange={(event) => props.onChangeKeyword(event)}
          />
        </div>
       </div>
     </div> */}
    </div>
   </div >
  </>
 );
}

export default FilterIndex;