import React, { Component } from "react";
import InputField from "../../Helper/inputfield";

class TripAddOnSerAddModel extends Component {
  state = {};
  handleChange = () => {};
  handleSubmit = (event) => {
    event.preventDefault();
  };
  render() {
    const {
      addOnServiceModelData,
      loadingAddOnServiceModelData,
      addOnSerModelAddButtonDisable,
      addOnSerModelAddLoadingContent,
    } = this.props;
    return (
      <div className="modal fade auth" id="AddOnServiceAdd">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content addon-modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                id="close-AddOnServiceAdd"
                data-dismiss="modal"
              >
                {/* <i className="material-icons">close</i> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#787878" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
              </button>
            </div>
            {loadingAddOnServiceModelData ? (
              "Loading..."
            ) : (
              <div className="modal-body">
                <h1 className="section-head">{addOnServiceModelData.name}</h1>
                <form className="top1">
                  {addOnServiceModelData.unit_type == "person" || addOnServiceModelData.unit_type == "item" ? (
                    <>
                      <p>Min: {addOnServiceModelData.min_value}</p>
                      <p>Max: {addOnServiceModelData.max_value}</p>
                      <p>
                        Amount: {addOnServiceModelData.currency}{" "}
                        {addOnServiceModelData.amount}/
                        {addOnServiceModelData.unit_type}
                      </p>
                      <InputField
                        type="text"
                        // placeholder={addOnServiceModelData.min_value}
                        name={`host_addon_services[${addOnServiceModelData.host_addon_service_id}][no_of_units]`}
                        value={
                          this.props.priceCalInput[
                            `host_addon_services[${addOnServiceModelData.host_addon_service_id}][no_of_units]`
                          ] > 0 ? this.props.priceCalInput[
                            `host_addon_services[${addOnServiceModelData.host_addon_service_id}][no_of_units]`
                          ] :
                          this.props.priceCalInput.amentityValue
                        }
                        onChange={(event) =>
                          this.props.addonServiceQuantity(
                            event,
                            addOnServiceModelData
                          )
                        }
                      />
                    </>
                  ) : ''}
                  <div className="mt-3">
                    <button
                      className="add-btn mr-2"
                      type="submit"
                      onClick={addOnServiceModelData.unit_type == "person" || addOnServiceModelData.unit_type == "item" ? this.props.addonServiceCallAPI :  
                      (event) => this.props.addonServiceBooking(
                        event,
                        addOnServiceModelData
                      )}
                      disabled={addOnSerModelAddButtonDisable}
                    >
                      {addOnSerModelAddLoadingContent != null
                        ? addOnSerModelAddLoadingContent
                        : "Add"}
                    </button>
                    <button
                      className="remove-btn"
                      onClick={(event) =>
                        this.props.removeAddonService(
                          event,
                          `host_addon_services[${addOnServiceModelData.host_addon_service_id}][no_of_units]`
                        )
                      }
                    >
                      Remove
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TripAddOnSerAddModel;
