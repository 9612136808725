import React, { Component } from "react";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

class FaqSection extends Component {
  state = {};
  render() {
    return (
      <div className="site-content">
        <div className="host-top-bottom-spacing">
          <div className="host-section-head">
            <h1>Frequently asked questions</h1>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <h4 className="faq-text">Getting Started</h4>
              <div id="accordion-faq" className="faq">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse1"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Can I list my service on{" "}
                      {configuration.get("configData.site_name")}?
                    </a>
                  </div>
                  <div id="collapse1" className="collapse">
                    <div className="card-body">
                    You can list your space or service with us if it’s related to a party or event and you can legally operate as per the local law in
your location.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse2"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Who can be a {configuration.get("configData.site_name")}{" "}
                      host?
                    </a>
                  </div>
                  <div id="collapse2" className="collapse">
                    <div className="card-body">
                    It’s easy to become a {" "}{configuration.get("configData.site_name")}{" "}
                    host in most areas that we operate, and it’s always free to create a 
                    listing. Be it a party hall, indoor play center, wedding locations, 
                    local bakers, face painters, magicians, inflatable & tent rentals, 
                    transportation services, or music bands. For more details read{" "}
                    <Link to={`/page/Host-Service-Agreement`} target="_blank">
                        {configuration.get("configData.site_name")}
                        {"  "}
                        Host Services Agreement.
                    </Link>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse3"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Does {configuration.get("configData.site_name")} verify
                      hosts?
                    </a>
                  </div>
                  <div id="collapse3" className="collapse">
                    <div className="card-body">
                      {configuration.get("configData.site_name")} does verify each listing and 
                      approve it based on the information provided by the host, 
                      the verification process includes meeting the host virtually, 
                      reviewing the listing content, etc. However, 
                      {" "}{configuration.get("configData.site_name")}{" "} expects the host to be responsible for the accuracy of the listing.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse7"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Does {configuration.get("configData.site_name")} screen guests?
                    </a>
                  </div>
                  <div id="collapse7" className="collapse">
                    <div className="card-body">
                      {configuration.get("configData.site_name")} {" "}does not screen the guest but does 
                      verify some information about the guest that includes a confirmed email 
                      address, the ability to provide a phone number and profile photo. Our payment 
                      gateway provider does additional fraud detection verification at their 
                      end too. As a host, for added security, you can also ask potential 
                      guests to provide an official ID before the event.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <h4 className="faq-text">Earnings</h4>
              <div id="accordion-faq" className="faq">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse4"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      How should I price my listing on{" "}
                      {configuration.get("configData.site_name")}?
                    </a>
                  </div>
                  <div id="collapse4" className="collapse">
                    <div className="card-body">
                    What you charge is always up to you, but as per our Book Assure Program we insist all 
                    hosts not to charge more on {" "}{configuration.get("configData.site_name")}  platform compared to other channels. 
                    The pricing you provide on {" "}{configuration.get("configData.site_name")} should be your best price. 
                    A host should be ready for a price match if they are selling the same service at a cheaper price on a different channel. 
                    To keep your listing competitive and maximize booking we suggest you add exclusive discount coupons to your listing.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse5"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      How do I get paid on {configuration.get("configData.site_name")}{" "}?
                    </a>
                  </div>
                  <div id="collapse5" className="collapse">
                    <div className="card-body">
                      All payments are processed securely through{" "}
                      {configuration.get("configData.site_name")}’s online
                      payment system. Guests are charged when a reservation is made for listings that 
                      allow Rapid Booking, for all other listings the guests are charged when 
                      host approves the booking. The hosts are paid the next business day 
                      after the event. All payments to the host will be processed through our 
                      platform electronically and hence the host needs to connect their bank 
                      account with us via our payment gateway provider.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse6"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      How does {configuration.get("configData.site_name")} make money?
                    </a>
                  </div>
                  <div id="collapse6" className="collapse">
                    <div className="card-body">
                      It’s always free to create a listing with us. We collect a nominal 10% 
                      service fee for every booking made on {" "}{configuration.get("configData.site_name")} platform from the host. 
                      This service fee helps us to cover all the platform development and 
                      maintenance cost, customer support cost, payment gateway charges, and marketing expenses.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse8"
                    >
                      <span>
                        <i className="fas fa-chevron-down" />
                      </span>
                      Does {configuration.get("configData.site_name")} provide any insurance for the hosts or guests?
                    </a>
                  </div>
                  <div id="collapse8" className="collapse">
                    <div className="card-body">
                      We expect all our host to have their own insurance as required, {" "}{configuration.get("configData.site_name")} {" "}
                      is just a listing/marketplace platform and does not provide any insurance coverage for both 
                      guest and host.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqSection;
