import React, { useState, useEffect } from 'react'
import api from "../../../Environment";
import { Link } from "react-router-dom";
import SingleTripPageMap from "../../Helper/singleTripPageMap";
import Confetti from 'react-confetti'


const RapidBookingConfirmation = (props) => {


  const [booking_details, setBookingDetails] = useState({})
  const [loading, setLoading] = useState({})

  useEffect(() => {
    getSingleHistoryDetailsApiCall();
  }, []);

  const getSingleHistoryDetailsApiCall = () => {
    api
      .postMethod("bookings_view", { booking_id: props.location.state })
      .then((response) => {
        if (response.data.success) {
          setBookingDetails({
            ...booking_details,
            ...response.data.data
          })
          setLoading(false)
        }
      });
  };


  return (
    <>
      <div className="main">
        <Confetti />
        <div className="singlehome-img-sec confirmation-page">
          <div className="container h-100">
            <div className="col-xl-7 col-lg-10 col-md-10 auto-margin h-100">
              <div className="confirmation-wrapper">
                {loading ? "loading" : booking_details.is_automatic_booking == 1 ? (
                  <>
                    <h1 className="text-uppercase category-section-head text-center mb-4">Woohoo! Your reservation is confirmed.</h1>
                    <p className="text-center">Relax and let Party Budha host work their magic to make your party a memorable one.</p>
                    <p>Your card has been charged to hold the booking.</p>
                    <svg id="confirm_check" x="0px" y="0px"
                      viewBox="0 0 60 60">
                      <path className="confirmation-check" d="M40.61,23.03L26.67,36.97L13.495,23.788c-1.146-1.147-1.359-2.936-0.504-4.314
                    c3.894-6.28,11.169-10.243,19.283-9.348c9.258,1.021,16.694,8.542,17.622,17.81c1.232,12.295-8.683,22.607-20.849,22.042
                    c-9.9-0.46-18.128-8.344-18.972-18.218c-0.292-3.416,0.276-6.673,1.51-9.578"/>
                    </svg>
                  </>
                ) : (
                  <>
                    <h1 className="text-uppercase category-section-head text-center mb-4">Hang tight, your reservation is pending.</h1>
                    <p className="text-center">This isn’t a confirmed booking yet. Your host will review your request and will respond soon, normally within 24 hours.  Until then sit back and relax!</p>
                    <p>Don’t worry! You won’t be charged until your booking is confirmed by the host.</p>
                    <div className="hourglass-wrapper">
                      <svg viewBox="0 0 73 88">
                        <g id="hourglass">
                          <path d="M63.8761664,86 C63.9491436,84.74063 64,83.4707791 64,82.1818182 C64,65.2090455 57.5148507,50.6237818 48.20041,44 C57.5148507,37.3762182 64,22.7909545 64,5.81818182 C64,4.52922091 63.9491436,3.25937 63.8761664,2 L10.1238336,2 C10.0508564,3.25937 10,4.52922091 10,5.81818182 C10,22.7909545 16.4851493,37.3762182 25.79959,44 C16.4851493,50.6237818 10,65.2090455 10,82.1818182 C10,83.4707791 10.0508564,84.74063 10.1238336,86 L63.8761664,86 Z" id="glass" fill="#ECF1F6"></path>
                          <rect id="top-plate" fill="#4D4544" x="0" y="0" width="74" height="8" rx="2"></rect>
                          <rect id="bottom-plate" fill="#4D4544" x="0" y="80" width="74" height="8" rx="2"></rect>

                          <g id="top-sand" transform="translate(18, 21)">
                            <clipPath id="top-clip-path" fill="white">
                              <rect x="0" y="0" width="38" height="21"></rect>
                            </clipPath>

                            <path clip-path="url(#top-clip-path)" d="M38,0 C36.218769,7.51704545 24.818769,21 19,21 C13.418769,21 1.9,7.63636364 0,0 L38,0 Z"></path>
                          </g>

                          <g id="bottom-sand" transform="translate(18, 55)">
                            <clipPath id="bottom-clip-path" fill="white">
                              <rect x="0" y="0" width="38" height="21"></rect>
                            </clipPath>

                            <g clip-path="url(#bottom-clip-path)">
                              <path className="sand-color" d="M0,21 L38,21 C36.1,13.3636364 24.581231,0 19,0 C13.181231,0 1.781231,13.4829545 0,21 Z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              {loading ? (
                "Loading"
              ) : (
                <>
                  <div className="col-xl-7 col-lg-10 col-md-10 auto-margin">
                    <div className="media">
                      <div className="media-body mr-3">
                        <a href="#">
                          <p className="red-text txt-overflow">
                            #{booking_details.booking_unique_id}
                          </p>
                        </a>
                        <h1 className="category-section-head">
                          {booking_details.host_name}
                        </h1>
                        <h4 className="captalize section-subhead">
                          {booking_details.full_address}
                        </h4>
                        <p>{booking_details.booking_description}</p>
                      </div>
                      <div className="resp-box-1">
                        <Link
                          to={`/provider-profile/${booking_details.provider_details.provider_id}`}
                        >
                          <img
                            src={booking_details.provider_details.picture}
                            alt={booking_details.provider_details.provider_name}
                            className="mt-3 rounded-circle review-img"
                          />
                          <p className="text-center top3 mb-0">
                            <a href="#" className="other-proname">
                              {booking_details.provider_details.provider_name}
                            </a>
                          </p>
                        </Link>
                      </div>
                    </div>

                    <div className="highlights-box">
                      <h2 className="chathead mt-0 d-flex justify-content-between">Event details
                        <span>{booking_details.is_automatic_booking == 1 && (
                          " Rapid Booking"
                        )}</span>
                      </h2>
                      <p className="overview-line" />
                      {booking_details.pricing_type != 3 ?
                        <h5 className="choosen-details">
                          <i className="fas fa-user mr-3" />
                          {booking_details.total_guests} Guest (
                          {booking_details.adults} Adults,{" "}
                          {booking_details.children} Children,{" "}
                          {booking_details.infants} Infants,{" "}
                          {booking_details.age_special} Special Needs)
                        </h5>
                        : ''}
                      {booking_details.pricing_type == 3 ?
                        booking_details.is_custom_pricing == 1 ?
                          <h5 className="choosen-details">
                            <i className="fas fa-address-book mr-3" />
                            Package - Custom Pricing
                          </h5>
                          :
                          <h5 className="choosen-details">
                            <i className="fas fa-address-book mr-3" />
                            Package - {booking_details.host_pricing_packages.name} {booking_details.host_pricing_packages.base_price_formatted}
                          </h5>
                        : ''}
                      <h5 className="choosen-details">
                        <i className="far fa-calendar-alt mr-3" />
                        {booking_details.checkin} {booking_details.host_time_slot ? booking_details.host_time_slot_formatted : ''}

                        {/* {booking_details.booking_type != 1 && (
                        <>
                          <i className="fas fa-arrow-right ml-3 mr-3" />
                          {booking_details.checkout}
                        </>
                      )} */}
                      </h5>

                      <p className="overview-line" />
                      <div className="row">
                        <div className="col-6">
                          <h5 className="choosen-details">Status</h5>
                          <h5 className="choosen-details">Payment Mode</h5>
                          <h5 className="choosen-details">Payment ID</h5>
                          <h5 className="choosen-details">Booked On</h5>
                          {booking_details.is_delivery_service_available ?
                            <h5 className="choosen-details">Service Delivery Address</h5>
                            : ""}

                        </div>

                        <div className="col-6 text-right">
                          <h5 className="choosen-details text-success">
                            {booking_details.status_text}
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.pricing_details.payment_mode} - {booking_details.card_last_four}
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.pricing_details.payment_id}
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.created_at}
                          </h5>
                          {booking_details.is_delivery_service_available ?
                            <h5 className="choosen-details">
                              {booking_details.service_delivery_address}
                            </h5>
                            : ""}
                        </div>
                      </div>

                      <p className="overview-line" />
                      <div className="row">
                        <div className="col-6">
                          {booking_details.pricing_type == 1 ? (
                            <h5 className="choosen-details">Base Price</h5>
                          ) : null}
                          {booking_details.pricing_type == 2 ? (
                            <h5 className="choosen-details">Total Guest Price</h5>
                          ) : null}
                          {booking_details.pricing_type == 3 ? (
                            <h5 className="choosen-details">
                              Total Package Price
                            </h5>
                          ) : null}
                          {/* <h5 className="choosen-details">Cleaning fee</h5> */}
                          <h5 className="choosen-details">Service fee</h5>
                          <h5 className="choosen-details">Tax Price</h5>

                          {/* {booking_details.pricing_details.security_deposit ==
                        0 ? (
                          ""
                        ) : (
                          <h5 className="choosen-details">Security Deposit</h5>
                        )} */}
                          {booking_details.addon_service_details.length > 0 ? (
                            <React.Fragment>
                              {booking_details.addon_service_details.map(
                                (service) => (
                                  <h5 className="choosen-details">
                                    {service.name}
                                  </h5>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {booking_details.pricing_details.promo_code_total ==
                            0 ? (
                            ""
                          ) : (
                            <h5 className="choosen-details">Promo code</h5>
                          )}
                        </div>
                        <div className="col-6 text-right">
                          {booking_details.pricing_type == 1 ? (
                            <h5 className="choosen-details">
                              {
                                booking_details.pricing_details
                                  .time_price_formatted
                              }
                            </h5>
                          ) : null}

                          {booking_details.pricing_type == 2 ? (
                            <h5 className="choosen-details">
                              {
                                booking_details.pricing_details
                                  .total_guest_price_formatted
                              }
                            </h5>
                          ) : null}
                          {booking_details.pricing_type == 3 ? (
                            <h5 className="choosen-details">
                              {
                                booking_details.pricing_details
                                  .package_amount_formatted
                              }
                            </h5>
                          ) : null}
                          {/* <h5 className="choosen-details">
                          {
                            booking_details.pricing_details
                              .cleaning_fee_formatted
                          }
                        </h5> */}
                          <h5 className="choosen-details">
                            {
                              booking_details.pricing_details
                                .service_fee_formatted
                            }
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.pricing_details.tax_price_formatted}
                          </h5>

                          {/* {booking_details.pricing_details.security_deposit ==
                        0 ? (
                          ""
                        ) : (
                          <h5 className="choosen-details">
                            {
                              booking_details.pricing_details
                                .security_deposit_formatted
                            }
                          </h5>
                        )} */}
                          {booking_details.addon_service_details.length > 0 ? (
                            <React.Fragment>
                              {booking_details.addon_service_details.map(
                                (service) => (
                                  <h5 className="choosen-details">
                                    {service.currency}{service.amount}.00
                                  </h5>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {booking_details.pricing_details.promo_code_total ==
                            0 ? (
                            ""
                          ) : (
                            <h5 className="choosen-details">
                              {" - "}{
                                booking_details.pricing_details
                                  .promo_code_total_formatted
                              }
                            </h5>
                          )}
                        </div>
                      </div>

                      <p className="overview-line" />
                      <div className="row">
                        <div className="col-6">
                          <h5 className="choosen-details">Total (CAD)</h5>
                        </div>
                        <div className="col-6 text-right">
                          <h5 className="choosen-details">
                            {
                              booking_details.pricing_details
                                .paid_amount_formatted
                            }
                          </h5>
                        </div>
                        {booking_details.is_automatic_booking == 0 ?
                          <div className="col-12">
                            <h6 className="choosen-details">Note: Amount going to be charged, once the property owner confirms.</h6>
                          </div>
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 pl-5 relative">
                    <div className="trips-map-img">
                      {/* <img
                      src={window.location.origin + "/assets/img/map.png"}
                      className="homes-img"
                    /> */}
                      <SingleTripPageMap location={booking_details} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start w-100">
                    <Link to="/history" className="pink-btn mb-2">
                      View Event History
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RapidBookingConfirmation
