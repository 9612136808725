import React, { useEffect, useState } from "react";
import Helper from "../../Helper/Helper";
import io from "socket.io-client";
// import openSocket from "socket.io-client";
import api from "../../../HostEnvironment";

import { apiConstants } from "./../../Constant/constants";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import ToastDemo from "../../Helper/toaster";
import {
    Form,
    Modal,
    InputGroup,
    FormControl,
    Col,
    Row,
    Button,
} from "react-bootstrap";
import { withToastManager } from "react-toast-notifications";
import ProfileInput from "../../Helper/profileInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import moment from 'moment';
var yesterday = moment().subtract(1, 'day');
var valid = function (current) {
    return current.isAfter(yesterday);
};

const onlyMonth = {
    month: "numeric",
};
const onlyYear = {
    year: "numeric",
};

const $ = window.$;

var today = new Date();
today.setDate(today.getDate());

const CustomPriceModal = (props) => {

    const [state, setState] = useState({
        loading: true,
        hosts: null,
    });

    const [inputData, setInputData] = useState({
        provider_id: localStorage.getItem("hostId"),
        user_id: props.userDetails.id,
        date: props.customPricingRequest ? props.customPricingRequest.checkin: '',
        date_formatted: props.customPricingRequest ? props.customPricingRequest.checkin: '',
    });

    useEffect(() => {
        getHostsApiCall();
    }, []);

    const getDefaultArravingTime = (checkin) => {
        return new Date(checkin);
    };

    const getArrivingDateandTime = (date) => {
        const datess = dayjs(date).format("DD-MM-YYYY");
        setInputData({
            ...inputData,
            date: datess,
            date_formatted: date,
        });
    };

    const getHostsApiCall = () => {
        api
            .postMethod("hosts_list")
            .then(response => {
                console.log(response);
                if (response.data.success === true) {
                    console.log(response.data);
                    setState({
                        ...state,
                        loading: false,
                        hosts: response.data.data,
                    });

                } else {
                    if (response.data.error_code == 1004) {
                        props.toggleLoginModal(false);
                    }
                    ToastDemo(props.toastManager, response.data.error, "error");
                }
            })
            .catch(function (error) { });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        api.postMethod("custom_pricing_request_save", inputData).then((response) => {
            props.handleCustomPricingModal();
            if (response.data.success) {
                ToastDemo(props.toastManager, response.data.message, "success");
            } else {
                ToastDemo(props.toastManager, response.data.error, "error");
                return false;
            }
        });
    };

    return (
        <>
            <Modal
                className="modal-dialog-center payment-add-card-modal"
                size="lg"
                centered
                show={props.requestCustomPricingModel}
                onHide={props.handleCustomPricingModal}
            >
                {props.requestCustomPricingModel ? (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Custom Pricing</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm={12} md={12}>
                                    <form onSubmit={handleSubmit}>
                                        <div className="custom-pricing-form">
                                            <div className="account add-promo-code">
                                                <div className="row">
                                                    <div className="col-3 text-right">
                                                        <label>Description</label>
                                                    </div>
                                                    <div className="col-9 mb-2">
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            rows="4"
                                                            id="message"
                                                            placeholder="Describe what is included and not included in the quoted price"
                                                            name="message"
                                                            value={inputData.message}
                                                            onChange={(event) => {
                                                                setInputData({
                                                                    ...inputData,
                                                                    message: event.currentTarget.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <ProfileInput
                                                    label="Amount"
                                                    type="number"
                                                    placeholder="Amount"
                                                    id="amount"
                                                    name="amount"
                                                    value={inputData.amount}
                                                    onChange={(event) => {
                                                        setInputData({
                                                            ...inputData,
                                                            amount: event.currentTarget.value,
                                                        });
                                                    }}
                                                    description=""
                                                />

                                                <div className="form-group row">
                                                    <div className="col-3 text-right">
                                                        <label>Event Date</label>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="custom-select-alike">
                                                            <DatePicker
                                                                minDate={today}
                                                                selected={inputData.date ? getDefaultArravingTime(inputData.date_formatted) : ''}
                                                                onChange={getArrivingDateandTime}
                                                                excludeDates={[today]}
                                                                placeholderText="Click to select a date"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* {state.loading ? "loading.." :
                                                    <div className="form-group row">
                                                        <div className="col-3 text-right">
                                                            <label>Property</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <select
                                                                onChange={(event) => {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        host_id: event.currentTarget.value,
                                                                    });
                                                                }}
                                                                className="form-control same-date-picker-input packageDropdown" id="packageDropdown"
                                                            >
                                                                <option value="" selected="selected">Select Property</option>
                                                                {state.hosts.map((host, index) => (

                                                                    <option
                                                                        key={index}
                                                                        value={host.id}
                                                                    >
                                                                        {host.host_name}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        </div>

                                                    </div>
                                                } */}

                                            </div>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                className="btn btn-danger width-btn"
                                data-dismiss="modal"
                                onClick={props.handleCustomPricingModal}
                            >
                                CANCEL
                            </Button>
                            <Button
                                type="submit"
                                className="btn btn-success width-btn"
                                data-dismiss="modal"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Form>
                ) : (
                    ""
                )}
            </Modal>
        </>
    );
};

export default withToastManager(CustomPriceModal);
