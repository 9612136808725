import React from 'react'
import Helper from '../../Helper/Helper'
import io from "socket.io-client";
// import openSocket from "socket.io-client";
import api from "../../../Environment";

import { apiConstants } from "./../../Constant/constants";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";

let socket;

class ChatModal extends Helper {

  state = {
    message: null,
    data: null,
    chatList: null,
    loadingChatList: true,
    socket: false,
    inputMessage: null,
    chatData: null,
    loadingChatData: true,
    headerDetail: null,
    headerDetailsLoadFirst: true,
    headerContentLoading: true,
    loadingStatus: true,
    loadingContent: null,
    loadingStatusChatHistory: true,
    loadingContentChatHistory: null,
    chatListskipCount: 0,
    chatHistorySkipCount: 0,
    loadMoreChatHistory: false,
    chatIndex: 0,
    loading: true,
    chatAssetId:'',
    messageType: this.props.priceCalInput.host_pricing_package_id == "custom_price" ? 1 : 0,
    chatSidebar: {
      status: true,
      desktop: false
    }
  };

  componentDidMount() {
    // Call api function
    if (localStorage.getItem("userLoginStatus")) {
      this.socketConnectionfun(
        localStorage.getItem("userId"),
        this.props.provider_details.provider_id,
        this.props.host_details.host_id
      );
      this.chatDetailsApiCall();
      if (window.innerWidth < 768) {
        this.setState({
          ...this.state,
          loading: false,
          chatSidebar: {
            status: false,
            desktop: false
          }
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
          chatSidebar: {
            status: true,
            desktop: true
          }
        });
      }
      window.addEventListener('resize', this.handleChatsidebar);
      this.scrollDown();
    }
  }

  componentWillUnmount() {
    socket.disconnect();
    window.removeEventListener('resize', this.handleChatsidebar);
  }

  handleChatsidebar = () => {
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state, chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state, chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }
  };

  handleChatSideBarToggler = (toggle_status) => {
    this.setState({
      ...this.state, chatSidebar: {
        ...this.state.chatSidebar,
        status: toggle_status,
      }
    });
  }

  socketConnectionfun = (userId, providerId, hostId) => {
    let socketUrl = configuration.get("configData.chat_socket_url");
    socket = io(socketUrl, {
      query:
        `commonid: 'user_id_` +
        userId +
        `_provider_id_` +
        providerId +
        `_host_id_` +
        hostId +
        `', myid: ` +
        userId,
    });

    socket.emit("update sender", {
      commonid:
        "user_id_" +
        userId +
        "_provider_id_" +
        providerId +
        "_host_id_" +
        hostId,
      myid: userId,
      userId: userId,
    });
    let chatContent;
    socket.on("message", (newData) => {
      //  if(newData.chattype)

      let content = [];
      content.push(newData);
      // this.messageStatusApiCall(newData.provider_id);
      chatContent = [...this.state.chatData, ...content];
      this.setState({ chatData: chatContent }, () => this.scrollDown());
    });
  };

  messageStatusApiCall = (provider_id) => {
    let inputData;

    inputData = {
      user_id: localStorage.getItem("userId"),
      provider_id: provider_id,
    };

    api
      .postMethod("chat_messages_status", inputData)
      .then((response) => {
        if (response.data.success) {

        } else {

        }
      });
  };
  updateDocument = (event) => {
    let inputData;

    if (event.currentTarget.type === "file") {

      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      reader.onloadend = () => {
        
      };
      inputData = {
        file: file,
        file_type: file.type.match("image") ? "image" : "document",
        user_id: localStorage.getItem("userId"),
        host_id: this.props.priceCalInput.host_id,
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      api
      .postMethod("chat_assests_save", inputData)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.data.id);
          this.setState({
            ...this.state,
            chatAssetId: response.data.data.id,
          });
        } else {

        }
      });
    }
  };

  chatDetailsApiCall = () => {
    let inputData;

    if (this.props.host_details.booking_id === undefined) {
      inputData = {
        host_id: this.props.host_details.host_id,
        provider_id: this.props.provider_details.provider_id,
        skip: this.state.chatHistorySkipCount,
        status: 1,
      };
    } else {
      inputData = {
        booking_id: this.props.host_details.booking_id,
        host_id: this.props.host_details.host_id,
        provider_id: this.props.provider_details.provider_id,
        skip: this.state.chatHistorySkipCount,
        status: 1,
      };
    }

    api.postMethod("bookings_chat_details", inputData).then((response) => {
      let chathistory;
      if (response.data.success) {
        if (this.state.loadMoreChatHistory) {
          if (this.state.chatData != null) {
            chathistory = [
              ...response.data.data.reverse(),
              ...this.state.chatData,
            ];
          } else {
            chathistory = [...response.data.data.reverse()];
          }
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount:
              response.data.data.length + this.state.chatHistorySkipCount,
            loadingStatusChatHistory: true,
            loadMoreChatHistory: false,
          });
        } else {
          chathistory = response.data.data.reverse();
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount: response.data.data.length,
            loadingStatusChatHistory: true,
          }, () => this.scrollDown());
        }
      } else {
        //
      }
    });
  };

  handleOnChange = ({ currentTarget: input }) => {
    if (input.name == 'request_type') {
      this.setState({ messageType: input.value });
    } else {
      this.setState({ inputMessage: input.value });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let is_emit_start = 0;
    if (this.state.messageType == 1) {
      let customInputData = {
        provider_id: this.props.provider_details.provider_id,
        host_id: this.props.host_details.host_id,
        user_id: localStorage.getItem("userId"),
        message: this.state.inputMessage,
      };

      api.postMethod("custom_pricing_status", customInputData)
        .then((response) => {
          console.log(response.data.success);
          if (response.data.success == false) {
            ToastDemo(
              this.props.toastManager,
              response.data.error,
              "error"
            );
            return false;
          } else {
            this.chatEmitStart();
          }
        });
    } else {
      is_emit_start = 1;
    }

    if (is_emit_start) {
      this.chatEmitStart();
    }
  };

  chatEmitStart = () => {
    if(this.state.inputMessage) {
      let online_status = 0;
      let inputData = { provider_id: this.props.provider_details.provider_id };
      api.postMethod("online_status", inputData)
        .then((response) => {
          if (response.data.success) {
            online_status = response.data.data.status;
          }

          let chatData = [
            {
              user_id: localStorage.getItem("userId"),
              provider_id: this.props.provider_details.provider_id,
              message: this.state.inputMessage,
              chat_asset_id: this.state.chatAssetId,
              request_type: this.state.messageType,
              host_id: this.props.host_details.host_id,
              booking_id: this.props.host_details.booking_id,
              chat_type: "up",
              notification_type: 1,
              updated: 'Just now',
              user_picture: localStorage.getItem("user_picture"),
              user_status: 1,
              custom_price_request_status: 0,
              provider_status: online_status == 1 ? 1 : 0,
              sender_id: localStorage.getItem("userId"),
            },
          ];

          socket.emit("message", chatData[0]);

          let messages;

          if (this.state.chatData != null) {
            messages = [...this.state.chatData, ...chatData];
          } else {
            messages = [...chatData];
          }
          this.setState({
            chatData: messages,
            inputMessage: "",
          }, () => this.scrollDown());
        });
    }
  }
  scrollDown = () => {

    const objDiv = document.getElementById("messages-holder");

    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      //console.log(differenceNumber)

      if (differenceNumber > 100) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = this.state.initialHeight;
        this.setState({
          ...this.state,
          initialHeight: this.state.initialHeight + 20,
        });
      }
    }
  };


  loadMoreChatHistory = (event) => {
    event.preventDefault();
    this.setState({
      loadingStatusChatHistory: false,
      loadingContentChatHistory: "Loading...",
      loadMoreChatHistory: true,
    });
    this.chatDetailsApiCall();
  };

  render() {

    const {
      chatList,
      loadingChatList,
      chatData,
      loadingChatData,
      headerDetail,
      headerContentLoading,
      loadingStatus,
      loadingContent,
      loadingContentChatHistory,
      loadingStatusChatHistory,
      loading,
      chatHistorySkipCount,
    } = this.state;

    const { apiResponse, host_details, provider_details, pricing_details, page, priceCalInput } = this.props;
    // console.log(apiResponse);
    // console.log("apiResponse.response_time_hrs",apiResponse ? apiResponse.response_time_hrs :'');
    return (
      <>
        <div className="modal fade show" id="chatModal">
          <div className="modal-dialog modal-dialog-centered chat-modal-width">
            <div className="modal-content ">
              <div className="modal-body ">
                <div className={`main chatWrapper`} id="hostChat">
                  {loading ? (
                    "Loading..."
                  ) : apiResponse != null && (
                    <div className="row m-0 w-100">
                      {this.state.chatSidebar.status && (
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 chat-leftsec">
                          <div className="chat-details text-center">
                            <div className="providerDetailswrapper">
                              <img
                                src={provider_details.picture}
                                className="chat-profile-img"
                              />
                              <div className="providerDetails">
                                <h4 className="chat-provider-head">
                                  {provider_details.provider_name}
                                </h4>
                                <div className="ratingWrapper">
                                  <span className="rating">
                                    <i class="fas fa-star"></i>
                                  </span>
                                  <span className="ratingText">
                                    {provider_details.overall_ratings}
                                  </span>
                                  <span className="reviewText">
                                    ( {provider_details.total_reviews_formatted})
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="text-left hostPriceDetails">
                              <h2 className="chathead">Event details</h2>
                              {this.props.host_details.host_name}<br></br>
                              {(apiResponse.response_time_hrs != null && apiResponse.response_time_mins != null) ? (
                                <p className="choosen-details">
                                  Response Time: {apiResponse.response_time_hrs > 0 ? apiResponse.response_time_hrs + " hours and" : ''}   {" "}
                                  {apiResponse.response_time_mins} Minutes
                                </p>
                              ) : ''}
                              <p className="overview-line my-3" />
                              <div className="row">
                                <div className="col-12 d-flex justify-content-between">
                                  {apiResponse.booking_type == 1 ? page == "singletrip" ? (
                                    <>
                                      {/* <h5 className="choosen-details">
                                        <span>{apiResponse.checkin}</span>
                                        <span>{" "}to{" "}</span>
                                        <span>{apiResponse.checkout}</span>
                                      </h5> */}
                                      <h5 className="choosen-details text-capitalize">
                                        check in
                                      </h5>
                                      <h5 className="choosen-details">
                                        {apiResponse.checkin}
                                      </h5>
                                    </>
                                  ) : (
                                    <div>
                                      <h5 className="choosen-details">
                                        <i className="far fa-calendar-alt mr-3" />
                                        {host_details.checkin}
                                        <i className="fas fa-arrow-right ml-3 mr-3" />
                                        {host_details.checkout}
                                      </h5>
                                      <div className="d-flex justify-content-between">
                                        <h5 className="choosen-details">
                                          Total Nights
                                        </h5>
                                        <h5 className="choosen-details">
                                          {host_details.total_days}{" "} {host_details.total_days_text}
                                        </h5>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <h5 className="choosen-details slotBased">
                                        <small>checkin</small>
                                        {apiResponse.checkin}
                                      </h5>
                                      <h5 className="choosen-details slotBased">
                                        <small className="text-right">Slot</small>
                                        {apiResponse.host_time_slot_formatted}
                                      </h5>
                                    </>
                                  )}
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                  <h5 className="choosen-details">
                                    Total Guest
                                  </h5>
                                  <h5 className="choosen-details">
                                    {page == "singletrip" ? apiResponse.total_guests : host_details.total_guests} guests
                                  </h5>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                  {apiResponse.pricing_type == 1 ? (
                                    <>
                                      <h5 className="choosen-details">Base Price</h5>
                                      <h5 className="choosen-details">
                                        {page == "singletrip" ? apiResponse.base_price : host_details.pricing_details.base_price_formatted}
                                      </h5>
                                    </>
                                  ) : null}
                                  {apiResponse.pricing_type == 2 ? (
                                    <>
                                      <h5 className="choosen-details">Total Guest Price</h5>
                                      <h5 className="choosen-details">
                                        {page == "singletrip" ? apiResponse.total_guest_price_formatted : host_details.pricing_details.base_price_formatted}
                                      </h5>
                                    </>
                                  ) : null}
                                  {apiResponse.pricing_type == 3 ? (
                                    <>
                                      <h5 className="choosen-details">Total Package Price</h5>
                                      <h5 className="choosen-details">
                                        {page == "singletrip" ? apiResponse.package_base_price : host_details.pricing_details.base_price_formatted}
                                      </h5>
                                    </>
                                  ) : null}

                                  {/* <h5 className="choosen-details">
                                    Base Price
                                  </h5>
                                  <h5 className="choosen-details">
                                    {page == "singletrip" ? apiResponse.base_price : host_details.pricing_details.base_price_formatted}
                                  </h5> */}
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                  <h5 className="choosen-details">
                                    Addon Services
                                  </h5>
                                  <h5 className="choosen-details">
                                    {page == "singletrip" ? apiResponse.host_addon_services_total : host_details.pricing_details.addon_service_total_formatted}
                                  </h5>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                  <h5 className="choosen-details">
                                    Tax
                                  </h5>
                                  <h5 className="choosen-details">
                                    {page == "singletrip" ? apiResponse.tax_price_formatted : host_details.pricing_details.tax_price_formatted}
                                  </h5>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                  <h5 className="choosen-details">
                                    Service fee
                                  </h5>
                                  <h5 className="choosen-details">
                                    {page == "singletrip" ? apiResponse.service_fee : host_details.pricing_details.service_fee_formatted}
                                  </h5>
                                </div>
                                {apiResponse && apiResponse.promo_code_details ?
                                  <div className="col-12 d-flex justify-content-between">
                                    <h5 className="choosen-details">
                                      Promo Code
                                    </h5>
                                    <h5 className="choosen-details">
                                      - {page == "singletrip" ? apiResponse.promo_amount_formatted : ''}
                                    </h5>
                                  </div>
                                  : ''}

                                <div className="col-12 d-flex justify-content-between">
                                  <h5 className="choosen-details">Cleaning fee</h5>
                                  <h5 className="choosen-details">
                                    {page == "singletrip"
                                      ? apiResponse.cleaning_fee
                                      : pricing_details.cleaning_fee_formatted}
                                  </h5>
                                </div>
                              </div>

                              <p className="overview-line my-3" />
                            </div>
                            <div className="chatleft-footer">
                              <div className="row">
                                <div className="col-6">
                                  <h5 className="choosen-details total text-left">Total </h5>
                                </div>
                                <div className="col-6 text-right">
                                  <h5 className="choosen-details total text-right">
                                    {page == "singletrip"
                                      ? apiResponse.actual_total_formatted
                                      : host_details.total_formatted}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 chat-rightsec">
                        <div className="relative height-100">
                          <div className="chat-header">
                            <div class="chat-list-box single-chat-box">
                              {/* <div className="chatSidebarToggler ml-2" onClick={() => this.props.history.goBack()}>
                              <i class="fas fa-arrow-left"></i>   
                            </div> */}
                              <div class="chat-prof-img">
                                <img
                                  src={provider_details.picture}
                                  alt={host_details.host_name}
                                ></img>
                              </div>
                              <div class="chat-prof-content">
                                <h5 class="chat-name">{host_details.host_name}</h5>
                                {(apiResponse.response_time_hrs != null && apiResponse.response_time_mins != null) ? (
                                  <p className="chat-date">
                                    Response Time: {apiResponse.response_time_hrs > 0 ? apiResponse.response_time_hrs + " hours and" : ''}   {" "}
                                    {apiResponse.response_time_mins} Minutes
                                  </p>
                                ) : ''}
                              </div>
                              {this.state.chatSidebar.desktop || (
                                <>
                                  {this.state.chatSidebar.status ? (
                                    <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(false)}>
                                      <i class="fas fa-times"></i>
                                    </div>
                                  ) : (
                                    <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(true)}>
                                      <i class="fas fa-bars"></i>
                                    </div>
                                  )}
                                </>
                              )}
                              {this.state.chatSidebar.desktop ? (
                                <div className="ml-md-auto mr-2 ml-2">
                                  <button type="button" className="close" onClick={() => this.props.handleChatModal(false)}>
                                    <i class="fas fa-times"></i>
                                  </button>
                                </div>
                              ) : (
                                <>
                                  {this.state.chatSidebar.status ? null : (
                                    <div className="ml-md-auto mr-2 ml-2">
                                      <button type="button" className="close" onClick={() => this.props.handleChatModal(false)}>
                                        <i class="fas fa-times"></i>
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                              <div class="clear-both"></div>
                            </div>
                          </div>

                          <div className="chat-content" id="messages-holder">
                            <div className="text-center">
                              {loadingStatusChatHistory ? "" : loadingContentChatHistory}
                              <a
                                href="category/index.html"
                                className="show-all"
                                onClick={(event) => this.loadMoreChatHistory(event)}
                              >
                                {loadingChatData
                                  ? ""
                                  : chatHistorySkipCount > 0
                                    ? "Show More"
                                    : ""}
                              </a>
                            </div>
                            {loadingChatData
                              ? ""
                              : chatData.map((chat, index) =>
                                <>
                                  {chat.confirmed_booking_id || chat.draft_booking_id ?
                                    <div className="offer-list-box">
                                      <h4>{chat.bookings.host_name}</h4>
                                      <p>{chat.message}</p>
                                      {chat.confirmed_booking_id ?
                                        <ul className="list-unstyled offer-list">
                                          <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                          <li><i class="far fa-clock mr-2"></i> Booked on: {chat.bookings.created}</li>
                                          <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                                        </ul> :
                                        <ul className="list-unstyled offer-list">
                                          <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                          <li><i class="far fa-clock mr-2"></i> Enquired on: {chat.bookings.created}</li>
                                          <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                                        </ul>
                                      }
                                      {chat.confirmed_booking_id ?
                                        <Link to={`/history-details/${chat.bookings.booking_id}`} className="view-order-btn">View Bookings</Link>
                                        : ''}
                                    </div>
                                    :
                                    chat.chat_type == "pu" ? (
                                      <div className="chat-left">
                                        <div className="display-inline">
                                          <img
                                            className="chat-img-left"
                                            src={chat.provider_picture}
                                          />
                                          <div className="chat-content-right">
                                            <div className="chat-message">
                                              {chat.message}{" "}
                                            </div>
                                            <p className="clearfix m-0" />
                                            <p className="chat-time">{chat.updated}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="clearfix" />
                                        <div className="chat-right">
                                          <div className="display-inline">
                                            <div className="chat-content-right">
                                              <div className="chat-message">
                                                {chat.message}{" "}
                                              </div>
                                              <p className="clearfix m-0" />
                                              <div className="chat-status-indication-right">
                                                <div className="chat-status-indicate-sec">
                                                <div className="chat-status-preview-doc-sec">
                                                {chat.chat_assets && chat.chat_assets.file ?
                                                  <p  className="preview-attach-info"><a href={chat.chat_assets.file} target="_blank">Preview Document</a></p>
                                                  : ''}
                                                </div>
                                                <div className="chat-status-indication-new">
                                                  <p className="chat-time text-right">
                                                    {chat.updated}
                                                  </p>
                                                  {chat.provider_status == 1 ?
                                                    <p className="read-msg-indication mr-1">Read</p>
                                                    :
                                                    <p className="delivered-msg-indication mr-1">Sent</p>
                                                  }
                                                  <p className="clearfix m-0" />
                                                  {chat.request_type == 1 && chat.custom_price_request_status == 1 ?
                                                    <p className="chat-time status-msg-indication">Approved</p>
                                                    : chat.request_type == 1 && chat.custom_price_request_status == 2 ?
                                                      <p className="chat-time status-msg-indication">Rejected</p>
                                                      : ''}
                                                </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                </>
                              )}
                          </div>

                          <div className="chat-footer">
                            <form onSubmit={this.handleSubmit} className="chat-attachement-form">
                            <div>
                                  {/* <input
                                    className="form-check-input"
                                    type="radio"
                                    name="request_type"
                                    id="message"
                                    value="0"
                                    defaultChecked={priceCalInput.host_pricing_package_id == 'custom_price' ? false : true}
                                    onChange={this.handleOnChange}
                                  />
                                  <label
                                    className="form-check-label row padding-left-step-1"
                                    htmlFor="message"
                                  >
                                    <p
                                      className="flow-check-txt text-dark"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>Message</b>
                                    </p>
                                  </label>
                                  <div className="clear-both" /> */}

                                  {/* <input
                                    className="form-check-input"
                                    type="radio"
                                    name="request_type"
                                    id="pricing"
                                    value="1"
                                    defaultChecked={priceCalInput.host_pricing_package_id == 'custom_price' ? true : false}
                                    onChange={this.handleOnChange}
                                  />
                                  <label
                                    className="form-check-label row padding-left-step-1"
                                    htmlFor="pricing"
                                  >
                                    <p
                                      className="flow-check-txt text-dark"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <b>Pricing</b>
                                    </p>
                                  </label>
                                  <div className="clear-both" /> */}
                                </div>
                              <div className="input-group dropdown">
                                <input
                                  type="text"
                                  className="form-control dropdown-toggle"
                                  data-toggle="dropdown"
                                  placeholder="enter your message"
                                  name="message"
                                  value={this.state.inputMessage}
                                  onChange={this.handleOnChange}
                                />
                                {this.state.messageType == 1 ? 
                                  <div
                                    className="input-group-append"
                                  >
                                      <input type="file" id="file" onChange={(event) => this.updateDocument(event)}/>
                                      <label for="file"> <i className="fas fa-paperclip"></i></label>
                                  </div>
                                :''}
                                <div
                                  className="input-group-append"
                                  onClick={this.handleSubmit}
                                >
                                  <span className="input-group-text" id="basic-addon">
                                    <i className="fas fa-paper-plane" />
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withToastManager(ChatModal);
