import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class EditListingStep3 extends Component {
  state = { ageLimits: [],infantsageLimits:[] ,childrenageLimits:[]};

  state = {
    loading: true,
    submitClicked: false,
    hostData: null,
    formData: {
      total_guests: 0,
      min_guests: null,
      max_guests: null,
      adult_from: 16,
      adult_to: 100,
      child_from: 2,
      child_to: 15,
      infants_from: 0,
      infants_to: 1,
    },
    loadingContent: null,
    isInputChanged: false,
  };
  componentDidMount() {
    this.ageLimitsGenerate();
    this.initialSteps();
  }
  initialSteps = () => {
    api
      .postMethod("hosts_step_3", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            data: response.data.data,
            formData: {
              host_id: response.data.data.host_id,
              min_guests: response.data.data.min_guests,
              max_guests: response.data.data.max_guests,
              adult_from: response.data.data.adult_from,
              adult_to: response.data.data.adult_to,
              child_from: response.data.data.child_from,
              child_to: response.data.data.child_to,
              infants_from: response.data.data.infants_from,
              infants_to: response.data.data.infants_to,
              age_special_from: response.data.data.age_special_from,
              age_special_to: response.data.data.age_special_to,
            },
          });
        } else {
          alert(response.data.error);
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  ageLimitsGenerate = () => {
    const ageLimitsarray = [];
    for (let index = 0; index <= 100; index++) {
      ageLimitsarray.push(index);
      this.setState({ ageLimits: ageLimitsarray });
    }

    const childrenageLimitsarray = [];
    for (let index = 2; index < 16; index++) {
      childrenageLimitsarray.push(index);
      this.setState({ childrenageLimits: childrenageLimitsarray });
    }


    const infantsageLimitsarray = [];
    for (let index = 0; index < 2; index++) {
      infantsageLimitsarray.push(index);
      this.setState({ infantsageLimits: infantsageLimitsarray });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });

    this.setState({ isInputChanged: true });
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step2/${this.props.match.params.host_id}`
    );
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });

    if (this.state.isInputChanged) {
      api
        .postMethod("hosts_step3_save", this.state.formData)
        .then((response) => {
          if (response.data.success) {
            this.props.history.push(
              `/host/listing-step4/${this.props.match.params.host_id}`
            );
          } else {
            // alert(response.data.error);
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            this.setState({
              loadingContent: null,
              submitClicked: false,
            });
          }
        });
    }
    this.props.history.push(
      `/host/listing-step4/${this.props.match.params.host_id}`
    );
    this.setState({
      loadingContent: null,
      submitClicked: false,
    });
  };

  render() {
    const { formData, loadingContent, submitClicked } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 3: Start with the basics
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          {/* <div className="add-flow"> */}
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  <div id="step-3" className="flow-tab">
                    {this.state.loading ? (
                      "Loading.."
                    ) : (
                      <div className="add-flow-block row">
                        <div className="col-md-12">
                          <div className="add-flow-block-left">
                            <h4 className="flow-tit">
                              How many guests are allowed per booking?
                            </h4>
                            <p className="main-txt">
                              Ensure you have sufficient capacity, this
                              information helps us to filter the right property
                              for the guest based on guest count.
                            </p>
                            <div className="flow-content">
                              <div className="form-group large-form-group">
                                <label className="light-txt">
                                  Minimum number of guests
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Should be one or more"
                                  name="min_guests"
                                  value={formData.min_guests}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="form-group large-form-group">
                                <label className="light-txt">
                                  Maximum number of guests allowed per booking
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Maximum number of guests allowed"
                                  name="max_guests"
                                  value={formData.max_guests}
                                  onChange={this.handleChange}
                                />
                              </div>

                              <div className="row age-limit-content">
                                <div className="col-md-12">
                                  <label className="light-txt">
                                    Age limit for adult
                                  </label>
                                </div>
                                <div className="col-md-6 col-xl-4 col-lg-4 resp-mrg-btm-xs">
                                  <select
                                    name="adult_from"
                                    className="form-control"
                                    placeholder="From"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.ageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          selected={
                                            value == formData.adult_from
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-6 col-xl-4 col-lg-4">
                                  <select
                                    name="adult_to"
                                    className="form-control"
                                    placeholder="To"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.ageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          selected={
                                            value == formData.adult_to
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>

                              <div className="row age-limit-content">
                                <div className="col-md-12">
                                  <label className="light-txt">
                                    Age limit for children
                                  </label>
                                </div>

                                <div className="col-md-6 col-xl-4 col-lg-4 resp-mrg-btm-xs">
                                  <select
                                    name="child_from"
                                    className="form-control"
                                    placeholder="From"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.childrenageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          selected={
                                            value == formData.child_from
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-6 col-xl-4 col-lg-4">
                                  <select
                                    name="child_to"
                                    className="form-control"
                                    placeholder="To"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.childrenageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          // selected={value == 100 ? true : false}
                                          selected={
                                            value == formData.child_to
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="row age-limit-content">
                                <div className="col-md-12">
                                  <label className="light-txt">
                                    Age limit for infants
                                  </label>
                                </div>

                                <div className="col-md-6 col-xl-4 col-lg-4 resp-mrg-btm-xs">
                                  <select
                                    name="infants_from"
                                    className="form-control"
                                    placeholder="From"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.infantsageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          selected={
                                            value == formData.infants_from
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-6 col-xl-4 col-lg-4">
                                  <select
                                    name="infants_to"
                                    className="form-control"
                                    placeholder="To"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.infantsageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          // selected={value == 100 ? true : false}
                                          selected={
                                            value == formData.infants_to
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="row age-limit-content">
                                <div className="col-md-12">
                                  <label className="light-txt">
                                    Age limit for people with special needs
                                  </label>
                                </div>

                                <div className="col-md-6 col-xl-4 col-lg-4 resp-mrg-btm-xs">
                                  <select
                                    name="age_special_from"
                                    className="form-control"
                                    placeholder="From"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.ageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          selected={
                                            value == formData.age_special_from
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-6 col-xl-4 col-lg-4">
                                  <select
                                    name="age_special_to"
                                    className="form-control"
                                    placeholder="To"
                                    onChange={this.handleChange}
                                  >
                                    {this.state.ageLimits.map(
                                      (value, index) => (
                                        <option
                                          value={value}
                                          // selected={value == 100 ? true : false}
                                          selected={
                                            value == formData.age_special_to
                                              ? true
                                              : false
                                          }
                                        >
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="listing-footer">
                  <button
                    className="white-btn"
                    type="button"
                    onClick={this.handleBackButton}
                  >
                    <i className="fas fa-chevron-left" /> Back
                  </button>
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={submitClicked}
                    onClick={this.submitForm}
                  >
                    {loadingContent == null ? "Save & Next" : loadingContent}
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditListingStep3;
