import React, { Component } from "react";
import dayjs from "dayjs";
import { Link, Redirect } from "react-router-dom";
import ProfileInput from "../../Helper/profileInput";
import Form from "../../Helper/form";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";
import { DatePicker } from "@y0c/react-datepicker";
import Select from 'react-select'
const $ = window.$;


class HostEditPromoCode extends HostHelper {
  constructor(props) {
    super(props);

    // States and props usage
  }

  state = {
    promoCodeData: {},
    loadingPromoCode: true,
    error: null,
    loginStatus: true,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false,
    users:null,
    value:[],
    data:{},
    expiry_date:null,
    userEmail: '',
  };

  componentDidMount() {
    this.getUsersListApiCall();
    setTimeout(() => {
      this.getPromoCodeDetails(this.props.match.params.id);
    }, 100);
  }

  getPromoCodeDetails = promo_code_id => {
    api
      .postMethod("promo_codes_view", { promo_code_id: promo_code_id })
      .then(response => {
        if (response.data.success) {
          this.setState({
            data: response.data.data,
            loadingPromoCode: false
          });

          const selected_users = this.state.data.user_id;

          if(selected_users){
            
            let selected = [];

            let users_selected_array = new Array();

            users_selected_array = selected_users.includes(',') ? selected_users.split(',') : selected_users;
            
            if(selected_users.includes(',')){
              
              for (let a in users_selected_array ) {
                users_selected_array[a] = parseInt(users_selected_array[a], 10); 
              }
              
            }

            $(this.state.users).each(function(index,value){
                
              if($.inArray(value.value,users_selected_array)!==-1){
                
                selected.push(value);
              }
              else if(value.value === parseInt(users_selected_array)){
                
                  selected.push(value);
              }

            });
            this.setState({ value: selected});
          }

        } else {
          // Do nothing.
        }
      });
  };


  getArrivingDateandTime = title => (...args) => {
    const data = { ...this.state.data };
    const datess = dayjs(args[0]).format("DD-MM-YYYY");
    this.setState({...this.state , data : { ...this.state.data ,expiry_date:datess}});
    this.setState({...this.state , data : { ...this.state.data ,expiry_date_formatted:datess}});

  };

  getDefaultLeavingTime = () => {
    var oldDateObj = new Date();
    var newDateObj = new Date();
    newDateObj.setTime(oldDateObj.getTime() + 60 * 60 * 1000);

    return dayjs(newDateObj).format("DD-MM-YYYY");
  };
  getDefaultArravingTime = expiry_date => {
    return dayjs(new Date(expiry_date)).format("DD-MM-YYYY");
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });

    api.postMethod("promo_codes_save", this.state.data).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
        this.props.history.push("/host/promo-code-list");
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  handleChangeUser = event => {

    let user_ids = [];

    $(event).each(function(index,value){
        user_ids.push(value.value);
    });

    this.setState({...this.state , data : { ...this.state.data ,user_id:user_ids}});

    this.setState({ value: event});
  };

  handleUserEmail = ({ currentTarget: input }) => {
    this.setState({ userEmail:input.value });
  };

 
  getUsersListApiCall = () => {
   
    api
      .postMethod("users_list")
      .then((response) => {
        if (response.data.success) {
          
          this.setState({
            users: response.data.data.users_list,
            loadingUsers: false,
          });
          console.log("users list",response.data.data.users_list);
        } 
      });

     
  };

  checkUser = event => {
    event.preventDefault();

    api.postMethod("search_users_email", { user_email: this.state.userEmail,user_ids:this.state.data.user_id }).then(response => {
      if (response.data.success) {

        let user_ids = [];

        $(response.data.data.users_list).each(function(index,value){
          user_ids.push(value.value);
        });
    
        this.setState({...this.state , data : { ...this.state.data ,user_id:user_ids}});
        this.setState({ value: response.data.data.users_list,userEmail:''});

      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  render() {
    const { promoCodeData, loadingPromoCode, error, loginStatus,users,data } = this.state;
    
    const options = users;

    const expiry_date_formatted = loadingPromoCode ? " " : data.expiry_date_formatted;

    this.state.data.expiry_date = expiry_date_formatted;

    if (!loginStatus) {
      return (
        <Redirect
          to={{
            pathname: "/host/login",
            state: { error: error }
          }}
        />
      );
    }
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                <form onSubmit={this.handleSubmit}>
                  <div className="panel">
                    <div className="panel-heading">required</div>

                    <div className="panel-body  account">
                      <ProfileInput
                        label="Title"
                        type="text"
                        placeholder=""
                        id="Title"
                        name="title"
                        value={
                          loadingPromoCode ? "Loading.." : data.title
                        }
                        onChange={this.handleChange}
                        description=""
                      />

                      <ProfileInput
                        label="Promo Code"
                        type="text"
                        placeholder=""
                        id="promo_code"
                        name="promo_code"
                        value={loadingPromoCode ? "" : data.promo_code}
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                    <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Select user</label>
                        </div>
                        <div className="col-7">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="User Email ID"
                            name="user_email"
                            value={this.state.userEmail}
                            onChange={this.handleUserEmail}
                          />
                        </div>
                        <div className="col-2 text-center">
                          <button
                            className="pink-btn btn-small"
                            onClick={(event) => this.checkUser(event)}
                          >
                            Apply
                          </button>
                        </div>
                      </div>

                    <div className="form-group row">
                            <div className="col-3 text-right">
                              <label>Select user</label>
                            </div>
                            <div className="col-9">
                            <Select isMulti name="user_id"
                             placeholder="Selected Users" 
                            // options={options}
                             onChange={this.handleChangeUser}
                             defaultValue={loadingPromoCode ? "" :[data.user_id]}
                             value={this.state.value}
                            />
                            </div>
                    </div>

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Amount type</label>
                        </div>
                        <div className="col-9">
                          <select
                            className="form-control"
                            name="amount_type"
                            id="amount_type"
                            value={
                              loadingPromoCode ? "" : data.amount_type
                            }
                            onChange={loadingPromoCode ? "" : this.handleChange}
                          >
                            <option>Select</option>
                            <option value="0">Percentage Amount</option>
                            <option value="1">Absolute Amount</option>
                          </select>
                        </div>
                      </div>

                      <ProfileInput
                        label="Amount"
                        type="text"
                        placeholder=""
                        id="amount"
                        name="amount"
                        value={loadingPromoCode ? "" : data.amount}
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Expiry Date</label>
                        </div>
                        <div className="col-9">
                          <DatePicker
                            // initialDate={
                            //   loadingPromoCode
                            //     ? ""
                            //     : this.getDefaultArravingTime(promoCodeData.expiry_date)
                            // }
                            onChange={this.getArrivingDateandTime(
                              "Range DatePicker"
                            )}
                            showDefaultIcon
                            placeholder={
                              loadingPromoCode ? "" : data.expiry_date
                            }
                          />
                        </div>
                      </div>

                      <ProfileInput
                        label="No of Users Limit"
                        type="text"
                        placeholder=""
                        id="no_of_users_limit"
                        name="no_of_users_limit"
                        value={
                          loadingPromoCode
                            ? ""
                            : data.no_of_users_limit
                        }
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                      <ProfileInput
                        label="Per User Limit"
                        type="text"
                        placeholder=""
                        id="per_users_limit"
                        name="per_users_limit"
                        value={
                          loadingPromoCode ? "" : data.per_users_limit
                        }
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Description</label>
                        </div>
                        <div className="col-9">
                          <textarea
                            type="text"
                            className="form-control"
                            rows="4"
                            name="description"
                            value={
                              loadingPromoCode ? "" : data.description
                            }
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-9 offset-3 text-center">
                          <button
                            className="pink-btn btn-block"
                            disabled={this.state.buttonDisable}
                          >
                            {this.state.loadingContent != null
                              ? this.state.loadingContent
                              : "save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostEditPromoCode);
