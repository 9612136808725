import React, { Component } from "react";
import api from "../../../HostEnvironment";

class HostLogout extends Component {
  componentDidMount() {
    api
      .postMethod("logout")
      .then((response) => {
        if (response.data.success) {
        }
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("hostId");
    localStorage.removeItem("hostLoginStatus");
    localStorage.removeItem("host_picture");
    localStorage.removeItem("provider_name");
    localStorage.removeItem("is_frontdesk");
    localStorage.removeItem("login_by");
    localStorage.removeItem("socket");
    window.location = "/host";
  }
  render() {
    return null;
  }
}

export default HostLogout;
