import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const LandingSubCategoryLoaders = (props) => {

  const {count} = props;

  const loaderCount = Array.from(Array(count).keys());

  return (
    <>
    <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
      <div className="party-life site-content">
        <Skeleton width={"40%"} height={40}></Skeleton>
        <div className='skeleton-card-loader m-t-20'>
          <div className='row'> 
          {loaderCount.map((loaders) => (
            <>
            <div className='col-lg-3 col-md-4 col-xl-3 resp-mrg-btm-xs margin-btm1'>
            <Skeleton width={"100%"} height={200}>

            </Skeleton>
            </div>
            </>
          )) }
            
          </div>
          
        </div>
      </div>
    </SkeletonTheme>
    </>
  )
}

export default LandingSubCategoryLoaders;