import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class EditListingStep6 extends Component {
  state = {
    loading: true,
    data: null,
    formData: {},
    loadingContent: null,
    submitClicked: false,
    displayTimeslot: false,
    displayDailySlot: false,
    isInputChanged: false,
    imagePreviewUrl:'',
  };

  componentDidMount() {
    this.step6ApiCall();
  }

  handle6Change = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    console.log("INPUT", input);

    if (input.type === "checkbox") {
      if (input.checked) {
        if (formData[input.name] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          formData[input.name] = array;
          this.setState({ formData });
        } else {
          const value = parseInt(input.value);
          formData[input.name].push(value);
          this.setState({ formData });
        }
      } else {
        const value = parseInt(input.value);
        let index = formData[input.name].indexOf(value);
        if (index !== -1) {
          formData[input.name].splice(index, 1);
          this.setState({ formData });
        }
      }
    }

    if (input.type === "radio") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "text") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "select-one") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "file") {
      formData[input.name] = input.files[0];
      this.setState({ formData });
      let reader = new FileReader();
      let file = input.files[0];

      reader.onloadend = () => {
        this.setState({
          imagePreviewUrl: reader.result,
        });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    this.setState({ isInputChanged: true });
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step5/${this.props.match.params.host_id}`
    );
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    if (this.state.isInputChanged) {
      api
        .postMethod("hosts_step6_save", this.state.formData)
        .then((response) => {
          if (response.data.success) {
            this.props.history.push(
              `/host/listing-step7/${this.props.match.params.host_id}`
            );
          } else {
            // alert(response.data.error);
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            this.setState({
              loadingContent: null,
              submitClicked: false,
            });
          }
        });
    }

    this.props.history.push(
      `/host/listing-step7/${this.props.match.params.host_id}`
    );
    this.setState({
      loadingContent: null,
      submitClicked: false,
    });
  };

  step6ApiCall = () => {
    api
      .postMethod("hosts_step_6", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            data: response.data.data,
            imagePreviewUrl:response.data.data.host_picture,
            formData: {
              host_id: response.data.data.host_id,
              title: response.data.data.host_name,
              description: response.data.data.host_description,
              is_automatic_booking: response.data.data.is_automatic_booking,
              is_only_residence: response.data.data.is_only_residence,
            },
          });
        } else {
          alert(response.data.error);
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  handleChange = ({ currentTarget: input }) => {
    // event.preventDefault();
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;

    this.setState({ formData });
  };
  render() {
    const { data, loading, formData,imagePreviewUrl } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 6: Update your space information
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          {/* <div className="add-flow"> */}
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  <div id="step-8" className="flow-tab">
                    <div className="add-flow-block row">
                      <div className="col-md-12">
                        {loading ? (
                          "Loading..."
                        ) : (
                          <div className="add-flow-block-left">
                            <h4 className="flow-tit">Name your Listing</h4>
                            <p className="main-txt">
                              Attract guests with a listing title that
                              highlights what makes your place special
                            </p>
                            <div className="flow-content">
                              <div
                                className="form-group large-form-group"
                                key="title"
                              >
                                <label className="light-txt">
                                  Name your Listing
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Add a title"
                                  name="title"
                                  onChange={this.handle6Change}
                                  value={formData["title"]}
                                  defaultValue={data.host_name}
                                />
                              </div>
                              <div
                                className="form-group large-form-group"
                                key="description"
                              >
                                <label className="light-txt">
                                  Describe your place to guests
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Describe your property"
                                  name="description"
                                  onChange={this.handle6Change}
                                  value={formData["description"]}
                                  defaultValue={data.host_description}
                                />
                              </div>
                              {/* <div
                                className="form-group large-form-group"
                                key="keywords"
                              >
                                <label className="light-txt">
                                  Keywords
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Keywords"
                                  name="keywords"
                                  onChange={this.handle6Change}
                                  value={formData["keywords"]}
                                  defaultValue={data.keywords}
                                />
                              </div> */}
                            </div>
                            <div className="flow-content">
                              <div
                                className="form-group large-form-group"
                                key="picture"
                              >
                                <label className="light-txt">
                                  Add cover photo to your listing
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="form-control"
                                  name="picture"
                                  onChange={this.handle6Change}
                                />
                              </div>
                              <img
                                src={imagePreviewUrl}
                                class="mr-3 rounded-circle user-profile-img"
                              />
                            </div>
                            <div className="flow-content">
                              <div className="flow-box">
                                <h5 className="flow-box-tit">
                                  Rapid Booking allowed?
                                </h5>
                                <p className="main-txt text-muted">
                                  Rapid Booking allows guest to book the
                                  property directly without Host approval, set
                                  to 'No' if host approval is required for each
                                  booking.
                                </p>
                                <div
                                  className="form-check add-list-block"
                                  key="is_automatic_booking_yes"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_automatic_booking"
                                    value="1"
                                    onChange={this.handle6Change}
                                    id="is_automatic_booking_yes"
                                    defaultChecked={
                                      data.is_automatic_booking == 1
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="is_automatic_booking_yes"
                                  >
                                    <h5 className="flow-check-tit">Yes</h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                                <div
                                  className="form-check add-list-block"
                                  key="is_automatic_booking_no"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_automatic_booking"
                                    value="0"
                                    onChange={this.handle6Change}
                                    id="is_automatic_booking_no"
                                    defaultChecked={
                                      data.is_automatic_booking == 0
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="is_automatic_booking_no"
                                  >
                                    <h5 className="flow-check-tit">No</h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                              </div>
                            </div>
                            <div className="flow-content">
                              <div className="flow-box">
                                <h5 className="flow-box-tit">
                                  Service Delivery?
                                </h5>
                                <p className="main-txt text-muted">
                                  Collect customer address (Service Delivery Address) during customer booking/checkout.
                                </p>
                                <div
                                  className="form-check add-list-block"
                                  key="is_delivery_service_available_yes"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_delivery_service_available"
                                    value="1"
                                    onChange={this.handle6Change}
                                    id="is_delivery_service_available_yes"
                                    defaultChecked={
                                      data.is_delivery_service_available == 1
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="is_delivery_service_available_yes"
                                  >
                                    <h5 className="flow-check-tit">Yes, I deliver my service to a customer location</h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                                <div
                                  className="form-check add-list-block"
                                  key="is_delivery_service_available"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_delivery_service_available"
                                    value="0"
                                    onChange={this.handle6Change}
                                    id="is_delivery_service_available"
                                    defaultChecked={
                                      data.is_delivery_service_available == 0
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="is_delivery_service_available"
                                  >
                                    <h5 className="flow-check-tit">No, I do not deliver any service to a customer location</h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                              </div>
                            </div>

                            <div className="flow-content">
                              <div className="flow-box">
                                <h5 className="flow-box-tit">
                                  Only for Community Residence?
                                </h5>
                                <p className="main-txt text-muted">
                                  Certain community halls allows booking only
                                  for residence in that community, set to 'Yes'
                                  if that restriction applies.
                                </p>
                                <div
                                  className="form-check add-list-block"
                                  key="is_only_residence_yes"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_only_residence"
                                    value="1"
                                    onChange={this.handle6Change}
                                    id="is_only_residence_yes"
                                    defaultChecked={
                                      data.is_only_residence == 1 ? true : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="is_only_residence_yes"
                                  >
                                    <h5 className="flow-check-tit">Yes</h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                                <div
                                  className="form-check add-list-block"
                                  key="is_only_residence_no"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_only_residence"
                                    value="0"
                                    onChange={this.handle6Change}
                                    id="is_only_residence_no"
                                    defaultChecked={
                                      data.is_only_residence == 0 ? true : false
                                    }
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor="is_only_residence_no"
                                  >
                                    <h5 className="flow-check-tit">No</h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="listing-footer">
                  <button
                    className="white-btn"
                    type="button"
                    onClick={this.handleBackButton}
                  >
                    <i className="fas fa-chevron-left" /> Back
                  </button>
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={this.state.submitClicked}
                    onClick={this.submitForm}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : "Save & Next"}
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditListingStep6;
