import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

import "./Listing.css";

class HostTimeslot extends Component {
  state = {
    loading: true,
    host: null,
    loadingContent: null,
    buttonDisable: false,
    hostId: null,
    formData: { start_time_picker: '', end_time_picker: '' },
    timeSlotData: [],
    loadingTimeSlot: true,
    hostCustomPricings:[],
    timezone:'',
    isEdited:false,
    slotDetails:null,
    buttonName:'Add Slot',
  };
  componentDidMount() {
    this.setState({ hostId: this.props.match.params.id });
    const formData = { ...this.state.formData };
    formData["host_id"] = this.props.match.params.id;
    this.setState({ formData });
    this.getTimeSlotDetails();
  }
  getTimeSlotDetails = () => {
    api
      .postMethod("hosts_time_slots", { host_id: this.props.match.params.id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loadingTimeSlot: false,
            timeSlotData: response.data.data.timeslots,
            hostCustomPricings:response.data.data.host_custom_pricing,
          });
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      }
      )
      .catch(function(error) {});
  };

  editSlot = (event, slot) => {
    event.preventDefault();
    const formData = { ...this.state.formData };
    formData['name'] = slot.name;
    formData['start_time'] = slot.start_time;
    formData['end_time'] = slot.end_time;
    formData['start_time_picker'] = moment(slot.start_time, 'HH:mm:ss');
    formData['end_time_picker'] = moment(slot.end_time, 'HH:mm:ss');
    formData['host_id'] = slot.host_id;
    formData['host_time_slot_id'] = slot.host_time_slot_id;
    this.setState({ formData });

    this.setState({
      slotDetails: slot,
      isEdited: true,
      buttonName:'Edit Slot',
    });
  };

  timeSlotSave = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("hosts_time_slots_save", this.state.formData)
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getTimeSlotDetails();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
        this.setState({
          loadingContent: null,
          buttonDisable: false,
          formData: {
            start_time_picker: null,
            end_time_picker: null,
            name: "",
            host_id: this.state.hostId,
          },
        });
      })
      .catch(function(error) {});
  };

  handleChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
  };

  handleValueChange = (value) => {
    const formData = { ...this.state.formData };
    formData["start_time"] = null;
    if (value != null) {
      formData["start_time_picker"] = value;
      formData["start_time"] = moment(value).format("HH:mm:ss");
    }
    this.setState({ formData });
  };
  handleEndChange = (value) => {
    if (value != undefined || value != null) {
      const formData = { ...this.state.formData };
      formData["end_time_picker"] = value;
      formData["end_time"] = value.format("HH:mm:ss");
      this.setState({ formData });
    }
  };

  deleteSlot = (event, slot) => {
    event.preventDefault();
    api
      .postMethod("hosts_time_slots_delete", {
        host_time_slot_id: slot.host_time_slot_id,
      })
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getTimeSlotDetails();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  clearValue = (event) => {
    event.preventDefault();
    this.setState({
      isEdited:false,
      slotDetails:null,
      buttonName:'Add new',
      formData: {
        start_time_picker: null,
        end_time_picker: null,
        start_time: null,
        start_time: null,
        name: "",
      },
    });
  };

  render() {
    const { loading, loadingTimeSlot, timeSlotData, formData,isEdited,slotDetails,buttonName } = this.state;

    console.log("timeslot length",timeSlotData);
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <form>
                  <div className="panel">
                    <div className="panel-heading">
                      Update your timeslots
                      <Link className="float-right" to={"/host/listing"}>
                        <i className="fas fa-chevron-left" /> Back
                      </Link>
                    </div>
                    <div className="panel-body account host-time-slot">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="media user-profile-sec">
                            <div className="media-body">
                              <div className="row">
                                <div className="form-group  col-md-6 col-xl-3 col-lg-3">
                                  <label className="light-txt">Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    name="name"
                                    onChange={this.handleChange}
                                    value={formData.name}
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="form-group col-md-6 col-xl-3 col-lg-3">
                                  <label className="light-txt">
                                    Start Time
                                  </label>
                                  <TimePicker
                                    showSecond={false}
                                    className="form-control timeslot-control"
                                    onChange={this.handleValueChange}
                                    name="start_time_picker"
                                    minuteStep={1}
                                    placeholder={isEdited && slotDetails ? slotDetails.start_time_formatted : "Select start time"}
                                    autoComplete="off"
                                    use12Hours
                                    value={formData.start_time_picker}
                                  />
                                </div>
                                <div className="form-group col-md-6 col-xl-3 col-lg-3">
                                  <label className="light-txt">End Time</label>
                                  <TimePicker
                                    showSecond={false}
                                    className="form-control"
                                    onChange={this.handleEndChange}
                                    name="end_time_picker"
                                    minuteStep={1}
                                    placeholder={isEdited && slotDetails ? slotDetails.end_time_formatted : "Select end time"}
                                    autoComplete="off"
                                    use12Hours
                                    value={formData.end_time_picker}
                                  />
                                </div>
                                <div className="col-md-6 col-xl-3 col-lg-3">
                                  <button
                                    className="green-btn mr-3"
                                    type="submit"
                                    disabled={this.state.buttonDisable}
                                    onClick={this.timeSlotSave}
                                    style={{ marginTop: "2.4em" }}
                                  >
                                    {this.state.loadingContent != null
                                      ? this.state.loadingContent
                                      : buttonName}
                                  </button>
                                  {isEdited && slotDetails ?
                                    <button
                                      className="green-btn"
                                      type="submit"
                                      onClick={this.clearValue}
                                    >
                                      Add New
                                    </button>
                                  : ''}
                                </div>
                              </div>

                              <div className="row">
                                <h3 className="time-slot-heading">Time slots</h3>
                                <div className="trans-table table-responsive">
                                  {loadingTimeSlot ? (
                                    "Loading..."
                                  ) : timeSlotData.length > 0 ? (
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Name</th>
                                          <th scope="col">Start Time</th>
                                          <th scope="col">End Time</th>
                                          <th scope="col">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {timeSlotData.map((slots) => (
                                          <tr>
                                            <td>{slots.name}</td>
                                            <td>{slots.start_time_formatted}</td>
                                            <td>{slots.end_time_formatted}</td>
                                            <td>
                                              <button
                                                className="btn mr-3"
                                                type="submit"
                                                onClick={(event) =>
                                                  this.editSlot(event, slots)
                                                }
                                              >
                                                Edit
                                              </button>

                                              <button
                                                className="btn"
                                                type="submit"
                                                onClick={(event) =>
                                                  this.deleteSlot(event, slots)
                                                }
                                              >
                                                Delete
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  ) : (
                                    "No data found"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                { this.state.timeSlotData.length > 0 ?
                <Link
                    className="btn btn-success pricing-btn-left" 
                    to={`/host/pricings/${this.state.hostId}`}
                  >
                    <i class="fas fa-hand-holding-usd"></i>
                     Pricing
                  </Link>

                  :''}
              
              </div>

                 


            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostTimeslot);
