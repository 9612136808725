import React, { Component } from "react";
import api from "../../../Environment";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import SingleTripPageMap from "../../Helper/singleTripPageMap";
import UserReviewModel from "./userReviewModel";

const $ = window.$;

class SingleHistory extends Component {
  state = {
    booking_details: null,
    loading: true,
    checkinBtnStatus: 0,
    checkoutBtnStatus: 0,
    cancelBtnStatus: 0,
    reviewBtnStatus: 0,
    messageBtnStatus: 0,
    CheckinApiCalled: false,
    CheckoutApiCalled: false,
    reviewApiCalled: false,
    cancelApiCalled: false,
  };

  componentDidMount() {
    this.getSingleHistoryDetailsApiCall();
  }

  getSingleHistoryDetailsApiCall = () => {
    api
      .postMethod("bookings_view", { booking_id: this.props.match.params.id })
      .then((response) => {
        if (response.data.success) {
          let booking_details_data = response.data.data;
          console.log(booking_details_data);
          if (booking_details_data.buttons.review_btn_status == 1) {
            setTimeout(() => {
              $("#userReviewModel").modal("show");
            }, 2000);
          }
          this.setState({
            booking_details: booking_details_data,
            loading: false,
          });
        }
      });
  };

  bookingCheckin = (event) => {
    event.preventDefault();
    api
      .postMethod("bookings_checkin", {
        booking_id: this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  bookingCheckout = (event) => {
    event.preventDefault();
    api
      .postMethod("bookings_checkout", {
        booking_id: this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  bookingCancel = (event) => {
    event.preventDefault();
    api
      .postMethod("bookings_cancel", {
        booking_id: this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  bookingReview = (event) => {
    event.preventDefault();
    api
      .postMethod("bookings_rating_report", {
        booking_id: this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  render() {
    const { booking_details, loading } = this.state;
    return (

      <div className="main">

        {loading ? (
          "Loading"
        ) : (
          <div>
            <div className="singlehome-img-sec">
              <img
                src={loading ? "Loading" : booking_details.picture}
                alt="Loading..."
                className="homes-img br-0"
              />
              {loading ? (
                "Loading"
              ) : (
                <div className="top-right">
                  {booking_details.buttons.message_btn_status == 1 ? (
                    <Link
                      to={{
                        pathname: "/single-trip/chat",
                        state: {
                          booking_details: booking_details,
                          page: "history",
                        },
                      }}
                      className="white-btn btn-small m-2"
                    >
                      <i className="far fa-comment" />
                      &nbsp; chat
                    </Link>
                  ) : (
                    ""
                  )}{" "}
                </div>
              )}
            </div>

            <div className="site-content">
              <div className="top-bottom-spacing">
                <div className="row">

                  <div className="col-xl-7 col-lg-10 col-md-10 auto-margin">
                    <div className="media resp-flex-column">
                      <div className="media-body mr-3">
                        <a href="#">
                          <p className="red-text txt-overflow">
                            #{booking_details.booking_unique_id}
                          </p>
                        </a>
                        <h1 className="category-section-head">
                          {booking_details.host_name}
                        </h1>
                        <h4 className="captalize section-subhead">
                          {booking_details.full_address}
                        </h4>
                        <p>{booking_details.booking_description}</p>
                      </div>
                      <div className="resp-box-1">
                        <Link
                          to={`/provider-profile/${booking_details.provider_details.provider_id}`}
                        >
                          <img
                            src={booking_details.provider_details.picture}
                            alt={booking_details.provider_details.provider_name}
                            className="mt-3 rounded-circle review-img"
                          />
                          <p className="text-left top3 mb-0">
                            <a href="#" className="other-proname">
                              {booking_details.provider_details.provider_name}
                            </a>
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      {booking_details.buttons.checkin_btn_status == 1 ? (
                        <div className="col-md-6">
                          <button
                            className="green-btn btn-block"
                            onClick={(event) => {
                              if (
                                window.confirm(
                                  "Are you sure you want to checkin?"
                                )
                              )
                                this.bookingCheckin(event);
                            }}
                          >
                            Checkin
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {booking_details.buttons.checkout_btn_status == 1 ? (
                        <div className="col-md-6">
                          <button
                            className="green-btn btn-block"
                            onClick={(event) => {
                              if (
                                window.confirm(
                                  "Are you sure you want to checkout?"
                                )
                              )
                                this.bookingCheckout(event);
                            }}
                          >
                            CheckOut
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {booking_details.buttons.review_btn_status == 1 ? (
                        <div className="col-md-6">
                          <a
                            className="green-btn btn-block"
                            href="#"
                            data-toggle="modal"
                            data-target="#userReviewModel"
                          >
                            Review
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      {booking_details.buttons.cancel_btn_status == 1 ? (
                        <div className="col-md-6">
                          <button
                            className="danger-outline-btn btn-block"
                            onClick={(event) => {
                              if (
                                window.confirm(
                                  "Are you sure you want to cancel booking?"
                                )
                              )
                                this.bookingCancel(event);
                            }}
                          >
                            Cancel Booking
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="highlights-box">
                      <h2 className="chathead mt-0">trip details</h2>
                      <p className="overview-line" />
                      {booking_details.pricing_type != 3 ?
                        <h5 className="choosen-details">
                          <i className="fas fa-user mr-3" />
                          {booking_details.total_guests} Guest (
                          {booking_details.adults} Adults,{" "}
                          {booking_details.children} Children,{" "}
                          {booking_details.infants} Infants,{" "}
                          {booking_details.age_special} Special Needs)
                        </h5>
                        : ''}
                      <h5 className="choosen-details">
                        <i className="far fa-calendar-alt mr-3" />
                        {booking_details.checkin} {booking_details.host_time_slot ? booking_details.host_time_slot_formatted : ''}

                        {/* <i className="fas fa-arrow-right ml-3 mr-3" />
                      {booking_details.checkout} */}
                      </h5>
                      {booking_details.pricing_type == 3 ?
                        booking_details.is_custom_pricing == 1 ?
                          <h5 className="choosen-details">
                            <i className="fas fa-address-book mr-3" />
                            Package - Custom Pricing
                          </h5>
                          : booking_details.host_pricing_packages ?
                            <h5 className="choosen-details">
                              <i className="fas fa-address-book mr-3" />
                              Package - {booking_details.host_pricing_packages.name} {booking_details.host_pricing_packages.base_price_formatted}
                            </h5> : ''
                        : ''}
                      <p className="overview-line" />
                      <div className="row">
                        <div className="col-6">
                          <h5 className="choosen-details">Status</h5>
                          <h5 className="choosen-details">Payment Mode</h5>
                          <h5 className="choosen-details">Payment ID</h5>
                          <h5 className="choosen-details">Booked On</h5>
                          {booking_details.is_delivery_service_available ?
                            <h5 className="choosen-details">Service Delivery Address</h5>
                            : ""}
                        </div>

                        <div className="col-6 text-right">
                          <h5 className="choosen-details text-success">
                            {booking_details.status_text}
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.pricing_details.payment_mode} - {booking_details.card_last_four}
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.pricing_details.payment_id}
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.created_at}
                          </h5>
                          {booking_details.is_delivery_service_available ?
                            <h5 className="choosen-details">
                              {booking_details.service_delivery_address}
                            </h5>
                            : ""}
                        </div>
                      </div>

                      <p className="overview-line" />
                      <div className="row">
                        <div className="col-6">
                          {booking_details.pricing_type == 1 ? (
                            <h5 className="choosen-details">Base Price</h5>
                          ) : null}
                          {booking_details.pricing_type == 2 ? (
                            <h5 className="choosen-details">Total Guest Price</h5>
                          ) : null}
                          {booking_details.pricing_type == 3 ? (
                            <h5 className="choosen-details">
                              Total Package Price
                            </h5>
                          ) : null}
                          {/* <h5 className="choosen-details">Cleaning fee</h5> */}
                          <h5 className="choosen-details">Service fee</h5>
                          <h5 className="choosen-details">Tax Price</h5>
                          {/* {booking_details.pricing_details.security_deposit ==
                        0 ? (
                          ""
                        ) : (
                          <h5 className="choosen-details">Security Deposit</h5>
                        )} */}
                          {booking_details.addon_service_details.length > 0 ? (
                            <React.Fragment>
                              {booking_details.addon_service_details.map(
                                (service) => (
                                  <h5 className="choosen-details">
                                    {service.name}
                                  </h5>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {booking_details.pricing_details.promo_code_total ==
                            0 ? (
                            ""
                          ) : (
                            <h5 className="choosen-details">Promo code</h5>
                          )}
                        </div>
                        <div className="col-6 text-right">
                          {booking_details.pricing_type == 1 ? (
                            <h5 className="choosen-details">
                              {
                                booking_details.pricing_details
                                  .time_price_formatted
                              }
                            </h5>
                          ) : null}

                          {booking_details.pricing_type == 2 ? (
                            <h5 className="choosen-details">
                              {
                                booking_details.pricing_details
                                  .total_guest_price_formatted
                              }
                            </h5>
                          ) : null}
                          {booking_details.pricing_type == 3 ? (
                            <h5 className="choosen-details">
                              {
                                booking_details.pricing_details
                                  .package_amount_formatted
                              }
                            </h5>
                          ) : null}
                          {/* <h5 className="choosen-details">
                          {
                            booking_details.pricing_details
                              .cleaning_fee_formatted
                          }
                        </h5> */}
                          <h5 className="choosen-details">
                            {
                              booking_details.pricing_details
                                .service_fee_formatted
                            }
                          </h5>
                          <h5 className="choosen-details">
                            {booking_details.pricing_details.tax_price_formatted}
                          </h5>
                          {/* {booking_details.pricing_details.security_deposit ==
                        0 ? (
                          ""
                        ) : (
                          <h5 className="choosen-details">
                            {
                              booking_details.pricing_details
                                .security_deposit_formatted
                            }
                          </h5>
                        )} */}
                          {booking_details.addon_service_details.length > 0 ? (
                            <React.Fragment>
                              {booking_details.addon_service_details.map(
                                (service) => (
                                  <h5 className="choosen-details">
                                    {service.currency}{service.amount}.00
                                  </h5>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {booking_details.pricing_details.promo_code_total ==
                            0 ? (
                            ""
                          ) : (
                            <h5 className="choosen-details">
                              {" - "}{
                                booking_details.pricing_details
                                  .promo_code_total_formatted
                              }
                            </h5>
                          )}
                        </div>
                      </div>

                      <p className="overview-line" />
                      <div className="row">
                        <div className="col-6">
                          <h5 className="choosen-details">Total (CAD)</h5>
                        </div>
                        <div className="col-6 text-right">
                          <h5 className="choosen-details">
                            {
                              booking_details.pricing_details
                                .total_price_formatted
                            }
                          </h5>
                        </div>
                        {booking_details.is_automatic_booking == 0 ?
                          <div className="col-12">
                            <h6 className="choosen-details">Note: Amount going to be charged, once the property owner confirms.</h6>
                          </div>
                          : ""}
                      </div>
                    </div>
                  </div>
                  <>
                    <div className="col-xl-5 pl-5 relative">
                      <div className="trips-map-img">
                        {/* <img
                      src={window.location.origin + "/assets/img/map.png"}
                      className="homes-img"
                    /> */}
                        <SingleTripPageMap location={booking_details} />
                      </div>
                    </div>
                    <UserReviewModel booking_id={this.props.match.params.id} host_id={booking_details.host_id} />
                  </>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withToastManager(SingleHistory);
