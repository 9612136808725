import React, { Component } from "react";
import NewHomeBanner from "./NewHomeBanner";
import Helper from "../Helper/Helper";
import Loader from "../Helper/Loader";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";
import LocationPage from "./locationPage";
import api from "../../Environment";
import NewLandingPageEvents from "./NewLandingPageEvents";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Pagination } from "swiper/core";
import MetaTags from "react-meta-tags";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

SwiperCore.use([Pagination]);

class NewSinglepropertiesIndex extends Helper {
  state = {
    first_block: null,
    mainData: null,
    loading: true,
    second_block: null,
    eventData: null,
    loadingEvent: true,
    locationData: null,
    homeLocationData:null,
    loadingLocation: true,
    searchInput: {},
    searchLoadingContent: null,
    searchButtonDisable: false,
    navigatorResult: null,
    loadingNavigator: true,
  };
  constructor(props) {
    super(props);

    // States and props usage
  }

  componentDidMount() {
    // Call api function

    let inputData = {
      api_page_type: "HOME",
    };
    this.homePageFirstSection(inputData);
    this.handleNavigator();
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.error,
        "error"
      );
    }
    this.getEventDetailsApi();
    this.getLocationDetailsApi();
  }

  getEventDetailsApi = () => {
    api.postMethod("events_index").then((response) => {
      if (response.data.success === true) {
        this.setState({
          loadingEvent: false,
          eventData: response.data.data.events,
        });
      } else {
        // Do nothing
      }
    });
  };

  getLocationDetailsApi = () => {
    api.postMethod("filter_locations").then((response) => {
      if (response.data.success === true) {
        console.log(response.data);
        this.setState({
          loadingLocation: false,
          locationData: response.data.data,
          homeLocationData: response.data,
        });
      } else {
        // Do nothing
      }
    });
  };

  convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  dateChange = (...args) => {
    // console.log("asdf", this.convert(args[0]));
    const searchInput = { ...this.state.searchInput };
    searchInput["from_date"] = this.convert(args[0]);
    this.setState({ searchInput });
  };

  selectEvent = (event, details) => {
    event.preventDefault();
    const searchInput = { ...this.state.searchInput };
    searchInput["event_id"] = details.event_id;
    searchInput["event_title"] = details.title;
    this.setState({ searchInput });
  };

  getSearchInput = ({ currentTarget: input }) => {
    let location_data = this.state.locationData.filter((item) => item.service_location_id == input.value).map(({service_location_name}) => service_location_name);

    const searchInput = { ...this.state.searchInput };
    searchInput[input.name] = input.value;
    searchInput['service_location_name'] = location_data ? location_data[0] : '';
    this.setState({ searchInput });
  };

  submitSearch = (event) => {
    event.preventDefault();
    this.setState({
      searchButtonDisable: true,
      searchLoadingContent: "Loading...",
    });

    let searchWithLocation = { ...this.state.searchInput };

    if (!this.state.loading && this.state.navigatorResult) {
      searchWithLocation = {
        ...searchWithLocation,
        location_name: this.state.navigatorResult.city,
      };
    }

    api
      .postMethod("search_result", searchWithLocation)
      .then((response) => {
        if (response.data.success) {
          console.log("Success", response.data.data);
          if (response.data.data[0].data.length > 0) {
            this.props.history.push("/search", {
              ...response.data.data,
              searchInput: this.state.searchInput,
            });
          } else {
            ToastDemo(
              this.props.toastManager,
              "No place found... Please try again",
              "error"
            );
          }
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
        this.setState({
          searchButtonDisable: false,
          searchLoadingContent: null,
        });
      })
      .catch(function(error) {});
  };

  render() {
    let load = new Loader();
    const {
      loading,
      loadingEvent,
      eventData,
      loadingLocation,
      locationData,
      searchButtonDisable,
      searchLoadingContent,
      sub_categories,
      navigatorResult,
      loadingNavigator,
      homeLocationData,
    } = this.state;
    
    return (
      <div>
        {loading ? (
          // load.bannerLoader()
          "Loading..."
        ) : (
          <NewHomeBanner
            {...this.state.first_block}
            props={this.props}
            loadingEvent={loadingEvent}
            eventData={eventData}
            loadingLocation={loadingLocation}
            locationData={locationData}
            dateChange={this.dateChange}
            getSearchInput={this.getSearchInput}
            submitSearch={this.submitSearch}
            searchLoadingContent={searchLoadingContent}
            searchButtonDisable={searchButtonDisable}
            searchInput={this.state.searchInput}
            selectEvent={this.selectEvent}
          />
        )}
        <div className="main">
          <MetaTags>
            <title>Party Budha</title>
            <meta name="keywords" content="Partybudha,party hall,indoor play center,wedding location" />
            <meta name="description" content="The best way to book party hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!" />
            <meta property="og:title" content="Party Budha: Find party spaces and event related services in your community" />
            <meta
              property="og:image"
              content={window.location.origin + "/assets/img/banner-1.png"}
            />
          </MetaTags>
          <div>
            <div className="single-listing-note-desc site-content">
                  <h4>With an approximately 1.2 million population, Calgary is one of the most vibrant cities in the Prairies region in Canada and is known for its cultural diversity, If you're looking forward to hosting a memorable party, you have arrived at the right place! We have great listings of varoious party spaces and services for all ages that match your expectation and budget</h4>
              </div>
            <div className="top-bottom-spacing">
              {/* <HomeStepsContent /> */}
              <div className="party-life site-content">
                <h1 className="section-head">Why Party Budha?</h1>
              </div>
              <div className="why-party-sec">
                <div className="why-party">
                  <div className="site-content">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <div className="section-title">
                          <h4 class="section-head">Why Party Budha?</h4>
                        </div> */}
                        <div className="why-party-card web-display">
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              <img src="../assets/icons/multiple.png" alt="image"/>
                            </div>
                            <div className="party-info">
                                <h5>More Choice</h5>
                                <p>
                                  No more scouting the city for party spaces and  arrangements. Be  it  a  party  hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!
                                </p>
                            </div>
                          </div>
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              <img src="../assets/icons/booking.png" alt="image"/>
                            </div>
                            <div className="party-info">
                              <h5>Hassle-free Booking</h5>
                                <p>
                                Compare price, check availability, read reviews, chat with the host and book online through our secure user friendly platform. All in just few clicks!
                                </p>
                            </div>
                          </div>
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              <img src="../assets/icons/social-care.png" alt="image"/>
                            </div>
                            <div className="party-info">
                              <h5>Peace of Mind</h5>
                                <p>
                                Our  Book  Assure  program  ensures  you as  a  customer  gets  the lowest  price possible  and  an  easy  cancellation experience. We got your back!
                                </p>
                            </div>
                          </div>
                        </div>
                        <Swiper pagination={true} className="mySwiper w-100 web-display-none">
                          <SwiperSlide>
                            <div className="why-party-item">
                              <div className="why-party-icon-sec">
                                <img src="../assets/icons/multiple.png" alt="image"/>
                                {/* <img src="../assets/img/opportunity.png"/> */}
                              </div>
                              <div className="party-info">
                                <h5>More Choice</h5>
                                <p>
                                  No more scouting the city for party spaces and  arrangements. Be  it  a  party  hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="why-party-item">
                              <div className="why-party-icon-sec">
                                <img src="../assets/icons/booking.png" alt="image" />
                                {/* <img src="../assets/img/booking.png"/> */}
                              </div>
                              <div className="party-info">
                                <h5>Hassle-free Booking</h5>
                                <p>
                                Compare price, check availability, read reviews, chat with the host and book online through our secure user friendly platform. All in just few clicks!
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="why-party-item">
                              <div className="why-party-icon-sec">
                                <img src="../assets/icons/social-care.png" alt="image"/>
                                {/* <img src="../assets/img/peace-of-mind.png"/> */}
                              </div>
                              <div className="party-info">
                                <h5>Peace of Mind</h5>
                                <p>
                                Our  Book  Assure  program  ensures  you as  a  customer  gets  the lowest  price possible  and  an  easy  cancellation experience. We got your back!
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loading && loadingNavigator ? (
                load.categoryLoader()
              ) : (
                <NewLandingPageEvents
                  loadingEvent={loadingEvent}
                  eventData={eventData}
                  loadingNavigator={loadingNavigator}
                  location_name={navigatorResult}
                />
              )}
              <div className="single-popular-location-sec site-content">
                  <div className="single-popular-location-header">
                    <h1 className="section-head">Popular locations</h1>
                    <Link to="#" className="green-btn btn-small">Find More Spaces</Link>
                  </div>
              </div>
             
              {loadingLocation ? (
                load.categoryLoader()
              ) : 
              <div class="site-content">
                {homeLocationData.data.length > 0 ? 
                  <div className="subcategory-leftsec">
                    <div className="row">
                      {homeLocationData.data.map(location => 
                    
                        <div className="subcategory-card view-all-card" key={location.unique_id}>
                          <div className="relative">
                            <section className="home-slider slider">
                              <Carousel
                                showThumbs={false}
                                infiniteLoop={true}
                                showStatus={false}
                              >
                                  <div
                                    key={location.service_location_picture}
                                    className="homes-img-sec1"
                                  >
                                    <img
                                      srcSet={location.service_location_picture}
                                      src={location.service_location_picture}
                                      alt="Loading..."
                                      className="homes-img"
                                    />
                                  </div>
                              </Carousel>
                            </section>
                            <div className="share-left">
                              <div className="wishlist-icon">
                                <button className="btn-wht" data-toggle="modal" data-target="#social-share">
                                <i class="fa fa-share-alt" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="amount-display">
                              <div className="booking-icon-sec">
                                <ul className="list-unstyled">
                                  <li>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/assets/img/rapid-booking-white.svg"
                                        }
                                        alt=""
                                        className="booking-icon"
                                    />
                                  </li>
                                  
                                  <li>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/assets/img/lock-white.svg"
                                        }
                                        alt=""
                                        className="booking-icon"
                                    />
                                  </li>
                                </ul>
                              </div>
                              <h2 className="absolute-amount">
                                Packages from $100
                              </h2>
                            </div>
                            <div className="wishlist-icon-sec">
                              <div className="wishlist-icon">
                                <Link
                                  className=""
                                  to="#"
                                >
                                  <i className="fa fa-heart">{' '}Save</i>    
                                </Link>
                              </div>
                            </div>
                          </div>
                          <Link to='#'>
                            <div className="homes-text-sec">
                              <p className="red-text txt-overflow">
                              host_location
                                <span className="room-content-dot">
                                  <i className="fas fa-circle" />
                                </span>{" "}
                                sub_category_name
                              </p>
                              <h4 className="homes-title txt-overflow m-t-10">
                              host_name
                              </h4>
                              <h5 className="homes-price txt-overflow m-t-10 new-features">
                                <ul className="list-unstyled">
                                    <li>
                                      <img
                                          src={
                                              window.location.origin +
                                              "/assets/img/rapid-booking.svg"
                                          }
                                          alt=""
                                          className="booking-icon-1"
                                      />
                                      <span>Rapid Booking Allowed</span>
                                    </li>
                                  
                                    <li>
                                      <img
                                          src={
                                              window.location.origin +
                                              "/assets/img/rapid-booking.svg"
                                          }
                                          alt=""
                                          className="booking-icon-1"
                                      />
                                      <span className="text-strike">Rapid Booking Allowed</span>
                                    </li>
                                  
                                    <li>
                                      <img
                                          src={
                                              window.location.origin +
                                              "/assets/img/lock.svg"
                                          }
                                          alt=""
                                          className="booking-icon-1"
                                      />
                                      <span>See amenities for details</span>
                                    </li>
                                </ul>
                              </h5>
                            
                              <div className="flex-common">
                                <p className="guest-icon">
                                  <i className="fa fa-users"></i> 12
                                </p>
                                <h5 className="rating-sec">
                                  <span className="rating-star">
                                    {this.starRatingHost(0)}
                                  </span>
                                  <span className="rating-count">
                                    &nbsp;(0)
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                   
                      )}
                    </div>
                  </div>
                : ''}
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(NewSinglepropertiesIndex);
