import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import ProfileInput from "../../Helper/profileInput";
import Form from "../../Helper/form";
import SideBar from "./sideBar";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";

class HostPayoutDetails extends HostHelper {
  constructor(props) {
    super(props);

    // States and props usage
  }

  state = {
    data: {},
    loading: true,
    error: null,
    loginStatus: true,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false
  };

  componentDidMount() {
    // this.getHostDetails();
    if (this.state.error != null) {
      ToastDemo(this.props.toastManager, this.state.error, "error");
    }
    this.getBillingDetails();
  }

  getBillingDetails = () => {
    api.postMethod("billing_info").then(response => {
      if (response.data.success) {
        this.setState({
          data: response.data.data,
          loading: false
        });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "success");
      }
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });

    api.postMethod("update_billing_info", this.state.data).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  render() {
    const { data, loading, error, loginStatus } = this.state;
    if (!loginStatus) {
      return (
        <Redirect
          to={{
            pathname: "/host/login",
            state: { error: error }
          }}
        />
      );
    }
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <SideBar />
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                <form onSubmit={this.handleSubmit}>
                  <div className="panel">
                    <div className="panel-heading">required</div>

                    <div className="panel-body  account">
                      <ProfileInput
                        label="Customer Name"
                        type="text"
                        placeholder=""
                        id="customer_name"
                        name="customer_name"
                        value={loading ? "" : data.customer_name}
                        onChange={this.handleChange}
                        description=""
                      />

                      <ProfileInput
                        label="Transit Number"
                        type="text"
                        placeholder=""
                        id="transit_no"
                        name="transit_no"
                        value={loading ? "" : data.transit_no}
                        onChange={this.handleChange}
                        description="Transit number be either 4 or 5 digits."
                      />

                      <ProfileInput
                        label="Account Number"
                        type="text"
                        placeholder=""
                        id="account_no"
                        name="account_no"
                        value={loading ? "" : data.account_no}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="Inst Number"
                        type="text"
                        placeholder=""
                        id="inst_no"
                        name="inst_no"
                        value={loading ? "" : data.inst_no}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Account type</label>
                        </div>
                        <div className="col-9">
                          <select
                            className="form-control"
                            name="account_type"
                            id="account_type"
                            name="account_type"
                            onChange={this.handleChange}
                          >
                            <option>Select</option>
                            <option value="1" 
                              selected={
                                data.account_type == 1
                                  ? true
                                  : false
                              }>Saving Account</option>
                            <option value="0" selected={
                                data.account_type == 0
                                  ? true
                                  : false
                              }>Checking Account</option>
                          </select>
                        </div>
                      </div>

                      <ProfileInput
                        label="Company Name"
                        type="text"
                        placeholder=""
                        id="company_name"
                        name="company_name"
                        value={loading ? "" : data.company_name}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="Street"
                        type="text"
                        placeholder=""
                        id="street"
                        name="street"
                        value={loading ? "" : data.street}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="City"
                        type="text"
                        placeholder=""
                        id="city"
                        name="city"
                        value={loading ? "" : data.city}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="Postal Code"
                        type="text"
                        placeholder=""
                        id="postal_code"
                        name="postal_code"
                        value={loading ? "" : data.postal_code}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="Prov"
                        type="text"
                        placeholder=""
                        id="prov"
                        name="prov"
                        value={loading ? "" : data.prov}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="Phone"
                        type="text"
                        placeholder=""
                        id="phone"
                        name="phone"
                        value={loading ? "" : data.phone}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="Fax"
                        type="text"
                        placeholder=""
                        id="fax_number"
                        name="fax_number"
                        value={loading ? "" : data.fax_number}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <ProfileInput
                        label="e-transfer Email id"
                        type="text"
                        placeholder=""
                        id="e_transfer_email"
                        name="e_transfer_email"
                        value={loading ? "" : data.e_transfer_email}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking with another RentRoom user. This is how we can
                        all get in touch."
                      />

                      <div className="row">
                        <div className="col-9 offset-3 text-center">
                          <button
                            className="pink-btn btn-block"
                            disabled={this.state.buttonDisable}
                          >
                            {this.state.loadingContent != null
                              ? this.state.loadingContent
                              : "save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostPayoutDetails);
