import React, { Component } from "react";

class AddListingStep5 extends Component {
  state = {};
  render() {
    const { thirdApiResponse, handleAmentiesChange, formData } = this.props;
    return (
      <div id="step-6" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{thirdApiResponse[0].title}</h4>
              <p className="main-txt">{thirdApiResponse[0].description}</p>
              <div className="flow-content">
                {thirdApiResponse[0].data.map((details) => (
                  <div className="flow-box" key={details.server_key}>
                    <h5 className="flow-box-tit">{details.question}</h5>
                    <input
                      on={handleAmentiesChange}
                      type="hidden"
                      value={details.server_key}
                      name={`amenities[${details.server_key}][server_key]`}
                    ></input>
                    {details.answer.length > 0 ? (
                      details.answer.map((ans) => (
                        <div
                          className="form-group form-check tick-check"
                          key={`amenities-key${details.server_key}${ans.key}`}
                        >
                          <input
                            type={details.type}
                            className="form-check-input"
                            name={`amenities[${details.server_key}][answer]`}
                            value={ans.key}
                            onChange={handleAmentiesChange}
                            id={`amenities-key${details.server_key}${ans.key}`}
                            defaultChecked={
                              formData[details.server_key] == undefined
                                ? false
                                : formData[details.server_key].indexOf(
                                    ans.key
                                  ) !== -1
                                ? true
                                : false
                            }
                          />
                          {console.log(
                            "check this",
                            formData[details.server_key] == undefined
                              ? ""
                              : formData[details.server_key].indexOf(ans.key) ==
                                ans.key
                              ? true
                              : formData[details.server_key].indexOf(ans.key)
                          )}
                          <label
                            className="form-check-label"
                            htmlFor={`amenities-key${details.server_key}${ans.key}`}
                          >
                            <div>
                              <h5 className="amen-tit">{ans.value}</h5>
                            </div>
                          </label>
                        </div>
                      ))
                    ) : (
                      <div className="form-group large-form-group">
                        <input
                          type={details.type == "input" ? "text" : details.type}
                          className="form-control"
                          placeholder="Provide accurate text"
                          name={`amenities[${details.server_key}][answer]`}
                          onChange={handleAmentiesChange}
                        />
                      </div>
                    )}
                    {details.is_description_required != 1 ? <hr></hr> : ""}

                    {details.is_description_required == 1 ? (
                      <>
                        <div className="form-group large-form-group">
                          <label className="form-check-label">
                            <h3 className="amen-tit">Description *</h3>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Provide accurate description"
                            name={`amenities[${details.server_key}][description]`}
                            onChange={handleAmentiesChange}
                          />
                        </div>
                        <hr></hr>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep5;
