import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
const $ = window.$;
class EditListingStep9 extends Component {
  state = {
    loading: true,
    data: null,
    formData: {
      host_id: this.props.match.params.host_id,
    },
    loadingContent: null,
    submitClicked: false,
    isInputChanged: false,
  };

  componentDidMount() {
    this.step9ApiCall();
  }

  handleAddOnServices11 = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    if (input.type === "text" || input.type == "number") {
      let array = [];
      let value = input.value;
      if (value != null) {
        if(value == '') {
          value = 0;
        }
        array.push(value);
      }
      formData[input.name] = array;
      this.setState({ formData });
    }
    if (input.type === "checkbox") {
      if (input.checked) {
        formData[input.name] = 1;
        this.setState({ formData });
      } else {
        if (formData[input.name] != undefined) {
          formData[input.name] = 0;
          this.setState({ formData });
        }
      }
    }

    if (input.type === "radio") {
      if (input.checked) {
        formData[input.name] = input.value;
        this.setState({ formData });
      } else {
        if (formData[input.name] != undefined) {
          formData[input.name] = input.value;
          this.setState({ formData });
        }
      }
    }
    this.setState({ isInputChanged: true });
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step8/${this.props.match.params.host_id}`
    );
  };

  handleOnClick = ({ currentTarget: input }) => {
    console.log("click input", input);
    // const formData = { ...this.state.formData };
    if (
      input.value == undefined ||
      input.value == null ||
      input.value == "$0.00"
    ) {
      $("#" + input.id).val("");
    }
    // formData[input.name] = "";
    // this.setState({ formData });
  };
  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    if (this.state.isInputChanged) {
      
      api
        .postMethod("hosts_step9_save", this.state.formData)
        .then((response) => {
          if (response.data.success) {
            this.props.history.push(`/host/listing/`);
          } else {
            alert(response.data.error);
            if (response.data.error_code == 1004) {
              this.props.toggleLoginModal(false);
            }
            this.setState({
              loadingContent: null,
              submitClicked: false,
            });
          }
        });
    } else {
    }
    this.props.history.push(`/host/listing/`);

    this.setState({
      loadingContent: null,
      submitClicked: false,
    });
  };

  step9ApiCall = () => {
    api
      .postMethod("hosts_step_9", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          const formData = {...this.state.formData};
          $(response.data.data.addon_services).each(function(index,value){
            if(value.amount > 0) {
              
              formData[`addon_services[${value.server_key}][amount]`] = value.amount;
              formData[`addon_services[${value.server_key}][is_required]`] = value.is_required;
              if(value.type == "others") {
                formData[`addon_services[${value.server_key}][unit_type]`] = value.unit_type;
                formData[`addon_services[${value.server_key}][min_value]`] = value.min_value;
                formData[`addon_services[${value.server_key}][max_value]`] = value.max_value;
              }
            }
          });
        
          this.setState({ formData });
          this.setState({
            loading: false,
            data: response.data.data,
          });
        } else {
          if (response.data.error_code == 1004) {
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  render() {
    const { data, formData } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 9: Update Addon Services
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              {this.state.loading ? (
                "Loading..."
              ) : (
                <div id="smartwizard" className="add-list-wizard">
                  <div className="add-list-content p-5-10">
                    <div id="step-12" className="flow-tab">
                      <div className="add-flow-block row">
                        <div className="col-md-12">
                          <div className="add-flow-block-left">
                            <h4 className="flow-tit">Add on Services.</h4>
                            <p className="main-txt">
                              Add additional add-on services & fees to your
                              listing. Please give the amount and mention it's
                              required for every booking or optional for the
                              guest to choose. Leave it blank if it's not
                              applicable.
                            </p>
                            <div className="flow-content">
                              {data.addon_services.map((details) => (
                                <div className="addon-content-box">
                                  <div className="form-group large-form-group">
                                    <label className="light-txt">
                                      {details.name}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`addon_services[${details.server_key}][amount]`}
                                      defaultValue={details.amount_formatted}
                                      placeholder="$0.00"
                                      onChange={this.handleAddOnServices11}
                                      onClick={this.handleOnClick}
                                      id={`addon_services_${details.server_key}`}
                                    />
                                  </div>

                                  <h5 className="flow-box-tit">
                                    Is required for every booking?
                                  </h5>
                                  <div
                                    className="form-check add-list-block"
                                    key={`add_services_required_yes_${details.server_key}`}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`addon_services[${details.server_key}][is_required]`}
                                      id={`add_services_required_yes${details.server_key}`}
                                      value="1"
                                      onChange={this.handleAddOnServices11}
                                      defaultChecked={
                                        details.is_required == 1 ? true : false
                                      }
                                    />
                                    <label
                                      className="form-check-label row"
                                      htmlFor={`add_services_required_yes${details.server_key}`}
                                    >
                                      <h5 className="flow-check-tit">Yes</h5>
                                    </label>
                                  </div>
                                  <div
                                    className="form-check add-list-block"
                                    key={`add_services_required_no_${details.server_key}`}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`addon_services[${details.server_key}][is_required]`}
                                      id={`add_services_required_no${details.server_key}`}
                                      value="0"
                                      onChange={this.handleAddOnServices11}
                                      defaultChecked={
                                        details.is_required == 0 ? true : false
                                      }
                                    />
                                    <label
                                      className="form-check-label row"
                                      htmlFor={`add_services_required_no${details.server_key}`}
                                    >
                                      <h5 className="flow-check-tit">No</h5>
                                    </label>
                                  </div>

                                  {details.type == "others" ? (
                                    <div>
                                      <div className="form-group large-form-group">
                                        <p className="light-txt"></p>
                                        <h5 className="flow-box-tit">
                                          Choose Unit Type
                                        </h5>

                                        <div
                                          className="form-check add-list-block"
                                          key={`add_services_required_unit_type_person${details.server_key}`}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`addon_services[${details.server_key}][unit_type]`}
                                            id={`add_services_required_unit_type_person${details.server_key}`}
                                            value="person"
                                            onClick={this.handleAddOnServices11}
                                            defaultChecked={
                                              details.unit_type == "person"
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="form-check-label row"
                                            htmlFor={`add_services_required_unit_type_person${details.server_key}`}
                                          >
                                            <h5 className="flow-check-tit">
                                              Per Person
                                            </h5>
                                          </label>
                                        </div>
                                        <div
                                          className="form-check add-list-block"
                                          key={`add_services_required_unit_type_item${details.server_key}`}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`addon_services[${details.server_key}][unit_type]`}
                                            id={`add_services_required_unit_type_item${details.server_key}`}
                                            value="item"
                                            onClick={this.handleAddOnServices11}
                                            defaultChecked={
                                              details.unit_type == "item"
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="form-check-label row"
                                            htmlFor={`add_services_required_unit_type_item${details.server_key}`}
                                          >
                                            <h5 className="flow-check-tit">
                                              Per Item
                                            </h5>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-6">
                                          <p className="light-txt">Min Unit</p>

                                          <input
                                            type="number"
                                            className="form-control"
                                            min="1"
                                            step="1"
                                            name={`addon_services[${details.server_key}][min_value]`}
                                            placeholder="0"
                                            onChange={
                                              this.handleAddOnServices11
                                            }
                                            defaultValue={details.min_value}
                                          />
                                        </div>

                                        <div className="col-6">
                                          <p className="light-txt">Max Unit</p>

                                          <input
                                            type="number"
                                            min="1"
                                            step="1"
                                            className="form-control"
                                            name={`addon_services[${details.server_key}][max_value]`}
                                            placeholder="0"
                                            onChange={
                                              this.handleAddOnServices11
                                            }
                                            defaultValue={details.max_value}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <p>Per Booking</p>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="listing-footer">
                    <button
                      className="white-btn"
                      type="button"
                      onClick={this.handleBackButton}
                    >
                      <i className="fas fa-chevron-left" /> Back
                    </button>
                    <button
                      className="green-btn float-right sw-btn-next"
                      type="button"
                      disabled={this.state.submitClicked}
                      onClick={this.submitForm}
                    >
                      {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : "Submit"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditListingStep9;
