import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/Helper";
import SearchForm from "../Helper/searchForm";
import api from "../../Environment";
import { render } from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import Typed from 'react-typed';
import ToastDemo from "../Helper/toaster";
import LazyLoad from 'react-lazyload';

const queryString = require("query-string");

var yesterday = moment().subtract( 1, 'day' );
var valid = function( current ){
    return current.isAfter( yesterday );
};

class HomeMainBanner extends Helper {

  state = {
    categories: null,
    loadingCategory: true,
    searchDisplay: true,
    loadingSearch: true,
    categories: null,
    location_id: null,
    searchValue: {},
    sub_categories: null,
    hosts: null,
    keywords: null,
  };

  searchOnChange = ({ currentTarget: input }) => {
    this.setState({ searchDisplay: false,loadingSearch: true,keywords:null,hosts:null });
    if (input.value == "") {
      this.setState({ searchDisplay: true });
    }
    const searchValue = { ...this.state.searchValue };
    searchValue["keywords"] = input.value;
    this.setState({searchValue});

    if(input.value.length > 2) {
      api
      .postMethod("filter_locations_hosts", { location: input.value })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            // searchResult: response.data.data.service_locations,
            hosts: response.data.data.hosts,
            keywords: response.data.data.keywords,
            loadingSearch: false,
          });
        }
      });
    }

    // api
    // .postMethod("filter_categories", { search_key: input.value })
    // .then((response) => {
    //   if (response.data.success) {
    //     this.setState({
    //       categories: response.data.data.categories,
    //       sub_categories: response.data.data.sub_categories,
    //       loadingSearch: false,
    //     });
    //   }
    // });
  };

  searchResult = (event) => {
    event.preventDefault();
    let location_id;
    if (this.state.searchResult.length > 0) {
      location_id = this.state.searchResult[0].service_location_id;
    } else {
      location_id = "";
    }
    this.setState({ searchDisplay: true });
    this.searchApiCall(this.props.props, location_id);
  };

  searchOnClickResult = (event, search) => {
    event.preventDefault();
    this.setState({ searchDisplay: true });

    this.searchApiCall(this.props.props, search.service_location_id);
  };

  handleChangeLocation = ({ currentTarget: input }) => {
    this.setState({ location_id: input.value });
  };
  submitSearch = (event) => {
    event.preventDefault();
    this.handleSubmit();
  }
  
  render() {
    const {
      loadingCategory,
      searchDisplay,
      categories,
      loadingSearch,
      sub_categories,
      hosts,
      keywords,
    } = this.state;
    const {
      loadingEvent,
      eventData,
      loadingLocation,
      locationData,
      getSearchInput,
      searchButtonDisable,
      searchLoadingContent,
      submitSearch,
      selectEvent,
      selectKeyword,
      searchInput,
    } = this.props;
    let inputProps = {
      placeholder: 'Date',
    };
    var location = {}

    const handleRedirect = (host) => {
      location = {
        pathname : `/event/${host.unique_id}`,
        state: {
          id: host.unique_id,
        },
      }
      return location;
    }
    
    const handleCategoryRedirect = (category) => {

      const query = queryString.stringify(
        {type : categories.api_page_type , selectedSubCategory : [category.name] , subCatID : [categories.api_page_type_id]},
        { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
      );

      location = {
        pathname : `/see_all/${category.name}/${category.api_page_type_id}/${categories.api_page_type}`,
        state : { 
          type:  categories.api_page_type,
          // searchInput: { 
          //   cat_title : category.name,
          //   cat_id : category.category_id
          // },
          notMainSearch: true,
        },
        search : query
      }
      return location;
    }

    const handleSubCategory = (sub_category) => {

      console.log("selected sub" , sub_category)

      console.log(sub_categories)

      const query = queryString.stringify(
        {type : sub_categories.api_page_type , selectedSubCategory : [sub_category.name] , subCatID : [sub_category.sub_category_id]},
        { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
      );

      console.log("redirect ")

      location = {
        pathname : `/see_all/${sub_category.name}/${sub_category.api_page_type_id}/${sub_categories.api_page_type}`,
        state : { 
          type:  sub_categories.api_page_type,
          // searchInput: { 
          //   sub_cat_title : sub_category.name,
          //   sub_cat_id : sub_category.category_id
          // },
          notMainSearch: true,
        },
        search : query
      }
      return location;
    }

    const handleSubmit = () => {

      const query = queryString.stringify(
        {
          selectedEvent : [searchInput.event_title] , 
          eventId : [searchInput.event_id] , 
          fromDate : searchInput.from_date , 
          selectedLocation : [searchInput.service_location_name], 
          keywords : searchInput.keywords, 
          locationId : [searchInput.service_location_id] 
        },
        { arrayFormat: "bracket" }
      );
      console.log(searchInput);
      location = {
        pathname : `/see_all`,
        state : { 
          searchInput: searchInput,
          notMainSearch: true,
        },
        search : query
      }

      return location;
    }

    return (
      <div className="banner-slider">
        <div className="position-rel">
          {/* <div className="flexslider">
            <ul className="slides">
              <div
                className="bgimg"
                style={{
                  backgroundImage: `url('../../../assets/img/banner.jpg')`,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  backgroundSize: "cover",
                }}
              />
              <li>
                <div
                  className="bgimg"
                  style={{
                    backgroundImage: `url('../../../assets/img/banner1.jpg')`
                  }}
                />
              </li>
            </ul>
          </div> */}
          <LazyLoad>
          <Carousel 
          showArrows={false} 
          showThumbs={false} 
          autoPlay={true} 
          infiniteLoop={true}
          interval={4550}
          stopOnHover={false}
          showStatus={false}
          showIndicators={false}
          // autoPlaySpeed={5000}
          >
                <div className="carousel-item-list">
                  <img className="img-dimension" src="../assets/img/user-landing-bg.jpg" alt="First slide"/>
                  {/* <div className="banner-text">
                    <h1 className="change-text clr-blue">great party ideas!</h1>
                  </div> */}
                </div>
                {/* <div className="carousel-item-list">
                  <img className="img-dimension" src="../assets/img/banner-2.jpg" alt="Second slide"/>
                  <div className="banner-text">
                    <h1 className="change-text clr-yellow">dream wedding destinations!</h1>
                  </div>
                </div>
                <div className="carousel-item-list">
                  <img className="img-dimension" src="../assets/img/banner-3.jpg" alt="Third slide"/>
                  <div className="banner-text">
                    <h1 className="change-text clr-orange">perfect venues!</h1>
                  </div>
                </div>
                <div className="carousel-item-list">
                  <img className="img-dimension" src="../assets/img/banner-4.jpg" alt="Third slide"/>
                  <div className="banner-text">
                    <h1 className="change-text clr-orange">social spaces!</h1>
                  </div>
                </div> */}
            </Carousel>
          </LazyLoad>
          <div className="banner-overlay">
            <div className="banner-text-space">
              <div>
                <h1>
                  Looking for {/*<span className="op-reduce">Party</span>{" "}*/}
                  <Typed
                    strings={[
                      'great party ideas!',
                      'dream wedding destinations!',
                      'perfect venues!',
                      'social spaces!']}
                    typeSpeed={80}
                    backSpeed={65}
                    loop
                    className="colorchange"
                  />
                </h1>
                  <h1>Ask the Budha</h1>
              </div>
              <form>
                <div className="search-bar">
                  <div className="row">
                    <div className="col-md-12">
                      <form className="search-form">
                        <div className="input-group dropdown">
                          <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon">
                              <i className="fas fa-search" />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control form-control-1 dropdown-toggle"
                            data-toggle="dropdown"
                            placeholder="What are you planning?"
                            name="search"
                            autoComplete="off"
                            value={this.state.searchValue.keywords}
                            onChange={this.searchOnChange}
                          />

                          <div className="dropdown-menu banner-dropdown-menu">
                            {loadingSearch
                              ? " "
                              : keywords.length > 0
                              ? keywords.map((keyword) => (
                                  
                                <Link
                                  to={"#"}
                                  key={keyword.id}
                                  className="grey-outline-btn size1"
                                  value={keyword.id}
                                  onClick={(event) => {
                                    const searchValue = { ...this.state.searchValue };
                                    searchValue["keywords"] = keyword.keywords;
                                    this.setState({searchValue});
                                    selectKeyword(event, keyword)
                                  }
                                  }
                                >
                                  {keyword.keywords} ({keyword.hosts_count})
                                </Link>
                              ))
                            : " "}
                            <h5 className="dropdown-title">Explore </h5>
                            {loadingEvent
                              ? "Loading..."
                              : eventData.length > 0
                              ? eventData.map((eventDetail) => (
                                  <Link
                                    to={"#"}
                                    key={eventDetail.event_id}
                                    className="grey-outline-btn size1"
                                    value={eventDetail.event_id}
                                    onClick={(event) =>
                                      {
                                        const searchValue = { ...this.state.searchValue };
                                        searchValue["keywords"] = eventDetail.title;
                                        this.setState({searchValue});
                                        selectEvent(event, eventDetail)
                                      }
                                    }
                                  >
                                    {eventDetail.title}
                                  </Link>
                                ))
                              : "No data found"}
                          </div>
                          {/* <div
                            className={
                              searchDisplay
                                ? "dropdown-menu banner-dropdown-menu home-banner-search-list"
                                : "dropdown-menu banner-dropdown-menu home-banner-search-list show"
                            }
                          >
                            <ul className="map-search-list">
                              
                              {loadingSearch
                                ? "Loading..."
                                : keywords.length > 0
                                ? keywords.map((keyword) => (
                                    <li key={keyword.host_id}>
                                      <Link
                                        to={handleRedirect(keyword)}
                                        className="map-search-list-link"
                                      >
                                        <i class="fa fa-key"></i>{" "}
                                        {keyword.keywords}
                                      </Link>
                                    </li>
                                  ))
                              : " "}
                              {loadingSearch
                                ? "Loading..."
                                : hosts.length > 0
                                ? hosts.map((host) => (
                                    <li key={host.id}>
                                      <Link
                                        to={handleRedirect(host)}
                                        className="map-search-list-link"
                                      >
                                        <i class="fab fa-buffer"></i>{" "}
                                        {host.host_name}
                                      </Link>
                                    </li>
                                  ))
                              : "No Data Found"}
                              
                            </ul>
                          </div> */}
                        </div>
                       
                        <label
                          className="mr-sm-2 sr-only"
                          for="inlineFormCustomSelect"
                        >
                          Location
                        </label>
                        <select
                          className="custom-select form-control border-right border-radius-search-1 resp-mrg-btm-xs"
                          id="inlineFormCustomSelect"
                          onChange={getSearchInput}
                          name="service_location_id"
                          value={searchInput.service_location_id}
                        >
                          <option selected>Location</option>
                          {loadingLocation
                            ? "Loading..."
                            : locationData.length > 0
                            ? locationData.map((location) => (
                                <option
                                  key={location.service_location_id}
                                  value={location.service_location_id}
                                >
                                  {location.service_location_name}
                                </option>
                              ))
                            : "No data found"}
                        </select>
                        <div className="input-group width-half home-search-date">
                          <Datetime
                              onChange={this.props.dateChange}
                              isValidDate={ valid }
                              dateFormat="DD-MM-YYYY"
                              inputProps={ inputProps }
                              timeFormat={false} 
                              closeOnSelect={true}
                          /> 
                        </div>
                        <Link
                          to={handleSubmit()}
                          className="btn search-btn"
                        >
                          Search
                        </Link>
                        {/* <button
                          className="btn search-btn"
                          disabled={searchButtonDisable}
                          onClick={this.submitSearch}
                        >
                          {searchLoadingContent != null
                            ? searchLoadingContent
                            : "Search"}
                        </button> */}
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeMainBanner;
