import React from 'react'

const FrontDeskBookingConfirm = () => {

  return (
    <>
      <div className="main my-5">
        <div className="singlehome-img-sec confirmation-page">
          <div className="container h-100">
            <div className="col-xl-8 col-lg-10 col-md-10 auto-margin h-100">
              <div className="confirmation-wrapper"> 
                <h1 className="text-uppercase category-section-head text-center mb-4">Woohoo! Your reservation is confirmed.</h1>
                  <p className="text-center">Relax and let Party Budha host work their magic to make your party a memorable one.</p>
                  <p>Your card has been charged to hold the booking.</p>
                  <svg id="confirm_check" x="0px" y="0px"
                  viewBox="0 0 60 60">
                    <path className="confirmation-check" d="M40.61,23.03L26.67,36.97L13.495,23.788c-1.146-1.147-1.359-2.936-0.504-4.314
                    c3.894-6.28,11.169-10.243,19.283-9.348c9.258,1.021,16.694,8.542,17.622,17.81c1.232,12.295-8.683,22.607-20.849,22.042
                    c-9.9-0.46-18.128-8.344-18.972-18.218c-0.292-3.416,0.276-6.673,1.51-9.578"/>
                  </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FrontDeskBookingConfirm
