import React, { Component } from "react";
import Helper from "../../Helper/Helper";

class TripFaqs extends Helper {

    state = {
        faqsData: null,
        loadingFaqs: true,
    };

    render() {
        const {
            faqsData,
            loadingFaqs,
        } = this.props;
        
        return loadingFaqs ? (
            ""
        ) : (
          <React.Fragment>
            {faqsData.length > 0 ? (
              <>
              <p className="overview-line"></p>
              <div className="single-trip-view-faq-sec">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h4 className="single-cat-text medium-cls ">FAQ</h4>
                    <div
                      id="accordion-faq"
                      className="faq single-trip-faq"
                    >
                      {faqsData.map((faq) => (
                        <div className="card">
                          <div className="card-header ">
                            <a
                              className="card-link faq-heading"
                              data-toggle="collapse"
                              data-target={`#${faq.id}`}
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <span>
                                <i className="fas fa-chevron-down" />
                              </span>
                              {faq.question}
                            </a>
                          </div>
                          <div id={faq.id}
                            className="collapse"
                            aria-labelledby={faq.id}
                            data-parent="#accordion-faq" 
                          >
                            <div className="card-body faq-para">
                              {faq.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              </>
            ) : (
              ""
            )}
          </React.Fragment>
        );
    }
}

export default TripFaqs;