import React, { Component } from "react";
import dayjs from "dayjs";
import { Link, Redirect } from "react-router-dom";
import ProfileInput from "../../Helper/profileInput";
import Form from "../../Helper/form";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";
import { DatePicker } from "@y0c/react-datepicker";
import Select from 'react-select'
const $ = window.$;

class HostAddPromoCode extends HostHelper {
  constructor(props) {
    super(props);

    // States and props usage
  }

  state = {
    data: {},
    loading: true,
    error: null,
    loginStatus: true,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false,
    loadingUsers: true,
    users:null,
    value: [],
    userEmail: '',
  };

  componentDidMount() {
    this.getUsersListApiCall();
  }

  getUsersListApiCall = () => {
    api
      .postMethod("users_list")
      .then((response) => {
        if (response.data.success) {
          
          this.setState({
            users: response.data.data.users_list,
            loadingUsers: false,
          });
          console.log("users list",response.data.data.users_list);
        } 
      }); 
  };

  getArrivingDateandTime = title => (...args) => {
    const data = { ...this.state.data };
    const datess = dayjs(args[0]).format("DD-MM-YYYY");
    data["expiry_date"] = datess;
    this.setState({ data });
  };

  getDefaultLeavingTime = () => {
    var oldDateObj = new Date();
    var newDateObj = new Date();
    newDateObj.setTime(oldDateObj.getTime() + 60 * 60 * 1000);

    return dayjs(newDateObj).format("YYYY-MM-DD HH:mm");
  };
  getDefaultArravingTime = () => {
    return dayjs(new Date()).format("YYYY-MM-DD HH:mm");
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });

    api.postMethod("promo_codes_save", this.state.data).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
        this.props.history.push("/host/promo-code-list");
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  handleUserEmail = ({ currentTarget: input }) => {
    this.setState({ userEmail:input.value });
  };


  checkUser = event => {
    event.preventDefault();

    api.postMethod("search_users_email", { user_email: this.state.userEmail,user_ids:this.state.data.user_id }).then(response => {
      if (response.data.success) {

        let user_ids = [];

        $(response.data.data.users_list).each(function(index,value){
          user_ids.push(value.value);
        });
    
        this.setState({...this.state , data : { ...this.state.data ,user_id:user_ids}});
        this.setState({ value: response.data.data.users_list,userEmail:''});

      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  handleChangeUser = event => {
    let user_ids = [];
    $(event).each(function(index,value){
      user_ids.push(value.value);
    });
    this.setState({...this.state , data : { ...this.state.data ,user_id:user_ids}});

    this.setState({ value: event});

  };

  render() {
    const { data, loading, error, loginStatus,users } = this.state;

    const options = users

    if (!loginStatus) {
      return (
        <Redirect
          to={{
            pathname: "/host/login",
            state: { error: error }
          }}
        />
      );
    }
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                <form onSubmit={this.handleSubmit}>
                  <div className="panel">
                    <div className="panel-heading">Add Promo Code</div>

                    <div className="panel-body  account add-promo-code">
                      <ProfileInput
                        label="Title"
                        type="text"
                        placeholder=""
                        id="Title"
                        name="title"
                        value={data.title}
                        onChange={this.handleChange}
                        description=""
                      />

                      <ProfileInput
                        label="Promo Code"
                        type="text"
                        placeholder=""
                        id="promo_code"
                        name="promo_code"
                        value={data.promo_code}
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>User Email</label>
                        </div>
                        <div className="col-7">
                          <input
                            type="text"
                            className="form-control"
                            id="user_email"
                            placeholder="User Email"
                            value={this.state.userEmail}
                            onChange={this.handleUserEmail}
                            name="user_email"
                          />
                        </div>
                        <div className="col-2 text-center">
                          <button
                            className="pink-btn btn-small"
                            onClick={(event) => this.checkUser(event)}
                          >
                            Apply
                          </button>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Select user</label>
                        </div>
                        <div className="col-9">
                        <Select 
                          name="user_id" 
                          // options={options}
                          placeholder="Selected Users"
                          onChange={this.handleChangeUser}
                          defaultValue={[data.user_id]}
                          isMulti
                          value={this.state.value}
                        />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Amount type</label>
                        </div>
                        <div className="col-9">
                          <select
                            className="form-control"
                            name="amount_type"
                            id="amount_type"
                            value={data.amount_type}
                            onChange={this.handleChange}
                          >
                            <option>Select</option>
                            <option value="0">Percentage Amount</option>
                            <option value="1">Absolute Amount</option>
                          </select>
                        </div>
                      </div>





                      <ProfileInput
                        label="Amount"
                        type="text"
                        placeholder=""
                        id="amount"
                        name="amount"
                        value={data.amount}
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Expiry Date</label>
                        </div>
                        <div className="col-9">
                          <DatePicker
                            initialDate={this.getDefaultArravingTime()}
                            onChange={this.getArrivingDateandTime(
                              "Range DatePicker"
                            )}
                            showDefaultIcon
                            placeholder
                          />
                        </div>
                      </div>

                      <ProfileInput
                        label="No of Users Limit"
                        type="text"
                        placeholder=""
                        id="no_of_users_limit"
                        name="no_of_users_limit"
                        value={data.no_of_users_limit}
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                      <ProfileInput
                        label="Per User Limit"
                        type="text"
                        placeholder=""
                        id="per_users_limit"
                        name="per_users_limit"
                        value={data.per_users_limit}
                        onChange={this.handleChange}
                        description="Note : If Your giving lowercase and tab space means we will remove this things and giving Uppercase promo code format without space."
                      />

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Description</label>
                        </div>
                        <div className="col-9">
                          <textarea
                            type="text"
                            className="form-control"
                            rows="4"
                            name="description"
                            value={data.description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-9 offset-3 text-center">
                          <button
                            className="pink-btn btn-block"
                            disabled={this.state.buttonDisable}
                          >
                            {this.state.loadingContent != null
                              ? this.state.loadingContent
                              : "save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostAddPromoCode);
