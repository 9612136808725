import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ProfileSideBar from "../../Helper/profileSideBar";
import ProfileInput from "../../Helper/profileInput";
import Form from "../../Helper/form";
import Helper from "../../Helper/Helper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../Environment";
import configuration from "react-global-configuration";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class EditProfile extends Helper {
  constructor(props) {
    super(props);

    // States and props usage
  }

  state = {
    data: null,
    loading: true,
    profileError: null,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false,
  };

  componentDidMount() {
    this.getUserDetails();
  }

  handleMobileNumber = (value) => {
    const data = { ...this.state.data };
    data['mobile'] = value;
    this.setState({ data });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });

    let userDetails = { ...this.state.data };
    const data = {
      name: userDetails.name,
      description: userDetails.description,
      email: userDetails.email,
      secondary_email: userDetails.secondary_email,
      mobile: userDetails.mobile,
    };
    api.postMethod("update_profile", data).then((response) => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        this.errorCodeChecker(response.data.error_code);
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  render() {
    const { data, loading, profileError } = this.state;

    if (profileError != null) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { error: profileError },
          }}
        />
      );
    }
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <ProfileSideBar />
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                <form onSubmit={this.handleSubmit}>
                  <div className="panel">
                    <div className="panel-heading">Edit Profile</div>

                    <div className="panel-body  account">
                      <ProfileInput
                        label="Name"
                        type="text"
                        placeholder=""
                        id="fname"
                        name="name"
                        value={loading ? "Loading" : data.name}
                        onChange={this.handleChange}
                        description=""
                      />

                      <ProfileInput
                        label="Email Address"
                        type="text"
                        placeholder=""
                        id="email"
                        name="email"
                        value={loading ? "Loading" : data.email}
                        onChange={this.handleChange}
                        // description="We won’t share your private email address with other
                        // PartyBudha users."
                      />

                      {/* <ProfileInput
                        label="Secondary Email"
                        type="text"
                        placeholder=""
                        id="secondary_email"
                        name="secondary_email"
                        value={loading ? "Loading" : data.secondary_email}
                        onChange={this.handleChange}
                        description="We won’t share your secondary email address with other
                        PartyBudha users."
                      /> */}
                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label htmlFor="number">Phone number</label>
                        </div>
                        <div className="col-9">
                          <PhoneInput
                            country={'ca'}
                            label="Phone number"
                            type="text"
                            placeholder=""
                            id="number"
                            name="mobile"
                            value={loading ? "" : data.mobile}
                            onChange={value => this.handleMobileNumber(value)}
                            className="form-control"
                          />
                        </div>
                      </div>

                      {/* <ProfileInput
                        label="Phone Number"
                        type="text"
                        placeholder=""
                        id="number"
                        name="mobile"
                        value={loading ? "Loading" : data.mobile}
                        onChange={this.handleChange}
                        description="This is only shared once you have a confirmed
                        booking."
                      /> */}

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Description</label>
                        </div>
                        <div className="col-9">
                          <textarea
                            type="text"
                            className="form-control"
                            rows="4"
                            name="description"
                            value={loading ? " " : data.description}
                            onChange={this.handleChange}
                          />
                          <h5 className="profile-note">
                            {configuration.get("configData.site_name")} is built
                            on relationships. Help other people get to know you.
                          </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-9 offset-3 text-center">
                          <button
                            className="pink-btn btn-block"
                            id="saveButton"
                            disabled={this.state.buttonDisable}
                          >
                            {this.state.loadingContent != null
                              ? this.state.loadingContent
                              : "Save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(EditProfile);
