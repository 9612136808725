import React, { Component } from "react";

import { Link } from "react-router-dom";

import configuration from "react-global-configuration";

import $ from "jquery";
import { isMobile } from "react-device-detect";
import api from "../../Environment";
import ToastDemo from "../../components/Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";

class PromoBanner extends Component {
    state = {
        arrowClass: isMobile ? "fas fa-caret-down" : 'fas fa-caret-left',
        email:'',
        promoCodeApiResponse:null,
        loadingPromoCode:false,
        promoCodeText:'',
        placeHolder:''
    };
    
    componentDidMount() {

    }

    onInputChange = ({ currentTarget: input }) => {
        this.setState({email:input.value });
    };

    checkPromoCode = (event) => {
        this.setState({
            loadingPromoCode: true,
            promoCodeText:'',
        });
        event.preventDefault();
        setTimeout(() => {
            api
            .postMethod("get_banner_promo_code",{email:this.state.email})
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        promoCodeApiResponse: response.data.data,
                        loadingPromoCode: false,
                        email:'',
                        promoCodeText:response.data.data.promo_code_text,
                        placeHolder:'PROMO CODE: '+response.data.data.promo_code,
                    });
                    ToastDemo(
                        this.props.toastManager,
                        response.data.message,
                        "success"
                    );
                } else {
                    this.setState({
                        promoCodeApiResponse: null,
                        loadingPromoCode: false,
                        email:'',
                        promoCodeText:'',
                        placeHolder:'',
                    });
                    ToastDemo(this.props.toastManager, response.data.error, "error");
                    return false;
                }
            });
        }, 10000);
    };

    handleBannerClick = (event) => {
        event.preventDefault();
        $("#theme-settings").toggleClass("open");
        $("#theme-button").toggleClass("close");
        if(isMobile) {
            this.setState({
                arrowClass: this.state.arrowClass == "fas fa-caret-down" ? "fas fa-caret-up" : "fas fa-caret-down",
            });
        } else {
            this.setState({
                arrowClass: this.state.arrowClass == "fas fa-caret-right" ? "fas fa-caret-left" : "fas fa-caret-right",
            });
        }
        
    };

    onCopy = () => {
        this.setState({ copied: true });
    
        $("#referral_copy_success")
          .html("copied")
          .fadeIn("slow");
    
        setTimeout(function() {
          $("#referral_copy_success").html("");
        }, 1000);
    };

    render() {
        return (
        <div>
            <div id="theme-button" className="floating-promo-sec" onClick={this.handleBannerClick}>
                <span className="promo-caret-icon">
                    <i className={`${this.state.arrowClass}`}></i>
                </span>
                <p className="promo-content">Avail Up To <span> $150 OFF</span> </p>
            </div>
            <div id="theme-settings" className="settings-panel">
                <div className="promo-banner-box">
                    <div className="promo-banner-img-sec">
                        {/* <img
                            src={window.location.origin + `/assets/img/landing-page/promo-banner-img.jpg`}
                        /> */}
                        <picture>
                            <source className="lazyload" type="image/jpg" srcset={window.location.origin + `/assets/img/landing-page/promo-banner-img.jpg`}/>
                            <img data-src={window.location.origin + `/assets/img/landing-page/promo-banner-img.jpg`} type="image/jpg" className="lazyload" alt="Loading..."/>
                        </picture>
                        <div className="promo-banner-caption">
                            <p>Best Way to Book Your Party</p>
                        </div>
                    </div>
                    <div className="promo-banner-info">
                        <div className="promo-banner-info-header">
                            <div className="info-sec">
                                <p>Avail Up To</p>
                                <h3>$150 OFF</h3>
                            </div>
                            <div className="promo-banner-info-img-sec">
                                {/* <img
                                    src={window.location.origin + `/assets/img/landing-page/logo.png`}
                                /> */}
                                <picture>
                                  <source className="lazyload" type="image/png" srcset={window.location.origin + `/assets/img/landing-page/logo.png`}/>
                                  <img data-src={window.location.origin + `/assets/img/landing-page/logo.png`} type="image/png" className="lazyload" alt="Loading..."/>
                                </picture>
                            </div>
                        </div>
                        <div className="promo-banner-info-body">
                            {!this.state.loadingPromoCode && this.state.promoCodeText =='' ?
                                <p>You have a chance to win a big discount. Are you ready?</p>
                            : 
                                (this.state.promoCodeText ? 
                                    <>
                                    <p>Congratulations, you have won  <span style={{color:'#ff8322'}}>{this.state.promoCodeText}</span> discount!</p>
                                    <h6>Hurry the code will expire soon!</h6>
                                    </>
                                : 
                                   ''
                                )
                            }

                            {this.state.loadingPromoCode ?
                                <div className="promo-loader-sec">
                                    <img
                                        src={window.location.origin + `/assets/img/landing-page/promo-loader-1.gif`}
                                        className="promo-loader"
                                    />
                                </div>
                            : '' }
                            {this.state.promoCodeText ?
                            <div className="promo-banner-form">
                                <div className="input-group">
                                    <input type="text" 
                                        className="form-control copy-text" 
                                        placeholder={this.state.placeHolder}
                                        disabled
                                    />
                                    <div className="input-group-append">
                                        <CopyToClipboard
                                        onCopy={this.onCopy}
                                        text={this.state.promoCodeApiResponse.promo_code}
                                        >
                                            <button className="input-group-text" id="basic-addon2">
                                                COPY
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                <p
                                    id="referral_copy_success"
                                    className="text-success"
                                ></p>
                            </div>
                            : 
                            <form onSubmit={this.checkPromoCode} className="promo-banner-form">
                                <div className="input-group">
                                    <input type="text" 
                                        className="form-control" 
                                        placeholder="YOUR EMAIL" 
                                        id="email"
                                        onChange={this.onInputChange}
                                        name="email"
                                        value={this.state.email}
                                    />
                                    <div className="input-group-append">
                                        <button type="submit" className="input-group-text" id="basic-addon2">
                                            TRY MY LUCK
                                        </button>
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default withToastManager(PromoBanner);
