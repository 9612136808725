import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeLastContent extends Component {
    state = {};
    render() {
        return (
            <div className="site-content">
                <div className="find-out-more">
                    <div className="row">
                        <div className="col-sm-5 col-md-5 col-lg-4 col-xl-4">
                            <div className="find-img-sec">
                                {/* <img
                                    srcSet="../../../assets/img/host-image.jpg,
                                                ../../../assets/img/host-image.jpg 1.5x,
                                                ../../../assets/img/host-image.jpg 2x"
                                    src="../../../assets/img/host-image.jpg"
                                    alt="Other Find"
                                    className="homes-img"
                                /> */}
                                <picture>
                                  <source className="lazyload homes-img" type="image/jpg" srcset="../../../assets/img/host-image.jpg"/>
                                  <img data-src="../../../assets/img/host-image.jpg" type="image/jpg" className="lazyload homes-img" alt="Loading..."/>
                              </picture>
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-8 col-xl-8 find-text-sec">
                            <div className="">
                                <h1 className="find-head captalize">
                                Find out how you could earn more by listing with us
                                </h1>
                                <h4 className="captalize find-subhead">
                                      Provide your business with the wings to fly
                                </h4>
                                <Link to="/host" className="green-btn btn-small">
                                    Find out more
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeLastContent;
