import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import Helper from "../../Helper/Helper";
import configuration from "react-global-configuration";
import AddCardForm from "../AccountSettings/addCardForm";
import PromoCodeDetails from "../Payments/PromoCodeDetails";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { apiConstants } from "../../Constant/constants";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";

const $ = window.$;
const onlyDay = {
  weekday: "long",
};
const onlyMonth = {
  month: "short",
};
const onlyDate = {
  day: "numeric",
};

class Checkout extends Helper {
  // constructor(props) {
  //   super(props);

  //   // States and props usage
  // }
  state = {
    loading: true,
    adults: 0,
    children: 0,
    infants: 0,
    apiResponses: null,
    apiCallStatus: true,
    total_days_price: null,
    total_guest: null,
    total_days: null,
    total: null,
    description: null,
    step: 1,
    cardList: null,
    cardLoading: true,
    special_request: "",
    is_alcohol_served: 0,
    cancellationPolicy: null,
    rulesLoading: true,
    activeBreakdown: null,
    paymentAddCard: false,
    apiResponse: null,
    buttonDisable: false,
    buttonLoading: null,
    service_delivery_address: '',
  };

  componentDidMount() {
    // Call api function

    if (this.props.location.state) {
      this.setState({
        loading: false,
      });
      this.listCardApi();
      const inputData = {
        host_id: this.props.location.state.singleTripDetails.basic_details
          .host_id,
      };
      api.postMethod("rule_lookups", inputData).then((response) => {
        if (response.data.success === true) {
          this.setState({
            cancellationPolicy: response.data.data.cancellation_policies,
            rulesLoading: false,
          });
        } else {
          this.errorCodeChecker(response.data.error_code);
        }
      });
    } else {
      this.props.history.goBack();
    }
  }

  listCardApi = () => {
    api.postMethod("cards_list").then((response) => {
      if (response.data.success) {
        this.setState({
          cardList: response.data.data,
          cardLoading: false,
        });
        // if (response.data.data.cards.length < 1) {
        //   ToastDemo(
        //     this.props.toastManager,
        //     "There is no card in your account. Please add card and try again.. ",
        //     "error"
        //   );
        //   this.props.history.push("/user-payment");
        // }
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.props.history.goBack();
      }
    });
  };

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.listCardApi();
  }

  renderAutoComplete = () => {
    const { google } = this.props;
    if (!google) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place);
      if (!place.geometry) return;

      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );

      this.setState({service_delivery_address:full_address });
    });
  };

  nextStep = (event) => {
    event.preventDefault();
    this.setState({ step: 2 });
  };
  prevStep = (event) => {
    event.preventDefault();
    this.setState({ step: 1 });
  };

  setPaymentAddCard = (event) => {
    event.preventDefault();
    this.setState({ paymentAddCard: true });
  };

  closePaymentAddCardModal = () => {
    this.setState({ paymentAddCard: false });
  };

  bookingCreate = (event) => {
    event.preventDefault();
    this.setState({
      loadingBookingForm: true,
      buttonDisable: true,
      buttonLoading: "Loading... Please wait",
    });
    let inputData;
    if (this.props.location.state.promoCodeApiResponse != null) {
      inputData = {
        ...this.props.location.state.priceCalInput,
        payment_mode: "CARD",
        total_guests: this.props.location.state.apiResponse.total_guests,
        description: this.state.description,
        promo_code: this.props.location.state.promoCodeInputData.promo_code,
        step: 5,
        is_alcohol_served: this.state.is_alcohol_served,
        special_request: this.state.special_request,
        service_delivery_address : this.state.service_delivery_address,
      };
    } else {
      inputData = {
        ...this.props.location.state.priceCalInput,
        payment_mode: "CARD",
        total_guests: this.props.location.state.apiResponse.total_guests,
        description: this.state.description,
        is_alcohol_served: this.state.is_alcohol_served,
        special_request: this.state.special_request,
        step: 5,
        service_delivery_address : this.state.service_delivery_address,
      };
    }

    api.postMethod("bookings_create", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          buttonLoading: null,
          buttonDisable: false,
        });
        ToastDemo(this.props.toastManager, "Booking successfull", "success");
        if (response.data.data.is_automatic_booking == 0) {
          this.props.history.push(
            "/booking-confirmation",
            response.data.data.booking_id
          );
        } else {
          this.props.history.push(
            "/rapid-booking-confirmation",
            response.data.data.booking_id
          );
        }
      } else {
        this.setState({
          buttonLoading: null,
          buttonDisable: false,
        });
        ToastDemo(this.props.toastManager, response.data.error, "error");
        if (
          response.data.error_code == 1004 ||
          response.data.error_code == 1002
        ) {
          this.props.history.push("/");
          ToastDemo(this.props.toastManager, "Please login to book", "error");
        }
      }
    });
  };

  handleChange = ({ currentTarget: input }) => {
    if (input.type === "checkbox") {
      if (input.checked) {
        this.setState({ is_alcohol_served: 1 });
      } else {
        if (input.name != undefined) {
          this.setState({ is_alcohol_served: 0 });
        }
      }
    } else {
      if (input.name == "description")
        this.setState({ description: input.value });
      else if (input.name == "special_request")
        this.setState({ special_request: input.value });
      else
      this.setState({ service_delivery_address: input.value });
    }
  };

  changeCard = ({ currentTarget: input }) => {
    api
      .postMethod("cards_default", { user_card_id: input.value })
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  handleBreakdownModal = (active_modal) => {
    this.setState({
      activeBreakdown: active_modal,
    });
  };

  handlebreakdownClose = () => {
    this.setState({
      activeBreakdown: null,
    });
  };

  render() {
    const {
      loading,
      loadingStatus,
      loadingContent,
      adults,
      children,
      infants,
      apiCallStatus,
      step,
      cardList,
      cardLoading,
      rulesLoading,
      cancellationPolicy,
      buttonDisable,
      buttonLoading
    } = this.state;
    if (loading) {
      return "Loading...";
    } else {
      const {
        apiResponse,
        loadingPromoCode,
        promoCodeApiResponse,
        singleTripDetails,
        priceCalInput,
        addonServicesData,
        apiCallStatus,
      } = this.props.location.state;

      const stripePromise = loadStripe(apiConstants.STRIPE_PUBLISHABLE_KEY);

      console.log("api response", apiResponse ? this.props.location.state : "");

      return (
        <div>
          <div className="d-flex align-items-center pad-1">
            <div className="checkout-left">
              <Link className="navbar-brand" to={"/"}>
                <img
                  data-src={window.location.origin + "/assets/site/favicon.png"}
                  src={configuration.get("configData.site_logo")}
                  alt={configuration.get("configData.site_name")}
                />
              </Link>
            </div>
            <div className="stepwizard checkout-left">
              <div className="stepwizard-row setup-panel">
                <div className="stepwizard-step">
                  <a
                    href="#step-1"
                    className={
                      step == 1 ? "checkout-head active" : "checkout-head"
                    }
                    onClick={this.prevStep}
                  >
                    1. Review booking details
                    <span>
                      <i className="fas fa-chevron-right ml-3 mr-3" />
                    </span>
                  </a>
                </div>
                <div className="stepwizard-step ">
                  <a
                    href="#step-3"
                    className={
                      step == 2 ? "checkout-head active" : "checkout-head"
                    }
                    onClick={this.nextStep}
                  >
                    2. Confirm and Pay
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="site-content"
            id="bookingForm"
            onClick={
              this.state.activeBreakdown != null
                ? this.handlebreakdownClose
                : null
            }
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 md-order-2">
                <form className="checkout-form">
                  <div className="mediLink common-msg">
                    <img
                      src={
                        singleTripDetails.basic_details.gallery.length
                          ? singleTripDetails.basic_details.gallery[0].picture
                          : singleTripDetails.basic_details.host_picture
                      }
                      alt={
                        singleTripDetails.basic_details.gallery.length
                          ? singleTripDetails.basic_details.gallery[0].caption
                          : ""
                      }
                      className="mr-3 align-self-center common-img"
                    />
                    <div className="media-body">
                      <h4 className="common-text">
                        <span className="bold-cls">This is a rare find.</span>{" "}
                        {singleTripDetails.provider_details.provider_name}
                        's place is usually booked.
                      </h4>
                    </div>
                  </div>
                  <div className="">
                    {step == 1 ? (
                      <div className="panel-primary setup-content" id="step-1">
                        <h1 className="checkout-section-head">
                          Review event details
                        </h1>
                        {/* Calendar section */}
                        <div>
                          <h4 className="checkout-section-subhead">
                            Event in {apiResponse.total_days_remaining}
                            {/* {singleTripDetails.basic_details.host_location} */}
                          </h4>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="flex-book-cal">
                                <div className="booking-calendar-div">
                                  <div className="booking-calendar-div-section">
                                    <p>
                                      {apiResponse.checkin_day}

                                      {/* {new Date(
                                        apiResponse.checkin
                                      ).toLocaleDateString("en-CA", onlyMonth)} */}
                                    </p>
                                    <p>
                                      {apiResponse.checkin_month}

                                      {/* {new Date(
                                        apiResponse.checkin
                                      ).toLocaleDateString("en-CA", onlyDate)} */}
                                    </p>
                                  </div>
                                </div>
                                <div className="booking-calendar-text-section">
                                  <div>
                                    <p>
                                      {apiResponse.checkin_day_text}
                                      {/* {new Date(
                                        apiResponse.checkin
                                      ).toLocaleDateString(
                                        "en-CA",
                                        onlyDay
                                      )}{" "} */}
                                      {/* check-in */}
                                    </p>

                                    {/* <h4>{apiResponse.checkin_time}</h4> */}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="booking-calendar-text-section checkout-event-field"
                                style={{ display: "none" }}
                              >
                                <p>
                                  TimeSlot:
                                  {apiResponse.host_time_slot}
                                </p>
                              </div>
                            </div>
                            {apiResponse.host_time_slot != "" && (
                              <div className="col-md-6">
                                <div className="flex-book-cal">
                                  <div className="booking-calendar-div">
                                    <div className="booking-calendar-div-section d-flex align-items-center m-0 h-100 justify-content-center">
                                      <p>Slot</p>
                                    </div>
                                  </div>
                                  <div className="booking-calendar-text-section">
                                    <div>
                                      <p>
                                        {apiResponse.host_time_slot_formatted}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <hr />
                        {apiResponse.pricing_type == 3 && (
                          <div className="col-md-6">
                            <h4 className="checkout-section-subhead">
                              Package Details
                            </h4>
                            <div className="flex-book-cal">
                              <div className="booking-calendar-div">
                                <div className="booking-calendar-div-section package-disp d-flex align-items-center m-0 h-100 justify-content-center">
                                  <p>Package name</p>
                                </div>
                              </div>
                              <br></br>

                              <div className="booking-calendar-text-section package-disp">
                                <div>
                                  <p>{apiResponse.package_name}</p>
                                </div>
                              </div>

                              <div className="booking-calendar-div package-disp">
                                <div className="booking-calendar-div-section  d-flex align-items-center m-0 h-100 justify-content-center">
                                  <p>Package Price</p>
                                </div>
                              </div>

                              <div className="booking-calendar-text-section package-disp">
                                <div>
                                  <p>{apiResponse.package_base_price}</p>
                                </div>
                              </div>
                            </div>
                            <br></br>
                          </div>
                          
                        )}

                        {singleTripDetails.amenties.data.find(
                          (ametity) =>
                            ametity.user_question == "Alcohol Allowed" &&
                            ametity.is_available == 1
                        ) && (
                          <div className="input-group">
                            <label className="">
                              <input
                                type="checkbox"
                                name="is_alcohol_served"
                                value="0"
                                onChange={this.handleChange}
                                className="m-0"
                                // checked={
                                //   singleTripDetails.amenties.data.find(
                                //     (ametity) =>
                                //       ametity.user_question == "Alcohol Allowed"
                                //   ).is_available == 1
                                //     ? true
                                //     : false
                                // }
                              />{" "}
                              Is Alcohol Served?
                            </label>
                          </div>
                        )}
                        <div>
                          <div className="media">
                            <div className="media-body">
                              <h4 className="medium-cls">
                                Special request for this booking
                              </h4>
                              <p>
                                It’s a request to the host and is not a
                                commitment from the host for this booking.
                              </p>
                            </div>
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              rows="5"
                              id="special_request"
                              name="special_request"
                              value={this.state.special_request}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        
                        {singleTripDetails.basic_details.is_delivery_service_available ? 
                          <>
                            <div>
                              <div className="media">
                                <div className="media-body">
                                  <h4 className="medium-cls">
                                    Service Delivery Address(Optional)
                                  </h4>
                                  <p>
                                    Location where you want the service to be delivered.
                                  </p>
                                </div>
                              </div>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder=" Service Delivery Address"
                                  onFocus={this.renderAutoComplete}
                                  ref={(ref) => (this.autocomplete = ref)}
                                  name="service_delivery_address"
                                  // value={this.state.service_delivery_address}
                                  onChange={this.handleChange}
                                />
                            </div>
                            <div>
                            <div className="media">
                              <div className="media-body">
                                <h4 className="medium-cls">
                                  Delivery Instructions(Optional)
                                </h4>
                              </div>
                            </div>
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                rows="5"
                                id="comment"
                                name="description"
                                onChange={this.handleChange}
                                value={this.state.description}
                              />
                            </div>
                          </div>
                          </>
                        : ''}
                        {singleTripDetails.basic_details.is_automatic_booking ==
                          1 && (
                          <div className="d-flex">
                            <div className="mr-3 calender-icon">
                              <i class="far fa-calendar-alt"></i>
                            </div>
                            <div>
                              <div className="media">
                                <div className="media-body">
                                  <h4 className="medium-cls">
                                    This listing accepts Rapid Booking, normally
                                    there is no approval required from the host
                                  </h4>
                                  <p>
                                    You will be charged now to hold the booking.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        
                        <div className="hr-ruler"></div>
                        {/* Guest details + description */}
                        <button
                          className="green-btn nextBtn checkout-btn"
                          type="button"
                          onClick={this.nextStep}
                        >
                          Agree and Continue
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {step == 2 ? (
                      <div className="setup-content" id="step-2">
                        <h1 className="checkout-section-head">
                          Confirm and Pay
                        </h1>
                        <div className="form-group">
                          <div className="flex-space-btwm">
                            <label>Pay with card</label> {' '}
                            <button className="pink-btn-sm" onClick={this.setPaymentAddCard}>
                              Add New
                            </button>
                          </div>
                          <Elements stripe={stripePromise}>
                            <AddCardForm
                              paymentAddCard={this.state.paymentAddCard}
                              closePaymentAddCardModal={
                                this.closePaymentAddCardModal
                              }
                            />
                          </Elements>

                          {cardLoading
                            ? "Loading..."
                            : cardList.cards.length > 0
                            ? cardList.cards.map((card) => (
                                <div
                                  className="form-check add-list-block"
                                  key={card.user_card_id}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="card"
                                    value={card.user_card_id}
                                    id={card.user_card_id}
                                    defaultChecked={
                                      card.is_default == 1 ? true : false
                                    }
                                    onChange={this.changeCard}
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor={card.user_card_id}
                                  >
                                    <h5 className="flow-check-tit">
                                      XXXX....
                                      {card.last_four}
                                    </h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                              ))
                            : ""}
                        </div>

                        <h4 className="checkout-text">Cancellation policy</h4>
                        <ul className="pl-sm">
                          {rulesLoading
                            ? "Loading..."
                            : cancellationPolicy.length > 0
                            ? cancellationPolicy.map((data) => (
                                <li>
                                  <h4 className="checkout-para">
                                    {data.content}
                                  </h4>
                                </li>
                              ))
                            : ""}
                        </ul>
                        {/* <h5 className="checkout-smalltext">
                          You will be redirected to PayU to complete your
                          payment.
                          <span className="bold-cls">
                            You must complete the process or the transaction
                            will not occur.
                          </span>
                        </h5> */}

                        <div className="row align-centre">
                          <div className="col-md-6">
                            <button
                              className="pink-btn checkout-btn"
                              disabled={buttonDisable}
                              onClick={this.bookingCreate}
                            >
                              {buttonLoading == null ? "Confirm and Pay" : buttonLoading}                          
                            </button>
                          </div>
                          <div className="col-md-6">
                            <div className="media align-items-center">
                              <img className="checkout-comodo-logo" src={"https://ssl.comodo.com/images/seals/sectigo_trust_seal_lg_2x.png"} alt=""/>
                              <img className="checkout-stripe-logo"  src={window.location.origin + `/assets/img/stripe-logo.svg`} alt=""/>
                            </div>
                          </div>
                        </div>
                        
                        <h5 className="checkout-smalltext">
                          I agree to the House Rules, Cancellation Policy, and
                          to the
                          <Link to="/page/terms" target="_blank" className="">
                            {" "}
                            {configuration.get("site_name")} Guest Services
                            Agreement.{" "}
                          </Link>
                          I also agree to pay the total amount shown, including
                          the Service Fees.
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                <div className="checkout-box pricedetails-box">
                  <div className="media">
                    <div className="media-body">
                      <h4 className="mt-0 captalize bold-cls">
                        {singleTripDetails.basic_details.host_name}{" "}
                      </h4>
                      <p className="captalize">
                        {singleTripDetails.basic_details.host_type}
                      </p>

                      {/* <h5 className="rating-sec">
                        <span className="rating-star">
                          {this.starRatingHost(
                            singleTripDetails.basic_details.admin_ratings
                          )}
                        </span>
                        <span className="ratingText">
                          {singleTripDetails.basic_details.admin_ratings} {"ratings"}
                        </span>
                        <span>
                          &nbsp;
                          {singleTripDetails.basic_details.total_ratings}{" "}
                          reviews
                        </span>
                      </h5> */}
                      <div className="ratingWrapper">
                        {/* <span className="rating">
                          <i class="fas fa-star"></i>
                        </span> */}
                        <span className="rating">
                          {this.starRatingHost(
                            singleTripDetails.basic_details.admin_ratings
                          )}
                        </span>
                        <span className="ratingText">
                          {singleTripDetails.basic_details.admin_ratings == ""
                            ? 0
                            : singleTripDetails.basic_details.admin_ratings}
                          {" Ratings"}
                        </span>
                        {/* <span className="reviewText">
                          ( {this.props.singleTripDetails.provider_details.total_reviews_formatted})
                        </span> */}
                      </div>
                    </div>
                    <img
                      src={
                        singleTripDetails.basic_details.gallery.length
                          ? singleTripDetails.basic_details.gallery[0].picture
                          : singleTripDetails.basic_details.host_picture
                      }
                      alt={
                        singleTripDetails.basic_details.gallery.length
                          ? singleTripDetails.basic_details.gallery[0].caption
                          : ""
                      }
                      className="ml-3 review-img3"
                    />
                  </div>

                  <p className="overview-line" />
                  {apiResponse.pricing_type != 3 ? (
                  <h5 className="choosen-details">
                    <i className="fas fa-user mr-3" />
                    {apiCallStatus
                      ? apiResponse.total_guests
                      : this.state.total_guests}
                    {' '}Guests
                  </h5>
                  ) : ''}
                  <h5 className="choosen-details">
                    <i className="far fa-calendar-alt mr-3" />
                    {apiResponse.checkin}

                    {apiResponse.host_time_slot
                      ? " -" + apiResponse.host_time_slot_formatted
                      : ""}

                    {/* {apiResponse.booking_type != 1 && (
                      <>
                      <i className="fas fa-arrow-right ml-3 mr-3" />
                      { apiResponse.checkout}
                      </>
                    )} */}
                  </h5>
                  {apiResponse.pricing_type == 3 && apiCallStatus ? (
                    <h5 className="choosen-details">
                      <i className="fas fa-address-book mr-3" />
                      {apiResponse.package_name} -{" "}
                      {apiResponse.package_base_price} Package
                    </h5>
                  ) : (
                    ""
                  )}

                  <p className="overview-line" />

                  <div className="row">
                    <div className="col-6">
                      {apiResponse.pricing_type == 1 ? (
                        <h5 className="choosen-details">Base Price</h5>
                      ) : null}
                      {apiResponse.pricing_type == 2 ? (
                        <h5 className="choosen-details">Total Guest Price</h5>
                      ) : null}
                      {apiResponse.pricing_type == 3 ? (
                        <h5 className="choosen-details">Total Package Price</h5>
                      ) : null}
                      {/* <h5 className="choosen-details">Cleaning fee</h5> */}
                      <div className="BreakdownWrapper">
                        <h5
                          className="choosen-details breakdown-text"
                          onClick={() => this.handleBreakdownModal("service")}
                        >
                          Service fee
                          <span>
                            <i class="far fa-question-circle"></i>
                          </span>
                        </h5>
                        <div
                          className={`breakdownModal ${
                            this.state.activeBreakdown == "service"
                              ? "active"
                              : null
                          }`}
                        >
                          <div className="bookingBreakdownwrapper">
                            <span
                              className="close"
                              onClick={this.handlebreakdownClose}
                            >
                              <i class="fas fa-times"></i>
                            </span>
                            <p>
                              This helps us run our platform and offer services
                              like 24/7 support.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="BreakdownWrapper">
                        <h5
                          className={`choosen-details ${apiResponse.tax_status ==
                            1 &&
                            apiResponse.tax_price != 0 &&
                            "breakdown-text"}`}
                          onClick={
                            apiResponse.tax_status == 1 &&
                            apiResponse.tax_price != 0
                              ? () => this.handleBreakdownModal("tax")
                              : null
                          }
                        >
                          Taxes
                          {apiResponse.tax_status == 1 &&
                            apiResponse.tax_price != 0 && (
                              <span>
                                <i class="far fa-question-circle"></i>
                              </span>
                            )}
                        </h5>
                        <div
                          className={`breakdownModal ${
                            this.state.activeBreakdown == "tax"
                              ? "active"
                              : null
                          }`}
                        >
                          <div className="bookingBreakdownwrapper column">
                            <div className="breakdownHeading">
                              <span
                                className="close"
                                onClick={this.handlebreakdownClose}
                              >
                                <i class="fas fa-times"></i>
                              </span>
                              <p className="breakdownheading">Tax Breakdown</p>
                            </div>
                            <p className="overview-line1 my-3 "></p>
                            <div className="breakdownBody">
                              {apiResponse.general_tax_price != 0 && (
                                <div className="taxwrapper">
                                  <span>
                                    GST{" "}
                                    {
                                      apiResponse.general_tax_in_percentage_formatted
                                    }
                                  </span>
                                  <span>
                                    {apiResponse.general_tax_price_formatted}
                                  </span>
                                </div>
                              )}
                              {apiResponse.pst_tax_price != 0 && (
                                <div className="taxwrapper">
                                  <span>
                                    PST{" "}
                                    {
                                      apiResponse.pst_tax_in_percentage_formatted
                                    }
                                  </span>
                                  <span>
                                    {apiResponse.pst_tax_price_formatted}
                                  </span>
                                </div>
                              )}
                              {apiResponse.hst_tax_price != 0 && (
                                <div className="taxwrapper">
                                  <span>
                                    HST{" "}
                                    {
                                      apiResponse.hst_tax_in_percentage_formatted
                                    }
                                  </span>
                                  <span>
                                    {apiResponse.hst_tax_price_formatted}
                                  </span>
                                </div>
                              )}
                            </div>
                            <p className="overview-line1 my-3 "></p>
                            <div className="breakdownFooter">
                              <>
                                <span>Total Tax Price</span>
                                <span>{apiResponse.tax_price_formatted}</span>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {apiResponse.security_deposit != "" ? (
                        <h5 className="choosen-details">Security Deposit</h5>
                      ) : (
                        ""
                      )} */}
                      {loadingPromoCode ? (
                        ""
                      ) : (
                        <div className="BreakdownWrapper">
                          <h5
                            className={`choosen-details breakdown-text`}
                            onClick={() =>
                              this.handleBreakdownModal("promocode")
                            }
                          >
                            Promo Code
                            <span>
                              <i class="far fa-question-circle"></i>
                            </span>
                          </h5>
                          <PromoCodeDetails
                            promoCodeApiResponse={apiResponse}
                            activeBreakdown={this.state.activeBreakdown}
                            handlebreakdownClose={this.handlebreakdownClose}
                          />
                        </div>
                      )}
                      {apiResponse.host_addon_service_ids.length > 0 &&
                      addonServicesData.length > 0 ? (
                        <>
                          <div className="BreakdownWrapper">
                            <h5
                              className="choosen-details breakdown-text"
                              onClick={() =>
                                this.handleBreakdownModal("addons")
                              }
                            >
                              Add-on services
                              <span>
                                <i class="far fa-question-circle"></i>
                              </span>
                            </h5>
                            <div
                              className={`breakdownModal ${
                                this.state.activeBreakdown == "addons"
                                  ? "active"
                                  : null
                              }`}
                            >
                              <div className="bookingBreakdownwrapper column">
                                <div className="breakdownHeading">
                                  <span
                                    className="close"
                                    onClick={this.handlebreakdownClose}
                                  >
                                    <i class="fas fa-times"></i>
                                  </span>
                                  <p className="breakdownheading">
                                    Addon Price Breakdown
                                  </p>
                                </div>
                                <p className="overview-line1 my-3 "></p>
                                <div className="breakdownBody">
                                  {apiResponse.host_addon_service_ids.length >
                                    0 &&
                                    apiResponse.host_addon_service_ids.map(
                                      (responseServiceID) =>
                                        addonServicesData.length > 0 &&
                                        addonServicesData
                                          .filter(
                                            (service) =>
                                              service.unit_type == "booking"
                                          )
                                          .map(
                                            (service) =>
                                              responseServiceID ==
                                                service.host_addon_service_id && (
                                                <>
                                                  <div className="taxwrapper">
                                                    <span>{service.name}</span>
                                                    <span>
                                                      {service.currency}{" "}
                                                      {priceCalInput[
                                                        `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                                      ] * service.amount}
                                                    </span>
                                                  </div>
                                                </>
                                              )
                                          )
                                    )}
                                  {apiResponse.host_addon_service_ids.length >
                                    0 &&
                                    apiResponse.host_addon_service_ids.map(
                                      (responseServiceID) =>
                                        addonServicesData.length > 0 &&
                                        addonServicesData
                                          .filter(
                                            (service) =>
                                              service.unit_type == "person" || service.unit_type == "item"
                                          )
                                          .map(
                                            (service) =>
                                              responseServiceID ==
                                                service.host_addon_service_id && (
                                                <>
                                                  <div className="taxwrapper">
                                                    <span>
                                                      {service.name}
                                                      {service.unit_type ==
                                                        "person" || service.unit_type == "item" ? (
                                                        <>
                                                          <span className="mx-2 font-weight-bold">
                                                            x
                                                          </span>
                                                          <span>
                                                            {
                                                              priceCalInput[
                                                                `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                                              ]
                                                            }
                                                          </span>
                                                        </>
                                                      ):''}
                                                    </span>
                                                    <span>
                                                      {service.currency}{" "}
                                                      {priceCalInput[
                                                        `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                                      ] * service.amount}
                                                    </span>
                                                  </div>
                                                </>
                                              )
                                          )
                                    )}
                                </div>
                                <p className="overview-line1 my-3 "></p>
                                <div className="breakdownFooter">
                                  <>
                                    <span>Total Addon Price</span>
                                    <span>
                                      {apiResponse.host_addon_services_total}
                                    </span>
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="col-6 text-right">
                      {apiResponse.pricing_type == 1 ? (
                        <h5 className="choosen-details">
                          {apiResponse.base_price}
                        </h5>
                      ) : null}

                      {apiResponse.pricing_type == 2 ? (
                        <h5 className="choosen-details">
                          {apiResponse.total_guest_price_formatted}
                        </h5>
                      ) : null}
                      {apiResponse.pricing_type == 3 ? (
                        <h5 className="choosen-details">
                          {apiResponse.package_base_price}
                        </h5>
                      ) : null}

                      {/* <h5 className="choosen-details">
                        {apiResponse.cleaning_fee}
                      </h5> */}
                      <h5 className="choosen-details">
                        {apiResponse.service_fee}
                      </h5>
                      <h5 className="choosen-details">
                        {apiResponse.tax_price_formatted}
                      </h5>
                      {/* {apiResponse.security_deposit != "" ? (
                        <h5 className="choosen-details">
                          {apiResponse.security_deposit}
                        </h5>
                      ) : (
                        ""
                      )} */}
                      {loadingPromoCode ? (
                        ""
                      ) : (
                        <h5 className="choosen-details">
                          - {promoCodeApiResponse.promo_amount_formatted}
                        </h5>
                      )}
                      {apiResponse.host_addon_service_ids.length > 0 ? (
                        <h5 className="choosen-details">
                          {apiResponse.host_addon_services_total}
                        </h5>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <p className="overview-line" />
                  <div className="row">
                    <div className="col-6">
                      <h5 className="choosen-details">Total (CAD)</h5>
                    </div>
                    <div className="col-6 text-right">
                      <h5 className="choosen-details">
                        {apiCallStatus
                          ? apiResponse.actual_total_formatted
                          : this.state.total}
                      </h5>
                    </div>
                  </div>
                  <h6 className="top">
                    The quoted fees include any applicable exchange rate.
                  </h6>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(withToastManager(Checkout));
