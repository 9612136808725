import React from "react";
import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
// import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import CustomSelect from "../../User/TripDetails/CustomSelect";

import "react-dates/initialize";

const $ = window.$;

class TripBookingForm extends Helper {
  componentDidMount() {
    this.props.setHostId();
  }

  render() {
    const { basicDetails, status } = this.props;
    const priceCalInput = this.props.priceCalInput;
    const {
      apiResponse,
      loadingBookingForm,
      apiCallStatus,
      buttonDisable,
      buttonLoadingContent,
      loadingPromoCode,
      promoCodeApiResponse,
      addonServicesData,
      loadingAddonService,
      timeSlotLoading,
      timeSlotData,
    } = this.props;
    return (
      <div className="col-xl-5 pl-5 relative dis-lg-none dis-md-none dis-sm-none dis-xs-none">
        <div className="pricedetails-box">
          <div className="price-align-left">
            <h3 className="home-price-details price-info-left">
              {basicDetails.per_day_formatted}
              <small> per Day</small>
            </h3>
            {/* <h3 className="home-price-details price-info-right">
              {this.props.singleTripDetails.pricing_details.security_deposit ==
              0
                ? "No"
                : this.props.singleTripDetails.pricing_details
                    .security_deposit_formatted}
              <small> Security Deposit</small>
            </h3> */}
          </div>
          <h5 className="rating-sec">
            <span className="rating-star">
              {this.starRatingHost(basicDetails.overall_ratings)}
            </span>
            <sup className="rating-sup">
              &nbsp;{basicDetails.total_ratings} reviews
            </sup>
          </h5>
          <p className="overview-line1" />
          <div className="form-group">
            <label className="medium-cls">Guest Name</label>
            <div className="input-group">
                <input
                    type="text"
                    name="user_name"
                    onChange={this.props.handleChange}
                    className="form-control"
                />
            </div>
        </div>
        <div className="form-group">
            <label className="medium-cls">Email</label>
            <div className="input-group">
                <input
                    type="text"
                    name="email"
                    onChange={this.props.handleChange}
                    className="form-control"
                />
            </div>
        </div>
        <div className="form-group">
            <label className="medium-cls">Mobile</label>
            <div className="input-group">
                <input
                    type="text"
                    name="mobile"
                    onChange={this.props.handleChange}
                    className="form-control"
                />
            </div>
        </div>
          <div className="form-group">
            <label className="medium-cls">dates</label>
            <div className="input-group">
              {/* <DateRangePicker /> */}
              {basicDetails.booking_type === 1 ? (
                <div className="booking-create">
                  <RangeDatePicker
                    startPlaceholder="Check-in"
                    endPlaceholder="Checkout"
                    onChange={this.props.onNormalBookingChangeDate("Range DatePicker")}
                    className="form-control form-control-lg "
                  />
                </div>
              ) : null}
              {basicDetails.booking_type === 2 ? (
                <>
                  <div className="booking-create">
                    <DatePicker
                      placeholder="Choose Date"
                      onChange={this.props.onChangeDate("Range DatePicker")}
                      className="form-control form-control-lg "
                    />
                  </div>
                  {timeSlotLoading ? (
                    ""
                  ) : timeSlotData.length > 0 ? (
                    <>
                    
                    <CustomSelect data={timeSlotData} placeHolder={"choose time slot"} handleChange={this.props.customSelectTimeSlotSelected} watcher={this.props.priceCalInput}/>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          <div className="form-group">
            <label className="medium-cls">Guests</label>
            <input
              type="text"
              id="guest-mobile-btn"
              data-toggle="dropdown"
              value={
                "Adult:" +
                priceCalInput.adults +
                ", Children: " +
                priceCalInput.children +
                ", Infants: " +
                priceCalInput.infants +
                ", Guests: " +
                priceCalInput.age_special
              }
              className="form-control form-control-lg"
            />
            <div className="dropdown-menu guest">
              <form className="inc-dec-count">
                <div className="row">
                  <div className="col-6 col-sm-6">
                    <label htmlFor="name">adults</label>
                  </div>
                  <div className="col-6 col-sm-6 text-right">
                    <div className="float-right">
                      <div
                        className="decrement-btn"
                        onClick={() => this.props.decreament("adults")}
                      >
                        <div className="dec button">
                          <i className="fas fa-minus" />
                        </div>
                      </div>
                      <input
                        type="text"
                        value={priceCalInput.adults}
                        name="adults"
                      />
                      <div
                        className="increment-btn"
                        onClick={() => this.props.increament("adults")}
                      >
                        <div className="inc button">
                          <i className="fas fa-plus" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6 col-sm-6">
                    <label htmlFor="name">children</label>
                  </div>
                  <div className="col-6 col-sm-6 text-right">
                    <div className="float-right">
                      <div
                        className="decrement-btn"
                        onClick={() => this.props.decreament("children")}
                      >
                        <div className="dec button">
                          <i className="fas fa-minus" />
                        </div>
                      </div>
                      <input
                        type="text"
                        value={priceCalInput.children}
                        name="children"
                      />
                      <div
                        className="increment-btn"
                        onClick={() => this.props.increament("children")}
                      >
                        <div className="inc button">
                          <i className="fas fa-plus" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6 col-sm-6">
                    <label htmlFor="name">infants</label>
                  </div>
                  <div className="col-6 col-sm-6 text-right">
                    <div className="float-right">
                      <div
                        className="decrement-btn"
                        onClick={() => this.props.decreament("infants")}
                      >
                        <div className="dec button">
                          <i className="fas fa-minus" />
                        </div>
                      </div>
                      <input
                        type="text"
                        value={priceCalInput.infants}
                        name="infants"
                      />
                      <div
                        className="increment-btn"
                        onClick={() => this.props.increament("infants")}
                      >
                        <div className="inc button">
                          <i className="fas fa-plus" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6 col-sm-6">
                    <label htmlFor="name">Guests</label>
                  </div>
                  <div className="col-6 col-sm-6 text-right">
                    <div className="float-right">
                      <div
                        className="decrement-btn"
                        onClick={() => this.props.decreament("age_special")}
                      >
                        <div className="dec button">
                          <i className="fas fa-minus" />
                        </div>
                      </div>
                      <input
                        type="text"
                        value={priceCalInput.age_special}
                        name="age_special"
                      />
                      <div
                        className="increment-btn"
                        onClick={() => this.props.increament("age_special")}
                      >
                        <div className="inc button">
                          <i className="fas fa-plus" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <p className="overview-line" />
            {loadingBookingForm ? (
              ""
            ) : (
              <React.Fragment>
                <div className="row">
                  <div className="col-6">
                    {basicDetails.pricing_type == 1 ? (
                      <h5 className="choosen-details">Base Price</h5>
                    ) : null}
                    {basicDetails.pricing_type == 2 ? (
                      <h5 className="choosen-details">Total Guest Price</h5>
                    ) : null}
                    {basicDetails.pricing_type == 3 ? (
                      <h5 className="choosen-details">Total Package Price</h5>
                    ) : null}
                    {/* <h5 className="choosen-details">Cleaning fee</h5> */}
                    <h5 className="choosen-details">Service fee</h5>
                    <h5 className="choosen-details">Tax fee</h5>
                    <h5 className="choosen-details">Addon Service fee</h5>
                    {/* {this.props.singleTripDetails.pricing_details
                      .security_deposit == 0 ? (
                      ""
                    ) : (
                      <h5 className="choosen-details">Security Deposit</h5>
                    )} */}

                    {loadingPromoCode ? (
                      ""
                    ) : (
                      <h5 className="choosen-details">Promo code</h5>
                    )}
                  </div>
                  <div className="col-6 text-right">
                    {apiResponse && basicDetails.pricing_type == 1 ? (
                      <h5 className="choosen-details">
                        {apiResponse.base_price}
                      </h5>
                    ) : null}

                    {apiResponse && basicDetails.pricing_type == 2 ? (
                      <h5 className="choosen-details">
                        {apiResponse.total_guest_price_formatted}
                      </h5>
                    ) : null}

                    {apiResponse && basicDetails.pricing_type == 3 && apiResponse ? (
                      <h5 className="choosen-details">
                        {apiResponse.package_base_price}
                      </h5>
                    ) : null}
                    {/* {apiResponse ? (
                        <h5 className="choosen-details">
                        {apiResponse.cleaning_fee}
                        </h5>
                    ) : null} */}


                    {apiResponse ? (
                        <h5 className="choosen-details">
                        {apiResponse.service_fee}
                        </h5>
                    ) : null}
                    {apiResponse ? (
                        <h5 className="choosen-details">
                        {apiResponse.tax_price_formatted}
                        </h5>
                    ) : null}

                    {/* {this.props.singleTripDetails.pricing_details
                      .security_deposit == 0 ? (
                      ""
                    ) : (
                      <h5 className="choosen-details">
                        {
                          this.props.singleTripDetails.pricing_details
                            .security_deposit_formatted
                        }
                      </h5>
                    )} */}

                    {apiResponse ? (
                        <h5 className="choosen-details">
                        {apiResponse.host_addon_services_total}
                        </h5>
                    ) : null}

                    {loadingPromoCode ? (
                      ""
                    ) : (
                      <h5 className="choosen-details">
                        {promoCodeApiResponse.promo_amount}
                      </h5>
                    )}
                  </div>
                </div>

                {/* <div className="row">
                  {apiResponse && apiResponse.host_addon_service_ids.length > 0
                    ? apiResponse.host_addon_service_ids.map(
                        (responseServiceID) =>
                          addonServicesData.length > 0
                            ? addonServicesData.map((service) =>
                                responseServiceID ==
                                service.host_addon_service_id ? (
                                  <React.Fragment>
                                    <div className="col-6">
                                      <h5 className="choosen-details">
                                        {service.name}
                                      </h5>
                                    </div>
                                    <div className="col-6">
                                      <h5 className="choosen-details text-right">
                                        {service.currency}{" "}
                                        {this.props.priceCalInput[
                                          `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                        ] * service.amount}
                                      </h5>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )
                              )
                            : ""
                      )
                    : ""}
                </div> */}

                <p className="overview-line1" />

                <div className="form-group">
                  <form onSubmit={this.props.checkPromoCode}>
                    <label className="medium-cls">Promo Code</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="promo_code"
                        placeholder="Promo code"
                        onChange={this.props.getPromoCode}
                        name="promo_code"
                        value={this.props.promoCodeInputData.promo_code}
                      />
                      <button type="submit" className="form-control">
                        Apply
                      </button>
                    </div>
                  </form>
                </div>

                {loadingPromoCode ? (
                  ""
                ) : (
                  <a
                    href="#"
                    onClick={this.props.removePromoCode}
                    className="remove-promo-btn"
                  >
                    Remove Promo
                    <i class="fas fa-chevron-right ml-2 vertical-align"></i>
                  </a>
                )}

                <p className="overview-line" />
                <div className="row">
                  <div className="col-6">
                    <h5 className="choosen-details">Total (CAD)</h5>
                  </div>
                  <div className="col-6 text-right">
                    <h5 className="choosen-details">
                      {apiCallStatus
                        ? apiResponse.actual_total_formatted
                        : this.props.total}
                    </h5>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          {basicDetails.pricing_type == 2 ? (
            <>
              NOTE:
              <p>
                Adult Price: ({basicDetails.adult_range}){" "}
                <span className="text-info">
                  {basicDetails.currency}
                  {basicDetails.base_price_adult}
                </span>
              </p>
              <p>
                Child Price: ({basicDetails.child_range}){" "}
                <span className="text-info">
                  {basicDetails.currency}
                  {basicDetails.base_price_child}
                </span>
              </p>
              <p>
                Infants Price: ({basicDetails.infants_range})
                <span className="text-info">
                  {basicDetails.currency}
                  {basicDetails.base_price_infants}
                </span>
              </p>
              <p>
                Special Needs Price: ({basicDetails.age_special_range})
                <span className="text-info">
                  {basicDetails.currency}
                  {basicDetails.base_price_age_special}
                </span>
              </p>
            </>
          ) : null}
          {status == "host" ? (
            ""
          ) : (
            <button
              className="pink-btn btn-block mb-2"
              onClick={this.props.bookingCreate}
            >
              {buttonLoadingContent == null ? "Book" : buttonLoadingContent}
            </button>
          )}
          <h5 className="small-text">you won’t be charged yet</h5>
          <p className="overview-line" />
          <div className="media top1">
            <div className="media-body">
              {/* <h4 className="home-info">This home is on people’s minds.</h4> */}
              <p className="home-info-text text-captilize">
                Pre-Book the space and enjoy your event!
              </p>
            </div>
            <img
              src={window.location.origin + "/assets/img/light.gif"}
              className="align-self-center ml-3 rounded-circle review-img1"
              alt="you won’t be charged yet"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(TripBookingForm);
