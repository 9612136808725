import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const FindListingsLoaders = (props) => {

    const { count } = props;

    const loaderCount = Array.from(Array(count).keys());

    return (
        <>
            <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
                <div className="site-content">
                    <Skeleton width={"30%"} height={40} style={{
                        marginBottom: '20px',
                    }}></Skeleton>
                    <div className='skeleton-card-loader'>
                        <div className='row'>
                            {loaderCount.map((loaders) => (
                                <>
                                    <div className='col-lg-4 col-md-4 col-xl-4'>
                                        <Skeleton width={"100%"} height={300} style={{
                                            marginBottom: '20px',
                                        }}>

                                        </Skeleton>
                                    </div>
                                </>
                            ))}

                        </div>

                    </div>
                </div>
            </SkeletonTheme>
        </>
    )
}

export default FindListingsLoaders;