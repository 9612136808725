import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";
import configuration from "react-global-configuration";

class TripHostSection extends Helper {
  state = {};
  render() {
    const { providerDetails, singleTripDetails } = this.props;
    // console.log(singleTripDetails.basic_details);
    return (
      <div id="host">
        <div className="media">
          <div className="media-body">
            <h1 className="section-head host-bottom">
              Hosted by {providerDetails.provider_name}
            </h1>
            <h4 className="host-text">
              {providerDetails.full_address} Joined in {providerDetails.joined}
            </h4>
            <h4 className="host-text mb-0">
              <span>
                {this.starRatingHost(singleTripDetails.basic_details.admin_ratings? singleTripDetails.basic_details.admin_ratings : 0)}
              </span>
            </h4>
            <h4 className="host-text mb-0">
              <span>{singleTripDetails.basic_details.admin_ratings} Ratings</span>
              &nbsp;&nbsp;
              {/* <span>
                                <i className="fas fa-male mr-05" />4 References
                            </span> */}
            </h4>
          </div>
          {localStorage.getItem("userLoginStatus") ? (
          <>
          <Link
            to={{
              pathname: "/single-trip/chat",
              state: {
                host_details: singleTripDetails.basic_details,
                provider_details: singleTripDetails.provider_details,
                pricing_details: singleTripDetails.pricing_details,
                page: "singletrip",
              },
            }}
            onClick={this.props.handleContactHost}
            className="green-outline-btn btn-small"
          >
            contact host
          </Link>
          {/* <p className="overview-line" /> */}
          </>
        ) : (
          <div>
              <a
                href="#"
                data-toggle="modal"
                data-target="#login"
              >
                <h4 className="green-outline-btn btn-small">contact host</h4>
              </a>
            </div>
        )}
          <Link
            to={`/provider-profile/${providerDetails.provider_id}`}
            target="_blank"
          >
            <img
              src={providerDetails.picture}
              alt={providerDetails.provider_name}
              className="rounded-circle review-img"
            />
          </Link>
        </div>
        <p className="overview-line" />

        <h4 className="host-text">{providerDetails.description}</h4>

        <div className="host-details">
          {providerDetails.language
            ? `
                        <h4>
                            Languages:
                            <span className="medium-cls">
                                {providerDetails.language}
                            </span>
                        </h4>
                        `
            : ""}
        </div>
        {/* {localStorage.getItem("userLoginStatus") ? (
          <>
          <Link
            to={{
              pathname: "/single-trip/chat",
              state: {
                host_details: singleTripDetails.basic_details,
                provider_details: singleTripDetails.provider_details,
                pricing_details: singleTripDetails.pricing_details,
                page: "singletrip",
              },
            }}
            onClick={this.props.handleContactHost}
            className="green-outline-btn btn-small"
          >
            contact host
          </Link>
          <p className="overview-line" />
          </>
        ) : (
          ""
        )} */}

        {singleTripDetails.basic_details.is_only_residence == 1 ? (
          <h4 className="host-text">
            <span className="medium-cls">NOTE</span>{" "}
            <span className="dot">
              <i className="fas fa-circle" />
            </span>{" "}
            This place is available for the residences.
          </h4>
        ) : null}
        {/* {singleTripDetails.cancellation_policy != "" ? (
          <>
            <p className="overview-line" />
            <h4 className="host-text">
              <span className="medium-cls">Cancellation Policy</span>
            </h4>
            <p>{singleTripDetails.cancellation_policy}</p>
          </>
        ) : null} */}
        {/* {singleTripDetails.other_cancellation_policies.length > 0 ? (
          <>
            {singleTripDetails.cancellation_policy == "" ? (
              <>
                <p className="overview-line" />

                <h4 className="host-text">
                  <span className="medium-cls">Cancellation Policy</span>
                </h4>
              </>
            ) : (
              ""
            )}
            <ul>
              {singleTripDetails.other_cancellation_policies.map(
                (other_house_rule) => (
                  <li className="p-05">{other_house_rule.content}</li>
                )
              )}
            </ul>
          </>
        ) : null} */}
        {/* {singleTripDetails.house_rules != "" ? (
          <>
            <p className="overview-line" />
            <h4 className="host-text">
              <span className="medium-cls">House Rules</span>
            </h4>
            <p>{singleTripDetails.house_rules}</p>
          </>
        ) : null}
        {singleTripDetails.other_house_rules.length > 0 ? (
          <>
            {singleTripDetails.house_rules == "" ? (
              <>
                <p className="overview-line" />

                <h4 className="host-text">
                  <span className="medium-cls">House Rules</span>
                </h4>
              </>
            ) : (
              ""
            )}
            <ul>
              {singleTripDetails.other_house_rules.map((other_house_rule) => (
                <li className="p-05">{other_house_rule.content}</li>
              ))}
            </ul>
          </>
        ) : null} */}
        
        <h4 className="host-text">
          <span className="medium-cls">
            Always communicate through{" "}
            {configuration.get("configData.site_name")}
          </span>{" "}
          <span className="dot">
            <i className="fas fa-circle" />
          </span>{" "}
          To protect your payment, never transfer money or communicate outside
          of the {configuration.get("configData.site_name")} website or app.
        </h4>
        <p className="overview-line" />
      </div>
    );
  }
}

export default TripHostSection;
