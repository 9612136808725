import React, { Component } from "react";
import Helper from "../../Helper/Helper";

class TripAddonServices extends Helper {

  state = {
    addonServicesData: null,
    loadingAddonService: true,
    priceCalInput: {},
    showMe: false,
  };
  handleClick = () => {
    this.setState({ showMe: !this.state.showMe });
    console.log(this.state.showMe);
  };

  componentDidMount() {
    this.props.getAddonServices();
  }

  render() {
    const {
      addonServicesData,
      loadingAddonService,
      priceCalInput,
    } = this.props;
    const { showMe } = this.props;
    return loadingAddonService ? (
      ""
    ) : (
      <React.Fragment>
        {addonServicesData.length > 0 ? (
          <>
            <p className="overview-line" />

            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <h4 className="single-cat-text medium-cls ">
                    Add on Services
                  </h4>
                  {/* <div className="addOnsCart">
                    <h4 className="single-cat-text mb-0 mr-2">Cart</h4>
                    <span class="badge badge-secondary">{priceCalInput.selectedAmentites}</span>
                  </div> */}
                </div>
                <div className="addons-box">
                  {addonServicesData.map((services) => (
                    <div
                      className="card-wrapper addon-space"
                      key={services.host_addon_service_id}
                    >
                      <input
                        className="c-card"
                        // type="checkbox"
                        id={services.host_addon_service_id}
                        value={services.min_value}
                        name={`host_addon_services[${services.host_addon_service_id}][no_of_units]`}
                        onClick={(event) =>
                          this.props.handleAddonService(event, services)
                        }
                        defaultChecked={
                          services.is_required == 1 ? true : false
                        }
                        disabled={services.is_required == 1 ? true : false}
                      />
                      {/* <div className="card-content"> */}

                      <div
                        className={
                          priceCalInput
                            ? priceCalInput[
                                `host_addon_services[${services.host_addon_service_id}][no_of_units]`
                              ] > 0
                              ? "card-content addon-active"
                              : "card-content"
                            : "card-content"
                        }
                      >
                          {priceCalInput ? (
                            priceCalInput[
                              `host_addon_services[${services.host_addon_service_id}][no_of_units]`
                            ] > 0 ? services.unit_type == "booking" ? (
                              <div className="card-state-icon">
                                 <i className="fas fa-check text-secondary"></i>
                              </div>
                            ) : (
                                <div className="personsCart">
                                    <img src={window.location.origin + "/assets/icons/trolley.png"} alt="" />
                                      <h1>{priceCalInput[
                                    `host_addon_services[${services.host_addon_service_id}][no_of_units]`
                                    ]}</h1>
                                  </div>
                            ) : ""
                          ) : (
                            ""
                          )}
                          {/* <div>
                            {services.is_required == 0 && priceCalInput ? (
                              priceCalInput[
                                `host_addon_services[${services.host_addon_service_id}][no_of_units]`
                              ] > 0 ? (
                                <div className="personsCart">
                                  <img src="../assets/icons/trolley.png" alt="" />
                                </div>
                              ) : "") : ""}
                          </div> */}
                        <label
                          className="margin-btn-zero"
                          for={services.host_addon_service_id}
                        >
                          <div className="card-image">
                            <img src={services.picture} />
                          </div>
                          <div className="addon-info">
                            <h5 className="card-title">{services.name}</h5>
                            <p className="small-meta">
                              {services.currency} {services.amount}/
                              {services.unit_type}
                            </p>
                            <p className="is-req">
                              {services.is_required == 1
                                ? "This add on service is required"
                                : ""}
                            </p>
                          </div>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default TripAddonServices;
