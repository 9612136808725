import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const queryString = require("query-string");

class LocationPage extends Component {
    state = {};
    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            adaptiveHeight: true,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    arrows: true,
                    slidesToShow: 3
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    arrows: true,
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    arrows: true,
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    arrows: false,
                    slidesToShow: 2
                  }
                }
              ]
        };
        const { length: count } = this.props.locations;
        const { locations } = this.props;

        if (count === 0) {
            return <p />;
        }

        const handleLocationRedirect = (location) => {

            const query = queryString.stringify(
                {locationId : [location.service_location_id] , selectedLocation : [location.service_location_name]},
                { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
              );
          

            location = {
                pathname : `/see_all/location/${location.service_location_id}/${location.service_location_name}`,
                state : { 
                    // type: locations.api_page_type,
                    // searchInput: { 
                    //     service_location_name : location.service_location_name,
                    //     service_location_id : location.service_location_id
                    // },
                    notMainSearch: true
                },
                search : query
            }
            return location;
        }

        return (
            <div>
                <div className="section-title">
                    <h1 className="section-head">{locations.title}</h1>
                </div>
                <section className="recom-slider slider location-slider">
                    <Slider {...settings} className="">
                        {locations.data.map(location => (
                            <div
                                className="recom-box-outer"
                                key={location.service_location_id}
                            >
                                <Link
                                    to={handleLocationRedirect(location)}
                                    // to={`/category/${location.service_location_name}/${location.api_page_type_id}/${locations.api_page_type}`}
                                    className="recom-box"
                                >
                                    <div className="recom-img">
                                        {/* <img
                                            src={
                                                location.service_location_picture
                                            }
                                            alt={location.service_location_name}
                                        /> */}
                                        <picture>
                                            <source className="lazyload" type="image/png" srcset={
                                                location.service_location_picture
                                            }/>
                                            <img data-src={
                                                location.service_location_picture
                                            } type="image/png" className="lazyload" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="recom-overlay" />
                                    <div className="recom-content">
                                        <h5>
                                            {location.service_location_name}
                                        </h5>
                                        <p>Packages from {location.amount_formatted}</p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                        {/* <div className="recom-box-outer">
                            <a href="#" className="recom-box">
                                <div className="recom-img">
                                    <img
                                        src={
                                            window.location.origin +
                                            "assets/img/recom1.webp"
                                        }
                                    />
                                </div>
                                <div className="recom-overlay" />
                                <div className="recom-content">
                                    <h5>Paris</h5>
                                    <p>$100/ Night Average</p>
                                </div>
                            </a>
                        </div> */}
                    </Slider>
                </section>
            </div>
        );
    }
}

export default LocationPage;
