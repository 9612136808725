import React, { Component } from "react";
import dayjs from "dayjs";
import { Link, Redirect } from "react-router-dom";
import ProfileInput from "../../Helper/profileInput";
import Form from "../../Helper/form";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";
import Select from 'react-select'
const $ = window.$;

class HostAddFrontDesk extends HostHelper {
  constructor(props) {
    super(props);

    // States and props usage
  }

  state = {
    data: {},
    loading: true,
    error: null,
    loginStatus: true,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false,
    loadingUsers: true,
    frontdesk_hosts:null,
    value: ['']
  };


  renderAutoComplete = () => {
    const { google } = this.props;
    if (!google) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place);
      if (!place.geometry) return;
      this.setState({ position: place.geometry.location });
      const data = { ...this.state.data };
      data["latitude"] = place.geometry.location.lat();
      data["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      data["full_address"] = full_address;

      this.setState({ data });
    });
  };
  

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });

    api.postMethod("host_front_desks_save", this.state.data).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
        this.props.history.push("/host/frontdesk-users");
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  handlePictureChange = ({ currentTarget: input }) => {
    
    const data = { ...this.state.data };
    if (input.type === "file") {
      console.log("input",input.files[0]);
      this.setState({...this.state,data:{...this.state.data,picture:input.files[0]}});
    }

    let reader = new FileReader();
    let file = input.files[0];
    if (file) {
      reader.readAsDataURL(file);
    }

  };

  handleChangeHost = event => {
    
    let host_ids = [];

    $(event).each(function(index,value){
        host_ids.push(value.value);
    });
    console.log("host_ids",host_ids);

    this.setState({...this.state , data : { ...this.state.data ,hosts:host_ids}});
  };

  componentDidMount() {
    this.getProviderHostsListApiCall();
  }
  getProviderHostsListApiCall = () => {
   
    api
      .postMethod("search_hosts")
      .then((response) => {
        if (response.data.success) {
          
          this.setState({
            frontdesk_hosts: response.data.data.host_list,
            loadingUsers: false,
          });
          console.log("hosts list",response.data.data.host_list);
        } 
      });

     
  };

  


  render() {
    const { data, loading, error, loginStatus,frontdesk_hosts } = this.state;

    const options = frontdesk_hosts;


    if (!loginStatus) {
      return (
        <Redirect
          to={{
            pathname: "/host/login",
            state: { error: error }
          }}
        />
      );
    }
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                <form onSubmit={this.handleSubmit}>
                  <div className="panel">
                    <div className="panel-heading">required
                         <Link className="float-right" to={"/host/frontdesk-users"}>
                          <i className="fas fa-chevron-left" /> Back
                        </Link>
                    </div>

                    <div className="panel-body  account">
                      <ProfileInput
                        label="Name"
                        type="text"
                        placeholder=""
                        id="name"
                        name="name"
                        value={data.name}
                        onChange={this.handleChange}
                        description=""
                      />

                      <ProfileInput
                        label="Email"
                        type="email"
                        placeholder="email"
                        id="email"
                        name="email"
                        value={data.email}
                        onChange={this.handleChange}
                        description=""
                        autoComplete="off"
                      />


                      <ProfileInput
                        label="Mobile"
                        type="number"
                        placeholder=""
                        id="mobile"
                        name="mobile"
                        value={data.mobile}
                        onChange={this.handleChange}
                        description=""
                      />


                        <ProfileInput
                            label="Password"
                            type="password"
                            placeholder=""
                            id="password"
                            name="password"
                            value={data.password}
                            onChange={this.handleChange}
                            description=""
                        />


                  <div className="form-group row">
                            <div className="col-3 text-right">
                              <label>Select Host</label>
                            </div>
                            <div className="col-9">
                            <Select name="host_front_desk" options={options}
                             onChange={this.handleChangeHost}
                             
                             isMulti
                            />
                            </div>
                    </div>


                    <ProfileInput
                       label="Picture"
                        type="file"
                        name="picture"
                        className="grey-outline-btn bold-cls w-100 text-center bottom"
                        onChange={this.handlePictureChange}
                    />
                   


                   <div className="form-group row">
                            <div className="col-3 text-right">
                                 <label>Full Address</label>
                              </div>
                              <div className="col-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="House name/number + street/road"
                                  onFocus={this.renderAutoComplete}
                                  ref={(ref) => (this.autocomplete = ref)}
                                  name="full_address"
                                  value={data["full_address"]}
                                  onChange={this.handleChange}
                                  defaultValue={data.full_address}
                                />
                           </div>    
                  </div> 

                     

                      <div className="form-group row">
                        <div className="col-3 text-right">
                          <label>Description</label>
                        </div>
                        <div className="col-9">
                          <textarea
                            type="text"
                            className="form-control"
                            rows="4"
                            name="description"
                            value={data.description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-9 offset-3 text-center">
                          <button
                            className="pink-btn btn-block"
                            disabled={this.state.buttonDisable}
                          >
                            {this.state.loadingContent != null
                              ? this.state.loadingContent
                              : "save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostAddFrontDesk);
