import React, { Component } from "react";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";

class AddListingStep4 extends Component {
  state = {};
  render() {
    const {
      secondApiResponse,
      handleChange,
      renderAutoComplete,
      handleAmentiesChange,
      google,
      position,
      autocomplete,
      formData,
    } = this.props;
    return (
      <div id="step-4" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{secondApiResponse[0].title}</h4>
              <p className="main-txt">{secondApiResponse[0].description}</p>
              <div className="flow-content">
                <div className="form-group large-form-group">
                  <label className="light-txt">
                    {secondApiResponse[0].data[0].question}
                  </label>
                  <select
                    className="form-control"
                    name={secondApiResponse[0].data[0].server_key}
                    value={formData.service_location_id}
                    onChange={handleChange}
                  >
                    <option>Select Service Location</option>
                    {secondApiResponse[0].data[0].answer.map((ans) => (
                      <option key={ans.key} value={ans.key}>
                        {ans.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group large-form-group">
                  <label className="light-txt">Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="House name/number + street/road"
                    onFocus={renderAutoComplete}
                    ref={(ref) => (autocomplete = ref)}
                    name={secondApiResponse[0].data[1].server_key}
                    value={formData["full_address"]}
                    onChange={handleAmentiesChange}
                  />
                  <Map
                    className="map"
                    google={google}
                    visible={true}
                    center={position}
                  >
                    <Marker position={position} />
                  </Map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep4;
