import React, { Component } from "react";
import HostFooter from "./hostFooter";
import HostAuthHeader from "./hostAuthHeader";
import HostLoginModal from "../Auth/HostLoginModal";

// This Layouts used in Home page Header,  static footer and content

class HostAuthLayout extends Component {
  constructor(props) {
    super(props);

    // define states

    this.state = {
      isValidAuth : false
    }

  }

  toggleLoginModal = (state) => {
    this.setState({
        ...this.state,
        isValidAuth : state
    })
}

  render() {
    return (
      <div className="wrapper">
        {this.state.isValidAuth && (
            <HostLoginModal dashboard={true}/>
        )} 
        <HostAuthHeader {...this.props.children.props} />
        <div>{React.cloneElement(this.props.children ,  {isValidAuth : this.state.isValidAuth , toggleLoginModal :this.toggleLoginModal})}</div>
        <HostFooter />
      </div>
    );
  }
}
export default HostAuthLayout;
