import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../../HostEnvironment";
import ToastDemo from "../../Helper/toaster";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";

class HostForgotPassword extends HostHelper {
  
  state = {
    data: {},
    loadingContent: null,
    buttonDisable: false,
  }; 

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("forgot_password", this.state.data)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data);
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });

        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({ loadingContent: null, buttonDisable: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null });
      });
  };

  render() {
    return (
      <div className="page-content">
        <div className="prov-login">
          <div className="log-head">
            <h4>Reset Password</h4>
            <p>
              Enter the email address associated with your account, and we’ll
              email you a link to reset your password.
            </p>
          </div>
          <form className="top1 prov-login-form">
            <div className="form-group input-group">
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="email address"
                onChange={this.handleChange}
              />
            </div>
            <button
              className="pink-btn bottom1"
              disabled={this.state.buttonDisable}
              onClick={this.handleSubmit}
            >
              {this.state.loadingContent != null
                  ? this.state.loadingContent
                  : "Send reset link"}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostForgotPassword);
