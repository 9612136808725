import React from "react";
import { useState, useEffect } from "react";

const CustomSelect = (props) => {
  const [showOptions, setShowOptions] = useState(false);

  const [selectedOption, setSelectedOption] = useState(props.defaultSelect);

  const handleToggleOptions = (status) => {
    setShowOptions(status);
  };

  const handleSelecetedOption = (value) => {
    setSelectedOption(value);
    setShowOptions(false);
  };

  return (
    <>
      <div className="w-100 custom-select-wrapper">
        <div
          className={`custom-select-alike ${showOptions ? "open" : ""}`}
          onClick={() => handleToggleOptions(true)}
        >
          <p className="placeholder">
            {selectedOption != null
              ? selectedOption
              : props.watcher.custom_select_value != null
              ? props.watcher.custom_select_value
              : props.placeHolder}
          </p>
        </div>
        {showOptions && (
          <>
            <div className="options-wrapper">
              <div
                className="options-backdrop"
                onClick={() => handleToggleOptions(false)}
              ></div>
              <div className="options">
                {props.data.map((options, index) => (
                  <p
                    key={index}
                    className={options.status == 0 ? "disabled" : ""}
                    onClick={
                      options.status == 0
                        ? null
                        : () => {
                            props.handleChange(options.time_slot_id);
                            handleSelecetedOption(
                              `${options.name} - ${options.start_time_formatted} - ${options.end_time_formatted}`
                            );
                          }
                    }
                  >
                    {options.name} {options.start_time_formatted} -{" "}
                    {options.end_time_formatted}
                  </p>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CustomSelect;
