import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import configuration from "react-global-configuration";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";

class TripGallery extends Component {
  constructor(props) {
    super(props);
  }
  
  state = {};
  render() {
    const { details,isModalOpen } = this.props;
    return (
      <Modal
        centered
        size="lg"
        className="modal-dialog-center sent-tip-modal"
        show={isModalOpen}
        onHide={this.props.handleGalleryModal}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="subscription-tip-ppv-tab">
          <Tab.Container id="left-tabs-example">
            <div id="" className="fullscreen">
              <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              >
              {details.gallery.map(image => (
                  <div key={image.picture}>
                  <img
                      srcSet={image.picture}
                      src={image.picture}
                      alt={image.picture}
                      className="homes-img"
                  />
                  {image.caption ? 
                    <p className="legend">{image.caption}</p>
                  : ''}
                  </div>
              ))}
              </Carousel>
          </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    );
  }
}

export default TripGallery;
