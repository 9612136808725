import React, { Component } from "react";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon
  } from "react-share";
import { data } from "jquery";


class HomeSocialShare extends Component {
  state = {};
  render() {

    const { details } = this.props;

    return (
      <div className="modal fade" id="social-share">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            id="close-login"
            data-dismiss="modal"
          >
            <i className="fa fa-close">close</i>
          </button>
        </div>

        <div className="modal-dialog modal-dialog-centered share-mdl">
          <div className="modal-content share-mdl-content">
            <div className="modal-body">
              <div id="" className="w-100">

              <div className="scl-flex">
                    <div className="social-icon">
                        <div className="text-center social-link">
                          <WhatsappShareButton
                            url={details.share_link}
                            title="Check this listing on Party Budha using this link"
                            separator=":: "
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        </div>
                      </div>

                      <div className="social-icon">
                        <div className="text-center social-link">
                          <TwitterShareButton
                            url={details.share_link}
                            title="Check this listing on Party Budha using this link"
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                        </div>
                      </div>

                      <div className="social-icon">
                        <div className="text-center social-link">
                          <FacebookShareButton
                            url={details.share_link}
                            title="Check this listing on Party Budha using this link"
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                        </div>
                      </div>

                      <div className="social-icon">
                        <div className="text-center social-link">
                          <LinkedinShareButton
                            url={details.share_link}
                            title="Check this listing on Party Budha using this link"
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                        </div>
                      </div>


                    </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeSocialShare;
