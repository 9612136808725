import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
class EditListingStep1 extends Component {
  state = {
    loading: true,
    category_id: null,
    subCategoryData: null,
    subcategoryLoading: true,
    sub_category_id: null,
    submitClicked: false,
    loadingContent: null,
    hostData: null,
    hostLoading: true,
    categoryData: null,
    formData: {
      sub_category_id: "",
      host_type: "",
      total_guests: 0,
      min_guests: null,
      max_guests: null,
      total_bedrooms: 0,
      total_beds: 0,
      total_bathrooms: "",
      bathroom_type: "",
      service_location_id: 0,
      latitude: "",
      longitude: "",
      adult_from: 16,
      adult_to: 100,
      child_from: 2,
      child_to: 15,
      infants_from: 0,
      infants_to: 1,
    },
  };

  componentDidMount() {
    this.initialSteps();
  }

  initialSteps = () => {
    api
      .postMethod("hosts_step_1", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            hostLoading: false,
            hostData: response.data.data,
            formData: {
              host_id: response.data.data.host.host_id,
              host_type: response.data.data.host.host_type,
              category_id: response.data.data.host.category_id,
              sub_category_id: response.data.data.host.sub_category_id,
            },
          });
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
    api
      .postMethod("hosts_categories", {
        host_id: this.props.match.params.host_id,
      })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            categoryData: response.data.data.categories,
            subcategoryLoading: false,
            subCategoryData: response.data.data.sub_categories,
          });
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  categoryChange = ({ currentTarget: input }) => {
    this.setState({ category_id: input.value });
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
    api
      .postMethod("hosts_sub_categories", { category_id: input.value })
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.length == 0) {
            ToastDemo(
              this.props.toastManager,
              "there is no sub-category in this category. Please choose other",
              "error"
            );
          }
          this.setState({
            subcategoryLoading: false,
            subCategoryData: response.data.data,
          });
        }else{
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
        }
      });
  };
  handleChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
    this.setState({ sub_category_id: input.value });
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    api.postMethod("hosts_step1_save", this.state.formData).then((response) => {
      if (response.data.success) {
        this.props.history.push(
          `/host/listing-step2/${this.props.match.params.host_id}`
        );
      } else {
        alert(response.data.error);
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
        this.setState({
          loadingContent: null,
          submitClicked: false,
        });
      }
    });
  };

  render() {
    const {
      categoryChange,
      category_id,
      categoryData,
      handleChange,
      subcategoryLoading,
      subCategoryData,
      formData,
      hostData,
      submitClicked,
    } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 1: Start with the basics
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          {/* <div className="add-flow"> */}
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  <div id="step-1" className="flow-tab">
                    {this.state.loading ? (
                      "Loading"
                    ) : (
                      <div className="add-flow-block row add-listing-left-content">
                        <div className="col-md-12">
                          <div className="add-flow-block-left">
                            <h4 className="flow-tit">
                              Tell Us About Your Place
                            </h4>
                            <div className="flow-content">
                              <div className="flow-box">
                                <div className="form-group">
                                  <label>
                                    First, let's select the category for your
                                    listing
                                  </label>
                                  <select
                                    className="form-control mw-300"
                                    name="category_id"
                                    onChange={this.categoryChange}
                                    value={category_id}
                                  >
                                    <option>Select One</option>
                                    {categoryData.map((category) => (
                                      <option
                                        key={category.category_id}
                                        value={category.category_id}
                                        selected={
                                          category.is_checked ? true : false
                                        }
                                      >
                                        {
                                          category.category_provider_display_name
                                        }
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label>
                                    Let's select the primary property type, the
                                    selected value will be displayed below your
                                    property thumbnail image in the listing
                                    page.
                                  </label>
                                  <select
                                    className="form-control mw-300"
                                    name="sub_category_id"
                                    onChange={this.handleChange}
                                    // value={formData.sub_category_id}
                                  >
                                    <option>Select One</option>
                                    {subcategoryLoading
                                      ? ""
                                      : subCategoryData.map((subcategory) => (
                                          <option
                                            key={subcategory.sub_category_id}
                                            value={subcategory.sub_category_id}
                                            selected={
                                              subcategory.is_checked
                                                ? true
                                                : false
                                            }
                                          >
                                            {
                                              subcategory.sub_category_user_display_name
                                            }
                                          </option>
                                        ))}
                                  </select>
                                </div>
                              </div>
                              <div className="flow-box">
                                <h5
                                  className="flow-box-tit"
                                  style={{ color: "#484848", fontSize: "20px" }}
                                >
                                  What will guests book
                                </h5>
                                {this.state.hostLoading
                                  ? ""
                                  : this.state.hostData.host_types.map(
                                      (host_type_details) => (
                                        <div
                                          className="form-check add-list-block"
                                          key={host_type_details.value}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="host_type"
                                            id={host_type_details.value}
                                            value={host_type_details.value}
                                            checked={
                                              formData["host_type"] ==
                                              host_type_details.value
                                                ? true
                                                : false
                                            }
                                            defaultChecked={
                                              host_type_details.is_selected
                                                ? true
                                                : false
                                            }
                                            onChange={this.handleChange}
                                          />
                                          <label
                                            className="form-check-label row padding-left-step-1"
                                            htmlFor={host_type_details.value}
                                          >
                                            <p
                                              className="flow-check-txt text-dark"
                                              style={{ fontSize: "16px" }}
                                            >
                                              <b>{host_type_details.value}</b>
                                            </p>
                                            <h5 className="flow-check-tit text-muted">
                                              {host_type_details.description}
                                            </h5>
                                          </label>
                                          <div className="clear-both" />
                                        </div>
                                      )
                                    )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="listing-footer">
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={submitClicked}
                    onClick={this.submitForm}
                  >
                    Save & Next
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withToastManager(EditListingStep1);
