import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/Helper";
import SearchForm from "../Helper/searchForm";
import api from "../../Environment";
import { render } from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
// import Typed from 'react-typed';
import ToastDemo from "../Helper/toaster";


var yesterday = moment().subtract( 1, 'day' );
var valid = function( current ){
    return current.isAfter( yesterday );
};

class NewHomeBanner extends Helper {

  state = {
    categories: null,
    loadingCategory: true,
    searchDisplay: true,
    loadingSearch: true,
    categories: null,
    location_id: null,
    searchInput: {},
    sub_categories: null,
  };

  searchOnChange = ({ currentTarget: input }) => {
    this.setState({ searchDisplay: false });
    if (input.value == "") {
      this.setState({ searchDisplay: true });
    }
    // api
    // .postMethod("filter_locations_hosts", { location: input.value })
    // .then((response) => {
    //   if (response.data.success) {
    //     this.setState({
    //       searchResult: response.data.data.service_locations,
    //       hosts: response.data.data.hosts,
    //       loadingSearch: false,
    //     });
    //   }
    // });
    api
    .postMethod("filter_categories", { search_key: input.value })
    .then((response) => {
      if (response.data.success) {
        this.setState({
          categories: response.data.data.categories,
          sub_categories: response.data.data.sub_categories,
          loadingSearch: false,
        });
      }
    });
  };

  searchResult = (event) => {
    event.preventDefault();
    let location_id;
    if (this.state.searchResult.length > 0) {
      location_id = this.state.searchResult[0].service_location_id;
    } else {
      location_id = "";
    }
    this.setState({ searchDisplay: true });
    this.searchApiCall(this.props.props, location_id);
  };

  searchOnClickResult = (event, search) => {
    event.preventDefault();
    this.setState({ searchDisplay: true });

    this.searchApiCall(this.props.props, search.service_location_id);
  };

  handleChangeLocation = ({ currentTarget: input }) => {
    this.setState({ location_id: input.value });
  };
  submitSearch = (event) => {
    event.preventDefault();
    this.handleSubmit();
  }
  
  render() {
    const {
      loadingCategory,
      searchDisplay,
      categories,
      loadingSearch,
      sub_categories,
    } = this.state;
    const {
      loadingEvent,
      eventData,
      loadingLocation,
      locationData,
      getSearchInput,
      searchButtonDisable,
      searchLoadingContent,
      submitSearch,
      selectEvent,
      searchInput,
    } = this.props;
    let inputProps = {
      placeholder: 'Date',
    };
    var location = {}
    const handleCategoryRedirect = (category) => {
      location = {
        pathname : `/see_all/${category.name}/${category.api_page_type_id}/${categories.api_page_type}`,
        state : { 
          type:  categories.api_page_type,
          searchInput: { 
            cat_title : category.name,
            cat_id : category.category_id
          },
          notMainSearch: true,
        }
      }
      return location;
    }

    const handleSubCategory = (sub_category) => {
      location = {
        pathname : `/see_all/${sub_category.name}/${sub_category.api_page_type_id}/${sub_categories.api_page_type}`,
        state : { 
          type:  sub_categories.api_page_type,
          searchInput: { 
            sub_cat_title : sub_category.name,
            sub_cat_id : sub_category.category_id
          },
          notMainSearch: true,
        }
      }
      return location;
    }

    const handleSubmit = () => {
      location = {
        pathname : `/see_all`,
        state : { 
          searchInput: searchInput,
          notMainSearch: true,
        }
      }
      return location;
    }

    return (
      <div className="banner-slider">
        <div className="position-rel">
          {/* <div className="flexslider">
            <ul className="slides">
              <div
                className="bgimg"
                style={{
                  backgroundImage: `url('../../../assets/img/banner.jpg')`,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  backgroundSize: "cover",
                }}
              />
              <li>
                <div
                  className="bgimg"
                  style={{
                    backgroundImage: `url('../../../assets/img/banner1.jpg')`
                  }}
                />
              </li>
            </ul>
          </div> */}
          <Carousel 
          showArrows={false} 
          showThumbs={false} 
          autoPlay={true} 
          infiniteLoop={true}
          interval={4550}
          stopOnHover={false}
          showStatus={false}
          showIndicators={false}
          // autoPlaySpeed={5000}
          >
                <div className="carousel-item-list">
                  <picture>
                      <source className="lazyload img-dimension" type="image/jpg" srcset="../assets/img/user-landing-bg.jpg"/>
                      <img data-src="../assets/img/user-landing-bg.jpg" type="image/jpg" className="lazyload img-dimension" alt="First slide"/>
                  </picture>
                  {/* <img className="img-dimension" src="../assets/img/user-landing-bg.jpg" alt="First slide"/> */}
                  {/* <div className="banner-text">
                    <h1 className="change-text clr-blue">great party ideas!</h1>
                  </div> */}
                </div>
                {/* <div className="carousel-item-list">
                  <img className="img-dimension" src="../assets/img/banner-2.jpg" alt="Second slide"/>
                  <div className="banner-text">
                    <h1 className="change-text clr-yellow">dream wedding destinations!</h1>
                  </div>
                </div>
                <div className="carousel-item-list">
                  <img className="img-dimension" src="../assets/img/banner-3.jpg" alt="Third slide"/>
                  <div className="banner-text">
                    <h1 className="change-text clr-orange">perfect venues!</h1>
                  </div>
                </div>
                <div className="carousel-item-list">
                  <img className="img-dimension" src="../assets/img/banner-4.jpg" alt="Third slide"/>
                  <div className="banner-text">
                    <h1 className="change-text clr-orange">social spaces!</h1>
                  </div>
                </div> */}
            </Carousel>

          <div className="banner-overlay">
            <div className="banner-text-space">
              <div>
                <h1>
                  Looking for {/*<span className="op-reduce">Party</span>{" "}*/}
                  {/* <Typed
                    strings={[
                      'great party ideas!',
                      'dream wedding destinations!',
                      'perfect venues!',
                      'social spaces!']}
                    typeSpeed={80}
                    backSpeed={65}
                    loop
                    className="colorchange"
                  /> */}
                  great party ideas in <span>Calgary!</span>
                </h1>
                  <h1>Ask the Budha</h1>
              </div>
              <form>
                <div className="search-bar">
                  <div className="row">
                    <div className="col-md-12">
                      <form className="search-form">
                        <div className="input-group dropdown">
                          <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon">
                              <i className="fas fa-search" />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control form-control-1 dropdown-toggle"
                            data-toggle="dropdown"
                            placeholder="What are you planning?"
                            name="search"
                            autoComplete="off"
                            value={searchInput.event_title}
                            onChange={this.searchOnChange}
                          />

                          <div className="dropdown-menu banner-dropdown-menu">
                            <h5 className="dropdown-title">Explore </h5>

                            {loadingEvent
                              ? "Loading..."
                              : eventData.length > 0
                              ? eventData.map((eventDetail) => (
                                  <Link
                                    to={"#"}
                                    key={eventDetail.event_id}
                                    className="grey-outline-btn size1"
                                    value={eventDetail.event_id}
                                    onClick={(event) =>
                                      selectEvent(event, eventDetail)
                                    }
                                  >
                                    {eventDetail.title}
                                  </Link>
                                ))
                              : "No data found"}
                          </div>
                          <div
                            className={
                              searchDisplay
                                ? "dropdown-menu banner-dropdown-menu home-banner-search-list"
                                : "dropdown-menu banner-dropdown-menu home-banner-search-list show"
                            }
                          >
                            <ul className="map-search-list">
                              {loadingSearch
                                ? "Loading..."
                                : categories.data.length > 0
                                ? categories.data.map((category) => (
                                    <li key={category.id}>
                                      <Link
                                        to={handleCategoryRedirect(category)}
                                        className="map-search-list-link"
                                      >
                                        <i class="fab fa-buffer"></i>{" "}
                                        {category.name}
                                      </Link>
                                    </li>
                                  ))
                                : sub_categories.data.length > 0
                                ? sub_categories.data.map((sub_categorie) => (
                                    <li key={sub_categorie.unique_id}>
                                      <Link
                                        to={handleSubCategory(sub_categorie)}
                                        className="map-search-list-link"
                                      >
                                        <i class="fab fa-buffer"></i>{" "}
                                        {sub_categorie.name}
                                      </Link>
                                    </li>
                                  ))
                              : "No Data Found"}
                            </ul>
                          </div>
                        </div>
                        {/*<label
                          className="mr-sm-2 sr-only"
                          for="inlineFormCustomSelect"
                        >
                          What are you planning?
                        </label>
                        <select
                          className="custom-select form-control border-right border-radius-search resp-mrg-btm-xs"
                          id="inlineFormCustomSelect"
                          onChange={getSearchInput}
                          name="event_id"
                        >
                          <option selected>What are you planning?</option>
                          {loadingEvent
                            ? "Loading..."
                            : eventData.length > 0
                            ? eventData.map((eventDetail) => (
                                <option
                                  key={eventDetail.event_id}
                                  value={eventDetail.event_id}
                                >
                                  {eventDetail.title}
                                </option>
                              ))
                            : "No data found"}
                            </select>*/}
                        <label
                          className="mr-sm-2 sr-only"
                          for="inlineFormCustomSelect"
                        >
                          Location
                        </label>
                        <select
                          className="custom-select form-control border-right border-radius-search-1 resp-mrg-btm-xs"
                          id="inlineFormCustomSelect"
                          onChange={getSearchInput}
                          name="service_location_id"
                          value={searchInput.service_location_id}
                        >
                          <option selected>Location</option>
                          {loadingLocation
                            ? "Loading..."
                            : locationData.length > 0
                            ? locationData.map((location) => (
                                <option
                                  key={location.service_location_id}
                                  value={location.service_location_id}
                                >
                                  {location.service_location_name}
                                </option>
                              ))
                            : "No data found"}
                        </select>
                        <div className="input-group width-half home-search-date">
                          <Datetime
                              onChange={this.props.dateChange}
                              isValidDate={ valid }
                              dateFormat="DD-MM-YYYY"
                              inputProps={ inputProps }
                              timeFormat={false} 
                              closeOnSelect={true}
                          /> 
                        </div>
                        <Link
                          to={handleSubmit()}
                          className="btn search-btn"
                        >
                          Search
                        </Link>
                        {/* <button
                          className="btn search-btn"
                          disabled={searchButtonDisable}
                          onClick={this.submitSearch}
                        >
                          {searchLoadingContent != null
                            ? searchLoadingContent
                            : "Search"}
                        </button> */}
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewHomeBanner;
