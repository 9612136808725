import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
const $ = window.$;

class EditListingStep2 extends Component {
  state = {
    loading: true,
    data: null,
    formData: {
      host_id:this.props.match.params.host_id
    },
    loadingContent: null,
    submitClicked: false,
  };

  componentDidMount() {
    this.step2ApiCall();
  }

  handle2Change = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    console.log(formData);
    if (input.type === "checkbox") {
      if (input.checked) {
        if (formData[input.name] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          formData[input.name] = array;
          this.setState({ formData });
        } else {
          const value = parseInt(input.value);
          formData[input.name].push(value);
          this.setState({ formData });
        }
      } else {
        const value = parseInt(input.value);
        console.log(value);
        console.log(formData[input.name]);
        let index = formData[input.name].indexOf(value);
        console.log(index);
        if (index !== -1) {
          formData[input.name].splice(index, 1);
          this.setState({ formData });
        }
      }
    }
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step1/${this.props.match.params.host_id}`
    );
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    api.postMethod("hosts_step2_save", this.state.formData).then((response) => {
      if (response.data.success) {
        this.props.history.push(
          `/host/listing-step3/${this.props.match.params.host_id}`
        );
      } else {
        alert(response.data.error);
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
        this.setState({
          loadingContent: null,
          submitClicked: false,
        });
      }
    });
  };

  step2ApiCall = () => {
    api
      .postMethod("hosts_step_2", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {

          let event_id = [];

          $(response.data.data.host.event_id).each(function(index,value){
            const id = parseInt(value);
            event_id.push(id);
          });

          this.setState({
            loading: false,
            data: response.data.data,
            formData: {
              host_id: response.data.data.host.host_id,
              event_id: event_id,
            },
          });
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 2: Update the events information
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          {/* <div className="add-flow"> */}
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  {/* Step code start */}
                  <div id="step-2" className="flow-tab">
                    <div className="add-flow-block row add-listing-left-content">
                      <div className="col-md-12">
                        <div className="add-flow-block-left">
                          <h4 className="flow-tit">
                            Events allowed in your property
                          </h4>
                          {loading ? (
                            "Loading..."
                          ) : (
                            <>
                              <p className="main-txt">{data.host.title}</p>
                              <div className="flow-content">
                                <div className="flow-box">
                                  <h5 className="flow-box-tit">
                                    {data.host.description}
                                  </h5>
                                  {data.host_events.map((details) => (
                                    <>
                                      <div
                                        className="form-group form-check tick-check"
                                        key={`event_id` + details.event_id}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="event_id"
                                          id={`event_id` + details.event_id}
                                          value={details.event_id}
                                          onChange={this.handle2Change}
                                          defaultChecked={
                                            details.is_selected != 0
                                              ? true
                                              : false
                                          }
                                        />
                                        <div>
                                          <label
                                            className="form-check-label"
                                            htmlFor={
                                              `event_id` + details.event_id
                                            }
                                          >
                                            <div>
                                              <h5 className="amen-tit">
                                                <b>{details.title}</b>
                                              </h5>
                                            </div>
                                          </label>
                                          <p className="flow-check-txt text-muted">
                                            {details.description}
                                          </p>
                                      </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="listing-footer">
                  <button
                    className="white-btn"
                    type="button"
                    onClick={this.handleBackButton}
                  >
                    <i className="fas fa-chevron-left" /> Back
                  </button>
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={this.state.submitClicked}
                    onClick={this.submitForm}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : "Save & Next"}
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(EditListingStep2);
