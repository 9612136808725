import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
//import { url } from "inspector";

class ShowAll extends Component {
  render() {
    const { eachData } = this.props;

    var location = {}

    const handleEventRedirect = (eventDetail) => {
        location = {
            pathname : `/see_all/${eventDetail.title}/${eventDetail.api_page_type_id}/${eventDetail.api_page_type}`,
            state : { 
                type: eventDetail.api_page_type,
                notMainSearch: true
            }
        }
        return location;
    }

    return (
        <Link
            to={handleEventRedirect(eachData)}
            // to={`/see_all/${eachData.title}/${eachData.api_page_type_id}/${eachData.api_page_type}`}
            className="show-all"
        >
            show all <i className="fas fa-chevron-right" />
        </Link>
    );
  }
}

export default ShowAll;
