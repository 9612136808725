import React, { Component } from "react";

class AddListingStep7 extends Component {
  state = {};
  render() {
    const {
      fifthApiResponse,
      handleAmentiesChange,
      formData,
      displayTimeslot,
      displayDailySlot,
    } = this.props;
    return (
      <div id="step-9" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{fifthApiResponse[1].title}</h4>
              <p className="main-txt"></p>
              <div className="flow-content">
                <div className="flow-box">
                  <h5 className="flow-box-tit"></h5>
                  <div
                    className="form-check add-list-block"
                    key="daily_booking"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name={fifthApiResponse[1].data[0].server_key}
                      value={1}
                      onChange={handleAmentiesChange}
                      id="daily_booking"
                    />
                    <label
                      className="form-check-label row"
                      htmlFor="daily_booking"
                    >
                      <h5 className="flow-check-tit">Daily Booking</h5>
                      <p className="flow-check-txt text-muted">
                        Allow only one booking per day, ideal for banquet halls
                        etc.
                      </p>
                    </label>
                    <div className="clear-both" />
                  </div>
                  <div
                    className="form-check add-list-block"
                    key="time_slot_booking"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name={fifthApiResponse[1].data[0].server_key}
                      value={2}
                      onChange={handleAmentiesChange}
                      id="time_slot_booking"
                    />
                    <label
                      className="form-check-label row"
                      htmlFor="time_slot_booking"
                    >
                      <h5 className="flow-check-tit">
                        Time slot based booking
                      </h5>
                      <p className="flow-check-txt text-muted">
                        Allow multiple time slots per day, ideal for indoor play
                        center, community halls etc.
                      </p>
                    </label>
                    <div className="clear-both" />
                  </div>
                </div>
              </div>
              {displayTimeslot ? (
                <div>
                  Update your prefered time slots from the listings page later
                </div>
              ) : null}
            </div>

            {displayDailySlot ? (
              <div className="flow-content">
                {fifthApiResponse[0].data.map((details) => (
                  <div className="form-group large-form-group">
                    <label className="light-txt">{details.question}</label>
                    <select
                      className="form-control"
                      name={details.server_key}
                      value={formData[details.server_key]}
                      onChange={handleAmentiesChange}
                    >
                      <option>Select {details.question}</option>
                      {details.answer.map((ans) => (
                        <option key={ans.key} value={ans.key}>
                          {ans.value}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep7;
