import React, { Component } from "react";
import Slider from "react-slick";
import api from "../../../Environment";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";

class TripSimilarListing extends Helper {
  state = {
    suggestions: {},
    loading: true,
  };
  componentDidMount() {
    api
      .postMethod("suggestions", { host_id: this.props.host_id })
      .then((response) => {
        this.setState({
          suggestions: response.data.data,
          loading: false,
        });
      });
  }
  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    const { loading, suggestions } = this.state;
    return (
      <div className="subcategory-leftsec similar-listing-sec">
        <div className="section-title">
          <h1 className="section-head">Similar listing</h1>
        </div>
            <div className="row resp-grid-row">
          {loading
            ? ""
            : suggestions.length > 0
            ? suggestions.map((data) => (
                <div
                  className="col-md-6 col-xl-3 col-lg-4 resp-grid-col"
                  key={data.host_id}
                >
                  <div className="similar-listing-card">
                    <div className="relative">
                      <section className="home-slider slider">
                        <Carousel
                          showThumbs={false}
                          infiniteLoop={true}
                          showStatus={false}
                        >
                          {data.gallery.map((image) => (
                            <div key={image.picture} className="homes-img-sec1">
                              <img
                                srcSet={image.picture}
                                src={image.picture}
                                alt="Loading..."
                                className="homes-img"
                              />
                            </div>
                          ))}
                        </Carousel>
                      </section>
                      <div className="amount-display">
                        {data.pricing_type != 3 ? (
                          <h2 className="absolute-amount">
                            From <span> {data.pricing_type_amount_formatted} </span>/{" "}
                            {data.pricing_type_text}
                          </h2>
                        ) : (
                          <h2 className="absolute-amount">
                            Packages From <span> {data.pricing_type_amount_formatted} </span>
                          </h2>
                        )}
                      </div>
                      <div className="wishlist-icon-sec">
                        <div className="wishlist-icon">
                          <Link
                            className=""
                            to="#"
                            onClick={(e) => this.handleClick(e, data)}
                          >
                            {/* {loading ? (
                          ""
                        ) : wishlistData.host_id != "" ? (
                          wishlistData.host_id == data.host_id ? (
                            <i className="fas fa-heart pink-clr" />
                          ) : (
                            <i className="far fa-heart" />
                          )
                        ) : (
                          <i className="far fa-heart" />
                        )} */}
                            {data.wishlist_status ? (
                              <>
                                <i className="fa fa-heart pink-clr" />
                                <span className="ml-2 save-text">Saved</span>
                              </>
                            ) : (
                              <>
                                <i className="fa fa-heart" />
                                <span className="ml-2 save-text">Save</span>
                              </>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <a href={`/event/${data.host_unique_id}`} target="_blank">
                      <div className="homes-text-sec">
                        <p className="red-text txt-overflow">
                          {data.host_location}{" "}
                          <span className="room-content-dot">
                            <i className="fas fa-circle" />
                          </span>{" "}
                          {data.sub_category_name}
                        </p>
                        <h4 className="homes-title txt-overflow m-t-10">
                          {data.host_name}
                        </h4>
                        <h5 className="homes-price txt-overflow m-t-10">
                          <span>
                            {data.is_automatic_booking == 1 ? (
                              <span>Rapid Booking</span>
                            ) : (
                              <span className="text-strike">Rapid Booking</span>
                            )}
                          </span>
                        </h5>
                        <div className="flex-common">
                          <p className="guest-icon">
                            <i className="fa fa-users"></i> {data.max_guests}
                          </p>
                          <h5 className="rating-sec">
                            <span className="rating-star">
                              {this.starRatingHost(data.overall_ratings)}
                            </span>
                            <span className="rating-count">
                              &nbsp;({data.total_ratings})
                            </span>
                          </h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ))
            : "No suggesstion"}
        </div>
      </div>
    );
  }
}

export default TripSimilarListing;
