import React, { Component } from "react";

class TripShowAllAmen extends Component {
  state = {};

  render() {
    const { amenties , notIncluedAmentites} = this.props;
    return (
      <div className="modal fade" id="amenities">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content allMentites">
            <div className="modal-header d-flex justify-content-between">
              <div>
               <h1 className="section-head">All Amenties</h1>
              </div>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <p className="amenties-line" />
            </div>
            <div className="modal-body">
              <h3 className="section-head">Available</h3>
              {amenties.data.map((details) => (
                details.is_available == 1 && (
                  <div>
                    <p className="captalize  amenities-modal-head">
                      {details.user_question}  {details.answers ? "("+details.answers+")": ''}
                    </p>

                    {/* {details.answers_data.map((answers) => (
                      <h4 className="amenities-modal-para captalize">
                        {answers.title} 
                      </h4>
                    ))} */}
                    {details.description == "" ? (
                      ""
                    ) : (
                      <h6 className="amenities-modal-para captalize">
                        NOTE: {details.description}
                      </h6>
                    )}
                    <p className="amenties-line" />
                  </div>
                )
              ))}
              {notIncluedAmentites.length > 0 ? (
                <>
                  <h3 className="section-head mt-4 mb-3">Not Available</h3>
                  {notIncluedAmentites.map((details , index) => (
                    <div>
                      <p className="captalize  amenities-modal-head text-strike">
                        {details.user_question}
                      </p>
                      {index == notIncluedAmentites.length && (
                        <p className="amenties-line" />
                      )}
                    </div>))}
                    {amenties.data.map((details) => (
                      details.is_available == 0 && (
                        <div>
                           <p className="captalize  amenities-modal-head text-strike">
                            {details.user_question}
                          </p>
                          <p className="amenties-line" />
                        </div>
                      )
                    ))}
                </>
              ): ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TripShowAllAmen;
