import React, { Component, useEffect, useState } from "react";
import Categories from "./homeCategories";
import HomeRoomContent from "./homeRoomContent";
import HomeMainBanner from "./homeMainBanner";
import HomeExtraContent from "./homeExtraContent";
import HomeLastContent from "./homeLastcontent";
import Helper from "../Helper/Helper";
import Loader from "../Helper/Loader";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";
import LocationPage from "./locationPage";
import api from "../../Environment";
import HomeStepsContent from "./homeStepsContent";
import HomeAppsContent from "./HomeAppsContent";
import LandingPageEvents from "./LandingPageEvents";
import LandingSubCategory from "./LandingSubCategory";
import ShowAll from "./ShowAll";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Pagination } from "swiper/core";
import MetaTags from "react-meta-tags";
import HomeCategoryListing from "./HomeCategoryListing";
import Geocode from "react-geocode";
import { apiConstants } from "../Constant/constants";
import BannerLoaders from "../Loaders/BannerLoaders";
import CategoryLoaders from "../Loaders/CategoryLoaders";
import WhyPartyLoaders from "../Loaders/WhyPartyLoaders";
import NewOnPartyBudhaLoaders from "../Loaders/NewOnPartyBudhaLoaders";
import FindListingsLoaders from "../Loaders/FindListingsLoaders";
import LandingSubCategoryLoaders from "../Loaders/LandingSubCategoryLoaders";
import TopRatedHostLoaders from "../Loaders/TopRatedHostLoaders";

SwiperCore.use([Pagination]);

const LandingPage = (props) => {

  const [state, setState] = useState({
    first_block: null,
    mainData: null,
    loading: true,
    second_block: null,
    searchInput: {},
    searchLoadingContent: null,
    searchButtonDisable: false,
  });

  const [eventData, setEventData] = useState({
    eventData: null,
    loadingEvent: true,
  });

  const [locationData, setLocationData] = useState({
    locationData: null,
    loadingLocation: true,
  });

  const [navigatorResult, setNavigatorResult] = useState({
    navigatorResult: { city: '' },
    loadingNavigator: true,
  });

  let load = new Loader();

  useEffect(() => {
    handleNavigator();
    if (props.location.state != null) {
      ToastDemo(
        props.toastManager,
        props.location.state.error,
        "error"
      );
    }
    getEventDetailsApi();
    getLocationDetailsApi();
  }, []);

  useEffect(() => {
    let inputData = {
      api_page_type: "HOME",
      location_name: navigatorResult.navigatorResult.city
    };
    homePageFirstSection(inputData);
  }, [!navigatorResult.loadingNavigator]);

  const homePageFirstSection = (inputData) => {
    api.postMethod("home_first_section", inputData).then((response) => {
      setState({
        ...state,
        first_block: response.data.first_block,
        mainData: response.data.data,
        loading: false,
        second_block: response.data.second_block,
        sub_categories: response.data.sub_categories,
      });
    });
  };

  const handleNavigator = () => {
    let city = "",
      state,
      country;
    navigator.geolocation.getCurrentPosition((position) => {
      var positionInfo =
        "Your current position is (" +
        "Latitude: " +
        position.coords.latitude +
        ", " +
        "Longitude: " +
        position.coords.longitude +
        ")";
      console.log("position info", positionInfo);
      Geocode.setApiKey(apiConstants.google_api_key);
      Geocode.setLanguage("en");
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          setNavigatorResult({
            ...navigatorResult,
            navigatorResult: {
              city: city,
            },
            loadingNavigator: false,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const getEventDetailsApi = () => {
    api.postMethod("events_index").then((response) => {
      if (response.data.success === true) {
        setEventData({
          ...eventData,
          loadingEvent: false,
          eventData: response.data.data.events,
        });
      } else {
        // Do nothing
      }
    });
  };

  const getLocationDetailsApi = () => {
    api.postMethod("filter_locations").then((response) => {
      if (response.data.success === true) {
        setLocationData({
          ...locationData,
          loadingLocation: false,
          locationData: response.data.data,
        });
      } else {
        // Do nothing
      }
    });
  };

  const convert = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  const dateChange = (...args) => {
    // console.log("asdf", convert(args[0]));
    const searchInput = { ...state.searchInput };
    searchInput["from_date"] = convert(args[0]);
    setState({ searchInput });
    setState({
      ...state,
      searchInput: searchInput,
    });
  };

  const selectEvent = (event, details) => {
    event.preventDefault();
    const searchInput = { ...state.searchInput };
    searchInput["event_id"] = details.event_id;
    searchInput["event_title"] = details.title;
    setState({
      ...state,
      searchInput: searchInput,
    });
  };

  const selectKeyword = (event, keywords) => {
    event.preventDefault();
    const searchInput = { ...state.searchInput };
    searchInput["keyword_id"] = keywords.id;
    searchInput["keywords"] = keywords.keywords;
    setState({
      ...state,
      searchInput: searchInput,
    });
  };

  const getSearchInput = ({ currentTarget: input }) => {
    let location_data = locationData.locationData.filter((item) => item.service_location_id == input.value).map(({ service_location_name }) => service_location_name);

    const searchInput = { ...state.searchInput };
    searchInput[input.name] = input.value;
    searchInput['service_location_name'] = location_data ? location_data[0] : '';
    setState({
      ...state,
      searchInput: searchInput,
    });
  };

  const submitSearch = (event) => {
    event.preventDefault();
    setState({
      ...state,
      searchButtonDisable: true,
      searchLoadingContent: "Loading...",
    });

    let searchWithLocation = { ...state.searchInput };

    if (!state.loading && navigatorResult.navigatorResult) {
      searchWithLocation = {
        ...searchWithLocation,
        location_name: navigatorResult.navigatorResult.city,
      };
    }

    api
      .postMethod("search_result", searchWithLocation)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data[0].data.length > 0) {
            props.history.push("/search", {
              ...response.data.data,
              searchInput: state.searchInput,
            });
          } else {
            ToastDemo(
              props.toastManager,
              "No place found... Please try again",
              "error"
            );
          }
        } else {
          ToastDemo(props.toastManager, response.data.error, "error");
        }
        setState({
          ...state,
          searchButtonDisable: false,
          searchLoadingContent: null,
        });
      })
      .catch(function (error) { });
  };

  useEffect(() => {
    function handleScrollYChange() {
      const lastScrollY = window.scrollY;
      if (lastScrollY) {
        localStorage.setItem("scrollY", lastScrollY);
      }
    }
    window.addEventListener("scroll", handleScrollYChange, true);
    return () => {
      window.removeEventListener("scroll", handleScrollYChange);
    };
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log(navigatorResult);
  //     window.scrollTo(0,localStorage.getItem("scrollY"));
  //   }, 5000);
  // }, [localStorage.getItem("scrollY") > 0] && !state.loading);

  useEffect(() => {
    if (!state.loading) {

      const delayDebounceFn = setTimeout(() => {
        window.scrollTo(0, localStorage.getItem("scrollY"));
      }, 1000);
      return () => clearTimeout(delayDebounceFn);

    }
  }, [state.loading]);

  if (state.loading) {

    return (
      <>
        <BannerLoaders />

        <div className="main">
          <div className="top-bottom-spacing">
            {state.loading && navigatorResult.loadingNavigator && (
              // load.categoryLoader()
              <CategoryLoaders count={4}/>
            )}
            <div className="party-life site-content">
              <h1 className="section-head">Why Party Budha?</h1>
            </div>
            <div className="why-party-sec">
              <div className="why-party">

                <div className="site-content">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="section-title">
                            <h4 class="section-head">Why Party Budha?</h4>
                          </div> */}
                      <div className="why-party-card web-display">
                        <div className="why-party-item">
                          <div className="why-party-icon-sec">
                            <img src="../assets/icons/multiple.png" alt="image"/>
                          </div>
                          <div className="party-info">
                            <h5>More Choice</h5>
                            <p>
                              No more scouting the city for party spaces and  arrangements. Be  it  a  party  hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!
                            </p>
                          </div>
                        </div>
                        <div className="why-party-item">
                          <div className="why-party-icon-sec">
                            <img src="../assets/icons/booking.png" alt="image"/>
                          </div>
                          <div className="party-info">
                            <h5>Hassle-free Booking</h5>
                            <p>
                              Compare price, check availability, read reviews, chat with the host and book online through our secure user friendly platform. All in just few clicks!
                            </p>
                          </div>
                        </div>
                        <div className="why-party-item">
                          <div className="why-party-icon-sec">
                            <img src="../assets/icons/social-care.png" alt="image"/>
                          </div>
                          <div className="party-info">
                            <h5>Peace of Mind</h5>
                            <p>
                              Our  Book  Assure  program  ensures  you as  a  customer  gets  the lowest  price possible  and  an  easy  cancellation experience. We got your back!
                            </p>
                          </div>
                        </div>
                      </div>
                      <Swiper pagination={true} className="mySwiper w-100 web-display-none">
                        <SwiperSlide>
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              <img src="../assets/icons/multiple.png" alt="image"/>
                              {/* <img src="../assets/img/opportunity.png"/> */}
                            </div>
                            <div className="party-info">
                              <h5>More Choice</h5>
                              <p>
                                No more scouting the city for party spaces and  arrangements. Be  it  a  party  hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              <img src="../assets/icons/booking.png" alt="image"/>
                              {/* <img src="../assets/img/booking.png"/> */}
                            </div>
                            <div className="party-info">
                              <h5>Hassle-free Booking</h5>
                              <p>
                                Compare price, check availability, read reviews, chat with the host and book online through our secure user friendly platform. All in just few clicks!
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              <img src="../assets/icons/social-care.png" alt="image"/>
                              {/* <img src="../assets/img/peace-of-mind.png"/> */}
                            </div>
                            <div className="party-info">
                              <h5>Peace of Mind</h5>
                              <p>
                                Our  Book  Assure  program  ensures  you as  a  customer  gets  the lowest  price possible  and  an  easy  cancellation experience. We got your back!
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {load.propertyLoader()}
            {load.categoryLoader()} */}
            {/* <WhyPartyLoaders count={3}/> */}
            <NewOnPartyBudhaLoaders  count={12}/>
            <FindListingsLoaders count={3}/>
            <TopRatedHostLoaders count={12}/>
            <LandingSubCategoryLoaders count={12}/>
          </div>
        </div>

      </>
    )
  } else {
    return (

      <div>
        {eventData.loadingEvent || locationData.loadingLocation ? (
          // load.bannerLoader()
          "Loading..."
        ) : (
          <HomeMainBanner
            props={props}
            loadingEvent={eventData.loadingEvent}
            eventData={eventData.eventData}
            loadingLocation={locationData.loadingLocation}
            locationData={locationData.locationData}
            dateChange={dateChange}
            getSearchInput={getSearchInput}
            submitSearch={submitSearch}
            searchLoadingContent={state.searchLoadingContent}
            searchButtonDisable={state.searchButtonDisable}
            searchInput={state.searchInput}
            selectEvent={selectEvent}
            selectKeyword={selectKeyword}
          />
        )}
        <div className="main">
          {/* <MetaTags>
            <title>Party Budha</title>
            <meta name="keywords" content="Partybudha,party hall,indoor play center,wedding location" />
            <meta name="description" content="The best way to book party hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!" />
            <meta property="og:title" content="Party Budha: Find party spaces and event related services in your community" />
            <meta
              property="og:image"
              content={window.location.origin + "/assets/img/banner-1.png"}
            />
          </MetaTags> */}
          <div>
            <div className="top-bottom-spacing">
              {state.loading && navigatorResult.loadingNavigator ? (
                load.categoryLoader()
              ) : (
                <LandingPageEvents
                  loadingEvent={eventData.loadingEvent}
                  eventData={eventData.eventData}
                  loadingNavigator={navigatorResult.loadingNavigator}
                  location_name={navigatorResult.navigatorResult}
                />
              )}
              {/* <HomeStepsContent /> */}
              <div className="party-life site-content">
                <h1 className="section-head">Why Party Budha?</h1>
              </div>
              <div className="why-party-sec">
                <div className="why-party">

                  <div className="site-content">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <div className="section-title">
                            <h4 class="section-head">Why Party Budha?</h4>
                          </div> */}
                        <div className="why-party-card web-display">
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              {/* <img src="../assets/icons/multiple.png" alt="image"/> */}
                              <picture>
                                <source className="lazyload" type="image/png" srcset="../assets/icons/multiple.png"/>
                                <img data-src="../assets/icons/multiple.png" type="image/png" className="lazyload" alt="image"/>
                              </picture>
                            </div>
                            <div className="party-info">
                              <h5>More Choice</h5>
                              <p>
                                No more scouting the city for party spaces and  arrangements. Be  it  a  party  hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!
                              </p>
                            </div>
                          </div>
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              {/* <img src="../assets/icons/booking.png" alt="image"/> */}
                              <picture>
                                <source className="lazyload" type="image/png" srcset="../assets/icons/booking.png"/>
                                <img data-src="../assets/icons/booking.png" type="image/png" className="lazyload" alt="image"/>
                              </picture>
                            </div>
                            <div className="party-info">
                              <h5>Hassle-free Booking</h5>
                              <p>
                                Compare price, check availability, read reviews, chat with the host and book online through our secure user friendly platform. All in just few clicks!
                              </p>
                            </div>
                          </div>
                          <div className="why-party-item">
                            <div className="why-party-icon-sec">
                              {/* <img src="../assets/icons/social-care.png" alt="image"/> */}
                              <picture>
                                <source className="lazyload" type="image/png" srcset="../assets/icons/social-care.png"/>
                                <img data-src="../assets/icons/social-care.png" type="image/png" className="lazyload" alt="image"/>
                              </picture>
                            </div>
                            <div className="party-info">
                              <h5>Peace of Mind</h5>
                              <p>
                                Our  Book  Assure  program  ensures  you as  a  customer  gets  the lowest  price possible  and  an  easy  cancellation experience. We got your back!
                              </p>
                            </div>
                          </div>
                        </div>
                        <Swiper pagination={true} className="mySwiper w-100 web-display-none">
                          <SwiperSlide>
                            <div className="why-party-item">
                              <div className="why-party-icon-sec">
                                {/* <img src="../assets/icons/multiple.png" alt="image"/> */}
                                {/* <img src="../assets/img/opportunity.png"/> */}
                                <picture>
                                  <source className="lazyload" type="image/png" srcset="../assets/icons/multiple.png"/>
                                  <img data-src="../assets/icons/multiple.png" type="image/png" className="lazyload" alt="image"/>
                                </picture>
                              </div>
                              <div className="party-info">
                                <h5>More Choice</h5>
                                <p>
                                  No more scouting the city for party spaces and  arrangements. Be  it  a  party  hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="why-party-item">
                              <div className="why-party-icon-sec">
                                {/* <img src="../assets/icons/booking.png" alt="image"/> */}
                                <picture>
                                <source className="lazyload" type="image/png" srcset="../assets/icons/booking.png"/>
                                <img data-src="../assets/icons/booking.png" type="image/png" className="lazyload" alt="image"/>
                              </picture>
                                {/* <img src="../assets/img/booking.png"/> */}
                              </div>
                              <div className="party-info">
                                <h5>Hassle-free Booking</h5>
                                <p>
                                  Compare price, check availability, read reviews, chat with the host and book online through our secure user friendly platform. All in just few clicks!
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="why-party-item">
                              <div className="why-party-icon-sec">
                                {/* <img src="../assets/icons/social-care.png" alt="image"/> */}
                                <picture>
                                <source className="lazyload" type="image/png" srcset="../assets/icons/social-care.png"/>
                                <img data-src="../assets/icons/social-care.png" type="image/png" className="lazyload" alt="image"/>
                              </picture>
                                {/* <img src="../assets/img/peace-of-mind.png"/> */}
                              </div>
                              <div className="party-info">
                                <h5>Peace of Mind</h5>
                                <p>
                                  Our  Book  Assure  program  ensures  you as  a  customer  gets  the lowest  price possible  and  an  easy  cancellation experience. We got your back!
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <HomeAppsContent /> */}
              {state.loading
                ? load.propertyLoader()
                : state.mainData.map((eachData, index) => {
                  return (
                    index < state.mainData.length && (
                      <div className="site-content" key={index}>
                        <HomeRoomContent
                          contentDetails={eachData.data}
                          title={eachData.title}
                          description={eachData.description}
                          history={props.history}
                          isMain={0}
                        />


                        {eachData.data.length > 0 ? (
                          <ShowAll
                            eachData={eachData}
                          />

                          // <Link
                          //   to={`/see_all/${eachData.title}/${eachData.api_page_type_id}/${eachData.api_page_type}`}
                          //   className="show-all"
                          // >
                          //   show all <i className="fas fa-chevron-right" />
                          // </Link>
                        ) : (
                          ""
                        )}
                        {index == 0 && state.second_block.data.length > 0 ? (
                          <LocationPage locations={state.second_block} />
                        ) : (
                          ""
                        )}
                        {index == 1 ? <HomeExtraContent /> : ""}
                      </div>
                    )
                  );
                })}
              {/* <HomeOtherContent /> */}
              {state.loading ? (
                load.categoryLoader()
              ) : (
                state.sub_categories ?
                  <LandingSubCategory
                    loadingEvent={state.loading}
                    eventData={state.sub_categories}
                  />
                  : ''
              )}
              <HomeCategoryListing/>
              <HomeLastContent />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withToastManager(LandingPage);
