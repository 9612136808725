import React, { Component } from "react";

class AddListingStep3 extends Component {
  state = { ageLimits: [] };

  ageLimitsGenerate = () => {
    const ageLimitsarray = [];
    for (let index = 1; index <= 100; index++) {
      ageLimitsarray.push(index);

      this.setState({ ageLimits: ageLimitsarray });
    }
  };
  componentDidMount() {
    this.ageLimitsGenerate();
  }
  render() {
    const { stepData, handleChange, formData } = this.props;
    return (
      <div id="step-3" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{stepData[3].title}</h4>
              <p className="main-txt">{stepData[3].description}</p>
              <div className="flow-content">
                <div className="form-group large-form-group">
                  <label className="light-txt">
                    {stepData[3].data[0].question}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={stepData[3].data[0].placeholder}
                    name={stepData[3].data[0].server_key}
                    value={formData.min_guests}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group large-form-group">
                  <label className="light-txt">
                    {stepData[3].data[1].question}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={stepData[3].data[1].placeholder}
                    name={stepData[3].data[1].server_key}
                    value={formData.max_guests}
                    onChange={handleChange}
                  />
                </div>

                <div className="row age-limit-content">
                  <div className="col-md-12">
                    <label className="light-txt">Age limit for adult</label>
                  </div>
                  <div className="col-md-4">
                    <select
                      name="adult_from"
                      className="form-control"
                      placeholder="From"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map(
                        (value, index) => (
                          // value >= 16 ? (
                          <option value={value}>{value}</option>
                        )
                        // ) : (
                        //   ""
                        // )
                      )}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      name="adult_to"
                      className="form-control"
                      placeholder="To"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map(
                        (value, index) => (
                          // value > 16 ? (
                          <option
                            value={value}
                            selected={value == 100 ? true : false}
                          >
                            {value}
                          </option>
                        )
                        // ) : (
                        //   ""
                        // )
                      )}
                    </select>
                  </div>
                </div>

                <div className="row age-limit-content">
                  <div className="col-md-12">
                    <label className="light-txt">Age limit for children</label>
                  </div>

                  <div className="col-md-4">
                    <select
                      name="children_from"
                      className="form-control"
                      placeholder="From"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map(
                        (value, index) => (
                          // value >= 2 && value <= 16 ? (
                          <option value={value}>{value}</option>
                        )
                        // ) : (
                        //   ""
                        // )
                      )}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      name="children_to"
                      className="form-control"
                      placeholder="To"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map(
                        (value, index) => (
                          // value > 2 && value <= 16 ? (
                          <option
                            value={value}
                            selected={value == 100 ? true : false}
                          >
                            {value}
                          </option>
                        )
                        // ) : (
                        //   ""
                        // )
                      )}
                    </select>
                  </div>
                </div>
                <div className="row age-limit-content">
                  <div className="col-md-12">
                    <label className="light-txt">Age limit for infants</label>
                  </div>

                  <div className="col-md-4">
                    <select
                      name="infants_from"
                      className="form-control"
                      placeholder="From"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map(
                        (value, index) => (
                          // value >= 0 && value <= 2 ? (
                          <option value={value}>{value}</option>
                        )
                        // ) : (
                        //   ""
                        // )
                      )}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      name="infants_to"
                      className="form-control"
                      placeholder="To"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map(
                        (value, index) => (
                          // value > 0 && value <= 2 ? (
                          <option
                            value={value}
                            selected={value == 100 ? true : false}
                          >
                            {value}
                          </option>
                        )
                        // ) : (
                        //   ""
                        // )
                      )}
                    </select>
                  </div>
                </div>
                <div className="row age-limit-content">
                  <div className="col-md-12">
                    <label className="light-txt">
                      Age limit for people with special needs
                    </label>
                  </div>

                  <div className="col-md-4">
                    <select
                      name="age_special_from"
                      className="form-control"
                      placeholder="From"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map((value, index) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      name="age_special_to"
                      className="form-control"
                      placeholder="To"
                      onChange={handleChange}
                    >
                      {this.state.ageLimits.map((value, index) => (
                        <option
                          value={value}
                          selected={value == 100 ? true : false}
                        >
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep3;
