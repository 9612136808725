import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeAppsContent extends Component {
  state = {};
  render() {
    return (
      <div className="site-content">
        <div className="section-title">
          <h1 className="section-head"></h1>
        </div>
        <div className="add-img-sec">
          <img
            src="../../../assets/img/banner1.jpg"
            alt="introducing"
            className="add-img"
            style={{ opacity: "0.6" }}
          />
          <div className="add-text">
            <h3 className="captalize">
              A new selection of homes verified for quality & comfort.
            </h3>
            <div>
              <Link to="#" className="white-btn banner-white-btn">
                Download Apps
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeAppsContent;
