import React, { Component } from "react";
import { Link } from "react-router-dom";

import InputField from "../Helper/inputfield";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import GoogleLogin from "react-google-login";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import { DatePicker, Calendar } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import dayjs from "dayjs";
import SocialLogin from "./SocialLogin";
import validator from 'validator';
import PasswordStrengthBar from 'react-password-strength-bar';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CustomPasswordMeter from "../Helper/CustomPasswordMeter";

const $ = window.$;

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class Register extends Helper {
  state = {
    data: {
      name: "",
      first_name: "",
      last_name: "",
      dob: "",
      mobile: "",
      is_newsletter: 1,
      email: "",
      password: "",
      device_type: "web",
      device_token: "123466",
      login_by: "manual",
      agree: 0,
      timezone: const_time_zone,
    },
    googleLoginInput: {
      social_unique_id: "",
      login_by: "",
      email: "",
      name: "",
      picture: "",
      device_type: "web",
      device_token: "123466",
      first_name: "",
      last_name: "",
      timezone: const_time_zone,
    },
    loadingContent: null,
    buttonDisable: false,
    displayFirstSection: "block",
    displaySecondSection: "none",
    imagePreviewUrl: window.location.origin +"/assets/img/placeholder.jpg",
    loading: true,
    passwordScore:0,
    activeBreakdown:null,
  };

  responseGoogle = (response) => {
    this.setState({
      googleLoginInput: "",
      loading: true,
    });
    const path = this.props.location;
    console.log(response);
    if (response.profileObj) {
      console.log(response.profileObj);
      const googleLoginInput = { ...this.state.googleLoginInput };
      googleLoginInput["social_unique_id"] = response.profileObj.googleId;
      googleLoginInput["login_by"] = "google";
      googleLoginInput["email"] = response.profileObj.email;
      googleLoginInput["first_name"] = response.profileObj.givenName;
      googleLoginInput["last_name"] = response.profileObj.familyName;
      googleLoginInput["name"] = response.profileObj.name;
      googleLoginInput["picture"] = response.profileObj.imageUrl;
      googleLoginInput["timezone"] = const_time_zone;
      
      this.setState({
        googleLoginInput: googleLoginInput,
        loading: false,
      });
      api.postMethod("check_new_user", this.state.googleLoginInput)
      .then((response) => {
          if (response.data.success === true) {
              if(response.data.data.is_new_user == 1) {
                  $("#social-login").modal("show");
                  $("#login").modal("hide"); 
              } else {
                  this.setState({
                      loadingContent: "Loading... Please wait..",
                      buttonDisable: true,
                  });
                  const path = this.props.location;
                  $("#login").modal("hide"); 
                  api.postMethod("register", this.state.googleLoginInput)
                  .then((response) => {
                      if (response.data.success === true) {
                          localStorage.setItem(
                              "userId",
                              response.data.data.user_id
                          );
                          localStorage.setItem("socket", true);
                          localStorage.setItem("login_by", response.data.data.login_by);
                          localStorage.setItem(
                              "accessToken",
                              response.data.data.token
                          );
                          localStorage.setItem("userLoginStatus", true);
                          localStorage.setItem(
                              "user_picture",
                              response.data.data.picture
                          );
                          localStorage.setItem(
                              "username",
                              response.data.data.username
                          );
          
                          // window.location = path ? path.from.pathname : "/home";
                          ToastDemo(
                              this.props.toastManager,
                              response.data.message,
                              "success"
                          );
                          this.setState({
                              loadingContent: null,
                              buttonDisable: false,
                          });
                          $("#login").modal("hide");
                          if (path) {
                              this.props.history.push(path.pathname);
                          } else {
                              this.props.history.push("/home");
                          }
                          this.setState({
                              googleLoginInput: {},
                              loading: true,
                          });
                      } else {
                          this.setState({
                              loadingContent: null,
                              buttonDisable: false,
                          });
                          ToastDemo(
                              this.props.toastManager,
                              response.data.error,
                              "error"
                          );
                      }
                  })
                  .catch((error) => {
                      this.setState({
                          loadingContent: null,
                          buttonDisable: false,
                      });
                  });

              }
          } else {
              
          }
      })
      .catch((error) => {
          this.setState({
              loadingContent: null,
              buttonDisable: false,
          });
      });
      // $("#signup").modal("hide");
      // $("#social-login").modal("show");
    }
  };

  handleMobileNumber = (value) => {
    const data = { ...this.state.data };
    data['mobile'] = value;
    this.setState({ data });
  };

  handleResponseChange = ({ currentTarget: input }) => {
    const googleLoginInput = { ...this.state.googleLoginInput };
    googleLoginInput[input.name] = input.value;
    this.setState({ googleLoginInput });
  };

  handleMobileResponse = (value) => {
    const googleLoginInput = { ...this.state.googleLoginInput };
    googleLoginInput['mobile'] = value;
    this.setState({ googleLoginInput });
  };

  handlePassword = (value) => {
    this.setState({
      passwordScore:value
    })
  };

  handleGoogleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    const path = this.props.location;
    $("#social-login").modal("hide");
    $("#signup").modal("hide");

    api
      .postMethod("register", this.state.googleLoginInput)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("login_by", response.data.data.login_by);
          localStorage.setItem("socket", true);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);
          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
          $("#signup").modal("hide");

          if (path) {
            // window.location = path.pathname;
            this.props.history.push(path.pathname);
          } else {
            // window.location = "/home";
            this.props.history.push("/home");
          }
          this.setState({
            googleLoginInput: {},
            loading: true,
          });
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          if (
            response.data.error_code == 1001 ||
            response.data.error_code == 1000
          ) {
            $("#signup").modal("hide");
          }
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch(function(error) {});
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const path = this.props.location;

    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("register", this.state.data)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("login_by", response.data.data.login_by);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("socket", true);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);
          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
            data: {},
          });
          $("#signup").modal("hide");

          if (path) {
            // window.location = path.pathname;
            this.props.history.push(path.pathname);
          } else {
              // window.location = "/home";
            this.props.history.push("/home");
          }
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          if (
            response.data.error_code == 1001 ||
            response.data.error_code == 1000
          ) {
            $("#signup").modal("hide");
            this.setState({
              displayFirstSection: "block",
              displaySecondSection: "none",
              loadingContent: null,
              buttonDisable: false,
              data: {
                name: "",
                email: "",
                password: "",
                device_type: "web",
                device_token: "123456",
                login_by: "manual",
                timezone: const_time_zone,
                first_name: "",
                last_name: "",
                dob: "",
                address: "",
                mobile: "",
              }
            });
          }

          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });

        }
      })
      .catch(function(error) {});
  };

  getDate = (title) => (...args) => {
    console.log(title, args);
    console.log(args[1]);
    const data = { ...this.state.data };
    data["dob"] = args[1];
    this.setState({ data });
  };

  displayNextSection = (event) => {
    event.preventDefault();
    if (validator.isEmail(this.state.data.email) && this.state.passwordScore >= 2) {
      this.setState({
        displayFirstSection: "none",
        displaySecondSection: "block",
      });
    } else if(this.state.passwordScore < 2) {
      alert('Password strength is low')
    } else {
      alert('Please Enter valid Email!')
    }
  };

  displayPrevSection = (event) => {
    event.preventDefault();
    this.setState({
      displayFirstSection: "block",
      displaySecondSection: "none",
    });
  };

  handleChangePicture = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (input.type === "file") {
      data[input.name] = input.files[0];
      this.setState({ data });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  loginClick = (event) => {
    event.preventDefault();
    $("#signup").modal("hide");
    $("#login").modal("show");
  };

  passwordClick = (event) => {
    event.preventDefault();
    this.setState({
      activeBreakdown: this.state.activeBreakdown == 'password' ? null : 'password',
    })
  };

  handlebreakdownClose = (event) => {
    event.preventDefault();
    this.setState({
      activeBreakdown: null,
    });
  };

  render() {
    const { data, imagePreviewUrl, googleLoginInput, loading,activeBreakdown } = this.state;
    return (
      <div>
        <div className="modal fade auth signup" id="signup">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  id="close-signup"
                  data-dismiss="modal"
                >
                  {/* <i className="material-icons">close</i> */}
                   <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#787878" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
              </div>

              <div className="modal-body">
                <h1 className="section-head">
                Welcome to {configuration.get("configData.site_name")}
                </h1>
                <div className="top1">
                  <div
                    className=""
                    style={{ display: this.state.displayFirstSection }}
                  >
                    <InputField
                      type="text"
                      name="email"
                      onChange={this.handleChange}
                      placeholder="email"
                      iconClassName="fas fa-envelope"
                      value={data.email}
                    />

                    <InputField
                      type="password"
                      name="password"
                      onChange={this.handleChange}
                      placeholder="password"
                      iconClassName="fas fa-key"
                      value={data.password}
                    />
                    <div class="row">
                        <div class="col-11">
                          <PasswordStrengthBar 
                            password={data.password} 
                            shortScoreWord='Too Short' 
                            scoreWords={['Too Short', 'Weak', 'Good', 'Strong']} 
                            minLength={8}
                            onChangeScore={score => this.handlePassword(score)}
                            className="password-package-bar"
                          />
                           <CustomPasswordMeter score={this.state.passwordScore} scoreWords={['Weak', 'Good', 'Strong']}/>
                        </div>
                        <div class="col-1">
                          <div className="BreakdownWrapper">
                            <span onClick={this.passwordClick}>
                              <i class="far fa-question-circle breakdown-text-color"></i>
                            </span>
                            <div className={`breakdownModal ${activeBreakdown == "password" ? "active" : null}`}>
                              <div className="bookingBreakdownwrapper column">
                                <div className="breakdownHeading">
                                  <span className="close" onClick={this.handlebreakdownClose}>
                                    <i class="fas fa-times breakdown-text"></i>
                                  </span>
                                  <p className="breakdownheading">Password Recommendation</p>
                                </div>
                                <p className="overview-line1 my-3 "></p>
                                <div className="breakdownBody">
                                  <div className="taxwrapper">
                                    <ul>
                                      <li>
                                        Atleast 8 characters in length
                                      </li>
                                      <li>
                                        Contains a number and symbol
                                      </li>
                                      <li>
                                        Contains a capital letter and small letters
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>                    
                        </div>
                    </div>
                   
                    
                    <button
                      onClick={this.displayNextSection}
                      className="pink-btn bottom1"
                    >
                      Continue
                    </button>
                  </div>

                  <div style={{ display: this.state.displaySecondSection }}>
                    <button
                      onClick={this.displayPrevSection}
                      className="pink-btn bottom1"
                    >
                      Back
                    </button>

                    <div className="input-group">
                      <div class="upload-btn-wrapper">
                        <img
                          src={imagePreviewUrl}
                          class="mr-3 rounded-circle user-profile-img"
                        />
                        <input
                          type="file"
                          name="picture"
                          onChange={this.handleChangePicture}
                        />
                      </div>
                    </div>

                    <InputField
                      type="text"
                      name="first_name"
                      onChange={this.handleChange}
                      placeholder="First Name"
                      iconClassName="fas fa-user-circle"
                      value={data.first_name}
                    />
                    <InputField
                      type="text"
                      name="last_name"
                      onChange={this.handleChange}
                      placeholder="Last Name"
                      iconClassName="fas fa-user"
                      value={data.last_name}
                    />

                    <div className="input-group">
                      <DatePicker
                        placeholder="DOB (mm/dd/yyyy)"
                        onChange={this.getDate("Date")}
                        dateFormat="MM-DD-YYYY"
                      />
                      <i
                        onClick={this.props.onClick}
                        aria-hidden="true"
                        className="fa fa-calendar"
                      ></i>
                    </div>
                    <InputField
                      type="text"
                      name="address"
                      placeholder="Address"
                      onChange={this.handleChange}
                      iconClassName="fas fa-address-card"
                    />

                    <div>
                      <div className="input-group">
                        <PhoneInput
                          country={'ca'}
                          label="Phone number"
                          type="text"
                          placeholder=""
                          id="number"
                          name="mobile"
                          onChange={value => this.handleMobileNumber(value)}
                          className="form-control"
                        />
                        {/* <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon">
                            <i className="fas fa-mobile" />
                          </span>
                        </div> */}
                      </div>
                    </div>

                    <p className="text-muted">
                      We'll send you marketing promotions, special offers,
                      inspiration, and policy updates via email.
                    </p>

                    <div className="input-group">
                      <label className="text-none">
                        <input
                          type="checkbox"
                          name="is_newsletter"
                          value="0"
                          onChange={this.handleChange}
                          className=""
                        />{" "}
                        I don’t want to receive any marketing messages from{" "}
                        {configuration.get("configData.site_name")}. I
                        understand I will still receive booking confirmation and
                        other important notification from Party Budha even if I
                        opt out of the marketing messages.
                      </label>
                    </div>

                    <p>
                      By clicking Agree & Signup, I agree to{" "}
                      
                      <Link to={`/page/Guest-Service-Agreement`} target="_blank">
                        {configuration.get("configData.site_name")}
                        {"  "}
                        Guest Services Agreement
                      </Link>
                       {"  "}and 
                      <Link to={`/page/privacy`} target="_blank">
                        {" "}
                        Privacy Policy{" "}
                      </Link>
                      .
                    </p>

                    <button
                      className="pink-btn btn-block"
                      disabled={this.state.buttonDisable}
                      onClick={this.handleSubmit}
                    >
                      {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : "Agree & Signup"}
                    </button>
                  </div>
                </div>

                <div className="login-separator">or continue with</div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 bottom1">
                    <GoogleLogin
                      clientId={apiConstants.GOOGLE_CLIENT_ID}
                      render={(renderProps) => (
                        <button
                          className="social-btn"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <i className="fab fa-google" /> Google
                        </button>
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </div>
                </div>
                <p className="line" />
                <h4 className="m-0 text-center captalize">
                  Already have a {configuration.get("configData.site_name")}{" "}
                  account?{" "}
                  <a
                    href="#"
                    className="bold-cls close-signup"
                    // data-toggle="modal"
                    // data-target="#login"
                    onClick={this.loginClick}
                  >
                    {" "}
                    Log in
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          ""
        ) : (
          <SocialLogin
            googleLoginInput={googleLoginInput}
            handleResponseChange={this.handleResponseChange}
            handleGoogleSubmit={this.handleGoogleSubmit}
            handleMobileResponse={this.handleMobileResponse}
          />
        )}
      </div>
    );
  }
}

export default withToastManager(Register);
