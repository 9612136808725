import React, { Component } from "react";

class AddListingStep1 extends Component {
  render() {
    const {
      categoryChange,
      categoryValue,
      stepData,
      handleChange,
      subcategoryLoading,
      subCategoryData,
      formData,
    } = this.props;
    return (
      <div id="step-1" className="flow-tab">
        <div className="add-flow-block row add-listing-left-content">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{stepData[0].title}</h4>
              <div className="flow-content">
                <div className="flow-box">
                  <div className="form-group">
                    <label>{stepData[0].data[0].question}</label>
                    <select
                      className="form-control mw-300"
                      name="category_id"
                      onChange={categoryChange}
                      value={categoryValue}
                    >
                      <option>Select One</option>
                      {stepData[0].data[0].answer.map((category) => (
                        <option key={category.key} value={category.key}>
                          {category.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      Let's select the primary property type, the selected value
                      will be displayed below your property thumbnail image in
                      the listing page.
                    </label>
                    <select
                      className="form-control mw-300"
                      name="sub_category_id"
                      onChange={handleChange}
                      // onSelect={this.categoryChange}
                      value={formData.sub_category_id}
                    >
                      <option>Select One</option>
                      {subcategoryLoading
                        ? ""
                        : subCategoryData.map((subcategory) => (
                            <option
                              key={subcategory.sub_category_id}
                              value={subcategory.sub_category_id}
                            >
                              {subcategory.sub_category_user_display_name}
                            </option>
                          ))}
                    </select>
                    {/* <p className="form-note">
                      Apartments are typically located in multi-unit residential
                      buildings or complexes where other people live.
                    </p> */}
                  </div>
                </div>
                <div className="flow-box">
                  <h5
                    className="flow-box-tit"
                    style={{ color: "#484848", fontSize: "20px" }}
                  >
                    {stepData[0].data[1].question}
                  </h5>
                  {stepData[0].data[1].answer.map((ans) => (
                    <div className="form-check add-list-block" key={ans.value}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name={stepData[0].data[1].server_key}
                        id={ans.value}
                        value={ans.value}
                        checked={
                          ans.value == formData[stepData[0].data[1].server_key]
                            ? true
                            : false
                        }
                        onChange={handleChange}
                      />
                      <label className="form-check-label row" htmlFor={ans.value}>
                        <p
                          className="flow-check-txt text-dark"
                          style={{ fontSize: "16px" }}
                        >
                          <b>{ans.value}</b>
                        </p>
                        <h5 className="flow-check-tit text-muted">
                          {ans.description}
                        </h5>
                      </label>
                      <div className="clear-both" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep1;
