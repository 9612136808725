import React, { Component } from "react";
import HostProfileSideBar from "./hostProfileSideBar";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";
import ProfileInput from "../../Helper/profileInput";

class HostStripeConnect extends HostHelper {
  state = {
    data: {},
    loading: true,
    stripeData: '',
    updateLoadingContent: null,
    loadingContent: null,
    buttonDisable: true,
    updateButtonDisable: true
  };

  componentDidMount() {
    this.getStripeConnect();
  }

  getStripeConnect = () => {
    api.postMethod("stripe_connect").then(response => {
      if (response.data.success) {
        this.setState({ stripeData: response.data.data, loading: false });
      } else {
        this.checkLoginHost(response.data);
      }
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      updateLoadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    
    api.postMethod("stripe_connect_get_url", this.state.data).then(response => {
      if (response.data.success) {
        console.log(response.data);
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ updateLoadingContent: null, buttonDisable: false, updateButtonDisable: true });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ updateLoadingContent: null, buttonDisable: true, updateButtonDisable: false });
      }
    });
    setTimeout(function(){
      window.location.reload(); 
    },1000);
  };

  handleRemove = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    
    api.postMethod("stripe_connected_accounts_remove", this.state.data).then(response => {
      if (response.data.success) {
        console.log(response.data);
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
    setTimeout(function(){
      window.location.reload(); 
    },1000);

  };

  handleStripeChange = ({ currentTarget: input }) => {
    console.log(input.value.length);

    input.value.length > 5 ? this.setState({ updateButtonDisable: false }) : this.setState({ updateButtonDisable: true });
      
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    const { stripeData, loading } = this.state;
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <HostProfileSideBar />
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                <form>
                  {loading ? (
                    "Loading.."
                  ) : (

                    <div className="panel">
                      <div className="panel-heading">Stripe Connect </div>
                      <div className="panel-body account">
                        <div className="media user-profile-sec">
                          <div className="media-body">
                            <h4>
                                As a Host, you may charge users to book your property, therefore, you are required to connect your stripe account so our system can automatically transfer your payments into your stripe account. Stripe will then transfer your funds into your bank account.
                            </h4>
                            <br></br>
                            <p><b>Account Status: {stripeData.account_status == 0 ? 'Not connected' : stripeData.account_status == 2 ? 'Verified' : 'Pending'}</b></p>
                            <p><b>Email Address: {stripeData.stripe_connected_account_email}</b></p>
                            <p><b>Is Connected: {stripeData.stripe_connected_account_email ? 'Yes' : 'No'}</b></p>
                          </div>
                        </div>
                        {stripeData.stripe_connected_account_email == '' ? 
                        <ProfileInput
                            label="Email Id"
                            type="test"
                            placeholder="Enter your stripe email address"
                            id="stripe_email"
                            name="stripe_email"
                            value={stripeData.stripe_email}
                            onChange={this.handleStripeChange}
                        />
                        : ''}
                        <div className="row">
                        {stripeData.stripe_connected_account_email == '' ? 
                          <div className="col-md-6 col-xl-4 col-lg-4 offset-3 resp-mrg-btm-xs">
                            <button
                              className="pink-btn btn-block"
                              type="submit"
                              disabled={this.state.updateButtonDisable}
                              onClick={(event) =>
                                this.handleSubmit(event)
                              }
                            >
                              {this.state.updateLoadingContent != null
                                ? this.state.updateLoadingContent
                                : "Update"}
                            </button>
                          </div>
                          : ''}
                          {stripeData.account_status == 1 ? 
                            <div className="col-md-8 col-xl-4 col-lg-4">
                              <a
                                className="pink-btn btn-block"
                                href={stripeData.express_connect_url ? stripeData.express_connect_url : '#'}
                                target={stripeData.express_connect_url ? "_blank" : ''}
                              >
                                {this.state.loadingContent != null
                                  ? this.state.loadingContent
                                  : "Click me to connect"}
                              </a>
                            </div>
                          : stripeData.account_status == 2 ? 
                          <>
                          <div className="col-md-8 col-xl-4 col-lg-4">
                            <button
                              className="pink-btn btn-block"
                              type="submit"
                              onClick={(event) =>
                                this.handleRemove(event)
                              }
                            >
                              {this.state.loadingContent != null
                                ? this.state.loadingContent
                                : "Remove"}
                            </button>
                          </div>
                          <div className="col-md-8 col-xl-4 col-lg-4">
                            <a
                                className="pink-btn btn-block"
                                href={stripeData.login_url ? stripeData.login_url : '#'}
                                target={stripeData.login_url ? "_blank" : ''}
                              >
                                View
                              </a>
                          </div></>

                          : ''
                          }
                          
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostStripeConnect);
