import React, { Component } from "react";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { withToastManager } from "react-toast-notifications";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
const $ = window.$;

class EmailVerification extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        inputData: {},
        loadingContent: null, 
        buttonDisable: false,
    };


    componentDidMount() {

        const inputData = {
            id: this.props.match.params.id,
            verification_code: this.props.match.params.verification_code,
            user_type: this.props.match.params.user_type,
        };

        api.postMethod("email_verification",inputData).then((response) => {

            if (response.data.success === true) {

                ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                );

                if(response.data.data.user_type == 'provider') {
                    this.props.history.push("/host/login");
                } else {
                    this.props.history.push("/");
                    $("#login").modal("show");
                }
                
            } else {
                ToastDemo(
                    this.props.toastManager,
                    response.data.error,
                    "error"
                );

                this.props.history.push("/");
            }
        });


    }

   
    render() {
    const { inputData } = this.state;
    return (
        <div className="page-content">
            <div className="prov-login">
                <h1 className="section-head">
                    Welcome to {configuration.get("configData.site_name")}
                </h1>
            </div>
            <div className="header-height" />
        </div>
    );
  }
}

export default withToastManager(EmailVerification);
