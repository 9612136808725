import React, { Component } from "react";
import api from "../../Environment";

class Logout extends Component {
  componentDidMount() {
    api
      .postMethod("logout")
      .then((response) => {
        if (response.data.success) {
        }
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("user_picture");
    localStorage.removeItem("username");
    localStorage.removeItem("is_frontdesk");
    localStorage.removeItem("booking_info");
    localStorage.removeItem("pricecal_input");
    localStorage.removeItem("login_by");
    localStorage.removeItem("socket");
    window.location = "/";
  }
  render() {
    return null;
  }
}

export default Logout;
