import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import Helper from "../../Helper/Helper";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon
  } from "react-share";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import { Helmet } from "react-helmet";
import { ShareButton, ShareButtonCircle } from "react-custom-share";
import { FaFacebook,FaTwitter,FaLinkedin,FaWhatsapp } from 'react-icons/fa';

const $ = window.$;

const gallery_options = {
  slug: 'gallery',
  startIndex: 0,
};

class TripBanner extends Helper {

    state = {
        wishlistData: this.props.basicDetails.wishlist_status,
        loading: true,
        gallery_items:[],
    };

    componentDidMount() {
      let gallery = [];

      $(this.props.basicDetails.gallery).each(function(index,value){
        let items = {
          src:value.picture,
          thumb:value.picture,
          caption:value.caption,
        }
        gallery.push(items);
      });
      console.log(this.props.basicDetails.gallery);
      this.setState({
        gallery_items : gallery,
      });
      setTimeout(() => {
        this.startFancyboxFromUrl();
      }, 1000);

      setTimeout(() => {
        if(this.props.isModalOpen) {
          Fancybox.show(this.state.gallery_items,gallery_options);
        }
      }, 2000);
      
    }

    startFancyboxFromUrl() {
      console.log(this.state.gallery_items);
      if (!Fancybox.getInstance()) {
        const { hash, slug, index } = Fancybox.Plugins.Hash.getParsedURL();
    
        if (hash && slug === gallery_options.slug) {
          gallery_options.startIndex = index - 1;
    
          Fancybox.show(this.state.gallery_items, gallery_options);
        }
      }
    }
    
    
    favourite = (event, data) => {
        event.preventDefault();
        if (this.props.status == "host") {
            ToastDemo(
                this.props.toastManager,
                "You can't add to wishlist, login as User",
                "error"
            );
        } else {
            let addWishlist = {
                host_id: data.host_id,
                clear_all_status: 0
            };
            api.postMethod("wishlist_operations", addWishlist).then(
                response => {
                    if (response.data.success) {
                      console.log(response.data.data.wishlist_status);
                        this.setState({
                            wishlistData: response.data.data.wishlist_status,
                            loading: false
                        });
                        console.log(this.state.wishlistData);
                        ToastDemo(
                            this.props.toastManager,
                            response.data.message,
                            "success"
                        );
                    } else {
                      if(response.data.error_code == 1002){
                        return $("#login").modal("show");
                      }
                        const check = this.checkLoginUser(response.data);
                        if (check) {
                            ToastDemo(
                                this.props.toastManager,
                                "Please login to save the details",
                                "error"
                            );
                        } else {
                            ToastDemo(
                                this.props.toastManager,
                                response.data.error,
                                "error"
                            );
                        }
                    }
                }
            );
        }
    };

    handleGalleryModal = event => {
      event.preventDefault();
      Fancybox.show(this.state.gallery_items,gallery_options);
    }

    render() {

        return (
            <div className="singlehome-img-sec site-content">
              <Helmet>
                <title>{this.props.metadata ? this.props.metadata.title: this.props.basicDetails.host_name}</title>
                <meta name="keywords" content={this.props.metadata ? this.props.metadata.keywords : ''} />
                <meta
                  name="description"
                  content={this.props.metadata ? this.props.metadata.description : this.props.basicDetails.host_description}
                />
                <meta
                  property="og:title"
                  content={this.props.metadata ? this.props.metadata.og_title : this.props.basicDetails.host_name}
                />
                <meta
                  property="og:image"
                  content={this.props.metadata ? this.props.metadata.og_image : this.props.basicDetails.host_picture}
                />
              </Helmet>
                <img
                  src={this.props.basicDetails.host_picture}
                  alt={this.props.basicDetails.host_name}
                  className="homes-img br-0"
                />
                <div className="top-right">
                  <Link
                      to="#"
                      className="white-btn btn-small m-2 save-rht"
                      onClick={e =>
                          this.favourite(e, this.props.basicDetails)
                      }
                  >
                      {this.state.wishlistData == 1 ? (
                          <div>
                              <i className="fa fa-check" />
                              &nbsp; saved
                          </div>
                      ) : (
                          <div>
                              <i className="far fa-heart" />
                              &nbsp; save
                          </div>
                      )}
                  </Link>
                  <div className="social-flex">
                    <div className="col-md-3 border-right-1 resp-width-1 resp-width-5 flex-box-content">
                        <div className="text-center social-link"
                        style={{
                          padding: "18px",
                        }}>
                          <WhatsappShareButton
                            url={this.props.basicDetails.share_link}
                            title={this.props.metadata ? this.props.metadata.description : this.props.basicDetails.host_description}
                            separator=":: "
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={48} round />
                          </WhatsappShareButton>
                        </div>
                      </div>

                      <div className="col-md-3 border-right-1 resp-width-1 resp-width-5 flex-box-content">
                        <div className="text-center social-link">
                          {/* <TwitterShareButton
                            url={this.props.basicDetails.share_link}
                            title={this.props.basicDetails.share_message}
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton> */}
                          <ShareButtonCircle 
                            url={this.props.basicDetails.share_link}
                            network={"Twitter"}
                            text={this.props.metadata ? this.props.metadata.title: this.props.basicDetails.host_name}
                            longtext={this.props.metadata ? this.props.metadata.description : this.props.basicDetails.host_description}
                          >
                            <FaTwitter />
                          </ShareButtonCircle>
                        </div>
                      </div>

                      <div className="col-md-3 border-right-1 resp-width-1 resp-width-5 flex-box-content">
                        <div className="text-center social-link">
                          {/* <FacebookShareButton
                            url={this.props.basicDetails.share_link}
                            title={this.props.basicDetails.share_message}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton> */}
                          <ShareButtonCircle 
                            url={this.props.basicDetails.share_link}
                            network={"Facebook"}
                            text={this.props.metadata ? this.props.metadata.title: this.props.basicDetails.host_name}
                            longtext={this.props.metadata ? this.props.metadata.description : this.props.basicDetails.host_description}
                          >
                            <FaFacebook />
                          </ShareButtonCircle>

                        </div>
                      </div>

                      <div className="col-md-3 border-right-1 resp-width-1 resp-width-5 flex-box-content">
                        <div className="text-center social-link">
                          {/* <LinkedinShareButton
                            url={this.props.basicDetails.share_link}
                            title={this.props.basicDetails.share_message}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton> */}
                          <ShareButtonCircle 
                            url={this.props.basicDetails.share_link}
                            network={"Linkedin"}
                            text={this.props.metadata ? this.props.metadata.title: this.props.basicDetails.host_name}
                            longtext={this.props.metadata ? this.props.metadata.description : this.props.basicDetails.host_description}
                          >
                            <FaLinkedin />
                          </ShareButtonCircle>
                        </div>
                      </div>


                    </div>



                </div>
                <div className="bottom-left">

                    <button
                        type="button"
                        className="white-btn btn-small m-2"
                        id="dynamic-gallery-demo"
                        onClick={e =>this.handleGalleryModal(e)}
                    >
                        view photos
                        <h6>{this.props.basicDetails.gallery.length} more photos</h6>
                    </button>
                    
                </div>
            </div>
        );
    }
}

export default withToastManager(TripBanner);
