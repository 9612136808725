import React, { useState,useEffect } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import Loader from "../../Helper/Loader";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import Compressor from 'compressorjs';
import CropImageModal from "./CropImageModal";

const gallery_options = {
  slug: 'gallery',
  startIndex: 0,
};
const $ = window.$;

const HostGallery = (props) => {

  const [state, setState] = useState({
    loading: true,
    gallery: null,
    host_details: null,
    files: [],
    loadingContent: null,
    buttonDisable: false,
    hostId: null,
    gallery_items:[],
  });

  const [inputData, setInputData] = useState({
    host_id: props.match.params.id,
  });

  const [formData, setFormData] = useState({
    host_id: props.match.params.id,
  });

  useEffect(() => {
    setState({
      ...state,
      hostId: props.match.params.id
    });
    getGalleryApiCall(props.match.params.id);
  }, []);

  const getGalleryApiCall = hostId => {
    api
      .postMethod("hosts_galleries", { host_id: hostId })
      .then(response => {
        if (response.data.success === true) {
          let gallery = [];
          $(response.data.data.galleries).each(function(index,value){
            let items = {
              src:value.picture,
              thumb:value.picture,
              caption:value.caption,
            }
            gallery.push(items);
          });

          setState({
            ...state,
            loading: false,
            gallery: response.data.data.galleries,
            host_details: response.data.data.host_details,
            gallery_items : gallery,
          });

        } else {
          if(response.data.error_code == 1004){
            props.toggleLoginModal(false);
          }
          ToastDemo(props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  const uploadImages = event => {
    event.preventDefault();
    setState({
      ...state,
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });

    api
      .postMethod("hosts_upload_files",formData)
      .then(response => {
        if (response.data.success === true) {
          ToastDemo(props.toastManager, response.data.message, "success");
          setState({
            ...state,
            loadingContent: null,
            buttonDisable: false
          });
          getGalleryApiCall(props.match.params.id);
        } else {
          if(response.data.error_code == 1004){
            props.toggleLoginModal(false);
          }
          ToastDemo(props.toastManager, response.data.error, "error");
          setState({
            ...state,
            loadingContent: null,
            buttonDisable: false
          });
        }
      })
      .catch(function(error) {});
  };
  const deleteImage = (event, host_gallery_id) => {
    event.preventDefault();
    api
      .postMethod("hosts_remove_files", {
        host_id: props.match.params.id,
        host_gallery_id: host_gallery_id
      })
      .then(response => {
        if (response.data.success === true) {
          ToastDemo(props.toastManager, response.data.message, "success");
          getGalleryApiCall(props.match.params.id);
        } else {
          if(response.data.error_code == 1004){
            props.toggleLoginModal(false);
          }
          ToastDemo(props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  const defaultImage = (event, host_gallery_id) => {
    event.preventDefault();
    api
      .postMethod("hosts_file_default", {
        host_id: props.match.params.id,
        host_gallery_id: host_gallery_id
      })
      .then(response => {
        if (response.data.success === true) {
          ToastDemo(props.toastManager, response.data.message, "success");
          getGalleryApiCall(props.match.params.id);
        } else {
          if(response.data.error_code == 1004){
            props.toggleLoginModal(false);
          }
          ToastDemo(props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  const handlePosition = ({ currentTarget: input }) => { 
    setInputData({
        ...inputData,
        [input.name]:input.value,
      }
    );
  }

  const [image, setImage] = useState({
    picture: "",
    cover: "",
  });


  const positionUpdate = (event) => {
    event.preventDefault();
    api
      .postMethod("hosts_galleries_position_update",inputData)
      .then(response => {
        if (response.data.success === true) {
          ToastDemo(props.toastManager, response.data.message, "success");
          setState({
            ...state,
            loadingContent: null,
            buttonDisable: false
          });
          getGalleryApiCall(props.match.params.id);
        } else {
          if(response.data.error_code == 1004){
            props.toggleLoginModal(false);
          }
          ToastDemo(props.toastManager, response.data.error, "error");
          setState({
            ...state,
            loadingContent: null,
            buttonDisable: false
          });
        }
      })
      .catch(function(error) {});

  };

  const closeCropModal = () => {
    setCropModalFlag({
      flag : false,
      image : '',
      width : '',
      height: '',
      shape : '',
      cropedProfileImage : '',
      cropedCoverImage : '',
      type : '',
      fileType : '',
      fileName : ''
    })
  }

  const handleGalleryModal = event => {
    event.preventDefault();
    Fancybox.show(state.gallery_items,gallery_options);
  }

  const handleChange = ({ currentTarget: input }) => {
    if (input.type === "text") {
      setFormData({
        ...formData,
        [input.name]:input.value,
      });
    }

    if (input.type === "file") {
      setFormData({
        ...formData,
        [input.name]:input.files[0],
      });
    }
  };
  const [cropModalFlag , setCropModalFlag] = useState({
    flag : false,
    image : '',
    width : '',
    height: '',
    shape : '',
    type : '',
    fileType : '',
    fileName : ''
  })

  const handleCoverChangeImage = (event) => {
    // if (event.currentTarget.type === "file") {
    //   const currentfileType = event.currentTarget.files[0].type
    //   const currentfileName = event.currentTarget.files[0].name
    //   let coverReader = new FileReader();
    //   let coverFile = event.currentTarget.files[0];
    //   let imageFile = event.currentTarget.files[0];
    //   let currentInputName = event.currentTarget.name;
    //   var options = {
    //     maxSizeMB: 1,
    //     maxWidthOrHeight: 1920,
    //     useWebWorker: true
    //   }

    //   new Compressor(imageFile, {
    //     quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //     success: (compressedFile) => {
    //       console.log(compressedFile);
    //       var covercroppedReader = new FileReader();
    //       covercroppedReader.readAsDataURL(compressedFile);
    //       covercroppedReader.onloadend = function() {
    //         var coverbase64 = covercroppedReader.result;
    //         setCropModalFlag({...cropModalFlag , 
    //           image : coverbase64 ,  
    //           width : 75 , height : 35  ,
    //           shape : "rect" , flag : true , 
    //           type: "picture",fileType : currentfileType , 
    //           fileName : currentfileName 
    //         })
    //       }
    //     },
    //   });

    //   if (coverFile) {
    //     coverReader.readAsDataURL(coverFile);
    //   }
    // }

    if (event.currentTarget.type === "file") {
      setFormData({
        ...formData,
        [event.currentTarget.name]:event.currentTarget.files[0],
      });
    }
    let reader = new FileReader();
    let file = event.currentTarget.files[0];

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="main">
      <div className="site-content">
          <div className="">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <button
                  type="button"
                  className="pink-btn btn-small m-2 float-right"
                  id="dynamic-gallery-demo"
                  onClick={e =>handleGalleryModal(e)}
                >
                  view photos
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <form onSubmit={uploadImages}>
                  <div className="panel">
                    <div className="panel-heading">
                      Add photos to your listing
                      <Link className="float-right" to={"/host/listing"}>
                        <i className="fas fa-chevron-left" /> Back
                      </Link>
                    </div>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 resp-mrg-btm-xs">
                          <div className="media user-profile-sec">
                            <div className="media-body">
                              <h2>
                                {state.loading
                                  ? "Loading..."
                                  : state.host_details
                                  ? state.host_details.host_name
                                  : ""}
                              </h2>
                              <h4>
                                Quality photos will drive more booking. You can start with one and add more after you publish.
                              </h4>
                              
                              <div
                                className="form-group mt-3"
                                key="description"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Image Caption"
                                  name="caption"
                                  onChange={handleChange}
                                />
                              </div>

                              <input
                                type="file"
                                name="file"
                                onChange={handleCoverChangeImage}
                                multiple
                                className="grey-outline-btn bold-cls w-100 text-center bottom"
                              />
                            </div>
                          </div>

                          <button
                            className="pink-btn btn-block mb-3"
                            type="submit"
                            disabled={state.buttonDisable}
                          >
                            {state.loadingContent != null
                              ? state.loadingContent
                              : "Submit"}
                          </button>
                          <br></br>
                          <p>
                            a. Set a meaningful image caption,
                            image captions will be displayed
                            below each photo on the listing.
                          </p>
                          <p>
                            b. After uploading the photos , you
                            can set the order in which the photos
                            needs to be displayed on the listing by
                            setting the position number for each
                            photos.
                          </p>
                          <p>
                            c. The photo with the position
                            numbered as 1 will be the 1st image
                            in the thumbnail followed by the
                            photos with position number 2 and 3.
                          </p>
                          <p>
                            d. The photo with the green
                            checkmark will be the cover photo in
                            the listing page. You can change your
                            cover photo anytime by clicking the
                            red checkmark below each image.
                          </p>
                        </div>

                        <div className="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                          <p>Image Preview</p>
                          <div className="row">
                            {state.loading
                              ? "Loading..."
                              : state.gallery.map((image,index) => (
                                  <div className="col-md-4">
                                    <div className="wishlist-img-sec">
                                      {image.is_default ? 
                                        <div className="gallery-check-box">
                                          <i class="fas fa-check"></i>
                                        </div>
                                      : 
                                        <div className="default-image-check">
                                          <Link
                                            to={"#"}
                                            onClick={event =>
                                              defaultImage(
                                                event,
                                                image.host_gallery_id
                                              )
                                            }
                                          >
                                            <i class="fas fa-check-circle pink-clr"></i>
                                          </Link>
                                        </div>
                                      }
                                      <img
                                        src={image.picture}
                                        alt="Users"
                                        className="gallery-img"
                                      />
                                       <div className="wishlist-icon-sec">
                                          <div className="wishlist-icon">
                                            <Link
                                              to={"#"}
                                              onClick={event =>
                                                window.confirm("Are you sure?") &&
                                                deleteImage(
                                                  event,
                                                  image.host_gallery_id
                                                )
                                              }
                                            >
                                              <i className="fas fa-trash pink-clr" />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="gallery-input-postion-sec">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Caption"
                                          name={`host_gallery[${image.host_gallery_id}][caption]`}
                                          onChange={(event) => handlePosition(event,image)}
                                          defaultValue={image.caption}
                                        />
                                      </div> 
                                      <div className="gallery-input-postion-sec">
                                        <input
                                          type="number"
                                          min='1'
                                          className="form-control"
                                          placeholder="Position"
                                          name={`host_gallery[${image.host_gallery_id}][position]`}
                                          onChange={(event) => handlePosition(event,image)}
                                          defaultValue={image.position}
                                        />
                                      </div>    
                                  </div>
                                ))}                            
                          </div>
                          <br></br>
                          <div className="row">
                            {!state.loading && state.gallery.length > 1 ? 
                              <button
                                onClick={positionUpdate}
                                className="pink-btn bottom1"
                              >
                                Update
                              </button>
                            : ''}
                          </div>
                          
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      <CropImageModal 
        image={cropModalFlag.image} 
        modalFlag={cropModalFlag.flag} 
        cropModalFlag={cropModalFlag} 
        closeModal={closeCropModal} 
        setImage={setImage} 
        imageState={image} 
        setFormData={setFormData} 
        formData={formData}/>

    </div>
  );
};

export default withToastManager(HostGallery);
