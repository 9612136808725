import React, { Component } from "react";
import Loader from "../../Helper/Loader";
import api from "../../../HostEnvironment";
import ToastDemo from "../../Helper/toaster";
import Helper from "../../Helper/Helper";
import TripBookingForm from "./tripBookingForm";
import TripPackagePricing from "../../User/TripDetails/TripPackagePricing";
import TripAddonServices from "../../User/TripDetails/tripAddonServices";
import TripShowAllAmen from "../../User/TripDetails/tripShowAllAmen";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import { withToastManager } from "react-toast-notifications";

const $ = window.$;

class hostDetails  extends Helper {

  state = {
    loading: true,
    singleTripDetails: [],
    addonServicesData: null,
    loadingAddonService: true,
    loadingAddonService: true,
    addOnServiceModelData: null,
    loadingAddOnServiceModelData: true,
    addOnSerModelAddButtonDisable: false,
    addOnSerModelAddLoadingContent: null,
    priceCalInput: {
        adults: 0,
        children: 0,
        infants: 0,
        checkin: null,
        checkout: "",
        host_id: null,
        age_special: 0,
    },
    min_guests: 1,
    max_guests: 1,
    timeSlotLoading: true,
    timeSlotData: null,
    apiResponse: null,
    promoCodeInputData: {},
    promoCodeApiResponse: null,
    loadingPromoCode: true,
    data:{},
    buttonDisableBoBookingForm: false,
    buttonLoadingContentBookingForm: null,
  };

  componentDidMount() {
    const formData = {
      host_id: this.props.match.params.id,
    };
    this.singleTripViewApiCall(formData);
  }
  
  singleTripViewApiCall(formData) {
    api.postMethod("hosts_view", formData).then((response) => {
      if (response.data.success === true) {
        this.setState({
          singleTripDetails: response.data.data,
          loading: false,
        });
      } else {
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
        ToastDemo(
            this.props.toastManager,
            response.data.error,
            "error"
        );
        this.props.history.goBack();
      }
    });
  }

  setHostId = () => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[
      "adults"
    ] = this.state.singleTripDetails.basic_details.min_guests;
    priceCalInput[
      "host_id"
    ] = this.state.singleTripDetails.basic_details.host_id;
    this.setState({
      priceCalInput,
      min_guests: this.state.singleTripDetails.basic_details.min_guests,
      max_guests: this.state.singleTripDetails.basic_details.max_guests,
    });
  };

  getPromoCode = ({ currentTarget: input }) => {
    const promoCodeInputData = { ...this.state.promoCodeInputData };
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[input.name] = input.value;
    promoCodeInputData[input.name] = input.value;
    this.setState({ promoCodeInputData });
    this.setState({ priceCalInput });
  };

  removePromoCode = (event) => {
    event.preventDefault();
    this.setState({
      promoCodeInputData: {},
      promoCodeApiResponse: null,
      loadingPromoCode: true,
      buttonLoadingContentBookingForm: null,
      buttonDisableBoBookingForm: false,
      loadingBookingForm: false,
    });
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["promo_code"] = "";
    this.setState({ priceCalInput });
  };

  selectPackage = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[input.name] = input.value;
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 500);
  };

  checkPromoCode = (event) => {
    console.log(this.state.apiResponse);
    event.preventDefault();
    const promoCodeInputData = { ...this.state.promoCodeInputData };
    promoCodeInputData[
      "host_id"
    ] = this.state.singleTripDetails.basic_details.host_id;
    promoCodeInputData["amount"] = this.state.apiResponse.actual_total_formatted.replace(
    "$",
    ""
    );
    this.setState({ promoCodeInputData });
    setTimeout(() => {
      this.promoCodeAPICall();
    }, 1000);
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
    });
  };

  promoCodeAPICall = () => {
    api
      .postMethod("apply_promo_code", this.state.promoCodeInputData)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loadingBookingForm: false,
            promoCodeApiResponse: response.data.data,
            loadingPromoCode: false,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
          });
          this.priceCalcultor();
          ToastDemo(
            this.props.toastManager,
            "Promo Code valid, Applied successfully",
            "success"
          );
        } else {
          const priceCalInput = { ...this.state.priceCalInput };
          priceCalInput["promo_code"] = "";
          this.setState({ priceCalInput });
          this.setState({
            promoCodeApiResponse: null,
            loadingPromoCode: true,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            loadingBookingForm: false,
          });
          ToastDemo(this.props.toastManager, response.data.error, "error");
          return false;
        }
      });
  };

  priceCalcultor = () => {
    const priceCalInput = this.state.priceCalInput;
    if (
      priceCalInput.adults !== 0 &&
      priceCalInput.checkin != null &&
      priceCalInput.checkout != "NaN-aN-aN"
    ) {
      this.priceCal();
      this.setState({
        buttonDisableBoBookingForm: true,
        buttonLoadingContentBookingForm: "Loading... Please wait",
      });
    }
  };

  priceCal = () => {
    api
      .postMethod("host_price_calculator", this.state.priceCalInput)
      .then((response) => {
       
        if (response.data.success) {
          const priceCalInput = { ...this.state.priceCalInput };

          priceCalInput["adults"] =
            priceCalInput["adults"] > response.data.data.min_guests
              ? priceCalInput["adults"]
              : response.data.data.min_guests;
          this.setState({
            priceCalInput,
            loadingBookingForm: false,
            total_days_price: response.data.data.total_days_price,
            apiResponse: response.data.data,
            apiCallStatus: true,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            min_guests: response.data.data.min_guests,
            max_guests: response.data.data.max_guests,
          });
        } else {
          this.setState({
            loadingBookingForm: true,
            total_days_price: null,
            apiResponse: null,
            apiCallStatus: false,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            toastContent: response.data.error,
            toastType: "error",
          });
          ToastDemo(this.props.toastManager, response.data.error, "error");
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          return false;
        }
      });
  };

  convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  onChangeDate = (title) => (...args) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = this.convert(args[0]);
    priceCalInput["checkout"] = this.convert(args[1]);
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);

    if (this.state.singleTripDetails.basic_details.booking_type == 2) {
      this.getTimeSlot({
        checkin: this.convert(args[0]),
        host_id: this.props.match.params.id,
      });
    }
    console.log("State", this.state);
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  bookingCreate = (event) => {

    event.preventDefault();
    if (!localStorage.getItem("hostLoginStatus")) {
      ToastDemo(
        this.props.toastManager,
        "Please login to continue",
        "error"
      );
    } else {
        let inputData;
        if (this.state.promoCodeApiResponse != null) {
            inputData = {
                ...this.state.priceCalInput,
                payment_mode: "card",
                total_guests: this.state.apiResponse.total_guests,
                description: this.state.description,
                promo_code: this.state.promoCodeInputData.promo_code,
                user_name: this.state.data.user_name,
                mobile: this.state.data.mobile,
                email: this.state.data.email,
            };
            } else {
            inputData = {
                ...this.state.priceCalInput,
                payment_mode: "card",
                total_guests: this.state.apiResponse.total_guests,
                description: this.state.description,
                user_name: this.state.data.user_name,
                mobile: this.state.data.mobile,
                email: this.state.data.email,
            };
        }

        api.postMethod("bookings_create", inputData).then((response) => {
        if (response.data.success) {
            ToastDemo(this.props.toastManager, "Booking successfull", "success");
            this.props.history.push("/host/booking-management");
        } else {
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            ToastDemo(this.props.toastManager, response.data.error, "error");
            if (
            response.data.error_code == 1004 ||
            response.data.error_code == 1002
            ) {
            this.props.history.push("/");
            ToastDemo(this.props.toastManager, "Please login to book", "error");
            }
        }
        });
    }
  };

  addonServiceCallAPI = (event) => {
    event.preventDefault();
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
      addOnSerModelAddButtonDisable: true,
      addOnSerModelAddLoadingContent: "Loading...",
    });
    this.priceCalcultor();
    setTimeout(() => {
      this.setState({
        // loadingBookingForm: false,
        buttonDisableBoBookingForm: false,
        buttonLoadingContentBookingForm: null,
        addOnSerModelAddButtonDisable: false,
        addOnSerModelAddLoadingContent: null,
      });
      ToastDemo(this.props.toastManager, "Service Added..", "success");
      $("#AddOnServiceAdd").modal("hide");
    }, 1000);
  };

  timeSlotSelected = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_time_slot_id"] = input.value;
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  getTimeSlot = (inputData) => {
    api
      .postMethod("hosts_time_slots_prebooking", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            timeSlotLoading: false,
            timeSlotData: response.data.data,
          });
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  getAddonServices = () => {
    api
      .postMethod("addon_services", {
        host_id: this.state.singleTripDetails.basic_details.host_id,
      })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            addonServicesData: response.data.data,
            loadingAddonService: false,
          });
          const priceCalInput = { ...this.state.priceCalInput };
          if (!this.state.loadingAddonService) {
            if (this.state.addonServicesData.length > 0) {
              this.state.addonServicesData.map((service) => {
                if (service.is_required == 1) {
                  let unitKey =
                    "host_addon_services[" +
                    service.host_addon_service_id +
                    "][no_of_units]";
                  priceCalInput[unitKey] = service.min_value;
                  this.setState({ priceCalInput });
                }
              });
            }
          }
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
        }
      });
  };

  increament = (value) => {
    // console.log("Increamenting", value);
    const priceCalInput = { ...this.state.priceCalInput };

    $("#guest-mobile-btn").dropdown("toggle");

    if (value === "adults") {
      let adultsCount =
        this.state.priceCalInput.adults >= this.state.min_guests
          ? this.state.priceCalInput.adults + 1
          : this.state.min_guests;
      if (adultsCount >= this.state.max_guests) {
        adultsCount = this.state.max_guests;
      }
      priceCalInput["adults"] = adultsCount;
    }
    if (value === "children") {
      let childrenCount =
        this.state.priceCalInput.children >= 0
          ? this.state.priceCalInput.children + 1
          : 0;
      priceCalInput["children"] = childrenCount;
    }
    if (value === "infants") {
      let infantsCount =
        this.state.priceCalInput.infants >= 0
          ? this.state.priceCalInput.infants + 1
          : 0;
      priceCalInput["infants"] = infantsCount;
    }
    if (value === "age_special") {
      let infantsCount =
        this.state.priceCalInput.age_special >= 0
          ? this.state.priceCalInput.age_special + 1
          : 0;
      priceCalInput["age_special"] = infantsCount;
    }
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  decreament = (value) => {
    $("#guest-mobile-btn").dropdown("toggle");
    const priceCalInput = { ...this.state.priceCalInput };
    if (value === "adults") {
      let adultsCount =
        this.state.priceCalInput.adults > this.state.min_guests
          ? this.state.priceCalInput.adults - 1
          : this.state.min_guests;
      priceCalInput["adults"] = adultsCount;
    }
    if (value === "children") {
      let childrenCount =
        this.state.priceCalInput.children > 0
          ? this.state.priceCalInput.children - 1
          : 0;
      priceCalInput["children"] = childrenCount;
    }
    if (value === "infants") {
      let infantsCount =
        this.state.priceCalInput.infants > 0
          ? this.state.priceCalInput.infants - 1
          : 0;
      priceCalInput["infants"] = infantsCount;
    }
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  handleAddonService = (event, service) => {
    this.setState({
      addOnServiceModelData: service,
      loadingAddOnServiceModelData: false,
    });
    $("#AddOnServiceAdd").modal("show");
  };

  render() {
    let load = new Loader("");
    const { loading,singleTripDetails } = this.state;
    
    return  loading ? (
        load.bannerLoader()
      ) : (
        <div>

            <div className="main">
                <div className="site-content">
                    <div className="top-bottom-spacing">
                        <div className="row">
                            <div className="col-xl-7 col-lg-10 col-md-10 auto-margin">
                                <div id="overview">
                                    <div className="media">
                                        <div className="media-body mr-3">
                                            <a href="#">
                                            <p className="red-text txt-overflow">
                                                {singleTripDetails.basic_details.location}
                                            </p>
                                            </a>
                                            <h1 className="category-section-head">
                                            {singleTripDetails.basic_details.host_name}
                                            </h1>
                                            <h4 className="captalize section-subhead">
                                            {singleTripDetails.basic_details.host_location}
                                            </h4>
                                            <h5 className="captalize section-subhead">
                                            {singleTripDetails.basic_details
                                                .is_automatic_booking == 0 ? (
                                                <span className="text-strike">Rapid Booking</span>
                                            ) : (
                                                singleTripDetails.basic_details
                                                .is_automatic_booking_text
                                            )}
                                            </h5>
                                        </div>
                                        <div>
                                            <Link
                                            to={`/provider-profile/${singleTripDetails.provider_details.provider_id}`}
                                            >
                                            <img
                                                src={singleTripDetails.provider_details.picture}
                                                alt="rentcubo"
                                                className="mt-3 rounded-circle review-img"
                                            />
                                            </Link>
                                            <p className="text-center top3 mb-0">
                                            <a href="#" className="other-proname">
                                                {singleTripDetails.provider_details.provider_name}
                                            </a>
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="home-requirements">
                                    {singleTripDetails.basic_details.section_4.length > 0
                                        ? singleTripDetails.basic_details.section_4.map(
                                            (details) => (
                                            <li className="">
                                                <img
                                                src={details.picture}
                                                alt="rentcubo"
                                                style={{ width: "20px", height: "auto" }}
                                                />
                                                &nbsp;{details.title}
                                            </li>
                                            )
                                        )
                                        : ""}
                                    </ul>

                                    <h4 className="single-cat-text">
                                        {renderHTML(
                                            singleTripDetails.basic_details.host_description
                                        )}
                                    </h4>
                                    {localStorage.getItem("userLoginStatus") ? (
                                        <Link
                                            to={"#"}
                                        
                                            onClick={this.handleContactHost}
                                        >
                                            <h4 className="collapse-head">contact hosts</h4>
                                        </Link>
                                    ) : (
                                    ""
                                    )}

                                    {singleTripDetails.basic_details.pricing_type == 3 ? (
                                        <TripPackagePricing
                                            singleTripDetails={singleTripDetails}
                                            loading={loading}
                                            selectPackage={this.selectPackage}
                                        />
                                    ) : null}
                                    {singleTripDetails.amenties.data.length > 0 ? (
                                    <>
                                        <p className="overview-line" />

                                        <h4 className="single-cat-text medium-cls ">
                                        {singleTripDetails.amenties.title}
                                        </h4>
                                        <ul className="amenties-list">
                                        <div>
                                            {singleTripDetails.amenties.data.map(
                                            (amenity, index) =>
                                                index <= 3 ? (
                                                <li className="">
                                                    <img
                                                    src={amenity.picture}
                                                    className="amentieimg"
                                                    />
                                                    &nbsp;{amenity.user_question}
                                                    <br />
                                                    <p className="text-muted amentie-answer">
                                                    {amenity.answers}
                                                    </p>
                                                    <br />
                                                    {amenity.description == "" ? (
                                                    ""
                                                    ) : (
                                                    <h6> NOTE: {amenity.description}</h6>
                                                    )}
                                                </li>
                                                ) : (
                                                ""
                                                )
                                            )}
                                            <div className="clearfix" />

                                            {singleTripDetails.amenties.data.length > 3 ? (
                                            <>
                                                <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#amenities"
                                                >
                                                <h4 className="amenities-head">
                                                    show all amenities
                                                </h4>
                                                </a>

                                                <TripShowAllAmen
                                                amenties={singleTripDetails.amenties}
                                                />
                                            </>
                                            ) : (
                                            ""
                                            )}
                                        </div>
                                        </ul>
                                    </>
                                    ) : (
                                    ""
                                    )}

                                    <TripAddonServices
                                        getAddonServices={this.getAddonServices}
                                        handleAddonService={this.handleAddonService}
                                        addonServicesData={this.state.addonServicesData}
                                        loadingAddonService={this.state.loadingAddonService}
                                        priceCalInput={this.state.priceCalInput}
                                    />

                                    <p className="overview-line" />
                                    {singleTripDetails.policies.data.length > 0
                                    ? singleTripDetails.policies.data.map((homeDetails) => (
                                        <div>
                                            <h4 className="single-cat-text medium-cls">
                                            {homeDetails.title}
                                            </h4>
                                            <h4 className="rules-text">
                                            {renderHTML(homeDetails.description)}
                                            </h4>
                                            <p className="overview-line" />
                                        </div>
                                        ))
                                    : ""}
                                 
                                    <p className="overview-line" />
                                </div>
                                
                            </div>
                            <TripBookingForm
                                basicDetails={singleTripDetails.basic_details}
                                status="user"
                                setHostId={this.setHostId}
                                getPromoCode={this.getPromoCode}
                                checkPromoCode={this.checkPromoCode}
                                promoCodeAPICall={this.promoCodeAPICall}
                                removePromoCode={this.removePromoCode}
                                priceCalcultor={this.priceCalcultor}
                                bookingCreate={this.bookingCreate}
                                increament={this.increament}
                                decreament={this.decreament}
                                convert={this.convert}
                                onChangeDate={this.onChangeDate}
                                handleChange={this.handleChange}
                                addonServiceQuantity={this.addonServiceQuantity}
                                addonServiceCallAPI={this.addonServiceCallAPI}
                                loadingBookingForm={this.state.loadingBookingForm}
                                apiResponse={this.state.apiResponse}
                                apiCallStatus={this.state.apiCallStatus}
                                providerDetails={this.state.providerDetails}
                                buttonDisable={this.state.buttonDisableBookingForm}
                                buttonLoadingContent={
                                this.state.buttonLoadingContentBookingForm
                                }
                                singleTripDetails={this.state.singleTripDetails}
                                loadingAddonService={this.state.loadingAddonService}
                                addonServicesData={this.state.addonServicesData}
                                promoCodeInputData={this.state.promoCodeInputData}
                                promoCodeApiResponse={this.state.promoCodeApiResponse}
                                loadingPromoCode={this.state.loadingPromoCode}
                                providerDetails={singleTripDetails.provider_details}
                                priceCalInput={this.state.priceCalInput}
                                location={this.props.location}
                                history={this.props.history}
                                timeSlotLoading={this.state.timeSlotLoading}
                                timeSlotData={this.state.timeSlotData}
                                timeSlotSelected={this.timeSlotSelected}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default withToastManager(hostDetails);
