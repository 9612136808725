import React, { Component } from "react";
import ForgotPassword from "../Auth/ForgotPassword";
import InputField from "../Helper/inputfield";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
// import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import { DatePicker, Calendar } from "@y0c/react-datepicker";
import { Link } from "react-router-dom";
import SocialLogin from "./SocialLogin";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const $ = window.$;

const responseFacebook = (response) => {};

class Login extends Helper {
    state = {
        data: {
            email: "",
            password: "",
            device_type: "web",
            timezone: const_time_zone,
        },
        googleLoginInput: {
            social_unique_id: "",
            login_by: "",
            first_name: "",
            last_name: "",
            email: "",
            name: "",
            picture: "",
            device_type: "web",
            device_token: "123466",
            timezone: const_time_zone,
        },
        loadingContent: null,
        buttonDisable: false,
        loading:true,
    }; 

    handleResponseChange = ({ currentTarget: input }) => {
        const googleLoginInput = { ...this.state.googleLoginInput };
        googleLoginInput[input.name] = input.value;
        this.setState({ googleLoginInput });
    };

    handleMobileResponse = (value) => {
        const googleLoginInput = { ...this.state.googleLoginInput };
        googleLoginInput['mobile'] = value;
        this.setState({ googleLoginInput });
    };

    responseGoogle = (response) => {
        this.setState({
            googleLoginInput: '',
            loading: true,
        });
        const path = this.props.location;
        $("#login").modal("hide");
        $('#homeHeader #collapsibleNavbar').removeClass("show");
        if (response.profileObj) {
            console.log(response.profileObj);
            const googleLoginInput = { ...this.state.googleLoginInput };
            googleLoginInput['social_unique_id'] = response.profileObj.googleId;
            googleLoginInput['login_by'] = "google";
            googleLoginInput['email'] =  response.profileObj.email;
            googleLoginInput['first_name'] = response.profileObj.givenName;
            googleLoginInput['last_name'] = response.profileObj.familyName;
            googleLoginInput['name'] = response.profileObj.name;
            googleLoginInput['picture'] = response.profileObj.imageUrl;
            googleLoginInput['timezone'] = const_time_zone;

            this.setState({
                googleLoginInput: googleLoginInput,
                loading: false,
            });

            api.postMethod("check_new_user", this.state.googleLoginInput)
            .then((response) => {
                if (response.data.success === true) {
                    if(response.data.data.is_new_user == 1) {
                        $("#social-login").modal("show");
                        $("#login").modal("hide"); 
                    } else {
                        this.setState({
                            loadingContent: "Loading... Please wait..",
                            buttonDisable: true,
                        });
                        const path = this.props.location;
                        $("#login").modal("hide"); 
                        api.postMethod("register", this.state.googleLoginInput)
                        .then((response) => {
                            if (response.data.success === true) {
                                localStorage.setItem(
                                    "userId",
                                    response.data.data.user_id
                                );
                                localStorage.setItem("socket", true);
                                localStorage.setItem("login_by", response.data.data.login_by);
                                localStorage.setItem(
                                    "accessToken",
                                    response.data.data.token
                                );
                                localStorage.setItem("userLoginStatus", true);
                                localStorage.setItem(
                                    "user_picture",
                                    response.data.data.picture
                                );
                                localStorage.setItem(
                                    "username",
                                    response.data.data.username
                                );
                
                                // window.location = path ? path.from.pathname : "/home";
                                ToastDemo(
                                    this.props.toastManager,
                                    response.data.message,
                                    "success"
                                );
                                this.setState({
                                    loadingContent: null,
                                    buttonDisable: false,
                                });
                                $("#login").modal("hide");
                                if (path) {
                                    // window.location = path.pathname;
                                    this.props.history.push(path.pathname);
                                } else {
                                    // window.location = "/home";
                                    this.props.history.push("/home");
                                }
                                this.setState({
                                    googleLoginInput: {},
                                    loading: true,
                                });
                            } else {
                                this.setState({
                                    loadingContent: null,
                                    buttonDisable: false,
                                });
                                ToastDemo(
                                    this.props.toastManager,
                                    response.data.error,
                                    "error"
                                );
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                loadingContent: null,
                                buttonDisable: false,
                            });
                        });

                    }
                } else {
                    
                }
            })
            .catch((error) => {
                this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                });
            });

            // $("#social-login").modal("show");
            // $("#login").modal("hide");

        }
    };

    getDate = (title) => (...args) => {
        console.log(title, args);
        console.log(args[1]);
        const data = { ...this.state.data };
        data["dob"] = args[1];
        this.setState({ data });
    };

    handleGoogleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loadingContent: "Loading... Please wait..",
            buttonDisable: true,
        });
        const path = this.props.location;
        $('#homeHeader #collapsibleNavbar').removeClass("show");
        $("#social-login").modal("hide");
        api.postMethod("register", this.state.googleLoginInput)
        .then((response) => {
            if (response.data.success === true) {
                localStorage.setItem(
                    "userId",
                    response.data.data.user_id
                );
                localStorage.setItem("socket", true);
                localStorage.setItem("login_by", response.data.data.login_by);
                localStorage.setItem(
                    "accessToken",
                    response.data.data.token
                );
                localStorage.setItem("userLoginStatus", true);
                localStorage.setItem(
                    "user_picture",
                    response.data.data.picture
                );
                localStorage.setItem(
                    "username",
                    response.data.data.username
                );

                // window.location = path ? path.from.pathname : "/home";
                ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                );
                this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                });
                $("#login").modal("hide");
                if (path) {
                    // window.location = path.pathname;
                    this.props.history.push(path.pathname);
                } else {
                    // window.location = "/home";
                    this.props.history.push("/home");
                }
                this.setState({
                    googleLoginInput: {},
                    loading: true,
                });
            } else {
                this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                });
                ToastDemo(
                    this.props.toastManager,
                    response.data.error,
                    "error"
                );
            }
        })
        .catch((error) => {
            this.setState({
                loadingContent: null,
                buttonDisable: false,
            });
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        $('#homeHeader #collapsibleNavbar').removeClass("show");
        const path = this.props.location;
        this.setState({
            loadingContent: "Loading... Please wait..",
            buttonDisable: true,
        });
        api.postMethod("login", this.state.data)
            .then((response) => {
                if (response.data.success === true) {
                    localStorage.setItem("login_by", response.data.data.login_by);
                    localStorage.setItem("socket", true);
                    localStorage.setItem("userId", response.data.data.user_id);
                    localStorage.setItem(
                        "accessToken",
                        response.data.data.token
                    );
                    localStorage.setItem("userLoginStatus", true);
                    localStorage.setItem(
                        "user_picture",
                        response.data.data.picture
                    );
                    localStorage.setItem(
                        "username",
                        response.data.data.username
                    );

                    localStorage.setItem(
                        "is_frontdesk",
                        0
                    );

                    // window.location = path ? path.from.pathname : "/home";
                    ToastDemo(
                        this.props.toastManager,
                        response.data.message,
                        "success"
                    );
                    this.setState({
                        loadingContent: null,
                        buttonDisable: false,
                    });
                    $("#login").modal("hide");
                    if (path) {
                        // window.location = path.pathname;
                        this.props.history.push(path.pathname);
                        window.location.reload();
                    } else {
                        // window.location = "/home";
                        this.props.history.push("/home");
                    }
                    // window.locatiom = path
                    //   ? this.props.history.push(path.from.pathname)
                    //   : this.props.history.push("/home");
                    // this.props.history.push("/home");
                } else {
                    this.setState({
                        loadingContent: null,
                        buttonDisable: false,
                    });
                    ToastDemo(
                        this.props.toastManager,
                        response.data.error,
                        "error"
                    );
                }
            })
            .catch((error) => {
                this.setState({ loadingContent: null, buttonDisable: false });
            });
    };

    signUpClick = (event) => {
        event.preventDefault();
        $("#login").modal("hide");
        $("#signup").modal("show");
    };

    fogotClick = (event) => {
        event.preventDefault();
        $("#login").modal("hide");
        $("#forgot-password").modal("show");
    };

    render() {
        const { data,loading,googleLoginInput } = this.state;

        return (
            <div>
                <div className="modal fade auth" id="login">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    id="close-login"
                                    data-dismiss="modal"
                                    onClick={() => this.props.showAuth && this.props.toggleLoginModal(false)}
                                >
                                    {/* <i className="material-icons">close</i> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#787878" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </button>
                            </div>

                            <div className="modal-body">
                                <h1 className="section-head">
                                    Log in to continue
                                </h1>
                             
                                    <InputField
                                        type="text"
                                        name="email"
                                        onChange={this.handleChange}
                                        placeholder="email"
                                        iconClassName="fas fa-lock"
                                        value={data.email}
                                    />

                                    <InputField
                                        type="password"
                                        name="password"
                                        onChange={this.handleChange}
                                        placeholder="password"
                                        iconClassName="fas fa-lock"
                                        value={data.password}
                                    />

                                    <button
                                        className="pink-btn bottom1"
                                        disabled={this.state.buttonDisable}
                                        onClick={this.handleSubmit}
                                    >
                                        {this.state.loadingContent != null
                                            ? this.state.loadingContent
                                            : "login"}
                                    </button>
                                    <a
                                        href="#"
                                        className="forgot-pass close-login"
                                        // data-toggle="modal"
                                        // data-target="#forgot-password"
                                        onClick={this.fogotClick}
                                    >
                                        forgot password?
                                    </a>

                                <div className="login-separator">
                                    or continue with
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 bottom1">
                                        <GoogleLogin
                                            clientId={
                                                apiConstants.GOOGLE_CLIENT_ID
                                            }
                                            render={(renderProps) => (
                                                <button
                                                    className="social-btn"
                                                    onClick={
                                                        renderProps.onClick
                                                    }
                                                    disabled={
                                                        renderProps.disabled
                                                    }
                                                >
                                                    <i className="fab fa-google" />{" "}
                                                    Google
                                                </button>
                                            )}
                                            buttonText="Login"
                                            onSuccess={this.responseGoogle}
                                            onFailure={this.responseGoogle}
                                            cookiePolicy={"single_host_origin"}
                                        />
                                    </div>
                                </div>
                                <p className="line" />
                                <h4 className="m-0 text-center captalize">
                                    Don't have an Account?{" "}
                                    <a
                                        href="#"
                                        className="bold-cls close-login"
                                        onClick={this.signUpClick}
                                        // data-toggle="modal"
                                        // data-target="#signup"
                                    >
                                        {" "}
                                        Sign up
                                    </a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ForgotPassword />
                {loading ? (
                    " "
                ) : (
                <SocialLogin 
                    googleLoginInput={googleLoginInput} 
                    handleResponseChange={this.handleResponseChange} 
                    handleGoogleSubmit={this.handleGoogleSubmit}
                    handleMobileResponse={this.handleMobileResponse}/>
                )}
            </div>
        );
    }
}

export default withToastManager(Login);
