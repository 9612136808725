import React, { Component } from "react";

class TripPackagePricing extends Component {
  state = {};

  render() {
    return (
      <>
        <p className="overview-line" />
        <h4 className="single-cat-text medium-cls ">Pricing Packages</h4>
        
        {this.props.loading ? (
          "Loading..."
        ) : this.props.singleTripDetails.host_pricing_packages.length > 0 ? (
          <div className="row">
            {this.props.singleTripDetails.host_pricing_packages.map(
              (details) => (
                <div
                  className="col-md-6 mrg-btm-package"
                  key={details.host_pricing_package_id}
                >
                  {/* background-grey */}
                  <div className={this.props.singleTripDetails.basic_details.custom_pricing_request.status == 0 ? "trip-host-package-price" : "trip-host-package-price"}>
                    <h3 className="host-pack-title">{details.name}</h3>
                    <h3 className="host-pack-desc">{details.description}</h3>
                    <h3 className="host-pack-desc">
                      <i class="fas fa-user-friends mr-2"></i>
                      {details.min_guests} - Min guests
                    </h3>
                    <h3 className="host-pack-desc">
                      <i class="fas fa-users mr-2"></i>
                      {details.max_guests} - Max guests
                    </h3>
                    <div class="selection-package">
                      <input
                        id={"package-select" + details.host_pricing_package_id}
                        type="radio"
                        name="host_pricing_package_id"
                        value={details.host_pricing_package_id}
                        onChange={this.props.selectPackage}
                        // disabled={this.props.singleTripDetails.basic_details.custom_pricing_request.status === 0 ? true : false}
                        checked={
                          Number(
                            this.props.priceCalInput.host_pricing_package_id
                          ) == details.host_pricing_package_id
                        }
                      />
                      <label
                        htmlFor={
                          "package-select" + details.host_pricing_package_id
                        }
                      >
                        <i class="fas fa-check-circle mr-2"></i>Select (
                        {details.base_price_formatted})
                      </label>
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="col-md-6 mrg-btm-package" key={"unique"}>
              <div className="trip-host-package-price">
                <h3 className="host-pack-title">Custom Quote</h3>
                {this.props.singleTripDetails.basic_details.custom_pricing_request.status === 0 ?
                  <h3 className="host-pack-desc">Request for a custom quote. Provide your requirement in the next page.</h3>
                : this.props.singleTripDetails.basic_details.custom_pricing_request.status === 1 ?
                <h3 className="host-pack-desc">{this.props.singleTripDetails.basic_details.custom_pricing_request.description}</h3>
                : 
                  <h3 className="host-pack-desc">Request for a custom quote. Provide your requirement in the next page.</h3>
                }

                
                {localStorage.getItem("userLoginStatus") ? (
                  <div class="selection-package">
                    {this.props.singleTripDetails.basic_details.custom_pricing_request.status === 0 ?
                      ''
                    : this.props.singleTripDetails.basic_details.custom_pricing_request.status === 1 ?
                      <input
                        id={"custom-package-select"}
                        type="radio"
                        name="host_pricing_package_id"
                        value="custom_price"
                        onClick={this.props.selectPackage}
                        // disabled={this.props.singleTripDetails.basic_details.custom_pricing_request.status === 0 ? true : false}
                        checked={
                          this.props.priceCalInput.host_pricing_package_id ==
                          "custom_price"
                        }
                      />
                    : 
                      <input
                        id={"custom-package-select"}
                        type="radio"
                        name="host_pricing_package_id"
                        value="custom_price_request"
                        onClick={this.props.selectPackage}
                        disabled={this.props.singleTripDetails.basic_details.custom_pricing_request.status === 0 ? true : false}
                        checked={
                          this.props.priceCalInput.host_pricing_package_id ==
                          "custom_price_request"
                        }
                      />
                    }
                    {this.props.singleTripDetails.basic_details.custom_pricing_request.status === 0 ? (
                      <label htmlFor={"custom-package-select"} style={{
                        color: '#ff8323',
                        border: '1px solid #ff8323', background: '#e5e5e5'
                      }}>
                        <i class="fas fa-hourglass-end mr-2"></i>Request In progress
                      </label>
                    ) : this.props.singleTripDetails.basic_details.custom_pricing_request.status === 1 ?
                      <label htmlFor={"custom-package-select"}>
                        <i class="fas fa-hourglass-end mr-2"></i>Custom Pricing ({this.props.singleTripDetails.basic_details.custom_pricing_request.amount_formatted})
                      </label>
                      :
                      <label htmlFor={"custom-package-select"}>
                        <i class="fas fa-check-circle mr-2"></i>Request Custom Quote
                      </label>
                    }
                  </div>
                ) : (
                  <div class="selection-package">
                    <a href="#" data-toggle="modal" data-target="#login">
                      <label htmlFor={"custom-package-select"}>
                        <i class="fas fa-check-circle mr-2"></i>Request Custom
                        Quote
                      </label>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default TripPackagePricing;
