import React, { Component } from "react";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import api from "../../Environment";
import MapContainer from "../Helper/mapHelper";
import Helper from "../Helper/Helper";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import HomeSocialShare from "../Home/homeSocialShare";
import LazyLoad from 'react-lazyload';

const $ = window.$;

class HomeRoomContent extends Helper {
  state = {
    wishlistData: {
      wishlist_status: "",
      host_id: "",
    },
    shareData : {
      share_link : ""
    },
    loading: true,
    host_id: null,
    activeFilter:'',
  };

  handleClick = (event, data) => {
    event.preventDefault();
    let el = event.target;

    const addWishlist = {
      host_id: data.host_id,
      clear_all_status: 0,
    };
    api.postMethod("wishlist_operations", addWishlist).then((response) => {
      if (response.data.success) {
        this.setState({
          wishlistData: response.data.data,
          loading: false,
          host_id: data.host_id,
        });
        if (response.data.data.wishlist_status) {
          el.classList.add("fa-heart-red");
          el.innerText = " Saved";
        } else {
          el.classList.remove("fa-heart-red");
          el.innerText = " Save";
        }
        ToastDemo(this.props.toastManager, response.data.message, "success");
      } else {

        if(response.data.error_code == 1002){
          return $("#login").modal("show");
        }

        const check = this.checkLoginUser(response.data);
        if (check) {
          ToastDemo(
            this.props.toastManager,
            "Please login to save the details",
            "error"
          );
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      }
    });
  };

  handleDropdown = (fiter) => {
    this.setState({ activeFilter: fiter });
  }

  closeDropDown = () => {
    this.setState({ activeFilter: '' });
  }

  handleImageClick = (host_id,status) => {
    console.log("IMage clicked. ", host_id);
    this.props.history.push(`/event/${host_id}`,{open_gallery:status});
  };

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
  }

  handleSocialShare = (share_link) => {
    this.setState({
      ...this.state,
      shareData : {
        share_link : share_link
      }
    })
  }

  render() {
    const { length: count } = this.props.contentDetails;
    const { wishlistData, loading, host_id } = this.state;

    if (count === 0) {
      return <p />;
    }

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="subcategory-leftsec">
        <div className="section-title">
        <div className={this.props.isMain ? "search-header-sec" : "section-title"}>
          <h1 className="section-head">{this.props.title}</h1>
          
          {/* <h4 className="captalize section-subhead">
          {this.props.description}
          </h4> */}
          {this.props.isMain == 1 ? 
            <div>
              <ul className="list-unstyled search-header-list">
                <li className="dropdown service-type-dropdown">
                <button className="sort-by-btn" onClick={() => this.handleDropdown("Price")}>
                  Sort By
                  <span>
                    {/* <i className="fas fa-sort"></i> */}
                    {this.props.priceFilter == 'high_low' ? 
                      <img
                        src={window.location.origin + `/assets/img/landing-page/low.png`}
                      />
                    : this.props.priceFilter == 'low_high' ? 
                      <img
                        src={window.location.origin + `/assets/img/landing-page/high.png`}
                      />
                    : this.props.priceFilter == 'highest_rated' ? 
                      <img
                        src={window.location.origin + `/assets/img/landing-page/highest-rated.png`}
                      />
                    : 
                      <img
                        src={window.location.origin + `/assets/img/landing-page/best-match.png`}
                      /> 
                    }
                    
                  </span>
                </button>
                <div className="filter-dropdown-wrapper">
                  {this.state.activeFilter == "Price" && (
                    <div className="filter-backdrop" onClick={this.closeDropDown}></div>
                  )}
                  <div className={`dropdown-menu guest sort-by-dropdown ${this.state.activeFilter == "Price" ? "show" : ""}`}>
                    <div className="custom-control custom-radio">
                      <input type="radio" 
                        id="customRadio1" 
                        name="customRadio" 
                        value="best_match"
                        className="custom-control-input" 
                        checked={this.props.priceFilter == 'best_match' || this.props.priceFilter == ''? true : false}
                        onChange={(event) => {
                          this.props.onChangePrice(event,event.currentTarget.value)
                        }}
                      />
                      <label className="custom-control-label" for="customRadio1">Best Match</label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input 
                        type="radio" 
                        id="customRadio2" 
                        name="customRadio" 
                        value="high_low"
                        className="custom-control-input"
                        checked={this.props.priceFilter == 'high_low' ? true : false}
                        onChange={(event) => {
                          this.props.onChangePrice(event, event.currentTarget.value)
                        }}
                      />
                      <label className="custom-control-label" for="customRadio2">Price High to Low</label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input 
                        type="radio" 
                        id="customRadio3" 
                        name="customRadio" 
                        value="low_high"
                        className="custom-control-input"
                        checked={this.props.priceFilter == 'low_high' ? true : false}
                        onChange={(event) => {
                          this.props.onChangePrice(event, event.currentTarget.value)
                        }}
                      />
                      <label className="custom-control-label" for="customRadio3">Price Low to High</label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input 
                        type="radio" 
                        id="customRadio4" 
                        name="customRadio"
                        value="highest_rated"
                        className="custom-control-input"
                        checked={this.props.priceFilter == 'highest_rated' ? true : false}
                        onChange={(event) => {
                          this.props.onChangePrice(event, event.currentTarget.value)
                        }}
                      />
                      <label className="custom-control-label" for="customRadio4">Highest Rated</label>
                    </div>
                  </div>
                </div>
                </li>
              </ul>
            </div>
          : 
            <h4 className="captalize section-subhead">
              {this.props.description}
            </h4>
          }
        </div>
        </div>

        <div className="row">
          {this.props.contentDetails.map((data) => (
            <div className="subcategory-card view-all-card" key={data.host_unique_id}>
              <div className="relative">
                <section className="home-slider slider">
                  {data.gallery_count > 3 ?
                    <Carousel
                      showThumbs={false}
                      infiniteLoop={true}
                      showStatus={false}
                    >
                      {data.gallery.map((image, index) => 
                         (index + 1 === data.gallery.length ?
                          <LazyLoad>
                            <div
                              className="homes-img-sec1"
                              onClick={() => this.handleImageClick(data.host_unique_id,1)}
                            > 
                              {/* <img
                                src={image.picture}
                                alt="Loading..."
                                className="homes-img"
                              /> */}
                              <picture>
                                  <source className="lazyload homes-img" type="image/png" srcset={image.picture}/>
                                  <img data-src={image.picture} type="image/png" className="lazyload homes-img" alt="Loading..."/>
                              </picture>
                              <div className="view-all-blured">
                                <img
                                  src={
                                    window.location.origin +
                                    "/assets/img/view-all-icon.svg"
                                  }
                                  alt="Loading..."
                                  className="view-all-photo-icon"
                                />
                                <p>View {data.gallery_count} more photos</p>
                              </div>
                            </div>
                          </LazyLoad>
                        :
                        <LazyLoad>
                            <div
                              key={image.picture}
                              className="homes-img-sec1"
                              onClick={() => this.handleImageClick(data.host_unique_id,0)}
                            >
                              {/* <img
                                src={image.picture}
                                alt="Loading..."
                                className="homes-img"
                              /> */}
                              <picture>
                                  <source className="lazyload homes-img" type="image/png" srcset={image.picture}/>
                                  <img data-src={image.picture} type="image/png" className="lazyload homes-img" alt="Loading..."/>
                              </picture>
                            </div>
                          </LazyLoad>
                      ))}
                    </Carousel>
                  : 
                    <Carousel
                      showThumbs={false}
                      infiniteLoop={true}
                      showStatus={false}
                    >
                      {data.gallery.map((image) => (
                        <LazyLoad>
                          <div
                            key={image.picture}
                            className="homes-img-sec1"
                            onClick={() => this.handleImageClick(data.host_unique_id,0)}
                          > 
                            {/* <img
                              src={image.picture}
                              alt="Loading..."
                              className="homes-img"
                            /> */}
                            <picture>
                                  <source className="lazyload homes-img" type="image/png" srcset={image.picture}/>
                                  <img data-src={image.picture} type="image/png" className="lazyload homes-img" alt="Loading..."/>
                              </picture>
                          </div>
                        </LazyLoad>
                      ))}
                    </Carousel>
                  }
                </section>
                <div className="share-left">
                  <div className="wishlist-icon">
                     <button className="btn-wht" data-toggle="modal" data-target="#social-share" onClick={() => this.handleSocialShare(data.share_link)}>
                     <i class="fa fa-share-alt" aria-hidden="true"></i>
                     </button>
                  </div>
                </div>

                <div className="amount-display">
                  <div className="booking-icon-sec">
                    <ul className="list-unstyled">
                    {data.is_automatic_booking == 1 ? (
                      <li>
                        <img
                            src={
                                window.location.origin +
                                "/assets/img/rapid-booking-white.svg"
                            }
                            alt=""
                            className="booking-icon"
                        />
                      </li>
                      ) : (
                        <>
                        </>
                      )}
                      {data.is_only_residence == 1 ? (
                        <li>
                          <img
                              src={
                                  window.location.origin +
                                  "/assets/img/lock-white.svg"
                              }
                              alt=""
                              className="booking-icon"
                          />
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                  {data.pricing_type != 3 ? (
                    <h2 className="absolute-amount">
                      From <span> {data.pricing_type_amount_formatted} </span>/{" "}
                      {data.pricing_type_text}
                    </h2>
                  ) : (
                    <h2 className="absolute-amount">
                      Packages From <span> {data.pricing_type_amount_formatted} </span>
                    </h2>
                  )}
                </div>

                

                <div className="wishlist-icon-sec">
                  <div className="wishlist-icon">
                    <Link
                      className=""
                      to="#"
                      onClick={(e) => this.handleClick(e, data)}
                    >
                      {/* {loading ? (
                        ""
                      ) : wishlistData.host_id != "" ? (
                        wishlistData.host_id == data.host_id ? (
                          <i className="fas fa-heart pink-clr" />
                        ) : (
                          <i className="far fa-heart" />
                        )
                      ) : (
                        <i className="far fa-heart" />
                      )} */}
                      {data.wishlist_status ? (
                        <>
                          <i className="fa fa-heart fa-heart-red">{' '}Saved</i>
                          {/* <span className="ml-2 save-text pink-clr">Saved</span> */}
                        </>
                      ) : (
                        <>
                          <i className="fa fa-heart">{' '}Save</i>
                          {/* <span className="ml-2 save-text">Save</span> */}
                        </>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
              <Link to={`/event/${data.host_unique_id}`}>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">
                    {data.host_location}{" "}
                    <span className="room-content-dot">
                      <i className="fas fa-circle" />
                    </span>{" "}
                    {data.sub_category_name}
                  </p>
                  <h4 className="homes-title txt-overflow m-t-10">
                    {data.host_name}
                  </h4>
                  <h5 className="homes-price txt-overflow m-t-10 new-features">
                    <ul className="list-unstyled">
                      {data.is_automatic_booking == 1 ? (
                        <li>
                          <img
                              src={
                                  window.location.origin +
                                  "/assets/img/rapid-booking.svg"
                              }
                              alt=""
                              className="booking-icon-1"
                          />
                          <span>Rapid Booking Allowed</span>
                        </li>
                      ) : (
                        <>
                        <li>
                          <img
                              src={
                                  window.location.origin +
                                  "/assets/img/rapid-booking.svg"
                              }
                              alt=""
                              className="booking-icon-1"
                          />
                          <span className="text-strike">Rapid Booking Allowed</span>
                        </li>
                        </>
                      )}
                      {data.is_only_residence == 1 ? (
                        <li>
                          <img
                              src={
                                  window.location.origin +
                                  "/assets/img/lock.svg"
                              }
                              alt=""
                              className="booking-icon-1"
                          />
                          <span>See amenities for details</span>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </h5>
                  {/* <h5 className="homes-price txt-overflow m-t-10">
                    <span>
                      {data.is_automatic_booking == 1 ? (
                        <span>Rapid Booking</span>
                      ) : (
                        <span className="text-strike">Rapid Booking</span>
                      )}
                    </span>
                  </h5> */}
                  <div className="flex-common">
                    <p className="guest-icon">
                      <i className="fa fa-users"></i> {data.max_guests}
                    </p>
                    <h5 className="rating-sec">
                      <span className="rating-star">
                        {this.starRatingHost(data.admin_ratings ? data.admin_ratings : 0 )}
                      </span>
                      <span className="rating-count">
                        &nbsp;({data.admin_ratings ? data.admin_ratings : 0})
                      </span>
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <HomeSocialShare details={this.state.shareData}/>
      </div>
      
    );
  }
}

export default withToastManager(HomeRoomContent);
