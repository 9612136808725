import React, { Component } from "react";
import api from "../../Environment";
import { Calendar } from "@y0c/react-datepicker";
import dayjs from "dayjs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from 'moment';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const $ = window.$;

const onlyMonth = {
  month: "numeric",
};
const onlyYear = {
  year: "numeric",
};
const onlyDate = {
  day: "numeric",
};

var today = new Date();
today.setDate(today.getDate());

class GetAvailability extends Component {
  state = {
    selected: [],
    num: 1,
    availabilities: null,
    loading: true,
    date_availabilities: null,
    selectedData : null,
    startDate : null,
    endDate : null,
    totalNights : null,
    notAvailableDates : [],
    defaultMonth: 2,
  };
  componentDidMount() {

    const inputData = {
      host_id: this.props.host_id,
      month: new Date().toLocaleDateString("en-CA", onlyMonth),
      year: new Date().toLocaleDateString("en-CA", onlyYear),
      loops: 2,
    };
    if(this.props.priceCalInput.checkin){
      this.setState({
        ...this.state,
        selectedData : this.props.priceCalInput.checkin
      })
    }

    this.getAvailabilityApiCall(inputData);
    if(window.innerWidth < 768){
      this.setState({
        defaultMonth:1,
      });
    } else {
      this.setState({
        defaultMonth:2,
      });
    }
  }
  getAvailabilityApiCall = (inputData) => {
    api.postMethod("host_availability_view", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          availabilities: response.data.data,
        });
        setTimeout(() => {
          if (this.state.availabilities != null) {
            let array = [];
            let notAvalialbe = []
            // if (this.props.basicDetails.booking_type == 1) {
              this.state.availabilities.data.map((data) => {
                if (data.is_booked == 1){
                  array.push(dayjs(data.date));
                  var date = moment(data.date, 'YYYY-MM-DD')
                  notAvalialbe.push(date.format('DD-MM-YYYY'));
                } 
              });
              this.setState({ selected: array , notAvailableDates: notAvalialbe ,  loading: false, });
            // }else{
            //   this.setState({ loading: false, });
            // }
            console.log(notAvalialbe);
          } else {
          }
        }, 3000);

        if (this.state.num > 1) console.log(this.state.selected[0].c);
      }
    });
  };


  customDayText = (date) => {
    // for test (year, month remove)
    // const classMap = {
    //   "01": "Abc",
    //   "02": "DEF"
    // };
    console.log("DAte in custom text", dayjs(date).format("DD"));
    console.log("DAte in custom text", dayjs(date).format("MM"));
    let classMap = {};
    this.state.availabilities.map((availability) => {
      if (parseInt(availability.month) == dayjs(date).format("MM")) {
        availability.availability_data.map((data) => {
          if (data.is_available == 1) {
            classMap[dayjs(date).format("DD")] =
              data.pricings.per_day_formatted;
          }
        });
      }
    });
    console.log("classMap", classMap);
    return classMap[dayjs(date).format("DD")];
  };

  handleChange = (date) => {
    const selectedDate = dayjs(date).format("YYYY-MM-DD");
    const inputData = {
      host_id: this.props.host_id,
      // date:selectedDate,
      checkin : selectedDate,
      loops: 2,
      timezone: const_time_zone,
    };

    this.setState({
      ...this.state,
      selectedData : selectedDate
    })

    this.props.handleAvaliablityDateChange(date , this.props.host_id);
    
    api.postMethod("host_availability_date_view", inputData).then((response) => {
      if (response.data.success) {

        this.setState({
          date_availabilities: response.data.data,
          loading: false,
        });
        // confirmAlert({
        //   title: "Availablity Details",
        //   message:response.data.data.data.availability_text,
        //   buttons: [
        //     {
        //       label: "Okay",
        //       // onClick: () => alert("Click Okay."),
        //     },
        //   ],
        // });    
        $("#Availability").modal("show");
        if (this.state.num > 1) console.log(this.state.selected[0].c);
      }
    });
  };

  handleNormalChange = (date) => {
    const selectedDate = dayjs(date).format("YYYY-MM-DD");
    const inputData = {
      host_id: this.props.host_id,
      // date:selectedDate,
      checkin : selectedDate,
      loops: 2,
      timezone: const_time_zone,
    };

    this.setState({
      ...this.state,
      selectedData : selectedDate
    })

    this.props.hanldeNormalBooking(date);
    
    api.postMethod("host_availability_date_view", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          date_availabilities: response.data.data,
          loading: false,
        });   
      }
    });
  };
  onMonthChange = (value) => {
    console.log(value);
  }
   onRangeChange = (dates) => {
    const [start, end] = dates;
    this.setState({
      startDate : start,
      endDate : end
    })
    this.props.handleRangleCalenderChange(dates);
    if(end != null){
      const checkin = moment(start);
      const checkout = moment(end);
      const days = checkout.diff(checkin, 'days');
      this.setState({
        totalNights : days
      })
    }
  };

  disabledDate(current , notAvailableDates) {
    return(
      //!disabledDates.find(date => date === moment(current).format("DD-MM-YYYY"))
      !notAvailableDates.find(date => date === moment(current).format("DD-MM-YYYY"))
      )
  }

  render() {
    const { selected, loading, availabilities , notAvailableDates } = this.state;

    return (
      // <div className="main">
      //   <div className="site-content">
      // <div className="top-bottom-spacing">
      <div className="row">
        <div className="col-12 ">
          {/* <h2>Update your calendar</h2> */}
          <p>Select date to view details.</p>
          {loading ? (
            "Loading..."
          ) : (
            <>
              {this.props.basicDetails.booking_type == 1 ? (                
                <>
                    {/* <h2 className="singleCheckinoutHeading">Select date</h2>  */}
                      <div id="customReactDatePicker" className="notRange">
                        <DatePicker  
                          inline 
                          excludeDates={[today]}
                          selected={today}
                          monthsShown={this.state.defaultMonth}
                          onChange={this.handleNormalChange}
                          minDate={today}
                          // peekNextMonth={this.onMonthChange}
                          calendarClassName="responsive-calendar"
                          filterDate={(date) => this.disabledDate(date , notAvailableDates)}
                          />
                    </div>
                </>
              ): (
                <>
                      <div id="customReactDatePicker" className="notRange">
                        <DatePicker  
                          inline 
                          excludeDates={[today]}
                          selected={today}
                          monthsShown={this.state.defaultMonth}
                          onChange={this.handleChange}
                          minDate={today}
                          calendarClassName="responsive-calendar"
                          filterDate={(date) => this.disabledDate(date , notAvailableDates)}
                          />
                      </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="modal fade" id="Availability">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" style={{float: "right"}}>
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <h1 className="section-head">Availability Details - {this.state.selectedData}</h1>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.date_availabilities ? this.state.date_availabilities.availability_text : "-"
                  }}
                />
              </div> */}

              <div>
                {this.state.date_availabilities 
                && this.state.date_availabilities.booking_type == 2 
                && this.state.date_availabilities.data.availability_text.map((time_slot , index) => (
                  <div 
                    className={`mt-3 singleTripTimeSlotWrapper ${time_slot.status ==0 ? "not-available" : ""}`}
                    key={index} 
                    onClick={time_slot.status != 0 ? () => this.props.hanldeModalTimeSlotChange(time_slot)  : null}
                  >
                    <div className="mb-0" data-dismiss="modal">
                      {time_slot.name} - 
                      <span>{" "}{time_slot.start_time_formatted}</span> to
                      <span>{" "}{time_slot.end_time_formatted}</span> {" "} {"-"}
                      <span>{" "}{time_slot.status_formatted}</span>
                    </div>
                  </div>
                ))}
              </div>
              <h5 className="mt-3">The time is based on the local time at the host/listing location.</h5>
            </div>
          </div>
        </div>
      </div>
     </div>
      //   </div>
      // </div>
    );
  }
}

export default GetAvailability;
