import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";


const HomeCategoryListing = (props) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
                arrows: false,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                dots: true,
                arrows: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
              }
            }
          ],
      };

    return (
        <>
            <div className="home-category-listing-sec site-content">
                <h1 className="section-head">Ideas and tips</h1>
                <p>Get inspired with the latest trends and advice from our experts</p>
                {/* <div className="home-category-listing-box">
                    <Link to="#">
                        <div className="home-category-listing-card">
                            <div className="home-category-listing-img-sec">
                                <img
                                    src={window.location.origin + `/assets/img/landing-page/articles-cat-global-1.jpg`}
                                    alt="Image"
                                    className="home-category-listing-img"
                                />
                            </div>
                            <div className="home-category-listig-info">
                                <Link to="#">
                                    <h5>Before the wedding</h5>
                                </Link>
                            </div>
                        </div>
                    </Link>
                    <Link to="#">
                        <div className="home-category-listing-card">
                            <div className="home-category-listing-img-sec">
                                <img
                                    src={window.location.origin + `/assets/img/landing-page/articles-cat-global-2.jpg`}
                                    alt="Image"
                                    className="home-category-listing-img"
                                />
                            </div>
                            <div className="home-category-listig-info">
                                <Link to="#">
                                    <h5>The wedding ceremony</h5>
                                </Link>
                            </div>
                        </div>
                    </Link>
                    <Link to="#">
                        <div className="home-category-listing-card">
                            <div className="home-category-listing-img-sec">
                                <img
                                    src={window.location.origin + `/assets/img/landing-page/articles-cat-global-3.jpg`}
                                    alt="Image"
                                    className="home-category-listing-img"
                                />
                            </div>
                            <div className="home-category-listig-info">
                                <Link to="#">
                                    <h5>The wedding banquet</h5>
                                </Link>
                            </div>
                        </div>
                    </Link>
                    <Link to="#">
                        <div className="home-category-listing-card">
                            <div className="home-category-listing-img-sec">
                                <img
                                    src={window.location.origin + `/assets/img/landing-page/articles-cat-global-4.jpg`}
                                    alt="Image"
                                    className="home-category-listing-img"
                                />
                            </div>
                            <div className="home-category-listig-info">
                                <Link to="#">
                                    <h5>The services for your wedding</h5>
                                </Link>
                            </div>
                        </div>
                    </Link>
                    <Link to="#">
                        <div className="home-category-listing-card">
                            <div className="home-category-listing-img-sec">
                                <img
                                    src={window.location.origin + `/assets/img/landing-page/articles-cat-global-5.jpg`}
                                    alt="Image"
                                    className="home-category-listing-img"
                                />
                            </div>
                            <div className="home-category-listig-info">
                                <Link to="#">
                                    <h5>Wedding fashion</h5>
                                </Link>
                            </div>
                        </div>
                    </Link>
                </div> */}
                <div className="home-blog-sec">
                    <Slider {...settings}>
                        <div className="">
                            <a href="https://blog.partybudha.ca/how-to-throw-an-exciting-quarantine-birthday-for-adults/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/03/blog-300x195-1.jpg"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/03/blog-300x195-1.jpg"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/03/blog-300x195-1.jpg" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>How To Throw An Exciting Quarantine Birthday For Adults</h4>
                                        <p>A quarantine birthday party is the result of the world’s crisis with Covid-19. And while it may feel lonely...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/simple-birthday-decoration-ideas/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog-7-1-768x498.jpg"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog-7-1-768x498.jpg"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog-7-1-768x498.jpg" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>Simple Birthday Decoration Ideas</h4>
                                        <p>Decorations make a birthday party more fun and exciting. However, to make everything perfect, you end up spending more...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/top-6-birthday-ideas-for-adults-2022/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog-6-1-768x504.jpg"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog-6-1-768x504.jpg"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog-6-1-768x504.jpg" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>Top 6 Birthday Ideas for Adults 2022</h4>
                                        <p>Who says birthdays are just for kids? No matter the age, adults should also celebrate the day they are...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/4-fun-filled-at-home-birthday-ideas-for-girls/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog5-1-768x495.png"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog5-1-768x495.png"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/02/blog5-1-768x495.png" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>4 Fun-Filled At-Home Birthday Ideas For Girls</h4>
                                        <p>Little girls hold a special place in everyone’s hearts. So, children’s birthday parties should be just as special, even...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/childrens-virtual-birthday-party-ideas-2022/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/02/7-768x508.jpg"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/02/7-768x508.jpg"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/02/7-768x508.jpg" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>Children’s Virtual Birthday Party Ideas 2022</h4>
                                        <p>Planning a child’s party is exciting for parents. One interesting way is to set up your child’s birthday decoration...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/top-5-birthday-party-places-for-teenagers-in-calgary/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/01/Image-1-1.jpg"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/01/Image-1-1.jpg"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/01/Image-1-1.jpg" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>Top 5 Birthday Party Places For Teenagers In Calgary</h4>
                                        <p>It is not easy to impress teenagers, as they are very picky about choosing the right place to celebrate...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/top-5-mobile-party-spa-for-kids-in-calgary/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/01/image-5-768x511.jpg"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/01/image-5-768x511.jpg"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/01/image-5-768x511.jpg" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>Top 5 Mobile Party Spa For Kids In Calgary</h4>
                                        <p>Spa is every girl’s dream! Girls, no matter the age, are much attracted to spa services. So, imagine spa...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/top-5-at-home-kids-birthday-party-ideas-in-calgary-2022/" target="_blank">
                                <div className="home-blog-card">
                                    <div className="home-blog-img-sec">
                                        {/* <img
                                            src="https://blog.partybudha.ca/wp-content/uploads/2022/01/Image-1.jpg"
                                            alt="Image"
                                            className="home-blog-img"
                                        /> */}
                                        <picture>
                                            <source className="lazyload home-blog-img" type="image/jpg" srcset="https://blog.partybudha.ca/wp-content/uploads/2022/01/Image-1.jpg"/>
                                            <img data-src="https://blog.partybudha.ca/wp-content/uploads/2022/01/Image-1.jpg" type="image/jpg" className="lazyload home-blog-img" alt="Loading..."/>
                                        </picture>
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>Top 5 At-Home Kids Birthday Party Ideas In Calgary 2022</h4>
                                        <p>The COVID pandemic has changed how we think and plan our kid’s birthday parties. Considering the ever-evolving social distancing...</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="">
                            <a href="https://blog.partybudha.ca/" target="_blank">
                                <div className="home-blog-card see-all-blog">
                                    <div className="home-blog-img-sec">
                                        <img
                                            src={
                                            window.location.origin +
                                            "/assets/img/landing-page/see-all-blog-img.svg"
                                            }
                                            alt="Image"
                                            className="home-blog-img"
                                        />
                                    </div>
                                    <div className="home-blog-info">
                                        <h4>See All Blog</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default HomeCategoryListing;
