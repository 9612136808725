import React, { Component , useEffect , useState } from "react";


const PricingsTimeSlots = ({
  TimeSlotsData,
  UpdateTimeSlot,
  handleTimeSlotChange,
  loadingTimeSlot,
  host,
  timeSlotInput,
}) => {

  const [timeSlotsData , setTimeSlotsData] = useState(TimeSlotsData)

 useEffect(() => {
  setTimeSlotsData(TimeSlotsData)
 },[TimeSlotsData]) 

  return (
    <div className="col-12">
      <div className="transactions">
        <h2 className="text-muted">Manage Timeslots</h2>
        <div className="trans-table table-responsive border-gray">
          <table className="table">
            <thead>
              {host.pricing_type == 1 ? (
                <tr>
                  <th scope="col">Slots</th>
                  <th scope="col">Base Price</th>
                  <th scope="col">Action</th>
                </tr>
              ) : host.pricing_type == 2 ? (
                <tr>
                  <th scope="col">Slots</th>
                  <th scope="col">Adult</th>
                  <th scope="col">Child</th>
                  <th scope="col">Infants</th>
                  <th scope="col">SN</th>
                  <th scope="col">Action</th>
                </tr>
              ) : (
                ""
              )}
            </thead>
            <tbody>
              {loadingTimeSlot
                ? ""
                : timeSlotsData.length > 0
                ? timeSlotsData.map((timeslot) =>
                    host.pricing_type == 1 ? (
                      <tr key={timeslot.host_time_slot_id}>
                        <td>{timeslot.name}</td>

                        <td>
                          <input
                            className="form-control"
                            placeholder="Base Price"
                            type="number"
                            name="fixed_base_price"
                            id={timeslot.host_time_slot_id}
                            onChange={handleTimeSlotChange}
                            defaultValue={timeslot.fixed_base_price}
                          ></input>
                        </td>
                        <td>
                          {timeSlotInput && timeSlotInput.host_time_slot_id == timeslot.host_time_slot_id ? 
                        
                          <button
                            className="btn btn1"
                            onClick={(event) => UpdateTimeSlot(event, timeslot.host_time_slot_id)}
                          >
                            Update
                          </button>
                          : ''}
                        </td>
                      </tr>
                    ) : host.pricing_type == 2 ? (
                      <tr key={timeslot.host_time_slot_id}>
                        <td>{timeslot.name}</td>
                        <td>
                          <input
                            placeholder="Price"
                            type="number"
                            id={timeslot.host_time_slot_id}
                            name="base_price_adult"
                            onChange={handleTimeSlotChange}
                            defaultValue={timeslot.base_price_adult}
                          ></input>
                        </td>
                        <td>
                          <input
                            placeholder="Price"
                            type="number"
                            name="base_price_child"
                            id={timeslot.host_time_slot_id}
                            onChange={handleTimeSlotChange}
                            defaultValue={timeslot.base_price_child}
                          ></input>
                        </td>
                        <td>
                          <input
                            placeholder="Price"
                            type="number"
                            name="base_price_infants"
                            id={timeslot.host_time_slot_id}
                            onChange={handleTimeSlotChange}
                            defaultValue={timeslot.base_price_infants}
                          ></input>
                        </td>
                        <td>
                          <input
                            placeholder="Price"
                            type="number"
                            id={timeslot.host_time_slot_id}
                            name="base_price_age_special"
                            onChange={handleTimeSlotChange}
                            defaultValue={timeslot.base_price_age_special}
                          ></input>
                        </td>
                        <td>
                          {timeSlotInput && timeSlotInput.host_time_slot_id == timeslot.host_time_slot_id ? 
                          
                            <button
                              className="btn btn1"
                              onClick={(event) => UpdateTimeSlot(event, timeslot.host_time_slot_id)}
                            >
                              Update
                            </button>
                          
                          : ''}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )
                  )
                : "No timeslots found"}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingsTimeSlots;
