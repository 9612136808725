import React, { Component } from "react";

import HomeRoomContent from "../Home/homeRoomContent";
import { Link } from "react-router-dom";
import api from "../../Environment";
import Helper from "../Helper/Helper";
import MapContainer from "../Helper/mapHelper";

import Loader from "../Helper/Loader";
import Filters from "../Helper/filters";

class ViewAll extends Helper {
  state = {
    first_block: null,
    mainData: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    contentData: null,
    map: true,
    lastLat: null,
    lastLng: null,
    loadingMap: true,
  };

  componentDidMount() {
    // Call api function
    let inputData;
    if (this.props.location.state.type && (this.props.location.state.type == "SUB_CATEGORY" || this.props.location.state.type == "LOCATION")) {
      inputData = {
        event_id: this.props.match.params.id,
        api_page_type_id: this.props.match.params.id,
        skip: this.state.skipCount,
        sub_category : this.props.match.params.url_type,
        api_page_type : this.props.location.state.type
      };
    } else if (this.props.location.state.type && this.props.location.state.type == "CATEGORY") {
      inputData = {
        api_page_type_id: this.props.match.params.id,
        skip: this.state.skipCount,
        category : this.props.match.params.url_type,
        api_page_type : this.props.location.state.type
      };
    }else{
      inputData = {
        event_id: this.props.match.params.id,
        skip: this.state.skipCount,
        api_page_type : this.props.location.state.type,
        location_name : this.props.location.state.location_name
      };
    }
    this.seeAll(inputData);
  }

  componentWillReceiveProps(props) {
    this.setState({ loading: true });


    if(props.location.state.notMainSearch){
      let inputData = {
        ...props.location.state.formData,
        skip:  0,
      }
      this.seeAll(inputData , false);
    }else{
      let inputData;
      if (props.match.params.name == "events") {
        inputData = {
          event_id: props.match.params.id,
          skip: this.state.skipCount,
        };
      } else {
        inputData = {
          api_page_type: props.match.params.url_type,
          api_page_type_id: props.match.params.id,
          skip: 0,
        };
      }

      this.seeAll(inputData , false);
    }
  }


  loadMore = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });
    let inputData;
    if(this.props.location.state.notMainSearch){
      let inputData = {
        ...this.props.location.state.formData,
        skip: this.state.skipCount,
      }
      this.seeAll(inputData , true);
    }else{
      if (this.props.match.params.name == "events") {
        inputData = {
          api_page_type: this.props.match.params.type,
          event_id: this.props.match.params.id,
          skip: this.state.skipCount,
        };
      } else {
        inputData = {
          api_page_type: this.props.match.params.url_type,
          api_page_type_id: this.props.match.params.id,
          skip: this.state.skipCount,
        };
      }
    this.seeAll(inputData , true);
    }
  };

  getListForLatLng = (lat, lng) => {
    this.setState({
      skipCount: 0,
      contentData: null,
      loading: true,
      lastLat: lat,
      lastLng: lng,
    });
    const inputData = {
      api_page_type: "LAT_LONG",
      skip: this.state.skipCount,
      latitude: lat,
      longitude: lng,
    };

    this.seeAll(inputData);
    // Need to call see all API.
  };

  toggleMap = (map) => {
    this.setState({ map: map });
  };

  render() {
    let load = new Loader();
    const { loading, loadingStatus, loadingContent } = this.state;
    const searchInput = this.props.location.state.searchInput;
    const currentLocation = this.props.location.state.currentLocation;
    const location = this.props.location;
    const notMainSearch = this.props.location.state.notMainSearch;
    const contentData  =this.state.contentData

    return (
      <div className="main">
        <Filters
          toggleMap={this.toggleMap}
          match={this.props.match}
          props={this.props}
          searchInput={searchInput}
          currentLocation={currentLocation}
          location = {location}
          notMainSearch={notMainSearch}
          
        />

        <div className="section-spacing main-bg">
          <div className="pd-btm-xs">
            <div className="row">
              <div
                className={
                  this.state.map
                    ? "col-8 full-width-tab full-width-mob"
                    : "col-12"
                }
              >
                <div className="">
                  {loading ? (
                    load.propertyLoader()
                  ) : (
                    <React.Fragment>
                      {contentData.length > 0 ? (
                        <HomeRoomContent
                          contentDetails={contentData}
                          title={this.state.mainData[0].title}
                          history={this.props.history}
                        />
                      ) : (
                        "No Data found"
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
              {this.state.map ? (
                <div className="col-4 padding-left-zero full-width-tab full-width-mob">
                  <div className="map-sec">
                    {this.state.loadingMap ? (
                      ""
                    ) : (
                      <MapContainer
                        data={this.state.contentData}
                        onMapPanned={this.getListForLatLng}
                        lastLat={this.state.lastLat}
                        lastLng={this.state.lastLng}
                      />
                    )}
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
            {loadingStatus ? "" : loadingContent}
            {loading ? (
              ""
            ) : this.state.contentData.length > 0 ? (
              <Link to="#" className="show-all" onClick={this.loadMore}>
                show more
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default ViewAll;
