import React, { Component } from "react";
import Login from "../Auth/Login";

import StaticFooter from "./Footers/StaticFooter";
import NoSearchHeader from "./Headers/NoSearchHeader";

// This Layouts used in Home page Header,  static footer and content

class ThirdLayout extends Component {

  constructor(props) {
    super(props);

    // define states
  }


  render() {
    return (
      <div className="wrapper">
        <NoSearchHeader {...this.props.children} />
        <div>{React.cloneElement(this.props.children)}</div>
        <StaticFooter />
      </div>
    );
  }
}
export default ThirdLayout;
