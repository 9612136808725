import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";

import "./Listing.css";
import PricingsTimeSlots from "./Pricings/PricingsTimeSlots";
import moment from 'moment';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
var yesterday = moment().subtract( 1, 'day' );
var valid = function( current ){
    return current.isAfter( yesterday );
};
class HostSpecialPricing extends Component {
  state = {
    loading: true,
    host: null,
    loadingContent: null,
    buttonDisable: false,
    hostId: null,
    pricingType: 0,
    formData: null,
    packagePricing: null,
    loadingPackagePricing: true,
    packagePricingInput: {},
    perGuestpricingInput: {},
    hostTimeSlots: null,
    timeSlotInput: {
      host_time_slot_id:0
    },
    timeslotShow: false,
    hostCustomPricings:[],
    currency:null,
    bookingType: 0,
  };
  componentDidMount() {
    this.setState({ hostId: this.props.match.params.id });
    this.getHostViewApiCall(this.props.match.params.id);
    const packagePricingInput = { ...this.state.packagePricingInput };
    packagePricingInput["host_id"] = this.props.match.params.id;
    this.setState({ packagePricingInput });
  }

  packagePricingSave = (event) => {
    event.preventDefault();
    this.setState({
      loading:true,
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("hosts_pricing_packages_save", this.state.packagePricingInput)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({packagePricingInput:{}});
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getPackagePricing();
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
        this.setState({ loadingContent: null, buttonDisable: false , loading:false });
      })
      .catch(function(error) {});
  };

  getPackagePricing = () => {
    api
      .postMethod("hosts_pricing_packages", {
        host_id: this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            loadingPackagePricing: false,
            packagePricing: response.data.data,
          });
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  getHostViewApiCall = (hostId) => {
    api
      .postMethod("hosts_pricings_view", { host_id: hostId })
      .then((response) => {
        console.log(response.data.data.host.pricing_type);
        console.log(response.data.data.host.booking_type);
        if (response.data.success === true) {
          this.setState({
            loading: false,
            host: response.data.data.host,
            bookingType: response.data.data.host.booking_type,
            pricingType: response.data.data.host.pricing_type,
            hostTimeSlots: response.data.data.host_timeslots,
            hostCustomPricings:response.data.data.host_custom_pricing,
            currency:response.data.data.currency
          });
          const formData = { ...this.state.formData };
          const timeSlotInput = { ...this.state.timeSlotInput };
          formData["fixed_base_price"] = this.state.host.base_price;
          formData["per_guest_price"] = this.state.host.per_guest_price;
          formData["pricing_type"] = this.state.host.pricing_type;
          formData["host_id"] = this.state.hostId;
          timeSlotInput["host_id"] = this.state.hostId;

          if (response.data.data.host.pricing_type == 2) {
            formData["base_price_adult"] =
              response.data.data.host.base_price_adult;
            formData["base_price_age_special"] =
              response.data.data.host.base_price_age_special;
            formData["base_price_child"] =
              response.data.data.host.base_price_child;
            formData["base_price_infants"] =
              response.data.data.host.base_price_infants;
            
          }
          if (response.data.data.host.pricing_type == 1 || response.data.data.host.pricing_type == 2) {
            this.setState({ timeslotShow: true });
          } else {
            this.setState({ timeslotShow: false });
          }
          this.setState({ formData: formData, timeSlotInput: timeSlotInput });
          if (response.data.data.host.pricing_type == 3) {
            this.getPackagePricing();
          }
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  handleChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
    // for booking type change.
    setTimeout(() => {
      if (this.state.formData.pricing_type != undefined) {
        this.setState({ pricingType: this.state.formData.pricing_type });
        if (this.state.pricingType == 3) {
          this.getPackagePricing();
        }
      }
    }, 500);
  };

  handleChangePackagePricing = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    const packagePricingInput = { ...this.state.packagePricingInput };
    packagePricingInput[input.name] = input.value;
    packagePricingInput['custom_date'] = this.state.formData.custom_date;
    this.setState({ packagePricingInput });
  };

  deletePackage = (event, packageDetails) => {
    event.preventDefault();
    api
      .postMethod("hosts_pricing_packages_delete", {
        host_pricing_package_id: packageDetails.host_pricing_package_id,
      })
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getPackagePricing();
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };



  deleteCustomPricing= (event, pricingDetails) => {
    event.preventDefault();
    api
      .postMethod("hosts_custom_pricings_delete", {
        host_custom_pricing_id: pricingDetails.id,
      })
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getHostViewApiCall(this.state.hostId);
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };


  updateCustomPricingStatus = (event, pricingDetails) => {
    event.preventDefault();
    api
      .postMethod("hosts_custom_pricings_status", {
        host_custom_pricing_id: pricingDetails.id,
      })
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getHostViewApiCall(this.state.hostId);
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };


  handlePricingSubmit = (event) => {
    event.preventDefault();
    if (this.state.pricingType == 3) {
      // Do nothing.
    } else {
      this.setState({
        loading:true,
        loadingContent: "Loading... Please wait..",
        buttonDisable: true,
      });

     if(this.state.formData.fixed_base_price == 0){
       delete this.state.formData['fixed_base_price']
     }

      api
        .postMethod("hosts_calendar_pricings_save", this.state.formData)
        .then((response) => {
          if (response.data.success === true) {
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.getCustomTimeSlots(this.state.formData.custom_date);
            this.setState({ loadingContent: null, buttonDisable: false});
            this.getHostViewApiCall(this.state.hostId);

          } else {
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            ToastDemo(this.props.toastManager, response.data.error, "error");
            this.setState({ loadingContent: null, buttonDisable: false , loading:false});
          }
        })
        .catch(function(error) {});
    }
  };

  // Per guest pricing.
  handleChangePerGuestPricing = ({ currentTarget: input }) => {
    const perGuestpricingInput = { ...this.state.perGuestpricingInput };
    perGuestpricingInput[input.name] = input.value;
    this.setState({ perGuestpricingInput });
  };

  convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  getCustomTimeSlots = (custom_date) => {

    const timeSlotInput = { ...this.state.formData };
    timeSlotInput["custom_date"] = custom_date;
    timeSlotInput["host_id"] = this.state.hostId;
    api
      .postMethod("custom_pricings_hosts_time_slots", {host_id : this.state.hostId , custom_date : custom_date })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({hostTimeSlots:[]})
          this.setState({
            loading: false,
            hostTimeSlots: response.data.data,
            timeslotShow: true,
          });
        } else {
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  onChangeDate = (title) => (...args) => {
    const formData = { ...this.state.formData };
    const timeSlotInput = { ...this.state.timeSlotInput };
    formData["custom_date"] = this.convert(args[0]);
    timeSlotInput["custom_date"] = this.convert(args[0]);
    setTimeout(() => {
      if (this.state.bookingType == 2 && (this.state.pricingType == 1 || this.state.pricingType == 2)) {
        this.getCustomTimeSlots(timeSlotInput["custom_date"]);
      }
    }, 500);
    this.setState({ formData, timeSlotInput });
  };

  handleTimeSlotChange = ({ currentTarget: input }) => {
    const timeSlotInput = { ...this.state.timeSlotInput };
    timeSlotInput["host_time_slot_id"] = input.id;
    timeSlotInput[input.name] = input.value;
    this.setState({ timeSlotInput });
  };

  UpdateTimeSlot = (event, timeslot_id) => {
    event.preventDefault();
    const timeSlotInput = { ...this.state.timeSlotInput };
    timeSlotInput["host_time_slot_id"] = timeslot_id;
    timeSlotInput["host_id"] = this.state.hostId;
    this.setState({ timeSlotInput });

    setTimeout(() => {

      this.setState({
        loading:true,
        loadingContent: "Loading... Please wait..",
        buttonDisable: true,
      });

      api
        .postMethod("hosts_calendar_pricings_save", this.state.timeSlotInput)
        .then((response) => {
          if (response.data.success === true) {
            timeSlotInput["host_time_slot_id"] = 0;
            timeSlotInput["host_id"] = this.state.hostId;
            this.getCustomTimeSlots(this.state.formData.custom_date);
            this.setState({ timeSlotInput ,loadingContent: null, buttonDisable: false});
            this.getHostViewApiCall(this.state.hostId);

            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
          } else {
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            ToastDemo(this.props.toastManager, response.data.error, "error");
            this.setState({ loadingContent: null, buttonDisable: false , loading:false});
          }
        })
        .catch(function(error) {});
    }, 500);
  };

  render() {
    const {
      loading,
      pricingType,
      loadingPackagePricing,
      packagePricing,
    } = this.state;


    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <form >
                  <div className="panel">
                    <div className="panel-heading">
                    Update your special pricing
                      <Link className="float-right" to={"/host/listing"}>
                        <i className="fas fa-chevron-left" /> Back
                      </Link>
                    </div>
                    <div className="panel-body account">
                      <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div className="media user-profile-sec">
                            <div className="media-body text-left">
                              {loading ? (
                                "Loading..."
                              ) : (
                                <>
                                  <h2>{this.state.host.host_name}</h2>
                                  <div className="flow-content mt-0">
                                    <div className="form-group large-form-group">
                                      <label className="light-txt text-left">
                                        Choose Date
                                      </label>
                                      <div className="booking-create">
                                        {/* <DatePicker
                                          placeholder="Choose Date"
                                          onChange={this.onChangeDate(
                                            "Range DatePicker"
                                          )}
                                          // className="form-control form-control-lg"
                                        /> */}
                                          <Datetime
                                            onChange={this.onChangeDate(
                                              "Range DatePicker"
                                            )}
                                            isValidDate={valid}
                                            dateFormat="DD-MM-YYYY"
                                            inputProps={{ placeholder: 'Choose Date'}}
                                            timeFormat={false} 
                                            closeOnSelect={true}
                                          /> 
                                      </div>
                                    </div>
                                  </div>

                                  {/* {pricingType == 1 ? (
                                    <div className="flow-content">
                                      <div className="form-group large-form-group">
                                        <label className="light-txt">
                                          Base price
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Enter price"
                                          name="fixed_base_price"
                                          onChange={this.handleChange}
                                          value={
                                            this.state.formData != null
                                              ? this.state.formData.fixed_base_price
                                              : 0
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null} */}
                                  {/* {pricingType == 2 ? (
                                    <>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per Adult price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Adult price"
                                            name="base_price_adult"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_adult
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per child price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Child price"
                                            name="base_price_child"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_child
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per Infant price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter infants price"
                                            name="base_price_infants"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_infants
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per Special Needs price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Special price"
                                            name="base_price_age_special"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_age_special
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : null} */}

                                  {/* {pricingType == 1 || pricingType == 2 ? (
                                    <button
                                      className="green-btn"
                                      type="button"
                                      disabled={this.state.buttonDisable}
                                      onClick={(event) => this.handlePricingSubmit(event)}
                                    >
                                      {this.state.loadingContent != null
                                        ? this.state.loadingContent
                                        : "Submit"}
                                    </button>
                                  ) : null} */}
                                  <span className="text-left">
                                  {pricingType == 1
                                    ? "NOTE: Fixed pricing."
                                    : null}
                                  {pricingType == 2
                                    ? "NOTE: Per Guest pricing."
                                    : null}
                                  {pricingType == 3
                                    ? "NOTE: Package pricing."
                                    : null}
                                    </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>


              {this.state.hostCustomPricings.length > 0 ? (
               <div className="BreakdownWrapper">
                <table className="cmn-table table table-responsive">
                  <thead>
                  {pricingType == 1 ? (
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Date</th>
                      <th scope="col">slots</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                    </tr>
                  ) : pricingType == 2 ? (
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Date</th>
                      <th scope="col">slots</th>
                      <th scope="col">Adult</th>
                      <th scope="col">Child</th>
                      <th scope="col">Infants</th>
                      <th scope="col">SN</th>
                      <th scope="col">Status</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                    ) : (
                      ""
                    )}
                  </thead>
                  <tbody>
                    {loading
                      ? ""
                      : this.state.hostCustomPricings.length > 0
                      ? this.state.hostCustomPricings.map((custompricing, index) => 
                      pricingType == 1 ? (
                        <tr>
                          <td>{index + 1}</td>
  
                          <td>
                              {moment(custompricing.custom_date).format('DD/MM/YYYY')}
                          </td>

                          <td>{custompricing.slot_name}</td>
 
                          <td>{this.state.currency}{custompricing.fixed_base_price}</td>

                          <td>
                            <button
                            className={"package-remove-btn pricing-action-btn " + (custompricing.status === 1 ? '' : 'approved-btn-clr')}
                            type="submit"
                            onClick={(event) =>
                              this.updateCustomPricingStatus(event, custompricing)
                            }>
                              {custompricing.status === 1 ? "Decline" : "Approve"}
                            </button>
                          </td>

                          {/* <td>
                            <button
                              className="package-remove-btn pricing-action-btn"
                              type="submit"
                              onClick={(event) =>
                                this.deleteCustomPricing(event, custompricing)
                              }
                            >
                              Delete
                            </button>
                          </td> */}
                        </tr>
                      ) : pricingType == 2 ? (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                                {moment(custompricing.custom_date).format('DD/MM/YYYY')}
                            </td>

                            <td>{custompricing.slot_name}</td>
                            <td>{this.state.currency}{custompricing.base_price_adult}</td>

                            <td>{this.state.currency}{custompricing.base_price_child}</td>

                            <td>{this.state.currency}{custompricing.base_price_infants}</td>

                            <td>{this.state.currency}{custompricing.base_price_age_special}</td>

                          
                            <td>
                                   <button
                                    className={"package-remove-btn pricing-action-btn " + (custompricing.status === 1 ? '' : 'approved-btn-clr')}
                                    type="submit"
                                    onClick={(event) =>
                                      this.updateCustomPricingStatus(event, custompricing)
                                    }>
                                  {custompricing.status === 1 ? "Decline" : "Approve"}
                                  </button>
                            </td>

                            {/* <td>
                              <button
                                className="package-remove-btn pricing-action-btn"
                                type="submit"
                                onClick={(event) =>
                                  this.deleteCustomPricing(event, custompricing)
                                }
                              >
                                Delete
                              </button>
                            </td> */}
                          
                          </tr>
                        ) : (
                          ""
                        ))
                      : "No data Found"}
                  </tbody>
                </table></div>

              ) : ''}




                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div className="media user-profile-sec">
                            <div className="media-body">
                              {loading ? (
                                "Loading..."
                              ) : pricingType == 3 ? (
                                <>
                                  <div className="row">
                                    <h2 className="pull-left">
                                      Add your packages{" "}
                                    </h2>
                                  </div>
                                  <hr></hr>
                                  <div className="package-content-1">
                                    <div className="">
                                      <div className="form-group">
                                        <label className="light-txt">
                                          Package Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Name"
                                          name="name"
                                          onChange={
                                            this.handleChangePackagePricing
                                          }
                                        />
                                      </div>

                                      <div className="row">
                                        <div className="form-group col-md-6">
                                          <label className="light-txt">
                                            Min Guests
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number"
                                            name="min_guests"
                                            onChange={
                                              this.handleChangePackagePricing
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label className="light-txt">
                                            Max Guests
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number"
                                            name="max_guests"
                                            onChange={
                                              this.handleChangePackagePricing
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="light-txt">
                                          Base price
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="$0.00"
                                          name="fixed_base_price"
                                          onChange={
                                            this.handleChangePackagePricing
                                          }
                                        />
                                      </div>

                                      <div className="form-group ">
                                        <label className="light-txt">
                                          Description
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Description"
                                          name="description"
                                          onChange={
                                            this.handleChangePackagePricing
                                          }
                                        />
                                      </div>

                                      <button
                                        className="green-btn"
                                        type="submit"
                                        disabled={this.state.buttonDisable}
                                        onClick={this.packagePricingSave}
                                      >
                                        {this.state.loadingContent != null
                                          ? this.state.loadingContent
                                          : "Submit"}
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-12">
                          <br></br>
                          <div>
                            <div>
                              {loading ? (
                                "Loading..."
                              ) : this.state.timeslotShow == true ? (
                                <PricingsTimeSlots
                                  TimeSlotsData={this.state.hostTimeSlots}
                                  UpdateTimeSlot={this.UpdateTimeSlot}
                                  handleTimeSlotChange={
                                    this.handleTimeSlotChange
                                  }
                                  loadingTimeSlot={this.state.loading}
                                  timeSlotInput={this.state.timeSlotInput}
                                  host={this.state.host}
                                ></PricingsTimeSlots>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      {loading ? (
                        "Loading..."
                      ) : pricingType == 3 ? (
                        <div className="row">
                          {loadingPackagePricing
                            ? "Loading..."
                            : packagePricing.length > 0
                            ? packagePricing.map((pricing) => (
                                <div
                                  className="col-md-4 mrg-btm-package"
                                  key={pricing.host_pricing_package_id}
                                >
                                  <div className="host-package-data">
                                    <div className="host-package-info">
                                      <h4>
                                        <span className="text-muted">
                                          Name:
                                        </span>{" "}
                                        <span>
                                          <b>{pricing.name}</b>
                                        </span>
                                      </h4>

                                  <h4>
                                        <span className="text-muted">
                                          Date:
                                        </span>{" "}
                                        {pricing.custom_date ? 
                                        <span>
                                          <b>{moment(pricing.custom_date).format('DD/MM/YYYY')}</b>
                                        </span>
                                        : ''}</h4>
                                      <h4>
                                        <span className="text-muted">
                                          Min Guest :
                                        </span>{" "}
                                        <span>{pricing.min_guests}</span>
                                      </h4>
                                      <h4>
                                        <span className="text-muted">
                                          Max Guest:
                                        </span>{" "}
                                        <span>{pricing.max_guests}</span>
                                      </h4>
                                      <h4>
                                        <span className="text-muted">
                                          Amount:
                                        </span>{" "}
                                        <span>
                                          {pricing.base_price_formatted}
                                        </span>
                                      </h4>
                                      <h4>
                                        <span className="text-muted">
                                          Description :
                                        </span>{" "}
                                        <span>{pricing.description}</span>
                                      </h4>
                                    </div>
                                    <div className="btn-bottom">
                                      <button
                                        className="package-remove-btn"
                                        type="submit"
                                        onClick={(event) =>
                                          this.deletePackage(event, pricing)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostSpecialPricing);
