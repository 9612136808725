import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Helper from "../../Helper/Helper";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";

import "react-dates/initialize";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import moment from 'moment';
import Select from 'react-select'
import CustomSelect from "./CustomSelect";
import 'moment-timezone';
import InputSelect from "./InputSelect";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

var yesterday = moment().subtract(1, 'day');
var valid = function (current) {
  return current.isAfter(yesterday);
};

const onlyMonth = {
  month: "numeric",
};
const onlyYear = {
  year: "numeric",
};

const $ = window.$;

var today = new Date();
today.setDate(today.getDate());


class MobileTripBookingForm extends Helper {

  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
      isEnd: false,
      selectedOption: null,
      options: null,
      notAvailableDates: [],
      guestDropDown: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.props.setHostId();
    window.addEventListener('scroll', this.handleScroll, true);
    const inputData = {
      host_id: this.props.basicDetails.host_id,
      month: new Date().toLocaleDateString("en-CA", onlyMonth),
      year: new Date().toLocaleDateString("en-CA", onlyYear),
      loops: 2,
    };
    this.getAvailabilityApiCall(inputData);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  getAvailabilityApiCall = (inputData) => {
    api.postMethod("host_availability_view", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          availabilities: response.data.data,
        });
        setTimeout(() => {
          if (this.state.availabilities != null) {
            let notAvalialbe = []
            // if (this.props.basicDetails.booking_type == 1) {
            this.state.availabilities.data.map((data) => {
              if (data.is_booked == 1) {
                var date = moment(data.date, 'YYYY-MM-DD')
                notAvalialbe.push(date.format('DD-MM-YYYY'));
              }
            });
            this.setState({ notAvailableDates: notAvalialbe, });
            // }else{
            //   this.setState({ loading: false, });
            // }
          } else {
          }
        }, 3000);

        // if (this.state.num > 1) 
        // console.log(this.state.selected[0].c);
      }
    });
  };

  getDefaultArravingTime = (checkin) => {
    // return checkin;
    return new Date(checkin);
  };

  handleScroll = () => {
    if (window.innerWidth > 1200) {
      const targetElement = $('#bookingForm').offset().top;

      const totalHeight = $('.main').height() - 150;

      if ($(window).scrollTop() > targetElement - 114 && $(window).scrollTop() < totalHeight) {
        this.setState({
          ...this.state,
          fixed: true,
          isEnd: false
        })
      } else if ($(window).scrollTop() > totalHeight) {
        this.setState({
          ...this.state,
          fixed: false,
          isEnd: true
        })
      }
      else {
        this.setState({
          ...this.state,
          fixed: false,
          isEnd: false
        })
      }
    }
  };

  disabledDate(current, notAvailableDates) {
    return (
      //!disabledDates.find(date => date === moment(current).format("DD-MM-YYYY"))
      !notAvailableDates.find(date => date === moment(current).format("DD-MM-YYYY"))
    )
  }

  handleDropdown() {
    this.setState({
      ...this.state,
      guestDropDown: true
    })
  }

  closeDropDown = () => {
    this.setState({
      ...this.state,
      guestDropDown: false
    })
  }

  render() {
    const { basicDetails, status } = this.props;
    const priceCalInput = this.props.priceCalInput;
    const {
      apiResponse,
      loadingBookingForm,
      apiCallStatus,
      buttonDisable,
      buttonLoadingContent,
      loadingPromoCode,
      promoCodeApiResponse,
      addonServicesData,
      loadingAddonService,
      timeSlotLoading,
      timeSlotData,
      pricingPackagesLoading,
      promoCodeData,
      data

    } = this.props;

    const { notAvailableDates, guestDropDown } = this.state;
    let inputProps = {
      placeholder: 'Choose Date',
    };
    let checkinProps = {
      placeholder: 'Check-in',
    };
    let checkoutProps = {
      placeholder: 'Check-out',
    };

    let userPromoCodes = apiResponse ? apiResponse.user_promo_codes : [];

    let currency = apiResponse ? apiResponse.currency : '';

    let usersList = this.props.usersList;

    let pricingPackagesData = [];

    pricingPackagesData = this.props.pricingPackagesData;

    return (
      <div className="col-xl-5 pl-5 relative booking-form-order-list mobile-trip-booking-form" id="bookingForm">
        <div className={`pricedetails-box ${this.state.fixed ? "fixedBookingForm" : this.state.isEnd ? "stickyInTheEnd" : null}`} >
          <div className="price-align-left d-flex justify-content-between align-items-center">
            {/* <h3 className="home-price-details price-info-left">
              {basicDetails.per_day_formatted}
              <small>Ratings</small>
            </h3> */}
            <div className="ratingWrapper">
              {/* <span className="rating">
                <i class="fas fa-star"></i>
              </span> */}
              <span className="rating">
                {this.starRatingHost(this.props.singleTripDetails.basic_details.admin_ratings)}
              </span>
              <span className="ratingText">
                {this.props.singleTripDetails.basic_details.admin_ratings == "" ? 0 : this.props.singleTripDetails.basic_details.admin_ratings} {"Ratings"}
              </span>
              {/* <span className="reviewText">
                ( {this.props.singleTripDetails.provider_details.total_reviews_formatted})
              </span> */}
            </div>
            {/* <h3 className="home-price-details price-info-right">
              {this.props.singleTripDetails.pricing_details.security_deposit ==
              0
                ? "No"
                : this.props.singleTripDetails.pricing_details
                    .security_deposit_formatted}
              <small> Security Deposit</small>
            </h3> */}
          </div>
          {/* <h5 className="rating-sec">
            <span className="rating-star">
              {this.starRatingHost(basicDetails.overall_ratings)}
            </span>
            <sup className="rating-sup">
              &nbsp;{basicDetails.total_ratings} reviews
            </sup>
          </h5> */}
          <p className="overview-line1" />

          <div className="form-group">
            <label className="medium-cls">dates</label>
            <div className="input-group">
              {/* <DateRangePicker /> */}
              {basicDetails.booking_type === 1 ? (
                <>
                  <div className="custom-select-alike">

                    <DatePicker
                      onChange={this.props.onNormalBookingChangeDate}
                      minDate={today}
                      excludeDates={[today]}
                      disabled={this.props.singleTripDetails.basic_details.custom_pricing_request.status == 0 ? true : false}
                      selected={this.props.priceCalInput.checkin ? this.getDefaultArravingTime(this.props.priceCalInput.checkin_formatted) : ''}
                      inputProps={inputProps}
                      placeholderText="Click to select a date"
                      filterDate={(date) => this.disabledDate(date, notAvailableDates)}
                    />

                  </div>

                </>
              ) : null}




              {basicDetails.booking_type === 2 ? (
                <>
                  <div className="form-group custom-select-alike">
                    <DatePicker
                      // selected={new Date()}
                      onChange={this.props.onChangeDate}
                      // onChange={this.props.onChangeDate("Range DatePicker")}
                      minDate={today}
                      excludeDates={[today]}
                      disabled={this.props.singleTripDetails.basic_details.custom_pricing_request.status == 0 ? true : false}
                      // dateFormat="dd/MM/yyyy"
                      selected={this.props.priceCalInput.checkin ? this.getDefaultArravingTime(this.props.priceCalInput.checkin_formatted) : ''}
                      placeholderText="Click to select a date"
                      inputProps={inputProps}
                      filterDate={(date) => this.disabledDate(date, notAvailableDates)}
                    />
                  </div>



                  {timeSlotLoading ? (
                    ""
                  ) : timeSlotData.length > 0 ? (
                    <>

                      <CustomSelect data={timeSlotData} placeHolder={"choose time slot"} handleChange={this.props.customSelectTimeSlotSelected} watcher={this.props.priceCalInput} />
                    </>
                  ) : null}
                </>
              ) : null}

            </div><br></br>

            {pricingPackagesLoading ? (
              ""
            ) : (pricingPackagesData.length > 0 && basicDetails.pricing_type === 3) ? (
              <div className="form-group w-100">

                <select
                  onChange={this.props.packagePricingSelected}
                  className="form-control same-date-picker-input packageDropdown" id="packageDropdown"
                >
                  <option value=""
                    disabled={this.props.singleTripDetails.basic_details.custom_pricing_request.status == 0 ? true : false}
                    selected="selected">Choose Pricing Package</option>
                  {pricingPackagesData.map((data, index) => (

                    <option
                      key={index}
                      value={data.host_pricing_package_id}
                      disabled={this.props.singleTripDetails.basic_details.custom_pricing_request.status == 0 ? true : false}
                      selected={this.props.priceCalInput.host_pricing_package_id === data.host_pricing_package_id}
                    >
                      {data.name} - {data.base_price_formatted}
                    </option>
                  ))}
                  <option
                    value="custom_price"
                    selected={this.props.priceCalInput.host_pricing_package_id === "custom_price"}
                  >
                    Custom Price
                  </option>
                </select>

              </div>
            ) : null}


            {localStorage.getItem('is_frontdesk') == 1 ? (

              <div className="form-group w-100">
                <label className="medium-cls">Guest Name</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="user_name"
                    onChange={(event) => this.props.handleUserDetailsChange(event)}
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
            ) : ""}

            {localStorage.getItem('is_frontdesk') == 1 ? (
              <div className="form-group w-100">
                <label className="medium-cls">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    name="email"
                    onChange={(event) => this.props.handleUserDetailsChange(event)}
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
            ) : ""}

            {localStorage.getItem('is_frontdesk') == 1 ? (
              <div className="form-group w-100">
                <label className="medium-cls">Mobile</label>
                <div className="input-group">
                  <input
                    type="number"
                    name="mobile"
                    onChange={(event) => this.props.handleUserDetailsChange(event)}
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
            ) : ""}

            {localStorage.getItem('is_frontdesk') == 1 ? (

              <div className="form-group w-100">
                <label className="medium-cls">Payment Mode</label>
                <div className="input-group">
                  <select class="form-control form-control-lg" name="payment_mode"
                    onChange={(event) => this.props.handleUserDetailsChange(event)}
                  >
                    <option value="">Select payment mode</option>
                    <option value="card">Card</option>
                    <option value="cash">Cash</option>
                  </select>
                </div>
              </div>
            ) : ""}


          </div>
          <div className="form-group">
            {basicDetails.pricing_type != 3 ?
              <div className="guest-dropdown-wrapper">
                <label className="medium-cls">Guests</label>
                <input
                  type="text"
                  id="guest-mobile-btn"
                  // data-toggle="dropdown"
                  onClick={() => this.handleDropdown()}
                  value={
                    "Adult:" +
                    priceCalInput.adults +
                    ", Children: " +
                    priceCalInput.children +
                    ", Infants: " +
                    priceCalInput.infants +
                    ", Special Needs: " +
                    priceCalInput.age_special
                  }
                  className="form-control form-control-lg"
                />

                <div className={`dropdown-menu guest ${guestDropDown ? "show" : ""}`}>
                  <div className="backdrop" onClick={this.closeDropDown}></div>
                  <form className="inc-dec-count">
                    <div className="row">
                      <div className="col-6 col-sm-6">
                        <label htmlFor="name">adults</label>
                      </div>
                      <div className="col-6 col-sm-6 text-right">
                        <div className="float-right">
                          <div
                            className="decrement-btn"
                            onClick={() => this.props.decreament("adults")}
                          >
                            <div className="dec button">
                              <i className="fas fa-minus" />
                            </div>
                          </div>
                          <input
                            type="text"
                            value={priceCalInput.adults}
                            name="adults"
                          />
                          <div
                            className="increment-btn"
                            onClick={() => this.props.increament("adults")}
                          >
                            <div className="inc button">
                              <i className="fas fa-plus" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 col-sm-6">
                        <label htmlFor="name">children</label>
                      </div>
                      <div className="col-6 col-sm-6 text-right">
                        <div className="float-right">
                          <div
                            className="decrement-btn"
                            onClick={() => this.props.decreament("children")}
                          >
                            <div className="dec button">
                              <i className="fas fa-minus" />
                            </div>
                          </div>
                          <input
                            type="text"
                            value={priceCalInput.children}
                            name="children"
                          />
                          <div
                            className="increment-btn"
                            onClick={() => this.props.increament("children")}
                          >
                            <div className="inc button">
                              <i className="fas fa-plus" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 col-sm-6">
                        <label htmlFor="name">infants</label>
                      </div>
                      <div className="col-6 col-sm-6 text-right">
                        <div className="float-right">
                          <div
                            className="decrement-btn"
                            onClick={() => this.props.decreament("infants")}
                          >
                            <div className="dec button">
                              <i className="fas fa-minus" />
                            </div>
                          </div>
                          <input
                            type="text"
                            value={priceCalInput.infants}
                            name="infants"
                          />
                          <div
                            className="increment-btn"
                            onClick={() => this.props.increament("infants")}
                          >
                            <div className="inc button">
                              <i className="fas fa-plus" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 col-sm-6">
                        <label htmlFor="name">Special Needs</label>
                      </div>
                      <div className="col-6 col-sm-6 text-right">
                        <div className="float-right">
                          <div
                            className="decrement-btn"
                            onClick={() => this.props.decreament("age_special")}
                          >
                            <div className="dec button">
                              <i className="fas fa-minus" />
                            </div>
                          </div>
                          <input
                            type="text"
                            value={priceCalInput.age_special}
                            name="age_special"
                          />
                          <div
                            className="increment-btn"
                            onClick={() => this.props.increament("age_special")}
                          >
                            <div className="inc button">
                              <i className="fas fa-plus" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              : ''}

            <p className="overview-line mt-3 mb-3" />
            {loadingBookingForm ? (
              ""
            ) : (
              <React.Fragment>
                <div className="row">
                  <div className="col-6">
                    {basicDetails.pricing_type == 1 ? (
                      <h5 className="choosen-details">Base Price</h5>
                    ) : null}
                    {basicDetails.pricing_type == 2 ? (
                      <h5 className="choosen-details">Total Guest Price</h5>
                    ) : null}
                    {basicDetails.pricing_type == 3 ? (
                      <h5 className="choosen-details">Total Package Price</h5>
                    ) : null}
                    {/* <h5 className="choosen-details">Cleaning fee</h5> */}

                    {apiResponse.host_addon_service_ids.length > 0 && !loadingAddonService ? (
                      <>
                        <div className="BreakdownWrapper">
                          <h5 className="choosen-details breakdown-text" onClick={() => this.props.handleBreakdownModal("addons")}>
                            Add-on services
                            <span>
                              <i class="far fa-question-circle"></i>
                            </span>
                          </h5>
                          <div className={`breakdownModal ${this.props.activeBreakdown == "addons" ? "active" : null}`}>
                            <div className="bookingBreakdownwrapper column">
                              <div className="breakdownHeading">
                                <span className="close" onClick={this.props.handlebreakdownClose}>
                                  <i class="fas fa-times"></i>
                                </span>
                                <p className="breakdownheading">Addon Price Breakdown</p>
                              </div>
                              <p className="overview-line1 my-3 "></p>
                              <div className="breakdownBody">
                                {apiResponse.host_addon_service_ids.length > 0 && apiResponse.host_addon_service_ids.map((responseServiceID) =>
                                  addonServicesData.length > 0 && addonServicesData.filter((service) => service.unit_type == "booking").map(
                                    (service) => responseServiceID ==
                                      service.host_addon_service_id && (
                                        <>
                                          <div className="taxwrapper">
                                            <span>
                                              {service.name}
                                            </span>
                                            <span>
                                              {service.currency}{" "}
                                              {this.props.priceCalInput[
                                                `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                              ] * service.amount}
                                            </span>
                                          </div>
                                        </>
                                      )))}
                                {apiResponse.host_addon_service_ids.length > 0 && apiResponse.host_addon_service_ids.map((responseServiceID) =>
                                  addonServicesData.length > 0 && addonServicesData.filter((service) => service.unit_type == "person" || service.unit_type == "item").map(
                                    (service) => responseServiceID ==
                                      service.host_addon_service_id && (
                                        <>
                                          <div className="taxwrapper">
                                            <span>
                                              {service.name}
                                              {
                                                service.unit_type == "person" || service.unit_type == "item" ? (
                                                  <>
                                                    <span className="mx-2 font-weight-bold">x</span>
                                                    <span>
                                                      {priceCalInput[
                                                        `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                                      ]}
                                                    </span>
                                                  </>
                                                ) : ''
                                              }
                                            </span>
                                            <span>
                                              {service.currency}{" "}
                                              {this.props.priceCalInput[
                                                `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                              ] * service.amount}
                                            </span>
                                          </div>
                                        </>
                                      )))}
                              </div>
                              <p className="overview-line1 my-3 "></p>
                              <div className="breakdownFooter">
                                <>
                                  <span>
                                    Total Addon Price
                                  </span>
                                  <span>
                                    {apiResponse.host_addon_services_total}
                                  </span>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="BreakdownWrapper">
                      <h5 className="choosen-details breakdown-text" onClick={() => this.props.handleBreakdownModal("service")}>
                        Service fee
                        <span>
                          <i class="far fa-question-circle"></i>
                        </span>
                      </h5>
                      <div className={`breakdownModal ${this.props.activeBreakdown == "service" ? "active" : null}`}>
                        <div className="bookingBreakdownwrapper">
                          <span className="close" onClick={this.props.handlebreakdownClose}>
                            <i class="fas fa-times"></i>
                          </span>
                          <p>This helps us run our platform and offer services like world class support.</p>
                        </div>
                      </div>
                    </div>

                    <div className="BreakdownWrapper">
                      <h5 className={`choosen-details ${apiResponse.tax_status == 1 && apiResponse.tax_price != 0 && "breakdown-text"}`}
                        onClick={apiResponse.tax_status == 1 && apiResponse.tax_price != 0 ? () => this.props.handleBreakdownModal("tax") : null}>
                        Taxes
                        {apiResponse.tax_status == 1 && apiResponse.tax_price != 0 && (
                          <span>
                            <i class="far fa-question-circle"></i>
                          </span>
                        )}
                      </h5>
                      <div className={`breakdownModal ${this.props.activeBreakdown == "tax" ? "active" : null}`}>
                        <div className="bookingBreakdownwrapper column">
                          <div className="breakdownHeading">
                            <span className="close" onClick={this.props.handlebreakdownClose}>
                              <i class="fas fa-times"></i>
                            </span>
                            <p className="breakdownheading">Tax Breakdown</p>
                          </div>
                          <p className="overview-line1 my-3 "></p>
                          <div className="breakdownBody">
                            {apiResponse.general_tax_price != 0 && (
                              <div className="taxwrapper">
                                <span>
                                  GST - {apiResponse.general_tax_in_percentage_formatted}
                                </span>
                                <span>
                                  {apiResponse.general_tax_price_formatted}
                                </span>
                              </div>
                            )}
                            {apiResponse.pst_tax_price != 0 && (
                              <div className="taxwrapper">
                                <span>
                                  PST - {apiResponse.pst_tax_in_percentage_formatted}
                                </span>
                                <span>
                                  {apiResponse.pst_tax_price_formatted}
                                </span>
                              </div>
                            )}
                            {apiResponse.hst_tax_price != 0 && (
                              <div className="taxwrapper">
                                <span>
                                  HST - {apiResponse.hst_tax_in_percentage_formatted}
                                </span>
                                <span>
                                  {apiResponse.hst_tax_price_formatted}
                                </span>
                              </div>
                            )}
                          </div>
                          <p className="overview-line1 my-3 "></p>
                          <div className="breakdownFooter">
                            <>
                              <span>
                                Total Tax Price
                              </span>
                              <span>
                                {apiResponse.tax_price_formatted}
                              </span>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {this.props.singleTripDetails.pricing_details
                      .security_deposit ? this.props.singleTripDetails.pricing_details
                      .security_deposit == 0 ? (
                      ""
                    ) : (
                      <h5 className="choosen-details">Security Deposit</h5>
                    ) : null} */}

                    {loadingPromoCode ? (
                      ""
                    ) : (


                      <div className="BreakdownWrapper">
                        <h5 className={`choosen-details breakdown-text color-red`}
                          onClick={() => this.props.handleBreakdownModal("promocode")}>
                          Promo Code
                          <span>
                            <i class="far fa-question-circle"></i>
                          </span>
                        </h5>
                        <div className={`breakdownModal ${this.props.activeBreakdown == "promocode" ? "active" : null}`}>
                          <div className="bookingBreakdownwrapper column">
                            <div className="breakdownHeading">
                              <span className="close" onClick={this.props.handlebreakdownClose}>
                                <i class="fas fa-times"></i>
                              </span>
                              <p className="breakdownheading">Promo Code Information</p>
                            </div>
                            <p className="overview-line1 my-3 "></p>
                            <div className="breakdownBody">
                              <div className="taxwrapper">
                                <span>
                                  <h4>Used Code </h4>
                                </span>
                                <span>
                                  {promoCodeApiResponse.promo_code_details.promo_code}
                                </span>
                              </div>

                            </div>

                            <p className="overview-line1 my-3 "></p>
                            <div className="breakdownBody">
                              <div className="taxwrapper">
                                <span>
                                  <h4>Calculation</h4>
                                </span>
                                <span>
                                  {promoCodeApiResponse.request_amount} -

                                  {promoCodeApiResponse.promo_amount_formatted}
                                </span>
                              </div>

                            </div>


                            <p className="overview-line1 my-3 "></p>
                            <div className="breakdownBody">
                              <div className="taxwrapper">
                                <span>
                                  {promoCodeApiResponse.promo_code_details.description}
                                </span>
                              </div>

                            </div>

                            <p className="overview-line1 my-3 "></p>
                            <div className="breakdownFooter">
                              <>
                                <span>
                                  Total Payable
                                </span>
                                <span>
                                  {promoCodeApiResponse.total_formatted}
                                </span>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>



                    )}





                  </div>
                  <div className="col-6 text-right">
                    {basicDetails.pricing_type == 1 ? (
                      <h5 className="choosen-details">
                        {apiResponse.base_price}
                      </h5>
                    ) : null}

                    {basicDetails.pricing_type == 2 ? (
                      <h5 className="choosen-details">
                        {apiResponse.total_guest_price_formatted}
                      </h5>
                    ) : null}

                    {basicDetails.pricing_type == 3 ? (
                      <h5 className="choosen-details">
                        {apiResponse.package_base_price}
                      </h5>
                    ) : null}

                    {/* <h5 className="choosen-details">
                      {apiResponse.cleaning_fee}
                    </h5> */}
                    {apiResponse.host_addon_service_ids.length > 0 && !loadingAddonService ? (
                      <h5 className="choosen-details">
                        {apiResponse.host_addon_services_total}
                      </h5>
                    ) : null}
                    <h5 className="choosen-details">
                      {apiResponse.service_fee}
                    </h5>
                    <h5 className="choosen-details ">
                      {apiResponse.tax_price_formatted}
                    </h5>
                    {/* {this.props.singleTripDetails.pricing_details
                      .security_deposit == 0 ? (
                      ""
                    ) : (
                      <h5 className="choosen-details">
                        {
                          this.props.singleTripDetails.pricing_details
                            .security_deposit_formatted
                        }
                      </h5>
                    )} */}
                    {loadingPromoCode ? (
                      ""
                    ) : (
                      <h5 className="choosen-details breakdown-text color-red">
                        - {promoCodeApiResponse.promo_amount_formatted}
                      </h5>
                    )}
                  </div>
                </div>






                {/* <div className="row">
                  {apiResponse.host_addon_service_ids.length > 0
                    ? apiResponse.host_addon_service_ids.map(
                        (responseServiceID) =>
                          addonServicesData.length > 0
                            ? addonServicesData.map((service) =>
                                responseServiceID ==
                                service.host_addon_service_id ? (
                                  <React.Fragment>
                                    <div className="col-6">
                                      <h5 className="choosen-details">
                                        {service.name}
                                      </h5>
                                    </div>
                                    <div className="col-6">
                                      <h5 className="choosen-details text-right">
                                        {service.currency}{" "}
                                        {this.props.priceCalInput[
                                          `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                        ] * service.amount}
                                      </h5>
                                    </div>
                                    
                                    {service.unit_type != "booking" ? (
                                      <div className="col-8">
                                        <div className="form-group">
                                          <form
                                            onSubmit={
                                              this.props.addonServiceCallAPI
                                            }
                                          >
                                            <div className="input-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="addonServiceQuan"
                                                placeholder={service.min_value}
                                                onChange={(event) =>
                                                  this.props.addonServiceQuantity(
                                                    event,
                                                    service
                                                  )
                                                }
                                                name={`host_addon_services[${service.host_addon_service_id}][no_of_units]`}
                                                value={
                                                  this.props.priceCalInput[
                                                    `host_addon_services[${service.host_addon_service_id}][no_of_units]`
                                                  ]
                                                }
                                              />
                                              <button
                                                type="submit"
                                                className="form-control"
                                              >
                                                Add
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    
                                  </React.Fragment>
                                ) : (
                                  ""
                                )
                              )
                            : ""
                      )
                    : ""}
                </div> */}

                <p className="overview-line1" />

                <div className="form-group" >
                  <form onSubmit={this.props.checkPromoCode}>
                    <label className="medium-cls">Promo Code</label>
                    <div className="input-group">
                      <InputSelect data={promoCodeData}
                        placeHolder={"Promo code"}
                        className="form-control select-width promo-code-input"
                        currency={apiResponse.currency}
                        handleInputChange={this.props.getPromoCode}
                        handleChange={this.props.getPromoCodeSelect}
                        promoCodeInputData={this.props.promoCodeInputData} />
                      {/* <Select 
                        options={promoCodeData}
                        name="promo_code"
                        searchable={true} 
                        className="form-control select-width"
                        create={true}
                        keepSelectedInList={true}
                        dropdownHandle={true}
                        onChange={values => this.props.getPromoCode(values)}
                        placeholder="Promo code"
                        noDataLabel="No matches found"
                      /> */}
                      <button type="submit" className="form-control">
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
                {/*                 
                <div className="form-group" >
                  <form onSubmit={this.props.checkPromoCode}>
                    <label className="medium-cls">Promo Code</label>
                   
                    {userPromoCodes.length < 1 ? 

                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="promo_code"
                        placeholder="Promo code"
                        onChange={this.props.getPromoCode}
                        name="promo_code"
                        value={this.props.promoCodeInputData.promo_code}
                      />
                      <button type="submit" className="form-control">
                        Apply
                      </button>
                    </div>
                    : 
                    <div className="input-group">
                      <select name="promo_code" style={{'width':'190px'}} className="form-control" onChange={this.props.getPromoCode}>
                        <option key=''>Select Promo Codes</option>
                      {userPromoCodes.map(promocodes =>
                        promocodes.is_available == 1 ?
                        <option key={promocodes.promo_code} value={promocodes.promo_code}>
                          {promocodes.title} &nbsp;&nbsp; -
                           Get &nbsp; {parseInt(promocodes.amount_type) === 1 ? currency :'' } {promocodes.amount} {parseInt(promocodes.amount_type) === 0 ? '%':'' } OFF using the Promo code
                           &nbsp;&nbsp;
                           {promocodes.promo_code} 
                        </option>
                        : ''
                      )};
                      </select>
                      <button type="submit" className="form-control" style={{'width':'100px'}}>
                        Apply
                      </button>
                    </div>
                    }
                  </form>
                </div> */}

                {loadingPromoCode ? (
                  ""
                ) : (
                  <a
                    href="#"
                    onClick={this.props.removePromoCode}
                    className="remove-promo-btn"
                  >
                    Remove Promo
                    <i class="fas fa-chevron-right ml-2 vertical-align"></i>
                  </a>
                )}

                <p className="overview-line  mt-3 mb-0" />
                <div className="row">
                  <div className="col-6">
                    <h5 className="choosen-details mt-3">Total (CAD)</h5>
                  </div>
                  <div className="col-6 text-right">
                    <h5 className="choosen-details mt-3">
                      {apiCallStatus
                        ? apiResponse.actual_total_formatted
                        : this.props.total}
                    </h5>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          {basicDetails.pricing_type == 2 ? (
            <>
              NOTE:
              <p>
                Adult Price: ({basicDetails.adult_range}){" "}
                <span className="text-info">
                  {apiCallStatus ?
                    apiResponse.currency + apiResponse.host_base_price_adult :
                    basicDetails.currency + basicDetails.base_price_adult}
                </span>
              </p>
              <p>
                Child Price: ({basicDetails.child_range}){" "}
                <span className="text-info">
                  {apiCallStatus ?
                    apiResponse.currency + apiResponse.host_base_price_child :
                    basicDetails.currency + basicDetails.base_price_child}
                </span>
              </p>
              <p>
                Infants Price: ({basicDetails.infants_range})
                <span className="text-info">
                  {apiCallStatus ?
                    apiResponse.currency + apiResponse.host_base_price_infants :
                    basicDetails.currency + basicDetails.base_price_infants}
                </span>
              </p>
              <p>
                Special Needs Price: ({basicDetails.age_special_range})
                <span className="text-info">
                  {apiCallStatus ?
                    apiResponse.currency + apiResponse.host_base_price_age_special :
                    basicDetails.currency + basicDetails.base_price_age_special}
                </span>
              </p>
            </>
          ) : null}

          {status == "host" ? localStorage.getItem("is_frontdesk") == 1 ? (
            <button
              className="pink-btn btn-block mb-2"
              onClick={this.props.bookingCreate}
            >
              Book now
            </button>
          ) : null : (
            <button
              className="pink-btn btn-block mb-2"
              disabled={buttonDisable}
              onClick={this.props.bookNow}
            >
              {buttonLoadingContent == null ? (this.props.priceCalInput.host_pricing_package_id === "custom_price" && this.props.priceCalInput.is_custom_pricing_request == 0 ? "Chat" : "Book") : buttonLoadingContent}
            </button>
          )}
          <h5 className="small-text">you won’t be charged yet</h5>
          {/* <p className="overview-line mt-3 mb-0" />
          <div className="media align-items-center">
            <div className="media-body">
              <h4 className="home-info">This home is on people’s minds.</h4>
              <p className="home-info-text text-captilize">
                Pre-Book the space and enjoy your event!
              </p>
            </div>
            <img
              src={window.location.origin + "/assets/img/light.gif"}
              className="align-self-center ml-3 rounded-circle review-img1"
              alt="you won’t be charged yet"
            />
          </div> */}
          <p className="overview-line mt-3 mb-0" />
          <div className="media align-items-center">
            <img className="comodo-logo" src={"https://ssl.comodo.com/images/seals/sectigo_trust_seal_lg_2x.png"} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(MobileTripBookingForm);
