import { useEffect } from "react";

export default function ScrollToTop({history}) {
  const { pathname } = history.location;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'});
  }, [pathname]);

  return null;
}