import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class EditListingStep8 extends Component {
  state = {
    loading: true,
    data: null,
    formData: {},
    loadingContent: null,
    submitClicked: false,
    isInputChanged: false,
  };

  componentDidMount() {
    this.step8ApiCall();
  }

  handle8Change = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    if (input.type === "checkbox") {
      if (input.checked) {
        if (formData[input.name] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          formData[input.name] = array;
          this.setState({ formData });
        } else {
          const value = parseInt(input.value);
          formData[input.name].push(value);
          this.setState({ formData });
        }
      } else {
        if (formData[input.name]) {
          const array = formData[input.name];
          const value = parseInt(input.value);
          let index = formData[input.name].indexOf(value);
          const found = array.find(element => element == value);
          if (found) {
            formData[input.name].splice(index, 1);
            this.setState({ formData });
          }
        }
      }
    } else {
      const formData = { ...this.state.formData };
      formData[input.name] = input.value;
      this.setState({ formData });
    }

    this.setState({ isInputChanged: true });
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step7/${this.props.match.params.host_id}`
    );
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    if (this.state.isInputChanged) {
      api
        .postMethod("hosts_step8_save", this.state.formData)
        .then((response) => {
          if (response.data.success) {
            this.props.history.push(
              `/host/listing-step9/${this.props.match.params.host_id}`
            );
          } else {
            // alert(response.data.error);
            if(response.data.error_code == 1004){
              this.props.toggleLoginModal(false);
            }
            this.setState({
              loadingContent: null,
              submitClicked: false,
            });
          }
        });
    }
    this.props.history.push(
      `/host/listing-step9/${this.props.match.params.host_id}`
    );
    this.setState({
      loadingContent: null,
      submitClicked: false,
    });
  };

  step8ApiCall = () => {
    api
      .postMethod("rule_lookups", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            data: response.data.data.host,
            OtherRuleLoading: false,
            OtherHouseRules: response.data.data.house_rules,
            OtherCancellationPolicies: response.data.data.cancellation_policies,
            formData: {
              host_id: this.props.match.params.host_id,
              house_rules: response.data.data.host.house_rules,
              cancellation_policy: response.data.data.host.cancellation_policy,
              other_house_rule_ids: response.data.data.host.house_rule_ids ? response.data.data.host.house_rule_ids : [],
              other_cancellation_policy_ids: response.data.data.host.cancellation_policy_ids ? response.data.data.host.cancellation_policy_ids : [],
            },
            loading: false,
          });
        } else {
          alert(response.data.error);
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  render() {
    const { data, loading, formData } = this.state;

    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>Step 9: Rules & Policies</span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          {/* <div className="add-flow"> */}
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  <div id="step-11" className="flow-tab">
                    <div className="add-flow-block row">
                      <div className="col-md-12">
                        <div className="add-flow-block-left">
                          <h4 className="flow-tit">Rules & Policies</h4>
                          {loading ? (
                            "Loading..."
                          ) : (
                            <>
                              <p className="main-txt">
                                Set house rules & cancellation policy for your
                                listing, either pick the applicable rules from
                                the list or create your own rules by providing
                                it in the textbox below
                              </p>

                              <div className="flow-content">
                                <div className="form-group large-form-group">
                                  <label className="light-txt">
                                    House Rules
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Cleaning manangement, Noise level etc..."
                                    name="house_rules"
                                    value={formData["house_rules"]}
                                    onChange={this.handle8Change}
                                    defaultValue={this.state.data.house_rules}
                                  />
                                </div>

                                <>
                                  {this.state.OtherHouseRules.map(
                                    (OtherHouseRule) => (
                                      <div
                                        className="form-group form-check tick-check"
                                        key={
                                          `other_house_rule_ids` +
                                          OtherHouseRule.id
                                        }
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="other_house_rule_ids"
                                          id={
                                            `other_house_rule_ids` +
                                            OtherHouseRule.rule_lookup_id
                                          }
                                          value={OtherHouseRule.rule_lookup_id}
                                          onChange={this.handle8Change}
                                          defaultChecked={
                                            OtherHouseRule.is_selected == 1
                                              ? true
                                              : false
                                          }
                                        />

                                        <label
                                          className="form-check-label"
                                          htmlFor={
                                            `other_house_rule_ids` +
                                            OtherHouseRule.rule_lookup_id
                                          }
                                        >
                                          <div>
                                            <h5 className="amen-tit">
                                              <b>{OtherHouseRule.content}</b>
                                            </h5>
                                          </div>
                                        </label>
                                        {/* <p className="flow-check-txt text-muted">
                                  {details.description}
                                </p> */}
                                      </div>
                                    )
                                  )}
                                </>

                                <div className="form-group large-form-group">
                                  <label className="light-txt">
                                    Do you have any rules for cancellation?
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Do you have any rules for cancellation?"
                                    name="cancellation_policy"
                                    value={formData["cancellation_policy"]}
                                    onChange={this.handle8Change}
                                    defaultValue={
                                      this.state.data.cancellation_policy
                                    }
                                  />
                                </div>

                                {this.state.OtherCancellationPolicies.map(
                                  (OtherCP) => (
                                    <div
                                      className="form-group form-check tick-check"
                                      key={
                                        `other_cancellation_policy_ids` +
                                        OtherCP.id
                                      }
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="other_cancellation_policy_ids"
                                        id={
                                          `other_cancellation_policy_ids` +
                                          OtherCP.rule_lookup_id
                                        }
                                        value={OtherCP.rule_lookup_id}
                                        onChange={this.handle8Change}
                                        defaultChecked={
                                          OtherCP.is_selected == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={
                                          `other_cancellation_policy_ids` +
                                          OtherCP.rule_lookup_id
                                        }
                                      >
                                        <div>
                                          <h5 className="amen-tit">
                                            <b>{OtherCP.content}</b>
                                          </h5>
                                        </div>
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="listing-footer">
                  <button
                    className="white-btn"
                    type="button"
                    onClick={this.handleBackButton}
                  >
                    <i className="fas fa-chevron-left" /> Back
                  </button>
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={this.state.submitClicked}
                    onClick={this.submitForm}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : "Save & Next"}
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditListingStep8;
