import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

class FrontDeskProfileSideBar extends Component {

    state = {};

    render() {
        return (
                <ul className="account-list">
                    <li>
                        <NavLink
                            activeClassName="active"
                            to={"/host/edit-profile"}
                        >
                            edit profile
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="active" to={"/host/photo"}>
                            photos
                        </NavLink>
                    </li>
                    {localStorage.getItem("login_by") == 'manual' ?
                    <li>
                        <NavLink
                            activeClassName="active"
                            to={"/host/change-password"}
                        >
                            change password
                        </NavLink>
                    </li>
                    : ''}
                    {/* <li>
                        <NavLink
                            activeClassName="active"
                            to={"/host/delete-account"}
                        >
                            delete account
                        </NavLink>
                    </li> */}

                </ul>

        );
    }
}
export default FrontDeskProfileSideBar;
