import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const NewOnPartyBudhaLoaders = (props) => {

    const { count } = props;

    const loaderCount = Array.from(Array(count).keys());

    return (
        <>
            <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
                <div className="site-content">
                    <div className="subcategory-leftsec">
                        <div className="section-title">
                            <Skeleton width={"25%"} height={30} style={{
                                marginBottom: '5px',
                            }}></Skeleton>
                            <Skeleton width={"40%"} height={15} style={{
                                marginBottom: '12px',
                            }}></Skeleton>
                        </div>
                        <div className='skeleton-card-loader'>
                            <div className='row'>
                                {loaderCount.map((loaders) => (
                                    <>
                                        <div className='subcategory-card view-all-card'>
                                            <Skeleton width={"100%"} height={360}></Skeleton>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                        <Skeleton width={"10%"} height={30} style={{
                            marginBottom: '10px',
                            marginTop: '10px'
                        }}></Skeleton>
                    </div>
                </div>
            </SkeletonTheme>
        </>
    )
}

export default NewOnPartyBudhaLoaders;