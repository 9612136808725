import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Search, Grid, Header, Segment } from "semantic-ui-react";
import _ from "lodash";
const DATE_OPTIONS = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

class HostListingManagement extends Component {
  state = {
    listings: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    isLoading: false,
    results: [],
    value: "",
    totalData: 0,
  };

  componentDidMount() {
    this.listingApiCall();
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.message,
        "success"
      );
    }
  }

  handleResultSelect = (e, { result }) =>
    this.setState({ value: result.host_name });

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          isLoading: false,
          results: [],
          value: "",
        });

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = (result) => re.test(result.host_name);

      this.setState({
        isLoading: false,
        results: _.filter(this.state.listings, isMatch),
      });
    }, 300);
  };

  hostStatusChange = (event, host_id) => {
    event.preventDefault();

    api.postMethod("hosts_status", { host_id: host_id }).then((response) => {
      if (response.data.success) {
        this.setState({ loading: true, skipCount: 0, listings: null });
        this.listingApiCall();
        ToastDemo(this.props.toastManager, response.data.message, "success");
      }else{
        this.props.toggleLoginModal(false);
      }
    });
  };

  deleteHost = (event, host_id) => {
    event.preventDefault();
    api.postMethod("hosts_delete", { host_id: host_id }).then((response) => {
      if (response.data.success) {
        this.setState({ loading: true, skipCount: 0, listings: null });
        this.listingApiCall();
        ToastDemo(this.props.toastManager, response.data.message, "success");
      }else{
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      }
    });
  };

  listingApiCall() {
    let items;
    api
      .postMethod("hosts_index", { skip: this.state.skipCount })
      .then((response) => {
        if (response.data.success) {
          if (this.state.listings != null) {
            items = [...this.state.listings, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            listings: items,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadingStatus: true,
            totalData : response.data.data.length,
          });
        }else{
          console.log(response.data.error)
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
        }
      });
  }
  loadMore = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.listingApiCall();
  };

  completionTextColor = (complete_percentage) => {
    if (complete_percentage <= 0) {
      return "text-danger";
    } else if (complete_percentage > 0 && complete_percentage <= 25) {
      return "text-warning";
    } else if (complete_percentage > 25 && complete_percentage <= 75) {
      return "text-info";
    } else if (complete_percentage > 75 && complete_percentage <= 100) {
      return "text-success";
    } else {
      return "display-hide";
    }
  };
  render() {
    const { loading, loadingContent, loadingStatus, totalData } = this.state;
    let listings;
    const { isLoading, value, results } = this.state;
    if (results.length > 0) {
      listings = results;
    } else {
      listings = this.state.listings;
    }
    return (
      <div className="main">
        <div className="container-fluid">
          <div className="rooms top-bottom-spacing">
            <div className="rooms-head">
              <div className="room-filter input-group mb-3">
                <Grid>
                  <Grid.Column width={6}>
                    <Search
                      loading={isLoading}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={_.debounce(this.handleSearchChange, 500, {
                        loading: true,
                      })}
                      placeholder="Search"
                      results={results}
                      value={value}
                      {...this.props}
                    />
                  </Grid.Column>
                </Grid>
              </div>
            </div>
            <div className="room-content">
              <div className="rooms-table table-responsive">
                <table className="cmn-table table">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Listing</th>
                      {/* <th scope="col">
                        Instant Book <i className="fas fa-chevron-down" />
                      </th> */}
                      <th scope="col">
                        Host Type <i className="fas fa-chevron-down" />
                      </th>

                      <th scope="col">
                        Location <i className="fas fa-chevron-down" />
                      </th>
                      {/* <th scope="col">
                        Timezone <i className="fas fa-chevron-down" />
                      </th> */}
                      <th scope="col">
                        Pricings <i className="fas fa-chevron-down" />
                      </th>
                      <th scope="col">
                        Status <i className="fas fa-chevron-down" />
                      </th>
                      <th scope="col">
                        Last Modified <i className="fas fa-chevron-down" />
                      </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading
                      ? ""
                      : listings.length > 0
                      ? listings.map((listing, index) => (
                          <tr key={listing.host_id}>
                            <td>{index + 1}</td>
                            <td>
                              <div>
                                <a href="#" className="room-list-img">
                                  <img
                                    src={listing.host_picture}
                                    alt={listing.host_name}
                                  />
                                </a>
                                <div className="room-list-content">
                                  <Link
                                    to={`/host/single/${listing.host_id}`}
                                    className="room-list-tit"
                                  >
                                    {listing.host_name}
                                  </Link>
                                  <p>
                                    <small
                                      className={this.completionTextColor(
                                        listing.complete_percentage
                                      )}
                                    >
                                      {listing.complete_percentage} % Completed
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </td>
                            {/* <td className="text-center">
                              <a
                                href="#"
                                className="light-img"
                                title="Instant Book"
                              >
                                <img src="../assets/img/light.svg" />
                              </a>
                            </td> */}

                            <td className="text-center">{listing.host_type}</td>
                            <td>{listing.host_location}</td>
                            {/* <td>{listing.timezone_formatted}</td> */}
                            <td>
                              {listing.booking_type == 2 ? (
                                listing.total_timeslots > 0 ? (
                                  <Link
                                    to={`/host/pricings/${listing.host_id}`}
                                  >
                                    View
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/host/timeslots/${listing.host_id}`}
                                  >
                                    Add Timeslot
                                  </Link>
                                )
                              ) : (
                                <Link to={`/host/pricings/${listing.host_id}`}>
                                  View
                                </Link>
                              )}
                            </td>
                            <td>
                              {listing.admin_host_status ? (
                                <small className="text-success">
                                  <i className="fas fa-thumbs-up" /> Admin
                                  Approved
                                </small>
                              ) : (
                                <small className="text-danger">
                                  <i className="fas fa-thumbs-down" /> Waiting
                                  for Approval
                                </small>
                              )}

                              <div className="clearfix" />

                              {listing.provider_host_status ? (
                                <small className="text-success">
                                  <i className="fas fa-check" /> Visible for
                                  User
                                </small>
                              ) : (
                                <small className="text-danger">
                                  <i className="far fa-times-circle" /> Hidden
                                  From User
                                </small>
                              )}

                              <div className="clearfix" />

                              {listing.is_admin_verified ? (
                                <small className="text-success">
                                  <i className="fa fa-eye" /> Details verified
                                </small>
                              ) : (
                                <small className="text-danger">
                                  <i className="far fa-eye-slash" /> Waiting for
                                  Verification
                                </small>
                              )}
                            </td>
                            <td>
                              {listing.updated_at
                                ? new Date(
                                    listing.updated_at
                                  ).toLocaleDateString("en-CA", DATE_OPTIONS)
                                : "-"}
                            </td>
                            <td>
                              <div class="dropup action-menu nav">
                                <a
                                  class="action-menu-icon"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      "/assets/img/menu.svg"
                                    }
                                    alt="menu"
                                  />
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                {localStorage.getItem('is_frontdesk') != 1 ? (

                                  <Link
                                    className="dropdown-item"
                                    to={`/host/listing-step1/${listing.host_id}`}
                                  >
                                    <i className="far fa-edit" /> Edit
                                  </Link>
                                ) : ''}
                                
                                  {listing.complete_percentage == 100 ? (
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={(event) =>
                                        window.confirm("Are you sure?") &&
                                        this.hostStatusChange(
                                          event,
                                          listing.host_id
                                        )
                                      }
                                    >
                                      <i className="far fa-copy" />{" "}
                                      {listing.provider_host_status != 1
                                        ? "Enable Visibility"
                                        : "Disable Visibility"}{" "}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  <Link
                                    className="dropdown-item"
                                    to={`/host/gallery/${listing.host_id}`}
                                  >
                                    <i className="far fa-image" /> Gallery
                                  </Link>
                                  {/* <Link
                                    className="dropdown-item"
                                    to={`/host/availability/${listing.host_id}`}
                                  >
                                    <i className="far fa-image" /> Availability
                                  </Link> */}
                                  {listing.booking_type == 2 ? (
                                    <Link
                                      className="dropdown-item"
                                      to={`/host/timeslots/${listing.host_id}`}
                                    >
                                      <i className="far fa-image" /> Manage Time
                                      slots
                                    </Link>
                                  ) : null}


                               {localStorage.getItem('is_frontdesk') != 1 ? (
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(event) =>
                                      window.confirm("Are you sure?") &&
                                      this.deleteHost(event, listing.host_id)
                                    }
                                  >
                                    <i className="fas fa-trash-alt" /> Delete
                                  </a>
                                 ):''}

                                  <Link
                                    className="dropdown-item"
                                    to={`/host/special-pricing/${listing.host_id}`}
                                  >
                                    <i class="fas fa-hand-holding-usd"></i>
                                    Special Pricing
                                  </Link>
                              


                                  {listing.complete_percentage >= 75 ? (
                                    <Link
                                      className="dropdown-item"
                                      to={`/host/single/${listing.host_id}`}
                                    >
                                      <i className="fas fa-eye" /> 
                                      {localStorage.getItem("is_frontdesk") == 1 ? " Book now" : " Preview"}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      : "No data Found"}
                    {loadingStatus ? "" : loadingContent}
                  </tbody>
                </table>
                {loading ? (
                  ""
                ) : listings.length > 0 && totalData > 0 ? (
                  <Link to={"#"} onClick={this.loadMore}>
                    Load More
                  </Link>
                ) : (
                  <h4>End of Results</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostListingManagement);
