import React, { useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import configuration from "react-global-configuration";
import Dropzone from 'react-dropzone';
import api from "../../../Environment";

const ChatAssetModal = (props) => {
    const [chatAssetInputData, setChatAssetInputData] = useState({});
    const handleSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(chatAssetInputData).length > 0 && chatAssetInputData.file) {

            let inputData;
            inputData = {
                user_id: localStorage.getItem("userId"),
                provider_id: props.toUserId,
                file_type: props.fileType,
                type:props.chatType,
                file: chatAssetInputData.file ? chatAssetInputData.file : "",
            };

            api
                .postMethod("chat_assets_save", inputData)
                .then((response) => {
                    if (response.data.success) {
                        props.assetUploadSuccess(event,response.data);
                    } else {

                    }
                });

        } else {
            alert("Please upload image and try again");
        }
        setChatAssetInputData({
            ...chatAssetInputData,
            file: '',
        });
        setImage({ ...image, title: '', picture: '' });
        props.closeChatAssetUploadModal("neeee");
    };

    const [image, setImage] = useState({
        picture: "",
    });

    const handleChangeImage = (event) => {
        if (event.currentTarget.type === "file") {
            setChatAssetInputData({
                ...chatAssetInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
            let reader = new FileReader();
            let file = event.currentTarget.files[0];

            setImage({ ...image, title: event.currentTarget.files[0].name });
            reader.onloadend = () => {
                if (props.fileType == 'image') {
                    setImage({ ...image, picture: reader.result });
                }
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    const nullData = ["", null, undefined, "light"];

    return (
        <>
            <Modal
                className="modal-dialog-center"
                size="md"
                centered
                show={props.chatAssetUpload}
                onHide={props.closeChatAssetUploadModal}
            >
                {props.chatAssetUpload === true ? (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Upload {props.fileType}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="floating-form">
                                <div className="floating-label mb-5 chat-upload-sec">
                                    <Button className="upload-chat-btn">
                                        <Form.Group className="mb-0">
                                            <Form.Control
                                                id="fileupload_photo"
                                                type="file"
                                                accept={
                                                    props.fileType == "image" ? "image/*" : props.fileType == "video" ? "video/*" : ".doc,.docx,.xml,application/pdf"
                                                }
                                                onChange={(event) => handleChangeImage(event)}
                                                name="file"
                                            />
                                            <Form.Label
                                                id="attach_file_photo"
                                                for="fileupload_photo"
                                                className="chat-attach_file"
                                                data-original-title="null"
                                            >
                                                Upload {props.fileType}
                                            </Form.Label>
                                        </Form.Group>
                                    </Button>
                                    {image.picture !== "" ? <Image src={image.picture} /> : null}
                                    <div><h4>{image.title}</h4></div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={props.closeChatAssetUploadModal}
                            >
                                cancel
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                                onClick={handleSubmit}
                            >
                                send
                            </Button>
                        </Modal.Footer>
                    </Form>
                ) : null}
            </Modal>
        </>
    );
};


export default ChatAssetModal;
