import React, { Component } from "react";
import HostLoginModal from "../Auth/HostLoginModal";

// This Layouts used in Home page Header,  static footer and content

class HostBasicLayout extends Component {
  constructor(props) {
    super(props);

    // define states
    this.state = {
      isValidAuth : true
    }
  }

  toggleLoginModal = (state) => {
    this.setState({
        ...this.state,
        isValidAuth : state
    })
  }

  render() {
    return (
      <div className="wrapper">
          {this.state.isValidAuth || (
              <HostLoginModal />
          )} 
        <div>{React.cloneElement(this.props.children , {isValidAuth : this.state.isValidAuth , toggleLoginModal :this.toggleLoginModal})}</div>
      </div>
    );
  }
}
export default HostBasicLayout;
