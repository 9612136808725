import React, { Component } from "react";

class AddListingStep6 extends Component {
  state = {};
  render() {
    const { fourthApiResponse, handleAmentiesChange, formData } = this.props;
    return (
      <div id="step-8" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{fourthApiResponse[0].title}</h4>
              <p className="main-txt">{fourthApiResponse[0].description}</p>
              <div className="flow-content">
                {fourthApiResponse[0].data.map((details) => (
                  <div
                    className="form-group large-form-group"
                    key={details.server_key}
                  >
                    <label className="light-txt">{details.question}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={details.placeholder}
                      name={details.server_key}
                      onChange={handleAmentiesChange}
                      value={formData[details.server_key]}
                    />
                  </div>
                ))}
              </div>
              <div className="flow-content">
                {fourthApiResponse[1].data.map((details) => (
                  <div
                    className="form-group large-form-group"
                    key={details.server_key}
                  >
                    <label className="light-txt">{details.question}</label>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      name={details.server_key}
                      onChange={handleAmentiesChange}
                    />
                  </div>
                ))}
              </div>
              <div className="flow-content">
                <div className="flow-box">
                  <h5 className="flow-box-tit">Rapid Booking allowed?</h5>
                  <p className="main-txt text-muted">
                    Rapid Booking allows guest to book the property directly
                    without Host approval, set to 'No' if host approval is
                    required for each booking.
                  </p>
                  <div
                    className="form-check add-list-block"
                    key="is_automatic_booking_yes"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_automatic_booking"
                      value="1"
                      onChange={handleAmentiesChange}
                      id="is_automatic_booking_yes"
                    />
                    <label
                      className="form-check-label row"
                      htmlFor="is_automatic_booking_yes"
                    >
                      <h5 className="flow-check-tit">Yes</h5>
                      {/* <p className="flow-check-txt text-muted">
                          {details.description}
                        </p> */}
                    </label>
                    <div className="clear-both" />
                  </div>
                  <div
                    className="form-check add-list-block"
                    key="is_automatic_booking_no"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_automatic_booking"
                      value="0"
                      onChange={handleAmentiesChange}
                      id="is_automatic_booking_no"
                    />
                    <label
                      className="form-check-label row"
                      htmlFor="is_automatic_booking_no"
                    >
                      <h5 className="flow-check-tit">No</h5>
                      {/* <p className="flow-check-txt text-muted">
                          {details.description}
                        </p> */}
                    </label>
                    <div className="clear-both" />
                  </div>
                </div>
              </div>

              <div className="flow-content">
                <div className="flow-box">
                  <h5 className="flow-box-tit">
                    Only for Community Residence?
                  </h5>
                  <p className="main-txt text-muted">
                    Certain community halls allows booking only for residence in
                    that community, set to 'Yes' if that restriction applies.
                  </p>
                  <div
                    className="form-check add-list-block"
                    key="is_only_residence_yes"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_only_residence"
                      value="1"
                      onChange={handleAmentiesChange}
                      id="is_only_residence_yes"
                    />
                    <label
                      className="form-check-label row"
                      htmlFor="is_only_residence_yes"
                    >
                      <h5 className="flow-check-tit">Yes</h5>
                      {/* <p className="flow-check-txt text-muted">
                          {details.description}
                        </p> */}
                    </label>
                    <div className="clear-both" />
                  </div>
                  <div
                    className="form-check add-list-block"
                    key="is_only_residence_no"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_only_residence"
                      value="0"
                      onChange={handleAmentiesChange}
                      id="is_only_residence_no"
                    />
                    <label
                      className="form-check-label row"
                      htmlFor="is_only_residence_no"
                    >
                      <h5 className="flow-check-tit">No</h5>
                      {/* <p className="flow-check-txt text-muted">
                          {details.description}
                        </p> */}
                    </label>
                    <div className="clear-both" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep6;
