import React from "react";
import { Link } from "react-router-dom";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";
import Helper from "../../Helper/Helper";
import configuration from "react-global-configuration";

const $ = window.$;

class HomeHeader extends Helper {
  // constructor(props) {
  //   super(props);
  // }

  state = {
    data: {},
    fixed : false,
    chat_notification:0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    if(localStorage.getItem("userLoginStatus")) {
      this.chatSocketConnect();
    }
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll,true);
  }
  
  handleScroll = () => {
    if( $(window).scrollTop() > 84) {
      this.setState({
        ...this.state,
        fixed:true,
      })
    }
    else{
      this.setState({
        ...this.state,
        fixed:false,
      })
    }
  };

  render() {
    // const count = Object.keys(this.state.data).length;

    let renderDetails;
    if (!localStorage.getItem("userLoginStatus")) {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item">
            <a className="nav-link" target="_blank" href="https://blog.partybudha.ca/">
              Blog
            </a>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/host"}>
              Become a Host
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="#"
              data-toggle="modal"
              data-target="#signup"
            >
              Sign up
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="#"
              data-toggle="modal"
              data-target="#login"
            >
              Log in
            </Link>
          </li>
        </React.Fragment>
      );
    } else {
      renderDetails = (
        <React.Fragment>

           <Link
            className="nav-link"
            to={"/wishlist"}
            id="navbarwishdrop"
            
          >
            Saved
          </Link>

          <li className="nav-item">
            <Link
              to={"/history"}
              className="nav-link"
              id="navbardrop"
            >
              Bookings
            </Link>
          </li>
          <li className={`${this.state.chat_notification ? "nav-item msg-notification" : "nav-item"}`}>
            <Link
              className="nav-link"
              to={"/inbox"}
              id="navbardrop"
            >
              Messages{" "}
            </Link>
          </li>


          <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-link1 dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
              alt=""
            >
              <img
                src={
                  localStorage.getItem("user_picture") === null
                    ? "../assets/img/user-pic.png"
                    : localStorage.getItem("user_picture")
                }
                className="profile-img"
                alt="User"
              />
              <span className={`${this.state.fixed ? "scolledHeaderUsername" : "text-white"}`}>
                {" "}
                Welcome {localStorage.getItem("username")}
              </span>
            </Link>
            <div className="dropdown-menu profile-drop">
              <Link to={"/edit-profile"} className="item">
                <div className="msg-head">
                  <h5>edit profile</h5>
                </div>
                <p className="msg-line" />
              </Link>
              {/* <Link to={"/notification"} className="item">
                <div className="msg-head">
                  <h5>account settings</h5>
                </div>
                <p className="msg-line" />
              </Link> */}
              <Link to={"/logout"} className="item">
                <div className="msg-head">
                  <h5>logout</h5>
                </div>
                <p className="msg-line" />
              </Link>
            </div>
          </li>
        </React.Fragment>
      );
    }

    return (
      <div>
        <div id="sub-page">
          <nav className={`navbar navbar-expand-xl bg-light navbar-light white-header trans-head fixed-top ${this.state.fixed && "fixedHomeHeader"}`} id="homeHeader">
            <Link className="navbar-brand" to={"/"}>
              {/* <img
                data-src={window.location.origin + "/assets/site/favicon.png"}
                src={configuration.get("configData.site_logo")}
                alt={configuration.get("configData.site_name")}
              /> */}
              <picture>
                  <source className="lazyload" type="image/png" srcset={configuration.get("configData.site_logo")}/>
                  <img data-src={configuration.get("configData.site_logo")} type="image/png" className="lazyload " alt={configuration.get("configData.site_name")}/>
              </picture>
            </Link>
            <div className="user-mobile-details">
              <img
                src={
                  localStorage.getItem("user_picture") === null
                    ? "../assets/img/user-pic.png"
                    : localStorage.getItem("user_picture")
                }
                className="profile-img"
                alt="User"
              />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
              aria-controls="collapsibleNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className={`${this.state.chat_notification ? "navbar-toggler-icon nav-msg-notification" : "navbar-toggler-icon"}`}></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end mobile-view"
              id="collapsibleNavbar"
            >
              <ul className="navbar-nav">{renderDetails}</ul>
            </div>
          </nav>
        </div>
        <div className="mob-header-height" />
        <Login {...this.props} />
        <Register {...this.props} />
      </div>
    );
  }
}

export default HomeHeader;
