import React from "react";

// import { Link } from "react-router-dom";
import io from "socket.io-client";
// import openSocket from "socket.io-client";
import Helper from "../../Helper/Helper";
import api from "../../../Environment";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

import { apiConstants } from "./../../Constant/constants";

let socket;

let host_details;
let provider_details;
let pricing_details;
let page;
let apiResponse;

class Chat extends Helper {
  state = {
    message: null,
    data: null,
    chatList: null,
    loadingChatList: true,
    socket: false,
    inputMessage: null,
    chatData: null,
    loadingChatData: true,
    headerDetail: null,
    headerDetailsLoadFirst: true,
    headerContentLoading: true,
    loadingStatus: true,
    loadingContent: null,
    loadingStatusChatHistory: true,
    loadingContentChatHistory: null,
    chatListskipCount: 0,
    chatHistorySkipCount: 0,
    loadMoreChatHistory: false,
    chatIndex: 0,
    loading: true,
    chatSidebar: {
      status: true,
      desktop: false
    },
    chatUser: null,
    chatProviderId: 0,
    initialHeight: 0,
    notificationIndex: [],
  };

  componentDidMount() {
    // Call api function
    if (this.props.location.state) {
      if (this.props.location.state.page == "history") {
        host_details = this.props.location.state.booking_details;
        provider_details = this.props.location.state.booking_details
          .provider_details;
        pricing_details = this.props.location.state.booking_details
          .pricing_details;
        page = "history";
        apiResponse = {}
      }
      if (this.props.location.state.page == "singletrip") {
        host_details = this.props.location.state.host_details;
        provider_details = this.props.location.state.provider_details;
        pricing_details = this.props.location.state.pricing_details;
        page = "singletrip";
        apiResponse = this.props.location.state.apiResponse
      }
      console.log(this.props.location.state);
      this.socketConnectionfun(
        localStorage.getItem("userId"),
        provider_details.provider_id,
        host_details.host_id
      );
      this.chatDetailsApiCall();
    } else {
      this.props.history.goBack();
    }
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }
    window.addEventListener('resize', this.handleChatsidebar);
  }

  componentWillUnmount() {
    socket.disconnect();
    window.removeEventListener('resize', this.handleChatsidebar);
  }

  handleChatsidebar = () => {
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state, chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state, chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }
  };

  handleChatSideBarToggler = (toggle_status) => {
    this.setState({
      ...this.state, chatSidebar: {
        ...this.state.chatSidebar,
        status: toggle_status,
      }
    });
  }

  socketConnectionfun = (userId, providerId, hostId) => {
    let socketUrl = configuration.get("configData.chat_socket_url");
    socket = io(socketUrl, {
      query:
        `commonid: 'user_id_` +
        userId +
        `_provider_id_` +
        providerId +
        `_host_id_` +
        hostId +
        `', myid: ` +
        userId,
    });

    socket.emit("update sender", {
      commonid:
        "user_id_" +
        userId +
        "_provider_id_" +
        providerId +
        "_host_id_" +
        hostId,
      myid: userId,
      userId: userId,
    });
    let chatContent;
    socket.on("message", (newData) => {
      //  if(newData.chattype)

      let content = [];
      content.push(newData);
      chatContent = [...this.state.chatData, ...content];
      this.setState({ chatData: chatContent }, () => this.scrollDown());
    });
  };

  scrollDown = () => {

    const objDiv = document.getElementById("messages-holder");

    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      console.log(differenceNumber)

      if (differenceNumber > 100) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = this.state.initialHeight;
        this.setState({
          ...this.state,
          initialHeight: this.state.initialHeight + 20,
        });
      }
    }
  };

  chatDetailsApiCall = () => {
    let inputData;

    if (host_details.booking_id === undefined) {
      inputData = {
        host_id: host_details.host_id,
        provider_id: provider_details.provider_id,
        skip: this.state.chatHistorySkipCount,
      };
    } else {
      inputData = {
        booking_id: host_details.booking_id,
        host_id: host_details.host_id,
        provider_id: provider_details.provider_id,
        skip: this.state.chatHistorySkipCount,
        status: 1,
      };
    }

    api.postMethod("bookings_chat_details", inputData).then((response) => {
      let chathistory;
      if (response.data.success) {
        if (this.state.loadMoreChatHistory) {
          if (this.state.chatData != null) {
            chathistory = [
              ...response.data.data.reverse(),
              ...this.state.chatData,
            ];
          } else {
            chathistory = [...response.data.data.reverse()];
          }
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount:
              response.data.data.length + this.state.chatHistorySkipCount,
            loadingStatusChatHistory: true,
            loadMoreChatHistory: false,
          });
        } else {
          chathistory = response.data.data.reverse();
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount: response.data.data.length,
            loadingStatusChatHistory: true,
          }, () => {
            this.scrollDown();
          });
        }
      } else {
        //
      }
    });
  };

  getNotificationIndex = () => {

    let lastIndexFound = 0;

    let startingIndex = null;

    const indexArray = []

    for (let index = 0; index <= this.state.chatData.length - 1; ++index) {
      if (this.state.chatData[index] && (this.state.chatData[index].notification_type == 1 || this.state.chatData[index].notification_type == 2)) {
        if (this.state.chatData[index].notification_type == this.state.chatData[index + 1 > this.state.chatData.length - 1 ? this.state.chatData.length - 1 : index + 1].notification_type) {
          if (startingIndex == null) {
            startingIndex = index
            indexArray.push(index)
          }
        } else {
          lastIndexFound = 0;
          startingIndex = null;
        }
      }
    }
    this.setState({
      ...this.state,
      notificationIndex: indexArray
    })
  }

  handleOnChange = ({ currentTarget: input }) => {
    this.setState({ inputMessage: input.value });
    //
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let online_status = 0;
    let inputData = { provider_id: provider_details.provider_id };
    api
      .postMethod("online_status", inputData)
      .then((response) => {
        if (response.data.success) {
          online_status = response.data.data.status;
        }

        let chatData = [
          {
            user_id: localStorage.getItem("userId"),
            provider_id: provider_details.provider_id,
            message: this.state.inputMessage,
            host_id: host_details.host_id,
            booking_id: host_details.booking_id,
            chat_type: "up",
            user_picture: localStorage.getItem("user_picture"),
            notification_type: 1,
            user_status: 1,
            provider_status: online_status == 1 ? 1 : 0,
            updated: 'Just now',
            sender_id: localStorage.getItem("userId"),
          },
        ];

        socket.emit("message", chatData[0]);

        let messages;

        if (this.state.chatData != null) {
          messages = [...this.state.chatData, ...chatData];
        } else {
          messages = [...chatData];
        }
        this.setState({
          chatData: messages,
          inputMessage: "",
        }, () => this.scrollDown());
      });
  };

  loadMoreChatHistory = (event) => {
    event.preventDefault();
    this.setState({
      loadingStatusChatHistory: false,
      loadingContentChatHistory: "Loading...",
      loadMoreChatHistory: true,
    });
    this.chatDetailsApiCall();
  };

  render() {
    const {
      chatList,
      loadingChatList,
      chatData,
      loadingChatData,
      headerDetail,
      headerContentLoading,
      loadingStatus,
      loadingContent,
      loadingContentChatHistory,
      loadingStatusChatHistory,
      loading,
    } = this.state;



    console.log("apiResponse.response_time_hrs", host_details);

    return (
      <div className={`main chatWrapper ${this.state.chatSidebar.desktop && "container mx-auto"}`} id="hostChat">
        {loading ? (
          "Loading..."
        ) : (
          <div className="row m-0 w-100">
            {this.state.chatSidebar.status && (
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 chat-leftsec">
                <div className="chat-details text-center">
                  <div className="providerDetailswrapper">
                    <img
                      src={provider_details.picture}
                      className="chat-profile-img"
                    />
                    <div className="providerDetails">
                      <h4 className="chat-provider-head">
                        {provider_details.provider_name}
                      </h4>
                      <div className="ratingWrapper">
                        <span className="ratingText">
                          {this.starRatingHost(host_details.admin_ratings ? host_details.admin_ratings : 0)}
                        </span>
                        <span className="reviewText">
                          ({host_details.admin_ratings} Ratings)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-left hostPriceDetails">
                    <h2 className="chathead">Event details</h2>
                    {host_details.host_name}<br></br>
                    {apiResponse ?
                      (apiResponse.response_time_hrs != null && apiResponse.response_time_mins != null) ? (
                        <p className="choosen-details">
                          Response Time: {apiResponse.response_time_hrs > 0 ? apiResponse.response_time_hrs + " hours and" : ''}   {" "}
                          {apiResponse.response_time_mins} Minutes
                        </p>
                      ) : '' : ''}
                    {host_details.pricing_type == 3 ?
                      host_details.is_custom_pricing == 1 ?
                        <h5 className="choosen-details">
                          <i className="fas fa-address-book mr-3" />
                          Package - Custom Pricing
                        </h5>
                        :
                        <h5 className="choosen-details">
                          <i className="fas fa-address-book mr-3" />
                          Package - {host_details.host_pricing_packages.name} {host_details.host_pricing_packages.base_price_formatted}
                        </h5>
                      : ''}
                    <p className="overview-line my-3" />
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        {apiResponse.booking_type == 1 ? page == "singletrip" ? (
                          <>
                            {/* <h5 className="choosen-details">
                                <span>{apiResponse.checkin}</span>
                                <span>{" "}to{" "}</span>
                                <span>{apiResponse.checkout}</span>
                              </h5> */}
                            <h5 className="choosen-details text-capitalize">
                              check in
                            </h5>
                            <h5 className="choosen-details">
                              {apiResponse.checkin}
                            </h5>
                          </>
                        ) : (
                          <div>
                            <h5 className="choosen-details">
                              <i className="far fa-calendar-alt mr-3" />
                              {host_details.checkin}
                              <i className="fas fa-arrow-right ml-3 mr-3" />
                              {host_details.checkout}
                            </h5>
                            <div className="d-flex justify-content-between">
                              <h5 className="choosen-details">
                                Total Nights
                              </h5>
                              <h5 className="choosen-details">
                                {host_details.total_days}{" "} {host_details.total_days_text}
                              </h5>
                            </div>
                          </div>
                        ) : (
                          <>
                            <h5 className="choosen-details slotBased">
                              <small>checkin</small>
                              {apiResponse.checkin ? apiResponse.checkin : host_details.checkin}
                            </h5>
                            <h5 className="choosen-details slotBased">
                              <small className="text-right">Slot</small>
                              {apiResponse.host_time_slot ? apiResponse.checkin : host_details.host_time_slot_formatted}
                            </h5>
                          </>
                        )}
                      </div>

                      <div className="col-12 d-flex justify-content-between">
                        <h5 className="choosen-details">
                          Total Guest
                        </h5>
                        <h5 className="choosen-details">
                          {page == "singletrip" ? apiResponse.total_guests : host_details.total_guests} guests
                        </h5>
                      </div>
                      <div className="col-12 d-flex justify-content-between">

                        {host_details.pricing_type == 1 ? (
                          <h5 className="choosen-details">Base Price</h5>
                        ) : null}
                        {host_details.pricing_type == 2 ? (
                          <h5 className="choosen-details">Total Guest Price</h5>
                        ) : null}
                        {host_details.pricing_type == 3 ? (
                          <h5 className="choosen-details">
                            Total Package Price
                          </h5>
                        ) : null}

                        {/* <h5 className="choosen-details">
                            {page == "singletrip" ? apiResponse.base_price : host_details.pricing_details.base_price_formatted}
                          </h5> */}
                        {host_details.pricing_type == 1 ? (
                          <h5 className="choosen-details">
                            {
                              host_details.pricing_details
                                .time_price_formatted
                            }
                          </h5>
                        ) : null}

                        {host_details.pricing_type == 2 ? (
                          <h5 className="choosen-details">
                            {
                              host_details.pricing_details
                                .total_guest_price_formatted
                            }
                          </h5>
                        ) : null}
                        {host_details.pricing_type == 3 ? (
                          <h5 className="choosen-details">
                            {
                              host_details.pricing_details
                                .package_amount_formatted
                            }
                          </h5>
                        ) : null}

                      </div>
                      <div className="col-12 d-flex justify-content-between">
                        <h5 className="choosen-details">
                          Addon Services
                        </h5>
                        <h5 className="choosen-details">
                          {page == "singletrip" ? apiResponse.host_addon_services_total : host_details.pricing_details.addon_service_total_formatted}
                        </h5>
                      </div>
                      <div className="col-12 d-flex justify-content-between">
                        <h5 className="choosen-details">
                          Tax Price
                        </h5>
                        <h5 className="choosen-details">
                          {page == "singletrip" ? apiResponse.tax_price_formatted : host_details.pricing_details.tax_price_formatted}
                        </h5>
                      </div>
                      <div className="col-12 d-flex justify-content-between">
                        <h5 className="choosen-details">
                          Service fee
                        </h5>
                        <h5 className="choosen-details">
                          {page == "singletrip" ? apiResponse.tax_price_formatted : host_details.pricing_details.service_fee_formatted}
                        </h5>
                      </div>
                      <div className="col-12 d-flex justify-content-between">
                        {/* {host_details.pricing_details.security_deposit == 0 ? (
                            ""
                          ) : (
                            <h5 className="choosen-details">Security Deposit</h5>
                          )}
                          {host_details.pricing_details.security_deposit == 0 ? (
                              ""
                            ) : (
                            <h5 className="choosen-details">
                              {
                                host_details.pricing_details
                                  .security_deposit_formatted
                              }
                            </h5>
                          )} */}
                      </div>

                      {/* <div className="col-12 d-flex justify-content-between">
                          <h5 className="choosen-details">Cleaning fee</h5>
                          <h5 className="choosen-details">
                            {page == "singletrip"
                              ? apiResponse.cleaning_fee
                              : pricing_details.cleaning_fee_formatted}
                          </h5>
                        </div> */}
                      <div className="col-12 d-flex justify-content-between">
                        {host_details.pricing_details.promo_code_total == 0 ? (
                          ""
                        ) : (
                          <h5 className="choosen-details">Promo code</h5>
                        )}
                        {host_details.pricing_details.promo_code_total == 0 ? (
                          ""
                        ) : (
                          <h5 className="choosen-details">
                            - {
                              host_details.pricing_details
                                .promo_code_total_formatted
                            }
                          </h5>
                        )}
                      </div>
                    </div>

                    <p className="overview-line my-3" />
                  </div>
                  <div className="chatleft-footer">
                    <div className="row">
                      <div className="col-6">
                        <h5 className="choosen-details total text-left">Total </h5>
                      </div>
                      <div className="col-6 text-right">
                        <h5 className="choosen-details total text-right">
                          {page == "singletrip"
                            ? apiResponse.actual_total_formatted
                            : host_details.total_formatted}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 chat-rightsec">
              <div className="relative height-100">
                <div className="chat-header">
                  <div class="chat-list-box single-chat-box">
                    <div className="chatSidebarToggler ml-2" onClick={() => this.props.history.goBack()}>
                      <i class="fas fa-arrow-left"></i>
                    </div>
                    <div class="chat-prof-img">
                      <img
                        src={provider_details.picture}
                        alt={host_details.host_name}
                      ></img>
                    </div>
                    <div class="chat-prof-content">
                      <h5 class="chat-name">{host_details.host_name}</h5>
                      {(apiResponse.response_time_hrs != null && apiResponse.response_time_mins != null) ? (
                        <p className="chat-date">
                          Response Time: {apiResponse.response_time_hrs > 0 ? apiResponse.response_time_hrs + " hours and" : ''}   {" "}
                          {apiResponse.response_time_mins} Minutes
                        </p>
                      ) : ''}
                    </div>
                    {this.state.chatSidebar.desktop || (
                      <>
                        {this.state.chatSidebar.status ? (
                          <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(false)}>
                            <i class="fas fa-times"></i>
                          </div>
                        ) : (
                          <div className="chatSidebarToggler" onClick={() => this.handleChatSideBarToggler(true)}>
                            <i class="fas fa-bars"></i>
                          </div>
                        )}
                      </>
                    )}
                    <div class="clear-both"></div>
                  </div>
                </div>

                <div className="chat-content" id="messages-holder">
                  <div className="text-center">
                    {loadingStatusChatHistory ? "" : loadingContentChatHistory}
                    <a
                      href="category/index.html"
                      className="show-all"
                      onClick={(event) => this.loadMoreChatHistory(event)}
                    >
                      {loadingChatData
                        ? ""
                        : chatData.length > 0
                          ? "Show More"
                          : ""}
                    </a>
                  </div>
                  {loadingChatData
                    ? ""
                    : chatData.map((chat, index) =>
                      <>
                        {/* {this.state.notificationIndex.map((data) => (
                          <>
                            {index == data && chat.notification_type == 1 && (
                              <div className="chat-protect-notes">
                                <p>Booking Enquiry</p>
                              </div>
                            ) }
                          </>
                        ))} */}
                        {chat.confirmed_booking_id || chat.draft_booking_id ?
                          <div className="offer-list-box">
                            <h4>{chat.bookings.host_name}</h4>
                            <p>{chat.message}</p>
                            {chat.confirmed_booking_id ?
                              <ul className="list-unstyled offer-list">
                                <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                <li><i class="far fa-clock mr-2"></i> Booked on: {chat.bookings.created}</li>
                                <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                              </ul> :
                              <ul className="list-unstyled offer-list">
                                <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                <li><i class="far fa-clock mr-2"></i> Enquired on: {chat.bookings.created}</li>
                                <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                              </ul>
                            }
                            {chat.confirmed_booking_id ?
                              <Link to={`/history-details/${chat.bookings.booking_id}`} className="view-order-btn">View Bookings</Link>
                              : ''}
                          </div>
                          :
                          chat.chat_type == "pu" ? (
                            <div className="chat-left">
                              <div className="display-inline">
                                <img
                                  className="chat-img-left"
                                  src={chat.provider_picture}
                                />
                                <div className="chat-content-right">
                                  <div className="chat-message">
                                    {chat.message}{" "}
                                  </div>
                                  <p className="clearfix m-0" />
                                  <p className="chat-time">{chat.updated}</p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="clearfix" />
                              <div className="chat-right">
                                <div className="display-inline">
                                  <div className="chat-content-right">
                                    <div className="chat-message">
                                      {chat.message}{" "}
                                    </div>
                                    <p className="clearfix m-0" />
                                    <div className="chat-status-indication-right">
                                      <p className="chat-time text-right">
                                        {chat.updated}
                                      </p>
                                      {chat.provider_status == 1 ?
                                        <p className="read-msg-indication">Read</p>
                                        :
                                        <p className="delivered-msg-indication">Sent</p>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                </div>

                <div className="chat-footer">
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-group dropdown">
                      <input
                        type="text"
                        className="form-control dropdown-toggle"
                        data-toggle="dropdown"
                        placeholder="enter your message"
                        name="message"
                        value={this.state.inputMessage}
                        onChange={this.handleOnChange}
                      />
                      <div
                        className="input-group-append"
                        onClick={this.handleSubmit}
                      >
                        <span className="input-group-text" id="basic-addon">
                          <i className="fas fa-paper-plane" />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Chat;
