import React, { Component } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import api from "../../Environment";
import Helper from "./Helper";

var yesterday = moment().subtract(1, 'day');
var valid = function (current) {
  return current.isAfter(yesterday);
};
class Filters extends Helper {
  state = {
    value: { min: 0, max: 10000 },
    adults: 0,
    children: 0,
    infants: 0,
    from_date: null,
    to_date: null,
    host_id: null,
    loading: true,
    mapView: false,
    hostTypeData: {},
    priceChange: false,
    total_guest: 0,
    total_home_type: 0,
    loadingEvent: true,
    eventData: null,
    loadingListing: true,
    listingData: null,
    selectedEvent: null,
    selectedListing: null,
    event_id: null,
    listing_id: null,
    is_automatic_booking: 0,
    loadingLocation: true,
    locationData: null,
    searchInput: { from_date: "", to_date: "" },
    selectedLocation: null,
    service_location_id: null,
    selectedSubCategory: null,
    sub_category_id: null,
  };
  componentDidMount() {
    let inputData = {
      api_page_type: "HOME",
    };
    this.homePageFirstSection(inputData);
    this.getListingDetails();
    this.getEventDetailsApi();
    this.getLocationDetailsApi();
    this.hanldePreDefinedFilter();

  }

  hanldePreDefinedFilter = () => {

    let preDefined = {}

    if (this.props.searchInput && this.props.searchInput.from_date) {
      preDefined = {
        ...preDefined,
        from_date: this.props.searchInput.from_date
      }
    }
    if (this.props.searchInput && this.props.searchInput.event_title) {
      preDefined = {
        ...preDefined,
        selectedEvent: [this.props.searchInput.event_title],
        event_id: [this.props.searchInput.event_id]
      }
    }
    if (this.props.searchInput && this.props.searchInput.service_location_id) {
      preDefined = {
        ...preDefined,
        // selectedLocation : ["home"],
        selectedLocation: [this.props.searchInput.service_location_name],
        service_location_id: [this.props.searchInput.service_location_id]
        // service_location_id : [parseInt(this.props.searchInput.service_location_id)]
      }
    }

    if (this.props.searchInput && this.props.searchInput.sub_cat_title) {
      preDefined = {
        ...preDefined,
        selectedSubCategory: [this.props.searchInput.sub_cat_title],
        sub_category_id: [this.props.searchInput.sub_cat_id]
      }
    }
    this.setState(preDefined);
  }

  handleInstantBooking = ({ currentTarget: input }) => {
    if (input.checked) {
      this.setState({ is_automatic_booking: 1 });
      setTimeout(() => {
        this.searchResultApiCall();
      }, 100);
    } else {
      this.setState({ is_automatic_booking: 0 });
      setTimeout(() => {
        this.searchResultApiCall();
      }, 100);
    }
  };

  getListingDetails = () => {
    api.postMethod("listings_index").then((response) => {
      if (response.data.success === true) {
        this.setState({
          loadingListing: false,
          listingData: response.data.data.listings,
        });
      } else {
        // Do nothing
      }
    });
  };

  getEventDetailsApi = () => {
    api.postMethod("events_index").then((response) => {
      if (response.data.success === true) {
        this.setState({
          loadingEvent: false,
          eventData: response.data.data.events,
        });
      } else {
        // Do nothing
      }
    });
  };

  handlChangeListing = (event, listing) => {
    if (event.currentTarget.checked) {
      if (this.state.listing_id == null) {
        let arrayB = [];
        arrayB.push(listing.title);
        this.setState({ selectedListing: arrayB });

        let array = [];
        const value = parseInt(listing.listing_id);
        array.push(value);
        this.setState({ listing_id: array });
      } else {
        const value = parseInt(listing.listing_id);

        this.setState((state) => {
          const listing_id = state.listing_id.push(value);
          const selectedListing = state.selectedListing.push(listing.title);
          return listing_id, selectedListing;
        });
      }
    } else {
      let index = this.state.listing_id.indexOf(listing.listing_id);
      if (index !== -1) {
        this.setState((state) => {
          const listing_id = state.listing_id.splice(index, 1);
          return listing_id;
        });
      }
      let indexSelectedListing = this.state.selectedListing.indexOf(
        listing.title
      );
      if (indexSelectedListing !== -1) {
        this.setState((state) => {
          const selectedListing = state.selectedListing.splice(index, 1);
          return selectedListing;
        });
      }
    }
  };

  handlChangeEvent = (e, event) => {
    if (e.currentTarget.checked) {
      if (this.state.event_id == null) {
        let arrayB = [];
        arrayB.push(event.title);
        this.setState({ selectedEvent: arrayB });

        let array = [];
        const value = parseInt(event.event_id);
        array.push(value);
        this.setState({ event_id: array });
      } else {
        const value = parseInt(event.event_id);

        this.setState((state) => {
          const event_id = state.event_id.push(value);
          const selectedEvent = state.selectedEvent.push(event.title);
          return event_id, selectedEvent;
        });
      }
    } else {
      let index = this.state.event_id.indexOf(event.event_id);
      if (index !== -1) {
        this.setState((state) => {
          const event_id = state.event_id.splice(index, 1);
          return event_id;
        });
      }
      let indexSelectedEvent = this.state.selectedEvent.indexOf(event.title);
      if (indexSelectedEvent !== -1) {
        this.setState((state) => {
          const selectedEvent = state.selectedEvent.splice(index, 1);
          return selectedEvent;
        });
      }
    }
  };

  handlLocationEvent = (e, event) => {
    if (e.currentTarget.checked) {
      if (this.state.service_location_id == null) {
        let arrayB = [];
        arrayB.push(event.service_location_name);
        this.setState({ selectedLocation: arrayB });

        let array = [];
        const value = parseInt(event.service_location_id);
        array.push(value);
        this.setState({ service_location_id: array });
      } else {
        const value = parseInt(event.service_location_id);

        this.setState((state) => {
          const service_location_id = state.service_location_id.push(value);
          const selectedLocation = state.selectedLocation.push(event.service_location_name);
          return service_location_id, selectedLocation;
        });
      }
    } else {
      let index = this.state.service_location_id.indexOf(event.service_location_id);
      if (index !== -1) {
        this.setState((state) => {
          const service_location_id = state.service_location_id.splice(index, 1);
          return service_location_id;
        });
      }
      let indexSelectedEvent = this.state.selectedLocation.indexOf(event.service_location_name);
      if (indexSelectedEvent !== -1) {
        this.setState((state) => {
          const selectedLocation = state.selectedLocation.splice(index, 1);
          return selectedLocation;
        });
      }
    }
  };

  handlSubCateEvent = (e, event) => {

    if (e.currentTarget.checked) {
      if (this.state.sub_category_id == null) {
        let arrayB = [];
        arrayB.push(event.name);
        this.setState({ selectedSubCategory: arrayB });

        let array = [];
        const value = parseInt(event.id);
        array.push(value);
        this.setState({ sub_category_id: array });
      } else {
        const value = parseInt(event.id);

        this.setState((state) => {
          const sub_category_id = state.sub_category_id.push(value);
          const selectedSubCategory = state.selectedSubCategory.push(event.name);
          return sub_category_id, selectedSubCategory;
        });
      }
    } else {
      let index = this.state.sub_category_id.indexOf(event.id);
      if (index !== -1) {
        this.setState((state) => {
          const sub_category_id = state.sub_category_id.splice(index, 1);
          return sub_category_id;
        });
      }
      let indexSelectedEvent = this.state.selectedSubCategory.indexOf(event.provider_name);
      if (indexSelectedEvent !== -1) {
        this.setState((state) => {
          const selectedSubCategory = state.selectedSubCategory.splice(index, 1);
          return selectedSubCategory;
        });
      }
    }
  };

  searchResultApiCall = () => {
    const {
      adults,
      children,
      infants,
      from_date,
      to_date,
      hostTypeData,
      priceChange,
      listing_id,
      event_id,
      is_automatic_booking,
      service_location_id,
      sub_category_id
    } = this.state;
    // console.log('preDefined'+JSON.stringify(this.state));
    let formData;

    if (adults != 0) {
      formData = {
        adults: adults,
      };
    }
    if (children != 0) {
      formData = {
        ...formData,
        children: children,
      };
    }
    if (infants != 0) {
      formData = {
        ...formData,
        infants: infants,
      };
    }

    if (from_date != null) {
      formData = {
        ...formData,
        from_date: from_date,
      };
    }

    if (to_date != null) {
      formData = {
        ...formData,
        to_date: to_date,
      };
    }
    if (hostTypeData != {}) {
      formData = {
        ...formData,
        ...hostTypeData,
      };
    }
    if (event_id != null) {
      formData = {
        ...formData,
        event_id: event_id,
      };
    }
    if (listing_id != null) {
      formData = {
        ...formData,
        listing_id: listing_id,
      };
    }
    if (is_automatic_booking != null) {
      formData = {
        ...formData,
        is_automatic_booking: is_automatic_booking,
      };
    }

    // console.log('one+'+this.props.searchInput);
    // console.log('one+'+service_location_id);

    if (service_location_id != null) {
      formData = {
        ...formData,
        service_location_id: service_location_id,
      };
    }

    if (this.props.searchInput && this.props.searchInput.service_location_id) {
      formData = {
        ...formData,
        service_location_id: this.props.searchInput.service_location_id,
      };
    }

    if (sub_category_id != null) {
      formData = {
        ...formData,
        sub_category_id: sub_category_id,
      };
    }

    if (!this.state.loading && this.state.navigatorResult) {
      formData = {
        ...formData,
        location_name: this.state.navigatorResult.city,
      }
    }


    if (priceChange) {
      const price = {
        min_input: this.state.value.min,
        max_input: this.state.value.max,
      };
      const inputData = {
        price: JSON.stringify(price),
      };
      formData = {
        ...formData,
        ...inputData,
      };
    }

    if (this.props.notMainSearch) {

      let inputData;

      if (this.props.match.params.name == "events") {
        inputData = {
          event_id: this.props.match.params.id,
          skip: this.state.skipCount,
          api_page_type: "EVENT"
        };
      }
      else if (this.props.location.state == "SUB_CATEGORY") {
        inputData = {
          event_id: this.props.match.params.id,
          skip: this.state.skipCount,
          sub_category: this.props.match.params.url_type,
          api_page_type: this.props.location.state.type
        };
      }
      else {
        inputData = {
          api_page_type: this.props.match.params.url_type,
          api_page_type_id: this.props.match.params.id,
        };
      }
      // console.log('preDefined'+JSON.stringify(this.props));
      formData = {
        ...formData,
        ...inputData,
      };
      // console.log('preDefined'+JSON.stringify(formData));
      this.setState({
        skipCount: 0,
      }, (() => {
        api
          .postMethod("search_result", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.setState({
                skipCount: response.data.data[0].data.length + this.state.skipCount,
              });
              console.log(response.data.data);
              this.props.props.history.push('/search', { ...response.data.data, searchInput: this.props.searchInput, notMainSearch: this.props.notMainSearch });
            } else {
              // ToastDemo(this.props.toastManager, response.data.error, "error");
            }
          })
          .catch(function (error) { });
        // this.seeAll(formData , true);
        // this.props.props.history.push('/search', {formData , searchInput :this.props.searchInput , notMainSearch : this.props.notMainSearch});
      }));
    } else {
      api
        .postMethod("search_result", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              skipCount: response.data.data[0].data.length + this.state.skipCount,
            });
            console.log(response.data.data[0].data.length);
            this.props.props.history.push('/search', { ...response.data.data, searchInput: this.props.searchInput, notMainSearch: this.props.notMainSearch });
          } else {
            // ToastDemo(this.props.toastManager, response.data.error, "error");
          }
        })
        .catch(function (error) { });
    }
  };

  convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  onChangeDate = (title) => (...args) => {
    const from_date = this.convert(args[0]);
    const to_date = this.convert(args[1]);

    if (to_date == "NaN-aN-aN") {
      this.setState({ from_date: from_date });
    } else {
      this.setState({ from_date: from_date, to_date: to_date });
    }

    if (from_date != null) {
      setTimeout(() => {
        this.searchResultApiCall();
      }, 1000);
    }
  };
  increament = (value) => {
    if (value == "adults") {
      this.setState({
        adults: this.state.adults + 1,
        total_guest: this.state.total_guest + 1,
      });
    }
    if (value == "children") {
      this.setState({
        children: this.state.children + 1,
        total_guest: this.state.total_guest + 1,
      });
    }
    if (value == "infants") {
      this.setState({
        infants: this.state.infants + 1,
        total_guest: this.state.total_guest + 1,
      });
    }
  };

  decreament = (value) => {
    if (value == "adults") {
      this.setState({
        adults: this.state.adults > 0 ? this.state.adults - 1 : 0,
        total_guest:
          this.state.adults > 0 ? this.state.total_guest - 1 : this.state.total_guest,
      });
    }
    if (value == "children") {
      this.setState({
        children: this.state.children > 0 ? this.state.children - 1 : 0,
        total_guest:
          this.state.children > 0 ? this.state.total_guest - 1 : this.state.total_guest,
      });
    }
    if (value == "infants") {
      this.setState({
        infants: this.state.infants > 0 ? this.state.infants - 1 : 0,
        total_guest:
          this.state.infants > 0 ? this.state.total_guest - 1 : this.state.total_guest,
      });
    }
  };

  handleMap = (event) => {
    event.preventDefault();
    this.setState({ mapView: !this.state.mapView });
    if (this.props.toggleMap) {
      this.props.toggleMap(!this.state.mapView);
    }
  };

  displayMoreFilter = (event) => {
    event.preventDefault();
    $("#more-filters-content").toggleClass("dis-block");
  };

  closeFilterView = (event) => {
    event.preventDefault();
    $("#mobile-guest-content").slideUp();
  };

  handlChange = ({ currentTarget: input }) => {
    const hostTypeData = { ...this.state.hostTypeData };
    if (input.checked) {
      if (hostTypeData[input.name] === undefined) {
        let array = [];
        array.push(input.value);
        hostTypeData[input.name] = array;
        this.setState({ hostTypeData });
        this.setState({
          total_home_type: this.state.total_home_type + 1,
        });
      } else {
        hostTypeData[input.name].push(input.value);
        this.setState({ hostTypeData });
        this.setState({
          total_home_type: this.state.total_home_type + 1,
        });
      }
    } else {
      let index = hostTypeData[input.name].indexOf(input.value);
      if (index !== -1) {
        hostTypeData[input.name].splice(index, 1);
        this.setState({ hostTypeData });
        this.setState({
          total_home_type: this.state.total_home_type - 1,
        });
      }
    }
  };

  handleGuest = (event) => {
    event.preventDefault();
    this.searchResultApiCall();
  };

  handleCancelButton = (event) => {
    event.preventDefault();
    this.setState({
      adults: 0,
      children: 0,
      infants: 0,
      total_guest: 0,
      formData: {},
    }, () => {
      this.handleFilterClear();
    });
  };

  handleHomeTypeCancelButton = (event) => {
    var $checkboxes = $("#home-type-form :checkbox");
    let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
    $checkboxes.prop("checked", checkbox_value ? false : '');
    event.preventDefault();
    this.setState({
      total_home_type: 0,
      hostTypeData: {},
    }, () => {
      this.handleFilterClear();
    });
  };

  handleListingCancelButton = (event) => {
    var $checkboxes = $("#listing-type-form :checkbox");
    let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
    $checkboxes.prop("checked", checkbox_value ? false : '');
    event.preventDefault();
    this.setState({
      selectedListing: null,
      listing_id: null,
    }, () => {
      this.handleFilterClear();
    });

  };

  handleEventCancelButton = (event) => {

    var $checkboxes = $("#event-type-form :checkbox");
    let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
    $checkboxes.prop("checked", checkbox_value ? false : '');

    event.preventDefault();
    this.setState({
      selectedEvent: null,
      event_id: null,
    }, () => {
      this.handleFilterClear();
    });

  };

  handleLocationCancelButton = (event) => {
    var $checkboxes = $("#location-type-form :checkbox");
    let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
    $checkboxes.prop("checked", checkbox_value ? false : '');
    event.preventDefault();
    this.setState({
      selectedLocation: null,
      service_location_id: null,
    }, () => {
      this.handleFilterClear();
    });

  };

  handleSubCatCancelButton = (event) => {
    var $checkboxes = $("#subCat-type-form :checkbox");
    let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
    $checkboxes.prop("checked", checkbox_value ? false : '');
    event.preventDefault();
    this.setState({
      selectedSubCategory: null,
      sub_category_id: null,
    }, () => {
      this.handleFilterClear();
    });

  };

  handleHomeType = (event) => {
    event.preventDefault();
    this.searchResultApiCall();
  };

  handlePrice = () => {
    this.setState({ priceChange: true });
    this.searchResultApiCall();
  };

  getLocationDetailsApi = () => {
    api.postMethod("filter_locations").then((response) => {
      if (response.data.success === true) {
        this.setState({
          loadingLocation: false,
          locationData: response.data.data,
        });
      } else {
        // Do nothing
      }
    });
  };

  getSearchInput = ({ currentTarget: input }) => {
    const searchInput = { ...this.state.searchInput };
    searchInput[input.name] = input.value;
    this.setState({ searchInput });
  };

  handleFilterClear = () => {

    const {
      adults,
      children,
      infants,
      from_date,
      to_date,
      hostTypeData,
      priceChange,
      listing_id,
      event_id,
      is_automatic_booking,
      service_location_id,
      sub_category_id
    } = this.state;

    let formData;

    if (adults != 0) {
      formData = {
        adults: adults,
      };
    }
    if (children != 0) {
      formData = {
        ...formData,
        children: children,
      };
    }
    if (infants != 0) {
      formData = {
        ...formData,
        infants: infants,
      };
    }

    if (from_date != null) {
      formData = {
        ...formData,
        from_date: from_date,
      };
    }

    if (to_date != null) {
      formData = {
        ...formData,
        to_date: to_date,
      };
    }
    if (hostTypeData != {}) {
      formData = {
        ...formData,
        ...hostTypeData,
      };
    }
    if (event_id != null) {
      formData = {
        ...formData,
        event_id: event_id,
      };
    }
    if (listing_id != null) {
      formData = {
        ...formData,
        listing_id: listing_id,
      };
    }
    if (is_automatic_booking != null) {
      formData = {
        ...formData,
        is_automatic_booking: is_automatic_booking,
      };
    }

    // console.log('one+'+this.props.searchInput);
    // console.log('one+'+service_location_id);

    if (service_location_id != null) {
      formData = {
        ...formData,
        service_location_id: service_location_id,
      };
    }

    if (this.props.searchInput && this.props.searchInput.service_location_id) {
      formData = {
        ...formData,
        service_location_id: this.props.searchInput.service_location_id,
      };
    }

    if (sub_category_id != null) {
      formData = {
        ...formData,
        sub_category_id: sub_category_id,
      };
    }

    if (!this.state.loading && this.state.navigatorResult) {
      formData = {
        ...formData,
        location_name: this.state.navigatorResult.city,
      }
    }


    if (priceChange) {
      const price = {
        min_input: this.state.value.min,
        max_input: this.state.value.max,
      };
      const inputData = {
        price: JSON.stringify(price),
      };
      formData = {
        ...formData,
        ...inputData,
      };
    }

    if (this.props.notMainSearch) {
      this.setState({
        skipCount: 0,
      }, (() => {
        api
          .postMethod("search_result", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.props.props.history.push('/search', { ...response.data.data, searchInput: this.props.searchInput, notMainSearch: this.props.notMainSearch });
            } else {
              // ToastDemo(this.props.toastManager, response.data.error, "error");
            }
          });

        // this.seeAll(formData , true);
        // this.props.props.history.push('/search', {formData , searchInput :this.props.searchInput , notMainSearch : this.props.notMainSearch});
      }));
    } else {
      api
        .postMethod("search_result", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.props.props.history.push('/search', { ...response.data.data, searchInput: this.props.searchInput, notMainSearch: this.props.notMainSearch });
          } else {
            // ToastDemo(this.props.toastManager, response.data.error, "error");
          }
        });
    }

  }


  render() {
    const DEFAULT_CLASS_NAMES = {
      activeTrack: "input-range__track input-range__track--active",
      disabledInputRange: "input-range input-range--disabled",
      inputRange: "input-range",
      labelContainer: "input-range__label-container",
      maxLabel: "input-range__label input-range__label--max",
      minLabel: "input-range__label input-range__label--min",
      slider: "input-range__slider",
      sliderContainer: "input-range__slider-container",
      track: "input-range__track input-range__track--background",
      valueLabel: "input-range__label input-range__label--value",
    };
    // console.log('Subcategories',this.state.sub_categories);

    let inputProps = {
      placeholder: 'Event Date',
    };
    return (
      <div>
        {this.state.loading ? null : (
          <>
            <div className="section-spacing sub-nav">
              <ul className="filters">
                <li className="dropdown">
                  <div className="filter-calendar">
                    <Datetime
                      onChange={this.onChangeDate("Range DatePicker")}
                      isValidDate={valid}
                      dateFormat="DD-MM-YYYY"
                      inputProps={inputProps}
                      timeFormat={false}
                      closeOnSelect={true}
                      initialValue={this.props.searchInput && this.props.searchInput.from_date}
                    />
                  </div>
                </li>
                <li className="dropdown">
                  <button
                    className={
                      this.state.total_guest != 0
                        ? "dropdown-toggle filter-active"
                        : "dropdown-toggle"
                    }
                    id="guest-mobile-btn"
                    data-toggle="dropdown"
                  >
                    {this.state.total_guest != 0 ? this.state.total_guest : ""}{" "}
                    Guests
                  </button>
                  <div className="dropdown-menu guest">
                    <form className="inc-dec-count">
                      <div className="row guest-options">
                        <div className="col-6 col-sm-6">
                          <label htmlFor="name">adults</label>
                        </div>
                        <div className="col-6 col-sm-6 text-right">
                          <div className="float-right">
                            <div
                              className="decrement-btn"
                              onClick={() => this.decreament("adults")}
                            >
                              <div className="dec button">
                                <i className="fas fa-minus" />
                              </div>
                            </div>
                            <input
                              type="text"
                              value={this.state.adults}
                              name="adults"
                            />
                            <div
                              className="increment-btn"
                              onClick={() => this.increament("adults")}
                            >
                              <div className="inc button">
                                <i className="fa fa-plus" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row guest-options">
                        <div className="col-6 col-sm-6">
                          <label htmlFor="name">
                            children
                            {/* <small className="text-muted">
                              <br />
                              Ages 2–12
                            </small> */}
                          </label>
                        </div>
                        <div className="col-6 col-sm-6 text-right">
                          <div className="float-right">
                            <div
                              className="decrement-btn"
                              onClick={() => this.decreament("children")}
                            >
                              <div className="dec button">
                                <i className="fas fa-minus" />
                              </div>
                            </div>
                            <input
                              type="text"
                              value={this.state.children}
                              name="children"
                            />
                            <div
                              className="increment-btn"
                              onClick={() => this.increament("children")}
                            >
                              <div className="inc button">
                                <i className="fas fa-plus" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row guest-options">
                        <div className="col-6 col-sm-6">
                          <label htmlFor="name">
                            infants
                            {/* <small className="text-muted">
                              <br />
                              Under 2
                            </small> */}
                          </label>
                        </div>
                        <div className="col-6 col-sm-6 text-right">
                          <div className="float-right">
                            <div
                              className="decrement-btn"
                              onClick={() => this.decreament("infants")}
                            >
                              <div className="dec button">
                                <i className="fas fa-minus" />
                              </div>
                            </div>
                            <input
                              type="text"
                              value={this.state.infants}
                              name="infants"
                            />
                            <div
                              className="increment-btn"
                              onClick={() => this.increament("infants")}
                            >
                              <div className="inc button">
                                <i className="fas fa-plus" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <Link
                            to="#"
                            className="cancel-link"
                            onClick={this.handleCancelButton}
                            data-toggle="dropdown"
                          >
                            Clear
                          </Link>
                        </div>
                        <div className="col-6">
                          <Link
                            to="#"
                            className="submit-link"
                            onClick={this.handleGuest}
                            data-toggle="dropdown"
                          >
                            Apply
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                <li className="dropdown">
                  {this.state.total_home_type != 0 ? (
                    <button
                      className="dropdown-toggle filter-active"
                      data-toggle="dropdown"
                    >
                      {" "}
                      Home Type - {this.state.total_home_type}
                    </button>
                  ) : (
                    <button className="" data-toggle="dropdown">
                      Type of place
                    </button>
                  )}

                  <div className="dropdown-menu guest account">
                    <form id="home-type-form">
                      <div>
                        <label className="form-checkbox mb-0">
                          Entire place
                          <input
                            type="checkbox"
                            name="host_type"
                            onChange={this.handlChange}
                            value="Entire Place"
                          />
                          <span className="checkmark" />
                        </label>
                        <p className="profile-note ml-35 mt-0">
                          Have a place to yourself
                        </p>
                      </div>

                      <label className="form-checkbox mb-0">
                        Private place
                        <input
                          type="checkbox"
                          name="host_type"
                          onChange={this.handlChange}
                          value="Private Place"
                        />
                        <span className="checkmark" />
                      </label>
                      <p className="profile-note ml-35 mt-0">
                        Have your own room and share some common spaces
                      </p>

                      <label className="form-checkbox mb-0">
                        Shared Room
                        <input
                          type="checkbox"
                          name="host_type"
                          onChange={this.handlChange}
                          value="Others"
                        />
                        <span className="checkmark" />
                      </label>
                      <p className="profile-note ml-35 mt-0">
                        Stay in Link shared space, like Link common room
                      </p>

                      <div className="row">
                        <div className="col-6 mt-3">
                          <button
                            className="cancel-link"
                            onClick={this.handleHomeTypeCancelButton}
                            data-toggle="dropdown"
                          >
                            Clear
                          </button>
                        </div>
                        <div className="col-6 mt-3">
                          <button
                            className="submit-link"
                            onClick={this.handleHomeType}
                            data-toggle="dropdown"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                <li className="dropdown">
                  {this.state.selectedEvent != null ? (
                    <button
                      className="dropdown-toggle filter-active"
                      data-toggle="dropdown"
                    >
                      Event Types - <b>{this.state.selectedEvent.length}</b>
                    </button>
                  ) : (
                    <button className="dropdown-toggle" data-toggle="dropdown">
                      Event Types
                    </button>
                  )}

                  <div className="dropdown-menu guest account">
                    <form id="event-type-form">
                      {this.state.loadingEvent
                        ? "Loading..."
                        : this.state.eventData.length > 0
                          ? this.state.eventData.map((events) => (
                            <div>
                              <label className="form-checkbox mb-0">
                                {events.title}
                                <input
                                  type="checkbox"
                                  name="event_id"
                                  onChange={(event) =>
                                    this.handlChangeEvent(event, events)
                                  }
                                  checked={this.state.event_id != null && this.state.event_id.find((id) => id == events.event_id)}
                                />
                                <span className="checkmark" />
                              </label>
                              <p className="profile-note ml-35 mt-0">
                                <>
                                  {events.description.length > 30
                                    ? `${events.description.substring(0, 30)}...`
                                    : events.description}
                                </>
                              </p>
                            </div>
                          ))
                          : "No data Found"}

                      <div className="row">
                        <div className="col-6 mt-3">
                          <button
                            className="cancel-link"
                            onClick={this.handleEventCancelButton}
                            data-toggle="dropdown"
                          >
                            Clear
                          </button>
                        </div>
                        <div className="col-6 mt-3">
                          <button
                            className="submit-link"
                            onClick={this.handleHomeType}
                            data-toggle="dropdown"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                {/* <li className="dropdown">
                {this.state.selectedListing != null ? (
                  <button className="dropdown-toggle filter-active" data-toggle="dropdown">
                    Listings - <b>{this.state.selectedListing.length}</b>
                  </button>
                ) : (
                  <button className="dropdown-toggle" data-toggle="dropdown">
                    Listings
                  </button>
                )}

                <div className="dropdown-menu guest account">
                  <form id="listing-type-form">
                    {this.state.loadingListing
                      ? "Loading..."
                      : this.state.listingData.length > 0
                      ? this.state.listingData.map((listing) => (
                          <div>
                            <label className="form-checkbox mb-0">
                              {listing.title}
                              <input
                                type="checkbox"
                                name="listing_id"
                                onChange={(event) =>
                                  this.handlChangeListing(event, listing)
                                }
                              />
                              <span className="checkmark" />
                            </label>
                            <p className="profile-note ml-35 mt-0">
                              <>
                                {listing.description.length > 30
                                  ? `${listing.description.substring(0, 30)}...`
                                  : listing.description}
                              </>
                            </p>
                          </div>
                        ))
                      : "No data Found"}

                    <div className="row">
                      <div className="col-6 mt-3">
                        <button
                          className="cancel-link"
                          onClick={this.handleListingCancelButton}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="col-6 mt-3">
                        <button
                          className="submit-link"
                          onClick={this.handleHomeType}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li> */}

                <li className="dropdown">
                  {this.state.selectedSubCategory != null ? (
                    <button className="dropdown-toggle filter-active" data-toggle="dropdown">
                      Service Type - <b>{this.state.selectedSubCategory.length}</b>
                    </button>
                  ) : (
                    <button className="dropdown-toggle" data-toggle="dropdown">
                      Service Type
                    </button>
                  )}

                  <div className="dropdown-menu guest account">
                    <form id="subCat-type-form">
                      {this.state.loading ? "loading" : this.state.sub_categories.data.length > 0
                        ? this.state.sub_categories.data.map((sub_cat) => (
                          <div>
                            <label className="form-checkbox mb-0">
                              {sub_cat.provider_name}
                              <input
                                type="checkbox"
                                name="sub_category"
                                onChange={(event) =>
                                  this.handlSubCateEvent(event, sub_cat)
                                }
                                checked={this.state.selectedSubCategory != null && this.state.selectedSubCategory.find((cat) => cat == sub_cat.name)}
                              />
                              <span className="checkmark" />
                            </label>
                            <p className="profile-note ml-35 mt-0">
                              <>
                                {sub_cat.provider_name.length > 30
                                  ? `${sub_cat.provider_name.substring(0, 30)}...`
                                  : sub_cat.provider_name}
                              </>
                            </p>
                          </div>
                        ))
                        : "No data Found"}

                      <div className="row">
                        <div className="col-6 mt-3">
                          <button
                            className="cancel-link"
                            onClick={this.handleSubCatCancelButton}
                            data-toggle="dropdown"
                          >
                            Clear
                          </button>
                        </div>
                        <div className="col-6 mt-3">
                          <button
                            className="submit-link"
                            onClick={this.handleHomeType}
                            data-toggle="dropdown"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                <li className="dropdown">
                  {this.state.selectedLocation != null ? (
                    <button className="dropdown-toggle filter-active" data-toggle="dropdown">
                      Location - <b>{this.state.selectedLocation.length}</b>
                    </button>
                  ) : (
                    <button className="dropdown-toggle" data-toggle="dropdown">
                      location
                    </button>
                  )}
                  <div className="dropdown-menu guest account">
                    <form id="location-type-form">
                      {this.state.loadingLocation
                        ? "Loading..."
                        : this.state.locationData.length > 0
                          ? this.state.locationData.map((location) => (
                            <div>
                              <label className="form-checkbox mb-0">
                                {location.service_location_name}
                                <input
                                  type="checkbox"
                                  name="location"
                                  onChange={(event) =>
                                    this.handlLocationEvent(event, location)
                                  }
                                  checked={this.state.selectedLocation != null && this.state.selectedLocation.find((id) => id == location.service_location_name)}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          ))
                          : "No data Found"}

                      <div className="row">
                        <div className="col-6 mt-3">
                          <button
                            className="cancel-link"
                            onClick={this.handleLocationCancelButton}
                            data-toggle="dropdown"
                          >
                            Clear
                          </button>
                        </div>
                        <div className="col-6 mt-3">
                          <button
                            className="submit-link"
                            onClick={this.handleHomeType}
                            data-toggle="dropdown"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <select
                    className="custom-select form-control border-right border-radius-search-1 resp-mrg-btm-xs"
                    id="inlineFormCustomSelect"
                    onChange={this.getSearchInput}
                    name="service_location_id"
                    value={this.state.searchInput.service_location_id}
                  >
                    <option selected>Location</option>
                    {this.state.loadingLocation
                      ? "Loading..."
                      : this.state.locationData.length > 0
                      ? this.state.locationData.map((location) => (
                          <option
                            key={location.service_location_id}
                            value={location.service_location_id}
                          >
                            {location.service_location_name}
                          </option>
                        ))
                      : "No data found"}
                  </select> */}
                </li>
                {/* <li className="dropdown">
                <button className="dropdown-toggle" data-toggle="dropdown">
                  {this.state.value.min != 0
                    ? this.state.value.min + " - " + this.state.value.max
                    : "price"}
                </button>
                <div className="dropdown-menu guest">
                  <div className="row guest-options">
                    <div className="col-10 offset-1">
                      <p className="mt-0 bottom" />
                      <p className="bottom" />

                      <InputRange
                        maxValue={10000}
                        minValue={100}
                        formatLabel={(value) => `${value} $`}
                        value={this.state.value}
                        onChange={(value) => this.setState({ value: value })}
                        onChangeComplete={this.handlePrice}
                        InputRangeClassNames={DEFAULT_CLASS_NAMES}
                      />
                    </div>
                  </div>
                </div>
              </li> */}

                {this.props.match.path == "/see_all/:name/:id/:url_type" ||
                  this.props.match.path == "/search" ? (
                  <li
                    className="toggle-btn"
                    id="map-toggle-btn"
                    onClick={this.handleMap}
                  >
                    <h5 className="float-left">Map</h5>
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.mapView}
                        className="ios-switch"
                      />{" "}
                      <div>
                        <div />
                      </div>
                    </label>
                  </li>
                ) : (
                  ""
                )}

                <li className="toggle-btn-instant">
                  <h5 className="float-left">Rapid Booking</h5>
                  <label>
                    <input
                      type="checkbox"
                      name="is_automatic_booking"
                      onChange={this.handleInstantBooking}
                      className="ios-switch"
                    />{" "}
                    <div>
                      <div />
                    </div>
                  </label>
                </li>
              </ul>
            </div>

            <div className="mobile-guest-dropdown" id="mobile-guest-content">
              <div className="mobile-guest-dropdown-head">
                <h4>
                  <span>
                    {/* <i
                      className="material-icons"
                      id="guest-mobile-closebtn"
                      onClick={this.closeFilterView}
                    >
                      close
                    </i> */}
                    <svg id="guest-mobile-closebtn"
                      onClick={this.closeFilterView} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#787878" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </span>
                  <span className="submit-link mt-1">clear</span>
                </h4>
              </div>
              <div className="mobile-guest-dropdown-content">
                <li className="dropdown">
                  <button
                    className="dropdown-toggle"
                    id="guest-mobile-btn"
                    data-toggle="dropdown"
                  >
                    guests
                  </button>
                  <div className="dropdown-menu guest">
                    <form className="inc-dec-count">
                      <div className="row">
                        <div className="col-6 col-sm-6">
                          <label htmlFor="name">adults</label>
                        </div>
                        <div className="col-6 col-sm-6 text-right">
                          <div className="float-right">
                            <div className="decrement-btn">
                              <div className="dec button">
                                <i className="fas fa-minus" />
                              </div>
                            </div>
                            <input type="text" defaultValue="3" />
                            <div className="increment-btn">
                              <div className="inc button">
                                <i className="fas fa-plus" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6 col-sm-6">
                          <label htmlFor="name">children</label>
                        </div>
                        <div className="col-6 col-sm-6 text-right">
                          <div className="float-right">
                            <div className="decrement-btn">
                              <div className="dec button">
                                <i className="fas fa-minus" />
                              </div>
                            </div>
                            <input type="text" defaultValue="3" />
                            <div className="increment-btn">
                              <div className="inc button">
                                <i className="fas fa-plus" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6 col-sm-6">
                          <label htmlFor="name">infants</label>
                        </div>
                        <div className="col-6 col-sm-6 text-right">
                          <div className="float-right">
                            <div className="decrement-btn">
                              <div className="dec button">
                                <i className="fas fa-minus" />
                              </div>
                            </div>
                            <input type="text" defaultValue="3" />
                            <div className="increment-btn">
                              <div className="inc button">
                                <i className="fas fa-plus" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <Link to="#" className="cancel-link">
                            Clear
                          </Link>
                        </div>
                        <div className="col-6">
                          <Link to="#" className="submit-link">
                            Apply
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>

                <form className="inc-dec-count">
                  <div className="row">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">adults</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">children</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">infants</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="mobile-guest-dropdown-footer">
                <button className="green-btn btn-block btn-lg">show results</button>
              </div>
            </div>

            <div className="more-filters" id="more-filters-content">
              <form className="inc-dec-count">
                <div>
                  <h4 className="more-filters-head">Rooms and beds</h4>
                  <div className="row">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">beds</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">bedrooms</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">bathrooms</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="overview-line" />

                <div className="show-mob">
                  <h4 className="more-filters-head">home type</h4>
                  <div className="account">
                    <label className="form-checkbox mb-0 mt-3">
                      Entrire Place
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <h5 className="profile-note ml-35 mt-0">
                      have Link place to yourself
                    </h5>

                    <label className="form-checkbox mb-0 mt-3">
                      Private Place
                      <input type="checkbox" defaultChecked="checked" />
                      <span className="checkmark" />
                    </label>
                    <h5 className="profile-note ml-35 mt-0">
                      Have your own room and share some common spaces
                    </h5>

                    <label className="form-checkbox mb-0 mt-3">
                      shared room
                      <input type="checkbox" defaultChecked="checked" />
                      <span className="checkmark" />
                    </label>
                    <h5 className="profile-note ml-35 mt-0">
                      Stay in Link shared space, like Link common room
                    </h5>
                  </div>
                  <p className="overview-line" />
                </div>

                <div className="show-mob">
                  <h4 className="more-filters-head">price details</h4>
                  <div className="guest">
                    <p className="mt-0 bottom">₹680 - ₹50000+</p>
                    <p className="bottom">The average nightly price is ₹5465.</p>
                    <input
                      data-addui="slider"
                      data-min="-5"
                      data-max="5"
                      data-range="true"
                      defaultValue="-2,2"
                    />
                  </div>
                  <p className="overview-line" />
                </div>

                <div>
                  <h4 className="more-filters-head">Amenities</h4>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          kitchen
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          heating
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          shampoo
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          air conditioning
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="collapse-content">
                    <h4
                      className="collapse-head captalize show"
                      data-toggle="collapse"
                      data-target="#rules"
                    >
                      show all aminities <i className="fas fa-chevron-down" />{" "}
                    </h4>
                    <div id="rules" className="collapse">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="mb-3">
                            <label className="form-checkbox mb-0">
                              kitchen
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="mb-3">
                            <label className="form-checkbox mb-0">
                              heating
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="mb-3">
                            <label className="form-checkbox mb-0">
                              shampoo
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="mb-3">
                            <label className="form-checkbox mb-0">
                              air conditioning
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <h4
                        className="collapse-foot captalize hide"
                        data-toggle="collapse"
                        data-target="#rules"
                      >
                        hide <i className="fas fa-chevron-up" />
                      </h4>
                    </div>
                  </div>
                </div>
                <p className="overview-line" />

                <div className="more-filters-footer">
                  <div className="text-right">
                    <button className="cancel-link mr-3">cancel</button>
                    <button className="green-btn">show 300+ homes</button>
                  </div>
                </div>
              </form>
              <div className="clearfix" />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Filters;
