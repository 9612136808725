import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./CropImageHelper";

const CropImageModal = (props) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const getCroppedImage = useCallback(async () => {
        const croppedImage = await getCroppedImg(
            props.image,
            croppedAreaPixels,
            0,
            props.cropModalFlag.fileType
        );

        var myFile = dataURLtoFile(croppedImage, props.cropModalFlag.fileName);

        if (props.cropModalFlag.type == "picture") {
            props.setImage({ ...props.imageState, picture: croppedImage });
            props.setFormData({
                ...props.formData,
                file: myFile,
            });
        }
        props.closeModal();
    }, [croppedAreaPixels]);

    return (
        <>
        <Modal
            className="modal-dialog-center sent-tip-modal"
            size={props.cropModalFlag.type == "picture" ? "md" : "lg"}
            centered
            show={props.cropModalFlag.type == "picture" ? props.modalFlag : false}
            onHide={props.cropModalFlag.type == "picture" ? props.closeModal : false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Crop Image {props.modalFlag == true ? "Yes" : "NO"} {props.cropModalFlag.type}</Modal.Title>
                {/* <div className="mb-3">
                    <h4 className="text-muted">
                        <span className="text-danger">Note : </span>Image size of 300 * 300 is recommended{" "}
                    </h4>
                </div> */}
            </Modal.Header>

            <Modal.Body className="cropmodal">
                <div
                    className={`${
                    props.cropModalFlag.type == "picture"
                        ? "image-profile-cropper"
                        : "image-cover-cropper"
                    }`}
                >
                    <Cropper
                        image={props.image}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape={props.cropModalFlag.shape}
                        aspect={props.cropModalFlag.width / props.cropModalFlag.height}
                        // cropSize={{ width: props.width, height: props.height }}
                        objectFit="contain"
                    />
                    <div className="crop-info-text">
                        <p>
                            <i className="fas fa-arrows-alt mr-2"></i>Drag to Reposition
                        </p>
                    </div>
                </div>
               
            </Modal.Body>
            <Modal.Footer>
                <div className="mt-3 text-center">
                    <button
                        className="save-btn btn btn-primary"
                        onClick={() => getCroppedImage()}
                    >
                        Crop
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default CropImageModal;
