import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import Loader from "../../Helper/Loader";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import HostHelper from "../../Helper/hostHelper";
import ReviewModel from "./reviewModel";
import BookingRejectReasonModal from "./bookingRejectReasonModal";


class HostSingleBookingDetails extends HostHelper {
  state = {
    loading: true,
    booking_details: null,
    cancelBtnStatus: 0,
    reviewBtnStatus: 0,
    messageBtnStatus: 0,
    messageApiCalled: false,
    reviewApiCalled: false,
    cancelApiCalled: false,
    bookingData: {
      booking_id: ""
    },
  };
  componentDidMount() {
    this.getBookingDetailsApiCall();
  }
  getBookingDetailsApiCall = () => {
    api
      .postMethod("bookings_view", { booking_id: this.props.match.params.id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            booking_details: response.data.data,
            loading: false,
          });
        }
      });
  };
  bookingCancel = (event) => {
    event.preventDefault();
    api
      .postMethod("bookings_cancel", { booking_id: this.props.match.params.id })
      .then((response) => {
        if (response.data.success) {
          this.getBookingDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };


  handleBookingReject = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      bookingData: {
        booking_id: this.props.match.params.id
      }
    })
  }



  bookingApprove = (event) => {
    event.preventDefault();
    api
      .postMethod("bookings_approve", { booking_id: this.props.match.params.id })
      .then((response) => {
        if (response.data.success) {
          this.getBookingDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };


  render() {
    let load = new Loader();
    const { loading, booking_details } = this.state;
    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing single-booking">
            <div className="single-book-nav">
              <Link to={"/host/booking-management"} className="back-link">
                <i className="fas fa-chevron-left" /> Back
              </Link>
            </div>
            <div className="single-booking-content">
              {loading ? (
                ""
              ) : (
                <div>
                  <h3 className="single-place-tit red-text">
                    #{booking_details.booking_unique_id}
                  </h3>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="single-book-left">
                        <div className="single-box">
                          <h6 className="single-note">
                            {booking_details.sub_category_name}
                          </h6>
                          <h3 className="single-place-tit">
                            {booking_details.host_name}
                          </h3>
                          <h5 className="single-place-txt">
                            {booking_details.booking_description == 'null' ? '' : booking_details.booking_description}
                          </h5>
                          <h5 className="single-place-txt">Special Request:
                            {booking_details.special_request == 'null' ? '' : booking_details.special_request}
                          </h5>
                          {booking_details.is_automatic_booking == 1 && (
                            <ul class="list-unstyled">
                              <li>
                                <img
                                  src={
                                    window.location.origin +
                                    "/assets/img/rapid-booking.svg"
                                  }
                                  alt=""
                                  className="booking-icon-1"
                                />
                              </li></ul>
                          )}
                          <p className="single-place-txt">
                            {booking_details.location}
                          </p>
                          {/* <p className="single-place-txt">United Statses</p> */}
                        </div>
                        {booking_details.pricing_type != 3 ?
                          <div className="single-box">
                            <h5 className="single-box-tit">Guests</h5>
                            <p className="single-box-txt">
                              {booking_details.total_guests} Guest (
                              {booking_details.adults} Adults,{" "}
                              {booking_details.children} Children,{" "}
                              {booking_details.infants} Infants,{" "}
                              {booking_details.age_special} Special Needs)
                            </p>
                          </div>
                          : ''}
                        {booking_details.is_alcohol_served ?
                          <div className="single-box">
                            <p className="single-box-txt">Is Alcohol Served : Yes</p>
                          </div>
                          : ''}
                        {booking_details.pricing_type == 3 ?
                          booking_details.is_custom_pricing == 1 ?

                            <div className="single-box">
                              <h5 className="single-box-tit">Package</h5>
                              <p className="single-box-txt">
                                Custom Pricing
                              </p>
                            </div>

                            :
                            booking_details.host_pricing_packages ?
                              <>
                                <div className="single-box">
                                  <h5 className="single-box-tit">Package</h5>
                                  <p className="single-box-txt">
                                    {booking_details.host_pricing_packages.name} {booking_details.host_pricing_packages.base_price_formatted}
                                  </p>
                                </div>
                              </> : ''
                          : ''}

                        <div className="single-box">
                          <div id="host">
                            <div className="media">
                              <div className="media-body">
                                <h1 className="section-head host-bottom">
                                  Booked By{" "}
                                  {booking_details.user_details.user_name}
                                </h1>
                                <h3 className="host-text">
                                  Booked on{" "}
                                  {booking_details.created}
                                </h3>
                                {/* <h4 className="host-text">
                                  Joined in{" "}
                                  {booking_details.user_details.joined}
                                </h4> */}
                                <h4 className="host-text">
                                  <span className="text-success">
                                    <i className="fas fa-phone small1 align-3 mr-05" />
                                    {booking_details.user_details.mobile}
                                  </span>
                                </h4>
                                {booking_details.is_delivery_service_available ?
                                  <h4 className="host-text">
                                    <b>Service Delivery Address</b>{" "}
                                    {booking_details.service_delivery_address}
                                  </h4>
                                  : ""}
                              </div>
                              <Link
                                to={`/profile/${booking_details.user_details.user_id}`}
                              >
                                <img
                                  src={booking_details.user_details.picture}
                                  alt={booking_details.user_details.user_name}
                                  className="ml-3 rounded-circle review-img"
                                />
                              </Link>
                            </div>

                            <p className="overview-line" />
                            <h4 className="host-text">
                              {booking_details.user_details.description}
                            </h4>
                          </div>
                        </div>
                        {localStorage.getItem('is_frontdesk') != 0 ?
                          <div className="single-box row">
                            {booking_details.buttons.cancel_btn_status == 1 ? (
                              <div className="col-md-6 ">
                                <button
                                  className="danger-outline-btn btn-block"
                                  data-toggle="modal" data-target="#bookingCancelModal"
                                  onClick={(e) =>
                                    window.confirm("Are you sure you want to cancel booking?") &&
                                    this.handleBookingReject(e)
                                  }
                                >
                                  Cancel Booking
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          : ""}
                        {localStorage.getItem('is_frontdesk') == 0 ?
                          <div className="single-box row">
                            {booking_details.buttons.review_btn_status == 1 ? (
                              <div className="col-md-6">
                                {/* <button
                                className="green-btn btn-block"
                                onClick={this.bookingReview}
                              > */}
                                <a
                                  className="green-btn btn-block"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#reviewModel"
                                >
                                  Review
                                </a>
                              </div>
                            ) : (
                              ""
                            )}


                            {booking_details.buttons.approve_reject_btn_status == 1 ? (
                              <div className="col-md-6 ">
                                <button
                                  className="green-outline-btn btn-block approve-btn-top"
                                  onClick={(e) =>
                                    window.confirm("Are you sure?") &&
                                    this.bookingApprove(e)
                                  }
                                >
                                  Approve
                                </button>


                              </div>
                            ) : (
                              ""
                            )}



                            {booking_details.buttons.approve_reject_btn_status == 1 ? (
                              <div className="col-md-6">
                                <button
                                  className="danger-outline-btn btn-block"
                                  data-toggle="modal" data-target="#bookingCancelModal"
                                  onClick={(e) => this.handleBookingReject(e)}
                                >
                                  Reject
                                </button>



                              </div>
                            ) : (
                              ""
                            )}

                            {booking_details.buttons.cancel_btn_status == 1 ? (
                              <div className="col-md-6 resp-mrg-btm-xs">
                                <button
                                  className="danger-outline-btn btn-block"
                                  // data-toggle="modal" data-target="#bookingCancelModal"
                                  onClick={(e) =>
                                    window.confirm("Are you sure you want to cancel booking?") &&
                                    this.bookingCancel(e)
                                  }
                                >
                                  Cancel Booking
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            <br></br>
                            <div className="col-md-6">
                              <Link
                                className="green-btn btn-block"
                                to={{
                                  pathname: "/host/single/chat",
                                  state: {
                                    booking_details: booking_details,
                                    page: "singletrip",
                                  },
                                }}
                              >
                                Chat
                              </Link>
                            </div>
                          </div>

                          : ""}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="single-book-right">
                        <div className="single-book-img">
                          <img
                            src={booking_details.host_picture}
                            alt={booking_details.host_name}
                          />
                        </div>
                        <div className="single-right-wrap">
                          <div className="check-block row">
                            <div className="col-md-6">
                              <div className="single-box">
                                <h5 className="single-box-sub-tit">Check in</h5>
                                <p className="single-box-sub-txt">
                                  {booking_details.checkin_formatted}
                                </p>
                              </div>
                            </div>
                            {booking_details.host_time_slot ? (
                              <div className="col-md-6">
                                <div className="single-box">
                                  <h5 className="single-box-sub-tit">
                                    Time Slot
                                  </h5>
                                  <p className="single-box-sub-txt">
                                    {booking_details.host_time_slot_formatted}
                                  </p>
                                </div>
                              </div>

                            ) : ''}


                          </div>
                          <div className="table-responsive single-billing-table check-block">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>Status</td>
                                  <td className="text-right text-success">
                                    {booking_details.status_text}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Payment Mode</td>
                                  <td className="text-right">
                                    {
                                      booking_details.pricing_details
                                        .payment_mode
                                    }
                                  </td>
                                </tr>

                                {booking_details.pricing_type == 1 ? (
                                  <tr>
                                    <td>Base Price</td>
                                    <td className="text-right">
                                      {
                                        booking_details.pricing_details
                                          .time_price_formatted
                                      }
                                    </td>
                                  </tr>
                                ) : null}
                                {booking_details.pricing_type == 2 ? (
                                  <tr>
                                    <td>Guest Price</td>
                                    <td className="text-right">
                                      {
                                        booking_details.pricing_details
                                          .total_guest_price_formatted
                                      }
                                    </td>
                                  </tr>
                                ) : null}
                                {booking_details.pricing_type == 3 ? (
                                  <tr>
                                    <td>Package Price</td>
                                    <td className="text-right">
                                      {
                                        booking_details.pricing_details
                                          .package_amount_formatted
                                      }
                                    </td>
                                  </tr>
                                ) : null}

                                {booking_details.pricing_details
                                  .total_additional_guest_price == 0 ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Extra Guest Price</td>
                                    <td className="text-right">
                                      {
                                        booking_details.pricing_details
                                          .total_additional_guest_price_formatted
                                      }
                                    </td>
                                  </tr>
                                )}
                                {/* <tr>
                                  <td>Cleaning Fee</td>
                                  <td className="text-right">
                                    {
                                      booking_details.pricing_details
                                        .cleaning_fee_formatted
                                    }
                                  </td>
                                </tr> */}
                                <tr>
                                  <td>Services Fee</td>
                                  <td className="text-right">
                                    {
                                      booking_details.pricing_details
                                        .service_fee_formatted
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Tax Price</td>
                                  <td className="text-right">
                                    {
                                      booking_details.pricing_details
                                        .tax_price_formatted
                                    }
                                  </td>
                                </tr>
                                {/* {booking_details.pricing_details
                                  .security_deposit == 0 ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Security Deposit</td>
                                    <td className="text-right">
                                      {
                                        booking_details.pricing_details
                                          .security_deposit_formatted
                                      }
                                    </td>
                                  </tr>
                                )} */}
                                {booking_details.pricing_details
                                  .promo_code_total == 0 ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td>Promo code </td>
                                    <td className="text-right">
                                      - {
                                        booking_details.pricing_details
                                          .promo_code_total_formatted
                                      }
                                    </td>
                                  </tr>
                                )}
                                {booking_details.addon_service_details.length >=
                                  0
                                  ? booking_details.addon_service_details.map(
                                    (service) => (
                                      <tr>
                                        <td>{service.name}</td>
                                        <td className="text-right">
                                          {service.currency}
                                          {service.amount}.00
                                        </td>
                                      </tr>
                                    )
                                  )
                                  : ""}
                              </tbody>
                              <tfoot>
                                <td>Total</td>
                                <td className="text-right">
                                  {
                                    booking_details.total_formatted
                                  }
                                </td>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ReviewModel booking_id={this.props.match.params.id} />

        <BookingRejectReasonModal booking_id={this.state.bookingData.booking_id} />
      </div>
    );
  }
}

export default withToastManager(HostSingleBookingDetails);
