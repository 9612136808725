import React, { Component } from "react";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import api from "../../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { apiConstants } from "../../../Constant/constants";

class EditListingStep4 extends Component {
  state = {
    loadingContent: null,
    submitClicked: false,
    loading: true,
    formData: {},
    position:null
  };

  renderAutoComplete = () => {
    const { google } = this.props;
    if (!google) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place);
      if (!place.geometry) return;
      this.setState({ position: place.geometry.location });
      const formData = { ...this.state.formData };
      formData["latitude"] = place.geometry.location.lat();
      formData["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      formData["full_address"] = full_address;

      this.setState({ formData });
    });
  };

  componentDidMount() {
    this.initialSteps();
  }

  initialSteps = () => {
    api
      .postMethod("hosts_step_4", { host_id: this.props.match.params.host_id })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            data: response.data.data,
            formData: {
              host_id: response.data.data.host_id,
              latitude:response.data.data.latitude,
              longitude:response.data.data.longitude,
            },
          });
        } else {
          alert(response.data.error);
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  handleChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
  };

  handleBackButton = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/host/listing-step3/${this.props.match.params.host_id}`
    );
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    api.postMethod("hosts_step4_save", this.state.formData).then((response) => {
      if (response.data.success) {
        this.props.history.push(
          `/host/listing-step5/${this.props.match.params.host_id}`
        );
      } else {
        // alert(response.data.error);
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
        this.setState({
          loadingContent: null,
          submitClicked: false,
        });
      }
    });
  };

  render() {
    const { google } = this.props;
    const {
      loadingContent,
      submitClicked,
      loading,
      formData,
      position,
      data,
    } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 4: Location information
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                {loading ? (
                  "Loading..."
                ) : (
                  <div className="add-list-content p-5-10">
                    <div id="step-4" className="flow-tab">
                      <div className="add-flow-block row">
                        <div className="col-md-12">
                          <div className="add-flow-block-left">
                            <h4 className="flow-tit">
                              Where's your place located?
                            </h4>
                            <p className="main-txt">
                              Only confirmed guests will get your exact address
                              after they book. We'll show everyone else an
                              approximate location
                            </p>
                            <div className="flow-content">
                              <div className="form-group large-form-group">
                                <label className="light-txt">
                                  Choose Service Location
                                </label>
                                <select
                                  className="form-control"
                                  name="service_location_id"
                                  value={formData.service_location_id}
                                  onChange={this.handleChange}
                                >
                                  <option>Select Service Location</option>
                                  {data.service_locations.map((ans) => (
                                    <option
                                      key={ans.key}
                                      value={ans.id}
                                      selected={
                                        ans.is_checked == 1 ? true : false
                                      }
                                    >
                                      {ans.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group large-form-group">
                                <label className="light-txt">
                                  Full Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="House name/number + street/road"
                                  onFocus={this.renderAutoComplete}
                                  ref={(ref) => (this.autocomplete = ref)}
                                  name="full_address"
                                  value={formData["full_address"]}
                                  onChange={this.handleChange}
                                  defaultValue={data.full_address}
                                />
                                <Map
                                  className="map"
                                  google={google}
                                  visible={true}
                                  center={position}
                                  initialCenter={{
                                    lat: formData["latitude"],
                                    lng: formData["longitude"],
                                  }}
                                >
                                  <Marker position={position} />
                                </Map>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="listing-footer">
                  <button
                    className="white-btn"
                    type="button"
                    onClick={this.handleBackButton}
                  >
                    <i className="fas fa-chevron-left" /> Back
                  </button>
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={submitClicked}
                    onClick={this.submitForm}
                  >
                    {loadingContent == null ? "Save & Next" : loadingContent}
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(withToastManager(EditListingStep4));
