import React, { Component } from "react";

class AddListingStep8 extends Component {
  state = {};
  render() {
    const {
      sixthApiResponse,
      handleAmentiesChange,
      formData,
      pricingType,
    } = this.props;
    return (
      <div id="step-10" className="flow-tab">
        <div className="add-flow-block row">
          <div className="col-md-12">
            <div className="add-flow-block-left">
              <h4 className="flow-tit">{sixthApiResponse[0].title}</h4>
              <p className="main-txt text-muted">
                {sixthApiResponse[0].description}
              </p>
              <div className="flow-content">
                <div className="form-group large-form-group">
                  <h5 className="flow-box-tit">
                    {sixthApiResponse[0].data.question}
                  </h5>
                </div>
              </div>

              <div className="flow-content">
                <div className="form-group large-form-group">
                  <h4 className="flow-tit">{sixthApiResponse[1].title}</h4>

                  {sixthApiResponse[1].data.map((details) => (
                    <div className="flow-box">
                      <h5 className="flow-box-tit">{details.question}</h5>
                      <input
                        type="text"
                        className="form-control"
                        name={details.server_key}
                        placeholder={details.placeholder}
                        value={formData[details.server_key]}
                        onChange={handleAmentiesChange}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddListingStep8;
