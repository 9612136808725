import React, { Component } from "react";
import HostLoginModal from "../Auth/HostLoginModal";

import HostHeader from "./hostHeader";
import FrontdeskHeader from "./FrontdeskHeader";

// This Layouts used in Home page Header,  static footer and content

class HostWithoutFooterLayout extends Component {
  constructor(props) {
    super(props);

    // define states
    this.state = {
      isValidAuth : true
    }
  }

  toggleLoginModal = (state) => {
    this.setState({
        ...this.state,
        isValidAuth : state
    })
  }

  render() {
    return (
      <div className="wrapper">
        {this.state.isValidAuth || (
            <HostLoginModal />
        )} 
        {localStorage.getItem("is_frontdesk") == "1" ? <FrontdeskHeader {...this.props.children.props} /> : <HostHeader {...this.props.children.props} />}
        <div>{React.cloneElement(this.props.children , {isValidAuth : this.state.isValidAuth , toggleLoginModal :this.toggleLoginModal})}</div>
      </div>
    );
  }
}
export default HostWithoutFooterLayout;
