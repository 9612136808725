import React, { useEffect  , useState as useStateNormal} from 'react';
import { Link } from 'react-router-dom';
import api from "../../Environment";
import MapContainer from "../Helper/mapHelper";
import HomeRoomContent from "../Home/homeRoomContent";
import FilterIndex from './FilterIndex';
import $ from "jquery";
import useState from 'react-usestateref';

const queryString = require("query-string");

const SeeAllPageIndex = (props) => {

  // console.log(props.location);
 const [adultCount, setAdultCount, adultRef] = useState(0);

 const [children, setChildren, childrenRef] = useState(0);

 const [infants, setInfants, infantsRef] = useState(0);

 const [totalGuest, setTotalGuest, totalGuestRef] = useState(0);

 const [hostTypeData, setHostTypeData, hostTypeDataRef] = useState({});

 const [totalHomeTypeSelected, setTotalHomeTypeSelected] = useState(0);

 const [eventData, setEventData] = useState("");

 const [loadingEvent, setLoadingEvent] = useState(true);

 const [selectedEvent, setSelectedEvent , selectedEventRef] = useState([]);

 const [eventId, setEventId, eventIdRef] = useState([]);

 const [locationData, setLocationData] = useState("");

 const [loadingLocation, setLoadingLocation] = useState(true);

 const [selectedLocation, setSelectedLocation , selectedLocationRef] = useState([]);

 const [locationId, setLocationId, locationIdRef] = useState([]);

 const [listingData, setListingData] = useState("");

 const [loadingListing, setLoadingListing] = useState(true);

 const [subCategoryData, setSubCategoryData] = useState("");

 const [loadingSubCategory, setLoadingSubCategory] = useState(true);

 const [selectedSubCategory, setSelectedSubCategory , selectedSubCategoryRef] = useState([]);

 const [subCategoryId, setSubCategoryId, subCategoryIdRef] = useState([]);

 const [fromDate, setFromDate, fromDateRef] = useState(null);

 const [toDate, setToDate, toDateRef] = useState(null);

 const [mapView, setMapView, mapViewRef] = useState(0);

 const [toggleMap, setToggleMap] = useState(0);

 const [automaticBooking, setAutomaticBooking, automaticBookingRef] = useState('');

 const [contentData, setContentData] = useState([]);

 const [loadingContent, setLoadingContent] = useState(true);

 const [loadingStatus, setLoadingStatus] = useState(true);

 const [loadMoreContent, setLoadMoreContent] = useState("");

 const [mainData, setMainData] = useState("");

 const [skipCount, setSkipCount, skipRef] = useState(0);

 const [loadingMap, setLoadingMap] = useState(true);

 const [lastLat, setLastLat] = useState(null);

 const [lastLng, setLastLng] = useState(null);

 const [apiInputData, setApiInputData] = useState({});

 const [searchApiInputData, setSearchApiInputData] = useState({});

 const [searchStarted, setSearchStarted] = useState(false);

 const parsedValues = queryString.parse(props.location.search ,  { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" });

 const [skipInitalRender , setSkipIntialRender] = useStateNormal(false)

 const [keywordData, setKeywordData, keywordRef] = useState([]);

 const [priceFilter, setPriceFilter, priceFilterRef] = useState('best_match');

 useEffect(() => {
  getEventDetailsApi();
  getLocationDetailsApi();
  getSubCategoryApi();
  // getListingDetailsApi();
  console.log(props.location.state);
  console.log(parsedValues)

  // We need to change this code
  // let inputData;
  // if (props.location.state === undefined) {
  //   // props.history.goBack();
  // } else {
  //   console.log(props.location.state);
  //   if (props.location.state.type){
  //     if(props.location.state.type == "LOCATION") {
  //       inputData = {
  //         service_location_id: props.match.params.id,
  //         api_page_type_id: props.match.params.id,
  //         skip: skipRef.current,
  //         sub_category: props.match.params.url_type,
  //         api_page_type: props.location.state.type
  //       };
  //       setLocationId([parseInt(props.match.params.id)]);
  //       if (props.location.state.searchInput){
  //         setSelectedLocation([props.location.state.searchInput.service_location_name]);
  //       }
  //     } else if(props.location.state.type == "EVENT") {
  //       inputData = {
  //         event_id: props.match.params.id,
  //         skip: skipRef.current,
  //         api_page_type: props.location.state.type,
  //         location_name: props.location.state.location_name
  //       };
  //       setEventId([parseInt(props.match.params.id)]);
  //       if (props.location.state.searchInput){
  //         setSelectedEvent([props.location.state.searchInput.event_title]);
  //       }
  //     }else if(props.location.state.type == "SUB_CATEGORY") {
  //       inputData = {
  //         sub_category_id: props.match.params.id,
  //         api_page_type_id: props.match.params.id,
  //         skip: skipRef.current,
  //         sub_category: props.match.params.url_type,
  //         api_page_type: props.location.state.type
  //       };
  //       setSubCategoryId([parseInt(props.match.params.id)]);
  //       if (props.location.state.searchInput){
  //         setSelectedSubCategory([props.location.state.searchInput.sub_cat_title]);
  //       }
  //     }
  //   } else {
  //     inputData = {
  //       service_location_id: props.location.state.searchInput.service_location_id ? props.location.state.searchInput.service_location_id: '',
  //       event_id: props.location.state.searchInput.event_id ? props.location.state.searchInput.event_id : '',
  //       from_date: props.location.state.searchInput.from_date ? props.location.state.searchInput.from_date : '',
  //       sub_category_id: props.location.state.searchInput.sub_cat_id ? props.location.state.searchInput.sub_cat_id : '',
  //     };
  //     if(props.location.state.searchInput.service_location_id) {
  //       setLocationId([parseInt(props.location.state.searchInput.service_location_id)]);
  //       setSelectedLocation([props.location.state.searchInput.service_location_name]);
  //     }
  //     if(props.location.state.searchInput.event_id) {
  //       setEventId([parseInt(props.location.state.searchInput.event_id)]);
  //       setSelectedEvent([props.location.state.searchInput.event_title]);
  //     }
  //     if(props.location.state.searchInput.from_date) {
  //       setFromDate(props.location.state.searchInput.from_date);
  //     }
  //     if(props.location.state.searchInput.sub_cat_id) {
  //       setSubCategoryId([parseInt(props.location.state.searchInput.sub_cat_id)]);
  //       setSelectedSubCategory([props.location.state.searchInput.sub_cat_title]);
  //     }
  //   }
  // }

  let inputData;

  if(Object.keys(parsedValues).length > 0){
    if(parsedValues.fromDate){
      setFromDate(parsedValues.fromDate)
      inputData = {...inputData , from_date : parsedValues.fromDate}
    }
    if(parsedValues.eventId){
      setEventId(parsedValues.eventId.map(elem=> parseInt(elem, 10)))
      setSelectedEvent(parsedValues.selectedEvent)
      inputData = {...inputData , event_id : parsedValues.eventId}
    }
    if(parsedValues.locationId){
      setLocationId(parsedValues.locationId.map(elem=> parseInt(elem, 10)))
      setSelectedLocation(parsedValues.selectedLocation)
      inputData = {...inputData , service_location_id : parsedValues.locationId}
    }
    if(parsedValues.subCatID){
      setSubCategoryId(parsedValues.subCatID.map(elem=> parseInt(elem, 10)))
      setSelectedSubCategory(parsedValues.selectedSubCategory)
      inputData = {...inputData , sub_category_id : parsedValues.subCatID}
    }

    if(parsedValues.hostTypeData){
      setHostTypeData(parsedValues.hostTypeData)
      setTotalHomeTypeSelected(parsedValues.hostTypeData.length)
    }
    if(parsedValues.adults){
      setAdultCount(Number(parsedValues.adults))
    }
    if(parsedValues.totalGuest){
      setTotalGuest(Number(parsedValues.totalGuest))
    }
    if(parsedValues.children){
      setChildren(Number(parsedValues.children))
    }
    if(parsedValues.infants){
      setInfants(Number(parsedValues.children))
    }
    
    if(parsedValues.automaticBooking){
      setAutomaticBooking(Number(parsedValues.automaticBooking))
    }
    if(parsedValues.priceFilter){
      setPriceFilter(parsedValues.priceFilter)
    }
    if(parsedValues.keywords){
      inputData = {...inputData,keywords : parsedValues.keywords}
      setKeywordData([parsedValues.keywords]);
    }

    if(parsedValues.mapView){

      setMapView(Number(parsedValues.mapView))
      setToggleMap(Number(parsedValues.mapView))
    }
  }



  setApiInputData(inputData);

  if(Object.keys(parsedValues).length > 0 && parsedValues.type) {
    seeAll(inputData);
  } else {
    searchResultApiCall();
  }
  
 }, []);

 const seeAll = async (inputData, _loadmore) => {
  await api.postMethod("see_all", { ...inputData, skip: skipRef.current }).then((response) => {
   if (response.data.success) {
    let items;
    if (
     contentData.length > 0 &&
     response.data.data[0].data.length > 0
    ) {
     setLoadingStatus(true);
     setLoadMoreContent("");
     items = [...contentData, ...response.data.data[0].data];
    } else {
     if (contentData.length > 0) {
      setLoadingStatus(false);
      items = contentData;
      setLoadMoreContent("No More data...");
     } else {
      setLoadingStatus(true);
      setLoadMoreContent("");
      items = response.data.data[0].data;
     }
    }
    setContentData(items);
    setMainData(response.data.data);
    setLoadingContent(false);
    setSkipCount(() => _loadmore ? response.data.data[0].data.length + skipCount : response.data.data[0].data.length);
    setLoadingMap(false);
    // props.history.replace({ pathname: `/see_all`})
    props.history.replace({ pathname: props.location.pathname , search : props.location.search})
   }
  });
 };

 const loadMore = (event) => {
  event.preventDefault();
  setLoadingStatus(false);
  setLoadMoreContent("Loading...");
  if (searchStarted) {
   // Call Search Result api. 
   searchResultApiCall(true);
  } else {
   // call see all api. 
   seeAll(apiInputData, true);
  }
  // let inputData;
  // if (props.location.state.notMainSearch) {
  //  let inputData = {
  //   ...props.location.state.formData,
  //   skip: skipRef.current,
  //  }
  //  seeAll(inputData, true);
  // } else {
  //  if (props.match.params.name == "events") {
  //   inputData = {
  //    api_page_type: props.match.params.type,
  //    event_id: props.match.params.id,
  //    skip: skipRef.current,
  //   };
  //  } else {
  //   inputData = {
  //    api_page_type: props.match.params.url_type,
  //    api_page_type_id: props.match.params.id,
  //    skip: skipRef.current,
  //   };
  //  }
  // }

 };

 const getListForLatLng = (lat, lng) => {
  setSkipCount(0);
  setContentData([]);
  setLoadingContent(true);
  setLastLat(lat);
  setLastLng(lng);
  const inputData = {
   api_page_type: "LAT_LONG",
   skip: skipRef.current,
   latitude: lat,
   longitude: lng,
  };
  seeAll(inputData);
 };


 const getEventDetailsApi = () => {
  api.postMethod("events_index").then((response) => {
   if (response.data.success === true) {
    setLoadingEvent(false);
    setEventData(response.data.data.events);
   } else {
    // Do nothing
   }
  });
 };

 const getLocationDetailsApi = () => {
  api.postMethod("filter_locations").then((response) => {
   if (response.data.success === true) {
    setLoadingLocation(false);
    setLocationData(response.data.data);
   } else {
    // Do nothing
   }
  });
 };

 const getListingDetailsApi = () => {
  api.postMethod("listings_index").then((response) => {
   if (response.data.success === true) {
    setLoadingListing(false);
    setListingData(response.data.data.listings);
   } else {
    // Do nothing
   }
  });
 };

 const getSubCategoryApi = () => {
  api.postMethod("subcategories_index").then((response) => {
   if (response.data.success === true) {
    setLoadingSubCategory(false);
    setSubCategoryData(response.data.data.sub_categories.data);
   } else {
    // Do nothing
   }
  });
 };

 // change date 
 const onChangeDate = (title) => (...args) => {
  const from_date = convert(args[0]);
  const to_date = convert(args[1]);

  if (to_date == "NaN-aN-aN") {
   setFromDate(from_date);
  } else {
   setFromDate(from_date);
   setToDate(to_date)
  }
  console.log(from_date);
  console.log(fromDate);
  if (from_date != null) {
   searchResultApiCall();
  }
 };

 // handle location change function
 const onChangeKeyword = (e, keyword) => {
  const tempKeywordData = [...keywordData];
  if (e.target.checked) {
   if (keywordData == null) {
    let arrayB = [];
    arrayB.push(keyword);
    setKeywordData(arrayB);
   } else {
    tempKeywordData.push(keyword);
    setKeywordData(tempKeywordData);
   }
  } else {
   let indexSelectedKey = keywordData.indexOf(keyword);
   if (indexSelectedKey !== -1) {
    tempKeywordData.splice(indexSelectedKey, 1);
    setKeywordData(tempKeywordData);
   }
  }
 };

 const onChangePrice = (event,value) => {
  event.preventDefault();
  setPriceFilter(value);
  setTimeout(() => {
    searchResultApiCall();
  }, 100);
 }  

 const convert = (str) => {
  let date = new Date(str),
   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
   day = ("0" + date.getDate()).slice(-2);
  return "Date", [date.getFullYear(), mnth, day].join("-");
 }

 // Apply guest function
 const handleGuestCancelButton = (event) => {
  event.preventDefault();
  setAdultCount(0);
  setChildren(0);
  setInfants(0);
  setTotalGuest(0);
  searchResultApiCall();
 };

 // Type of Place selected this function will trigger. 
 const handlePlaceType = (event) => {
  const tempHostTypeData = { ...hostTypeData };
  if (event.target.checked) {
   if (tempHostTypeData[event.target.name] === undefined) {
    let array = [];
    array.push(event.target.value);
    tempHostTypeData[event.target.name] = array;
    setHostTypeData(tempHostTypeData);
    setTotalHomeTypeSelected(totalHomeTypeSelected + 1);
   } else {
    tempHostTypeData[event.target.name].push(event.target.value);
    setHostTypeData(tempHostTypeData);
    setTotalHomeTypeSelected(totalHomeTypeSelected + 1);
   }
  } else {
   let index = tempHostTypeData[event.target.name].indexOf(event.target.value);
   if (index !== -1) {
    tempHostTypeData[event.target.name].splice(index, 1);
    setHostTypeData(tempHostTypeData);
    setTotalHomeTypeSelected(totalHomeTypeSelected - 1);
   }
  }
 };

 // Type of place clear button function. 
 const handleHomeTypeCancelButton = (event) => {
  var $checkboxes = $("#home-type-form :checkbox");
  let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
  $checkboxes.prop("checked", checkbox_value ? false : '');
  event.preventDefault();
  setTotalHomeTypeSelected(0);
  setHostTypeData({});
  searchResultApiCall();
 };

 // Handle Event change function

 const handlChangeEvent = (e, event) => {
  const tempSelectedEvent = [...selectedEvent];
  const tempEventId = [...eventId];
  if (e.target.checked) {
   if (eventId == null) {
    let arrayB = [];
    arrayB.push(event.title);
    setSelectedEvent(arrayB);
    let array = [];
    const value = parseInt(event.event_id);
    array.push(value);
    setEventId(array);
   } else {
    const value = parseInt(event.event_id);
    tempEventId.push(value);
    tempSelectedEvent.push(event.title);
    setEventId(tempEventId);
    setSelectedEvent(tempSelectedEvent);
   }
  } else {
   let index = eventId.indexOf(event.event_id);
   if (index !== -1) {
    tempEventId.splice(index, 1);
    setEventId(tempEventId);
   }
   let indexSelectedEvent = selectedEvent.indexOf(event.title);
   if (indexSelectedEvent !== -1) {
    tempSelectedEvent.splice(indexSelectedEvent, 1);
    setSelectedEvent(tempSelectedEvent);
   }
  }
 };

 // handle event filter cancel button
 const handleEventCancelButton = (event) => {

  var $checkboxes = $("#event-type-form :checkbox");
  let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
  $checkboxes.prop("checked", checkbox_value ? false : '');

  // event.preventDefault();
  setSelectedEvent([]);
  setEventId([]);
  searchResultApiCall();

 };


 // Handle sub category change function
 const handlSubCateEvent = (e, event) => {
  const tempSelectedSubCategory = [...selectedSubCategory];
  const tempSubCategoryId = [...subCategoryId];
  if (e.target.checked) {
   if (subCategoryId == null) {
    let arrayB = [];
    arrayB.push(event.name);
    setSelectedSubCategory(arrayB);
    let array = [];
    const value = parseInt(event.id);
    array.push(value);
    setSubCategoryId(array);
   } else {
    const value = parseInt(event.id);
    tempSubCategoryId.push(value);
    tempSelectedSubCategory.push(event.name);
    setSubCategoryId(tempSubCategoryId);
    setSelectedSubCategory(tempSelectedSubCategory);
   }
  } else {
   let index = tempSubCategoryId.indexOf(event.id);
   if (index !== -1) {
    tempSubCategoryId.splice(index, 1);
    setSubCategoryId(tempSubCategoryId);
   }
   let indexSelectedEvent = selectedSubCategory.indexOf(event.name);
   if (indexSelectedEvent !== -1) {
    tempSelectedSubCategory.splice(indexSelectedEvent, 1);
    setSelectedSubCategory(tempSelectedSubCategory);
   }
  }
 };
 // handle subcategory clear button

 const handleSubCatCancelButton = (event) => {
  var $checkboxes = $("#subCat-type-form :checkbox");
  let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
  $checkboxes.prop("checked", checkbox_value ? false : '');
  event.preventDefault();
  setSelectedSubCategory([]);
  setSubCategoryId([]);
  searchResultApiCall();
 };

 // handle location change function
 const handlLocationEvent = (e, event) => {
  const tempSelectedLocation = [...selectedLocation];
  const tempLocationId = [...locationId];

  if (e.target.checked) {
   if (locationId == null) {
    let arrayB = [];
    arrayB.push(event.service_location_name);
    setSelectedLocation(arrayB);

    let array = [];
    const value = parseInt(event.service_location_id);
    array.push(value);
    setLocationId(array);
   } else {
    const value = parseInt(event.service_location_id);
    tempLocationId.push(value);
    tempSelectedLocation.push(event.service_location_name);
    setLocationId(tempLocationId);
    setSelectedLocation(tempSelectedLocation);
   }
  } else {
   let index = locationId.indexOf(event.service_location_id);
   if (index !== -1) {
    tempLocationId.splice(index, 1);
    setLocationId(tempLocationId);
   }
   let indexSelectedEvent = selectedLocation.indexOf(event.service_location_name);
   if (indexSelectedEvent !== -1) {
    tempSelectedLocation.splice(indexSelectedEvent, 1);
    setSelectedLocation(tempSelectedLocation);
   }
  }
 };

 //handle location clear button
 const handleLocationCancelButton = (event) => {
  var $checkboxes = $("#location-type-form :checkbox");
  let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
  $checkboxes.prop("checked", checkbox_value ? false : '');
  event.preventDefault();
  setSelectedLocation([]);
  setLocationId([]);
  searchResultApiCall();

 };
 
  //handle location clear button
  const handleKeywordCancelButton = (event) => {
    var $checkboxes = $("#keyword-type-form :checkbox");
    let checkbox_value = $checkboxes.length === $checkboxes.filter(":checked").length;
    $checkboxes.prop("checked", checkbox_value ? false : '');
    event.preventDefault();
    setKeywordData([]);
    searchResultApiCall();
  
   };

 // handle map function
 const handleMap = (event) => {
  event.preventDefault();
  let queryData =  {...parsedValues};
  setMapView(mapView == 1 ? 0 : 1);
  setToggleMap(toggleMap == 1 ? 0 : 1);
  
  if (mapViewRef.current) {
    queryData = {...queryData , mapView: mapViewRef.current}
  }else{
    queryData = {...queryData , mapView: mapViewRef.current}
  }

  const query = queryString.stringify(
    queryData,
    { arrayFormat: "bracket-separator" , arrayFormatSeparator: '|'}
  );
  props.history.replace({
    pathname: props.location.pathname,
    search: query,
  });
 };

 // Handle Instant booking
 const handleInstantBooking = (event) => {
  setLoadingContent(true);
  if (event.target.checked) {
   setAutomaticBooking(1);
   setTimeout(() => {
    searchResultApiCall();
   }, 100);
  } else {
   setAutomaticBooking('');
   setTimeout(() => {
    searchResultApiCall();
   }, 100);
  }
 };



 const searchResultApiCall = (_loadmore) => {
  console.log("_loadmore", _loadmore);
  if (_loadmore) {
   // do nothing...
  } else {
   setLoadingContent(true);
  }

  setSearchStarted(true);
  let formData;

  if (adultRef.current != 0) {
   formData = {
    adults: adultRef.current,
   };
  }
  if (childrenRef.current != 0) {
   formData = {
    ...formData,
    children: childrenRef.current,
   };
  }
  if (infantsRef.current != 0) {
   formData = {
    ...formData,
    infants: infantsRef.current,
   };
  }

  if (fromDateRef.current != null) {
   formData = {
    ...formData,
    from_date: fromDateRef.current,
   };
  }

  if (toDateRef.current != null) {
   formData = {
    ...formData,
    to_date: toDateRef.current,
   };
  }
  if (hostTypeDataRef.current != {}) {
   formData = {
    ...formData,
    ...hostTypeDataRef.current,
   };
  }
  if (eventIdRef.current != null) {
   formData = {
    ...formData,
    search_event_id: eventIdRef.current,
   };
  }

  if (automaticBookingRef.current != null) {
   formData = {
    ...formData,
    is_automatic_booking: automaticBookingRef.current,
   };
  }

  if (priceFilterRef.current != null) {
    formData = {
     ...formData,
     price_filter: priceFilterRef.current,
    };
  }

  if (locationIdRef.current != null) {
   formData = {
    ...formData,
    service_location_id: locationIdRef.current,
   };
  }

  if (subCategoryIdRef.current != null) {
   formData = {
    ...formData,
    sub_category_id: subCategoryIdRef.current,
   };
  }

  if (keywordRef.current != null) {
    formData = {
     ...formData,
     keywords: keywordRef.current,
    };
   }

  formData = {
   ...formData,
   // ...apiInputData,
   skip: _loadmore ? skipRef.current : 0
  };
  console.log(eventIdRef.current);
  console.log(formData);

  if(skipInitalRender){
    constructSearchFormData();
  }

  api
   .postMethod("search_result", formData)
   .then((response) => {
    if (response.data.success === true) {
     let items;
     if (
      contentData.length > 0 &&
      response.data.data[0].data.length > 0 &&
      _loadmore
     ) {
      setLoadingStatus(true);
      setLoadMoreContent("");
      items = [...contentData, ...response.data.data[0].data];
     } else {
      if (contentData.length > 0 && _loadmore) {
       setLoadingStatus(false);
       items = contentData;
       setLoadMoreContent("No More data...");
      } else {
       setLoadingStatus(true);
       setLoadMoreContent("");
       items = response.data.data[0].data;
      }
     }

     console.log("data", response.data);
     setContentData(items);
     setMainData(response.data.data);
     setLoadingContent(false);
     setSkipCount(() => _loadmore ? response.data.data[0].data.length + skipCount : response.data.data[0].data.length);
     setLoadingMap(false);
    } else {
     // Error message
    }
   })
   .catch(function (error) { });

 };

const constructSearchFormData = () => {
  let queryData =  {...parsedValues};

  if(hostTypeData.host_type){
    queryData = {...queryData , hostTypeData : hostTypeData.host_type}
  }else{
    delete queryData.hostTypeData
  }

  if(selectedEventRef.current!= 0){
    queryData = {...queryData , selectedEvent : selectedEventRef.current , eventId : eventIdRef.current}
  }else{
    delete queryData.selectedEvent
    delete queryData.eventId
  }

  if(selectedSubCategoryRef.current!= 0) {
    queryData = {...queryData , selectedSubCategory : selectedSubCategoryRef.current , subCatID : subCategoryIdRef.current}
  }else{
    delete queryData.selectedSubCategory
    delete queryData.subCatID
  }

  if(selectedLocationRef.current!= 0){
    queryData = {...queryData , selectedLocation : selectedLocationRef.current , locationId : locationIdRef.current}
  }else{
    delete queryData.selectedLocation
    delete queryData.locationId
  }

  if (fromDateRef.current != null) {
    queryData = {
     ...queryData,
     fromDate: fromDateRef.current,
    };
   }
 
   if (toDateRef.current != null) {
    queryData = {
     ...queryData,
     toDate: toDateRef.current,
    };
   }

  if (adultRef.current != 0) {
    queryData = {...queryData , adults: adultRef.current}
  }else{
    delete queryData.adults
  }

  if(totalGuestRef.current != 0){
    queryData = {...queryData , totalGuest: totalGuestRef.current}
  }else{
    delete queryData.totalGuest
  }

  if (childrenRef.current != 0) {
  queryData = {...queryData , children: childrenRef.current}
  }else{
    delete queryData.children
  }

  if (infantsRef.current != 0) {
  queryData = {...queryData , infants: infantsRef.current}
  }else{
    delete queryData.infants
  }
  
  if (automaticBookingRef.current != 0) {
    queryData = {...queryData , automaticBooking: automaticBookingRef.current}
  }else{
    delete queryData.automaticBooking
  }

  if (priceFilterRef.current != 0) {
    queryData = {...queryData , priceFilter: priceFilterRef.current}
  }else{
    delete queryData.priceFilter
  }

  const query = queryString.stringify(
    queryData,
    { arrayFormat: "bracket-separator" , arrayFormatSeparator: '|'}
  );

  console.log(query)

  props.history.replace({
    pathname: props.location.pathname,
    search: query,
  });
}

useEffect(() => {
  setSkipIntialRender(true)
},[])

return (
  <>
   <div className="main">
    <FilterIndex {...props} adultCount={adultCount} setAdultCount={setAdultCount} children={children} setChildren={setChildren} infants={infants} setInfants={setInfants} totalGuest={totalGuest}
     hostTypeData={hostTypeData} onChangeDate={onChangeDate} setTotalGuest={setTotalGuest} totalHomeTypeSelected={totalHomeTypeSelected}
     setTotalHomeTypeSelected={setTotalHomeTypeSelected} handlePlaceType={handlePlaceType} fromDate={fromDate}
     loadingEvent={loadingEvent} eventData={eventData} selectedEvent={selectedEvent} eventId={eventId} handlChangeEvent={handlChangeEvent}
     loadingSubCategory={loadingSubCategory} subCategoryData={subCategoryData} selectedSubCategory={selectedSubCategory} handlSubCateEvent={handlSubCateEvent}
     loadingLocation={loadingLocation} locationData={locationData} selectedLocation={selectedLocation} handlLocationEvent={handlLocationEvent}
     handleMap={handleMap} toggleMap={toggleMap} mapView={mapView} handleInstantBooking={handleInstantBooking}
     handleGuestCancelButton={handleGuestCancelButton} searchResultApiCall={searchResultApiCall}
     handleHomeTypeCancelButton={handleHomeTypeCancelButton} handleEventCancelButton={handleEventCancelButton}
     handleSubCatCancelButton={handleSubCatCancelButton} handleLocationCancelButton={handleLocationCancelButton} subCategoryId={subCategoryId}
     constructSearchFormData={constructSearchFormData} 
     onChangeKeyword={onChangeKeyword} handleKeywordCancelButton={handleKeywordCancelButton} 
     keywordData={keywordData} />

    <div className="section-spacing main-bg">
     <div className="pd-btm-xs">
      <div className="row">
       <div
        className={
         toggleMap
          ? "col-8 full-width-tab full-width-mob"
          : "col-12"
        }
       >
        <div className="">
         {loadingContent ? (
          "Loading..."
         ) : (
          <React.Fragment>
           {contentData.length > 0 ? (
            <HomeRoomContent
             contentDetails={contentData}
             title={mainData[0].title}
             history={props.history}
             onChangePrice={onChangePrice} 
             automaticBooking={automaticBooking} 
             priceFilter={priceFilter}
             isMain={1}
            />
           ) : (
            "No Data found"
           )}
          </React.Fragment>
         )}
        </div>
        {loadingStatus ? "" : loadMoreContent}
        {loadingContent ? (
        ""
        ) : contentData.length > 0 && loadMoreContent == "" ? (
        <button className="show-all button-show-all" onClick={loadMore}>
          show more
        </button>
        ) : (
        ""
        )}
       </div>
       
       {toggleMap ? (
        <div className="col-4 padding-left-zero full-width-tab full-width-mob">
         <div className="map-sec">
          {loadingMap ? (
           ""
          ) : (
           <MapContainer
            data={contentData}
            onMapPanned={getListForLatLng}
            lastLat={lastLat}
            lastLng={lastLng}
           />
          )}
         </div>
        </div>
       ) : (
        false
       )}
      </div>
     </div>
    </div>
   </div>

  </>
 );
}

export default SeeAllPageIndex;