import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import Loader from "../../Helper/Loader";
import Helper from "../../Helper/Helper";

class FrontDesk  extends Helper {

  state = {
    loadingListing: true,
    listingData: null,
    skipCount:0,
    loading: true,
    loadingMap: true,
    loadingContent: null,
    contentData: null,
    singleTripDetails: [],
  };

  componentDidMount() {
    this.getListingDetails();
  }

  

  getListingDetails = (inputData) => {

    api.postMethod("hosts_search",inputData).then((response) => {
      let items;
      if (response.data.success === true) {

        if (this.state.contentData != null) {
          items = [...this.state.contentData, ...response.data.data.hosts];
        } else {
          items = [...response.data.data.hosts];
        }

        this.setState({
          contentData: items,
          loadingListing: false,
          listingData: response.data.data.hosts,
          loading: false,
          loadingStatus: true,
          skipCount: response.data.data.hosts.length + this.state.skipCount,
          loadingContent:'',
        });
      } else {
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      }
    });
  };


  singleTripViewApiCall(formData) {
    api.postMethod("hosts_view", formData).then((response) => {
      if (response.data.success === true) {
        this.setState({
          singleTripDetails: response.data.data,
          loading: false,
        });
        this.props.history.push(`/host/single/${formData['host_id']}`,response.data.data);


      } else {
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      
        this.props.history.goBack();
      }
    });
  }

  handleImageClick = (host_id) => {
    console.log("IMage clicked. ", host_id);
    if(localStorage.getItem('is_frontdesk')  === 1){
      const formData = {
        host_id: host_id,
      };
      this.singleTripViewApiCall(formData);
    }else{
      
      this.props.history.push(`/host/single/${host_id}`);

    }
  };

  loadMore = (event) => {
    event.preventDefault();
    this.setState({ loadingListing: false, loadingContent: "Loading..." });    
    let inputData = {
      skip: this.state.skipCount,
    };
    
    this.getListingDetails(inputData);
  };

  render() {
    let load = new Loader();
    const { loadingListing, listingData, loadingContent, loading } = this.state;
    return (
      
      <div className="main">

          <div className="section-spacing">
            <div className="">
              <div className="row">
                <div className="col-12">
                  {loading ? (
                    load.propertyLoader()
                  ) :

                  this.state.contentData.length > 0 ? (
                    <div className="subcategory-leftsec row">
                      {this.state.contentData.map(data => (
                        <div className="subcategory-card view-all-card" key={data.host_id}>
                        <div className="relative">
                          <section className="home-slider slider">
                            <Carousel
                              showThumbs={false}
                              infiniteLoop={true}
                              showStatus={false}
                            >
                              {data.gallery.map((image) => (
                                <div
                                  key={image.picture}
                                  className="homes-img-sec1"
                                  onClick={() => this.handleImageClick(data.host_id)}
                                >
                                  <img
                                    srcSet={image.picture}
                                    src={image.picture}
                                    alt="Loading..."
                                    className="homes-img"
                                  />
                                </div>
                              ))}
                            </Carousel>
                          </section>
                          <div className="amount-display">
                          {data.pricing_type != 3 ? (
                            <h2 className="absolute-amount">
                              From <span> {data.pricing_type_amount_formatted} </span>/{" "}
                              {data.pricing_type_text}
                            </h2>
                          ) : (
                            <h2 className="absolute-amount">
                              Packages From <span> {data.pricing_type_amount_formatted} </span>
                            </h2>
                          )}
                          </div>
                          
                        </div>
                        <Link to={`/host/single/${data.host_id}`}>
                          <div className="homes-text-sec">
                            <p className="red-text txt-overflow">
                              {data.host_location}{" "}
                              <span className="room-content-dot">
                                <i className="fas fa-circle" />
                              </span>{" "}
                              {data.sub_category_name}
                            </p>
                            <h4 className="homes-title txt-overflow m-t-10">
                              {data.host_name}
                            </h4>
                            <h5 className="homes-price txt-overflow m-t-10">
                              <span>
                                {data.is_automatic_booking == 1 ? (
                                  <span>Rapid Booking</span>
                                ) : (
                                  <span className="text-strike">Rapid Booking</span>
                                )}
                              </span>
                            </h5>
                            <div className="flex-common">
                              <p className="guest-icon">
                                <i className="fa fa-users"></i> {data.max_guests}
                              </p>
                              <h5 className="rating-sec">
                                <span className="rating-star">
                                  {this.starRatingHost(data.overall_ratings)}
                                </span>
                                <span className="rating-count">
                                  &nbsp;({data.total_ratings})
                                </span>
                              </h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      ))}
                    </div>
                  ) : (
                    "No data Found"
                  )}
                </div>
              </div>
              
              {loadingListing ? "" : loadingContent}

              {loadingListing ? (
                ""
              ) : this.state.contentData.length > 0 ? (
                <Link to="#" className="show-all" onClick={this.loadMore}>
                  show more
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
    
    );
  }
}

export default FrontDesk;
