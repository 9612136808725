import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";

import "./Listing.css";
import PricingsTimeSlots from "./Pricings/PricingsTimeSlots";
const $ = window.$;

class HostPricing extends Component {
  state = {
    loading: true,
    host: null,
    loadingContent: null,
    buttonDisable: false,
    hostId: null,
    pricingType: 0,
    formData: null,
    packagePricing: null,
    loadingPackagePricing: true,
    packagePricingInput: {},
    perGuestpricingInput: {},
    hostTimeSlots: [],
    timeSlotInput: {},
    showPricingTimeslots: true,
  };
  componentDidMount() {
    this.setState({ hostId: this.props.match.params.id });
    this.getHostViewApiCall(this.props.match.params.id);
    const packagePricingInput = { ...this.state.packagePricingInput };
    packagePricingInput["host_id"] = this.props.match.params.id;
    this.setState({ packagePricingInput });
  }
  packagePricingSave = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("hosts_pricing_packages_save", this.state.packagePricingInput)
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getPackagePricing();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
        this.setState({ loadingContent: null, buttonDisable: false });
      })
      .catch(function(error) {});
  };

  getPackagePricing = () => {
    api
      .postMethod("hosts_pricing_packages", {
        host_id: this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            loadingPackagePricing: false,
            packagePricing: response.data.data,
          });
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  getHostViewApiCall = (hostId) => {
    api
      .postMethod("hosts_pricings_view", { host_id: hostId })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            loading: false,
            host: response.data.data.host,
            pricingType: response.data.data.host.pricing_type,
            hostTimeSlots: response.data.data.host_timeslots,
          });
          const packagePricingInput = { ...this.state.packagePricingInput };
          packagePricingInput["package_base_price"] = this.state.host.base_price;
          this.setState({ packagePricingInput: packagePricingInput });

          const formData = { ...this.state.formData };
          formData["base_price"] = this.state.host.base_price;
          formData["package_base_price"] = this.state.host.base_price;
          formData["per_guest_price"] = this.state.host.per_guest_price;
          formData["pricing_type"] = this.state.host.pricing_type;
          formData["host_id"] = this.state.hostId;
          formData["cleaning_fee"] = response.data.data.host.cleaning_fee;
          formData["security_deposit"] =
            response.data.data.host.security_deposit;
          formData["base_price_adult"] =
            response.data.data.host.base_price_adult;
          formData["base_price_age_special"] =
            response.data.data.host.base_price_age_special;
          formData["base_price_child"] =
            response.data.data.host.base_price_child;
          formData["base_price_infants"] =
            response.data.data.host.base_price_infants;
          formData["general_tax_in_percentage"] =
            response.data.data.host.general_tax_in_percentage;
          formData["room_tax_in_percentage"] =
            response.data.data.host.room_tax_in_percentage;
          formData["pst_tax_in_percentage"] =
            response.data.data.host.pst_tax_in_percentage;
          formData["hst_tax_in_percentage"] =
            response.data.data.host.hst_tax_in_percentage;
          this.setState({ formData: formData,showPricingTimeslots: true });
          if (response.data.data.host.pricing_type == 3) {
            this.getPackagePricing();
            this.setState({ showPricingTimeslots: false });
          }
          
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  handleChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
    // for booking type change.
    setTimeout(() => {
      if (this.state.formData.pricing_type != undefined) {
        this.setState({ pricingType: this.state.formData.pricing_type });
        if (this.state.pricingType == 3) {
          this.getPackagePricing();

          this.setState({ showPricingTimeslots: false });
        }
      }
    }, 500);
  };

  handleChangePackagePricing = ({ currentTarget: input }) => {
    const packagePricingInput = { ...this.state.packagePricingInput };
    packagePricingInput[input.name] = input.value;
    this.setState({ packagePricingInput });
  };

  deletePackage = (event, packageDetails) => {
    event.preventDefault();
    api
      .postMethod("hosts_pricing_packages_delete", {
        host_pricing_package_id: packageDetails.host_pricing_package_id,
      })
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getPackagePricing();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  };

  handlePricingSubmit = (event) => {
    event.preventDefault();
    if (this.state.pricingType == 3) {
      // Do nothing.
    } else {
      this.setState({
        loadingContent: "Loading... Please wait..",
        buttonDisable: true,
      });

      api
        .postMethod("hosts_pricings_save", this.state.formData)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({hostTimeSlots:[]});
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
         
            this.setState({loadingContent: null, buttonDisable: false });
            this.getHostViewApiCall(this.props.match.params.id);
          } else {
            ToastDemo(this.props.toastManager, response.data.error, "error");
            this.setState({ loadingContent: null, buttonDisable: false });
          }
        })
        .catch(function(error) {});
    }
  };

  // Per guest pricing.

  handleChangePerGuestPricing = ({ currentTarget: input }) => {
    const perGuestpricingInput = { ...this.state.perGuestpricingInput };
    perGuestpricingInput[input.name] = input.value;
    this.setState({ perGuestpricingInput });
  };

  handleTimeSlotChange = ({ currentTarget: input }) => {
    const timeSlotInput = { ...this.state.timeSlotInput };
    timeSlotInput["host_time_slot_id"] = input.id;
    timeSlotInput[input.name] = input.value;
    console.log(input);
    console.log(timeSlotInput);
    this.setState({ timeSlotInput });
  };

  UpdateTimeSlot = (event, timeslot) => {
    event.preventDefault();
    console.log("current value",$('#'+timeslot).val());
    // const timeSlotInput = { ...this.state.timeSlotInput };
    // timeSlotInput["host_time_slot_id"] = timeslot;
    // timeSlotInput["fixed_base_price"] = $('#timeslot'+timeslot).val() ? $('#timeslot'+timeslot).val() : '';

    // this.setState({ timeSlotInput });

    setTimeout(() => {
      api
        .postMethod("host_timeslots_price_update", this.state.timeSlotInput)
        .then((response) => {
          if (response.data.success === true) {
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
          } else {
            ToastDemo(this.props.toastManager, response.data.error, "error");
          }
        })
        .catch(function(error) {});
    }, 500);
  };

  render() {
    const {
      loading,
      pricingType,
      loadingPackagePricing,
      packagePricing,
    } = this.state;

    return (
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <form onSubmit={this.handlePricingSubmit}>
                  <div className="panel">
                    <div className="panel-heading">
                      Update your special pricing
                      <Link className="float-right" to={"/host/listing"}>
                        <i className="fas fa-chevron-left" /> Back
                      </Link>
                    </div>
                    <div className="panel-body account host-pricing">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="media user-profile-sec">
                            <div className="media-body">
                              {loading ? (
                                "Loading..."
                              ) : (
                                <>
                                  <h2>{this.state.host.host_name}</h2>

                                  <div className="flow-content">
                                    <div className="form-group large-form-group">
                                      <h5 className="flow-box-tit">
                                        Choose price type for your space
                                      </h5>
                                      <div
                                        className="form-check add-list-block"
                                        key="PRICING_TYPE_FIXED"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="pricing_type"
                                          id="PRICING_TYPE_FIXED"
                                          value="1"
                                          onChange={this.handleChange}
                                          defaultChecked={
                                            this.state.host.pricing_type == 1
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className="form-check-label row"
                                          htmlFor="PRICING_TYPE_FIXED"
                                        >
                                          <h5 className="flow-check-tit">
                                            Fixed Activity Rate/Basic Rent
                                          </h5>
                                        </label>
                                        <div className="clear-both" />
                                      </div>

                                      {this.state.host.booking_type != 1 ? (
                                        <>
                                          <div
                                            className="form-check add-list-block"
                                            key="PRICING_TYPE_PER_GUEST"
                                          >
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="pricing_type"
                                              id="PRICING_TYPE_PER_GUEST"
                                              value="2"
                                              onChange={this.handleChange}
                                              defaultChecked={
                                                this.state.host.pricing_type ==
                                                2
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="form-check-label row"
                                              htmlFor="PRICING_TYPE_PER_GUEST"
                                            >
                                              <h5 className="flow-check-tit">
                                                Rate Per Guest
                                              </h5>
                                            </label>
                                            <div className="clear-both" />
                                          </div>

                                          <div
                                            className="form-check add-list-block"
                                            key="PRICING_TYPE_PACKAGE"
                                          >
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="pricing_type"
                                              id="PRICING_TYPE_PACKAGE"
                                              value="3"
                                              onChange={this.handleChange}
                                              defaultChecked={
                                                this.state.host.pricing_type ==
                                                3
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label
                                              className="form-check-label row"
                                              htmlFor="PRICING_TYPE_PACKAGE"
                                            >
                                              <h5 className="flow-check-tit">
                                                Package Based Rate
                                              </h5>
                                            </label>
                                            <div className="clear-both" />
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="flow-content">
                                    {/* <div className="form-group large-form-group">
                                      <label className="light-txt">
                                        Cleaning Fee
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="$"
                                        name="cleaning_fee"
                                        onChange={this.handleChange}
                                        value={
                                          this.state.formData != null
                                            ? this.state.formData.cleaning_fee
                                            : 0
                                        }
                                      />
                                    </div> */}

                                    {/* <div className="form-group large-form-group">
                                      <label className="light-txt">
                                        Security Deposit
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="$"
                                        name="security_deposit"
                                        disabled
                                        onChange={this.handleChange}
                                        value={
                                          this.state.formData != null
                                            ? this.state.formData
                                                .security_deposit
                                            : 0
                                        }
                                      />
                                    </div> */}
                                    <div className="clearfix"></div>
                                    <div className="row">
                                      {/* <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="light-txt">
                                            General Tax in %
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="$"
                                            step="any"
                                            min="0"
                                            name="general_tax_in_percentage"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .general_tax_in_percentage
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div> */}
                                      {/* <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="light-txt">
                                            Room Tax in %
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="$"
                                            step="any"
                                            min="0"
                                            name="room_tax_in_percentage"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .room_tax_in_percentage
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div> */}
                                    </div>
                                    <div className="row">
                                      {/* <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="light-txt">
                                            PST Tax in %
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="$"
                                            step="any"
                                            min="0"
                                            name="pst_tax_in_percentage"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .pst_tax_in_percentage
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="light-txt">
                                            HST Tax in %
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="$"
                                            step="any"
                                            min="0"
                                            name="hst_tax_in_percentage"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .hst_tax_in_percentage
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                  {pricingType == 1 ? (
                                    <div className="flow-content">
                                      <div className="form-group large-form-group">
                                        <label className="light-txt">
                                          Base price
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Enter price"
                                          name="base_price"
                                          onChange={this.handleChange}
                                          value={
                                            this.state.formData != null
                                              ? this.state.formData.base_price
                                              : 0
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                  
                                  {pricingType == 3 ? (
                                      <div className="form-group large-form-group">
                                        <label className="light-txt">
                                          Packages Starting from
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Enter price"
                                          name="package_base_price"
                                          onChange={this.handleChangePackagePricing}
                                          value={
                                            this.state.packagePricingInput
                                              ? this.state.packagePricingInput.package_base_price
                                              : 0
                                          }
                                        />
                                      </div>
                                  ) : null}
                                  {pricingType == 3 ? (
                                    <h4>Note: This price will be shown on the home page on
                                    the thumbnail of your listing. Please give the lowest
                                    price of your package here.</h4>
                                  ) : null}

                                  {pricingType == 2 ? (
                                    <>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per Adult price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Adult price"
                                            name="base_price_adult"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_adult
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per child price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Child price"
                                            name="base_price_child"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_child
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per Infant price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter infants price"
                                            name="base_price_infants"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_infants
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="flow-content">
                                        <div className="form-group large-form-group">
                                          <label className="light-txt">
                                            Per Special Needs price
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Special price"
                                            name="base_price_age_special"
                                            onChange={this.handleChange}
                                            value={
                                              this.state.formData != null
                                                ? this.state.formData
                                                    .base_price_age_special
                                                : 0
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : null}

                                  {pricingType == 1 || pricingType == 2 ? (
                                    <button
                                      className="green-btn"
                                      type="submit"
                                      disabled={this.state.buttonDisable}
                                    >
                                      {this.state.loadingContent != null
                                        ? this.state.loadingContent
                                        : "Submit"}
                                    </button>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="media user-profile-sec">
                            <div className="media-body">
                              {loading ? (
                                "Loading..."
                              ) : pricingType == 3 ? (
                                <>
                                  <div className="row">
                                    <h2 className="pull-left">
                                      Add your packages{" "}
                                    </h2>
                                  </div>
                                  <hr></hr>
                                  <div className="package-content-1">
                                    <div className="">
                                      <div className="form-group">
                                        <label className="light-txt">
                                          Package Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Name"
                                          name="name"
                                          onChange={
                                            this.handleChangePackagePricing
                                          }
                                        />
                                      </div>

                                      <div className="row">
                                        <div className="form-group col-md-6">
                                          <label className="light-txt">
                                            Min Guests
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number"
                                            name="min_guests"
                                            onChange={
                                              this.handleChangePackagePricing
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label className="light-txt">
                                            Max Guests
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number"
                                            name="max_guests"
                                            onChange={
                                              this.handleChangePackagePricing
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="light-txt">
                                          Base price
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="$0.00"
                                          name="base_price"
                                          onChange={
                                            this.handleChangePackagePricing
                                          }
                                        />
                                      </div>

                                      <div className="form-group ">
                                        <label className="light-txt">
                                          Description
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Description"
                                          name="description"
                                          onChange={
                                            this.handleChangePackagePricing
                                          }
                                        />
                                      </div>

                                      <button
                                        className="green-btn"
                                        type="submit"
                                        disabled={this.state.buttonDisable}
                                        onClick={this.packagePricingSave}
                                      >
                                        {this.state.loadingContent != null
                                          ? this.state.loadingContent
                                          : "Submit"}
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-12">
                          <br></br>
                          <div className="media user-profile-sec">
                            <div className="media-body">
                              {loading ? (
                                "Loading..."
                              ) : (this.state.host.pricing_type == 1 || this.state.host.pricing_type == 2) &&
                                this.state.hostTimeSlots.length > 0 &&
                                this.state.showPricingTimeslots ? (
                                <PricingsTimeSlots
                                  TimeSlotsData={this.state.hostTimeSlots}
                                  UpdateTimeSlot={this.UpdateTimeSlot}
                                  handleTimeSlotChange={
                                    this.handleTimeSlotChange
                                  }
                                  loadingTimeSlot={this.loading}
                                  timeSlotInput={this.state.timeSlotInput}
                                  host={this.state.host}
                                ></PricingsTimeSlots>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      {loading ? (
                        "Loading..."
                      ) : pricingType == 3 ? (
                        <div className="row">
                          {loadingPackagePricing
                            ? "Loading..."
                            : packagePricing.length > 0
                            ? packagePricing.map((pricing) => (
                                <div
                                  className="col-md-12 col-xl-4 col-lg-4 mrg-btm-package"
                                  key={pricing.host_pricing_package_id}
                                >
                                  <div className="host-package-data">
                                    <div className="host-package-info">
                                      <h4>
                                        <span className="text-muted">
                                          Name:
                                        </span>{" "}
                                        <span>
                                          <b>{pricing.name}</b>
                                        </span>
                                      </h4>
                                      <h4>
                                        <span className="text-muted">
                                          Min Guest :
                                        </span>{" "}
                                        <span>{pricing.min_guests}</span>
                                      </h4>
                                      <h4>
                                        <span className="text-muted">
                                          Max Guest:
                                        </span>{" "}
                                        <span>{pricing.max_guests}</span>
                                      </h4>
                                      <h4>
                                        <span className="text-muted">
                                          Amount:
                                        </span>{" "}
                                        <span>
                                          {pricing.base_price_formatted}
                                        </span>
                                      </h4>
                                      <h4>
                                        <span className="text-muted">
                                          Description :
                                        </span>{" "}
                                        <span>{pricing.description}</span>
                                      </h4>
                                    </div>
                                    <div className="btn-bottom">
                                      <button
                                        className="package-remove-btn mr-3"
                                        type="submit"
                                        onClick={(event) =>
                                          this.deletePackage(event, pricing)
                                        }
                                      >
                                        Delete
                                      </button>
                                      <Link
                                        className="package-remove-btn"
                                        to={`/host/package-edit/${pricing.host_pricing_package_id}`}
                                      >
                                        Edit
                                      </Link> 
                                    </div>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(HostPricing);
