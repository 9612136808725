import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "./Helper";
import api from "../../Environment";
import configuration from "react-global-configuration";


class SearchForm extends Helper {

  constructor(props) {
    super(props);
  }
  state = {
    categories: null,
    loadingCategory: true,
    searchDisplay: true,
    loadingSearch: true,
    searchResult: null,
    location_id: null,
    hosts: null,
  };
  componentDidMount() {
    this.getCategory();
  }
  searchOnChange = ({ currentTarget: input }) => {
    this.setState({ searchDisplay: false });
    if (input.value == "") {
      this.setState({ searchDisplay: true });
    }
    api
      .postMethod("filter_locations_hosts", { location: input.value })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            // searchResult: response.data.data.service_locations,
            hosts: response.data.data.hosts,
            loadingSearch: false,
          });
        }
      });
  };

  searchResult = (event) => {
    event.preventDefault();
    let location_id;
    if (this.state.searchResult.length > 0) {
      location_id = this.state.searchResult[0].service_location_id;
    } else {
      location_id = "";
    }
    this.setState({ searchDisplay: true });
    this.searchApiCall(this.props, location_id);
  };

  searchOnClickResult = (event, search) => {
    event.preventDefault();
    this.setState({ searchDisplay: true });
    this.searchApiCall(this.props, search.service_location_id);
  };

  handleChangeLocation = ({ currentTarget: input }) => {
    this.setState({ location_id: input.value });
  };
  render() {
    const {
      loadingCategory,
      searchDisplay,
      searchResult,
      hosts,
      loadingSearch,
    } = this.state;

    var location = {}
    const handleRedirect = (host) => {
      location = {
        pathname : `/event/${host.unique_id}`,
        state: {
          id: host.unique_id,
        },
      }
      return location;
    }

    return (
      <form
        className="form-inline justify-content-start"
        onSubmit={this.searchResult}
      >
        <div className="input-group dropdown">
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon">
              <i className="fas fa-search" />
            </span>
          </div>
          <input
            type="text"
            className="form-control form-control-lg dropdown-toggle"
            data-toggle="dropdown"
            placeholder="Search your preferred listing..."
            name="search"
            autocomplete="off"
            onChange={this.searchOnChange}
          />

          {/* <div className="dropdown-menu nav-dropdown-menu">
            <h5 className="dropdown-title">
              explore {configuration.get("configData.site_name")}
            </h5>
            <Link to="/home" className="grey-outline-btn size1">
              all
            </Link>

            {loadingCategory
              ? "Loading.."
              : this.renderCategory("grey-outline-btn size1")}
          </div> */}
          {loadingSearch ? "" :
          <div
            className={
              searchDisplay
                ? "dropdown-menu nav-dropdown-menu "
                : "dropdown-menu nav-dropdown-menu show"
            }
          >
            <ul className="map-search-list">
              {hosts.length > 0
                  ? hosts.map((host) => (
                      <li key={host.unique_id} >
                        <Link
                          to={handleRedirect(host)}
                          className="map-search-list-link dropdown-toggle"
                          onClick={() => this.setState({...this.state , loadingSearch : true })}
                        >
                          <i className="fas fa-building" />{" "}
                          {host.host_name}
                        </Link>
                      </li>
                    ))
                : "No Data Found"}
            </ul>
          </div>
          }
        </div>
      </form>
    );
  }
}

export default SearchForm;
