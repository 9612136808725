import React, { Component } from "react";
import ReactTimeout from "react-timeout";
import { Link } from "react-router-dom";

import Emitter from "./components/Services/EventEmitter";
import axios from "axios";
import CookieConsent, { Cookies } from "react-cookie-consent";

import { Router, Switch, Route, Redirect } from "react-router-dom";

// import createHistory from "history/createBrowserHistory";

import { createBrowserHistory as createHistory } from "history";

//Layouts

import FirstLayout from "../src/components/Layouts/FirstLayout";
import SecondLayout from "../src/components/Layouts/SecondLayout";
import ThirdLayout from "../src/components/Layouts/ThirdLayout";
import FourthLayout from "../src/components/Layouts/FourthLayout";
import FifthLayout from "../src/components/Layouts/FifthLayout";
import SixthLayout from "../src/components/Layouts/SixthLayout";
import SeventhLayout from "../src/components/Layouts/SeventhLayout";

// FirstLayout pages
import LandingPage from "../src/components/Home/LandingPage";

// Second layout pages
import AdditionalPage from "./components/Home/AdditionalPage";

// Third layout pages
import ExplorePage from "./components/Home/ExplorePage";

// Fourth Layout pages
import EditProfile from "./components/User/AccountSettings/EditProfile";

// Fifth layout pages
import Chat from "./components/User/TripDetails/Chat";

// Sixth layout pages
import Checkout from "./components/User/Payments/Checkout";

// Seventh layout pages
import CategoryHome from "./components/Category/Home";
import BecomeAHost from "./components/Others/becomeAHost";
import Photo from "./components/User/AccountSettings/photo";
import Notification from "./components/User/AccountSettings/notification";
import UserPayment from "./components/User/AccountSettings/userPayment";
import ChangePassword from "./components/User/AccountSettings/changePassword";
import UserReview from "./components/User/AccountSettings/userReview";
import DeleteAccount from "./components/User/AccountSettings/deleteAccount";
import UserProfile from "./components/User/AccountSettings/userProfile";
import WishList from "./components/User/AccountSettings/wishlist";
import SingleTrip from "./components/User/TripDetails/singleTrip";
import TripHistory from "./components/User/TripDetails/tripHistory";
import SingleHistory from "./components/User/TripDetails/singleHistory";
import SubCategory from "./components/Home/subCategory";
import Logout from "./components/Auth/logout";
import HostHome from "./components/Host/home";
import HostLayout from "./components/Host/HostLayout/hostLayout";
import HostEditProfile from "./components/Host/AccountSetting/EditProfile";
import HostNotification from "./components/Host/AccountSetting/hostNotification";
import HostPhoto from "./components/Host/AccountSetting/hostPhoto";
import HostProfile from "./components/Host/AccountSetting/hostProfile";
import HostStripeConnect from "./components/Host/AccountSetting/hostStripeConnect";
import HostChangePassword from "./components/Host/AccountSetting/changePassword";
import HostDeleteAccount from "./components/Host/AccountSetting/deleteAccount";
import HostReview from "./components/Host/AccountSetting/hostReview";
import HostLogout from "./components/Host/AccountSetting/hostLogout";
import Search from "./components/Home/search";
import { ToastProvider } from "react-toast-notifications";
import HostLogin from "./components/Host/Auth/login";
import HostAuthLayout from "./components/Host/HostLayout/hostAuthLayout";
import HostRegister from "./components/Host/Auth/register";
import HostForgotPassword from "./components/Host/Auth/forgotPassword";
import HostResetPassword from "./components/Host/Auth/ResetPassword";
import HostDashbaord from "./components/Host/RevenueDetails/dashboard";
import HostTransactionHistory from "./components/Host/RevenueDetails/transactionHistory";
import HostBookingManagement from "./components/Host/RevenueDetails/bookingManagement";
import HostSubscriptionHistory from "./components/Host/RevenueDetails/subscriptionHistory";
import HostSingleBookingDetails from "./components/Host/RevenueDetails/singleBookingDetails";
import HostSubscription from "./components/Host/RevenueDetails/subscriptions";
import HostSubscriptionInvoice from "./components/Host/Payments/hostSubInvoice";
import HostCard from "./components/Host/AccountSetting/hostCard";
import HostListingManagement from "./components/Host/RevenueDetails/listingManagement";
import HostSubPaySuccess from "./components/Host/RevenueDetails/subscriptionPaySuccess";
import { Elements, StripeProvider } from "react-stripe-elements";
import Inbox from "./components/User/TripDetails/inbox";
import HostInbox from "./components/Host/Messages/hostInbox";
import HostWithoutFooterLayout from "./components/Host/HostLayout/hostWithoutFooterLayout";
import ViewAll from "./components/Home/viewAll";
import HostSingleTripView from "./components/Host/RevenueDetails/singleTripView";
import HostBasicLayout from "./components/Host/HostLayout/hostBasicLayout";
import HostAddListing from "./components/Host/Listing/addListing";
import ProviderProfile from "./components/User/providerProfile";
import OtherUserProfile from "./components/User/otherUserProfile";
import StaticPage from "./components/Others/StaticPage";
import EditListing from "./components/Host/Listing/editListing";
import HostGallery from "./components/Host/Listing/gallery";
import HostAvailability from "./components/Host/Listing/availabilityCalendar";
import HostSpecialPricing from "./components/Host/Listing/HostSpecialPricing";
import configuration from "react-global-configuration";

import configData from "./json/settings.json";
import HostChat from "./components/Host/RevenueDetails/hostChat";
import { Helmet } from "react-helmet";

import { apiConstants } from "./components/Constant/constants";
import HostViewPromoCodeList from "./components/Host/PromoCode/ViewPromoCodeList";
import HostAddPromoCode from "./components/Host/PromoCode/AddPromoCode";
import HostEditPromoCode from "./components/Host/PromoCode/EditPromoCode";
import HostPayoutDetails from "./components/Host/RevenueDetails/HostPayoutDetails";
import HostSinglePromoCode from "./components/Host/PromoCode/SinglePromoCode";
import AddListingIndex from "./components/Host/Listing/AddListing/AddListingIndex";
import HostPricing from "./components/Host/Listing/HostPricing";
import HostTimeslot from "./components/Host/Listing/HostTimeslot";
import HostPackageEdit from "./components/Host/Listing/HostPackageEdit";
import EditListingStep1 from "./components/Host/Listing/EditListing/EditListingStep1";
import EditListingStep2 from "./components/Host/Listing/EditListing/EditListingStep2";
import EditListingStep3 from "./components/Host/Listing/EditListing/EditListingStep3";
import EditListingStep4 from "./components/Host/Listing/EditListing/EditListingStep4";
import EditListingStep5 from "./components/Host/Listing/EditListing/EditListingStep5";
import EditListingStep6 from "./components/Host/Listing/EditListing/EditListingStep6";
import EditListingStep7 from "./components/Host/Listing/EditListing/EditListingStep7";
import EditListingStep8 from "./components/Host/Listing/EditListing/EditListingStep8";
import EditListingStep9 from "./components/Host/Listing/EditListing/EditListingStep9";

import FrontDesk from "./components/Host/RevenueDetails/frontDesk";
import hostDetails from "./components/Host/RevenueDetails/hostDetails";
import MetaTags from "react-meta-tags";
import ScrollToTop from "./components/Helper/ScrollToTop";
import RapidBookingConfirmation from "./components/User/confirmation/RapidBookingConfirmation";
import BookingConfirmation from "./components/User/confirmation/BookingConfirmation";
import CustomPriceConfirmation from "./components/User/confirmation/CustomPriceConfirmation";
import HostRulesAndPolicies from "./components/User/TripDetails/hostRulesandPolicies";

import HostViewFrontDeskList from "./components/Host/FrontDesk/ViewFrontDeskList";
import HostAddFrontDesk from "./components/Host/FrontDesk/AddFrontDesk";
import HostEditFrontDesk from "./components/Host/FrontDesk/EditFrontDesk";
import HostSingleFrontDesk from "./components/Host/FrontDesk/SingleFrontDesk";
import FrontDeskBookingConfirm from "./components/Host/FrontDesk/FrontDeskBookingConfirm";
import SeeAllPageIndex from "./components/SeeAllPage/SeeAllPageIndex";
import ResetPassword from "./components/Auth/ResetPassword";
import EmailVerification from "./components/Auth/EmailVerification";

import PropertiesSingleView from "./components/Home/PropertiesSingleView";
import NewSinglePropertiesIndex from "./components/Home/NewSinglePropertiesIndex";
import BannerPromo from "../src/components/Home/BannerPromo";
import BannerPromoLayout from "./components/Layouts/BannerPromoLayout";

const history = createHistory();

const $ = window.$;
const AppRoute = ({
 component: Component,
 layout: Layout,
 screenProps: ScreenProps,
 ...rest
}) => (
 <Route
  {...rest}
  render={(props) => (
   <Layout screenProps={ScreenProps}>
    <Component {...props} />
   </Layout>
  )}
  isAuthed
 />
);
const HostPrivateRoute = ({
 component: Component,
 layout: Layout,
 screenProps: ScreenProps,
 host_authentication,
 ...rest
}) => (
 <Route
  {...rest}
  render={(props) =>
   host_authentication === true ? (
    <Layout screenProps={ScreenProps}>
     <Component {...props} authRoute={true} />
    </Layout>
   ) : (
    <Redirect
     to={{
      pathname: "/host/login",
      state: { from: props.location },
     }}
    />
   )
  }
 />
);

const UserPrivateRoute = ({
 component: Component,
 layout: Layout,
 screenProps: ScreenProps,
 user_authentication,
 ...rest
}) => (
 <Route
  {...rest}
  render={(props) =>
   user_authentication === true ? (
    <Layout screenProps={ScreenProps}>
     <Component {...props} authRoute={true} />
    </Layout>
   ) : (
    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
   )
  }
 />
);
class App extends Component {
 constructor(props) {
  super(props);

  // user_Authentication

  let userId = localStorage.getItem("userId");

  let providerId = localStorage.getItem("hostId");

  let accessToken = localStorage.getItem("accessToken");

  this.state = {
   loading: true,
   configLoading: true,
   user_authentication: userId && accessToken ? true : false,
   host_authentication: providerId && accessToken ? true : false,
  };

  this.eventEmitter = new Emitter();

  history.listen((location, action) => {
   userId = localStorage.getItem("userId");
   providerId = localStorage.getItem("hostId");
   accessToken = localStorage.getItem("accessToken");

   // this.setState({ loading : true, user_authentication : userId && accessToken ? true : false});

   this.setState({ loading: true, user_authentication: true });
   this.setState({ loading: true, host_authentication: true });

   // this.loadingFn();

   document.body.scrollTop = 0;
  });

  this.fetchConfig();
 }

 async fetchConfig() {
  const response = await fetch(apiConstants.settingsUrl);
  console.log("respi", response);
  const configValue = await response.json();
  configuration.set({
   configData: configValue.data,
  });
  this.setState({ configLoading: false });
 }

 loadingFn() {
  this.props.setTimeout(() => {
   this.setState({ loading: false });
  }, 3 * 1000);
 }

 componentDidMount() {}

 componentWillMount() {
  document.title = configData.data.site_name;
 }

 render() {
  const isLoading = this.state.configLoading;

  if (isLoading) {
   return (
    <div className="wrapper">
     <div className="loader-warpper">
      <div id="loader">
       <ul>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
       </ul>
      </div>
     </div>
    </div>
   );
  }

  return (
   <div>
    <CookieConsent
     // disableStyles={true}
     location="bottom"
     // buttonClasses="btn btn-primary"
     containerClasses="col-lg-9"
     // contentClasses="text-capitalize"
     buttonText="Okay"
     cookieName="cookiesAccept"
     style={{ background: "#2B373B" }}
     expires={1500}
    >
     We use cookies to help personalize content, tailor and measure ads, and
     provide a safer experience. By navigating the site, you agree to the use of
     cookies to collect information. Read our{" "}
     <a href="/page/privacy" target="_blank">
      {" "}
      Cookie Policy{" "}
     </a>{" "}
     to learn more.
    </CookieConsent>
    <Helmet>
      <title>Party Budha: Find party spaces and event related services in your community</title>
      {/* <link
        rel="icon"
        type="image/png"
        href={configuration.get("configData.site_icon")}
        sizes="16x16"
      />
      <link rel="shortcut icon" href={configuration.get("configData.site_icon")} />
      <meta property="og:title" content="Party Budha: Find party spaces and event related services in your community"></meta> */}
      <meta name="description" content="The best way to book party hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!"></meta>
      <meta
        name="keywords"
        content="Partybudha,party hall,indoor play center,wedding location"
      ></meta>
      <meta
        name="author"
        content={configuration.get("configData.meta_author")}
      ></meta>

      <meta
        property="og:title"
        content="Party Budha: Find party spaces and event related services in your community"
      ></meta>
      <meta
        property="og:description"
        content="The best way to book party hall, indoor play center, wedding location, local bakers & caterers, inflatable rentals ...We got them all!"
      ></meta>
      <meta
        property="og:image"
        content={configuration.get("configData.site_logo")}
      ></meta>

      <meta
        itemprop="name"
        content={configuration.get("configData.site_name")}
      ></meta>
      <meta
        itemprop="image"
        content={configuration.get("configData.site_logo")}
      ></meta>
    </Helmet>
    <ToastProvider>
     <Router history={history}>
      <>
       <ScrollToTop history={history} />
       <Switch>
        {/*** Layout 1 - Transparent Fixed Header and Floating Footer ****/}

        <AppRoute
         exact
         path={"/"}
         component={LandingPage}
         layout={FirstLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         exact
         path={"/new-single-properties"}
         component={NewSinglePropertiesIndex}
         layout={FirstLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/page/:id"}
         component={StaticPage}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
        />

        {/*** Layout 2 - Transparent Fixed Header and Footer ****/}

        {/* <AppRoute
         path={"/additioal"}
         component={AdditionalPage}
         layout={SecondLayout}
         screenProps={this.eventEmitter}
        /> */}

        {/*** Layout 3 - Fixed Header and Floating Footer ****/}

        {/* <AppRoute
         path={"/home"}
         component={ExplorePage}
         layout={ThirdLayout}
         screenProps={this.eventEmitter}
        /> */}

        {/*** Layout 4 - Common Header and Footer ****/}

        <AppRoute
         exact
         path={"/home"}
         component={LandingPage}
         layout={FirstLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/profile/:id"}
         component={OtherUserProfile}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        {/*** Layout 5 - content and Header ****/}

        <AppRoute
         path={"/provider-profile/:id"}
         component={ProviderProfile}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
        />

        {/*** Layout 6 - content and Footer ****/}

        <AppRoute
         path={"/checkout"}
         component={Checkout}
         layout={SixthLayout}
         screenProps={this.eventEmitter}
        />

        {/*** Layout 7 - Header with sub section and footer ****/}

        <AppRoute
         path={"/reset-password/:token"}
         component={ResetPassword}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/email-verification/:id/:verification_code/:user_type"}
         component={EmailVerification}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/register"}
         component={BannerPromo}
         layout={BannerPromoLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/category/:name/:id/:url_type"}
         component={CategoryHome}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/view_all/:name/:id/:url_type"}
         component={ViewAll}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
         displayMap={true}
        />

        <AppRoute
         path={"/see_all/:name?/:id?/:url_type?"}
         component={SeeAllPageIndex}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
         displayMap={true}
        />

        <AppRoute
         path={"/search"}
         component={Search}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/edit-profile"}
         component={EditProfile}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/photo"}
         component={Photo}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/single-trip/chat"}
         component={Chat}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/notification"}
         component={Notification}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/change-password"}
         component={ChangePassword}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/user-review"}
         component={UserReview}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/delete-account"}
         component={DeleteAccount}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/user-profile"}
         component={UserProfile}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/wishlist"}
         component={WishList}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/event/:id"}
         component={SingleTrip}
         layout={ThirdLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/history"}
         component={TripHistory}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/history-details/:id"}
         component={SingleHistory}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/inbox"}
         component={Inbox}
         layout={FifthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/rapid-booking-confirmation"}
         component={RapidBookingConfirmation}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/booking-confirmation"}
         component={BookingConfirmation}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />
        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/custom-price-confirmation"}
         component={CustomPriceConfirmation}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/host-rules-policies/:id/:type"}
         component={HostRulesAndPolicies}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/subcategory"}
         component={SubCategory}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
        />

        <Route path={"/logout"} component={Logout} />

        {/* Host Routing */}

        <AppRoute
         path={"/host/login"}
         component={HostLogin}
         layout={HostAuthLayout}
         screenProps={this.eventEmitter}
        />
        <AppRoute
         path={"/host/register"}
         component={HostRegister}
         layout={HostAuthLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/host/forgot-password"}
         component={HostForgotPassword}
         layout={HostAuthLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
          path={"/host/reset-password/:token"}
          component={HostResetPassword}
          layout={HostAuthLayout}
          screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/dashboard"}
         component={HostDashbaord}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />
        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/single/chat"}
         component={HostChat}
         layout={HostWithoutFooterLayout}
         screenProps={this.eventEmitter}
        />
        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/transaction-history"}
         component={HostTransactionHistory}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />
        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/booking-management"}
         component={HostBookingManagement}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/booking-details/:id"}
         component={HostSingleBookingDetails}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/subscriptions"}
         component={HostSubscription}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/invoice/:subscription_id"}
         component={HostSubscriptionInvoice}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/subscription-history"}
         component={HostSubscriptionHistory}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />
        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/subscription/success"}
         component={HostSubPaySuccess}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/add-listing"}
         component={AddListingIndex}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />
        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/edit-listing/:id"}
         component={EditListing}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/gallery/:id"}
         component={HostGallery}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />
        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/availability/:id"}
         component={HostAvailability}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/booking-confirm"}
         component={FrontDeskBookingConfirm}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step1/:host_id"}
         component={EditListingStep1}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />
        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step2/:host_id"}
         component={EditListingStep2}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />
        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step3/:host_id"}
         component={EditListingStep3}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step4/:host_id"}
         component={EditListingStep4}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step5/:host_id"}
         component={EditListingStep5}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step6/:host_id"}
         component={EditListingStep6}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step7/:host_id"}
         component={EditListingStep7}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step8/:host_id"}
         component={EditListingStep8}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing-step9/:host_id"}
         component={EditListingStep9}
         layout={HostBasicLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/listing"}
         component={HostListingManagement}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/pricings/:id"}
         component={HostPricing}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/package-edit/:host_pricing_package_id"}
         component={HostPackageEdit}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/special-pricing/:id"}
         component={HostSpecialPricing}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/timeslots/:id"}
         component={HostTimeslot}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/single/:id"}
         component={HostSingleTripView}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/edit-profile"}
         component={HostEditProfile}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/notification"}
         component={HostNotification}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/photo"}
         component={HostPhoto}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />
        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/stripe_connect"}
         component={HostStripeConnect}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/profile"}
         component={HostProfile}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/change-password"}
         component={HostChangePassword}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/delete-account"}
         component={HostDeleteAccount}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/review"}
         component={HostReview}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/inbox"}
         component={HostInbox}
         layout={HostWithoutFooterLayout}
         screenProps={this.eventEmitter}
        />

        <Route path={"/host/logout"} component={HostLogout} />

        <AppRoute
         path={"/host/dashboard"}
         component={HostHome}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/payment"}
         exact={true}
         component={HostCard}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/promo-code-list"}
         exact={true}
         component={HostViewPromoCodeList}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/create-frontdesk-user"}
         exact={true}
         component={HostAddFrontDesk}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/edit-front-desk/:id"}
         exact={true}
         component={HostEditFrontDesk}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/single-front-desk/:id"}
         exact={true}
         component={HostSingleFrontDesk}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/frontdesk-users"}
         exact={true}
         component={HostViewFrontDeskList}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/add-promo-code"}
         exact={true}
         component={HostAddPromoCode}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/edit-promo-code/:id"}
         exact={true}
         component={HostEditPromoCode}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/single-promo-code/:id"}
         exact={true}
         component={HostSinglePromoCode}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/payout-details"}
         exact={true}
         component={HostPayoutDetails}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <UserPrivateRoute
         user_authentication={this.state.user_authentication}
         path={"/user-payment"}
         component={UserPayment}
         layout={FourthLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host/front-desk"}
         component={FrontDesk}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <HostPrivateRoute
         host_authentication={this.state.host_authentication}
         path={"/host-details/:id"}
         component={hostDetails}
         layout={HostLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         path={"/host"}
         component={BecomeAHost}
         layout={HostAuthLayout}
         screenProps={this.eventEmitter}
        />

        <AppRoute
         exact
         path={"/properties-single-view"}
         component={PropertiesSingleView}
         layout={SeventhLayout}
         screenProps={this.eventEmitter}
         displayMap={true}
        />

        {/**
         *  For private route
         * <UserPrivateRoute user_authentication={this.state.user_authentication} path={"/subscriptions"} component={Subscription} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>
         */}
       </Switch>
      </>
     </Router>
    </ToastProvider>
   </div>
  );
 }
}

export default ReactTimeout(App);
