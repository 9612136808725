import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helper from "../Helper/Helper";
import Loader from "../Helper/Loader";
import HomeRoomContent from "../Home/homeRoomContent";

class PropertiesSingleView extends Helper {

    state = {
        first_block: null,
        mainData: null,
        loading: true,
        second_block: null
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Call api function
        const inputData = {
          api_page_type: this.props.match.params.url_type,
          api_page_type_id: this.props.match.params.id
        };
    
        this.homePageFirstSection(inputData);
    }

    render() {
        let load = new Loader();
        console.log(mainData);
        const { loading, mainData, second_block } = this.state;
        return (
            <>
                <div className="properties-single-view-sec">
                    <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                                <div className="properties-banner-content">
                                    <h2>Find properties near you</h2>
                                    <p>Discover thousands of unique locations perfect for your production.</p>
                                    <div className="location-properties-sec">
                                        <div className="location-user-img-sec">
                                            <Image
                                                className="location-user-img"
                                                src={
                                                    window.location.origin + "/assets/img/properties-banner.png"
                                                }
                                            />
                                        </div>
                                        <div className="loaction-properties-name">
                                            <i className="fas fa-map-marker"></i> <span>Calgary</span>
                                        </div>
                                    </div>
                                </div>
                        </Col>
                        <Col md={6}>
                                <Image
                                    className="properties-banner-img"
                                    src={
                                        window.location.origin + "/assets/img/properties-banner.png"
                                    }
                                />
                        </Col>
                    </Row>
                    <div className="properties-single-view-info">
                        <Row className="align-items-center">
                            <Col md={6}>
                                    <Image
                                        className="properties-banner-img"
                                        src={
                                            window.location.origin + "/assets/img/properties-img.png"
                                        }
                                    />
                            </Col>
                            <Col md={6}>
                                <div className="properties-single-view-list-sec">
                                    <h4>How film shoot locations work on Party Budha</h4>
                                    <p>Peerspace is the easiest way to book unique spaces for meetings, events, film, and photo shoots.</p>
                                    <h5>The most locations</h5>
                                    <p>Peerspace is the easiest way to book unique spaces for meetings, events, film, and photo shoots.</p>
                                    <h5>The most locations</h5>
                                    <p>Peerspace is the easiest way to book unique spaces for meetings, events, film, and photo shoots.</p>
                                    <h5>The most locations</h5>
                                
                                    <p>Peerspace is the easiest way to book unique spaces for meetings, events, film, and photo shoots.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                        <div className="popular-location-single-view-sec">
                            <div className="row">
                                <div className="col-md-12">
                                        
                                </div>
                            </div>
                        </div>

                        <div className="top-bottom-spacing">
                            <div className="display-inline">
                                {loading
                                    ? load.propertyLoader()
                                : 
                                    <HomeRoomContent
                                        contentDetails={mainData[0].data}
                                        title={mainData[0].title}
                                        history={this.props.history}
                                    />  
                                }

                                <div className="subcategory-rightsec">
                                    <div className="map-sec">
                                    {/* <img src="../assets/img/map-img.jpg" className="map-img" /> */}
                                    {/* <MapContainer /> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Container>
                </div>
            </>
        );
    }
}
export default PropertiesSingleView;
