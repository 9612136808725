import React, { Component } from "react";
import api from "../../../Environment";
import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";
import UserReviewModel from "./userReviewModel";
import FancyBox from "../TripDetails/FancyBox";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";

const $ = window.$;

class TripReviewSection extends Helper {
  state = {
    reviews: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    contentData: null,
  };
  componentDidMount() {
    this.reviewsApiCall();
  }
  reviewsApiCall() {
    let items;
    api
      .postMethod("reviews", {
        host_id: this.props.details.host_id,
        skip: this.state.skipCount,
      })
      .then((response) => {
        if (response.data.success) {
          if (this.state.reviews != null) {
            items = [...this.state.reviews, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            reviews: items,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadingStatus: true,
          });
        }
      });
  }
  loadMore = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.reviewsApiCall();
  };

  openSignupModal = (event) => {
    event.preventDefault();
    ToastDemo(this.props.toastManager, "Please login to continue", "error");
    $("#login").modal("show");
  }
  render() {
    const { reviews, loading, loadingContent, loadingStatus } = this.state;

    return (
      <div>
        {loading ? (
          "Loading..."
        ) : (
          <div id="reviews">
            <div className="row">
              <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <h1 className="section-head">
                  {this.props.details.total_ratings} reviews
                </h1>
                <div>
                  <span className="rating-star new-rating-star">
                    {this.starRatingHost(
                      this.props.details.overall_ratings,
                      13
                    )}
                  </span>
                </div>
              </div>
              {this.props.details.review_btn_status == 1 && localStorage.getItem('userLoginStatus') ? (
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="review-btn-sec text-right">
                    <button
                      className="green-outline-btn btn-small mr-0 mt-2"
                      data-toggle="modal"
                      data-target="#userReviewModel"
                    >
                      Write a Review
                    </button>
                  </div>
                </div>
              ) : !localStorage.getItem('userLoginStatus') ? (
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="review-btn-sec text-right">
                    <button
                      className="green-outline-btn btn-small mr-0 mt-2"
                      onClick={this.openSignupModal}
                    >
                      Write a Review
                    </button>
                  </div>
                </div>
              ) : ''}


            </div>
            {reviews.length > 0 ?
              reviews.map((review, index) => (
                <div key={index}>
                  <div className="media top1 rating-flex-sec">
                    <img
                      src={review.user_picture}
                      className="align-self-center mr-3 rounded-circle review-img1"
                    />
                    <div className="media-body">
                      <h4 className="reviewer-name">
                        {review.user_name}
                        {/* {review.booking_id != 0 ?
                          <span>
                            <img
                              src="../assets/img/verified-icon.svg"
                              className="ml-2"
                              style={{ width: "20px", height: "auto" }}
                            />
                          </span>
                          : ''} */}
                      </h4>
                      {/* <p className="review-date">{review.updated}</p> */}
                      <div className="rating-verifed-card">
                    <div>
                      <span className="rating-star">
                        {this.starRatingHost(
                          review.ratings,
                          20
                        )}
                      </span>
                    </div>
                    {review.booking_id != 0 ?
                      <div className="rating-verifed-text">
                        Verified Purchase
                      </div>
                      : ''}
                  </div>
                    </div>
                  </div>
                  <h4 className="comment-text">{review.review}</h4>
                  {review.booking_user_review_gallery.length > 0 ?
                    <FancyBox>
                      <div className="review-mutiple-box">
                        {review.booking_user_review_gallery.map((file, index) => (
                          <>
                            {file.file_type.match("image") == "image" ?
                              <img
                                src={file.file}
                                className="review-multiple-img"
                                data-fancybox
                              />
                              :
                              <a
                                data-fancybox
                                data-type="video"
                                href={file.file}
                              >
                                <video
                                  src={file.file}
                                  // controls
                                  // autoPlay={false}
                                  // preload="auto"
                                  className="review-multiple-img"
                                >
                                </video>
                              </a>
                            }

                          </>
                        ))}
                      </div>

                    </FancyBox>
                    : ''}
                  {loadingStatus ? "" : loadingContent}

                  <p className="overview-line" />
                </div>
              ))

              : ''}
            {reviews.length > 12 ? (
              <Link to="#" className="show-all" onClick={this.loadMore}>
                show more
              </Link>
            ) : (
              ""
            )}

            <div className="modal fade" id="report">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <h1 className="section-head">
                      Do you want to anonymously report this review?
                    </h1>

                    <h4 className="captalize  amenities-modal-head">
                      If so, please choose one of the following reasons
                    </h4>
                    <form>
                      <div className="form-check radio-green top">
                        <input
                          className="form-check-input"
                          name="group101"
                          type="radio"
                          id="radio103"
                        />
                        <label className="form-check-label" htmlFor="radio103">
                          Inappropriate content
                        </label>
                        <p className="m-l-2 bottom">
                          This review contains violent, graphic, promotional, or
                          otherwise offensive content.
                        </p>
                      </div>
                      <div className="form-check radio-green top">
                        <input
                          className="form-check-input"
                          name="group101"
                          type="radio"
                          id="radio104"
                          checked
                        />
                        <label className="form-check-label" htmlFor="radio104">
                          Dishonest or hateful content
                        </label>
                        <p className="m-l-2 bottom">
                          This review is purposefully malicious and assaulting.
                        </p>
                      </div>
                      <div className="form-check radio-green top">
                        <input
                          className="form-check-input"
                          name="group101"
                          type="radio"
                          id="radio105"
                        />
                        <label className="form-check-label" htmlFor="radio105">
                          Fake content
                        </label>
                        <p className="m-l-2 bottom">
                          This review contains false information or may be fake.
                        </p>
                      </div>
                      <button className="green-btn top1">submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <UserReviewModel details={this.props.details} host_id={this.props.details.host_id} />
      </div>
    );
  }
}

export default withToastManager(TripReviewSection);
