import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
const $ = window.$;

class BookingRejectReasonModal extends Component {
    
    state = {
        cancelled_reason: null,
        buttonDisable: false,
        loadingContent: null
      };
    
      handleChange = ({ currentTarget: input }) => {
        this.setState({ cancelled_reason: input.value });
      };

      
    
      handleSubmit = event => {
        event.preventDefault();
        this.setState({
          buttonDisable: true,
          loadingContent: "Loading.. Please wait.."
        });
        api
          .postMethod("bookings_reject", {
            booking_id: this.props.booking_id,
            cancelled_reason: this.state.cancelled_reason,
          })
          .then(response => {
            if (response.data.success) {
              ToastDemo(this.props.toastManager, response.data.message, "success");
              setTimeout(function(){
               window.location.reload(); 
              },1000);
            } else {
              ToastDemo(this.props.toastManager, response.data.error, "error");
            }
            $("#bookingCancelModal").modal("hide");
            this.setState({ buttonDisable: false, loadingContent: null });
          });
      };




    render() {
  
      const { details } = this.props;
      
      return (
        <div>
        <div className="modal fade " id="bookingCancelModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  id="close-forgot"
                  data-dismiss="modal"
                >
                  {/* <i className="material-icons">close</i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#787878" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </div>

              <div className="modal-body">
                <h1 className="section-head">Reject the booking</h1>
                <p className="small-line" />
                <h4>Reason</h4>
                <form className="top1" onSubmit={this.handleSubmit}>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      
                        <textarea rows="5" cols="25" name="cancelled_reason" onChange={this.handleChange}value={this.state.cancelled_reason} className="form-control" required maxLength="250"></textarea>

                    </div> <br></br>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <button
                        className="pink-btn bottom1"
                        disabled={this.state.buttonDisable}
                      >
                        {this.state.loadingContent != null
                          ? this.state.loadingContent
                          : "Submit Reason"}
                      </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    }
  }
  
  export default withToastManager(BookingRejectReasonModal);
  