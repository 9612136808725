/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import TripBanner from "./tripBanner";
import TripGallery from "./tripGallery";
import TripBookingForm from "./tripBookingForm";
import MobileTripBookingForm from "./mobile-tripBookingForm";
import TripReviewSection from "./tripReviewSection";
import TripHostSection from "./tripHostSection";
import TripSimilarListing from "./tripSimilarListing";
import TripShowAllAmen from "./tripShowAllAmen";
import api from "../../../Environment";
import TripLocation from "./TripLocation";
import renderHTML from "react-render-html";
import Slider from "react-slick";
import Loader from "../../Helper/Loader";
import { RangeDatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import GetAvailability from "../../Helper/getAvailability";
import TripAddonServices from "./tripAddonServices";
import TripAddOnSerAddModel from "./TripAddOnSerAddModel";
import TripPackagePricing from "./TripPackagePricing";
import moment from "moment";
import ChatModal from "./ChatModal";
import { MetaTags, ReactTitle } from "react-meta-tags";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import TripFaqs from "./TripFaqs";
import CustomPriceModal from "./CustomPriceModal";

const queryString = require("query-string");

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const $ = window.$;

class SingleTrip extends Component {
  state = {
    singleTripDetails: null,
    loading: true,
    formData: {
      host_id: this.props.match.params.id,
    },
    priceCalInput: {
      adults: 0,
      children: 0,
      infants: 0,
      checkin: "",
      checkout: "",
      host_id: null,
      age_special: 0,
      selectedAmentites: 0,
      addedAmentity: {
        name: "",
        value: "",
      },
      amentityValue: "",
      custom_select_value: null,
    },
    loadingBookingForm: true,
    total_days_price: null,
    basicDetails: null,
    apiResponse: {is_custom_pricing_request:1},
    apiCallStatus: false,
    providerDetails: null,
    buttonDisableBoBookingForm: false,
    buttonLoadingContentBookingForm: null,
    promoCodeInputData: {},
    promoCodeApiResponse: null,
    loadingPromoCode: true,
    addonServicesData: null,
    loadingAddonService: true,
    addOnServiceModelData: null,
    loadingAddOnServiceModelData: true,
    addOnSerModelAddButtonDisable: false,
    addOnSerModelAddLoadingContent: null,
    timeSlotLoading: true,
    timeSlotData: [],
    min_guests: 1,
    max_guests: 1,
    activeBreakdown: null,
    chatModel: false,
    pricingPackagesLoading: true,
    pricingPackagesData: [],
    isModalOpen: false,
    promoCodeData: [],
    faqsData:null,
    loadingFaqs:true,
    requestCustomPricingModel: false,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.open_gallery) {
      if (this.props.location.state.open_gallery == 1) {
        this.setState({
          isModalOpen: true,
        });
      }
    }
    if (this.props.location.state && this.props.location.state.custom_pricing) {
      if (this.props.location.state.custom_pricing == 1) {
        const priceCalInput = { ...this.state.priceCalInput };
        priceCalInput["host_pricing_package_id"] = "custom_price";
        priceCalInput["checkin"] = dayjs(this.props.location.state.checkin).format("YYYY-MM-DD");
        priceCalInput["checkin_formatted"] = this.props.location.state.checkin;
        priceCalInput["custom_pricing_request_id"] = this.props.location.state.custom_pricing_request_id;

        this.setState({ priceCalInput });
      }
      setTimeout(() => {
        this.priceCal();
        this.getTimeSlotWithAvailable({
          checkin: dayjs(this.props.location.state.checkin).format("YYYY-MM-DD"),
          host_id: this.state.singleTripDetails.basic_details.host_id,
          timezone: const_time_zone,
        });
      }, 1000);
    }

    // if(Object.keys(parsedValues).length > 0){
    //   this.setState({
    //     ...this.state,
    //     priceCalInput : {
    //       ...this.state.priceCalInput,
    //       ...parsedValues
    //     }
    //   }, () => (this.priceCal(), this.getTimeSlotWithAvailable({
    //     checkin : parsedValues.checkin,
    //     host_id : Number(parsedValues.host_id),
    //     timezone: const_time_zone,
    //   })))
    // }

    const formData = {
      host_id: this.props.match.params.id,
    };
    this.singleTripViewApiCall(formData);
    this.getPricingPackages(formData);
    this.getAddonServices();
    this.getFaqs();
    // window.addEventListener('popstate', this.onBackButtonEvent,true);
    // window.removeEventListener('popstate', this.onBackButtonEvent,true);
    // this.checkLocalStorage();
  }

  // componentWillUnmount(){
  //   window.addEventListener('popstate', (event) => {
  //     if (event.state) {
  //       this.onBackButtonEvent();
  //     }
  //    }, false);
  // }

  // onBackButtonEvent = () => {
  //     $("#image-gal").modal("hide");
  // }

  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.location.state.id) {
  //     const formData = {
  //       host_id: nextProps.location.state.id,
  //     };
  //     this.setState({ loading: true });
  //     this.singleTripViewApiCall(formData);
  //     this.getPricingPackages(formData);
  //   }

  // }

  displaySimilarTrip = (host_id) => {
    const formData = {
      host_id: host_id,
    };
    this.setState({
      loading: true,
      singleTripDetails: null,
      priceCalInput: {
        adults: 0,
        children: 0,
        infants: 0,
        checkin: null,
        checkout: "",
        host_id: null,
      },
    });

    this.singleTripViewApiCall(formData);
  };

  checkLocalStorage = () => {
    const booking_info = JSON.parse(localStorage.getItem("booking_info"));
    const pricecal_input = JSON.parse(localStorage.getItem("pricecal_input"));

    this.setState({
      apiResponse: booking_info,
      priceCalInput: pricecal_input,
      loadingBookingForm: booking_info ? false : true,
      apiCallStatus: booking_info ? false : true,
    });
  };
  setHostId = () => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[
      "adults"
    ] = this.state.singleTripDetails.basic_details.min_guests;
    priceCalInput[
      "host_id"
    ] = this.state.singleTripDetails.basic_details.host_id;
    this.setState({
      priceCalInput,
      min_guests: this.state.singleTripDetails.basic_details.min_guests,
      max_guests: this.state.singleTripDetails.basic_details.max_guests,
    });
  };

  getPromoCodeSelect = (value) => {
    console.log(value);
    const promoCodeInputData = { ...this.state.promoCodeInputData };
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["promo_code"] = value;
    promoCodeInputData["promo_code"] = value;
    this.setState({ promoCodeInputData });
    this.setState({ priceCalInput });
  };

  getPromoCode = ({ currentTarget: input }) => {
    const promoCodeInputData = { ...this.state.promoCodeInputData };
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["promo_code"] = input.value;
    promoCodeInputData["promo_code"] = input.value;
    this.setState({ promoCodeInputData });
    this.setState({ priceCalInput });
  };

  removePromoCode = (event) => {
    event.preventDefault();
    this.setState({
      promoCodeInputData: {},
      promoCodeApiResponse: null,
      loadingPromoCode: true,
      buttonLoadingContentBookingForm: null,
      buttonDisableBoBookingForm: false,
      loadingBookingForm: false,
    });
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["promo_code"] = "";
    this.setState({ priceCalInput });
  };

  checkPromoCode = (event) => {
    // console.log(this.state.apiResponse);
    event.preventDefault();
    const promoCodeInputData = { ...this.state.promoCodeInputData };
    promoCodeInputData[
      "host_id"
    ] = this.state.singleTripDetails.basic_details.host_id;
    promoCodeInputData[
      "amount"
    ] = this.state.apiResponse.actual_total_formatted.replace("$", "");
    this.setState({ promoCodeInputData });
    setTimeout(() => {
      this.promoCodeAPICall();
    }, 1000);
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
    });
  };

  promoCodeAPICall = () => {
    api
      .postMethod("apply_promo_code", this.state.promoCodeInputData)
      .then((response) => {
        // console.log("response", response);
        if (response.data.success) {
          this.setState({
            loadingBookingForm: false,
            promoCodeApiResponse: response.data.data,
            loadingPromoCode: false,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
          });
          this.priceCalcultor();
          ToastDemo(
            this.props.toastManager,
            "Promo Code valid, Applied successfully",
            "success"
          );
        } else {
          const priceCalInput = { ...this.state.priceCalInput };
          priceCalInput["promo_code"] = "";
          this.setState({ priceCalInput });
          this.setState({
            promoCodeApiResponse: null,
            loadingPromoCode: true,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            loadingBookingForm: false,
          });
          ToastDemo(this.props.toastManager, response.data.error, "error");
          return false;
        }
      });
  };

  priceCalcultor = () => {
    const priceCalInput = this.state.priceCalInput;

    console.log(priceCalInput);

    // const appendQueryString = priceCalInput;

    // delete appendQueryString.addedAmentity;

    // const query = queryString.stringify(
    //   {...appendQueryString },
    //   { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
    // );

    // this.props.history.push({
    //   pathname: this.props.location.pathname,
    //   search: query,
    // });

    if (
      priceCalInput.adults !== 0 &&
      priceCalInput.checkin != null &&
      priceCalInput.checkout != "NaN-aN-aN"
    ) {
      this.priceCal();
      this.setState({
        buttonDisableBoBookingForm: true,
        buttonLoadingContentBookingForm: "Loading... Please wait",
      });
    } else {
      this.priceCal();
    }
  };

  priceCal = () => {
    console.log(this.state.priceCalInput.host_pricing_package_id);
    api
      .postMethod("host_price_calculator", this.state.priceCalInput)
      .then((response) => {
        // console.log("response", response);
        if (response.data.success) {
          const priceCalInput = { ...this.state.priceCalInput };

          priceCalInput["adults"] =
            priceCalInput["adults"] > response.data.data.min_guests
              ? priceCalInput["adults"]
              : response.data.data.min_guests;

          if (response.data.data.user_promo_codes.length > 0) {
            let array = [];
            response.data.data.user_promo_codes.map((promo_code, i) => {
              if (promo_code.is_available == 1) {
                console.log(promo_code);
                let lable =
                  promo_code.title +
                  " - Get " +
                  (parseInt(promo_code.amount_type) === 1
                    ? response.data.data.currency
                    : "") +
                  promo_code.amount +
                  (parseInt(promo_code.amount_type) === 0 ? "%" : "") +
                  " OFF using the Promo code " +
                  promo_code.promo_code;
                let object = {
                  value: promo_code.promo_code,
                  label: lable,
                };
                array.push(object);
              }
            });
            this.setState({
              promoCodeData: array,
            });
          }

          this.setState({
            priceCalInput,
            loadingBookingForm: false,
            total_days_price: response.data.data.total_days_price,
            apiResponse: response.data.data,
            apiCallStatus: true,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            min_guests: response.data.data.min_guests,
            max_guests: response.data.data.max_guests,
            user_promo_codes: response.data.data.user_promo_codes,
          });
        } else {
          this.setState({
            loadingBookingForm: true,
            total_days_price: null,
            apiResponse: null,
            apiCallStatus: false,
            buttonLoadingContentBookingForm: null,
            buttonDisableBoBookingForm: false,
            toastContent: response.data.error,
            toastType: "error",
          });
          ToastDemo(this.props.toastManager, response.data.error, "error");
          return false;
        }
      });
  };

  bookNow = (event) => {
    console.log();
    event.preventDefault();
    if (!localStorage.getItem("userLoginStatus")) {
      ToastDemo(this.props.toastManager, "Please login to continue", "error");
      $("#login").modal("show");
    } else {
      if (this.state.singleTripDetails.basic_details.booking_type == 2) {
        if (!this.state.priceCalInput.host_time_slot_id) {
          ToastDemo(
            this.props.toastManager,
            "Please select all the details, Try again",
            "error"
          );
          return false;
        }
      }

      if (this.state.singleTripDetails.basic_details.pricing_type == 3) {
        if (!this.state.priceCalInput.host_pricing_package_id) {
          ToastDemo(
            this.props.toastManager,
            "Please select all the details, Try again",
            "error"
          );
          return false;
        }
      }

      // if(this.state.priceCalInput.host_pricing_package_id == 'custom_price' && this.state.apiResponse.is_custom_pricing_request == 0) {
      //   // this.setState({
      //   //   requestCustomPricingModel: true,
      //   // });
      //   this.handleContactHost(event);
      //   return false;
      // }

      if (this.state.apiCallStatus) {
        // this.contactHostAPI();
        // console.log("After API reponse");
        // localStorage.setItem('booking_info', JSON.stringify(this.state.apiResponse));
        // localStorage.setItem('pricecal_input', JSON.stringify(this.state.priceCalInput));
        this.props.history.push("/checkout", this.state);
      } else {
        ToastDemo(
          this.props.toastManager,
          "Please select all the details, Try again",
          "error"
        );
      }
    }
    // console.log("Book now Clicked");
  };

  increament = (value) => {
    // console.log("Increamenting", value);
    const priceCalInput = { ...this.state.priceCalInput };

    //$("#guest-mobile-btn").dropdown("toggle");

    if (value === "adults") {
      let adultsCount =
        this.state.priceCalInput.adults >= this.state.min_guests
          ? this.state.priceCalInput.adults + 1
          : this.state.min_guests;
      if (adultsCount >= this.state.max_guests) {
        adultsCount = this.state.max_guests;
      }
      priceCalInput["adults"] = adultsCount;
    }
    if (value === "children") {
      let childrenCount =
        this.state.priceCalInput.children >= 0
          ? this.state.priceCalInput.children + 1
          : 0;
      priceCalInput["children"] = childrenCount;
    }
    if (value === "infants") {
      let infantsCount =
        this.state.priceCalInput.infants >= 0
          ? this.state.priceCalInput.infants + 1
          : 0;
      priceCalInput["infants"] = infantsCount;
    }
    if (value === "age_special") {
      let infantsCount =
        this.state.priceCalInput.age_special >= 0
          ? this.state.priceCalInput.age_special + 1
          : 0;
      priceCalInput["age_special"] = infantsCount;
    }
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  decreament = (value) => {
    //$("#guest-mobile-btn").dropdown("toggle");
    const priceCalInput = { ...this.state.priceCalInput };
    if (value === "adults") {
      let adultsCount =
        this.state.priceCalInput.adults > this.state.min_guests
          ? this.state.priceCalInput.adults - 1
          : this.state.min_guests;
      priceCalInput["adults"] = adultsCount;
    }
    if (value === "children") {
      let childrenCount =
        this.state.priceCalInput.children > 0
          ? this.state.priceCalInput.children - 1
          : 0;
      priceCalInput["children"] = childrenCount;
    }
    if (value === "infants") {
      let infantsCount =
        this.state.priceCalInput.infants > 0
          ? this.state.priceCalInput.infants - 1
          : 0;
      priceCalInput["infants"] = infantsCount;
    }
    if (value === "age_special") {
      let ageSpecialCount =
        this.state.priceCalInput.age_special > 0
          ? this.state.priceCalInput.age_special - 1
          : 0;
      priceCalInput["age_special"] = ageSpecialCount;
    }
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  onChangeDate = (date) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = dayjs(date).format("YYYY-MM-DD");
    priceCalInput["checkin_formatted"] = date;
    priceCalInput["custom_select_value"] = null;

    delete priceCalInput.host_time_slot_id;

    this.setState({ priceCalInput }, () => this.priceCalcultor());

    // setTimeout(() => {
    //   this.priceCalcultor();
    // }, 1000);

    if (this.state.priceCalInput["host_time_slot_id"]) {
      setTimeout(function() {
        $(".same-date-picker-input").val("");
      }, 500);
    }

    if (this.state.singleTripDetails.basic_details.booking_type == 2) {
      this.getTimeSlotWithAvailable({
        checkin: dayjs(date).format("YYYY-MM-DD"),
        host_id: this.state.singleTripDetails.basic_details.host_id,
        timezone: const_time_zone,
      });
    }
  };

  onNormalBookingChangeDate = (date) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = dayjs(date).format("YYYY-MM-DD");
    priceCalInput["checkin_formatted"] = date;
    priceCalInput["custom_select_value"] = null;
    delete priceCalInput.host_time_slot_id;
    this.setState({ priceCalInput }, () => {
      this.priceCalcultor();
      if (this.state.singleTripDetails.basic_details.pricing_type == 3) {
        this.getPricingPackages(this.state.priceCalInput);
      }
    });
  };

  onChangeCheckoutDate = (title) => (...args) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkout"] = this.convert(args[0]);
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  getAddonServices = () => {
    api
      .postMethod("addon_services", {
        host_id: this.state.singleTripDetails
          ? this.state.singleTripDetails.basic_details.host_id
          : this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            addonServicesData: response.data.data,
            loadingAddonService: false,
          });
          const priceCalInput = { ...this.state.priceCalInput };
          if (!this.state.loadingAddonService) {
            if (this.state.addonServicesData.length > 0) {
              this.state.addonServicesData.map((service) => {
                if (service.is_required == 1) {
                  let unitKey =
                    "host_addon_services[" +
                    service.host_addon_service_id +
                    "][no_of_units]";
                  priceCalInput[unitKey] = service.min_value;
                  priceCalInput["selectedAmentites"] =
                    this.state.priceCalInput.selectedAmentites + 1;
                  this.setState({ priceCalInput });
                }
              });
            }
          }
        } else {
          this.errorCodeChecker(response.data.error_code);
        }
      });
  };
  getFaqs = () => {
    api
      .postMethod("host_faqs", {
        host_id: this.state.singleTripDetails ? this.state.singleTripDetails.basic_details.host_id : this.props.match.params.id,
      })
      .then((response) => {
        if (response.data.success === true) {
          console.log(response.data.data);
          this.setState({
            faqsData: response.data.data,
            loadingFaqs: false,
          });
        } else {
          this.errorCodeChecker(response.data.error_code);
        }
      });
  };

  handleAddonService = (event, service) => {
    // console.log(service);
    if (
      service.unit_type == "person" ||
      service.unit_type == "item" ||
      service.is_required == 0
    ) {
      this.setState({
        addOnServiceModelData: service,
        loadingAddOnServiceModelData: false,
      });
      $("#AddOnServiceAdd").modal("show");
    }
  };

  removeAddonService = (event, name) => {
    event.preventDefault();
    const priceCalInput = { ...this.state.priceCalInput };
    delete priceCalInput[name];
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
      addOnSerModelAddButtonDisable: true,
      addOnSerModelAddLoadingContent: "Loading...",
    });
    setTimeout(() => {
      priceCalInput["selectedAmentites"] =
        this.state.priceCalInput.selectedAmentites - 1;
      // this.setState({ priceCalInput });
      this.setState(
        {
          priceCalInput,
          // loadingBookingForm: false,
          buttonDisableBoBookingForm: false,
          buttonLoadingContentBookingForm: null,
          addOnSerModelAddButtonDisable: false,
          addOnSerModelAddLoadingContent: null,
        },
        () => this.priceCalcultor()
      );
    }, 1000);
    $("#AddOnServiceAdd").modal("hide");
    ToastDemo(this.props.toastManager, "Servie Removed", "success");
  };

  addonServiceQuantity = (event, service) => {
    // add on service Quantity

    if (service.max_value >= event.currentTarget.value) {
      const priceCalInput = { ...this.state.priceCalInput };
      const checkValue = priceCalInput[event.currentTarget.name] !== undefined;

      if (priceCalInput[event.currentTarget.name]) {
        //priceCalInput[event.currentTarget.name] = event.currentTarget.value;
        this.setState({
          priceCalInput: {
            ...this.state.priceCalInput,
            [event.currentTarget.name]: event.currentTarget.value,
            addedAmentity: {
              name: event.currentTarget.name,
              value: event.currentTarget.value,
            },
          },
        });
      } else {
        this.setState({
          priceCalInput: {
            ...this.state.priceCalInput,
            amentityValue: event.currentTarget.value,
            addedAmentity: {
              name: event.currentTarget.name,
              value: event.currentTarget.value,
            },
          },
        });
      }
    } else {
      ToastDemo(
        this.props.toastManager,
        "Please check the min and max of the service.",
        "error"
      );
    }
  };

  addonServiceBooking = (event, addon) => {
    // add on service Quantity
    event.preventDefault();
    const { min_value, host_addon_service_id } = addon;

    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
      addOnSerModelAddButtonDisable: true,
      addOnSerModelAddLoadingContent: "Loading...",
      priceCalInput: {
        ...this.state.priceCalInput,
        amentityValue: min_value,
        addedAmentity: {
          name: `host_addon_services[${host_addon_service_id}][no_of_units]`,
          value: min_value,
        },
      },
    });

    setTimeout(() => {
      const priceCalInput = { ...this.state.priceCalInput };
      priceCalInput["selectedAmentites"] =
        this.state.priceCalInput.selectedAmentites + 1;
      priceCalInput[
        this.state.priceCalInput.addedAmentity.name
      ] = this.state.priceCalInput.addedAmentity.value;
      priceCalInput["addedAmentity"] = {
        name: "",
        value: "",
      };
      priceCalInput["amentityValue"] = "";
      this.setState({
        priceCalInput,
        // loadingBookingForm: false,
        buttonDisableBoBookingForm: false,
        buttonLoadingContentBookingForm: null,
        addOnSerModelAddButtonDisable: false,
        addOnSerModelAddLoadingContent: null,
      });
      $("#AddOnServiceAdd").modal("hide");
      this.priceCalcultor();
    }, 1000);
  };

  addonServiceCallAPI = (event) => {
    event.preventDefault();
    this.setState({
      loadingBookingForm: true,
      buttonDisableBoBookingForm: true,
      buttonLoadingContentBookingForm: "Loading... Please wait",
      addOnSerModelAddButtonDisable: true,
      addOnSerModelAddLoadingContent: "Loading...",
    });
    setTimeout(() => {
      const priceCalInput = { ...this.state.priceCalInput };
      priceCalInput["selectedAmentites"] =
        this.state.priceCalInput.selectedAmentites + 1;
      priceCalInput[
        this.state.priceCalInput.addedAmentity.name
      ] = this.state.priceCalInput.addedAmentity.value;
      priceCalInput["addedAmentity"] = {
        name: "",
        value: "",
      };
      priceCalInput["amentityValue"] = "";
      this.setState({
        priceCalInput,
        // loadingBookingForm: false,
        buttonDisableBoBookingForm: false,
        buttonLoadingContentBookingForm: null,
        addOnSerModelAddButtonDisable: false,
        addOnSerModelAddLoadingContent: null,
      });
      $("#AddOnServiceAdd").modal("hide");
      this.priceCalcultor();
    }, 1000);
  };

  singleTripViewApiCall(formData) {
    // console.log(formData);
    api.postMethod("hosts_view", formData).then((response) => {
      if (response.data.success === true) {
        this.setState({
          singleTripDetails: response.data.data,
          loading: false,
        });
        if(response.data.data.basic_details.is_custom_pricing_request == 1) {
          const priceCalInput = { ...this.state.priceCalInput };
          priceCalInput["checkin"] = dayjs(response.data.data.basic_details.custom_pricing_request.checkin).format("YYYY-MM-DD");
          priceCalInput["checkin_formatted"] = response.data.data.basic_details.custom_pricing_request.checkin;

          this.setState({ priceCalInput });
          setTimeout(() => {
            // this.priceCal();
            this.getTimeSlotWithAvailable({
              checkin: dayjs(response.data.data.basic_details.custom_pricing_request.checkin).format("YYYY-MM-DD"),
              host_id: this.state.singleTripDetails.basic_details.host_id,
              timezone: const_time_zone,
            });
          }, 1000);
        }
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.errorCodeChecker(response.data.error_code);
        this.props.history.goBack();
      }
    });
  }

  selectPackage = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[input.name] = input.value;
    this.setState({ priceCalInput });
    $(".packageDropdown").val(input.value);
    console.log(input.value);
    if(input.value == 'custom_price_request') {
      this.setState({
        requestCustomPricingModel: true,
      });
    }

    setTimeout(() => {
      this.priceCalcultor();
    }, 500);
  };

  handleUserDetailsChange = (event) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput[event.currentTarget.name] = event.currentTarget.value;
    this.setState({ priceCalInput });
  };

  handleContactHost = (event) => {
    event.preventDefault();
    if (this.state.apiCallStatus) {
      this.contactHostAPI();
      // setTimeout(() => {
      //   this.props.history.push("/single-trip/chat", {
      //     host_details: this.state.singleTripDetails.basic_details,
      //     provider_details: this.state.singleTripDetails.provider_details,
      //     pricing_details: this.state.singleTripDetails.pricing_details,
      //     page: "singletrip",
      //     apiResponse : this.state.apiResponse
      //   });
      // }, 1000);
    } else {
      ToastDemo(
        this.props.toastManager,
        "Please fill the booking details to Contact host",
        "error"
      );
    }
  };
  
  contactHostAPI = () => {
    api
      .postMethod("bookings_draft_save", this.state.apiResponse)
      .then((response) => {
        if (response.data.success === true) {
          // Do nothing
        } else {
          this.errorCodeChecker(response.data.error_code);
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .then(() => {
        this.setState({
          chatModel: true,
        });
        //$("#chatModal").modal("show");
      });
  };

  // get time slot based on the date

  getTimeSlot = (inputData) => {
    api
      .postMethod("hosts_time_slots_prebooking", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            timeSlotLoading: false,
            timeSlotData: response.data.data,
          });
        } else {
          this.errorCodeChecker(response.data.error_code);
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  getTimeSlotWithAvailable = (inputData) => {
    api
      .postMethod("host_availability_date_view", inputData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            timeSlotLoading: false,
            timeSlotData: response.data.data.data.availability_text,
          });
        } else {
          this.errorCodeChecker(response.data.error_code);
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  // get time slot based on the date

  getPricingPackages = (inputData) => {
    api.postMethod("hosts_pricing_packages", inputData).then((response) => {
      if (response.data.success === true) {
        this.setState({
          pricingPackagesLoading: false,
          pricingPackagesData: response.data.data,
        });
      } else {
        this.errorCodeChecker(response.data.error_code);
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  timeSlotSelected = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_time_slot_id"] = input.value;
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  customSelectTimeSlotSelected = (value) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_time_slot_id"] = value;

    const selectedValue = this.state.timeSlotData.find(
      (timesolt) => timesolt.time_slot_id == value
    );

    priceCalInput[
      "custom_select_value"
    ] = `${selectedValue.name} - ${selectedValue.start_time_formatted} - ${selectedValue.end_time_formatted}`;

    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
      if (this.state.singleTripDetails.basic_details.pricing_type == 3) {
        this.getPricingPackages(this.state.priceCalInput);
      }
    }, 1000);
  };

  packagePricingSelected = ({ currentTarget: input }) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_pricing_package_id"] = input.value;
    this.setState({ priceCalInput });
    $("#package-select" + input.value).prop("checked", true);
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  handleAvaliablityDateChange = (selected_date, host_id) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = dayjs(selected_date).format("YYYY-MM-DD");
    priceCalInput["checkin_formatted"] = selected_date;
    this.setState({ priceCalInput });

    if (this.state.singleTripDetails.basic_details.booking_type == 2) {
      // this.getTimeSlot({
      //   checkin: selected_date,
      //   host_id: host_id,
      // });
      this.getTimeSlotWithAvailable({
        checkin: selected_date,
        host_id: this.state.singleTripDetails.basic_details.host_id,
        timezone: const_time_zone,
      });
    }
  };

  hanldeNormalBooking = (selected_date) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = dayjs(selected_date).format("YYYY-MM-DD");
    priceCalInput["checkin_formatted"] = selected_date;
    this.setState({ priceCalInput }, () => {
      // console.log("calling pricecalcularor");
      this.priceCalcultor();
    });
  };

  hanldeModalTimeSlotChange = (timeSlot) => {
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["host_time_slot_id"] = timeSlot.time_slot_id;
    priceCalInput[
      "custom_select_value"
    ] = `${timeSlot.name} - ${timeSlot.start_time_formatted} - ${timeSlot.end_time_formatted}`;
    this.setState({ priceCalInput });
    setTimeout(() => {
      this.priceCalcultor();
    }, 1000);
  };

  handleRangleCalenderChange = (dates) => {
    const [start, end] = dates;
    const checkin = moment(start).format("DD-MM-YYYY");
    const checkout = moment(end).format("DD-MM-YYYY");
    const priceCalInput = { ...this.state.priceCalInput };
    priceCalInput["checkin"] = checkin;
    priceCalInput["checkout"] = checkout;
    this.setState({ priceCalInput }, () => {
      if (checkout != "Invalid date") {
        this.priceCal();
      }
    });
  };

  handleBreakdownModal = (active_modal) => {
    this.setState({
      activeBreakdown: active_modal,
    });
  };

  handlebreakdownClose = () => {
    this.setState({
      activeBreakdown: null,
    });
  };

  handleChatModal = (status) => {
    this.setState({
      chatModel: status,
    });
  };

  handleCustomPricingModal = (status) => {
    this.setState({
      requestCustomPricingModel: false,
    });
  };

  handleGalleryModal = (status) => {
    this.setState({
      isModalOpen: status,
    });
  };
  
  render() {
    let load = new Loader("");
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    let { singleTripDetails, loading, isModalOpen } = this.state;

    return loading ? (
      load.bannerLoader()
    ) : (
      <div>
        <Helmet>
          <title>
            {singleTripDetails.metadata
              ? singleTripDetails.metadata.title
              : singleTripDetails.basic_details.host_name}
          </title>
          <meta
            name="keywords"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.keywords
                : ""
            }
          />
          <meta
            name="description"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.description
                : singleTripDetails.basic_details.host_description
            }
          />
          <meta
            property="og:title"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.og_title
                : singleTripDetails.basic_details.host_name
            }
          />
          <meta
            property="og:image"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.og_image
                : singleTripDetails.basic_details.host_picture
            }
          />
        </Helmet>
        <MetaTags>
          <title>
            {singleTripDetails.metadata
              ? singleTripDetails.metadata.title
              : singleTripDetails.basic_details.host_name}
          </title>
          <meta
            name="keywords"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.keywords
                : ""
            }
          />
          <meta
            name="description"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.description
                : singleTripDetails.basic_details.host_description
            }
          />
          <meta
            property="og:title"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.og_title
                : singleTripDetails.basic_details.host_name
            }
          />
          <meta
            property="og:image"
            content={
              singleTripDetails.metadata
                ? singleTripDetails.metadata.og_image
                : singleTripDetails.basic_details.host_picture
            }
          />
        </MetaTags>
        <ReactTitle
          title={
            singleTripDetails.metadata
              ? singleTripDetails.metadata.title
              : singleTripDetails.basic_details.host_name
          }
        />

        <TripBanner
          basicDetails={singleTripDetails.basic_details}
          metadata={singleTripDetails.metadata}
          status="user"
          handleGalleryModal={this.handleGalleryModal}
          isModalOpen={isModalOpen}
        />

        {/* <TripGallery details={singleTripDetails.basic_details} isModalOpen={isModalOpen} handleGalleryModal={this.handleGalleryModal}/> */}

        <div
          className="main"
          onClick={
            this.state.activeBreakdown != null
              ? this.handlebreakdownClose
              : null
          }
        >
          <div className="site-content">
            <div className="top-bottom-spacing">
              <div className="row">
                <div className="col-xl-7 col-lg-10 col-md-10 auto-margin">
                  <div id="overview">
                    <div className="media resp-flex-column">
                      <div className="media-body mr-3">
                        <a href="#">
                          <p className="red-text txt-overflow">
                            {singleTripDetails.basic_details.location}
                          </p>
                        </a>
                        <h1 className="category-section-head">
                          {singleTripDetails.basic_details.host_name}
                        </h1>
                        {/* <h4 className="captalize section-subhead">
                          {singleTripDetails.basic_details.host_location}
                        </h4> */}
                        {/* <h5 className="captalize section-subhead">
                          {singleTripDetails.basic_details
                            .is_automatic_booking == 0 ? (
                            <span className="text-strike">Rapid Booking</span>
                          ) : (
                            singleTripDetails.basic_details
                              .is_automatic_booking_text
                          )}
                        </h5> */}
                      </div>
                      <div className="resp-box">
                        {localStorage.getItem("userLoginStatus") ? (
                          <>
                            <Link
                              to={{
                                pathname: "/single-trip/chat",
                                state: {
                                  host_details: singleTripDetails.basic_details,
                                  provider_details:
                                    singleTripDetails.provider_details,
                                  pricing_details:
                                    singleTripDetails.pricing_details,
                                  page: "singletrip",
                                },
                              }}
                              onClick={this.handleContactHost}
                              className="green-outline-btn btn-small my-auto mr-3"
                            >
                              contact host
                            </Link>
                            {/* <p className="overview-line" /> */}
                          </>
                        ) : (
                          <div className="my-auto">
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#login"
                            >
                              <h4 className="green-outline-btn btn-small my-auto mr-3">
                                contact host
                              </h4>
                            </a>
                          </div>
                        )}

                        <div className="resp-box-1">
                          <Link
                            to={`/provider-profile/${singleTripDetails.provider_details.provider_id}`}
                          >
                            <img
                              src={singleTripDetails.provider_details.picture}
                              alt="rentcubo"
                              className="mt-3 rounded-circle review-img"
                            />
                          </Link>
                          <p className="text-center top3 mb-0">
                            <a href="#" className="other-proname">
                              {singleTripDetails.provider_details.provider_name}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <ul className="home-requirements">
                      {singleTripDetails.basic_details.section_4.length > 0
                        ? singleTripDetails.basic_details.section_4.map(
                            (details) => (
                              <li className="">
                                <img
                                  src={details.picture}
                                  alt="rentcubo"
                                  style={{ width: "20px", height: "auto" }}
                                />
                                &nbsp;
                                <span className={details.class}>
                                  {details.title}
                                </span>
                              </li>
                            )
                          )
                        : ""}
                    </ul>

                    <h4 className="single-cat-text">
                      {renderHTML(
                        singleTripDetails.basic_details.host_description
                      )}
                    </h4>
                    {/* {localStorage.getItem("userLoginStatus") ? (
                      <Link
                        to={"#"}
                        // to={{
                        //   pathname: "/single-trip/chat",
                        //   state: {
                        //     host_details: singleTripDetails.basic_details,
                        //     provider_details:
                        //       singleTripDetails.provider_details,
                        //     pricing_details: singleTripDetails.pricing_details,
                        //     page: "singletrip",
                        //   },
                        // }}
                        onClick={this.handleContactHost}
                      >
                        <h4 className="collapse-head">contact hosts</h4>
                      </Link>
                    ) : (
                      <div className="my-auto">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#login"
                        >
                          <h4 className="collapse-head">contact host</h4>
                        </a>
                    </div>
                    )} */}

                    {singleTripDetails.basic_details.pricing_type == 3 ? (
                      <TripPackagePricing
                        singleTripDetails={singleTripDetails}
                        loading={loading}
                        selectPackage={this.selectPackage}
                        priceCalInput={this.state.priceCalInput}
                      />
                    ) : null}

                    {singleTripDetails.amenties.data.length > 0 ? (
                      <>
                        <p className="overview-line" />

                        <h4 className="single-cat-text medium-cls ">
                          {singleTripDetails.amenties.title}
                        </h4>
                        <ul className="amenties-list">
                          <div>
                            {singleTripDetails.amenties.data
                              .filter((amenties) => amenties.is_available == 1)
                              .map((amenity, index) =>
                                index <= 3 ? (
                                  <li className="">
                                    <img
                                      src={
                                        amenity.amenities_picture != ""
                                          ? amenity.amenities_picture
                                          : amenity.picture
                                      }
                                      className="amentieimg"
                                    />
                                    &nbsp;{amenity.user_question}
                                    <br />
                                    {/* <p className="text-muted amentie-answer">
                                      {amenity.answers}
                                    </p> */}
                                    <br />
                                    {amenity.description == "" ? (
                                      ""
                                    ) : (
                                      <h6> NOTE: {amenity.description}</h6>
                                    )}
                                  </li>
                                ) : (
                                  ""
                                )
                              )}

                            <div className="clearfix" />

                            {singleTripDetails.amenties.data.length > 1 ? (
                              <>
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#amenities"
                                >
                                  <h4 className="amenities-head">
                                    show all amenities
                                  </h4>
                                </a>

                                <TripShowAllAmen
                                  amenties={singleTripDetails.amenties}
                                  notIncluedAmentites={
                                    singleTripDetails.not_included_amenities
                                  }
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </ul>
                      </>
                    ) : (
                      ""
                    )}

                    <TripFaqs 
                      faqsData={this.state.faqsData}
                      loadingFaqs={this.state.loadingFaqs}
                    />

                    {singleTripDetails.other_house_rules.length > 0 ? (
                      <div className="cancellationPolicy">
                        <p className="overview-line" />
                        <h4 className="single-cat-text medium-cls ">
                          House Rules
                        </h4>
                        {singleTripDetails.other_house_rules.map(
                          (houseRule) => (
                            <div className="d-flex align-items-center my-2 singlePolicies">
                              <span className="mr-3">
                                <i class="far fa-dot-circle"></i>
                              </span>
                              <h4 className="single-cat-text mb-0">
                                {houseRule.content}
                              </h4>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}

                    {singleTripDetails.other_cancellation_policies.length >
                    0 ? (
                      <div className="cancellationPolicy">
                        <p className="overview-line" />

                        <h4 className="single-cat-text medium-cls ">
                          Cancellation Policies
                        </h4>
                        {singleTripDetails.other_cancellation_policies.map(
                          (cancelPolicy) => (
                            <div className="d-flex align-items-center my-2 singlePolicies">
                              <span className="mr-3">
                                <i class="far fa-dot-circle"></i>
                              </span>
                              <h4 className="single-cat-text mb-0">
                                {cancelPolicy.content}
                              </h4>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}

                    {singleTripDetails.house_rules ? (
                      <div className="house_rules">
                        <p className="overview-line" />

                        <h4 className="single-cat-text medium-cls ">
                          Other House Rules
                        </h4>
                        <h4 className="single-cat-text ">
                          {singleTripDetails.house_rules}
                        </h4>
                      </div>
                    ) : null}

                    {singleTripDetails.cancellation_policy ? (
                      <div className="cancellationPolicy">
                        <p className="overview-line" />

                        <h4 className="single-cat-text medium-cls ">
                          Other Cancellation Policies
                        </h4>
                        <h4 className="single-cat-text ">
                          {singleTripDetails.cancellation_policy}
                        </h4>
                      </div>
                    ) : null}

                    <TripAddonServices
                      getAddonServices={this.getAddonServices}
                      handleAddonService={this.handleAddonService}
                      addonServicesData={this.state.addonServicesData}
                      loadingAddonService={this.state.loadingAddonService}
                      priceCalInput={this.state.priceCalInput}
                    />

                    <p className="overview-line" />
                    {singleTripDetails.policies.data.length > 0
                      ? singleTripDetails.policies.data.map((homeDetails) => (
                          <div>
                            <h4 className="single-cat-text medium-cls">
                              {homeDetails.title}
                            </h4>
                            <h4 className="rules-text">
                              {renderHTML(homeDetails.description)}
                            </h4>
                            <p className="overview-line" />
                          </div>
                        ))
                      : ""}

                    <h4 className="single-cat-text medium-cls">availability</h4>
                    {/* <h4 className="single-cat-text"> */}
                    <div id="singleTripCalender">
                      <GetAvailability
                        host_id={singleTripDetails.basic_details.host_id}
                        basicDetails={singleTripDetails.basic_details}
                        handleAvaliablityDateChange={
                          this.handleAvaliablityDateChange
                        }
                        hanldeModalTimeSlotChange={
                          this.hanldeModalTimeSlotChange
                        }
                        priceCalInput={this.state.priceCalInput}
                        handleRangleCalenderChange={
                          this.handleRangleCalenderChange
                        }
                        hanldeNormalBooking={this.hanldeNormalBooking}
                        onChangeDate={this.onChangeDate}
                      />
                    </div>
                    <div className="mobile-display">
                      <MobileTripBookingForm
                        basicDetails={singleTripDetails.basic_details}
                        status="user"
                        setHostId={this.setHostId}
                        getPromoCode={this.getPromoCode}
                        getPromoCodeSelect={this.getPromoCodeSelect}
                        checkPromoCode={this.checkPromoCode}
                        promoCodeAPICall={this.promoCodeAPICall}
                        removePromoCode={this.removePromoCode}
                        priceCalcultor={this.priceCalcultor}
                        bookNow={this.bookNow}
                        increament={this.increament}
                        decreament={this.decreament}
                        convert={this.convert}
                        onChangeDate={this.onChangeDate}
                        onChangeCheckoutDate={this.onChangeCheckoutDate}
                        addonServiceQuantity={this.addonServiceQuantity}
                        addonServiceCallAPI={this.addonServiceCallAPI}
                        loadingBookingForm={this.state.loadingBookingForm}
                        apiResponse={this.state.apiResponse}
                        apiCallStatus={this.state.apiCallStatus}
                        providerDetails={this.state.providerDetails}
                        buttonDisable={this.state.buttonDisableBookingForm}
                        buttonLoadingContent={
                          this.state.buttonLoadingContentBookingForm
                        }
                        singleTripDetails={this.state.singleTripDetails}
                        loadingAddonService={this.state.loadingAddonService}
                        addonServicesData={this.state.addonServicesData}
                        promoCodeInputData={this.state.promoCodeInputData}
                        promoCodeApiResponse={this.state.promoCodeApiResponse}
                        loadingPromoCode={this.state.loadingPromoCode}
                        providerDetails={singleTripDetails.provider_details}
                        priceCalInput={this.state.priceCalInput}
                        location={this.props.location}
                        history={this.props.history}
                        timeSlotLoading={this.state.timeSlotLoading}
                        timeSlotData={this.state.timeSlotData}
                        timeSlotSelected={this.timeSlotSelected}
                        activeBreakdown={this.state.activeBreakdown}
                        handleBreakdownModal={this.handleBreakdownModal}
                        handlebreakdownClose={this.handlebreakdownClose}
                        onNormalBookingChangeDate={
                          this.onNormalBookingChangeDate
                        }
                        packagePricingSelected={this.packagePricingSelected}
                        pricingPackagesLoading={
                          this.state.pricingPackagesLoading
                        }
                        pricingPackagesData={this.state.pricingPackagesData}
                        handleUserDetailsChange={this.handleUserDetailsChange}
                        customSelectTimeSlotSelected={
                          this.customSelectTimeSlotSelected
                        }
                        promoCodeData={this.state.promoCodeData}
                      />
                    </div>

                    {/* <span className="medium-cls">1 night</span> minimum stay
                      <span className="dot">
                        <i className="fas fa-circle" />
                      </span>{" "}
                      Updated 1 day ago
                    </h4> */}
                    <p className="overview-line" />
                  </div>

                  <TripReviewSection
                    details={singleTripDetails.basic_details}
                  />

                  <TripHostSection
                    providerDetails={singleTripDetails.provider_details}
                    singleTripDetails={singleTripDetails}
                    handleContactHost={this.handleContactHost}
                  />

                  <TripLocation location={singleTripDetails.basic_details} />
                  <div className="clearfix" />
                </div>
                <TripBookingForm
                  basicDetails={singleTripDetails.basic_details}
                  status="user"
                  setHostId={this.setHostId}
                  getPromoCode={this.getPromoCode}
                  getPromoCodeSelect={this.getPromoCodeSelect}
                  checkPromoCode={this.checkPromoCode}
                  promoCodeAPICall={this.promoCodeAPICall}
                  removePromoCode={this.removePromoCode}
                  priceCalcultor={this.priceCalcultor}
                  bookNow={this.bookNow}
                  increament={this.increament}
                  decreament={this.decreament}
                  convert={this.convert}
                  onChangeDate={this.onChangeDate}
                  onChangeCheckoutDate={this.onChangeCheckoutDate}
                  addonServiceQuantity={this.addonServiceQuantity}
                  addonServiceCallAPI={this.addonServiceCallAPI}
                  loadingBookingForm={this.state.loadingBookingForm}
                  apiResponse={this.state.apiResponse}
                  apiCallStatus={this.state.apiCallStatus}
                  providerDetails={this.state.providerDetails}
                  buttonDisable={this.state.buttonDisableBookingForm}
                  buttonLoadingContent={
                    this.state.buttonLoadingContentBookingForm
                  }
                  singleTripDetails={this.state.singleTripDetails}
                  loadingAddonService={this.state.loadingAddonService}
                  addonServicesData={this.state.addonServicesData}
                  promoCodeInputData={this.state.promoCodeInputData}
                  promoCodeApiResponse={this.state.promoCodeApiResponse}
                  loadingPromoCode={this.state.loadingPromoCode}
                  providerDetails={singleTripDetails.provider_details}
                  priceCalInput={this.state.priceCalInput}
                  location={this.props.location}
                  history={this.props.history}
                  timeSlotLoading={this.state.timeSlotLoading}
                  timeSlotData={this.state.timeSlotData}
                  timeSlotSelected={this.timeSlotSelected}
                  activeBreakdown={this.state.activeBreakdown}
                  handleBreakdownModal={this.handleBreakdownModal}
                  handlebreakdownClose={this.handlebreakdownClose}
                  onNormalBookingChangeDate={this.onNormalBookingChangeDate}
                  packagePricingSelected={this.packagePricingSelected}
                  pricingPackagesLoading={this.state.pricingPackagesLoading}
                  pricingPackagesData={this.state.pricingPackagesData}
                  handleUserDetailsChange={this.handleUserDetailsChange}
                  customSelectTimeSlotSelected={
                    this.customSelectTimeSlotSelected
                  }
                  promoCodeData={this.state.promoCodeData}
                />
              </div>

              <TripSimilarListing
                host_id={singleTripDetails.basic_details.host_id}
                displaySimilarTrip={this.displaySimilarTrip}
              />
            </div>
          </div>
        </div>
        <TripAddOnSerAddModel
          addOnServiceModelData={this.state.addOnServiceModelData}
          loadingAddOnServiceModelData={this.state.loadingAddOnServiceModelData}
          priceCalInput={this.state.priceCalInput}
          addonServiceQuantity={this.addonServiceQuantity}
          addonServiceCallAPI={this.addonServiceCallAPI}
          addOnSerModelAddButtonDisable={
            this.state.addOnSerModelAddButtonDisable
          }
          addOnSerModelAddLoadingContent={
            this.state.addOnSerModelAddLoadingContent
          }
          removeAddonService={this.removeAddonService}
          addonServiceBooking={this.addonServiceBooking}
        />
        {this.state.chatModel && (
          <ChatModal
            host_details={this.state.singleTripDetails.basic_details}
            provider_details={this.state.singleTripDetails.provider_details}
            pricing_details={this.state.singleTripDetails.pricing_details}
            page={"singletrip"}
            apiResponse={this.state.apiResponse}
            chatModel={this.state.chatModel}
            priceCalInput={this.state.priceCalInput}
            handleChatModal={this.handleChatModal}
          />
        )}
        {this.state.requestCustomPricingModel && (
          <CustomPriceModal
            host_details={this.state.singleTripDetails.basic_details}
            requestCustomPricingModel={this.state.requestCustomPricingModel}
            priceCalInput={this.state.priceCalInput}
            handleCustomPricingModal={this.handleCustomPricingModal}
          />
        )}
      </div>
    );
  }
}

export default withToastManager(SingleTrip);
