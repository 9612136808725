import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import api from "../../Environment";
import { apiConstants } from "../Constant/constants";
import StarRatingComponent from "react-star-rating-component";
import Geocode from "react-geocode";
import ToastDemo from "../Helper/toaster";
import configuration from "react-global-configuration";
import io from "socket.io-client";
let socket;

class Helper extends Component {
  state = {
    first_block: null,
    mainData: null,
    userDetails: {},
    data: {},
    errors: {},
    isMapVisible: false,
    loading: true,
    loadingCategory: true,
    loadingProfile: true,
    profileError: null,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    contentData: null,
    second_block: null,
    profileUpdateStatusContent: null,
    changeChat: false,
    loadingMap: true,
    navigatorResult: null,
    loadingNavigator:true,
    chat_notification:0,
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  homePageFirstSection = async (inputData) => {
    console.log("first section running");
    await api.postMethod("home_first_section", inputData).then((response) => {
      this.setState({
        first_block: response.data.first_block,
        mainData: response.data.data,
        loading: false,
        second_block: response.data.second_block,
        sub_categories: response.data.sub_categories,
      });
    });
  };

  seeAll = (inputData, loadmore) => {
    api.postMethod("see_all", inputData).then((response) => {
      if (response.data.success) {
        let items;
        if (
          this.state.contentData != null &&
          response.data.data[0].length > 0
        ) {
          items = [...this.state.contentData, ...response.data.data[0].data];
        } else {
          items = response.data.data[0].data;
        }
        console.log(loadmore);

        this.setState(
          {
            contentData: items,
            mainData: response.data.data,
            loading: false,
            skipCount: loadmore
              ? response.data.data[0].data.length + this.state.skipCount
              : response.data.data[0].data.length,
            loadingStatus: true,
            loadingMap: false,
            first_block: response.data.first_block,
          },
          () => console.log(this.state.contentData)
        );
      }
    });
  };

  chatSocketConnect = () => {
    const interval = setInterval(() => {
      api.postMethod("get_notifications_status").then((response) => {
        if (response.data.success === true) {
          this.setState({
            chat_notification:response.data.data.chat_notification,
          })  
        }
      });
        
    }, 120000);
    return () => clearInterval(interval);
  };

  renderCategory(label) {
    return (
      <React.Fragment>
        {this.state.categories.map((category) => (
          <Link
            key={category.category_id}
            // to={`/see_all/${category.name}/${category.api_page_type_id}/${
            //   this.props.categoryDetails.api_page_type
            // }`}
            to={`/category/${category.category_user_display_name}/${category.api_page_type_id}/${category.api_page_type}`}
            className={label}
          >
            {category.category_user_display_name}
          </Link>
        ))}
      </React.Fragment>
    );
  }

  renderPropsCategory(label) {
    return (
      <React.Fragment>
        {this.props.data.map((category) => (
          <Link
            key={category.category_id}
            to={`/see_all/${category.name}/${category.api_page_type_id}/${this.props.api_page_type}`}
            className={label}
          >
            {category.name}
          </Link>
        ))}
      </React.Fragment>
    );
  }

  getUserDetails() {
    api.postMethod("profile").then((response) => {
      if (response.data.success === true) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        this.errorCodeChecker(response.data.error_code);
        const check = this.checkLoginUser(response.data);
        if (check) {
          this.setState({ profileError: "Please login to continue" });
        } else {
          this.setState({ profileError: response.data.error });
        }
      }
    });
  }

  getProviderProfile(id) {
    api.postMethod("providers_view", { provider_id: id }).then((response) => {
      if (response.data.success === true) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        // Do nothing.
      }
    });
  }

  getOtherUserProfile(id) {
    api.postMethod("users_view", { user_id: id }).then((response) => {
      if (response.data.success === true) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        // Do nothing.
      }
    });
  }

  updateProfile() {
    let userDetails = { ...this.state.data };
    const data = {
      name: userDetails.name,
      description: userDetails.description,
      email: userDetails.email,
      mobile: userDetails.mobile,
    };

    api.postMethod("update_profile", data).then((response) => {
      if (response.data.success) {
        this.setState({
          profileUpdateStatusContent: response.data.message,
        });
      } else {
        this.errorCodeChecker(response.data.error_code);
        this.setState({
          profileUpdateStatusContent: response.data.error,
        });
      }
    });
  }

  changePassword() {
    api.postMethod("change_password", this.state.data).then((response) => {
      if (response.data.success) {
      }
    });
  }

  getMapVisible() {
    return this.state.isMapVisible;
  }

  getCategory() {
    api.getMethod("categories").then((response) => {
      if (response.data.success === true) {
        let categories = response.data.data;
        this.setState({
          categories: categories,
          loadingCategory: false,
        });
      } else {
        this.errorCodeChecker(response.data.error_code);
      }
    });
  }

  checkLoginUser(data) {
    return apiConstants.ERROR_CODE.includes(data.error_code);
  }

  priceCalculatorApiCall(inputData) {
    api.postMethod("host_price_calculator", inputData).then((response) => {
      return response.data;
    });
  }
  searchApiCall(props, location_id) {
    api
      .postMethod("search_result", { service_location_id: location_id })
      .then((response) => {
        if (response.data.success === true) {
          props.history.push("/search", response.data.data);
        } else {
          this.errorCodeChecker(response.data.error_code);
          // ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function(error) {});
  }

  starRatingHost(ratings, givenfontSize = 17) {
    return (
      <span style={{ fontSize: givenfontSize }}>
        <StarRatingComponent
          name="ratings"
          starCount={5}
          value={ratings}
          starColor={"#c54a59"}
          emptyStarColor={"#adadad"}
          editing={false}
        />
      </span>
    );
  }

  handleNavigator = () => {
    console.log("navigator running");

    let city = "",
      state,
      country;

    navigator.geolocation.getCurrentPosition((position) => {
      var positionInfo =
        "Your current position is (" +
        "Latitude: " +
        position.coords.latitude +
        ", " +
        "Longitude: " +
        position.coords.longitude +
        ")";
      console.log("position info", positionInfo);
      Geocode.setApiKey(apiConstants.google_api_key);
      Geocode.setLanguage("en");
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          console.log("google response", response);
          const address = response.results[0].formatted_address;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }

          console.log("cityy", city);
          setTimeout(() => {
            if (city != "") {
              this.setState({
                navigatorResult: {
                  city: city,
                },
                loadingNavigator: false,
              });
            }
            api
              .postMethod("home_first_section", {
                location_name: city,
                api_page_type: "HOME",
              })
              .then((response) => {
                if (response.data.success === true) {
                  this.setState({
                    first_block: response.data.first_block,
                    mainData: response.data.data,
                    loading: false,
                    second_block: response.data.second_block,
                    sub_categories: response.data.sub_categories,
                  });
                } else {
                  ToastDemo(
                    this.props.toastManager,
                    response.data.error,
                    "error"
                  );
                }
              })
              .catch(function(error) {});
          }, 1000);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  errorCodeChecker(errorCode) {
    console.log(errorCode);
    if (
      // errorCode == 3000 ||
      // errorCode == 3002 ||
      // errorCode == 905 ||
      // errorCode == 133 ||
      // errorCode == 103 ||
      errorCode == 1004
    ) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("userLoginStatus");
      localStorage.removeItem("user_picture");
      localStorage.removeItem("username");
      localStorage.removeItem("is_frontdesk");
      localStorage.removeItem("login_by");
      localStorage.removeItem("socket");

      // this.props && this.props.history.push("/");
      ToastDemo(this.props.toastManager, "Session Expired", "error");

      setTimeout(function() {
        window.location = "/";
      }, 1000);
    }
  }
}

export default Helper;
