import React, { Component } from "react";
import api from "../../../../HostEnvironment";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../../Helper/toaster";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import AddListingStep1 from "./AddListingStep1";
import AddListingStep2 from "./AddListingStep2";
import AddListingStep3 from "./AddListingStep3";
import AddListingStep4 from "./AddListingStep4";
import AddListingStep5 from "./AddListingStep5";
import AddListingStep6 from "./AddListingStep6";
import AddListingStep7 from "./AddListingStep7";
import AddListingStep8 from "./AddListingStep8";
import AddListingStep9 from "./AddListingStep9";
import AddListingStep10 from "./AddListingStep10";
import { apiConstants } from "../../../Constant/constants";

class AddLisingIndex extends Component {
  state = {
    displayFirst: true,
    loading: true,
    steps: null,
    stepNumber: null,
    stepData: null,
    loadingStep: true,
    stepNum: 0,
    secondApiResponse: null,
    secondApiLoading: true,
    thirdApiResponse: null,
    thirdApiLoading: true,
    fourthApiResponse: null,
    fourthApiLoading: true,
    fifthApiResponse: null,
    fifthApiLoading: true,
    sixthApiResponse: null,
    sixthApiLoading: true,
    seventhApiResponse: null,
    seventhApiLoading: true,
    eighthApiResponse: null,
    eighthApiLoading: true,
    categoryValue: null,
    subCategoryData: null,
    subcategoryLoading: true,
    subCategoryValue: null,
    checkedStatus: false,
    position: null,
    submitClicked: false,
    loadingContent: null,
    displayTimeslot: false,
    displayDailySlot: false,
    pricingType: 0,
    OtherRuleLoading: true,
    OtherHouseRules: null,
    OtherCancellationPolicies: null,
    loadingMessage: "Loading...",
    formData: {
      sub_category_id: "",
      host_type: "",
      total_guests: 0,
      min_guests: null,
      max_guests: null,
      total_bedrooms: 0,
      total_beds: 0,
      total_bathrooms: "",
      bathroom_type: "",
      service_location_id: 0,
      latitude: "",
      longitude: "",
      adult_from: 16,
      adult_to: 100,
      child_from: 2,
      child_to: 15,
      infants_from: 0,
      infants_to: 1,
    },
  };

  componentDidMount() {
    api.postMethod("hosts_steps").then((response) => {
      if (response.data.success) {
        this.setState({
          steps: response.data.data,
          stepNumber: response.data.data[0].step,
          loading: false,
        });
        api
          .postMethod("hosts_steps", {
            step: this.state.stepNumber,
          })
          .then((response) => {
            if (response.data.success) {
              this.setState({
                loadingStep: false,
                stepData: response.data.data,
              });
              console.log("APi state", this.state.stepData);
            }else{
              if(response.data.error_code == 1004){
                this.props.toggleLoginModal(false);
              }
            }
          });
      } else {
        // this.props.history.push({
        //   pathname: "/host/subscriptions",
        //   state: { success: false, message: response.data.error },
        // });
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
        this.setState({
          loadingMessage: response.data.error,
        });
      }
    });
    this.setState({ stepNum: 1 });
  }

  renderAutoComplete = () => {
    const { google } = this.props;
    if (!google) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place);
      if (!place.geometry) return;
      this.setState({ position: place.geometry.location });
      const formData = { ...this.state.formData };
      formData["latitude"] = place.geometry.location.lat();
      formData["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      formData["full_address"] = full_address;

      this.setState({ formData });
    });
  };

  handleNextButton = (event) => {
    event.preventDefault();
    this.setState({ stepNum: this.state.stepNum + 1 });

    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    api.postMethod("hosts_step1_save", this.state.formData).then((response) => {
      if (response.data.success) {
        this.props.history.push(
          `/host/listing-step2/${response.data.data.host_id}`
        );
      } else {
        // alert(response.data.error);
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
        this.setState({
          loadingContent: null,
          submitClicked: false,
        });
      }
    });

    if (this.state.secondApiLoading && this.state.stepNum == 2) {
      this.callSecondApi();
    }
    if (this.state.thirdApiLoading && this.state.stepNum == 3) {
      this.callThirdApi();
    }
    if (this.state.fourthApiLoading && this.state.stepNum == 4) {
      this.callFourthApi();
    }
    if (this.state.fifthApiLoading && this.state.stepNum == 5) {
      this.callFifthApi();
    }
    // if (this.state.sixthApiLoading && this.state.stepNum == 6) {
    //   this.callSixthApi();
    // }
    if (this.state.seventhApiLoading && this.state.stepNum == 6) {
      this.callSeventhApi();
    }
    if (this.state.eighthApiLoading && this.state.stepNum == 7) {
      this.callEighthApi();
    }
  };

  handleBackButton = (event) => {
    event.preventDefault();
    if (this.state.stepNum > 1) {
      this.setState({ stepNum: this.state.stepNum - 1 });
    }
  };

  callSecondApi = () => {
    api.postMethod("hosts_steps", { step: "2_LOCATION" }).then((response) => {
      if (response.data.success) {
        this.setState({
          secondApiLoading: false,
          secondApiResponse: response.data.data,
        });
      }else{
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      }
    });
  };

  callThirdApi = () => {
    console.log("Third API");
    api.postMethod("hosts_steps", { step: "3_AMENTIES" }).then((response) => {
      if (response.data.success) {
        this.setState({
          thirdApiLoading: false,
          thirdApiResponse: response.data.data,
        });
      }else{
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      }
    });
  };

  callFourthApi = () => {
    console.log("Fourth API");
    api.postMethod("hosts_steps", { step: "4_BASICS" }).then((response) => {
      if (response.data.success) {
        this.setState({
          fourthApiLoading: false,
          fourthApiResponse: response.data.data,
        });
        console.log("Fourth API", this.state.fourthApiResponse);
      }else{
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      }
    });
  };

  callFifthApi = () => {
    console.log("fifth API");
    api
      .postMethod("hosts_steps", { step: "5_OTHER_QUESTIONS" })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            fifthApiLoading: false,
            fifthApiResponse: response.data.data,
          });
        }else{
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
        }
      });
  };

  callSixthApi = () => {
    console.log("Sixth API");
    api.postMethod("hosts_steps", { step: "6_PRICING" }).then((response) => {
      if (response.data.success) {
        this.setState({
          sixthApiLoading: false,
          sixthApiResponse: response.data.data,
        });
      }else{
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      }
    });
  };

  callSeventhApi = () => {
    console.log("(Seventh) API");
    api
      .postMethod("hosts_steps", { step: "9_ADDON_SERVICES" })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            seventhApiLoading: false,
            seventhApiResponse: response.data.data,
          });
        }else{
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
        }
      });
  };

  callEighthApi = () => {
    console.log("(eight) API");
    api
      .postMethod("hosts_steps", { step: "8_CONFIRMATION_AND_PREVIEW" })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            eighthApiLoading: false,
            eighthApiResponse: response.data.data,
          });
        }else{
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
        }
      });
    api.postMethod("rule_lookups").then((response) => {
      if (response.data.success) {
        this.setState({
          OtherRuleLoading: false,
          OtherHouseRules: response.data.data.house_rules,
          OtherCancellationPolicies: response.data.data.cancellation_policies,
        });
      }else{
        if(response.data.error_code == 1004){
          this.props.toggleLoginModal(false);
        }
      }
    });
  };

  categoryChange = ({ currentTarget: input }) => {
    // event.preventDefault();
    this.setState({ categoryValue: input.value });
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData });
    api
      .postMethod("sub_categories", { category_id: input.value })
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.length == 0) {
            ToastDemo(
              this.props.toastManager,
              "there is no sub-category in this category. Please choose other",
              "error"
            );
          }
          this.setState({
            subcategoryLoading: false,
            subCategoryData: response.data.data,
          });
        }else{
          if(response.data.error_code == 1004){
            this.props.toggleLoginModal(false);
          }
        }
      });
  };
  handleChange = ({ currentTarget: input }) => {
    // event.preventDefault();
    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    console.log("INPUT", input);

    this.setState({ formData });

    this.setState({ subCategoryValue: input.value });
  };

  handleAmentiesChange = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    console.log("INPUT", input);

    if (input.type === "checkbox") {
      if (input.checked) {
        if (formData[input.name] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          formData[input.name] = array;
          this.setState({ formData });
        } else {
          const value = parseInt(input.value);
          formData[input.name].push(value);
          this.setState({ formData });
        }
      } else {
        const value = parseInt(input.value);
        let index = formData[input.name].indexOf(value);
        if (index !== -1) {
          formData[input.name].splice(index, 1);
          this.setState({ formData });
        }
      }
    }

    if (input.type === "radio") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "text") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "select-one") {
      formData[input.name] = input.value;
      this.setState({ formData });
    }
    if (input.type === "file") {
      formData[input.name] = input.files[0];
      this.setState({ formData });
    }
    // for booking type change.
    setTimeout(() => {
      if (this.state.formData.booking_type != undefined) {
        if (this.state.formData.booking_type == 2) {
          this.setState({ displayTimeslot: true, displayDailySlot: false });
        } else {
          this.setState({ displayTimeslot: false, displayDailySlot: true });
        }
      }
      if (this.state.formData.pricing_type != undefined) {
        this.setState({ pricingType: this.state.formData.pricing_type });
      }
    }, 500);
  };

  handleAddOnServices = ({ currentTarget: input }) => {
    const formData = { ...this.state.formData };
    if (input.type === "text" || input.type == "number") {
      let array = [];
      const value = input.value;
      if (value != null) {
        array.push(value);
      }
      formData[input.name] = array;
      this.setState({ formData });
    }
    if (input.type === "checkbox") {
      if (input.checked) {
        formData[input.name] = 1;
        this.setState({ formData });
      } else {
        if (formData[input.name] != undefined) {
          formData[input.name] = 0;
          this.setState({ formData });
        }
      }
    }

    if (input.type === "radio") {
      if (input.checked) {
        formData[input.name] = input.value;
        this.setState({ formData });
      } else {
        if (formData[input.name] != undefined) {
          formData[input.name] = input.value;
          this.setState({ formData });
        }
      }
    }
  };

  increament = (value) => {
    const formData = { ...this.state.formData };
    formData[value] = this.state.formData[value] + 1;
    this.setState({ formData });
  };

  decreament = (value) => {
    const formData = { ...this.state.formData };
    formData[value] = this.state.formData[value] - 1;
    this.setState({ formData });
  };

  submitForm = () => {
    this.setState({
      loadingContent: "Loading.. Please Wait..",
      submitClicked: true,
    });
    api.postMethod("hosts_save", this.state.formData).then((response) => {
      if (response.data.success) {
        this.props.history.push({
          pathname: "/host/listing",
          state: { success: true, message: response.data.message },
        });
      } else {
        console.log("Fails", response);
        alert(response.data.error);
        this.setState({
          loadingContent: null,
          submitClicked: false,
        });
      }
    });
  };

  render() {
    const {
      displayFirst,
      loading,
      steps,
      stepNumber,
      loadingStep,
      stepData,
      stepNum,
      secondApiLoading,
      secondApiResponse,
      thirdApiLoading,
      thirdApiResponse,
      fourthApiLoading,
      fourthApiResponse,
      fifthApiLoading,
      fifthApiResponse,
      sixthApiLoading,
      sixthApiResponse,
      eighthApiLoading,
      eighthApiResponse,
      seventhApiLoading,
      seventhApiResponse,
      subCategoryData,
      subcategoryLoading,
      formData,
      checkedStatus,
      position,
      submitClicked,
      loadingContent,
      categoryValue,
      displayTimeslot,
      loadingMessage,
    } = this.state;
    return (
      <div
        className="wrapper"
        data-spy="scroll"
        data-target="#second"
        data-offset="100"
      >
        <nav className="navbar navbar-expand-xl bg-light navbar-light white-header">
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
            />
            <span style={{ marginLeft: "20px" }}>
              Step 1: Start with the basics
            </span>
          </Link>
        </nav>
        <div className="container-add-listing add-list">
          {/* <div className="add-flow"> */}
          <div className="flow-bg row">
            <div className="flow-bg-left col-md-7">
              <div id="smartwizard" className="add-list-wizard">
                <div className="add-list-content p-5-10">
                  {loadingStep ? (
                    loadingMessage
                  ) : stepNumber == "1_PROPERTY" && stepNum == 1 ? (
                    <AddListingStep1
                      stepData={stepData}
                      loadingStep={loadingStep}
                      categoryChange={this.categoryChange}
                      categoryValue={categoryValue}
                      handleChange={this.handleChange}
                      subcategoryLoading={subcategoryLoading}
                      subCategoryData={subCategoryData}
                      formData={formData}
                    />
                  ) : stepNumber == "1_PROPERTY" && stepNum == 2 ? (
                    // Host event selection
                    <AddListingStep2
                      stepData={stepData}
                      handleAmentiesChange={this.handleAmentiesChange}
                    />
                  ) : stepNumber == "1_PROPERTY" && stepNum == 3 ? (
                    <AddListingStep3
                      stepData={stepData}
                      handleChange={this.handleChange}
                      formData={formData}
                    />
                  ) : (
                    ""
                  )}
                  {secondApiLoading ? (
                    ""
                  ) : stepNum == 4 ? (
                    <div id="step-4" className="flow-tab">
                      <div className="add-flow-block row">
                        <div className="col-md-12">
                          <div className="add-flow-block-left">
                            <h4 className="flow-tit">
                              {secondApiResponse[0].title}
                            </h4>
                            <p className="main-txt">
                              {secondApiResponse[0].description}
                            </p>
                            <div className="flow-content">
                              <div className="form-group large-form-group">
                                <label className="light-txt">
                                  {secondApiResponse[0].data[0].question}
                                </label>
                                <select
                                  className="form-control"
                                  name={secondApiResponse[0].data[0].server_key}
                                  value={formData.service_location_id}
                                  onChange={this.handleChange}
                                >
                                  <option>Select Service Location</option>
                                  {secondApiResponse[0].data[0].answer.map(
                                    (ans) => (
                                      <option key={ans.key} value={ans.key}>
                                        {ans.value}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              <div className="form-group large-form-group">
                                <label className="light-txt">
                                  Full Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="House name/number + street/road"
                                  onFocus={this.renderAutoComplete}
                                  ref={(ref) => (this.autocomplete = ref)}
                                  name={secondApiResponse[0].data[1].server_key}
                                  value={formData["full_address"]}
                                  onChange={this.handleAmentiesChange}
                                />
                                <Map
                                  className="map"
                                  google={this.props.google}
                                  visible={true}
                                  center={position}
                                >
                                  <Marker position={position} />
                                </Map>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {thirdApiLoading ? (
                    ""
                  ) : stepNum == 5 ? (
                    <AddListingStep5
                      thirdApiResponse={thirdApiResponse}
                      handleAmentiesChange={this.handleAmentiesChange}
                      formData={formData}
                    />
                  ) : (
                    ""
                  )}

                  {fourthApiLoading ? (
                    ""
                  ) : stepNum == 6 ? (
                    <AddListingStep6
                      fourthApiResponse={fourthApiResponse}
                      handleAmentiesChange={this.handleAmentiesChange}
                      formData={formData}
                    />
                  ) : (
                    ""
                  )}
                  {fifthApiLoading ? (
                    ""
                  ) : stepNum == 7 ? (
                    <AddListingStep7
                      fifthApiResponse={fifthApiResponse}
                      handleAmentiesChange={this.handleAmentiesChange}
                      formData={formData}
                      displayTimeslot={displayTimeslot}
                      displayDailySlot={this.state.displayDailySlot}
                    />
                  ) : (
                    ""
                  )}
                  {sixthApiLoading ? (
                    ""
                  ) : stepNum == 7 ? (
                    <AddListingStep8
                      sixthApiResponse={sixthApiResponse}
                      handleAmentiesChange={this.handleAmentiesChange}
                      formData={formData}
                      pricingType={this.state.pricingType}
                    />
                  ) : (
                    ""
                  )}

                  {eighthApiLoading ? (
                    ""
                  ) : stepNum == 8 ? (
                    <AddListingStep9
                      eighthApiResponse={eighthApiResponse}
                      handleAmentiesChange={this.handleAmentiesChange}
                      formData={formData}
                      OtherRuleLoading={this.state.OtherRuleLoading}
                      OtherHouseRules={this.state.OtherHouseRules}
                      OtherCancellationPolicies={
                        this.state.OtherCancellationPolicies
                      }
                    />
                  ) : (
                    ""
                  )}

                  {seventhApiLoading ? (
                    ""
                  ) : stepNum == 9 ? (
                    <AddListingStep10
                      seventhApiResponse={seventhApiResponse}
                      handleAddOnServices={this.handleAddOnServices}
                      formData={formData}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div className="listing-footer">
                  {stepNum == 1 ? (
                    ""
                  ) : (
                    <button
                      className="white-btn"
                      type="button"
                      onClick={this.handleBackButton}
                    >
                      <i className="fas fa-chevron-left" /> Back
                    </button>
                  )}
                  <button
                    className="green-btn float-right sw-btn-next"
                    type="button"
                    disabled={submitClicked}
                    onClick={
                      stepNum == 9 ? this.submitForm : this.handleNextButton
                    }
                  >
                    {stepNum == 9
                      ? loadingContent == null
                        ? "Submit"
                        : loadingContent
                      : "Next"}{" "}
                    {stepNum != 9 ? <i className="fas fa-chevron-right" /> : ""}
                  </button>
                </div>

                {/* <div className="add-list-footer listing-footer">
                  <div className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end">
                    <div
                      className="btn-group mr-2 sw-btn-group row bg-white"
                      role="group"
                    >
                      e
                        <button
                          className="green-color add-back sw-btn-prev disabled"
                          type="button"
                          onClick={this.handleBackButton}
                        >
                          <i className="fas fa-chevron-left" /> Back
                        </button>
                      )}
                      <button
                        className="green-btn sw-btn-next"
                        type="button"
                        disabled={submitClicked}
                        onClick={
                          stepNum == 10
                            ? this.submitForm
                            : this.handleNextButton
                        }
                      >
                        {stepNum == 10
                          ? loadingContent == null
                            ? "Submit"
                            : loadingContent
                          : "Next"}
                      </button>
                    </div>
                    <div className="clear-both" />
                  </div>
                </div>
               */}
              </div>
            </div>
            <div className="flow-bg-right col-md-5" />
          </div>
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(withToastManager(AddLisingIndex));
