import React from 'react'
import { Link } from 'react-router-dom';

const queryString = require("query-string");


const LandingSubCategory = (props) => {

  const { loadingEvent, eventData } = props;

  var location = {}

  const handleEventRedirect = (eventDetail) => {

    const query = queryString.stringify(
      {selectedSubCategory : [eventDetail.name] , subCatID : [eventDetail.id]},
      { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
    );

    location = {
      pathname : `/see_all/events/${eventDetail.id}/${eventDetail.unique_id}`,
      state : { 
        // type:  eventData.api_page_type,
        // searchInput: { 
        //   sub_cat_title : eventDetail.name,
        //   sub_cat_id : eventDetail.id
        // },
        notMainSearch: true
      },
      search : query
    }
    return location;
  }

  return (
    <>
    {loadingEvent ? (
      "Loading..."
    ) : eventData.data.length > 0 ? (
      <div className="party-life site-content">
        <h1 className="section-head">What are you looking for?</h1>
        <section className="m-t-10">
          <div className="row grid-column-sec">
            {eventData.data.map((eventDetail) => (
              <div
                key={eventDetail.event_id}
                className="col-md-4 col-xl-3 col-lg-3 margin-btm1 resp-mrg-btm-xs"
              >
                <Link
                  to={handleEventRedirect(eventDetail)}
                >
                  <div
                    class="homes-img-sec1 event-image"
                    style={{
                      backgroundImage:
                        "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.6))," +
                        `url(${eventDetail.picture})`,
                    }}
                  ></div>

                  <div class="carousel-caption carousel-caption-event">
                    <h1 className="caption-txt">{eventDetail.name}</h1>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <br></br>
        </section>
      </div>
    ) : null}
    </>
  )
}

export default LandingSubCategory
