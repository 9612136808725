import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../../HostEnvironment";
import ToastDemo from "../../Helper/toaster";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import HostRegister from "../../Host/Auth/register";
import { apiConstants } from "../../Constant/constants";
import GoogleLogin from "react-google-login";
import SocialLogin from "./SocialLogin";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const $ = window.$;

class HostLogin extends HostHelper {
  state = { 
    data: {
      timezone: const_time_zone,
    },
    googleLoginInput: {
      social_unique_id: "",
      login_by: "",
      first_name: "",
      last_name: "",
      email: "",
      name: "",
      picture: "",
      device_type: "web",
      device_token: "123466",
      timezone: const_time_zone,
    },
    loadingContent: null, 
    buttonDisable: false,
    loading:true,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("login", this.state.data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("hostId", response.data.data.provider_id);
          localStorage.setItem("login_by", response.data.data.login_by);
          localStorage.setItem("socket", true);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("hostLoginStatus", true);
          localStorage.setItem("host_picture", response.data.data.picture);
          localStorage.setItem("provider_name", response.data.data.username);
          localStorage.setItem("is_frontdesk", response.data.data.is_frontdesk);
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });

          // window.location = response.data.data.is_frontdesk == 1 ? "/host/front-desk" :"/host/dashboard";

          if(response.data.data.is_frontdesk == 1) {
            window.location = "/host/front-desk";
          } else {
            if(response.data.data.provider_type == 0) {
              window.location = "/host/subscriptions";
            } else {
              window.location = "/host/dashboard";
            }
          }

          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({ loadingContent: null, buttonDisable: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null });
      });
  };

  handleResponseChange = ({ currentTarget: input }) => {
    const googleLoginInput = { ...this.state.googleLoginInput };
    googleLoginInput[input.name] = input.value;
    this.setState({ googleLoginInput });
  };

  handleMobileResponse = (value) => {
    const googleLoginInput = { ...this.state.googleLoginInput };
    googleLoginInput['mobile'] = value;
    this.setState({ googleLoginInput });
  };

  responseGoogle = (response) => {
    this.setState({
        googleLoginInput: '',
        loading: true,
    });
    const path = this.props.location;
    if (response.profileObj) {
        console.log(response.profileObj);
        const googleLoginInput = { ...this.state.googleLoginInput };
        googleLoginInput['social_unique_id'] = response.profileObj.googleId;
        googleLoginInput['login_by'] = "google";
        googleLoginInput['email'] =  response.profileObj.email;
        googleLoginInput['first_name'] = response.profileObj.givenName;
        googleLoginInput['last_name'] = response.profileObj.familyName;
        googleLoginInput['name'] = response.profileObj.name;
        googleLoginInput['picture'] = response.profileObj.imageUrl;
        googleLoginInput['timezone'] = const_time_zone;

        this.setState({
            googleLoginInput: googleLoginInput,
            loading: false,
        });
        api.postMethod("check_new_provider", this.state.googleLoginInput)
        .then((response) => {
            if (response.data.success === true) {
                if(response.data.data.is_new_user == 1) {
                  $("#hostsignup").modal("hide");
                  $("#social-login").modal("show");
                } else {
                    this.setState({
                        loadingContent: "Loading... Please wait..",
                        buttonDisable: true,
                    });
                    const path = this.props.location;
                    $("#hostsignup").modal("hide"); 
                    api.postMethod("register", this.state.googleLoginInput)
                    .then((response) => {
                      if (response.data.success) {
                        localStorage.setItem("hostId", response.data.data.provider_id);
                        localStorage.setItem("login_by", response.data.data.login_by);
                        localStorage.setItem("socket", true);
                        localStorage.setItem("accessToken", response.data.data.token);
                        localStorage.setItem("hostLoginStatus", true);
                        localStorage.setItem("host_picture", response.data.data.picture);
                        localStorage.setItem("provider_name", response.data.data.username);
                        localStorage.setItem("is_frontdesk", response.data.data.is_frontdesk);
                        ToastDemo(this.props.toastManager, response.data.message, "success");
                        this.setState({ loadingContent: null, buttonDisable: false });

                        if(response.data.data.is_frontdesk == 1) {
                          window.location = "/host/front-desk";
                        } else {
                          if(response.data.data.provider_type == 0) {
                            window.location = "/host/subscriptions";
                          } else {
                            window.location = "/host/dashboard";
                          }
                        }

                      } else {
                        ToastDemo(this.props.toastManager, response.data.error, "error");
                        this.setState({ loadingContent: null, buttonDisable: false });
                      }
                    })
                    .catch((error) => {
                      this.setState({ loadingContent: null });
                    });

                }
            } else {
                
            }
        })
        .catch((error) => {
            this.setState({
                loadingContent: null,
                buttonDisable: false,
            });
        });
        // $("#social-login").modal("show");
    }
  };

  handleGoogleSubmit = (event) => {
    event.preventDefault();
    this.setState({
        loadingContent: "Loading... Please wait..",
        buttonDisable: true,
    });
    const path = this.props.location;
    $("#social-login").modal("hide");
    api.postMethod("register", this.state.googleLoginInput)
    .then((response) => {
      if (response.data.success) {
        localStorage.setItem("hostId", response.data.data.provider_id);
        localStorage.setItem("login_by", response.data.data.login_by);
        localStorage.setItem("socket", true);
        localStorage.setItem("accessToken", response.data.data.token);
        localStorage.setItem("hostLoginStatus", true);
        localStorage.setItem("host_picture", response.data.data.picture);
        localStorage.setItem("provider_name", response.data.data.username);
        localStorage.setItem("is_frontdesk", response.data.data.is_frontdesk);
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });

        if(response.data.data.is_frontdesk == 1) {
          window.location = "/host/front-desk";
        } else {
          if(response.data.data.provider_type == 0) {
            window.location = "/host/subscriptions";
          } else {
            window.location = "/host/dashboard";
          }
        }

        // window.locatiom = path
        //   ? this.props.history.push(path.from.pathname)
        //   : this.props.history.push("/home");
        // this.props.history.push("/home");
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    })
    .catch((error) => {
      this.setState({ loadingContent: null });
    });
  };

  render() {
    const { loading,googleLoginInput } = this.state;
    return (
      <div className="page-content">
        <div className="prov-login">
          <h1 className=""> Host Login </h1>
          <form className="top1 prov-login-form" onSubmit={this.handleSubmit}>
            <div className="form-group input-group">
              <input
                type="text"
                className="form-control"
                placeholder="email address"
                onChange={this.handleChange}
                name="email"
              />
            </div>
            <div className="form-group input-group">
              <input
                type="password"
                className="form-control"
                placeholder="password"
                onChange={this.handleChange}
                name="password"
              />
            </div>
            <button
              className="pink-btn bottom1 block cmn-btn"
              disabled={this.state.buttonDisable}
            >
              {" "}
              {this.state.loadingContent != null
                ? this.state.loadingContent
                : "login"}
            </button>
            <Link
              to={"/host/forgot-password"}
              className="forgot-pass close-login"
            >
              forgot password?
            </Link>
          </form>
          <div className="login-separator">
              or continue with
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 bottom1">
                  <GoogleLogin
                      clientId={
                          apiConstants.GOOGLE_CLIENT_ID
                      }
                      render={(renderProps) => (
                          <button
                              className="social-btn"
                              onClick={
                                  renderProps.onClick
                              }
                              disabled={
                                  renderProps.disabled
                              }
                          >
                              <i className="fab fa-google" />{" "}
                              Google
                          </button>
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={"single_host_origin"}
                  />
              </div>
          </div>
          <h4 className="m-0 text-center captalize">
            Don't have an account?{" "}
            <Link  to="#"
            data-toggle="modal"
            data-target="#hostsignup">
              {" "}
              Sign up
            </Link>
          </h4>
        </div>
        <div className="header-height" />
        <HostRegister {...this.props.props} />
          {loading ? (
            " "
          ) : (
          <SocialLogin 
              googleLoginInput={googleLoginInput} 
              handleResponseChange={this.handleResponseChange} 
              handleGoogleSubmit={this.handleGoogleSubmit}
              handleMobileResponse={this.handleMobileResponse}/>
          )}
      </div>
    );
  }
}

export default withToastManager(HostLogin);
